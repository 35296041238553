import { useCallback, useEffect } from 'react'

import { FormHelperText, formHelperTextClasses } from '@mui/material'
import get from 'lodash/get'
import { useFormState, useWatch, useFormContext, FieldError } from 'react-hook-form'

import { FormatedFormError } from 'appTypes'
import {
    GridContainerColumns,
    GridItem,
    SelectInput,
    TextInput,
    useArrayControllerElementContext,
} from 'components'
import { useUtilityDrawerContext } from 'components/Drawer/UtilityDrawer'
import {
    formErrorToString,
    inputIntegerNonNegativeSpacedMaskParams,
    validateLessThan,
    requiredValidation,
} from 'utils'

import { defaultValue, getTypeChoice, typeChoices } from '../constants'

import TriggerThresholdValidation from './TriggerValidation'
import { PMIntervalRow } from './UnitPMForm'
import UnitPMMeterLine from './UnitPMMeterLine'
import UnitPMMeterValueInput from './UnitPMMeterValueInput'
import UnitPMFMeterCalcThreshold from './UnitPmMeterCalcThreshold'
import useInputSource, { meterTypeItemSource } from './useInputSource'
import { UnitPMDrawerEditorExtraState } from './useUnitPMDrawerEditor'

const everyValidation = validateLessThan(1000, 'Should be less than 1000')

const UnitPMMeterTime = () => {
    const source = useInputSource()
    const { index } = useArrayControllerElementContext<{ valueType: PMIntervalRow }>()
    const { extra } = useUtilityDrawerContext()
    const { isArchived } = extra as UnitPMDrawerEditorExtraState

    // TODO: this validation triggers on mount, but it should trigger only on change
    const thresholdValidation = useCallback((v, data) => {
        const row = get(data, meterTypeItemSource(index)) || {}
        const { value, valueType, threshold, thresholdType } = row

        if (!value || !threshold) {
            return null
        }

        if (
            (Number(value) || 0) * (getTypeChoice(valueType)?.toDay || 0) <
            (Number(threshold) || 0) * (getTypeChoice(thresholdType)?.toDay || 0)
        ) {
            return 'The Threshold cannot be greater than the Meter interval'
        }
    }, [])

    return (
        <>
            <GridContainerColumns>
                <GridItem xs={6}>
                    <UnitPMMeterValueInput validate={everyValidation} />
                </GridItem>
                <GridItem xs={6}>
                    <SelectInput
                        source={source('valueType')}
                        disabled={isArchived}
                        label="Type"
                        validate={requiredValidation}
                        choices={typeChoices}
                        defaultValue={defaultValue}
                        disableEmptyValue
                    />
                </GridItem>
                <GridItem xs={6}>
                    <TextInput
                        disabled={isArchived}
                        sx={{
                            [`& .${formHelperTextClasses.root}`]: {
                                display: 'none !important',
                            },
                        }}
                        source={source('threshold')}
                        label="“Due Soon” when"
                        {...inputIntegerNonNegativeSpacedMaskParams}
                        validate={thresholdValidation}
                    />
                </GridItem>
                <GridItem xs={6}>
                    <SelectInput
                        source={source('thresholdType')}
                        disabled={isArchived}
                        label="Type"
                        clearable={false}
                        choices={typeChoices.map((item) => ({
                            ...item,
                            name: item.name + ' left',
                        }))}
                        sx={{
                            [`& .${formHelperTextClasses.root}`]: {
                                display: 'none !important',
                            },
                        }}
                        disableEmptyValue
                        defaultValue={defaultValue}
                        validate={thresholdValidation}
                    />
                </GridItem>
            </GridContainerColumns>
            <ThresholdHelperText />
            <UnitPMFMeterTimeCalcThreshold />
            <UnitPMMeterTimeLine />
            <TriggerThresholdValidation
                extraSource={['valueType', 'threshold', 'thresholdType']}
                triggerExtraSource={['thresholdType']}
            />
        </>
    )
}

export default UnitPMMeterTime

const ThresholdHelperText = () => {
    const source = useInputSource()
    const { errors, dirtyFields } = useFormState()
    const thresholdSource = source('threshold')
    const tresholdTypeSource = source('thresholdType')
    const field = get(errors, thresholdSource) as FieldError | FormatedFormError // TO DO find better solution
    const error = formErrorToString(field, 'message')
    const { setValue, getValues } = useFormContext()

    const areTouched = get(dirtyFields, thresholdSource) || get(dirtyFields, tresholdTypeSource)

    useEffect(() => {
        if (!areTouched) {
            return
        }
        const makeTouched = (name: string) => {
            setValue(name, getValues(name), {
                shouldDirty: true,
                shouldTouch: true,
                shouldValidate: true,
            })
        }

        makeTouched(tresholdTypeSource)
        makeTouched(thresholdSource)
    }, [areTouched])

    return (
        <FormHelperText
            error={Boolean(error)}
            sx={{ height: '39px', mx: '14px' }}
        >
            {error}
        </FormHelperText>
    )
}

const useTimeTypes = () => {
    const source = useInputSource()
    const [everyTypeValue, thresholdTypeValue] = useWatch({
        name: [source('valueType'), source('thresholdType')],
    })

    const getType = (type: string) => typeChoices.find((choice) => choice.id === type)

    return {
        everyTypeValue,
        thresholdTypeValue,
        every: getType(everyTypeValue),
        threshold: getType(thresholdTypeValue),
    }
}

const UnitPMMeterTimeLine = () => {
    const { every, threshold } = useTimeTypes()

    const calc = (type) => (num: number) => {
        if (!type) {
            return num
        }

        return type.toDay * num
    }

    return (
        <UnitPMMeterLine
            getEvery={calc(every)}
            getThreshold={calc(threshold)}
        />
    )
}

const UnitPMFMeterTimeCalcThreshold = () => {
    const { every, threshold, everyTypeValue } = useTimeTypes()

    return (
        <UnitPMFMeterCalcThreshold
            dependencies={[everyTypeValue]}
            getValue={(num) => (every ? every.toDay * num : num)}
            getThresholdValue={(num) => (threshold ? num / threshold.toDay : num)}
        />
    )
}

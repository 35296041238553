import { UnarchiveOutlined } from '@mui/icons-material'

import { IconBox } from 'components'

const ArchivedUnitBadge = () => {
    return (
        <IconBox sx={{ ml: '6px', height: '20px', width: '20px' }}>
            <UnarchiveOutlined
                sx={(theme) => ({
                    color: `${theme.palette.text.disabled} !important`,
                })}
            />
        </IconBox>
    )
}

export default ArchivedUnitBadge

import { AccessAlarm } from '@mui/icons-material'

import { ReactComponent as ClipboardClock } from 'assets/images/clipboard-clock-outline.svg'
import { BoxContainer, StatusText } from 'components'
import { reportUrl } from 'pages/Reports'
import { PMIntervalRow } from 'pages/Units/PM/PMEditor/UnitPMForm'
import { createReportResource } from 'pages/Units/config/constants'

import { Monitor } from '../../MonitorComponents'
import { useMonitor } from '../useMonitor'

interface dataType {
    dueCount: number
    dueWorkOrdersCount: number
    overdueCount: number
    overdueWorkOrdersCount: number
    plannedCount: number
    plannedWorkOrdersCount: number
    total: number
}

const MonitorPM = () => {
    const data = useMonitor<dataType>(createReportResource('pm-schedules'))
    if (!data) {
        return <Monitor.Skeleton />
    }

    const {
        dueCount,
        dueWorkOrdersCount,
        overdueCount,
        overdueWorkOrdersCount,
        plannedCount,
        plannedWorkOrdersCount,
    } = data
    const pmCountFormatter = (value: number) => {
        if (value < 1000) {
            return value
        }

        return `${Math.floor(value / 1000)} k`
    }
    const createLink = (status: PMIntervalRow['status']) => {
        return (
            reportUrl(reportUrl.urls.intervals) +
            '?filter=' +
            encodeURIComponent(
                JSON.stringify({
                    status: [status],
                }),
            )
        )
    }
    return (
        <Monitor>
            <Monitor.Title icon={AccessAlarm}>PM Intervals</Monitor.Title>

            <Monitor.ArrowButton navigateTo={reportUrl(reportUrl.urls.intervals)} />
            <Monitor.Container>
                <Monitor.Button navigateTo={createLink('OVERDUE')}>
                    <BoxContainer>
                        <Monitor.ValueText>{pmCountFormatter(overdueCount)}</Monitor.ValueText>
                        <Monitor.Counter image={ClipboardClock}>
                            {overdueWorkOrdersCount}
                        </Monitor.Counter>
                    </BoxContainer>
                    <StatusText statusColor={(theme) => theme.palette.charts.red}>
                        Overdue
                    </StatusText>
                </Monitor.Button>
                <Monitor.Button navigateTo={createLink('DUE')}>
                    <BoxContainer>
                        <Monitor.ValueText>{pmCountFormatter(dueCount)}</Monitor.ValueText>
                        <Monitor.Counter image={ClipboardClock}>
                            {dueWorkOrdersCount}
                        </Monitor.Counter>
                    </BoxContainer>
                    <StatusText statusColor={(theme) => theme.palette.charts.orange}>
                        Due Soon
                    </StatusText>
                </Monitor.Button>
                <Monitor.Button navigateTo={createLink('PLANNED')}>
                    <BoxContainer>
                        <Monitor.ValueText>{pmCountFormatter(plannedCount)}</Monitor.ValueText>
                        <Monitor.Counter image={ClipboardClock}>
                            {plannedWorkOrdersCount}
                        </Monitor.Counter>
                    </BoxContainer>
                    <StatusText
                        sx={{ width: 'fit-content' }}
                        statusColor={(theme) => theme.palette.charts.greenBody}
                    >
                        Planned
                    </StatusText>
                </Monitor.Button>
            </Monitor.Container>
        </Monitor>
    )
}
export default MonitorPM

import { ResourceType } from 'components'
import { useResource } from 'components/resource'
import { useAuthProvider } from 'hooks'

import { AllPermissions } from './../appTypes/models/PermissionsModel'

const useResourcePermissions = (resourceProp?: ResourceType): AllPermissions => {
    const resource = useResource(resourceProp)
    const authProvider = useAuthProvider()
    const permission = authProvider.permissions()[resource.name]

    return permission
}

export default useResourcePermissions

import { inject, observer } from 'mobx-react'

import { CompanyModel } from 'appTypes/models'
import {
    Form,
    GridContainer,
    GridItemLayout,
    PageContent,
    SaveButtonMobileFixed,
    SectionTitle,
    TextInput,
} from 'components'
import Icons from 'components/icons'
import { useSubmit } from 'hooks'
import { AuthStore } from 'providers/authStore'
import { costNonNegativeMaskParams, parseNumber, validateMaxLength } from 'utils'

const laborValidate = validateMaxLength(10)

const Global = inject('auth')(
    observer(({ auth }: { auth?: AuthStore }) => {
        const company = auth.currentCompany

        const submit = useSubmit<CompanyModel>(
            async (formData) => {
                await auth.saveCompany({
                    data: {
                        laborHourlyRate: parseNumber(formData.laborHourlyRate) as number,
                    },
                })
            },
            {
                successMessage: ({ defaultMessages }) => defaultMessages.updated,
            },
        )

        return (
            <PageContent>
                <Form
                    record={company}
                    onSubmit={submit}
                >
                    <GridContainer>
                        <GridItemLayout>
                            <SectionTitle>Global Hourly Labor Rate</SectionTitle>
                            <TextInput
                                source="laborHourlyRate"
                                label="Rate"
                                {...costNonNegativeMaskParams}
                                validate={laborValidate}
                            />
                            <SaveButtonMobileFixed startIcon={<Icons.SaveFilled />} />
                        </GridItemLayout>
                    </GridContainer>
                </Form>
            </PageContent>
        )
    }),
)

export default Global

import { Billing } from 'appTypes/models'
import { StyledElement, Typography } from 'components'
import { resolveDoublePriceMask } from 'utils'

interface WoTotalProps {
    title: string
    count: number
    price: number
    total: number
}

const WoTotal = ({ title, count, price, total }: WoTotalProps) => {
    return (
        <StyledElement sx={{ px: '12px' }}>
            <Typography
                variant="chartTitle"
                color="text.disabled"
            >
                {title}
            </Typography>
            <StyledElement sx={{ display: 'flex', mt: '4px' }}>
                <Typography
                    variant="body2"
                    color="text.secondary"
                >
                    {count} x {resolveDoublePriceMask(price)}
                </Typography>
                <Typography
                    sx={{ ml: 'auto' }}
                    variant="body2"
                    color="text.secondary"
                >
                    {resolveDoublePriceMask(total)}
                </Typography>
            </StyledElement>
        </StyledElement>
    )
}

const BreakdownTooltip = ({ billing }: { billing: Billing }) => {
    return (
        <StyledElement sx={{ width: '219px' }}>
            <Typography
                variant="subtitle1"
                color="text.primary"
            >
                Breakdown
            </Typography>

            <StyledElement
                sx={{
                    background: (theme) => theme.palette.gray,
                    mx: '-12px',
                    mt: '17px',
                    py: '16px',
                }}
            >
                <StyledElement>
                    <WoTotal
                        title="Work Orders"
                        count={billing.workOrdersForPeriodCount}
                        price={billing.workOrderPrice}
                        total={billing.workOrdersForPeriodTotal}
                    />
                </StyledElement>
                {billing.usedFreeWorkOrdersForPeriodCount ? (
                    <StyledElement sx={{ mt: '16px' }}>
                        <WoTotal
                            title="Free Work Orders"
                            count={billing.usedFreeWorkOrdersForPeriodCount}
                            price={-billing.workOrderPrice}
                            total={-billing.usedFreeWorkOrdersForPeriodTotal}
                        />
                    </StyledElement>
                ) : null}
            </StyledElement>

            <StyledElement sx={{ display: 'flex', mt: '23px' }}>
                <Typography
                    variant="chartTitle"
                    color="text.primary"
                >
                    Total
                </Typography>
                <Typography
                    sx={{ ml: 'auto' }}
                    variant="menuItem"
                    color="text.primary"
                >
                    {resolveDoublePriceMask(billing.toBePaidWorkOrdersForPeriodTotal)}
                </Typography>
            </StyledElement>
        </StyledElement>
    )
}

export default BreakdownTooltip

import { inject, observer } from 'mobx-react'

import { getCk33Info, GetCk33InfoArgs } from 'components'
import { AuthStore } from 'providers/authStore'

export const partCk33Label = (args: GetCk33InfoArgs) => getCk33Info(args).label

const PartCk33Label = inject('auth')(
    observer(({ auth }: { auth?: AuthStore }) => <>{partCk33Label(auth)}</>),
)

export default PartCk33Label

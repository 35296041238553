import { iconButtonClasses } from '@mui/material'
import { PasswordInput as PassInp, PasswordInputProps } from 'react-admin'
import { useWatch } from 'react-hook-form'

const PasswordInput = (props: PasswordInputProps) => {
    const value = useWatch({ name: props.source })
    return (
        <PassInp
            size="medium"
            variant="outlined"
            label="Password"
            sx={{
                mb: '8px',
                [`.${iconButtonClasses.root}`]: {
                    display: value ? undefined : 'none',
                },
            }}
            {...props}
        />
    )
}

PasswordInput.defaultProps = {
    source: 'password',
}

export default PasswordInput

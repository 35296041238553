import { alpha } from '@mui/material'

import { Optional } from 'appTypes'
import { displayState } from 'utils'

export const filterSearchText: 'text' = 'text'

export const emptySelectValue = 'empty-value'

export const perPageLimit = 1000

export const nonFieldErrors = 'nonFieldErrors'

export const emptyPerPage = -99

export const emptyOptionValue = '__EMPTY__'

interface State {
    abbr: string
    name: string
}
export const states: State[] = [
    { abbr: 'AL', name: 'Alabama' },
    { abbr: 'AK', name: 'Alaska' },
    { abbr: 'AZ', name: 'Arizona' },
    { abbr: 'AR', name: 'Arkansas' },
    { abbr: 'CA', name: 'California' },
    { abbr: 'CO', name: 'Colorado' },
    { abbr: 'CT', name: 'Connecticut' },
    { abbr: 'DE', name: 'Delaware' },
    { abbr: 'DC', name: 'District of Columbia' },
    { abbr: 'FL', name: 'Florida' },
    { abbr: 'GA', name: 'Georgia' },
    { abbr: 'HI', name: 'Hawaii' },
    { abbr: 'ID', name: 'Idaho' },
    { abbr: 'IL', name: 'Illinois' },
    { abbr: 'IN', name: 'Indiana' },
    { abbr: 'IA', name: 'Iowa' },
    { abbr: 'KS', name: 'Kansas' },
    { abbr: 'KY', name: 'Kentucky' },
    { abbr: 'LA', name: 'Louisiana' },
    { abbr: 'ME', name: 'Maine' },
    { abbr: 'MD', name: 'Maryland' },
    { abbr: 'MA', name: 'Massachusetts' },
    { abbr: 'MI', name: 'Michigan' },
    { abbr: 'MN', name: 'Minnesota' },
    { abbr: 'MS', name: 'Mississippi' },
    { abbr: 'MO', name: 'Missouri' },
    { abbr: 'MT', name: 'Montana' },
    { abbr: 'NE', name: 'Nebraska' },
    { abbr: 'NV', name: 'Nevada' },
    { abbr: 'NH', name: 'New Hampshire' },
    { abbr: 'NJ', name: 'New Jersey' },
    { abbr: 'NM', name: 'New Mexico' },
    { abbr: 'NY', name: 'New York' },
    { abbr: 'NC', name: 'North Carolina' },
    { abbr: 'ND', name: 'North Dakota' },
    { abbr: 'OH', name: 'Ohio' },
    { abbr: 'OK', name: 'Oklahoma' },
    { abbr: 'OR', name: 'Oregon' },
    { abbr: 'PA', name: 'Pennsylvania' },
    { abbr: 'RI', name: 'Rhode Island' },
    { abbr: 'SC', name: 'South Carolina' },
    { abbr: 'SD', name: 'South Dakota' },
    { abbr: 'TN', name: 'Tennessee' },
    { abbr: 'TX', name: 'Texas' },
    { abbr: 'UT', name: 'Utah' },
    { abbr: 'VT', name: 'Vermont' },
    { abbr: 'VA', name: 'Virginia' },
    { abbr: 'WA', name: 'Washington' },
    { abbr: 'WV', name: 'West Virginia' },
    { abbr: 'WI', name: 'Wisconsin' },
    { abbr: 'WY', name: 'Wyoming' },
]

export const stateChoices = states.map(({ name, abbr }) => ({
    id: abbr,
    name: displayState({ abbr, name }),
}))

export const statesObject = states.reduce((obj, state) => {
    obj[state.abbr] = state
    return obj
}, {} as { [key: string]: State })

export const updatedMessage = 'Successfully updated'
export const defaultPagination = { page: 1, perPage: 25 }
export const defaultSort = { field: 'id', order: 'DESC' }
export const defaultFilter = {}

export const EMPTY_ARRAY = Object.freeze([])
export const EMPTY_OBJECT = Object.freeze({})

export const WindowURL = window.URL || window.webkitURL

export const basePermissions = {
    retrieve: 'retrieve',
    destroy: 'destroy',
    destroyBulk: 'destroyBulk',
    create: 'create',
    createBulk: 'createBulk',
    update: 'update',
    archive: 'archive',
    archiveBulk: 'archiveBulk',
    unarchive: 'unarchive',
    unarchiveBulk: 'unarchiveBulk',
    pdf: 'pdf',
}

export interface TagColor {
    id: string
    name: string
    color: string
    // number -> opacity, string -> color
    bgcolor: number | string
}

type TagColorInput = Optional<TagColor, 'bgcolor'>

const transformTag = (tag: TagColorInput): TagColor => {
    return {
        ...tag,
        bgcolor: tag.bgcolor || alpha(tag.color, 0.2),
    }
}

export const tagColors: TagColor[] = (() => {
    const tags: TagColorInput[] = [
        {
            id: 'PURPLE',
            name: 'Purple ',
            color: '#766EBE',
        },
        { id: 'BLUE', name: 'Blue', color: '#3F85F7' },
        { id: 'GREEN', name: 'Green', color: '#5B9C79' },
        { id: 'BLUE_GREEN', name: 'Blue-green', color: '#45A0B9' },
        { id: 'YELLOW', name: 'Yellow', color: '#C5920D' },
        { id: 'BROWN', name: 'Brown', color: '#AE3115', bgcolor: '#F3E0DC' },
        { id: 'GREY', name: 'Grey', color: '#6D778A', bgcolor: '#E9EBED' },
        { id: 'LIGHT_BROWN', name: 'Light brown', color: '#C47D30', bgcolor: '#F6ECE0' },
        { id: 'PINK', name: 'Pink', color: '#C025C3', bgcolor: '#F6DEF6' },
        { id: 'RED', name: 'Red', color: '#E60023', bgcolor: '#FFD9DF' },
    ]

    return tags.map(transformTag)
})()

export const tagColorsObject = tagColors.reduce((acc, color) => {
    acc[color.id] = color
    return acc
}, {} as Record<string, TagColor>)

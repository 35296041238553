import { Link } from 'react-router-dom'

import { IconBox } from 'components'
import Icons from 'components/icons'
const SeeAllButton = () => {
    return (
        <Link to="cross-reference">
            <IconBox title="See All">
                <Icons.GridView />
            </IconBox>
        </Link>
    )
}
export default SeeAllButton

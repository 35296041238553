import { useMemo } from 'react'

import { WindowURL } from 'configs/constants'
import { fileType } from 'utils'

const useAttachmentFile = (file: string | File) => {
    const img = useMemo<{ url: string; name: string }>(() => {
        if (typeof file === 'string') {
            const fileNameParts = file.split('?')
            const fileName = fileNameParts[0].substring(fileNameParts[0].lastIndexOf('/') + 1)

            return {
                url: fileType(file) === 'pdf' ? 'pdf' : file,
                name: fileName,
            }
        }

        if (file instanceof File) {
            return {
                url: file.type === 'application/pdf' ? 'pdf' : WindowURL.createObjectURL(file),
                name: file.name,
            }
        }
        return null
    }, [file])

    return img
}
export default useAttachmentFile

import { FC } from 'react'

import { ArrowForward, PercentOutlined, WorkOutlineOutlined } from '@mui/icons-material'
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined'
import { useListContext } from 'react-admin'

import { WOJobItemTypes, JobModel } from 'appTypes/models'
import { ReactComponent as FeeIcon } from 'assets/images/fee.svg'
import {
    ViewHeader,
    InfoCardHeaderProps,
    SliderView,
    useResource,
    ListTotalBadge,
} from 'components'
import { DeleteOneFromListActionWithShowRefetch } from 'components/actions'
import Icons from 'components/icons'
import Section from 'components/styled/Section'
import { useCreateResourcePath } from 'hooks'
import { partsAvatarConfig } from 'pages/Parts/config/constants'
import { BoxLink } from 'pages/Units/Meters/components'

import { WithIsVendorWO } from '../Overview/WorkOrderOverview'

import WorkOrderJobCard, {
    WorkOrderJobCardActions,
    WorkOrderJobCardItemsActions,
} from './WorkOrderJobCard'
import {
    LaborCreateDrawerToggler,
    PartCreateDrawerToggler,
    VendorWorkOrderExtraActionsDrawerToggler,
} from './WorkOrderJobCard/components'
import WorkOrderJobsEmpty from './WorkOrderJobsEmpty'
import { WorkOrderJobsFallback } from './WorkOrderJobsResource'
import { deleteJobAction, EditJobDrawerToggler } from './config/constants'

const itemActions: WorkOrderJobCardItemsActions = (job, { children, resource, listContext }) => {
    return [
        <PartCreateDrawerToggler
            key={WOJobItemTypes.part}
            resource={resource}
            listContext={listContext}
            children={({ onClick }) =>
                children({ title: 'Add Part', Icon: partsAvatarConfig.Icon, onClick })
            }
        />,
        <LaborCreateDrawerToggler
            key={WOJobItemTypes.labor}
            resource={resource}
            listContext={listContext}
            job={job}
            children={({ onClick }) =>
                children({
                    title: 'Add Labor',
                    Icon: ScheduleOutlinedIcon,
                    onClick,
                })
            }
        />,
        <VendorWorkOrderExtraActionsDrawerToggler
            key={WOJobItemTypes.service}
            resource={resource}
            listContext={listContext}
            title="Add Services"
            itemResource="service"
            children={({ onClick }) =>
                children({
                    title: 'Add Services',
                    Icon: WorkOutlineOutlined,
                    onClick,
                })
            }
        />,
        <WithIsVendorWO key={WOJobItemTypes.fee}>
            {(isVendorWorkOrder) =>
                isVendorWorkOrder && (
                    <VendorWorkOrderExtraActionsDrawerToggler
                        resource={resource}
                        listContext={listContext}
                        title="Add Fee"
                        itemResource="fee"
                        children={({ onClick }) =>
                            children({
                                title: 'Add Fee',
                                Icon: FeeIcon,
                                onClick,
                            })
                        }
                    />
                )
            }
        </WithIsVendorWO>,
        <WithIsVendorWO key={WOJobItemTypes.tax}>
            {(isVendorWorkOrder) =>
                isVendorWorkOrder && (
                    <VendorWorkOrderExtraActionsDrawerToggler
                        resource={resource}
                        listContext={listContext}
                        title="Add Tax"
                        itemResource="tax"
                        children={({ onClick }) =>
                            children({
                                title: 'Add Tax',
                                Icon: PercentOutlined,
                                onClick,
                            })
                        }
                    />
                )
            }
        </WithIsVendorWO>,
    ]
}

const actions: WorkOrderJobCardActions = (job, { children, resource, listContext }) => [
    <EditJobDrawerToggler
        listContext={listContext}
        job={job}
        resource={resource}
        key="edit"
        children={({ onClick }) => children({ title: 'Edit', Icon: Icons.Edit, onClick })}
    />,
    <DeleteOneFromListActionWithShowRefetch
        {...deleteJobAction(job.id, resource)}
        key="delete"
        id={job.id}
        resource={resource}
        children={children}
        listContext={listContext}
    />,
]

const WorkOrderJobsSlider: FC<InfoCardHeaderProps> = ({ action }) => {
    const jobsListContext = useListContext<JobModel>()

    const { data = [], total, isLoading } = jobsListContext
    const resource = useResource()
    const createPath = useCreateResourcePath()

    let content

    if (isLoading) {
        return <WorkOrderJobsFallback />
    }

    if (!isLoading) {
        if (total) {
            const items = data.map((job, i) => (
                <WorkOrderJobCard
                    key={`${job.name}-${i}`}
                    job={job}
                    jobsListContext={jobsListContext}
                    resource={resource}
                    actions={actions}
                    itemActions={itemActions}
                />
            ))
            if (total >= 4) {
                const path = createPath({
                    type: 'list',
                })
                items.push(
                    <BoxLink
                        to={path}
                        label="SEE ALL JOBS"
                        icon={<ArrowForward />}
                    />,
                )
            }
            content = (
                <SliderView
                    height="219px"
                    items={items}
                />
            )
        } else {
            content = <WorkOrderJobsEmpty />
        }
    }

    return (
        <Section>
            <ViewHeader title="Jobs">
                <ViewHeader.Content>
                    <ListTotalBadge />
                </ViewHeader.Content>
                <ViewHeader.Content placement="rightMobile">{action}</ViewHeader.Content>
            </ViewHeader>
            {content}
        </Section>
    )
}
export default WorkOrderJobsSlider

import { ReactElement } from 'react'

import { useRecordContext } from 'react-admin'

import { RaResourceContextProvider, useResource } from 'components'
import { useClearResource } from 'hooks'

export const jobsItemsShortResourceName = 'work-order-job-items'

const WorkOrderJobItemsResource = ({ children }: { children: ReactElement }) => {
    const { id } = useRecordContext() || {}
    const resource = useResource()

    const jobItemsResource = `${resource.resource}/${id}/items`

    useClearResource(jobItemsResource)

    if (!id) {
        return null
    }

    return (
        <RaResourceContextProvider
            value={{
                resource: jobItemsResource,
                name: 'work-order-job-items',
            }}
        >
            {children}
        </RaResourceContextProvider>
    )
}

export default WorkOrderJobItemsResource

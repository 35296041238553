import { Box, darken, Divider, SvgIcon } from '@mui/material'
import { inject, observer } from 'mobx-react'
import { Link } from 'react-router-dom'

import { Billing } from 'appTypes/models'
import { ReactComponent as LogoBillingFree } from 'assets/images/logo-billing-free.svg'
import { ReactComponent as LogoBillingPro } from 'assets/images/logo-small-part.svg'
import { StatusText, Typography, Button } from 'components'
import { urls } from 'configs/config'
import renderOnBilling from 'hocs/renderOnBilling'
import { AuthStore } from 'providers/authStore'
import { BillingVariantColors, freeSubscriptionBillingColorVariant } from 'utils'

const billingVariantColors: BillingVariantColors = {
    variant1: (theme) => theme.palette.charts.greenBody,
    variant2: (theme) => theme.palette.charts.yellowBody,
    variant3: (theme) => theme.palette.charts.red,
}

const FreeSubscriptionStatusText = ({ billing }: { billing: Billing }) => {
    const colorVariant = freeSubscriptionBillingColorVariant(billing).variant
    return (
        <StatusText
            statusColor={(theme) => billingVariantColors[colorVariant](theme)}
            textColor={(theme) => darken(billingVariantColors[colorVariant](theme), 0.2)}
        >
            {billing.allUsedWorkOrdersCount}/{billing.allFreeWorkOrdersCount}
        </StatusText>
    )
}

const SidebarProfileBilling = inject('auth')(
    observer(({ auth }: { auth?: AuthStore }) => {
        const billing = auth.billing
        const subscription = billing.subscription
        return (
            <>
                <Box
                    p="14px 16px 18px"
                    bgcolor={(theme) => theme.palette.gray}
                >
                    <Typography
                        variant="subtitle2"
                        color="text.primary"
                        mb="12px"
                        display="flex"
                        alignItems="center"
                    >
                        <SvgIcon
                            component={subscription === 'pro' ? LogoBillingPro : LogoBillingFree}
                            inheritViewBox
                            sx={{ width: '16px', height: '16px', mr: '8px' }}
                        />
                        {subscription === 'pro' ? 'Fleetpal Pro' : 'Early Access'}
                    </Typography>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography
                            variant="body2"
                            color="text.primary"
                        >
                            {subscription === 'pro' ? 'Work Orders' : 'Free Work Orders'}
                        </Typography>
                        {subscription === 'pro' ? (
                            <StatusText statusColor={(theme) => theme.palette.primary.main}>
                                {billing.workOrdersForPeriodCount}
                            </StatusText>
                        ) : (
                            <FreeSubscriptionStatusText billing={billing} />
                        )}
                    </Box>
                    {subscription === 'free' && (
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            mt="16px"
                        >
                            <Button
                                variant="contained"
                                size="small"
                                component={Link}
                                to={urls.changePlan}
                            >
                                Upgrade
                            </Button>

                            {/* <Button size="small">
                            Earn free WO
                        </Button> */}
                        </Box>
                    )}
                </Box>
                <Divider light />
            </>
        )
    }),
)

export default renderOnBilling(SidebarProfileBilling)

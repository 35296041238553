import { ReactElement, useEffect } from 'react'

import {
    TablePagination,
    Pagination as MuiPagination,
    TablePaginationProps,
    alpha,
    tablePaginationClasses,
    paginationItemClasses,
} from '@mui/material'
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions'
import { useGridApiContext } from '@mui/x-data-grid'

import { BoxContainer } from 'components'
import classes from 'theme/classes'

import { useListInternalContext } from './ListInternalContextProvider'
interface SanitizedPaginationProps
    extends Pick<
        TablePaginationActionsProps,
        | 'backIconButtonProps'
        | 'nextIconButtonProps'
        | 'count'
        | 'className'
        | 'getItemAriaLabel'
        | 'onPageChange'
        | 'page'
        | 'rowsPerPage'
        | 'showFirstButton'
        | 'showLastButton'
    > {
    selectProps: object
}
const SanitizedPagination = (props: SanitizedPaginationProps) => {
    const {
        backIconButtonProps,
        nextIconButtonProps,
        onPageChange,
        rowsPerPage,
        selectProps,
        count,
        page,
        ...rest
    } = props

    return (
        <MuiPagination
            page={page + 1}
            sx={{
                [`& .${paginationItemClasses.root}`]: {
                    [`&.${classes.selected}`]: {
                        background: (theme) => alpha(theme.palette.text.main, 0.12),
                    },
                },
            }}
            count={Math.ceil(count / rowsPerPage)}
            {...rest}
            onChange={onPageChange}
        />
    )
}

type ExtendedPaginationProps = TablePaginationProps & {
    leftSide: ReactElement
}

const Pagination = ({ leftSide, ...rest }: ExtendedPaginationProps) => {
    const apiRef = useGridApiContext()
    const { setGridApi } = useListInternalContext()
    useEffect(() => {
        setGridApi(apiRef)
    }, [])
    return (
        <BoxContainer
            sx={{
                display: 'flex',
                marginLeft: 'auto',
                [`& .${tablePaginationClasses.select}`]: {
                    paddingBottom: '4px',
                },
            }}
        >
            {leftSide}
            <TablePagination
                {...rest}
                ActionsComponent={SanitizedPagination}
            />
        </BoxContainer>
    )
}
export default Pagination

import { Create, PageContent } from 'components'

import { VendorForm } from '../components'
import { vendorsSerializer } from '../constants/config'

import { VendorCreateHeader } from './components'
const VendorCreate = () => {
    return (
        <Create serializer={vendorsSerializer}>
            <VendorCreateHeader />
            <PageContent>
                <VendorForm />
            </PageContent>
        </Create>
    )
}

export default VendorCreate

import 'photoswipe/dist/photoswipe.css'
import {
    BoxContainer,
    GridContainer,
    Section,
    SectionTitle,
    GridItemLayout,
    InfoBadge,
} from 'components'

import { UnitDefect } from './UnitDVIR'
import UnitDvirDefectCard from './UnitDvirDefectCard'
const UnitDvirDefectsSection = ({ data }: { data: UnitDefect[] }) => {
    return (
        <Section>
            <SectionTitle
                as={BoxContainer}
                gap="8px"
            >
                Defects <InfoBadge badgeContent={data.length} />
            </SectionTitle>

            <GridContainer
                zIndex={1}
                spacing={{ xs: 3, sm: 6 }}
                rowGap="24px"
                position="relative"
            >
                {data.map((def, i) => (
                    <GridItemLayout
                        height="224px"
                        zIndex={data.length - i}
                        key={def.id}
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        xl={2}
                    >
                        <UnitDvirDefectCard {...def} />
                    </GridItemLayout>
                ))}
            </GridContainer>
        </Section>
    )
}
export default UnitDvirDefectsSection

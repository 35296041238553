import { ReactElement, createContext, useRef, useContext } from 'react'

import {
    Identifier,
    ListContextProvider,
    ListControllerResult,
    RaRecord,
    RecordContextProvider,
    SaveButtonProps,
} from 'react-admin'

import ErrorBoundary, { withErrorBoundary } from 'components/ErrorBoundary'
import ListUtilityDrawer, { ListUtilityDrawerInterface } from 'components/list/ListUtilityDrawer'
import { RaResourceContextProvider, ResourceType } from 'components/resource'

import UtilityDrawerCancelButton, {
    UtilityDrawerCancelButtonProps,
} from './UtilityDrawerCancelButton'
import UtilityDrawerDeleteOneFromListButton, {
    UtilityDrawerDeleteOneFromListButtonProps,
} from './UtilityDrawerDeleteOneFromListButton'
import UtilityDrawerSaveButton from './UtilityDrawerSaveButton'

const renderEmptyContent: ListUtilityDrawerInterface['renderContent'] = () => null
const renderEmptyWrapper: ListUtilityDrawerInterface['renderWrapper'] = () => <></>

export interface UtilityDrawerProps
    extends Partial<
        Omit<
            ListUtilityDrawerInterface,
            'open' | 'setOpen' | 'renderBottomLeft' | 'renderBottomRight' | 'renderTopRight'
        >
    > {
    renderBottomLeft?: (
        render: (props: UtilityDrawerCancelButtonProps) => ReactElement,
    ) => ReactElement
    renderBottomRight?: (render: (props?: SaveButtonProps) => ReactElement) => ReactElement
    renderTopRight?: (
        render: (props?: UtilityDrawerDeleteOneFromListButtonProps) => ReactElement,
    ) => ReactElement
}

const _renderBottomLeft = (params?: UtilityDrawerCancelButtonProps) => (
    <UtilityDrawerCancelButton {...params} />
)
const _renderBottomRight = (params?: SaveButtonProps) => <UtilityDrawerSaveButton {...params} />
const _renderTopRight = (params?: UtilityDrawerDeleteOneFromListButtonProps) => (
    <UtilityDrawerDeleteOneFromListButton {...params} />
)

export interface UtilityDrawerDrawerArgs extends Partial<UtilityDrawerProps> {}
interface BlockContext {
    retry: () => void
}

export interface DrawerState {
    extraArgs?: {
        id?: Identifier
        type?: 'edit' | 'create'
        resource?: ResourceType
        listContext?: ListControllerResult
        recordContext?: RaRecord
    }
    drawerArgs?: UtilityDrawerDrawerArgs
    extra?: any
    meta?: any
}

interface UseUtilityDrawerContext {
    forceClose: () => void
    close: () => void
    state: DrawerState
    extraArgs: DrawerState['extraArgs']
    extra: any
    controller: {
        block: (cb?: (ctx: BlockContext) => void) => () => void
    }
    updateState: DrawerProps['updateState']
}

export interface UseUtilityDrawerSetters
    extends Pick<UseUtilityDrawerContext, 'close' | 'controller' | 'forceClose'> {}

export const UtilityDrawerContext = createContext<UseUtilityDrawerContext>(
    {} as UseUtilityDrawerContext,
)

const defaultBlocker = () => {
    /* */
}

export const useUtilityDrawerContext = () => useContext(UtilityDrawerContext)

interface DrawerProps {
    close: () => void
    isOpen: boolean
    state: DrawerState
    updateState: (state: DrawerState) => void
}

const UtilityDrawer = ({ state, close, isOpen, updateState }: DrawerProps) => {
    const blocked = useRef<(((ctx: BlockContext) => void) | null)[]>([])

    const blockedClose = () => {
        if (blocked.current.length) {
            blocked.current.forEach((blocked) =>
                blocked({
                    retry: () => close(),
                }),
            )
            return
        }
        close()
    }

    const block = (cb: (ctx: BlockContext) => void = defaultBlocker) => {
        blocked.current.push(cb)
        return () => (blocked.current = blocked.current.filter((blocked) => blocked !== cb))
    }

    const { drawerArgs = {}, extraArgs = {} } = state

    const {
        renderBottomLeft,
        renderBottomRight,
        renderTopRight,
        renderContent = renderEmptyContent,
        renderWrapper = renderEmptyWrapper,
        ...restDrawerArgs
    } = drawerArgs

    return (
        <ListUtilityDrawer
            onForceClose={close}
            onClose={blockedClose}
            renderWrapper={(params) => {
                let content = renderWrapper(params)
                content = extraArgs.recordContext ? (
                    <RecordContextProvider value={extraArgs.recordContext}>
                        {content}
                    </RecordContextProvider>
                ) : (
                    content
                )
                content = extraArgs.listContext ? (
                    <ListContextProvider value={extraArgs.listContext}>
                        {content}
                    </ListContextProvider>
                ) : (
                    content
                )
                content = extraArgs.resource ? (
                    <RaResourceContextProvider value={extraArgs.resource}>
                        {content}
                    </RaResourceContextProvider>
                ) : (
                    content
                )

                return (
                    <UtilityDrawerContext.Provider
                        value={{
                            close: blockedClose,
                            forceClose: close,
                            state,
                            extraArgs: state.extraArgs,
                            extra: state.extra,
                            controller: {
                                block,
                            },
                            updateState,
                        }}
                    >
                        <ErrorBoundary>{content}</ErrorBoundary>
                    </UtilityDrawerContext.Provider>
                )
            }}
            anchorBottomHeightSize="big"
            title=""
            renderContent={renderContent}
            open={isOpen}
            setOpen={(open) => (open ? undefined : blockedClose())}
            renderBottomLeft={
                renderBottomLeft ? () => renderBottomLeft(_renderBottomLeft) : _renderBottomLeft
            }
            renderBottomRight={
                renderBottomRight ? () => renderBottomRight(_renderBottomRight) : _renderBottomRight
            }
            renderTopRight={renderTopRight ? () => renderTopRight(_renderTopRight) : () => null}
            {...restDrawerArgs}
        />
    )
}

export default withErrorBoundary(UtilityDrawer, {})

import { PopoverOrigin } from '@mui/material'
import { RaRecord } from 'react-admin'

import { WorkOrderModel } from 'appTypes/models'
import { BoxContainer, Typography, SimpleScrollbar } from 'components'
import { ActionsMenu, MenuActionProps, useActionsMenuContext } from 'components/actions'
import Icons from 'components/icons'
import { UseCreateResourcePathParams } from 'hooks'
import { useWoLimit } from 'pages/WorkOrders/hooks'

import CreateWOSection from './CreateWOSection'
import ExistingWOSection from './ExistingWOSection'
import { UseWOParams } from './UseWO/useAttachWO'
export interface WorkOrderCreateMenuProps<ParentType extends RaRecord = any>
    extends Omit<MenuActionProps<WorkOrderModel>, 'actions'>,
        Pick<UseCreateResourcePathParams, 'queryParams'>,
        Pick<UseWOParams, 'options'> {
    parentRecord: ParentType
    disabled: boolean
    anchorOrigin?: PopoverOrigin
    transformOrigin?: PopoverOrigin
    refetchList: string
}

const AddWorkOrderMenu = <ParentType extends RaRecord = any>({
    queryParams,
    disabled,
    parentRecord,
    anchorOrigin,
    transformOrigin,
    refetchList,
    options,
    ...props
}: WorkOrderCreateMenuProps<ParentType>) => {
    const { canCreateWo, woLimitReachedAction } = useWoLimit()
    const { close } = useActionsMenuContext()

    return (
        <ActionsMenu
            anchorOrigin={
                anchorOrigin || {
                    vertical: 'bottom',
                    horizontal: 'right',
                }
            }
            transformOrigin={
                transformOrigin || {
                    vertical: 'top',
                    horizontal: 'right',
                }
            }
            renderToggler={(open, isOpen) => (
                <BoxContainer
                    sx={{ cursor: disabled ? 'default' : 'pointer' }}
                    onClick={
                        disabled
                            ? undefined
                            : (event) => {
                                  if (canCreateWo()) {
                                      open(event)
                                      return
                                  }
                                  woLimitReachedAction()
                              }
                    }
                    role="button"
                    gap="4px"
                >
                    <Icons.Add
                        sx={{
                            width: '16px',
                            height: '16px',
                            color: disabled ? 'action.disabled' : 'primary.main',
                        }}
                    />
                    <Typography
                        variant="body2"
                        color={disabled ? 'action.disabled' : 'primary.main'}
                    >
                        Add
                    </Typography>
                </BoxContainer>
            )}
            actions={(params, { children }) => [
                <SimpleScrollbar
                    style={{ maxHeight: '500px', width: '274px' }}
                    key="add-wo"
                >
                    <CreateWOSection<ParentType>
                        key="create-wo"
                        parentRecord={parentRecord}
                        cardMenuClose={close}
                        refetchList={refetchList}
                        options={options}
                    />
                    <ExistingWOSection<ParentType>
                        key="existing-wo"
                        parentRecord={parentRecord}
                        refetchList={refetchList}
                        cardMenuClose={close}
                        options={options}
                    />
                </SimpleScrollbar>,
            ]}
            {...props}
        />
    )
}
export default AddWorkOrderMenu

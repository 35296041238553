import { useRef, useState } from 'react'

import { ViewWeekOutlined } from '@mui/icons-material'
import { ClickAwayListener, Popper } from '@mui/material'
import { useListContext } from 'react-admin'

import { DatagridColumnsProps } from 'components'
import GridColumnsPanel from 'components/Datagrid/DatagridColumnsPanel'
import { Button } from 'components/mui'
export const columnsButtonId = 'listColumnsButton'
const ColumnsButton = ({
    resetColumns,
}: {
    resetColumns: DatagridColumnsProps['resetColumns']
}) => {
    const anchorEl = useRef()
    const [open, setOpen] = useState(false)
    const { data } = useListContext()

    return (
        <>
            <Button
                variant="text"
                disabled={!data || !data.length}
                id={columnsButtonId}
                ref={anchorEl}
                startIcon={<ViewWeekOutlined />}
                onClick={() => {
                    setOpen(!open)
                }}
                children="Manage Columns"
                color="secondary"
                sx={{ display: 'flex', marginLeft: '8px', marginRight: 'auto' }}
            />
            <Popper
                anchorEl={anchorEl.current}
                open={open}
                sx={{ zIndex: 1300 }}
            >
                <ClickAwayListener
                    onClickAway={() => {
                        setOpen(false)
                    }}
                >
                    <GridColumnsPanel resetColumns={resetColumns} />
                </ClickAwayListener>
            </Popper>
        </>
    )
}

export default ColumnsButton

import { ChevronLeft } from '@mui/icons-material'

import UtilityDrawerCancelButton from 'components/Drawer/UtilityDrawerCancelButton'

import { useStepsContext } from './UseWOStepsContext'

const UseWOBackButton = () => {
    const { step, prev } = useStepsContext()

    return (
        <UtilityDrawerCancelButton
            children={step === 1 ? undefined : 'Back'}
            startIcon={step === 1 ? undefined : <ChevronLeft />}
            customAction={step === 1 ? undefined : prev}
        />
    )
}

export default UseWOBackButton

import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import QRScanner from 'qr-scanner'

import { Button } from 'components/mui'
import { NonDisplayedInput } from 'components/styled'
import { useNotify } from 'hooks'

import { useQRScannerContext } from './QRScannerContent'

const QRScannerFileButton = () => {
    const { onDecode } = useQRScannerContext()
    const notify = useNotify()

    return (
        <label>
            <Button
                component="div"
                variant="contained"
                startIcon={<FileUploadOutlinedIcon />}
            >
                Scan From File
            </Button>
            <NonDisplayedInput
                name="qr-scanner-file"
                type="file"
                accept="image/*"
                onChange={async (event) => {
                    const file = event.target.files?.[0]
                    if (!file) {
                        return
                    }
                    if (!file.type.startsWith('image')) {
                        notify('Unsupported file format', {
                            type: 'error',
                        })
                        return
                    }

                    const scan = (
                        disallowCanvasResizing: boolean = false,
                    ): Promise<QRScanner.ScanResult | null> => {
                        return QRScanner.scanImage(file, {
                            returnDetailedScanResult: true,
                            alsoTryWithoutScanRegion: true,
                            disallowCanvasResizing, // This makes scanning better/worse for some images. This is why toggle this and scan twice
                        }).catch(() => null)
                    }

                    let scanned = await scan()
                    if (!scanned) {
                        scanned = await scan(true)
                    }
                    if (scanned) {
                        onDecode(scanned)
                    } else {
                        notify('No QR code detected in selected file', {
                            type: 'error',
                        })
                    }

                    event.target.value = ''
                }}
            />
        </label>
    )
}

export default QRScannerFileButton

import React, { ElementType } from 'react'

import { alpha } from '@mui/material'

import { ReactComponent as fleetpal } from 'assets/images/logo-small-part.svg'
import { BoxContainer } from 'components'

import SuccessCircle from './SuccessCircle'

interface SuccessImageprops {
    image: ElementType<any>
}
const SuccessImage = ({ image }: SuccessImageprops) => {
    return (
        <BoxContainer
            position="relative"
            height="86px"
            width="156px"
        >
            <SuccessCircle
                sx={{
                    background: (theme) => alpha(theme.palette.text.primary, 0.05),
                    left: '0',
                }}
                icon={image}
            />
            <SuccessCircle
                sx={{
                    background: (theme) => alpha(theme.palette.primary.main, 0.1),
                    right: '0',
                }}
                icon={fleetpal}
            />
        </BoxContainer>
    )
}

export default SuccessImage

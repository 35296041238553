import { ReactElement } from 'react'

import {
    alpha,
    formHelperTextClasses,
    inputClasses,
    MenuItem,
    menuItemClasses,
    Select,
    selectClasses,
    SelectProps,
    styled,
    SxProps,
    Theme,
} from '@mui/material'
import { RaRecord } from 'react-admin'

import { StatusType } from 'appTypes'
import { Status } from 'components'
import { DropdownIconText } from 'components/IconText'
import classes from 'theme/classes'

type Choice = RaRecord & Pick<StatusType, 'iconColor'>

export interface DropdownSelectorProps<RecordType extends Choice = Choice>
    extends Omit<SelectProps, 'children' | 'MenuProps' | 'value'> {
    choices: RecordType[]
    isChoiceDissabled?: (choice: RecordType) => boolean
    hideDisabledChoice?: boolean
    readOnly?: boolean
    value: RecordType['id']
}

const statusClassName = 'choice-status'

type DropdownSelectorType = <RecordType extends Choice = Choice>(
    props: DropdownSelectorProps<RecordType>,
) => ReactElement

const DropdownSelector: DropdownSelectorType = styled(
    ({
        className,
        onChange,
        choices,
        isChoiceDissabled,
        hideDisabledChoice = true,
        ...rest
    }: DropdownSelectorProps) => {
        return (
            <Select
                onChange={onChange}
                className={className}
                label={false}
                size="small"
                MenuProps={{
                    sx: {
                        [`& .${menuItemClasses.root}.${classes.selected}`]: {
                            backgroundColor: 'unset !important',
                            color: (theme) => theme.palette.primary.main,
                        },
                        [`& .${statusClassName}`]: {
                            mx: '8px',
                        },
                    },
                }}
                disableUnderline
                variant="standard"
                {...rest}
            >
                {
                    // the value should be in the options. This is why render all of them
                    choices.map((choice) => {
                        const disabled = isChoiceDissabled ? isChoiceDissabled(choice) : false
                        return (
                            <MenuItem
                                key={choice.id}
                                value={choice.id}
                                disabled={disabled}
                                sx={{
                                    display:
                                        hideDisabledChoice && disabled
                                            ? 'none !important'
                                            : undefined,
                                }}
                            >
                                <DropdownIconText gap="6px">
                                    <Status
                                        size="8px"
                                        iconColor={choice.iconColor}
                                        className={statusClassName}
                                        sx={{ mx: 0 }}
                                    />
                                    {choice.name}
                                </DropdownIconText>
                            </MenuItem>
                        )
                    })
                }
            </Select>
        )
    },
)<DropdownSelectorProps>`
    margin-bottom: 0;
    ${({ readOnly }) => (readOnly ? 'min-width: unset;' : '')}

    & .${inputClasses.root} {
        margin-top: 0;
        background-color: unset;
        &.${classes.focused} .${inputClasses.input} {
            background-color: ${({ theme, readOnly }) =>
                readOnly ? 'unset' : alpha(theme.palette.primary.main, 0.08)};
        }
    }
    & .${inputClasses.input} {
        padding-left: 6px;
        border-radius: 4px !important;
        font-size: 12px;
        font-weight: 500;
        line-height: 1;
        ${({ readOnly }) =>
            readOnly
                ? `
            padding: 0;
            display: flex;
            padding-right: 0 !important;
            `
                : `padding: 3px 6px;
            `}
        min-height: unset !important;
        text-transform: uppercase;
    }
    & .${selectClasses.icon} {
        width: 20px;
        height: 20px;
        margin-top: 1px;
        ${({ readOnly }) => (readOnly ? 'display: none !important;' : '')}
    }
    & .${formHelperTextClasses.root} {
        display: none !important;
    }
`

export default DropdownSelector

export const dropdownSelectorInDatagridStyle: SxProps<Theme> = {
    marginLeft: '-12px',
    [`&.${inputClasses.root} .${inputClasses.input}`]: (theme) => ({
        ...theme.typography.body2,
        fontWeight: 'normal',
        color: theme.palette.text.primary,
        textTransform: 'none',
        padding: '3px 24px 3px 6px',
    }),
}

import { FC } from 'react'

import { alpha, Chip } from '@mui/material'

import { Role } from 'appTypes/models'
import { Columns, Spacer, Typography } from 'components'

import permissionsTranslator from '../permissionsTranslator'

interface UserRolesPermissionsListProps {
    role: Role
}

const UserRolesPermissionsList: FC<UserRolesPermissionsListProps> = ({ role }) => {
    const permissions = role.permissions

    return (
        <>
            {permissionsTranslator.map((role) => {
                const currentRole = permissions[role.id]
                if (!currentRole) {
                    return null
                }

                return (
                    <Columns
                        mb="32px"
                        gap="8px"
                        key={role.label}
                    >
                        <Typography variant="subtitle1">{role.label}</Typography>
                        <Spacer
                            flexWrap="wrap"
                            width="75%"
                        >
                            {Object.entries(role.permissions).map(([name, value]) =>
                                currentRole[name] ? (
                                    <Chip
                                        size="small"
                                        label={value}
                                        key={value}
                                        sx={(theme) => {
                                            return {
                                                width: 'fit-content',
                                                background: alpha(theme.palette.text.primary, 0.08),
                                            }
                                        }}
                                    />
                                ) : null,
                            )}
                        </Spacer>
                    </Columns>
                )
            })}
        </>
    )
}

export default UserRolesPermissionsList

import { ReactElement } from 'react'

import { Identifier, useRecordContext } from 'react-admin'

import { UnitModel } from 'appTypes/models'
import { ResourceType, useOpenUtilityDrawer, useResource, UtilityDrawerEditor } from 'components'
import { UtilityDrawerEditorProps } from 'components/Drawer/UtilityDrawerEditor'

import { unitSerializer } from '../config/constants'

import UnitForm from './UnitForm'

interface UnitDrawerTogglerProps extends Pick<UtilityDrawerEditorProps, 'onSuccess'> {
    children: (params: { onClick: () => void }) => ReactElement
    id?: Identifier
    title?: string
    resource?: ResourceType
}

const UnitDrawerToggler = ({
    children,
    id,
    title = 'Create Unit',
    resource: resourceProp,
    onSuccess,
}: UnitDrawerTogglerProps) => {
    const open = useOpenUtilityDrawer()
    const resource = useResource(resourceProp)

    return children({
        onClick: () => {
            open({
                extraArgs: {
                    resource,
                    id,
                },
                drawerArgs: {
                    title,
                    renderWrapper: (props) => (
                        <UtilityDrawerEditor
                            onSuccess={onSuccess}
                            {...props}
                            serializer={unitSerializer}
                        />
                    ),
                    renderContent: () => <UnitForm />,
                },
            })
        },
    })
}

export const UnitEditDrawerToggler = ({ children }: Pick<UnitDrawerTogglerProps, 'children'>) => {
    const record = useRecordContext<UnitModel>() || ({} as UnitModel)

    return (
        <UnitDrawerToggler
            children={children}
            id={record.id}
            title="Edit Details"
        />
    )
}

export default UnitDrawerToggler

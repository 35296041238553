import { FC, ReactNode } from 'react'

import { Box, BoxProps, SxProps, Theme } from '@mui/material'

import { FixedTopPortal } from 'pages/layouts/FixedTopPart'

export interface HeaderProps {
    children: ReactNode
    bgColor?: BoxProps['bgcolor']
    fixed?: boolean
    sx?: SxProps<Theme>
}

const Header: FC<HeaderProps> = ({ children, bgColor = 'white', fixed, sx }) => {
    const content = (
        <Box
            width="100%"
            bgcolor={bgColor}
            sx={sx}
        >
            {children}
        </Box>
    )

    if (fixed) {
        return <FixedTopPortal>{content}</FixedTopPortal>
    }

    return content
}

export default Header

import { Switch } from '@mui/material'

import { BoxContainer, Typography } from 'components'

import { useSyncedStateContext } from '../utils/SyncedStateContext'

interface ToSyncSwitchProps {
    id: string
    toSync: boolean
}

const ToSyncSwitch = ({ id, toSync }: ToSyncSwitchProps) => {
    const { synced, handleSyncedChange } = useSyncedStateContext()
    const checked = synced[id] ?? toSync
    return (
        <BoxContainer component="span">
            <Switch
                size="small"
                color="success"
                checked={checked}
                onChange={(e) =>
                    handleSyncedChange({
                        [id]: e.target.checked,
                    })
                }
            />
            <Typography
                variant="body2"
                component="span"
            >
                {checked ? 'Yes' : 'No'}
            </Typography>
        </BoxContainer>
    )
}

export default ToSyncSwitch

import { FC } from 'react'

import { Role } from 'appTypes/models'
import { InfoCard, InfoCardHeader, Typography } from 'components'

import { ViewMoreAction } from './UserRoleActions'

type UserRoleCardProps = {
    role: Role
}

const UserRoleCard: FC<UserRoleCardProps> = ({ role }) => {
    return (
        <InfoCard
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
                height: '90px',
            }}
        >
            <InfoCardHeader
                title={<Typography variant="subtitle2">{role.label}</Typography>}
                noDivider
                action={<ViewMoreAction role={role} />}
            />
            <Typography
                variant="body2"
                color="text.secondary"
            >
                {role.membersCount} Members
            </Typography>
        </InfoCard>
    )
}

export default UserRoleCard

import { PropsWithChildren } from 'react'

import { SvgIcon } from '@mui/material'

import { BoxContainer, Typography } from 'components'

interface MonitorTitleProps extends PropsWithChildren {
    icon?: typeof SvgIcon
}
const MonitorTitle = ({ icon, children }: MonitorTitleProps) => {
    return (
        <BoxContainer gap="6px">
            {icon && (
                <SvgIcon
                    component={icon}
                    sx={{ width: '16px', height: '16px', color: 'text.secondary' }}
                />
            )}
            <Typography
                variant="chartTitle"
                color="text.secondary"
                sx={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    maxWidth: '110px',
                    overflow: 'hidden',
                }}
            >
                {children}
            </Typography>
        </BoxContainer>
    )
}

export default MonitorTitle

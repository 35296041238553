import { Routes, useLocation } from 'react-router-dom'

import { tabRoutes } from 'components/router/LinkTabs'
import { urls } from 'configs/config'

import Global from './Global'
import Integrations from './Integrations'
import IntegrationsUnitPage from './Integrations/IntegrationsUnitList/IntegrationsUnitPage'
import { Label as IntegrationsLabel } from './Integrations/components'
import { TagsManagement } from './TagsManagement'
import { ConfigureHeader } from './components'

const { tabs, routes } = tabRoutes([
    {
        value: '',
        label: 'Global',
        element: <Global />,
    },
    {
        value: 'integrations',
        label: <IntegrationsLabel />,
        element: <Integrations />,
    },
    {
        value: 'sync-vehicles/:telematic',
        label: 'Sync Vehicles',
        element: <IntegrationsUnitPage />,
        tabHidden: true,
    },
    {
        value: 'tags-management',
        label: 'Tags Management',
        element: <TagsManagement />,
    },
])

const Configure = () => {
    const { pathname } = useLocation()

    return (
        <>
            {pathname.includes(urls.configure + '/sync-vehicles') || (
                <ConfigureHeader tabs={tabs} />
            )}
            <Routes>{routes}</Routes>
        </>
    )
}

export default Configure

import { FC } from 'react'

import { Settings, Sync } from '@mui/icons-material'
import LinkOffOutlinedIcon from '@mui/icons-material/LinkOffOutlined'
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined'
import { Grid, SvgIcon, Tooltip } from '@mui/material'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'

import {
    BoxContainer,
    InfoCard,
    StatusText,
    StatusTextProps,
    Typography,
    Spacer,
    InfoCardHeader,
    InfoCardDetails,
    Columns,
    Button,
} from 'components'
import { useCompanyTaskSync } from 'components/Sync'
import Icons from 'components/icons'
import { Telematics, TelematicsExtra } from 'components/telematics/hooks/useTelematics'
import { urls } from 'configs/config'
import { useConfirm } from 'hooks'

import ConnectedCardContent from './ConnectedCardContent'
import SyncingCardContent from './SyncingCardContent'
import useIntegrationDrawerTogler from './useIntegrationDrawerTogler'

export interface ItemPropTypes {
    item: TelematicsExtra & Telematics
    refetch: () => void
    onDisconnect: () => void
    onManualSync: () => void
}
const Item: FC<ItemPropTypes> = observer(({ item, onDisconnect, onManualSync, refetch }) => {
    const openDrawer = useIntegrationDrawerTogler()
    const navigate = useNavigate()
    const confirm = useConfirm()
    const { logo: image, connectUrl, provider: name, isActive, error } = item
    const navigateToManagePath = urls.configure + '/sync-vehicles/' + item.provider

    const syncing = useCompanyTaskSync((tasks) => {
        return tasks.some((item) => item.startsWith(name))
    })

    let statusProps: StatusTextProps = {
        children: 'Not Connected',
        statusColor: (theme) => theme.palette.text.secondary,
    }

    if (syncing) {
        statusProps = {
            children: 'Sync in Progress',
            statusColor: (theme) => theme.palette.primary.main,
        }
    } else if (isActive) {
        if (error) {
            statusProps = {
                children: 'Error',
                statusColor: (theme) => theme.palette.charts.red,
            }
        } else {
            statusProps = {
                children: 'Connected',
                statusColor: (theme) => theme.palette.charts.greenBody,
            }
        }
    }
    return (
        <Grid item>
            <InfoCard
                sx={{
                    height: '100%',
                    width: '285px',
                    minHeight: '146px',
                }}
            >
                <Columns
                    justifyContent="space-between"
                    height="100%"
                >
                    <InfoCardHeader
                        noDivider
                        actions={(params, { children }) =>
                            isActive
                                ? [
                                      syncing
                                          ? undefined
                                          : children({
                                                Icon: Sync,
                                                key: 'manual-sync',
                                                title: 'Manual Sync',
                                                onClick: onManualSync,
                                            }),
                                      syncing
                                          ? undefined
                                          : children({
                                                Icon: Settings,
                                                key: 'manage',
                                                disabled: Boolean(error),
                                                title: 'Manage',
                                                onClick: () => {
                                                    navigate(navigateToManagePath)
                                                },
                                            }),

                                      children({
                                          Icon: LinkOffOutlinedIcon,
                                          key: 'disconnect',
                                          title: 'Disconnect',
                                          onClick: () =>
                                              confirm({
                                                  title: 'Are you sure you want to Disconnect?',
                                                  content:
                                                      "Your units will remain in Fleetpal's system.",
                                                  confirmButtonProps: {
                                                      startIcon: <LinkOffOutlinedIcon />,
                                                      children: 'Disconnect',
                                                      color: 'error',
                                                      variant: 'text',
                                                  },
                                                  onConfirm: onDisconnect,
                                              }),
                                          itemColor: (theme) => theme.palette.error.main,
                                      }),
                                  ]
                                : []
                        }
                        title={
                            <BoxContainer
                                justifyContent="space-between"
                                // TODO: card actions should have some space from the left side
                                mr={isActive ? '8px' : undefined}
                            >
                                <Spacer>
                                    <SvgIcon
                                        inheritViewBox
                                        sx={{
                                            width: '24px',
                                            height: '24px',
                                            maxWidth: '100%',
                                        }}
                                        component={image}
                                    />
                                    <Typography
                                        variant="subtitle1"
                                        textTransform="capitalize"
                                    >
                                        {name}
                                    </Typography>
                                </Spacer>
                                <BoxContainer>
                                    {error ? (
                                        <Tooltip title={error}>
                                            <SvgIcon
                                                component={Icons.InfoOutlined}
                                                sx={{
                                                    width: '16px',
                                                    height: '16px',
                                                    mr: '4px',
                                                    color: statusProps.statusColor,
                                                }}
                                            />
                                        </Tooltip>
                                    ) : null}
                                    <StatusText {...statusProps} />
                                </BoxContainer>
                            </BoxContainer>
                        }
                    />
                    <InfoCardDetails
                        details={
                            (syncing && <SyncingCardContent />) ||
                            (isActive ? (
                                <ConnectedCardContent item={item} />
                            ) : (
                                <Button
                                    startIcon={<LinkOutlinedIcon />}
                                    variant="text"
                                    color="primary"
                                    size="medium"
                                    component={isActive ? 'div' : 'a'}
                                    href={isActive ? null : connectUrl}
                                    onClick={isActive ? () => openDrawer(item, refetch) : null}
                                >
                                    Connect
                                </Button>
                            ))
                        }
                        propRecord={{ id: name }}
                    />
                </Columns>
            </InfoCard>
        </Grid>
    )
})

export default Item

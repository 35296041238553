import { useLayoutEffect, useState } from 'react'

import FlashOffOutlinedIcon from '@mui/icons-material/FlashOffOutlined'
import FlashOnOutlinedIcon from '@mui/icons-material/FlashOnOutlined'

import { Button } from 'components'

import { useQRScannerContext } from './QRScannerContent'

const QRScannerFlasButton = () => {
    const [flash, setFlash] = useState<boolean | null>(null)
    const { scanner, activeCamera } = useQRScannerContext()

    useLayoutEffect(() => {
        scanner.hasFlash().then((hasFlash) => {
            setFlash(hasFlash ? scanner.isFlashOn() : null)
        })
    }, [activeCamera])

    if (flash === null) {
        return null
    }

    const toggle = () => {
        scanner.toggleFlash().then(() => setFlash(scanner.isFlashOn()))
    }

    return (
        <Button
            variant="contained"
            sx={{
                position: 'absolute',
                top: 20,
                left: 20,
            }}
            startIcon={flash ? <FlashOnOutlinedIcon /> : <FlashOffOutlinedIcon />}
            onClick={toggle}
        >
            Flash
        </Button>
    )
}

export default QRScannerFlasButton

import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton, LoadingButtonProps } from '@mui/lab'
import { ButtonProps } from '@mui/material'
import { useFormState, useFormContext } from 'react-hook-form'

import { useToggleFullPageLoader, specialErrorNames } from 'components'

const ConfirmButton = ({
    fullPageLoader,
    ...props
}: LoadingButtonProps & { fullPageLoader?: boolean }) => {
    const { isSubmitting, isValid } = useFormState()
    const { clearErrors } = useFormContext()

    const onClick: ButtonProps['onClick'] = (event) => {
        props.onClick?.(event)
        if (props.type === 'submit') {
            clearErrors(specialErrorNames)
        }
    }

    useToggleFullPageLoader({ isOpen: Boolean(fullPageLoader && isSubmitting) })

    return (
        <LoadingButton
            disabled={!isValid}
            loadingPosition={isSubmitting ? 'start' : undefined}
            loading={isSubmitting}
            // mui requires startIcon when loadingPosition = "start" is used. Hack
            startIcon={isSubmitting ? <CloseIcon sx={{ opacity: '0 !important' }} /> : undefined}
            size="large"
            children="OK"
            onClick={onClick}
            {...props}
        />
    )
}
export default ConfirmButton

import { useLayoutEffect, useState } from 'react'

type AllowedWidgetDimensions = 'sm' | 'mdX' | 'mdY' | 'lg'

export const checkWidgetDimensions = (
    dimension: AllowedWidgetDimensions,
    allowedDimensions: AllowedWidgetDimensions[],
) => {
    if (!dimension) {
        return true
    }
    return allowedDimensions.some((v) => v === dimension)
}

const useCollapseWidget = (ref: React.MutableRefObject<HTMLDivElement>) => {
    const [widgetDimension, setWidgetDimension] = useState<AllowedWidgetDimensions>('lg')

    useLayoutEffect(() => {
        const observer = new ResizeObserver((entries) => {
            const currHeight = entries[0].contentRect.height
            const currWidth = entries[0].contentRect.width
            if (currHeight < 160 && currWidth < 300) {
                setWidgetDimension('sm')
            }
            if (currHeight < 160 && currWidth >= 300) {
                setWidgetDimension('mdX')
            }
            if (currHeight >= 160 && currWidth < 300) {
                setWidgetDimension('mdY')
            }
            if (currHeight >= 160 && currWidth >= 300) {
                setWidgetDimension('lg')
            }
        })
        observer.observe(ref.current)
        return () => {
            observer.disconnect()
        }
    }, [ref.current])
    return widgetDimension
}
export default useCollapseWidget

import { badgeClasses, BadgeProps } from '@mui/material'
import { alpha, styled } from '@mui/material/styles'

import BadgeText from 'components/badge/BadgeText'

const InfoBadge = styled((props: BadgeProps) => {
    if (typeof props.badgeContent === 'undefined') {
        return null
    }
    return (
        <BadgeText
            {...props}
            badgeContent={String(props.badgeContent)}
        />
    )
})<BadgeProps>`
    & .${badgeClasses.badge} {
        background-color: ${({ theme }) => alpha(theme.palette.text.main, 0.08)};
    }
`

InfoBadge.defaultProps = {
    max: Infinity,
    variant: 'standard',
}

export default InfoBadge

import { ReactNode } from 'react'

import { RaRecord } from 'react-admin'

import { ListTotalBadge, ListTotalBadgeProps } from 'components'
import Header from 'components/pages/header'
import { urls } from 'configs/config'

interface ReportsHeaderProps<RecordType extends RaRecord = RaRecord> {
    children: string
    renderTotal?: ListTotalBadgeProps<RecordType>['renderContent']
    aside?: ReactNode
}

const ReportsHeader = <RecordType extends RaRecord = RaRecord>({
    children,
    renderTotal,
    aside,
}: ReportsHeaderProps<RecordType>) => {
    return (
        <Header>
            <Header.Block>
                <Header.BackButton to={urls.root} />
                <Header.Main>
                    <Header.Spacer
                        sx={(theme) => ({
                            [theme.breakpoints.down('sm')]: {
                                flexDirection: 'column',
                                gap: 0,
                                alignItems: 'flex-start',
                            },
                        })}
                    >
                        <Header.Title>{children}</Header.Title>
                        <ListTotalBadge renderContent={renderTotal} />
                    </Header.Spacer>
                </Header.Main>
                {aside && <Header.Aside>{aside}</Header.Aside>}
            </Header.Block>
        </Header>
    )
}

export default ReportsHeader

import { Identifier } from 'react-admin'

import { SortType } from 'appTypes'
import { VendorModel } from 'appTypes/models'
import { CardListConfig, DatagridColumnsProps, PageContent } from 'components'
import { DatagridLink } from 'components/Datagrid'
import { ListSortContentProps } from 'components/list/ListSortDrawerContent'
import { FilterConfig } from 'components/list/filter/ListFilterForm'
import ListFilterRangeInput from 'components/list/filter/ListFilterRangeInput'
import ListFilterValueInput from 'components/list/filter/ListFilterValueInput'
import ResourceContextProviderWithClearEffect from 'components/resource/ResourceContextProviderWithClearEffect'
import { urls } from 'configs/config'
import { vendorFilters } from 'pages/Vendors/List/VendorsList'
import {
    vendorAddressInfo,
    vendorsResource,
    vendorTypesObject,
} from 'pages/Vendors/constants/config'
import { costMaskParams, resolveDoublePriceMask } from 'utils'

import { ListBaseWithPeriod, ListWithPeriodSelector, ReportsHeader } from '../components'
import LinkArrowButton from '../components/LinkArrowButton'

const defaultSort: SortType<VendorModel> = {
    field: 'total',
    order: 'DESC',
}

const sortCfg: ListSortContentProps<VendorModel> = {
    sortBy: [
        { id: 'name', label: 'Company Name' },
        { id: 'type', label: 'Vendor Type' },
        { id: 'totalParts', label: 'Parts' },
        { id: 'totalLabor', label: 'Labor' },
        { id: 'totalServices', label: 'Services' },
        { id: 'totalFees', label: 'Fees' },
        { id: 'totalTax', label: 'Tax' },
        { id: 'total', label: 'Total Expenses' },
    ],
}

const navigateTo = (id: Identifier) => `${urls.vendors}/${id}`

const cardsCfg: CardListConfig<VendorModel> = {
    titleSource: 'name',
    titleLink: (record) => navigateTo(record.id),
    defaultImage: null,
    action: (record) => <LinkArrowButton path={navigateTo(record.id)} />,
    details: [
        {
            source: 'type',
            label: 'Vendor Type',
            render: (value) => vendorTypesObject[value]?.name,
        },
        {
            source: 'totalParts',
            label: 'Parts',
            render: resolveDoublePriceMask,
        },
        {
            source: 'totalLabor',
            label: 'Labor',
            render: resolveDoublePriceMask,
        },
        {
            source: 'totalServices',
            label: 'Services',
            render: resolveDoublePriceMask,
        },
        {
            source: 'totalFees',
            label: 'Fee',
            render: resolveDoublePriceMask,
        },
        {
            source: 'totalTax',
            label: 'Tax',
            render: resolveDoublePriceMask,
        },
        {
            source: 'total',
            label: 'Total Expenses',
            render: resolveDoublePriceMask,
        },
    ],
}

const columnsCfg: DatagridColumnsProps<VendorModel> = {
    columns: [
        {
            field: 'name',
            headerName: 'Company Name',
            renderCell: (params) => {
                return <DatagridLink {...params} />
            },
        },
        {
            field: 'type',
            headerName: 'Vendor Type',
            valueFormatter: ({ value }) => vendorTypesObject[value]?.name,
        },
        {
            field: 'totalParts',
            headerName: 'Parts',
            valueFormatter: ({ value }) => resolveDoublePriceMask(value),
            align: 'right',
            headerAlign: 'right',
        },
        {
            field: 'totalLabor',
            headerName: 'Labor',
            valueFormatter: ({ value }) => resolveDoublePriceMask(value),
            align: 'right',
            headerAlign: 'right',
        },
        {
            field: 'totalServices',
            headerName: 'Services',
            valueFormatter: ({ value }) => resolveDoublePriceMask(value),
            align: 'right',
            headerAlign: 'right',
        },
        {
            field: 'totalFees',
            headerName: 'Fees',
            valueFormatter: ({ value }) => resolveDoublePriceMask(value),
            align: 'right',
            headerAlign: 'right',
        },
        {
            field: 'totalTax',
            headerName: 'Tax',
            valueFormatter: ({ value }) => resolveDoublePriceMask(value),
            align: 'right',
            headerAlign: 'right',
        },
        {
            field: 'total',
            headerName: 'Total Expenses',
            valueFormatter: ({ value }) => resolveDoublePriceMask(value),
            align: 'right',
            headerAlign: 'right',
        },
        {
            field: 'city',
            headerName: vendorAddressInfo.label,
            renderCell: ({ row }) => vendorAddressInfo.value(row),
            toExport: {
                separate: [
                    {
                        field: 'city',
                        headerName: 'City',
                    },
                    {
                        field: 'state',
                        headerName: 'State',
                    },
                    {
                        field: 'zipCode',
                        headerName: 'Zip',
                    },
                ],
            },
        },
    ],
    resetColumns: {
        city: false,
    },
    actions: null,
    checkboxSelection: false,
}

const filtersCfg: FilterConfig<VendorModel> = {
    filters: [
        { id: 'name', label: 'Company Name' },
        {
            id: 'type',
            label: 'Vendor Type',
            renderComponent: (props) => (
                <ListFilterValueInput
                    {...props}
                    makeItemLabel={(record) => vendorTypesObject[record.id]?.name}
                />
            ),
        },
        vendorFilters.state,
        { id: 'city', label: 'Vendor City' },
        { id: 'zipCode', label: 'Vendor Zip' },
        vendorFilters.paymentMethod,
        vendorFilters.paymentTerm,
        {
            id: 'totalParts',
            label: 'Parts',
            filterType: 'range',
            renderComponent: (props) => (
                <ListFilterRangeInput
                    inputProps={costMaskParams}
                    {...props}
                />
            ),
        },
        {
            id: 'totalLabor',
            label: 'Labor',
            filterType: 'range',
            renderComponent: (props) => (
                <ListFilterRangeInput
                    inputProps={costMaskParams}
                    {...props}
                />
            ),
        },
        {
            id: 'totalServices',
            label: 'Services',
            filterType: 'range',
            renderComponent: (props) => (
                <ListFilterRangeInput
                    inputProps={costMaskParams}
                    {...props}
                />
            ),
        },
        {
            id: 'totalFees',
            label: 'Fee',
            filterType: 'range',
            renderComponent: (props) => (
                <ListFilterRangeInput
                    inputProps={costMaskParams}
                    {...props}
                />
            ),
        },
        {
            id: 'totalTax',
            label: 'Tax',
            filterType: 'range',
            renderComponent: (props) => (
                <ListFilterRangeInput
                    inputProps={costMaskParams}
                    {...props}
                />
            ),
        },
        {
            id: 'total',
            label: 'Total Expenses',
            filterType: 'range',
            renderComponent: (props) => (
                <ListFilterRangeInput
                    inputProps={costMaskParams}
                    {...props}
                />
            ),
        },
    ],
}

const preferencesResource = {
    ...vendorsResource,
    name: 'reports-vendor-expenses',
}

const VendorExpenses = () => {
    return (
        <ResourceContextProviderWithClearEffect value={vendorsResource}>
            <ListBaseWithPeriod
                preferencesResource={preferencesResource}
                sort={defaultSort}
                filter={{ withAspects: ['totals'] }}
            >
                <ReportsHeader<VendorModel>
                    renderTotal={({ total, hasPreviousPage }) =>
                        `${total} | ${resolveDoublePriceMask(
                            (hasPreviousPage as any).aggregates.totalQueryset || 0,
                        )} total`
                    }
                >
                    Total Vendor Expenses
                </ReportsHeader>
                <PageContent>
                    <ListWithPeriodSelector
                        exportFileName="total-vendor-expenses"
                        preferencesResource={preferencesResource}
                        sortCfg={sortCfg}
                        cardsCfg={cardsCfg}
                        columnsCfg={columnsCfg}
                        filtersCfg={filtersCfg}
                    />
                </PageContent>
            </ListBaseWithPeriod>
        </ResourceContextProviderWithClearEffect>
    )
}

export default VendorExpenses

import { ReactElement } from 'react'

import { NotificationOptions, NotificationType } from 'react-admin'

import { updatedMessage } from 'configs/constants'

import useErrorHandler from './useErrorHandler'
import useNotify from './useNotify'

export interface UseSubmitExtraArgs<T = any, R = any> {
    successMessage?:
        | ((params: {
              response: T
              formData: R
              defaultMessages: {
                  created: string
                  updated: string
              }
          }) => ReactElement | string)
        | string
        | ReactElement
    successMessageType?: NotificationType
    successMessageArgs?: NotificationOptions['messageArgs']
}

const useSubmit = <ReqData = any, RespData = any>(
    submit: (formData: ReqData) => Promise<RespData>,
    {
        successMessage,
        successMessageType = 'success',
        successMessageArgs,
    }: UseSubmitExtraArgs<RespData, ReqData> = {},
) => {
    const notify = useNotify()
    const errorHandler = useErrorHandler({
        anonymous: true,
    })
    return async (formData: ReqData) => {
        try {
            const response = await submit(formData)
            const message =
                typeof successMessage === 'function'
                    ? successMessage({
                          response,
                          formData,
                          defaultMessages: {
                              created: 'Successfully created',
                              updated: updatedMessage,
                          },
                      })
                    : successMessage
            if (message) {
                notify(message, {
                    type: successMessageType,
                    undoable: false,
                    messageArgs: successMessageArgs,
                })
            }
        } catch (error) {
            return await errorHandler(error).then((isErrorHandled) => {
                return isErrorHandled ? {} : error
            })
        }
    }
}

export default useSubmit

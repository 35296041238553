import { useRef } from 'react'

import { InfoOutlined } from '@mui/icons-material'
import { useRecordContext } from 'react-admin'

import { WOJobItemTypesUnion, WorkOrderModel } from 'appTypes/models'
import { BoxContainer, Typography } from 'components'
import { useResizeObserverWidth } from 'hooks'
import { useWidgetContext } from 'pages/Dashboard/components/GridLayout'
import { SliderWidget, SliderWidgetSkeleton } from 'pages/Dashboard/components/WidgetBase/styled'
import { checkWidgetDimensions } from 'pages/Dashboard/components/WidgetBase/useCollapseWidget'
import { unitGetRoundedPercents } from 'pages/Dashboard/components/Widgets/CostPerUnitWidget'
import {
    Clarification,
    CostPerUnitChart,
} from 'pages/Dashboard/components/Widgets/CostPerUnitWidget/components'
import { JobItemsCalculations } from 'pages/Dashboard/components/Widgets/components/types'
import { resolveDoublePriceMask } from 'utils'

import LineItemsTooltip from '../Jobs/WorkOrderJobCard/components/LineItemsTooltip'

export interface ItemInfoType
    extends Record<WOJobItemTypesUnion, { color: string; total: number }> {}

export const AcrossAllItemsTooltip = ({
    tooltipData,
}: {
    tooltipData: Partial<JobItemsCalculations>
}) => {
    const context = useWidgetContext()
    return (
        <BoxContainer alignItems="center">
            <LineItemsTooltip data={tooltipData}>
                <Typography
                    component={BoxContainer}
                    color="text.secondary"
                    ml="5px"
                    variant="chartListItem"
                >
                    <BoxContainer
                        fontSize={
                            checkWidgetDimensions(context?.dimensions, ['sm', 'mdY'])
                                ? '10px'
                                : '12px'
                        }
                    >
                        <InfoOutlined sx={{ width: '10px' }} />
                        &nbsp;Across All Line Items
                    </BoxContainer>
                </Typography>
            </LineItemsTooltip>
        </BoxContainer>
    )
}

const WorkOrderFinancialInfo = () => {
    const elementRef = useRef()
    const isUnderWidthPoint = useResizeObserverWidth(elementRef, 360)

    const record = useRecordContext<WorkOrderModel>()
    if (!record) {
        return <SliderWidgetSkeleton />
    }
    const ratioData = unitGetRoundedPercents(record)
    const validData = ratioData.total > 0

    return (
        <SliderWidget
            ref={elementRef}
            headerProps={{
                title: 'Total Cost',
                label:
                    typeof ratioData.total === 'number'
                        ? resolveDoublePriceMask(ratioData.total)
                        : null,
                clarification: (
                    <Clarification
                        {...ratioData}
                        showTooltip={isUnderWidthPoint}
                        validData={validData}
                    />
                ),
                chart: (
                    <CostPerUnitChart
                        {...ratioData}
                        validData={validData}
                    />
                ),
            }}
        />
    )
}
export default WorkOrderFinancialInfo

import { useWatch, useFormContext } from 'react-hook-form'

import { TextInputProps } from 'components'
import { useDidUpdate } from 'hooks'

import useInputSource from './useInputSource'

interface UnitPMFMeterCalcThresholdProps extends Partial<TextInputProps> {
    getValue?: (num: number) => number
    getThresholdValue?: (num: number) => number
    dependencies?: any[]
}

const UnitPMFMeterCalcThreshold = ({
    getValue,
    getThresholdValue,
    dependencies = [],
}: UnitPMFMeterCalcThresholdProps) => {
    const source = useInputSource()
    const intervalValue = useWatch({ name: source('value') })
    const { setValue } = useFormContext()

    useDidUpdate(() => {
        const value = Number(intervalValue)
        if (isNaN(value)) {
            return
        }
        const thresholdValue = (getValue ? getValue(value) : value) * (20 / 100)
        setValue(
            source('threshold'),
            Math.ceil(getThresholdValue ? getThresholdValue(thresholdValue) : thresholdValue),
            {
                shouldValidate: true,
                shouldDirty: true,
                shouldTouch: true,
            },
        )
    }, [intervalValue, ...dependencies])

    return null
}

export default UnitPMFMeterCalcThreshold

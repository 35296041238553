import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined'

import { StatusText, useTelematics } from 'components'
import { urls } from 'configs/config'

import { Monitor } from '../../MonitorComponents'

import ConnectButton from './ConnectButton'

const MonitorIntegrations = () => {
    const { data, errorCount, runningCount } = useTelematics()
    if (!data || !data.length) {
        return <Monitor.Skeleton />
    }

    const navigateTo = urls.configure + '/integrations'
    const telematicsCount = data.length

    return (
        <Monitor>
            <Monitor.Title icon={WorkspacesOutlinedIcon}>Integrations</Monitor.Title>

            <Monitor.ArrowButton navigateTo={navigateTo} />
            <Monitor.Container>
                <Monitor.ColumnsContainer>
                    <Monitor.ValueText>{errorCount}</Monitor.ValueText>
                    <StatusText
                        sx={{ width: 'fit-content' }}
                        statusColor={(theme) => theme.palette.charts.red}
                    >
                        Error
                    </StatusText>
                </Monitor.ColumnsContainer>
                <Monitor.ColumnsContainer>
                    <Monitor.ValueText>{runningCount}</Monitor.ValueText>
                    <StatusText
                        sx={{ width: 'fit-content' }}
                        statusColor={(theme) => theme.palette.charts.greenBody}
                    >
                        Running
                    </StatusText>
                </Monitor.ColumnsContainer>
                {telematicsCount ? null : <ConnectButton navigateTo={navigateTo} />}
            </Monitor.Container>
        </Monitor>
    )
}
export default MonitorIntegrations

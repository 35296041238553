import { ReactElement } from 'react'

import { Link } from 'react-router-dom'

import { MeterModel } from 'appTypes/models'
import { Typography } from 'components'
import { useCreateResourcePath } from 'hooks'
import { woResource } from 'pages/WorkOrders/config/constants'
import { prettyText } from 'utils'

const MeterSource = ({ meter }: { meter: MeterModel }): ReactElement => {
    const createPath = useCreateResourcePath()
    if (meter.source === 'MANUAL') {
        return <>{prettyText(meter.source)} Entry</>
    } else if (meter.source === 'WORK_ORDER') {
        return (
            <Typography
                component={Link}
                color="primary.main"
                variant="inherit"
                to={createPath({
                    resource: woResource.resource,
                    id: meter.workOrder.id,
                    type: 'edit',
                })}
            >
                {meter.workOrder.number}
            </Typography>
        )
    }
    return <>Integration - {prettyText(meter.source)}</>
}

export default MeterSource

export const meterSourceAsText = (source: MeterModel['source']) => {
    if (source === 'MANUAL') {
        return 'Manual Entry'
    }
    if (source === 'WORK_ORDER') {
        return 'Work Orders'
    }
    return `Integration - ${prettyText(source)}`
}

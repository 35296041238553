import { createContext, useContext, useEffect, useRef, useState } from 'react'

import QrScanner from 'qr-scanner'

import { useNotify } from 'hooks'

import { useQRScannerModalContext } from './QRScanner'
import QRScannerChangeCameraButton from './QRScannerChangeCameraButton'
import QRScannerCloseButton from './QRScannerCloseButton'
import QRScannerFileButton from './QRScannerFileButton'
import QRScannerFlasButton from './QRScannerFlashButton'
import QRScannerMenu from './QRScannerMenu'

export interface QRScannerContentProps {
    onDecode: (result: QrScanner.ScanResult) => void
}

interface QRScannerContextResult {
    scanner: QrScanner
    activeCamera: string
    setActiveCamera: (camera: string) => void
    onDecode: (result: QrScanner.ScanResult) => void
}

const QRScannerContext = createContext<QRScannerContextResult>(null)
export const useQRScannerContext = () => useContext(QRScannerContext)

export const qrScannerDefaultCamera = 'environment'

const QRScannerContent = ({ onDecode }: QRScannerContentProps) => {
    const ref = useRef(null)
    const [scanner, setScanner] = useState<QrScanner | false>(null)
    const [activeCamera, setActiveCamera] = useState<string>(qrScannerDefaultCamera)
    const { close } = useQRScannerModalContext()
    const notify = useNotify()

    useEffect(() => {
        let scanner: QrScanner

        QrScanner.hasCamera().then((hasCamera) => {
            if (!hasCamera) {
                notify('No Camera found!', { type: 'error' })
                close()
                return
            }

            scanner = new QrScanner(ref.current, onDecode, {
                highlightScanRegion: true,
                highlightCodeOutline: true,
                maxScansPerSecond: 10,
            })

            scanner
                .start()
                .then(() => {
                    setScanner(scanner)
                })
                .catch((err) => {
                    notify(
                        (typeof err === 'string' ? err : 'Something went wrong.') +
                            ' Check your Camera Permissions',
                        { type: 'error' },
                    )
                    close()
                })
        })

        return () => {
            scanner?.stop()
        }
    }, [])

    return (
        <>
            <video ref={ref} />
            <QRScannerContext.Provider
                value={{ scanner: scanner as QrScanner, activeCamera, setActiveCamera, onDecode }}
            >
                {scanner ? (
                    <>
                        <QRScannerCloseButton />
                        <QRScannerFlasButton />
                        <QRScannerMenu>
                            <QRScannerChangeCameraButton />
                            <QRScannerFileButton />
                        </QRScannerMenu>
                    </>
                ) : null}
            </QRScannerContext.Provider>
        </>
    )
}

export default QRScannerContent

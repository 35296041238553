import { Button } from '@mui/material'

import { PartModel } from 'appTypes/models'
import { DialogSelector } from 'components'
import Icons from 'components/icons'
import { DialogSelectorProps } from 'components/inputs/DialogSelector/DialogSelector'

import { PartDrawerToggler } from '../components'
import { partsResource, partsResourceName } from '../config/constants'

const PartSelector = ({
    reference = partsResourceName,
    successMessage,
    ...props
}: Omit<
    DialogSelectorProps,
    | 'reference'
    | 'titleSource'
    | 'defaultSelectorProps'
    | 'filter'
    | 'required'
    | 'itemPrimary'
    | 'itemSecondary'
    | 'renderNoResults'
    | 'renderAboveList'
> & { reference?: string; successMessage?: string }) => {
    return (
        <DialogSelector<PartModel>
            titleSource={(record) => (record ? record.number : 'Parts')}
            reference={reference}
            defaultSelectorProps={{
                label: 'Part No',
            }}
            filter={{
                search: {
                    placeholder: 'Search by Part Number or Description',
                },
            }}
            required
            itemPrimary="number"
            itemSecondary={(record) => [
                record.description,
                `VMRS: ${record.componentData.code} - ${record.componentData.text}`,
                record.manufacturerData ? record.manufacturerData.description : '',
            ]}
            noResults={({ searchValue }) => {
                if (!searchValue) {
                    return {
                        title: 'No Parts Added',
                        text: 'To attach a part to this WO, you need to go the Parts page and add your first part.',
                        image: (images) => images.listEmpty,
                        imageWidth: '180px',
                    }
                }
            }}
            renderNoResults={({ filterValues }) => (filterValues.recentlyUsed ? null : undefined)}
            renderAboveList={({ onSelect, control }) => (
                <PartDrawerToggler
                    successMessage={successMessage}
                    title="Create New Part"
                    onSuccess={(record) => {
                        onSelect(record.id)
                        control.refetch()
                    }}
                    resource={partsResource}
                    children={({ onClick }) =>
                        control.isFetching ? null : (
                            <Button
                                startIcon={<Icons.Add />}
                                variant="contained"
                                onClick={onClick}
                                sx={{ mt: '20px' }}
                            >
                                Create New Part
                            </Button>
                        )
                    }
                />
            )}
            {...props}
        />
    )
}

export default PartSelector

import { PropsWithChildren } from 'react'

import { AdminContext as RAAdminContext, DataProvider } from 'react-admin'
// import { ReactQueryDevtools } from 'react-query/devtools'

import {
    authProvider as authProviderConstructor,
    dataProvider as dataProviderConstructor,
    i18nProvider,
    authStore,
    queryClientProvider,
    storeProvider,
} from 'providers'
import getTheme from 'theme'

const dataProvider = dataProviderConstructor(authStore)
const authProvider = authProviderConstructor(authStore)
const AdminContext = ({ children }: PropsWithChildren) => {
    return (
        <RAAdminContext
            dataProvider={dataProvider as DataProvider}
            authProvider={authProvider}
            theme={getTheme('light')}
            i18nProvider={i18nProvider}
            store={storeProvider}
            queryClient={queryClientProvider}
        >
            {children}
        </RAAdminContext>
    )
}
export default AdminContext

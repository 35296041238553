import { Button, PaletteMode, useMediaQuery } from '@mui/material'
import { useTheme } from 'react-admin'

import getTheme from 'theme'
import { capitalize } from 'utils'

const ThemeButton = ({ mode }: { mode: PaletteMode | 'system' }) => {
    const [, setTheme] = useTheme()
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
    const preferedMode = prefersDarkMode ? 'dark' : 'light'

    return (
        <Button onClick={() => setTheme(getTheme(mode === 'system' ? preferedMode : mode))}>
            {capitalize(mode)}
        </Button>
    )
}
export default ThemeButton

import { Resource, ResourceProps } from 'react-admin'

import { useClearResource } from 'hooks'

import RaResourceContextProvider, { ResourceType } from './RaResourceContextProvider'

interface ResourceWithClearEffectProps extends Omit<ResourceProps, 'name'> {
    name: ResourceType
}

const ResourceWithClearEffect = ({ name, ...rest }: ResourceWithClearEffectProps) => {
    useClearResource(name.resource)

    return (
        <RaResourceContextProvider value={name}>
            <Resource
                {...rest}
                name={name.resource}
            />
        </RaResourceContextProvider>
    )
}

export default ResourceWithClearEffect

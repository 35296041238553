import { alpha } from '@mui/material'

import { TagModel } from 'appTypes/models'
import { Columns, Chip } from 'components'
import { ActionsMenu } from 'components/actions'
import TagField from 'pages/Configure/TagsManagement/components/TagField'

const TagsField = ({ tags, smallVariant }: { tags: TagModel[]; smallVariant?: boolean }) => {
    const tagsCount = tags?.length

    if (!tagsCount) {
        return <>-</>
    }
    return (
        <ActionsMenu
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            renderToggler={(open) => (
                <Chip
                    component="span"
                    sx={(theme) => ({
                        bgcolor: alpha(theme.palette.text.primary, 0.08),
                        color: theme.palette.text.primary,
                    })}
                    onClick={open}
                    label={`${tagsCount} Tag${tagsCount === 1 ? '' : 's'}`}
                    variant="filled"
                    size={smallVariant ? 'extraSmall' : 'small'}
                />
            )}
            actions={() => [
                <Columns
                    gap="6px"
                    px="16px"
                    key="tags"
                    alignItems="flex-start"
                >
                    {tags.map((tag) => (
                        <TagField
                            tag={tag}
                            key={tag.id}
                        />
                    ))}
                </Columns>,
            ]}
        />
    )
}

export default TagsField

import { Typography } from 'components'

const CollapsibleNotes = ({ note }: { note: string }) => {
    return (
        <Typography
            sx={{ overflowWrap: 'break-word' }}
            variant="body2"
        >
            {note}
        </Typography>
    )
}
export default CollapsibleNotes

import { FC } from 'react'

import { ButtonProps } from '@mui/material'

import { Button } from 'components'

export interface UtilityDrawerCloseButtonBaseProps extends ButtonProps {}

const UtilityDrawerCloseButtonBase: FC<UtilityDrawerCloseButtonBaseProps> = (props) => {
    return (
        <Button
            size="medium"
            variant="text"
            {...props}
            children={props.children ?? 'Close'}
        />
    )
}

export default UtilityDrawerCloseButtonBase

import { FC } from 'react'

import { Alert, AlertTitle } from '@mui/material'

import { AnchorLink, CenterText, Typography } from 'components'
import { urls } from 'configs/config'

import { TokenType } from './AcceptInvitation'

interface ExpiredInvitationProps {
    tokenCode: TokenType
}
type TokenMapType = Record<
    Exclude<TokenType, 'active' | 'invalid'>,
    { title: string; text: string }
>

const tokenMap: TokenMapType = {
    expired: {
        title: 'Link Expired',
        text: 'Your Invitation has expired.',
    },
    deactivated: {
        title: 'Access Denied',
        text: 'This Company has been deactivated.',
    },
}

const ExpiredInvitation: FC<ExpiredInvitationProps> = ({ tokenCode }) => {
    const config = tokenMap[tokenCode]
    return (
        <>
            <Alert
                variant="standard"
                severity="warning"
                sx={{ mb: '24px' }}
            >
                <AlertTitle>{config.title}</AlertTitle>
                <Typography
                    variant="body2"
                    color="inherit"
                >
                    {config.text}
                </Typography>
                <Typography
                    variant="body2"
                    color="inherit"
                >
                    Please, contact your administrator.
                </Typography>
            </Alert>
            <CenterText>
                <Typography display="inline">Don't have an account? </Typography>

                <AnchorLink to={urls.register}>Sign up!</AnchorLink>
            </CenterText>
        </>
    )
}

export default ExpiredInvitation

import { FC } from 'react'

import { useCreatePath } from 'react-admin'
import { useWatch } from 'react-hook-form'

import api from 'api'
import { MeterModel, UnitPMIntervalTypes, WorkOrderModel } from 'appTypes/models'
import { Columns, FormPreventSubmit } from 'components'
import { useQuery } from 'hooks'
import { getMetersResource } from 'pages/Units/Meters/components/UnitMetersResource'
import { dateTimeParse, pathJoin } from 'utils'

import { woResource } from '../../config/constants'

import LabelSwitch from './LabelSwitch'
import PmMeterInputs from './PmMeterInput'

export interface WoCloseGetData {
    defects: { id: string; name: string }[]
    pmSchedules: { id: string; name: string; types: UnitPMIntervalTypes[] }[]
}

export interface WoCloseDataType extends WoCloseGetData {
    meters: { [key in MeterModel['type']]?: MeterModel | null }
    requiredMeterTypes?: {
        [key in MeterModel['type']]?: MeterType
    }
}

export type MeterType = Partial<MeterModel> & { pmIds: string[] }

const WoCloseFields: FC<{ workOrder: WorkOrderModel }> = ({ workOrder }) => {
    const completed = useWatch({ name: 'completed' })

    const createPath = useCreatePath()

    const { data } = useQuery<WoCloseDataType>(
        ['wo-close-data', String(completed || '')],
        async () => {
            const woPath = createPath({
                resource: woResource.resource,
                type: 'edit',
                id: workOrder.id,
            })
            const { defects, pmSchedules }: WoCloseGetData = await api.get(woPath + '/close')

            const data: { currentMeters: { [key in MeterModel['type']]?: MeterModel | null } } =
                await api.get(pathJoin(getMetersResource(workOrder.unit), `current`), {
                    now: dateTimeParse(completed),
                })

            const requiredMeterTypes = pmSchedules.reduce((obj, pm) => {
                pm.types.forEach((type) => {
                    if (type === 'TIME') {
                        return
                    }
                    if (!obj[type]) {
                        obj[type] = { ...data.currentMeters[type], type, pmIds: [] }
                    }
                    obj[type].pmIds.push(pm.id)
                })
                return obj
            }, {} as WoCloseDataType['requiredMeterTypes'])

            return {
                defects,
                pmSchedules,
                meters: data.currentMeters,
                requiredMeterTypes,
            }
        },
        {
            cacheTime: 0,
        },
    )

    if (!data) {
        return <FormPreventSubmit />
    }

    const { defects, pmSchedules, requiredMeterTypes } = data

    return (
        <>
            {Object.keys(requiredMeterTypes).map((meter) => {
                return (
                    <PmMeterInputs
                        key={meter}
                        meter={requiredMeterTypes[meter]}
                    />
                )
            })}
            <Columns gap="13px">
                {defects.map((defect) => (
                    <LabelSwitch
                        label="dvir"
                        source={`dvir.${defect.id}`}
                        key={defect.id}
                    >
                        {`Resolve ${defect.name}`}
                    </LabelSwitch>
                ))}
                {pmSchedules.map((pm) => (
                    <LabelSwitch
                        label="pm"
                        source={`pm.${pm.id}`}
                        key={pm.id}
                    >
                        {`Reset ${pm.name}`}
                    </LabelSwitch>
                ))}
            </Columns>
        </>
    )
}

export default WoCloseFields

import { SvgIcon } from '@mui/material'

import { SortType } from 'appTypes'
import { MeterModel } from 'appTypes/models'
import {
    PageContent,
    ListBase,
    useArchivedContext,
    List,
    DatagridColumnsProps,
    CardListConfig,
    BoxContainer,
    Typography,
} from 'components'
import { FtuCreateText } from 'components/list/ListFTU'
import { ListSortContentProps } from 'components/list/ListSortDrawerContent'
import ListFilterDateRangeValueInput from 'components/list/filter/ListFilterDateRangeValueInput'
import { FilterConfig } from 'components/list/filter/ListFilterForm'
import ListFilterRangeInput from 'components/list/filter/ListFilterRangeInput'
import ListFilterValueInput from 'components/list/filter/ListFilterValueInput'
import {
    formatDate,
    generateResource,
    inputIntegerNonNegativeSpacedMaskParams,
    resolveIntegerSpacedMask,
} from 'utils'

import {
    ActionAdd,
    disableMeterSelection,
    unitMeterActions,
    unitMeterBulkActions,
    unitMeterExtendedActions,
} from './actions'
import { MeterSource, UnitMetersDrawerListToggler, UnitMetersSectionTitle } from './components'
import { meterSourceAsText } from './components/MeterSource'
import UnitMetersResource from './components/UnitMetersResource'
import { meterTypesConfig } from './constants'

const defaultSort: SortType<MeterModel> = {
    field: 'timestamp',
    order: 'DESC',
}

const preferencesResource = generateResource({
    name: 'unit-meters',
    resource: 'unit-meters',
})

const sortConfig: ListSortContentProps<MeterModel> = {
    sortBy: [
        { id: 'timestamp', label: 'Date' },
        { id: 'source', label: 'Source' },
        { id: 'type', label: 'Meter Type' },
        { id: 'value', label: 'Value' },
    ],
}

const filterConfig: FilterConfig<MeterModel> = {
    filters: [
        {
            id: 'timestamp',
            label: 'Date',
            filterType: 'range',
            renderComponent: (props) => <ListFilterDateRangeValueInput {...props} />,
        },
        {
            id: 'source',
            label: 'Source',
            renderComponent: (props) => (
                <ListFilterValueInput
                    {...props}
                    makeItemLabel={({ id }) => meterSourceAsText(id as MeterModel['source'])}
                />
            ),
        },
        {
            id: 'type',
            label: 'Meter Type',
            renderComponent: (props) => (
                <ListFilterValueInput
                    {...props}
                    makeItemLabel={({ id }) =>
                        meterTypesConfig[id as keyof typeof meterTypesConfig].name
                    }
                />
            ),
        },
        {
            id: 'value',
            label: 'Value',
            filterType: 'range',
            renderComponent: (props) => (
                <ListFilterRangeInput
                    {...props}
                    integerValuesInput
                />
            ),
        },
    ],
}

const UnitMeters = () => {
    const isArchived = useArchivedContext()

    const columnsConfig: DatagridColumnsProps<MeterModel> = {
        columns: [
            {
                field: 'timestamp',
                headerName: 'Date',
                valueFormatter: ({ value }) =>
                    formatDate(value, ({ shortenedDateTime }) => shortenedDateTime),
            },
            {
                field: 'source',
                headerName: 'Source',
                renderCell: ({ row }) => <MeterSource meter={row as MeterModel} />,
            },
            {
                field: 'type',
                headerName: 'Meter Type',
                renderCell: ({ value }) => {
                    const config = meterTypesConfig[value as keyof typeof meterTypesConfig]
                    return (
                        <BoxContainer gap="8px">
                            <SvgIcon
                                inheritViewBox
                                component={config.Icon}
                                sx={{ width: '16px', height: '16px' }}
                            />
                            {config.name}
                        </BoxContainer>
                    )
                },
            },
            {
                field: 'value',
                headerName: 'Value',
                valueFormatter: ({ value }) => resolveIntegerSpacedMask(value),
            },
        ],
        actions: isArchived ? null : ({ row }, args) => unitMeterActions(row as MeterModel, args),
        checkboxSelection: !isArchived,
    }

    const cardsConfig: CardListConfig<MeterModel> = {
        titleSource: (meter) => (
            <Typography variant="subtitle2">
                {formatDate(meter.timestamp, 'MMM dd yyyy hh:mm')}
            </Typography>
        ),
        disableTitleLink: true,
        subTitleSource: (meter) => (
            <Typography
                variant="tooltip"
                display="flex"
            >
                <MeterSource meter={meter} />
            </Typography>
        ),
        defaultImage: null,
        details: [
            {
                source: 'value',
                label: ({ record }) => {
                    const config = meterTypesConfig[record.type]
                    return (
                        <BoxContainer gap="4px">
                            <SvgIcon
                                inheritViewBox
                                component={config.Icon}
                                sx={{ width: '14px', height: '14px' }}
                            />
                            {config.name}
                        </BoxContainer>
                    )
                },
                render: (value) => resolveIntegerSpacedMask(value),
            },
        ],
        actions: isArchived ? undefined : unitMeterExtendedActions,
        actionsDisabled: (meter) => meter.source === 'WORK_ORDER',
    }
    return (
        <PageContent>
            <UnitMetersResource>
                <ListBase
                    sort={defaultSort}
                    preferencesResource={preferencesResource}
                >
                    <UnitMetersSectionTitle action={<ActionAdd />} />
                    <List
                        disableSelectRecord={disableMeterSelection}
                        hideSearch
                        bulkActions={isArchived ? undefined : unitMeterBulkActions}
                        preferencesResource={preferencesResource}
                        sortCfg={sortConfig}
                        columnsCfg={columnsConfig}
                        cardsCfg={cardsConfig}
                        filtersCfg={filterConfig}
                        disableManageColumns
                        listFTUProps={{
                            secondaryTitle: isArchived ? '' : 'Would you like to add one?',
                            action: isArchived ? null : (
                                <UnitMetersDrawerListToggler>
                                    {({ onClick }) => (
                                        <FtuCreateText onClick={onClick}>Add Meters</FtuCreateText>
                                    )}
                                </UnitMetersDrawerListToggler>
                            ),
                            linkText: null,
                        }}
                    />
                </ListBase>
            </UnitMetersResource>
        </PageContent>
    )
}

export default UnitMeters

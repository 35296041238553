import { ReactElement } from 'react'

import { Identifier, useListContext } from 'react-admin'

import { MemberModel } from 'appTypes/models'
import { useOpenUtilityDrawer, useResource } from 'components'
import UtilityDrawerListEditor from 'components/Drawer/UtilityDrawerListEditor'
import { basePermissions } from 'configs/constants'
import { renderOnPermission } from 'hocs'
import { Serializer } from 'utils'

import MemberForm from './MemberForm'

const memberSerializer: Serializer<MemberModel> = ['role']

const EditMemberAction = renderOnPermission(
    ({ children, id }: { children: (openDrawer: () => void) => ReactElement; id: Identifier }) => {
        const open = useOpenUtilityDrawer()
        const listContext = useListContext<MemberModel>()
        const resource = useResource()

        const openDrawer = () => {
            open({
                drawerArgs: {
                    title: 'Change Role',
                    renderWrapper: (params) => (
                        <UtilityDrawerListEditor
                            serializer={memberSerializer}
                            {...params}
                        />
                    ),
                    renderContent: () => <MemberForm />,
                },
                extraArgs: {
                    listContext,
                    id,
                    resource,
                },
            })
        }

        return children(openDrawer)
    },
    basePermissions.update,
)

export default EditMemberAction

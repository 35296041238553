import { useRecordContext } from 'react-admin'

import { VendorModel } from 'appTypes/models'
import { IconBox, SliderView, Section, TagsCard, ViewHeader } from 'components'
import Icons from 'components/icons'
import { VendorDrawerToggler, VendorSliderSkeleton } from 'pages/Vendors/components'

import { AddressDetails, BasicDetails, FinancialInformation } from './components'

const skeletonSlides = new Array(4).fill(<VendorSliderSkeleton />)

const VendorDetails = () => {
    const record = useRecordContext<VendorModel>()
    const slides = record?.id
        ? [
              <BasicDetails />,
              <VendorDrawerToggler id={record.id}>
                  {(open) => <TagsCard open={open} />}
              </VendorDrawerToggler>,
              <AddressDetails />,
              <FinancialInformation />,
          ]
        : skeletonSlides
    return (
        <Section>
            <ViewHeader
                title="Company Details"
                loading={!record?.id}
            >
                <ViewHeader.Content placement="rightMobile">
                    <VendorDrawerToggler id={record?.id}>
                        {(open) => (
                            <IconBox
                                title="Edit"
                                onClick={open}
                            >
                                <Icons.Edit />
                            </IconBox>
                        )}
                    </VendorDrawerToggler>
                </ViewHeader.Content>
            </ViewHeader>
            <SliderView
                height="154px"
                items={slides}
            />
        </Section>
    )
}

export default VendorDetails

import { Box, Link, styled } from '@mui/material'

import { ReactComponent as Deactivated } from 'assets/images/deactivate.svg'
import { CenterText, Button, Typography } from 'components'
import { landingPageUrl, supportEmail } from 'configs/config'

const Main = styled('div')`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 335px;
`

const Logout = () => {
    return (
        <Main>
            <Box
                alignItems="center"
                display="flex"
                justifyContent="center"
            >
                <Deactivated />
            </Box>
            <CenterText>
                <Typography
                    variant="h5"
                    mt="32px"
                >
                    Your company account has <br />
                    been deactivated!
                </Typography>
                <Typography
                    variant="body1"
                    mt="16px"
                >
                    To reactivate your company account, <br /> please contact us at
                    <Link href={`mailto:${supportEmail}`}> {supportEmail}</Link>.
                </Typography>
            </CenterText>
            <CenterText>
                <Button
                    sx={{ mt: '24px' }}
                    variant="contained"
                    href={landingPageUrl}
                >
                    GO TO SITE
                </Button>
            </CenterText>
        </Main>
    )
}

export default Logout

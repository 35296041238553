import { useFlags } from 'launchdarkly-react-client-sdk'

export type flagName =
    | 'flagDarkMode'
    | 'showVersionInMobileMode'
    | 'useBilling'
    | 'flag63Reports'
    | 'flag621UnitListAllColumns'
    | 'flag3976PartPurchaseHistory'

export interface Flags extends Record<flagName, boolean> {}

declare module 'launchdarkly-js-sdk-common' {
    interface LDFlagSet extends Partial<Flags> {}
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useFlags1 = () => {
    return {
        ...useFlags(),
    } as Flags
}

export default useFlags as () => Flags

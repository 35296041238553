import { ReactElement } from 'react'

import { Identifier } from 'react-admin'

import { useOpenUtilityDrawer, UtilityDrawerEditor } from 'components'
import { UtilityDrawerEditorProps } from 'components/Drawer/UtilityDrawerEditor'

import { vendorsResource, vendorsSerializer } from '../constants/config'

import VendorForm from './VendorForm'

interface VendorDrawerTogglerProps extends Pick<UtilityDrawerEditorProps, 'onSuccess'> {
    children: (toggle: () => void) => ReactElement
    id?: Identifier
}

const VendorDrawerToggler = ({ children, id, onSuccess }: VendorDrawerTogglerProps) => {
    const open = useOpenUtilityDrawer()

    return children(() =>
        open({
            drawerArgs: {
                title: id ? 'Edit Details' : 'Create Vendor',
                renderContent: () => <VendorForm />,
                renderWrapper: (params) => (
                    <UtilityDrawerEditor
                        {...params}
                        onSuccess={onSuccess}
                        serializer={vendorsSerializer}
                    />
                ),
            },
            extraArgs: {
                id,
                resource: vendorsResource,
            },
        }),
    )
}

export default VendorDrawerToggler

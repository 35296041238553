import { createContext, useContext, ReactElement, ReactNode } from 'react'

import { AllProps } from 'appTypes'

import { ArrayControllerBasevalueType, ArrayControllerResult } from './ArrayControllerBase'
import { useArrayControllerContext } from './ArrayControllerContext'

interface ArrayControllerElemenetProps<ValueType = ArrayControllerBasevalueType> {
    children: ((params: { item: ValueType; index: number }) => ReactElement) | ReactNode
    onDelete?: (params: { item: ValueType; index: number }) => void
    extra?: ((params: { item: ValueType; index: number }) => any) | AllProps
    itemKey?: (params: { item: ValueType; index: number }) => string | number
}

interface Params {
    extra?: { [key: string]: any }
    valueType?: any
}
interface SingleItemContextResult<
    P extends Params = { extra: AllProps; valueType: ArrayControllerBasevalueType },
> {
    remove: () => void
    arrayController: ArrayControllerResult<Params['valueType']>
    item: P['valueType']
    extra?: P['extra']
    index: number
}

const ArrayControllerElementContext = createContext<SingleItemContextResult<Params>>(null)
export const useArrayControllerElementContext = <
    P extends Params = { extra: AllProps; valueType: ArrayControllerBasevalueType },
>() => useContext(ArrayControllerElementContext) as SingleItemContextResult<P>

const ArrayControllerElements = <ValueType = number,>({
    children,
    onDelete,
    extra,
    itemKey,
}: ArrayControllerElemenetProps<ValueType>) => {
    const arrayController = useArrayControllerContext<ValueType>()
    const { array, remove } = arrayController

    return (
        <>
            {array.map((item, index) => (
                <ArrayControllerElementContext.Provider
                    key={itemKey ? itemKey({ item, index }) : String(item)}
                    value={{
                        index,
                        remove: () => {
                            remove({ item, index })
                            onDelete?.({ item, index })
                        },
                        arrayController,
                        item,
                        extra: typeof extra === 'function' ? extra({ item, index }) : extra,
                    }}
                >
                    {typeof children === 'function' ? children({ item, index }) : children}
                </ArrayControllerElementContext.Provider>
            ))}
        </>
    )
}

export default ArrayControllerElements

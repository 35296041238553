import { Identifier } from 'react-admin'

type BaseList = {
    id: Identifier
    totalPercent: number
    total: number
}[]
type ReturnType = {
    top3Percentage: number
    top3Total: number
    uncategorized: boolean
}
export const extractWidgetTop3 = (list: BaseList, excludeNegatives?: boolean): ReturnType =>
    list.reduce(
        ({ top3Percentage, top3Total, uncategorized }, obj) => {
            if (obj.id === 'uncategorized') {
                uncategorized = true
            }
            top3Percentage = top3Percentage + obj.totalPercent
            top3Total += excludeNegatives ? (obj.total > 0 ? obj.total : 0) : obj.total

            return { top3Percentage, top3Total, uncategorized }
        },
        { top3Percentage: null, top3Total: null, uncategorized: null },
    )

import { ReactNode } from 'react'

import { BoxProps, useTheme } from '@mui/material'
import { RaRecord, useListContext } from 'react-admin'

import DataCard, { DataCardDetail, DataCardDetailsProp } from 'components/DataCard/DataCard'
import { GridItemCard } from 'components/pages'
import { useFlags } from 'hooks'

import { CardListConfig } from './CardList'

interface CardListCardCfg<RecordType extends RaRecord = RaRecord> extends Pick<BoxProps, 'sx'> {
    cardsCfg: CardListConfig<RecordType>
    record: RecordType
    disableSelectRecord?: (record: RecordType) => string
}

const CardListCard = <RecordType extends RaRecord = RaRecord>({
    cardsCfg,
    record,
    sx,
    disableSelectRecord,
}: CardListCardCfg<RecordType>) => {
    const theme = useTheme()
    const { titleSource, subTitleSource, actions, action, titleLink, imageSource } = cardsCfg

    const flags = useFlags()
    const listContext = useListContext()
    const link: string = typeof titleLink === 'function' ? titleLink(record) : titleLink
    const title: ReactNode =
        typeof titleSource === 'function' ? titleSource(record) : record[titleSource]
    const image: string =
        typeof imageSource === 'function' ? imageSource(record) : record[imageSource]
    const subTitle: ReactNode =
        typeof subTitleSource === 'function'
            ? subTitleSource(record, { flags, listContext })
            : record[subTitleSource]

    let details: DataCardDetailsProp = []

    const pushToDetails = (values) => {
        if (typeof values === 'function') {
            const detailsArray = values(record)
            return pushToDetails(detailsArray)
        }
        if (Array.isArray(values)) {
            values.forEach((cfg) => {
                const value = record[cfg.source]
                const detailProps: DataCardDetail = {
                    label:
                        typeof cfg.label === 'function'
                            ? cfg.label({ record })
                            : cfg.label || (cfg.source as string),
                    value: cfg.render?.(value, record) || value,
                    key: cfg.source as string,
                }
                ;(details as DataCardDetail[]).push(detailProps)
            })
        } else {
            details = values
        }
    }

    pushToDetails(cardsCfg.details)

    return (
        <GridItemCard
            className="data-card"
            sx={sx}
        >
            <DataCard
                nonSelectable={disableSelectRecord?.(record)}
                id={record.id}
                to={link}
                record={record}
                title={title}
                subtitle={subTitle}
                disableTitleLink={cardsCfg.disableTitleLink}
                withStatus={
                    typeof cardsCfg.withStatus === 'function'
                        ? cardsCfg.withStatus({ flags })
                        : cardsCfg.withStatus
                }
                image={image}
                defaultImage={
                    typeof cardsCfg.defaultImage === 'function'
                        ? cardsCfg.defaultImage(record)
                        : cardsCfg.defaultImage
                }
                color={cardsCfg.colorGetter?.(record, theme)}
                details={details}
                action={action}
                actions={actions}
                actionsDisabled={cardsCfg.actionsDisabled?.(record)}
                avatarColor={cardsCfg.avatarColor}
                avatarOpacity={cardsCfg.avatarOpacity}
                styles={cardsCfg.styles}
                // create util func to check for function
                collapsibleContent={
                    typeof cardsCfg.collapsibleContent === 'function'
                        ? cardsCfg.collapsibleContent(record)
                        : cardsCfg.collapsibleContent
                }
            />
        </GridItemCard>
    )
}

export default CardListCard

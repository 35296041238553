import { ReactNode } from 'react'

import { Link } from 'react-router-dom'

import { Typography, TypographyProps } from 'components'
import { UseCreateResourcePathParams, useCreateResourcePath } from 'hooks'

interface LinkCardButtonProps extends TypographyProps {
    text: ReactNode
    to: string | UseCreateResourcePathParams
}
const LinkCardButton = ({ text, to, ...rest }: LinkCardButtonProps) => {
    const createPath = useCreateResourcePath()

    return (
        <Link to={typeof to === 'string' ? to : createPath(to)}>
            <Typography
                color="primary.main"
                variant="body2"
                {...rest}
                component="span"
            >
                {text || '-'}
            </Typography>
        </Link>
    )
}
export default LinkCardButton

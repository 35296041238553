import { useDelete as useRaDeleteOne, useRecordContext, Identifier, RaRecord } from 'react-admin'
import { useNavigate } from 'react-router-dom'

import { ServerErrors } from 'appTypes'
import { ConfirmConfig } from 'components/Confirm'
import { useConfirm, useCreateResourcePath } from 'hooks'

import useDelete, { UseDeleteParams } from './useDelete'

export interface DeleteOneParams extends Omit<UseDeleteParams, 'onConfirm'> {
    id?: Identifier

    onSuccess?: () => void

    redirectTo?: 'list' | ''

    onError?: (params: {
        error: ServerErrors
        defaultOnError: () => void
        confirm: (params: ConfirmConfig) => void
    }) => void
}

const useDeleteOne = () => {
    const deleteResource = useDelete()
    const record = useRecordContext() || ({} as RaRecord)
    const [deleteOne] = useRaDeleteOne()
    const createPath = useCreateResourcePath()
    const navigate = useNavigate()
    const confirm = useConfirm()

    return ({
        id,
        resource,
        onSuccess,
        redirectTo = 'list',
        onError,
        ...rest
    }: DeleteOneParams = {}) => {
        deleteResource({
            ...rest,
            onConfirm: async (params) => {
                const finalResource = resource || params.resource
                const finalId = id || record.id
                await deleteOne(
                    finalResource.resource,
                    {
                        id: finalId,
                    },
                    {
                        onSuccess: () => {
                            onSuccess?.()
                            params.onSuccess()
                            if (redirectTo) {
                                navigate(
                                    createPath({
                                        resource: finalResource.resource,
                                        id: finalId,
                                        type: redirectTo,
                                    }),
                                )
                            }
                        },
                        onError: (error: ServerErrors) => {
                            if (onError) {
                                onError({
                                    error,
                                    defaultOnError: () => params.onError(error),
                                    confirm,
                                })
                            } else {
                                params.onError(error)
                            }
                        },
                    },
                )
            },
        })
    }
}

export default useDeleteOne

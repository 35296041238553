import { PropsWithChildren } from 'react'

import { Badge as MuiBage } from '@mui/material'
import { badgeClasses } from '@mui/material/Badge'

import { IntervalsType } from 'appTypes/models'
import { useArchivedContext } from 'components'

const PMBadge = ({
    children,
    unitPmRecord,
    disabled,
}: PropsWithChildren & { unitPmRecord: IntervalsType; disabled?: boolean }) => {
    const isArchived = useArchivedContext()
    if (unitPmRecord.status === 'PLANNED' || isArchived || disabled) {
        return <>{children}</>
    }
    if (unitPmRecord.status === 'OVERDUE') {
        return (
            <MuiBage
                variant="dot"
                color="error"
            >
                {children}
            </MuiBage>
        )
    }
    return (
        <MuiBage
            variant="dot"
            sx={(theme) => ({
                [`& .${badgeClasses.badge}`]: {
                    backgroundColor: `${theme.palette.charts.orange} !important`,
                },
            })}
        >
            {children}
        </MuiBage>
    )
}
export default PMBadge

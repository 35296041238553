import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Link } from 'react-router-dom'

import { IconBox, StyledElement } from 'components'

const MonitorArrowButton = ({ navigateTo }: { navigateTo: string }) => {
    const navigateIcon = (
        <IconBox
            sx={{
                width: '16px',
                height: '16px',
                position: 'relative',
            }}
            disabled={!navigateTo}
        >
            <ArrowForwardIcon />
        </IconBox>
    )
    return (
        <StyledElement sx={{ position: 'absolute', right: '20px' }}>
            {navigateTo ? <Link to={navigateTo}>{navigateIcon}</Link> : navigateIcon}
        </StyledElement>
    )
}

export default MonitorArrowButton

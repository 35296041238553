import { FC, useMemo } from 'react'

import { Validator } from 'react-admin'

import { TextInput } from 'components'
import { useUtilityDrawerContext } from 'components/Drawer/UtilityDrawer'
import { TextInputProps } from 'components/inputs/TextInput'
import { inputIntegerPositiveMask, requiredValidation } from 'utils'

import useInputSource from './useInputSource'
import { UnitPMDrawerEditorExtraState } from './useUnitPMDrawerEditor'

interface UnitPMMeterEveryInputProps extends Partial<TextInputProps> {}

const UnitPMMeterEveryInput: FC<UnitPMMeterEveryInputProps> = (props) => {
    const source = useInputSource()
    const validate = props.validate
    const { extra } = useUtilityDrawerContext()
    const { isArchived } = extra as UnitPMDrawerEditorExtraState

    const validation = useMemo(() => {
        const validation: Validator[] = [requiredValidation]
        if (validate) {
            validation.push(...(Array.isArray(validate) ? validate : [validate]))
        }
        return validation
    }, [validate])

    return (
        <TextInput
            disabled={isArchived}
            label="Every"
            {...props}
            validate={validation}
            {...inputIntegerPositiveMask(props)}
            source={source('value')}
        />
    )
}

export default UnitPMMeterEveryInput

import { SaveButton as RaSaveButton, SaveButtonProps } from 'react-admin'
import { useFormState, useFormContext } from 'react-hook-form'

import { useResource } from 'components/resource'
import { nonFieldErrors } from 'configs/constants'

import { resourceToFormId } from './Form'

export interface SubmitButtonProps extends SaveButtonProps {}

export const specialErrorNames = [nonFieldErrors]

const SubmitButton = (props: SubmitButtonProps) => {
    const { clearErrors } = useFormContext()
    const { isValid } = useFormState()
    const resource = useResource()

    const onClick: SubmitButtonProps['onClick'] = (event) => {
        props.onClick?.(event)

        clearErrors(specialErrorNames)
    }

    return (
        <RaSaveButton
            form={resourceToFormId(resource)}
            disabled={!isValid}
            {...props}
            onClick={onClick}
        />
    )
}

export default SubmitButton

// if the button should be disabled while there are errors, use this:
// Boolean(Object.keys(errors).filter((error) => !specialErrorNames.includes(error)).length)

import { useRecordContext } from 'react-admin'

import { VendorModel } from 'appTypes/models'
import { ShowHeaderSkeleton, TabType, Typography } from 'components'
import { Action, deleteOneAction } from 'components/actions'
import Icons from 'components/icons'
import Header from 'components/pages/header'
import { deleteVendorParams, vendorTypesObject } from 'pages/Vendors/constants/config'

const actions: Action<VendorModel> = (record, { children }) => {
    return [
        deleteOneAction({
            id: record.id,
            ...deleteVendorParams,
            children,
        }),
    ]
}
const VendorShowHeader = ({ tabs }: { tabs: TabType[] }) => {
    const vendor = useRecordContext<VendorModel>()
    if (!vendor) {
        return <ShowHeaderSkeleton />
    }
    return (
        <Header fixed>
            <Header.Block divider="hideOnMobile">
                <Header.BackButton />
                <Header.Main>
                    <Header.Info
                        title={<Header.Title>{vendor.name}</Header.Title>}
                        avatar={
                            <Header.Avatar
                                imageSrc={vendor.logo}
                                defaultImage={<Icons.Vendors />}
                            />
                        }
                        subheader={
                            <Typography
                                textTransform="uppercase"
                                fontWeight={500}
                                variant="caption"
                                color="text.primary"
                            >
                                {vendorTypesObject[vendor.type]?.name}
                            </Typography>
                        }
                    />
                </Header.Main>
                <Header.Aside>
                    <Header.Actions actions={actions} />
                </Header.Aside>
            </Header.Block>
            <Header.Tabs
                tabs={tabs}
                tabWidth="186px"
            />
        </Header>
    )
}

export default VendorShowHeader

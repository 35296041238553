import { useClearResource } from 'hooks'

import RaResourceContextProvider, {
    ResourceContextProviderProps,
} from './RaResourceContextProvider'

const ResourceContextProviderWithClearEffect = ({
    value,
    children,
}: ResourceContextProviderProps) => {
    useClearResource(value.resource)

    return <RaResourceContextProvider value={value}>{children}</RaResourceContextProvider>
}

export default ResourceContextProviderWithClearEffect

import { FC } from 'react'

import { Form, FormProps } from 'components/form'

import { useUtilityDrawerContext } from './UtilityDrawer'
import UtilityDrawerFormMakeSubmittable from './UtilityDrawerFormMakeSubmittable'
import UtilityDrawerWarnWhenUnsavedChanges from './UtilityDrawerWarnWhenUnsavedChanges'

interface UtilityDrawerFormProps extends FormProps {
    onSubmit?: (RaRecord) => Promise<any>
    disableCloseOnSubmit?: boolean
    submittableAtBeginning?: boolean
}
const UtilityDrawerForm: FC<UtilityDrawerFormProps> = ({
    onSubmit: onSubmitProp,
    children,
    warnWhenUnsavedChanges = true,
    disableCloseOnSubmit,
    submittableAtBeginning,
    ...rest
}) => {
    const { forceClose } = useUtilityDrawerContext()

    const onSubmit = async (params) => {
        const res = await onSubmitProp?.(params)
        if (res) {
            return res
        }
        if (!disableCloseOnSubmit) {
            forceClose()
        }
    }
    return (
        <Form
            onSubmit={onSubmit}
            warnWhenUnsavedChanges={false}
            children={
                <>
                    {warnWhenUnsavedChanges && <UtilityDrawerWarnWhenUnsavedChanges />}
                    {submittableAtBeginning && <UtilityDrawerFormMakeSubmittable />}
                    {children}
                </>
            }
            {...rest}
        />
    )
}

export default UtilityDrawerForm

import { Link } from 'react-admin'

import { IntervalsType, UnitPMIntervalTimeType, UnitPMReminderModel } from 'appTypes/models'
import { BoxContainer, Columns, Typography } from 'components'
import { useCreateResourcePath } from 'hooks'
import { AddWorkOrderMenu } from 'pages/Units/components'
import { woResource } from 'pages/WorkOrders/config/constants'
import { resolveIntegerSpacedMask } from 'utils'

const timeLeftFormat = (selectedPM: UnitPMIntervalTimeType) => {
    const { years, months, days } = selectedPM.remainingTillOverduePretty
    const y = Math.abs(years)
    const m = Math.abs(months)
    const d = Math.abs(days)
    const pluralText = (time: number, text: string) =>
        time ? `${time} ${text}${time === 1 ? '' : 's'}` : ''
    return (
        [pluralText(y, 'year'), pluralText(m, 'month'), pluralText(d, 'day')]
            .filter((time) => time)
            .join(', ') || '0 days'
    )
}
export const getLeft = (selectedPM: IntervalsType) => {
    if (selectedPM.type === 'TIME') {
        return timeLeftFormat(selectedPM)
    }
    if (selectedPM.threshold !== null || selectedPM.status === 'OVERDUE') {
        return resolveIntegerSpacedMask(Math.abs(selectedPM.value - selectedPM.passed))
    }
    return `${selectedPM.value - selectedPM.passed} out of ${selectedPM.value}`
}
export const getLeftUnitType = (type: IntervalsType['type']) => {
    if (type === 'TIME') {
        return `TIME`
    }
    if (type === 'ENGINE_HOURS') {
        return `HOURS`
    }
    return `MILES`
}
const BottomSection = ({
    selectedPM,
    pmReminder,
    isArchived,
}: {
    selectedPM: IntervalsType
    pmReminder: UnitPMReminderModel
    isArchived: boolean
}) => {
    const createPath = useCreateResourcePath()

    const getLeftUnitTypeValue = getLeftUnitType(selectedPM.type)

    const leftValue = getLeft(selectedPM)
    const workOrderContent = pmReminder.workOrderData ? (
        <Typography variant="body2">
            <Link
                to={createPath({
                    resource: woResource.resource,
                    id: pmReminder.workOrderData.id,
                    type: 'edit',
                })}
            >
                {pmReminder.workOrderData.number}
            </Link>
        </Typography>
    ) : (
        <AddWorkOrderMenu<UnitPMReminderModel>
            options={{
                jobDefaultValues: {
                    notes: pmReminder.description
                        ? [
                              {
                                  createdByData: { name: pmReminder.name },
                                  source: 'PM_SCHEDULE',
                                  text: pmReminder.description,
                              },
                          ]
                        : [],
                },
            }}
            disabled={isArchived}
            parentRecord={pmReminder}
            refetchList="pm-schedules"
        />
    )
    return (
        <Columns
            gap="10px"
            sx={{ paddingTop: '12px' }}
        >
            <BoxContainer justifyContent="space-between">
                <Typography
                    variant="tooltip"
                    color="text.disabled"
                >
                    LEFT ({getLeftUnitTypeValue})
                </Typography>
                <BoxContainer overflow="hidden">
                    <Typography
                        textOverflow="ellipsis"
                        overflow="hidden"
                        whiteSpace="nowrap"
                        variant="body2"
                        color={
                            isArchived
                                ? 'text.primary'
                                : selectedPM.status === 'OVERDUE'
                                ? 'error.main'
                                : 'text.primary'
                        }
                    >
                        {leftValue}
                    </Typography>

                    {leftValue.charAt(0) !== '0' && selectedPM.status === 'OVERDUE' && (
                        <Typography
                            variant="body2"
                            color={isArchived ? 'text.primary' : 'error.main'}
                        >
                            &nbsp;overdue
                        </Typography>
                    )}
                </BoxContainer>
            </BoxContainer>
            <BoxContainer justifyContent="space-between">
                <Typography
                    variant="tooltip"
                    color="text.disabled"
                >
                    INTERVAL(
                    {selectedPM.type === 'TIME' ? selectedPM.valueType + 'S' : getLeftUnitTypeValue}
                    )
                </Typography>
                <Typography
                    variant="body2"
                    color="text.primary"
                >
                    {resolveIntegerSpacedMask(selectedPM.value)}
                </Typography>
            </BoxContainer>
            <BoxContainer justifyContent="space-between">
                <Typography
                    variant="tooltip"
                    color="text.disabled"
                >
                    WORK ORDER
                </Typography>
                {workOrderContent}
            </BoxContainer>
        </Columns>
    )
}
export default BottomSection

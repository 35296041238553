import { FC } from 'react'

import { Box } from '@mui/material'

import ListFilterNoResultsIcon from 'assets/images/list-filter-no-results.svg'
import NoResultsCard from 'components/NoResultsCard'
import { NoResultsCardProps } from 'components/NoResultsCard/NoResultsCard'
import Typography from 'components/mui/Typography'

interface DialogSelectorNoResultsProps {
    title?: string
    text?: string
    image?: NoResultsCardProps['imageSrc']
    imageWidth?: NoResultsCardProps['imageProps']['width']
}

const DialogSelectorNoResults: FC<DialogSelectorNoResultsProps> = ({
    title = 'No Results',
    text = 'No matching items found. Please modify your search.',
    image = ListFilterNoResultsIcon,
    imageWidth,
}) => {
    return (
        <Box
            display="flex"
            height="100%"
            alignItems="center"
        >
            <NoResultsCard
                transparent
                height="auto"
                title={title}
                gridItem={false}
                subtitle={
                    <Typography
                        variant="body1"
                        color="text.secondary"
                    >
                        {text}
                    </Typography>
                }
                imageSrc={image}
                imageProps={{
                    mb: '16px',
                    width: imageWidth || '140px',
                }}
            />
        </Box>
    )
}

export default DialogSelectorNoResults

import { CheckCircleOutline, ErrorOutline } from '@mui/icons-material'
import { SvgIcon, Theme } from '@mui/material'

import { Spacer, Typography } from 'components'

import SpanEllipsis from './SpanEllipsis'

interface DVIRStatusProps {
    children: string
}
const safeStatuses = ['SAFE', 'RESOLVED', 'HARMLESS', 'CORRECTED', 'SATISFACTORY']
const statusMap = {
    SAFE: 'Safe',
    UNSAFE: 'Unsafe',
    RESOLVED: 'Resolved',
    SATISFACTORY: 'Without Defects',
    CORRECTED: 'Corrected',
    HARMLESS: 'Need Not to Be Corrected',
    SATISFACTORY_WITH_DEFECTS: 'With Defects',
}
const DVIRStatus = ({ children }: DVIRStatusProps) => {
    const isHarmfull = !safeStatuses.includes(children)
    const color = (theme: Theme) =>
        isHarmfull ? theme.palette.error.main : theme.palette.success.main
    if (!children) {
        return null
    }
    return (
        <Spacer
            overflow="hidden"
            textOverflow="ellipsis"
            component="span"
        >
            <SvgIcon
                component={isHarmfull ? ErrorOutline : CheckCircleOutline}
                sx={{ width: '16px', height: '16px', color }}
            />
            <Typography
                color={color}
                component={SpanEllipsis}
                variant="body2"
            >
                {statusMap[children] || 'Unknown'}
            </Typography>
        </Spacer>
    )
}

export default DVIRStatus

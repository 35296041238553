import { MeterModel } from 'appTypes/models'
import { IconBox, useArchivedContext } from 'components'
import {
    Action,
    deleteOneFromListAction,
    deleteManyFromListAction,
    multiselectAction,
} from 'components/actions'
import Icons from 'components/icons'
import { ListBulkActions } from 'components/list/ListSelection'
import { deleteMeterAction } from 'pages/Units/config/constants'

import { UnitMetersDrawerListToggler } from './components'
import { meterSourceMap } from './constants'

export type MeterActions = Action<MeterModel>

export const unitMeterActions: MeterActions = (meter, { children }) => {
    return [
        <UnitMetersDrawerListToggler
            key="edit"
            id={meter.id}
            children={({ onClick, disabled }) =>
                children({
                    title: 'Edit',
                    Icon: Icons.Edit,
                    onClick,
                    disabled,
                    titleOnDisabled:
                        (meter.source === 'WORK_ORDER' &&
                            'This meter reading originates from a Work Order and can be updated through it') ||
                        ((meter.source === 'MOTIVE' || meter.source === 'SAMSARA') &&
                            'This meter reading originates from a Telematics system and can not be updated') ||
                        '',
                })
            }
            disabled={meter.source !== 'MANUAL'}
        />,
        deleteOneFromListAction({
            disabled: meter.source === 'WORK_ORDER',
            children: (params) =>
                children({
                    ...params,
                    titleOnDisabled:
                        'This meter reading originates from a Work Order and can be updated through it',
                }),
            id: meter.id,
            ...deleteMeterAction,
        }),
    ]
}

export const disableMeterSelection = ({ source }: MeterModel) =>
    source !== 'MANUAL' && source !== 'SAMSARA' && source !== 'MOTIVE'
        ? `This meter reading originates from a ${meterSourceMap[source]} and can be updated through it.`
        : null

export const unitMeterExtendedActions: MeterActions = (meter, args) => [
    ...unitMeterActions(meter, args),
    multiselectAction({
        disabled: Boolean(disableMeterSelection(meter)),
        children: (params) => args.children(params),
        id: meter.id,
    }),
]

export const metersActions: Action<MeterModel> = (params, { children }) => [
    <UnitMetersDrawerListToggler
        key="add"
        children={({ onClick }) => children({ Icon: Icons.Add, title: 'Add Meters', onClick })}
    />,
    children({
        Icon: Icons.GridView,
        title: 'See All',
        key: 'see-all',
        to: 'meters',
    }),
]

export const ActionAdd = () => {
    const isArchived = useArchivedContext()

    if (isArchived) {
        return null
    }

    return (
        <UnitMetersDrawerListToggler>
            {({ onClick }) => (
                <IconBox
                    title="Add Meter"
                    onClick={onClick}
                >
                    <Icons.Add />
                </IconBox>
            )}
        </UnitMetersDrawerListToggler>
    )
}

export const unitMeterBulkActions: ListBulkActions = ({ children }) => [
    deleteManyFromListAction({
        children,
        confirmConfig: {
            title: 'Are you sure you want to delete the selected Meters?',
        },
    }),
]

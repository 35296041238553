import { deleteOneFromListAction } from 'components/actions'
import Icons from 'components/icons'

import { VendorContactDrawerToggler } from './components'
import { ContactActions } from './components/VendorContactCard'

export const contactActions: ContactActions = ({ contact, flags }, { children }) => [
    <VendorContactDrawerToggler
        key="edit"
        id={contact.id}
        children={({ onClick }) => children({ title: 'View/Edit', Icon: Icons.Edit, onClick })}
    />,
    deleteOneFromListAction({
        children,
        id: contact.id,
        confirmConfig: {
            title: 'Are you sure you want to delete this Contact Person?',
        },
    }),
]

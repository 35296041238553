import { useRecordContext } from 'react-admin'

import { WorkOrderModel } from 'appTypes/models'
import { PageContent, SliderView } from 'components'

import WorkOrderFinancialInfo from './WorkOrderFinancialInfo'

const WorkOrderTopSection = () => {
    const record = useRecordContext<WorkOrderModel>()
    const slides = [<WorkOrderFinancialInfo />]
    if (record && (!record.jobsCount || !record.jobsItemsCount)) {
        return null
    }
    return (
        <PageContent
            bgcolor={(theme) => theme.palette.darkGray}
            flexGrow="initial !important"
        >
            <SliderView items={slides} />
        </PageContent>
    )
}
export default WorkOrderTopSection

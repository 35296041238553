import { FC } from 'react'

import { SvgIcon } from '@mui/material'
import { useTranslate } from 'react-admin'

import { MeterModel } from 'appTypes/models'
import {
    StyledElement,
    InfoCard,
    InfoCardDetails,
    InfoCardHeader,
    SectionTypography,
    useArchivedContext,
    BoxContainer,
    EllipsisOverflow,
} from 'components'
import { resolveIntegerSpacedMask, formatDate } from 'utils'

import { unitMeterActions } from '../actions'
import { meterTypesConfig } from '../constants'

import MeterSource from './MeterSource'

interface UnitMetersSectionCardProps {
    meter: MeterModel
}

const UnitMetersCard: FC<UnitMetersSectionCardProps> = ({ meter }) => {
    const isArchived = useArchivedContext()
    const translate = useTranslate()
    const MeterIcon = meterTypesConfig[meter.type].Icon
    return (
        <InfoCard>
            <InfoCardHeader
                record={meter}
                actionsDisabled={meter.source === 'WORK_ORDER'}
                actions={isArchived ? undefined : unitMeterActions}
                title={
                    <StyledElement sx={{ display: 'flex', width: '100%' }}>
                        <div>
                            <SectionTypography
                                variant="subtitle2"
                                mb="5px"
                                color="text"
                            >
                                <StyledElement as="span">
                                    {formatDate(meter.timestamp, 'MMM dd yyyy hh:mm a')}
                                </StyledElement>
                            </SectionTypography>
                            <SectionTypography
                                variant="tooltip"
                                mb="0"
                            >
                                <MeterSource meter={meter} />
                            </SectionTypography>
                        </div>
                    </StyledElement>
                }
            />
            <InfoCardDetails<MeterModel>
                details={[
                    {
                        label: (
                            <BoxContainer>
                                <SvgIcon sx={{ width: '14px !important', height: '14px' }}>
                                    {MeterIcon && <MeterIcon />}
                                </SvgIcon>
                                <EllipsisOverflow>{translate(meter.type)}</EllipsisOverflow>
                            </BoxContainer>
                        ),
                        source: ({ value }) => resolveIntegerSpacedMask(value),
                    },
                ]}
                propRecord={meter}
            />
        </InfoCard>
    )
}
export default UnitMetersCard

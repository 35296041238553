import { ReactElement, FC } from 'react'

import { Identifier, useListContext, useShowContext } from 'react-admin'

import { DateInput, TextInput, useOpenUtilityDrawer, UtilityDrawerEditor } from 'components'
import { partsResource } from 'pages/Parts/config/constants'
import { VendorField } from 'pages/WorkOrders/components/VendorWorkOrderForm'
import { costMaskParams, requiredValidation } from 'utils'

interface PurchaseHistoryDrawerToglerProps {
    children: (open: () => void) => ReactElement
    id?: Identifier
}
export const createResourcePath = (id: Identifier) =>
    `${partsResource.resource}/${id}/purchase-history`
const PurchaseHistoryDrawerTogler: FC<PurchaseHistoryDrawerToglerProps> = ({ children, id }) => {
    const open = useOpenUtilityDrawer()
    const { record } = useShowContext()
    const { refetch } = useListContext()
    return children(() => {
        open({
            drawerArgs: {
                title: id ? 'Edit Manual Entry' : 'Add Manual Entry',
                renderWrapper: (params) => (
                    <UtilityDrawerEditor
                        {...params}
                        onSuccess={() => {
                            refetch()
                        }}
                    />
                ),
                renderContent: () => <PurchaseHistoryForm />,
                renderBottomRight: (render) => render({ label: id ? undefined : 'Add Entry' }),
            },
            extraArgs: {
                id,
                resource: {
                    name: 'purchase-history',
                    resource: createResourcePath(record.id),
                },
            },
        })
    })
}

export default PurchaseHistoryDrawerTogler

const PurchaseHistoryForm = () => {
    return (
        <>
            <VendorField canEditVendor />
            <TextInput
                source="price"
                label="Part Cost"
                validate={requiredValidation}
                {...costMaskParams}
            />
            <DateInput
                source="purchaseDate"
                label="Purchase Date"
                validate={requiredValidation}
                defaultValue={new Date()}
            />
        </>
    )
}

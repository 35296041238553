import { Route, Routes, Navigate } from 'react-router-dom'

import { urls } from 'configs/config'

import CostPerCategoryCK31 from './CostPerCategory/CostPerCategoryCK31'
import CostPerEquipmentCategoryCK2 from './CostPerEquipmentCategory/CostPerEquipmentCategoryCK2'
import CostPerMeter from './CostPerMeter/CostPerMeter'
import CostPerResonForRepairCK14 from './CostPerReasonForRepairCK14/CostPerReasonForRepairCK14'
import { CostPerVMRSGroup } from './CostPerVMRSGroup'
import Downtime from './Downtime'
import Dvirs from './Dvirs/Dvirs'
import MaintenanceCost from './MaintenanceCost'
import PMIntervals from './PMIntervals'
import VendorExpenses from './VendorExpenses'
export const reportsUrls = {
    maintenanceCost: 'maintenance-cost',
    costPerMeter: 'cost-per-meter',
    downtime: 'downtime',
    costPerCategory: 'cost-per-category',
    costPerVMRSGroup: 'cost-per-vmrs-group',
    vendorExpenses: 'total-vendor-expenses',
    costPerReasonForRepair: 'cost-per-reason-for-repair',
    costPerEquipmentCategory: 'cost-per-equipment-category',
    intervals: 'pm-intervals',
    defects: 'defects',
} as const

export const reportUrl = (report: typeof reportsUrls[keyof typeof reportsUrls]) => {
    return `${urls.reports}/${report}`
}

reportUrl.urls = reportsUrls

const Reports = () => {
    return (
        <Routes>
            <Route
                path={reportsUrls.maintenanceCost}
                element={<MaintenanceCost />}
            />
            (
            <Route
                path={reportsUrls.costPerMeter}
                element={<CostPerMeter />}
            />
            ) (
            <Route
                path={reportsUrls.downtime}
                element={<Downtime />}
            />
            )
            <Route
                path={reportsUrls.costPerCategory}
                element={<CostPerCategoryCK31 />}
            />
            <Route
                path={reportsUrls.costPerVMRSGroup}
                element={<CostPerVMRSGroup />}
            />
            <Route
                path={reportsUrls.vendorExpenses}
                element={<VendorExpenses />}
            />
            <Route
                path={reportsUrls.costPerReasonForRepair}
                element={<CostPerResonForRepairCK14 />}
            />
            <Route
                path={reportsUrls.costPerEquipmentCategory}
                element={<CostPerEquipmentCategoryCK2 />}
            />
            <Route
                path={reportsUrls.intervals}
                element={<PMIntervals />}
            />
            <Route
                path={reportsUrls.defects}
                element={<Dvirs />}
            />
            <Route
                path="*"
                element={<Navigate to={urls.root} />}
            />
        </Routes>
    )
}

export default Reports

import { BoxProps } from '@mui/material'

import { Skeleton } from 'components'

import { Separator, InfoTitle, WidgetInfoContainer } from './styled'

const WidgetBodySeparator = ({ title }: Pick<WidgetInfoProps, 'title'>) => {
    return (
        <Separator>
            <InfoTitle
                variant="chartLabel"
                color="text.secondary"
                className="WidgetBaseInfoTitle"
            >
                {title}
            </InfoTitle>
        </Separator>
    )
}
export interface WidgetInfoProps {
    title: React.ReactNode | string
    content: React.ReactNode | string
    containerProps?: BoxProps
}
export const WidgetInfoSkeleton = () => {
    return (
        <WidgetInfoContainer
            sx={{
                padding: '16px',
            }}
        >
            <Skeleton />
        </WidgetInfoContainer>
    )
}
export const WidgetInfo = ({ title, content, containerProps }: WidgetInfoProps) => {
    return (
        <WidgetInfoContainer {...containerProps}>
            <WidgetBodySeparator title={title} />
            {content}
        </WidgetInfoContainer>
    )
}

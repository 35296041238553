import { FC, useState, useCallback, useEffect, forwardRef } from 'react'

import { CheckCircleOutline } from '@mui/icons-material'
import { Snackbar, useMediaQuery, darken, lighten, Theme } from '@mui/material'
import MuiAlert, { AlertProps, AlertColor, alertClasses } from '@mui/material/Alert'
import {
    useNotificationContext,
    undoableEventEmitter,
    NotificationPayload,
    useTranslate,
} from 'react-admin'

import { Modify } from 'appTypes'
import { NotificationMessageType } from 'hooks'

interface AlertCustomProps extends AlertProps {
    severity: AlertColor
    width?: string
}
const Alert = forwardRef<HTMLDivElement, AlertCustomProps>(
    ({ width, ...props }: AlertCustomProps, ref) => {
        return (
            <MuiAlert
                sx={(theme) => ({
                    width: width || 292,
                    [theme.breakpoints.down('sm')]: {
                        width: '100%',
                    },
                    color: darken(theme.palette[props.severity].main, 0.6),
                    background: lighten(theme.palette[props.severity].main, 0.9),
                    [`& .${alertClasses.message}`]: {
                        fontWeight: 400,
                        paddingBottom: 1,
                    },
                    [`& .${alertClasses.icon}`]: {
                        fontWeight: 500,
                        color: theme.palette[props.severity].main,
                    },
                })}
                iconMapping={{
                    success: <CheckCircleOutline fontSize="inherit" />,
                }}
                elevation={1}
                ref={ref}
                variant="filled"
                {...props}
            />
        )
    },
)

type ExtendedMessage = { message: string }
type MessageInfo = Modify<
    NotificationPayload,
    { message: string | ExtendedMessage | NotificationMessageType }
>

const Toast: FC = () => {
    // // react-admin copy-paste START

    const { notifications, takeNotification } = useNotificationContext()
    const translate = useTranslate()
    const [open, setOpen] = useState(false)
    const [messageInfo, setMessageInfo] = useState<MessageInfo>(undefined)

    const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

    useEffect(() => {
        if (notifications.length && !messageInfo) {
            // Set a new snack when we don't have an active one
            const currNotification = takeNotification()
            if (!currNotification) {
                return
            }
            setMessageInfo(currNotification)
            setOpen(true)
        } else if (notifications.length && messageInfo && open) {
            // Close an active snack when a new one is added
            setOpen(false)
        }
    }, [notifications, messageInfo, open, takeNotification])

    const handleRequestClose = useCallback(() => {
        setOpen(false)
    }, [setOpen])

    const handleExited = useCallback(() => {
        if (messageInfo && messageInfo.notificationOptions.undoable) {
            undoableEventEmitter.emit('end', { isUndo: false })
        }
        setMessageInfo(undefined)
    }, [messageInfo])

    if (!messageInfo) {
        return null
    }

    // // react-admin copy-paste END

    const messageArgs = messageInfo.notificationOptions.messageArgs || {}

    return (
        <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={handleRequestClose}
            TransitionProps={{ onExited: handleExited }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
                ...messageArgs.origin,
            }}
            sx={{ marginBottom: matches ? '60px' : 'none' }}
        >
            <Alert
                width={messageArgs.width}
                severity={messageInfo.type || 'success'}
                onClose={() => setOpen(false)}
                {...messageArgs.AlertProps}
            >
                {typeof messageInfo.message === 'function'
                    ? messageInfo.message()
                    : translate(
                          (messageInfo.message as ExtendedMessage)?.message ??
                              (messageInfo.message as string),
                      )}
            </Alert>
        </Snackbar>
    )
}

export default Toast

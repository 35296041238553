import { styled } from '@mui/material/styles'

import Typography from 'components/mui/Typography'

const HeaderTitle = styled(Typography)`
    margin-bottom: 0;
    text-overflow: ellipsis;
    overflow: hidden;
`

HeaderTitle.defaultProps = {
    variant: 'h5',
    color: 'text.primary',
    typography: { xs: 'h6', sm: 'h5' },
}

export default HeaderTitle

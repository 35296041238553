import { PropsWithChildren } from 'react'

import { inject } from 'mobx-react'
import { ListBase, ListControllerProps } from 'react-admin'

import { ResourceType, useResource } from 'components/resource'
import { AuthStore } from 'providers/authStore'

import ListPurify from './ListPurify'
import { useListView, ListViewMode } from './ListViewContext'

const ListBaseViewMode = inject('auth')(
    ({
        children,
        preferencesResource,
        auth,
        ...rest
    }: PropsWithChildren<
        ListControllerProps & {
            preferencesResource?: ResourceType
            auth?: AuthStore
        }
    >) => {
        const { viewMode } = useListView()
        const { name } = useResource(preferencesResource)
        const listPerPage = auth.preferences.resources[name]?.perPage || 10

        return (
            <ListBase
                {...rest}
                {
                    ...(viewMode === ListViewMode.list
                        ? { perPage: listPerPage }
                        : { page: 1, perPage: 100 }) // limit of free version of x-data-grid
                }
            >
                {children}
                <ListPurify perPage={listPerPage} />
            </ListBase>
        )
    },
)

export default ListBaseViewMode

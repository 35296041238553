import { Box } from '@mui/material'

import { Typography } from 'components/mui'

import PeriodSelectorInput from './PeriodSelectorInput'
import PeriodSelectorText from './PeriodSelectorText'

const PeriodSelector = () => {
    return (
        <Box
            display="flex"
            alignItems="center"
            color="text.secondary"
            justifyContent={{
                xs: 'space-between',
                md: 'flex-start',
            }}
        >
            <Box
                display="flex"
                alignItems="center"
            >
                <Typography
                    variant="caption"
                    color="inherit"
                    mr="8px"
                >
                    Period:
                </Typography>

                <PeriodSelectorInput />
            </Box>
            <Typography
                variant="caption"
                color="text.disabled"
                ml="8px"
            >
                <PeriodSelectorText />
            </Typography>
        </Box>
    )
}

export default PeriodSelector

import { useMemo } from 'react'

import { Delete } from '@mui/icons-material'

import { Button } from 'components'
import Typography from 'components/mui/Typography'
import { Columns } from 'components/styled'

import ListFilterRangeInput from '../ListFilterRangeInput'
import ListFilterSourceInput, {
    ListFilterSourceInputProps,
    RenderFilterComponentProps,
} from '../ListFilterSourceInput'
import ListFilterValueInput, { ListFilterValueInputProps } from '../ListFilterValueInput'

import { ListFilterItemHeader, ListFilterItemRoot } from './styled'

export interface ListFilterItemProps extends ListFilterValueInputProps, ListFilterSourceInputProps {
    removeFilterItem: () => void
    isFormEmpty: boolean
    filterType: ListFilterSourceInputProps['sourceChoices'][0]['filterType']
    renderComponent: ListFilterSourceInputProps['sourceChoices'][0]['renderComponent']
    filterKey2source: string[]
}

const ListFilterItem = ({
    source,
    label,
    inputKey,
    filterType,
    registerFilterItem,
    removeFilterItem,
    sourceChoices,
    isFormEmpty,
    filterKey2source,
    renderComponent,
}: ListFilterItemProps) => {
    const makeItemLabel = useMemo(() => {
        for (const item of sourceChoices) {
            if (item.id === source) {
                return item.makeItemLabel
            }
        }
    }, [source])

    const valueInputComponentProps: RenderFilterComponentProps = {
        key: source,
        inputKey,
        source,
        label,
        makeItemLabel,
    }
    let ValueInputComponentJSX

    if (renderComponent) {
        ValueInputComponentJSX = renderComponent(valueInputComponentProps)
    } else {
        let ValueInputComponent = ListFilterValueInput

        if (filterType === ('range' as const)) {
            ValueInputComponent = ListFilterRangeInput
        }
        ValueInputComponentJSX = <ValueInputComponent {...valueInputComponentProps} />
    }

    return (
        <ListFilterItemRoot>
            <ListFilterItemHeader>
                <Typography variant="subtitle2">Filter</Typography>
                {
                    // source && value && <CheckCircleRoundedIcon color="success" fontSize="small" />
                }
                {!isFormEmpty && (
                    <Button
                        size="small"
                        variant="outlined"
                        color="secondary"
                        startIcon={<Delete fontSize="large" />}
                        sx={{
                            border: 0,
                            '&:hover': { border: 0 },
                            letterSpacing: '0.46px',
                            lineHeight: '26px',
                            marginLeft: 'auto',
                            padding: '4px 5px',
                        }}
                        onClick={() => {
                            removeFilterItem()
                        }}
                    >
                        REMOVE
                    </Button>
                )}
            </ListFilterItemHeader>
            <Columns pt="10px">
                <ListFilterSourceInput
                    filterKey2source={filterKey2source}
                    key={inputKey}
                    inputKey={inputKey}
                    registerFilterItem={registerFilterItem}
                    sourceChoices={sourceChoices}
                />
                {ValueInputComponentJSX}
            </Columns>
        </ListFilterItemRoot>
    )
}

export default ListFilterItem

import { useDeferredValue, useEffect } from 'react'

import { useRecordContext } from 'react-admin'
import { useFormContext } from 'react-hook-form'

import { makeid } from 'utils'

export const formDirtyTriggerField = 'formDirtyTriggerField'

const UtilityDrawerFormMakeSubmittable = () => {
    const { setValue } = useFormContext()
    const record = useDeferredValue(useRecordContext())

    useEffect(() => {
        const triggerForm = () => {
            setValue(formDirtyTriggerField, makeid(), {
                shouldDirty: true,
                shouldTouch: true,
            })
        }
        triggerForm()
        setTimeout(triggerForm, 200)
    }, [record])

    return null
}

export default UtilityDrawerFormMakeSubmittable

import { FC } from 'react'

import { ArrowForward } from '@mui/icons-material'
import { useListContext, useRecordContext } from 'react-admin'

import { VendorContactModel, VendorModel } from 'appTypes/models'
import { SliderView, Section } from 'components'
import { Action, ActionsMenu } from 'components/actions'
import Icons from 'components/icons/'
import { useCreateResourcePath } from 'hooks'
import { BoxLink } from 'pages/Units/Meters/components'

import { contactActions } from '../../Contacts/actions'
import {
    VendorContactCard,
    VendorContactsEmpty,
    VendorContactDrawerToggler,
    VendorContactsHeader,
} from '../../Contacts/components'

const contactsActions: Action<VendorContactModel> = (params, { children }) => [
    <VendorContactDrawerToggler
        key="add-contact"
        children={({ onClick }) =>
            children({ Icon: Icons.Add, title: 'Add Contact Person', onClick })
        }
    />,
    children({
        Icon: Icons.GridView,
        title: 'See All',
        key: 'see-all',
        to: 'contacts',
    }),
]

const VendorContacts: FC = () => {
    const { data = [], total, isLoading } = useListContext<VendorContactModel>()
    const vendor = useRecordContext<VendorModel>() || ({} as VendorModel)

    const createPath = useCreateResourcePath()
    let content

    if (!isLoading) {
        if (total) {
            const items = data.map((contact) => (
                <VendorContactCard
                    contact={contact}
                    actions={contactActions}
                />
            ))
            if (total >= 4) {
                const path = createPath({
                    type: 'list',
                })
                items.push(
                    <BoxLink
                        to={path}
                        sx={{ height: '142px' }}
                        label="SEE ALL CONTACTS"
                        icon={<ArrowForward />}
                    />,
                )
            }
            content = <SliderView items={items} />
        } else {
            content = <VendorContactsEmpty />
        }
    }
    return (
        <Section>
            <VendorContactsHeader
                actions={
                    <ActionsMenu
                        record={vendor}
                        actions={contactsActions}
                    />
                }
            />
            {content}
        </Section>
    )
}

export default VendorContacts

import { FC, ReactNode } from 'react'

import { RecordContextProvider } from 'react-admin'

import { WorkOrderModel } from 'appTypes/models'
import { RaResourceContextProvider, UtilityDrawerEditor, useResource } from 'components'
import { useUtilityDrawerContext } from 'components/Drawer/UtilityDrawer'
import UtilityDrawerForm from 'components/Drawer/UtilityDrawerForm'
import {
    InputStateContextProvider,
    InputStateContextResult,
    inputStateAllDisabled,
} from 'components/context/InputStateContext'
import { vendorWorkOrderSerializer, workOrderSerializer } from 'pages/WorkOrders/config/constants'
import { useDataProviderConfig } from 'providers/dataProvider'
import { Serializer } from 'utils'

import { useStepsContext } from './UseWOStepsContext'
import { UseWOExtraState } from './useAttachWO'

const woInputState: InputStateContextResult<WorkOrderModel> = {
    inputs: {
        unit: {
            disabled: true,
        },
        vendor: {
            disabled: false,
        },
    },
}

const vendorWOSerializer: Serializer<WorkOrderModel> = vendorWorkOrderSerializer.map((field) =>
    typeof field === 'object' && field.name === 'vendor'
        ? { ...field, parse: 'emptyToNull' }
        : field,
)

const UseWOWOForm: FC<{ children: ReactNode }> = ({ children }) => {
    const { extraArgs, extra, updateState, state } = useUtilityDrawerContext()
    const isFromDvir = extra?.parentRecord?.dvirs
    const { next } = useStepsContext()
    const { id } = extraArgs
    const { unit, woType, workOrder, isVendor, parentRecord }: UseWOExtraState = extra
    const resource = useResource()

    const onSuccess = () => {
        next()
    }

    const woResource = resource.resource + '/' + parentRecord.id + '/work_order'

    useDataProviderConfig(woType ? woResource : '', {
        getOne: {
            makeId: () => '',
        },
        update: {
            makeId: () => '',
        },
    })

    if (woType) {
        return (
            <RaResourceContextProvider value={{ resource: woResource }}>
                <InputStateContextProvider value={woInputState}>
                    <UtilityDrawerEditor
                        submittableAtBeginning
                        successMessage=""
                        serializer={isVendor ? vendorWOSerializer : workOrderSerializer}
                        defaultValues={{
                            priority: 'LOW',
                            repairPriorityClass: isFromDvir ? 'NON_SCHEDULED' : 'SCHEDULED',
                            unit: unit.id,
                        }}
                        onSuccess={(wo: WorkOrderModel) => {
                            const extra: UseWOExtraState = { ...state.extra, workOrder: wo }
                            updateState({
                                ...state,
                                extra,
                                extraArgs: { ...state.extraArgs, id: wo.id },
                            })
                            onSuccess()
                        }}
                        disableCloseOnSubmit
                    >
                        {children}
                    </UtilityDrawerEditor>
                </InputStateContextProvider>
            </RaResourceContextProvider>
        )
    }

    if (id) {
        return (
            <InputStateContextProvider value={inputStateAllDisabled}>
                <RecordContextProvider value={workOrder}>
                    <UtilityDrawerForm
                        submittableAtBeginning
                        onSubmit={async () => onSuccess()}
                        disableCloseOnSubmit
                    >
                        {children}
                    </UtilityDrawerForm>
                </RecordContextProvider>
            </InputStateContextProvider>
        )
    }

    return null
}

export default UseWOWOForm

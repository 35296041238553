import { ReactNode } from 'react'

import { ListControllerProps, RaRecord } from 'react-admin'

import { SortType } from 'appTypes'
import { ResourceType } from 'components/resource'

import ListBaseViewMode from './ListBaseViewMode'
import ListSortDrawerProvider from './ListSortDrawerContext'
import ListViewProvider from './ListViewContext'
import ListFilterDrawerProvider from './filter/ListFilterDrawerContext'

export interface ListBaseProps<RecordType extends RaRecord = any> extends ListControllerProps {
    sort: SortType<RecordType>
    preferencesResource?: ResourceType
    children: ReactNode
}

const ListBase = <RecordType extends RaRecord = any>({
    children,
    sort,
    preferencesResource,
    ...rest
}: ListBaseProps<RecordType>) => {
    return (
        <ListViewProvider preferencesResource={preferencesResource}>
            <ListBaseViewMode
                {...rest}
                sort={sort}
                preferencesResource={preferencesResource}
            >
                <ListFilterDrawerProvider>
                    <ListSortDrawerProvider defaultSort={sort}>{children}</ListSortDrawerProvider>
                </ListFilterDrawerProvider>
            </ListBaseViewMode>
        </ListViewProvider>
    )
}

export default ListBase

import { createContext, PropsWithChildren, useContext } from 'react'

export const ArchivedContext = createContext(false)

export const useArchivedContext = () => useContext(ArchivedContext)

const ArchivedProvider = ({ value, children }: PropsWithChildren<{ value?: boolean }>) => {
    return <ArchivedContext.Provider value={value || false}>{children}</ArchivedContext.Provider>
}
export default ArchivedProvider

import { RaRecord } from 'react-admin'

import { Create } from 'components/resource'
import { CreateCustomProps } from 'components/resource/Create'

import { useUtilityDrawerContext } from './UtilityDrawer'
import { UtilityDrawerExtraProps } from './UtilityDrawerEditor'
import UtilityDrawerFormMakeSubmittable from './UtilityDrawerFormMakeSubmittable'
import UtilityDrawerWarnWhenUnsavedChanges from './UtilityDrawerWarnWhenUnsavedChanges'

export interface UtilityDrawerCreateCustomProps<RecordType extends RaRecord = any>
    extends CreateCustomProps<RecordType> {
    disableCloseOnSubmit?: boolean
    submittableAtBeginning?: boolean
}

interface UtilityDrawerCreateProps<RecordType extends RaRecord = any>
    extends UtilityDrawerCreateCustomProps<RecordType>,
        UtilityDrawerExtraProps {}

const UtilityDrawerCreate = <RecordType extends RaRecord = any>({
    disableCloseOnSubmit,
    submittableAtBeginning,
    ...props
}: UtilityDrawerCreateProps<RecordType>) => {
    const { forceClose } = useUtilityDrawerContext()
    const onSuccess = (params) => {
        if (!disableCloseOnSubmit) {
            forceClose()
        }
        props.onSuccess?.(params)
    }

    return (
        <Create
            disableSuccessRedirect
            {...props}
            onSuccess={onSuccess}
            warnWhenUnsavedChanges={false}
            children={
                <>
                    <UtilityDrawerWarnWhenUnsavedChanges />
                    {submittableAtBeginning && <UtilityDrawerFormMakeSubmittable />}
                    {props.children}
                </>
            }
        />
    )
}

export default UtilityDrawerCreate

import { useTheme } from '@mui/material'

import { Slider, SliderProps, gridItemLayoutColumns, useTelematicsContext } from 'components'

import {
    MonitorDVIR,
    MonitorIntegrations,
    MonitorPM,
    MonitorUnits,
    MonitorWorkOrder,
} from './Monitor'
const DashboardSlider = () => {
    const theme = useTheme()
    const { data } = useTelematicsContext()
    const sliderItems: SliderProps['items'] = [
        <MonitorPM key="pm" />,
        <MonitorWorkOrder key="wo" />,
        <MonitorUnits key="units" />,
        <MonitorIntegrations key="integrations" />,
    ]
    if (data.length) {
        sliderItems.unshift(<MonitorDVIR key="dvir" />)
    }
    return (
        <Slider
            items={sliderItems}
            slidesToShow={4}
            responsive={[
                {
                    breakpoint: theme.breakpoints.values.lg,
                    settings: {
                        slidesToShow: 12 / gridItemLayoutColumns.md,
                    },
                },
                {
                    breakpoint: theme.breakpoints.values.md,
                    settings: {
                        arrows: false,
                        slidesToShow: 1.9,
                        swipe: true,
                    },
                },
                {
                    breakpoint: theme.breakpoints.values.sm,
                    settings: {
                        arrows: false,
                        slidesToShow: 1.09,
                        swipe: true,
                    },
                },
            ]}
        />
    )
}

export default DashboardSlider

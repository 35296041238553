import { ListBase } from 'react-admin'

import { IconBox, PageContent } from 'components'
import Icons from 'components/icons/'

import { CreateJobDrawerToggler } from '../Jobs/config/constants'
import { useWoActionIsDisabled } from '../hooks'

import WorkOrderJobsResource from './WorkOrderJobsResource'
import WorkOrderJobsSection from './WorkOrderJobsSection'

const WorkOrderJobsOpenDrawerButton = () => {
    const disabled = useWoActionIsDisabled()
    if (disabled) {
        return null
    }

    return (
        <CreateJobDrawerToggler>
            {({ onClick }) => (
                <IconBox
                    title="Create Job"
                    onClick={onClick}
                >
                    <Icons.Add />
                </IconBox>
            )}
        </CreateJobDrawerToggler>
    )
}

const WorkOrderJobs = () => {
    return (
        <PageContent>
            <WorkOrderJobsResource>
                <ListBase perPage={100}>
                    <WorkOrderJobsSection
                        action={<WorkOrderJobsOpenDrawerButton />}
                        gridColumns={{ xs: 12 }}
                    />
                </ListBase>
            </WorkOrderJobsResource>
        </PageContent>
    )
}

export default WorkOrderJobs

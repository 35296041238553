import { useRef } from 'react'

import { Gallery, Typography } from 'components'

import PhotoButtonImage from './PhotoButtonImage'

const PhotoButton = ({ files }: { files: string[] }) => {
    const galleryOpener = useRef<{ open: (i: number) => void }>(null)
    const photoCount = files.length

    if (!files.length) {
        return null
    }

    return (
        <Gallery
            getOpener={(opener) => {
                galleryOpener.current = opener
            }}
        >
            <Typography
                component="span"
                color="primary.main"
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                    galleryOpener.current.open(0)
                }}
            >
                {photoCount}
            </Typography>
            {files.map((file) => (
                <PhotoButtonImage
                    key={file}
                    file={file}
                />
            ))}
        </Gallery>
    )
}
export default PhotoButton

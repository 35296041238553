import { useState } from 'react'

import { CircularProgress } from '@mui/material'
import {
    useGoogleLogin as useBaseGoogleLogin,
    UseGoogleLoginOptionsImplicitFlow,
} from '@react-oauth/google'

import { LoginWithGoogleData } from 'api'
import Google from 'assets/images/social-google.svg'
import { Typography, StyledImg } from 'components'
import Button from 'components/mui/Button'
import { useFinalErrorHandler, useAuthProvider } from 'hooks'

import { useRegisterContext } from '../Register/CompanyDetails'

import IdentityVerificationDialog from './IdentityVerificationDialog'

const GoogleButton = () => {
    const googleLogin = useGoogleLogin()

    return (
        <>
            <Button
                disabled={googleLogin.loading}
                disableElevation
                fullWidth
                sx={{
                    backgroundColor: (theme) => theme.palette.grey[50] + ' !important',
                    border: '1px solid',
                    borderColor: (theme) => theme.palette.grey[100],
                    height: '56px',
                    textTransform: 'none',
                    '&:hover': {
                        border: '0',
                        backgroundColor: (theme) => theme.palette.grey[50],
                    },
                    '& span': {
                        display: 'flex',
                    },
                }}
                onClick={() => googleLogin.googleLogin()}
                size="large"
                variant="contained"
            >
                <StyledImg
                    sx={{ mr: '10px' }}
                    src={Google}
                    alt="google"
                    width="24px"
                />
                <Typography
                    component="span"
                    pt="3px"
                    mr="10px"
                >
                    Continue with Google
                </Typography>
                {googleLogin.loading && <CircularProgress size="20px" />}
            </Button>
            {googleLogin.verificationRequired && (
                <IdentityVerificationDialog
                    loading={googleLogin.loading}
                    onClose={googleLogin.handleCancel}
                    login={googleLogin.login}
                    error={googleLogin.error}
                    onPasswordChange={googleLogin.onPasswordChange}
                />
            )}
        </>
    )
}

export default GoogleButton

type GoogleLoginOnSuccessResponse = Parameters<UseGoogleLoginOptionsImplicitFlow['onSuccess']>[0]

const useGoogleLogin = () => {
    const [error, setError] = useState('')
    const [tokens, setGoogleTokens] = useState<GoogleLoginOnSuccessResponse>(null)
    const [loading, setLoading] = useState(false)
    const authProvider = useAuthProvider()
    const errorHandler = useFinalErrorHandler()
    const { setUserState } = useRegisterContext()

    const login = async ({
        password,
        ...tokensParam
    }: GoogleLoginOnSuccessResponse & { password: string }) => {
        setLoading(true)
        const data: LoginWithGoogleData = {
            password,
            accessToken: tokensParam.access_token || tokens?.access_token,
        }
        await authProvider
            .loginWithGoogle(data)
            .then((resp) => {
                if (!resp.token) {
                    setUserState(data)
                    return
                }
                setGoogleTokens(null)
                setError('')
            })
            .catch((error) => {
                const errMsg = error.nonFieldErrors
                if (errMsg?.code === 'password_required') {
                    setGoogleTokens(tokensParam)
                } else if (errMsg?.code === 'invalid_password') {
                    setError('Invalid Password')
                } else if (error.confirmPassword) {
                    setError(error.confirmPassword)
                } else {
                    errorHandler(error, {
                        anonymous: true,
                    })
                }
                setLoading(false)
            })
    }

    const handleFailure = (response) => {
        console.warn('Failed to authenticate with Google.', response?.details)
    }

    const onPasswordChange = () => {
        setError('')
    }

    const handleCancel = () => setGoogleTokens(null)

    const googleLogin = useBaseGoogleLogin({
        onSuccess: login as any,
        onError: handleFailure as any,
    })

    return {
        loading,
        verificationRequired: !!tokens,
        login,
        error,
        handleCancel,
        onPasswordChange,
        googleLogin,
    }
}

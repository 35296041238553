import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined'
import { IconButton } from '@mui/material'

import { useCollapsibleController } from './CollapsibleContext'

const CollapsibleTogglerAction = ({ name }: { name?: string }) => {
    const { toggleExpand, isExpanded } = useCollapsibleController(name)

    return (
        <IconButton
            aria-label={isExpanded ? 'Collapse' : 'Expand'}
            onClick={toggleExpand}
            size="small"
            sx={{ width: '22px', height: '22px' }}
        >
            {isExpanded ? <KeyboardArrowUpOutlinedIcon /> : <KeyboardArrowDownOutlinedIcon />}
        </IconButton>
    )
}

export default CollapsibleTogglerAction

import { ReactElement } from 'react'

import { formHelperTextClasses } from '@mui/material'
import { Identifier, RaRecord } from 'react-admin'

import SelectInput from 'components/inputs/SelectInput'

export interface ListSortContentProps<RecordType extends RaRecord = any> {
    sortBy: { id: Identifier & keyof RecordType; label: string | ReactElement }[]
}

const orders = [
    { id: 'ASC', label: 'Ascending' },
    { id: 'DESC', label: 'Descending' },
]

const emptyChoices = []

const ListSortDrawerContent = ({ sortBy = emptyChoices }: ListSortContentProps) => {
    return (
        <>
            <SelectInput
                clearable={false}
                source="field"
                label="Column/Label"
                sx={{
                    marginBottom: '16px',
                    [`& .${formHelperTextClasses.root}`]: {
                        display: 'none',
                    },
                }}
                choices={sortBy}
                optionText="label"
                helperText={false}
                disableEmptyValue
            />

            <SelectInput
                clearable={false}
                source="order"
                label="Sort Order"
                sx={{
                    [`& .${formHelperTextClasses.root}`]: {
                        display: 'none',
                    },
                }}
                choices={orders}
                optionText="label"
                helperText={false}
                disableEmptyValue
            />
        </>
    )
}

export default ListSortDrawerContent

import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material'
import { styled } from '@mui/system'

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip
        {...props}
        classes={{ popper: className }}
    />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 192,
    },
})
export default CustomWidthTooltip

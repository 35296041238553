import { Alert, SvgIcon, styled } from '@mui/material'
import { BooleanInput, useRecordContext } from 'react-admin'

import { BoxContainer, SectionTitleSmall, Spacer, Typography } from 'components'
import { useUtilityDrawerContext } from 'components/Drawer/UtilityDrawer'

import IntegrationsUnitListDialog from '../IntegrationsUnitList/IntegrationsUnitListDialog'

import { ItemPropTypes } from './Item'
import { IntegrationDrawerToglerExtraProps } from './useIntegrationDrawerTogler'

const BoldSpan = styled('span')`
    font-weight: 500;
    white-space: nowrap;
`

const IntegrationConnectForm = () => {
    const { extra }: { extra: IntegrationDrawerToglerExtraProps } = useUtilityDrawerContext()
    const record = useRecordContext<ItemPropTypes['item']>()
    const {
        provider: name,
        syncMeters,
        syncDvirs,
        autosaveNewVehicles,
        isActive,
        fleetpalUserData,
    } = record

    return (
        <>
            <BoxContainer justifyContent="space-between">
                <Spacer>
                    <SvgIcon
                        inheritViewBox
                        sx={{
                            width: '24px',
                            height: '24px',
                            maxWidth: '100%',
                        }}
                        component={extra.provider.logo}
                    />
                    <Typography
                        variant="subtitle1"
                        textTransform="capitalize"
                    >
                        {name}
                    </Typography>
                </Spacer>

                {!extra.provider.isActive && <IntegrationsUnitListDialog telematic={record} />}
            </BoxContainer>
            <SectionTitleSmall
                mt="24px"
                variant="subtitle1"
            >
                Select info you want to sync
            </SectionTitleSmall>

            <BooleanInput
                source="syncMeters"
                helperText={false}
                defaultValue={syncMeters}
                label="Meters"
            />
            <BooleanInput
                source="syncDvirs"
                helperText={false}
                defaultValue={syncDvirs}
                label="DVIR"
            />

            <BooleanInput
                source="autosaveNewVehicles"
                helperText={false}
                defaultValue={autosaveNewVehicles}
                label="Automatically connect new incoming Units"
            />

            {name === 'samsara' && fleetpalUserData?.id === null && isActive === false && (
                <Alert
                    severity="info"
                    sx={{ mt: '18px' }}
                >
                    <Typography
                        color="text.primary"
                        variant="body2"
                    >
                        A new user with the email <BoldSpan>{fleetpalUserData.email}</BoldSpan> will
                        be created in Samsara.
                    </Typography>
                </Alert>
            )}
        </>
    )
}

export default IntegrationConnectForm

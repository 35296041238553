import { AddCommentOutlined } from '@mui/icons-material'
import { useShowContext, useUpdate } from 'react-admin'

import {
    Columns,
    GridContainer,
    GridItemLayout,
    IconBox,
    InfoBadge,
    Note,
    NotesEmptyCard,
    PageContent,
    TextareaInput,
    ViewHeader,
} from 'components'
import { useConfirm, useCreateResourcePath } from 'hooks'
import { maxLengthValidationText, requiredValidation } from 'utils'

export const noteInputValidations = [maxLengthValidationText, requiredValidation]

const NotesTab = ({ removeAddButton }: { removeAddButton?: boolean }) => {
    const [update] = useUpdate()
    const { record, refetch } = useShowContext()
    const createPath = useCreateResourcePath()
    const confirm = useConfirm()
    if (!record) {
        return null
    }
    const addNote = () =>
        confirm({
            title: 'Add Note',
            closeOnError: false,
            formProps: {
                warnWhenUnsavedChanges: true,
            },
            content: (
                <TextareaInput
                    source="note"
                    label="Notes"
                    parse={(v) => {
                        const s = String(v)
                        return s.trimStart()
                    }}
                    validate={noteInputValidations}
                />
            ),
            onConfirm: async ({ formValues }) => {
                await update(
                    createPath({
                        id: record.id,
                        type: 'edit',
                    }),

                    { data: { notesToAdd: [formValues.note] } },
                    {
                        returnPromise: true,
                    },
                )
                refetch()
            },
            confirmButtonProps: {
                size: 'small',
                startIcon: <AddCommentOutlined />,
                children: 'Add Note',
            },
            awaitOnConfirm: true,
        })
    const notesLenght = record?.notes?.length
    return (
        <PageContent>
            <ViewHeader title="Notes">
                <ViewHeader.Content>
                    <InfoBadge badgeContent={notesLenght || 0} />
                </ViewHeader.Content>
                {removeAddButton ? null : (
                    <ViewHeader.Content placement="rightMobile">
                        <IconBox
                            title="Add Note"
                            onClick={addNote}
                        >
                            <AddCommentOutlined />
                        </IconBox>
                    </ViewHeader.Content>
                )}
            </ViewHeader>
            <GridContainer>
                {notesLenght ? (
                    <GridItemLayout>
                        <Columns gap="10px">
                            {record.notes.map((obj, i) => (
                                <Note
                                    {...obj}
                                    key={i}
                                />
                            ))}
                        </Columns>
                    </GridItemLayout>
                ) : (
                    <NotesEmptyCard
                        action={addNote}
                        removeAddButton={removeAddButton}
                    />
                )}
            </GridContainer>
        </PageContent>
    )
}

export default NotesTab

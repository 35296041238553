import { ComponentProps } from 'react'

import { Box, BoxProps, Divider, styled } from '@mui/material'
import clsx from 'clsx'

import PageBlock from 'components/pages/PageBlock'

import headerClasses from './classes'

interface HeaderBlockProps extends ComponentProps<typeof PageBlock> {
    mobileCenter?: boolean
    divider?: false | 'hideOnMobile'
    innerContentProps?: BoxProps
}

const HeaderBlock = styled(
    ({
        className,
        mobileCenter,
        children,
        divider,
        innerContentProps,
        ...rest
    }: HeaderBlockProps) => (
        <>
            <PageBlock
                {...rest}
                className={clsx(className, mobileCenter && headerClasses.mobileCenter)}
            >
                <Box
                    className="inner-content"
                    {...innerContentProps}
                >
                    {children}
                </Box>
            </PageBlock>
            {divider === false ? null : (
                <Divider
                    sx={{
                        background: 'white',
                        display:
                            divider === 'hideOnMobile' ? { xs: 'none', sm: 'block' } : undefined,
                    }}
                />
            )}
        </>
    ),
)`
    padding-top: 14px;
    padding-bottom: 14px;
    min-height: 90px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    & .inner-content {
        gap: 24px;
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;

        ${({ theme }) => `
            ${theme.breakpoints.down('sm')} {
                gap: 16px;
            }
        `}
    }

    ${({ mobileCenter, theme }) =>
        mobileCenter
            ? `
        ${theme.breakpoints.down('sm')} {
            & .inner-content {
                grid-template-columns: 30px auto 30px;
                grid-template-areas: 
                    "aside-left main aside-right";
                display: grid;
                grid-auto-columns: 1fr;
                grid-auto-flow: column;
            }
        }
    `
            : ''}
`

export default HeaderBlock

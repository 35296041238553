import { FC } from 'react'

import { ListBase, useRecordContext } from 'react-admin'

import { UnitPMReminderModel } from 'appTypes/models'
import { FlexReverse, PageContent, RaResourceContextProvider, useArchivedContext } from 'components'
import { perPageLimit } from 'configs/constants'

import { getUnitResource } from '../config/constants'

import UnitPMDependentSection from './UnitPMDependentSection'
import UnitPMIndependentSection from './UnitPMIndependentSection'

const UnitPM: FC = () => {
    const record = useRecordContext()
    const isArchived = useArchivedContext()

    if (!record) {
        return null
    }

    const PMResource = `${getUnitResource(isArchived).resource}/${record.id}/pm-schedules`

    return (
        <PageContent>
            <RaResourceContextProvider value={{ resource: PMResource, name: 'intervals' }}>
                <FlexReverse>
                    <ListBase<UnitPMReminderModel>
                        filter={{ type: 'independent' }}
                        perPage={perPageLimit}
                    >
                        <UnitPMIndependentSection />
                    </ListBase>
                    <ListBase<UnitPMReminderModel>
                        filter={{ type: 'dependent' }}
                        perPage={perPageLimit}
                    >
                        <UnitPMDependentSection />
                    </ListBase>
                </FlexReverse>
            </RaResourceContextProvider>
        </PageContent>
    )
}

export default UnitPM

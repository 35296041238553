import { Breakpoint, styled } from '@mui/material'

import DataAvatar from 'components/DataAvatar'
import Typography from 'components/mui/Typography'
import PageBlock from 'components/pages/PageBlock'

export const StyledHeaderMainBlock = styled(PageBlock)`
    display: flex;
    align-items: center;
    background: ${({ theme }) => theme.palette.white};
    width: 100%;
    position: relative;
    padding-top: 16px;
    padding-bottom: 15px;
`

export const SHOW_HEADER_BREAKPOINT: Breakpoint = 'sm'

export const StyledHeaderDataAvatar = styled(DataAvatar)`
    width: 40px;
    height: 40px;
    margin-right: 10px;
    ${({ theme }) => `
    ${theme.breakpoints.up(SHOW_HEADER_BREAKPOINT)} {
        marginRight: 16px;
        width: 56px;
        height: 56px;
    }
`}
`

export const StyledHeaderTitle = styled(Typography)`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    ${({ theme }) => `
    ${theme.breakpoints.down(SHOW_HEADER_BREAKPOINT)} {
        fontWeight: 500;
        margin-bottom: 8px;
        font-size: 20px;
        line-height: 20px;
    }
`}
`

export default StyledHeaderMainBlock

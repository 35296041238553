import { Alarm } from '@mui/icons-material'
import { useTheme } from '@mui/material'

import { calculateWidgetValue, WidgetPieChartBase } from '../../components'
import { ComplianceRateResponse } from '../types'

interface ComplianceRateChartProps {
    validData: boolean
    data: ComplianceRateResponse
}
const ComplianceRateChart = ({ data, validData }: ComplianceRateChartProps) => {
    const { palette } = useTheme()
    const total = validData ? data.notOnTimeCount + data.onTimeCount : 1
    return (
        <WidgetPieChartBase
            valid={validData}
            icon={Alarm}
            renderContent={({ id, value, total }) => [
                value + ' ' + id + ' ' + calculateWidgetValue(total, value),
            ]}
            sortByValue={false}
            data={
                validData
                    ? [
                          {
                              id: 'On-time (Planned + Due Soon)',
                              value: data.onTimeCount,
                              color: palette.charts.greenBody,
                              total,
                          },
                          {
                              id: 'Not On-time (Overdue)',
                              value: data.notOnTimeCount,
                              color: palette.charts.red,
                              total,
                          },
                      ]
                    : []
            }
        />
    )
}
export default ComplianceRateChart

import { FC } from 'react'

import { Box, Stack } from '@mui/material'
import { useLogin } from 'react-admin'
import { useNavigate } from 'react-router-dom'

import {
    FormNonFieldErrors,
    CheckboxInput,
    Form,
    Typography,
    EmailInput,
    PasswordInput,
    AnchorLink,
} from 'components'
import { urls } from 'configs/config'
import { useDidMount, useSubmit } from 'hooks'
import { formErrors, formErrorToString, validatePassword, validateEmail } from 'utils'

import { withRegisterContext } from '../Register/CompanyDetails'
import { GoogleButton, AuthBody, AuthSubmitButton, SignDivider } from '../components'

interface LoginFormData {
    email: string
    password: string
    rememberMe?: boolean
}

const emailValidation = validateEmail(true)
const passwordValidation = validatePassword(false)

const Login: FC = () => {
    const login = useLogin()
    const navigate = useNavigate()

    const onSubmit = useSubmit<LoginFormData>(async (formValues) => {
        const next = new URLSearchParams(window.location.search).get('next')
        await login(formValues, next)
    })

    useDidMount(() => {
        // hack for browser autofill
        ;(document.querySelector('input[type="checkbox"]') as HTMLInputElement)?.click?.()
    })

    return (
        <AuthBody>
            <Form
                warnWhenUnsavedChanges={false}
                onSubmit={onSubmit}
                mode="onSubmit"
                formOnError={({ errors, defaultOnError }) => {
                    if (
                        formErrorToString(formErrors(errors).nonFieldErrors, 'code') ===
                        'inactive_company'
                    ) {
                        navigate(urls.deactivated)
                    }
                    defaultOnError()
                }}
            >
                <Typography
                    variant="h6"
                    mb="22px"
                >
                    Sign in with Email address
                </Typography>

                <FormNonFieldErrors />

                <EmailInput
                    validate={emailValidation}
                    label="Email Address"
                />
                <PasswordInput validate={passwordValidation} />

                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={0}
                >
                    <CheckboxInput
                        label="Remember me"
                        source="rememberMe"
                    />
                    <AnchorLink
                        to={urls.passwordReset}
                        pb="22px"
                    >
                        Forgot Password
                    </AnchorLink>
                </Stack>
                <AuthSubmitButton
                    label="Sign in"
                    disabled={false}
                />

                <Box
                    display="flex"
                    justifyContent="center"
                    marginTop="21px"
                >
                    <Typography display="inline">Don't have an account ?&nbsp;</Typography>

                    <AnchorLink to={urls.register}>Sign up!</AnchorLink>
                </Box>

                <SignDivider />

                <GoogleButton />
            </Form>
        </AuthBody>
    )
}

export default withRegisterContext(Login)

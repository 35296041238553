import { Tooltip } from '@mui/material'

import { ReactComponent as TruckRemoved } from 'assets/images/truck-remove-outline.svg'
import { StatusText } from 'components'
import { StyledSVG } from 'pages/Configure/Integrations/components/ConnectedUnitsStatus'
import { capitalize } from 'utils'

const UnitDeactivatedBadge = ({ provider }: { provider: string }) => {
    return (
        <Tooltip
            title={`Connection is established, but the unit is deactivated in ${capitalize(
                provider,
            )}.`}
        >
            <StatusText
                statusColor={(theme) => theme.palette.text.disabled}
                sx={{
                    boxSizing: 'border-box',
                    width: '16px',
                    height: '16px',
                    padding: '1.5px !important',
                }}
            >
                {' '}
                <StyledSVG component={TruckRemoved} />
            </StatusText>
        </Tooltip>
    )
}

export default UnitDeactivatedBadge

import { ResourceContextProvider } from 'react-admin'

import { WorkOrderModel } from 'appTypes/models'
import { Create, PageContent } from 'components'

import { VendorWorkOrderForm } from '../components'
import { vendorWorkOrderSerializer, woResource } from '../config/constants'
import { useWoLimit } from '../hooks'

import { VendorWorkOrderCreateHeader } from './components'
const VendorWorkOrderCreate = () => {
    const { hasError, woLimitReachedAction } = useWoLimit()

    return (
        <ResourceContextProvider value={woResource.resource + '/vendor'}>
            <Create<WorkOrderModel>
                redirect="edit"
                redirectInfo={{
                    resource: woResource,
                }}
                serializer={vendorWorkOrderSerializer}
                formOnError={({ errors, defaultOnError, reset }) => {
                    if (hasError(errors)) {
                        reset(
                            {},
                            {
                                keepValues: true,
                                keepDirty: true,
                                keepTouched: true,
                            },
                        )
                        woLimitReachedAction()
                        return
                    }

                    defaultOnError()
                }}
            >
                <VendorWorkOrderCreateHeader />
                <PageContent>
                    <VendorWorkOrderForm />
                </PageContent>
            </Create>
        </ResourceContextProvider>
    )
}

export default VendorWorkOrderCreate

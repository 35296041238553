import get from 'lodash/get'
import { RaRecord } from 'react-admin'

import { dateParse, dateTimeParse } from './date'
import {
    emptyNullParse,
    parseBoolean,
    parseEmail,
    parseFile,
    parseNumber,
    parseString,
    parseYear,
    selfValue,
} from './parse'
import { lowerCaseFormat } from './string'

const defaultParsers = {
    number: parseNumber,
    email: parseEmail,
    string: parseString,
    emptyToNull: emptyNullParse,
    file: parseFile,
    dateTime: dateTimeParse,
    boolean: parseBoolean,
    year: parseYear,
    date: dateParse,
    disableOnEdit: (value: any, data: RaRecord) => (data.id ? undefined : value),
    lowerCase: lowerCaseFormat,
    selfValue,
}

type singleParser<T, K extends keyof T> =
    | keyof typeof defaultParsers
    | ((value: T[K], data: T) => T[K] | any)

export type singleSerializer<T> = {
    [K in keyof T]: {
        name: K
        parse?: singleParser<T, K> | singleParser<T, K>[]
        fields?: Serializer<T[K]>
        disableOnEdit?: boolean
    }
}[keyof T]

export type Serializer<T = any> = (keyof T | singleSerializer<T>)[]

const stringSerialize = <RecordType = any>(
    key: keyof typeof defaultParsers,
    value: string,
    data: RecordType,
) => {
    return defaultParsers[key].call(this, value, data)
}
const serialize = <RecordType = any>(
    data: RecordType,
    serializer: Serializer<RecordType> | undefined,
) => {
    if (!serializer) {
        return data
    }

    const newData: {
        [key in keyof RecordType]?: any
    } = {}
    serializer.forEach((fieldSerializer) => {
        if (typeof fieldSerializer === 'object') {
            let value = get(data, fieldSerializer.name, '')
            const parser = fieldSerializer.parse
            const name = fieldSerializer.name
            const fields = fieldSerializer.fields

            if (fields) {
                value = serialize(data[name], fields)
            } else {
                ;(Array.isArray(parser) ? parser : [parser]).forEach((parserItem) => {
                    if (typeof parserItem === 'string') {
                        value = stringSerialize<RecordType>(parserItem, value, data)
                    } else if (typeof parserItem === 'function') {
                        value = parserItem(value, data)
                    }
                })
            }

            newData[name] = value
        } else {
            newData[fieldSerializer] = parseString(get(data, fieldSerializer, ''))
        }
    })

    return newData
}

export default serialize

import { SortType } from 'appTypes'
import { VendorModel } from 'appTypes/models'
import {
    CardListConfig,
    DatagridColumnsProps,
    StyledElement,
    List,
    ListBase,
    PageContent,
    ListAvatar,
    Typography,
    CardCollapsibleContent,
} from 'components'
import { DatagridLink } from 'components/Datagrid'
import {
    deleteManyFromListAction,
    deleteOneFromListAction,
    editRedirectInListAction,
    multiselectAction,
} from 'components/actions'
import { ListBulkActions } from 'components/list/ListSelection'
import { ListSortContentProps } from 'components/list/ListSortDrawerContent'
import { FilterConfig } from 'components/list/filter/ListFilterForm'
import { ListFilterChoices } from 'components/list/filter/ListFilterSourceInput'
import ListFilterValueInput, {
    dataProviderConfigProps,
} from 'components/list/filter/ListFilterValueInput'
import { statesObject } from 'configs/constants'
import {
    TagsCollapsibleContent,
    TagsCollapsibleIconHolder,
    TagsField,
} from 'pages/Configure/TagsManagement'
import { formatDate, displayState, phoneMaskResolver, prettyText } from 'utils'

import {
    deleteVendorParams,
    vendorAddressInfo,
    vendorAvatarConfig,
    vendorPaymentMethodsObject,
    vendorTypesObject,
} from '../constants/config'

import { VendorListHeader } from './components'

const defaultSort: SortType<VendorModel> = {
    field: 'created',
    order: 'DESC',
}

const sortConfig: ListSortContentProps<VendorModel> = {
    sortBy: [
        {
            id: 'created',
            label: 'Created on',
        },
        {
            id: 'name',
            label: 'Name',
        },
        {
            id: 'state',
            label: 'State',
        },
    ],
}

const filtersConfig: FilterConfig<VendorModel> = {
    filters: [
        {
            id: 'state',
            label: 'State',
            renderComponent: (params) => (
                <ListFilterValueInput
                    {...params}
                    makeItemLabel={(option) => option.name || option.id}
                    dataProviderProps={{
                        ...dataProviderConfigProps,
                        getList: {
                            ...dataProviderConfigProps.getList,
                            prepareDataAfterResponse: (data, query) => {
                                return dataProviderConfigProps.getList
                                    .prepareDataAfterResponse(data, query)
                                    .map((state) => ({
                                        id: state.id,
                                        name:
                                            statesObject[state.id] &&
                                            displayState({
                                                name: statesObject[state.id].name,
                                                abbr: statesObject[state.id].abbr,
                                            }),
                                    }))
                            },
                        },
                    }}
                />
            ),
        },
        { id: 'city', label: 'City' },
        {
            id: 'paymentMethod',
            label: 'Default Payment Method',
            renderComponent: (params) => (
                <ListFilterValueInput
                    {...params}
                    makeItemLabel={(option) => option && prettyText(String(option.id))}
                />
            ),
        },
        {
            id: 'paymentTerm',
            label: 'Default Payment Term',
        },
        { id: 'tags', label: 'Tags' },
    ],
}

export const vendorFilters = filtersConfig.filters.reduce((filters, filter) => {
    filters[filter.id] = filter
    return filters
}, {} as { [key in keyof VendorModel]: ListFilterChoices<VendorModel>[0] })

const bulkActions: ListBulkActions<VendorModel> = ({ children }) => [
    deleteManyFromListAction({
        confirmConfig: {
            title: 'Are you sure you want to delete the selected Vendors?',
        },
        children,
    }),
]
const columnsConfig: DatagridColumnsProps<VendorModel> = {
    resetColumns: {
        nationalAccount: false,
        paymentMethod: false,
        paymentTerm: false,
        phone: false,
        email: false,
        website: false,
        created: false,
        tagsData: false,
    },
    columns: [
        {
            field: 'logo',
            headerName: 'Avatar',
            maxWidth: 72,
            renderCell: (cell) => (
                <ListAvatar
                    linkProps={{ 'aria-label': `View Vendor ${cell.row.name}` }}
                    id={cell.id}
                    imageSrc={cell.value}
                    defaultImage={<vendorAvatarConfig.Icon />}
                />
            ),
        },
        {
            field: 'name',
            headerName: 'Company Name',
            renderCell: (params) => <DatagridLink {...params} />,
        },
        {
            field: 'type',
            headerName: 'Vendor Type',
            renderCell: ({ value }) => (
                <StyledElement sx={{ textTransform: 'capitalize' }}>
                    {vendorTypesObject[value as keyof typeof vendorTypesObject]?.name}
                </StyledElement>
            ),
        },
        {
            field: 'address',
            headerName: 'Address Line 1',
        },
        {
            field: 'address2',
            headerName: 'Address Line 2',
        },
        {
            field: 'city',
            headerName: vendorAddressInfo.label,
            renderCell: ({ row }) => vendorAddressInfo.value(row),
            toExport: {
                separate: [
                    {
                        field: 'city',
                        headerName: 'City',
                    },
                    {
                        field: 'state',
                        headerName: 'State',
                    },
                    {
                        field: 'zipCode',
                        headerName: 'Zip',
                    },
                ],
            },
        },
        {
            field: 'taxId',
            headerName: 'Tax ID',
        },
        {
            field: 'nationalAccount',
            headerName: 'National Account',
        },
        {
            field: 'paymentMethod',
            headerName: 'Default Payment Method',
            renderCell: ({ row }) => vendorPaymentMethodsObject[row.paymentMethod]?.name,
        },
        {
            field: 'paymentTerm',
            headerName: 'Default Payment Term',
            renderCell: ({ row }) => row.paymentTermData?.name,
        },
        {
            field: 'phone',
            headerName: 'Company Phone',
            valueFormatter: ({ value }) => phoneMaskResolver(value),
        },
        {
            field: 'email',
            headerName: 'Company Email',
        },
        {
            field: 'website',
            headerName: 'Website',
        },
        {
            field: 'created',
            headerName: 'Created on',
            flex: 1,
            valueFormatter: ({ value }) => {
                return formatDate(value, (dateFormats) => dateFormats.shortenedDateTime)
            },
        },
        {
            field: 'tagsData',
            headerName: 'Tags',
            renderCell: ({ value }) => <TagsField tags={value} />,
        },
    ],
    actions: ({ row }, { children }) => [
        editRedirectInListAction({
            children,
            id: row.id,
        }),
        deleteOneFromListAction({
            children,
            id: row.id,
            ...deleteVendorParams,
        }),
    ],
}

const cardsConfig: CardListConfig<VendorModel> = {
    titleSource: 'name',
    subTitleSource: (record) => (
        <Typography
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            color="text.primary"
        >
            {record.type?.replaceAll('_', ' ')}
        </Typography>
    ),
    imageSource: 'logo',
    defaultImage: <vendorAvatarConfig.Icon />,
    details: [
        {
            label: 'Address Line 1',
            source: 'address',
        },
        {
            label: 'Address Line 2',
            source: 'address2',
        },
        {
            label: vendorAddressInfo.label,
            source: 'city',
            render: (_, vendor) => vendorAddressInfo.value(vendor),
        },
        {
            label: 'Tax ID',
            source: 'taxId',
        },
    ],
    collapsibleContent: (record) => (
        <CardCollapsibleContent
            content={[
                {
                    iconHolder: (
                        <TagsCollapsibleIconHolder
                            tags={record.tagsData}
                            key="tagsIcon"
                        />
                    ),
                    component: (
                        <TagsCollapsibleContent
                            tags={record.tagsData}
                            key="tagsContent"
                        />
                    ),
                },
            ]}
        />
    ),
    actions: ({ id }, { children }) => [
        editRedirectInListAction({
            children,
            id,
        }),
        multiselectAction({
            children,
            id,
        }),
        deleteOneFromListAction({
            children,
            id,
            ...deleteVendorParams,
        }),
    ],
}

const VendorsList = () => {
    return (
        <ListBase sort={defaultSort}>
            <VendorListHeader />
            <PageContent>
                <List
                    bulkActions={bulkActions}
                    sortCfg={sortConfig}
                    columnsCfg={columnsConfig}
                    cardsCfg={cardsConfig}
                    filtersCfg={filtersConfig}
                    listFTUProps={{
                        linkText: 'Create Vendor',
                        secondaryTitle: 'Would you like to create one?',
                    }}
                />
            </PageContent>
        </ListBase>
    )
}

export default VendorsList

import { ReactNode, FC } from 'react'

import { Card as MuiCard, CardContent, Link, styled, Box } from '@mui/material'

import backgroundImage from 'assets/images/auth_bg.svg'
import logo from 'assets/images/logo.svg'
import { StyledImg } from 'components'
import { landingPageUrl } from 'configs/config'

const Card = styled(MuiCard)`
    box-shadow: ${({ theme }) => theme.shadows[3]};
    & > * {
        flex-grow: 1;
        flex-basis: 50%;
    }
    width: 356px;
    ${({ theme }) => theme.breakpoints.down('sm')} {
        width: 335px;
    }
`

const StyledImageWrapper = styled('div')`
    width: 100%;
    height: 100vh;
    position: fixed;
    overflow: hidden;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`

interface AuthBodyProps {
    children: ReactNode
}

const AuthBody: FC<AuthBodyProps> = ({ children }) => {
    return (
        <>
            <StyledImageWrapper>
                <StyledImg
                    src={backgroundImage}
                    sx={{
                        position: 'absolute',
                        height: 'calc(100% + 170px)',
                        top: '-85px',
                        left: 'calc(50% - 216px)',
                    }}
                    alt="background"
                />
            </StyledImageWrapper>
            <Box
                position="relative"
                display="flex"
                justifyContent="center"
            >
                <div>
                    <Link
                        href={landingPageUrl}
                        target="_blank"
                        rel="noreferrer"
                        mb="24px"
                        mt="40px"
                        display="inline-block"
                    >
                        <img
                            src={logo}
                            width={162}
                            alt="Fleetpal"
                        />
                    </Link>
                    <Card>
                        <CardContent
                            sx={{
                                p: '24px !important',
                            }}
                        >
                            {children}
                        </CardContent>
                    </Card>
                </div>
            </Box>
        </>
    )
}

export default AuthBody

import { useContext, createContext, ReactNode, useState, useEffect } from 'react'

import api from 'api'
import authStore from 'providers/authStore'
import { makeid } from 'utils'

interface SyncContextResult {
    subscribeSync: () => () => void
}

const SyncContext = createContext<SyncContextResult>(null)

interface SyncProviderProps {
    children: ReactNode
}

export const SyncProvider = ({ children }: SyncProviderProps) => {
    const [sync, setSync] = useState<string[]>([])

    const shouldSync = Boolean(sync.length)

    useEffect(() => {
        if (shouldSync) {
            let timer
            const request = async () => {
                const user = await api.getCurrentUser()
                if (!user) {
                    return
                }
                authStore.action(() => {
                    authStore.user.activeTasks = user.activeTasks
                    authStore.currentCompany.activeTasks =
                        user.membership?.company?.activeTasks || []
                })
                timer = setTimeout(request, 10000)
            }

            timer = setTimeout(request, 5000)

            return () => {
                clearTimeout(timer)
            }
        }
    }, [shouldSync])

    const subscribeSync = () => {
        const id = makeid()
        setSync((oldSync) => [...oldSync, id])

        return () => {
            setSync((oldSync) => oldSync.filter((item) => item !== id))
        }
    }

    return (
        <SyncContext.Provider
            value={{
                subscribeSync,
            }}
        >
            {children}
        </SyncContext.Provider>
    )
}

export const useSync = (isActive: boolean = true) => {
    const { subscribeSync } = useContext(SyncContext)

    useEffect(() => {
        if (isActive) {
            const unsubscribe = subscribeSync()
            return unsubscribe
        }
    }, [isActive])
}

type Condition = boolean | ((tasks: string[]) => boolean)

export const useUserTaskSync = (condition: Condition = true): boolean => {
    const activeTasks = authStore.user.activeTasks

    const syncing =
        Boolean(activeTasks.length) &&
        (typeof condition === 'function' ? condition(activeTasks) : condition)

    useSync(syncing)

    return syncing
}

export const useCompanyTaskSync = (condition: Condition = true): boolean => {
    const activeTasks = authStore.currentCompany.activeTasks

    const syncing =
        Boolean(activeTasks.length) &&
        (typeof condition === 'function' ? condition(activeTasks) : condition)

    useSync(syncing)

    return syncing
}

import { useStoreContext } from 'react-admin'

import { ConfirmConfig } from 'components'

export interface UseConfirmParams extends ConfirmConfig {}

const useConfirm = () => {
    const { setItem } = useStoreContext()
    return (params: ConfirmConfig) => {
        setItem('confirm', params)
    }
}

export default useConfirm

import { ReactNode } from 'react'

import {
    ArrowDownward,
    ArrowUpward,
    DragHandle,
    CalendarTodayOutlined,
    EventBusyOutlined,
    InsertInvitationOutlined,
} from '@mui/icons-material'
import { Identifier } from 'react-admin'

import { StatusType } from 'appTypes'
import { WorkOrderStatusPermissions, WorkOrderModel, WorkOrderType } from 'appTypes/models'
import { ResourceAvatar } from 'appTypes/models/ResourceAvatar'
import { ReactComponent as VendorWOIcon } from 'assets/images/vendorsWO.svg'
import { ResourceType } from 'components'
import { ConfirmConfig, ConfirmContentWithFetchOne } from 'components/Confirm'
import Icons from 'components/icons'
import { DeleteOneParams, UseFileDownloadParams } from 'hooks'
import { generateResource, Serializer } from 'utils'

export const woResource = generateResource({
    name: 'work-orders',
    resource: 'work-orders',
})

export const poResource = generateResource({
    resource: 'purchase-orders',
    name: 'purchase-orders',
})

export const workOrderStatuses: {
    [key in WorkOrderModel['status']]: StatusType & {
        permission: keyof WorkOrderStatusPermissions
        changeable: (status: WorkOrderModel['status']) => boolean
    }
} = {
    OPEN: {
        text: 'Open',
        iconColor: (theme) => theme.palette.info.main,
        permission: 'reopen',
        changeable: (status) => status !== 'CANCELED',
    },
    CANCELED: {
        text: 'Canceled',
        iconColor: (theme) => theme.palette.error.main,
        permission: 'cancel',
        changeable: (status) => status !== 'CLOSED',
    },
    CLOSED: {
        text: 'Closed',
        iconColor: (theme) => theme.palette.success.main,
        permission: 'close',
        changeable: (status) => status !== 'CANCELED',
    },
}

export const workOrderStatusesArray = Object.entries(workOrderStatuses).map(([key, value]) => ({
    id: key as WorkOrderModel['status'],
    name: value.text,
    iconColor: value.iconColor,
    permission: value.permission,
    changeable: value.changeable,
}))

interface ConfigStatusType {
    text: string

    icon: ReactNode
}

export const priorities: {
    [key in WorkOrderModel['priority']]: ConfigStatusType
} = {
    HIGH: {
        text: 'High',
        icon: <ArrowUpward fontSize="small" />,
    },
    MEDIUM: {
        text: 'Medium',
        icon: <DragHandle fontSize="small" />,
    },
    LOW: {
        text: 'Low',
        icon: <ArrowDownward fontSize="small" />,
    },
}

export const prioritiesArray = Object.entries(priorities).map(([key, value]) => ({
    id: key,
    name: value.text,
    icon: value.icon,
}))

export const repairClass: {
    [key in WorkOrderModel['repairPriorityClass']]: ConfigStatusType
} = {
    SCHEDULED: {
        text: 'Scheduled',
        icon: (
            <InsertInvitationOutlined
                color="success"
                fontSize="small"
            />
        ),
    },
    NON_SCHEDULED: {
        text: 'Non-Scheduled',
        icon: (
            <EventBusyOutlined
                color="warning"
                fontSize="small"
            />
        ),
    },
    EMERGENCY: {
        text: 'Emergency',
        icon: (
            <CalendarTodayOutlined
                color="error"
                fontSize="small"
            />
        ),
    },
}

export const repairClassArray = Object.entries(repairClass).map(([key, value]) => ({
    id: key,
    name: value.text,
    icon: value.icon,
}))

export const pdfOneWOArgs = (
    record: WorkOrderModel,
): UseFileDownloadParams & { id: Identifier } => ({
    id: record.id,
    filename: record.number,
    type: 'pdf',
})
export const vendorWODeleteDialogAction = (): {
    confirmConfig: ConfirmConfig
} => ({
    confirmConfig: {
        cancelButtonProps: {
            children: 'Close',
        },
        confirmButtonProps: null,
        title: 'This Vendor Work Order cannot be deleted because a related Invoice exists.',
    },
})

export const deleteWorkOrderAction = (
    { id, purchaseOrderData }: WorkOrderModel,
    resource: ResourceType,
): DeleteOneParams => ({
    confirmConfig: {
        title: purchaseOrderData
            ? 'Are you sure you want to delete this Vendor Work Order?'
            : 'Are you sure you want to delete this Work Order?',
        content: (
            <ConfirmContentWithFetchOne<WorkOrderModel>
                id={id}
                resource={resource}
                render={({ jobsCount, jobsItemsCount }) =>
                    purchaseOrderData ? (
                        <>
                            This Vendor Work Order contains {jobsCount} jobs and {jobsItemsCount}{' '}
                            line items
                        </>
                    ) : (
                        <>
                            This Work Order contains {jobsCount} jobs and {jobsItemsCount} line
                            items
                        </>
                    )
                }
            />
        ),
    },
    onError: ({ error, defaultOnError, confirm }) => {
        error.nonFieldErrors?.code === 'has_invoices'
            ? confirm(vendorWODeleteDialogAction().confirmConfig)
            : defaultOnError()
    },
})

export const workOrderSerializer: Serializer<WorkOrderModel> = [
    { name: 'unit', parse: ['emptyToNull', 'disableOnEdit'] },
    'priority',
    'repairPriorityClass',
    {
        name: 'started',
        parse: 'dateTime',
    },
    'description',
    { name: 'meterType', parse: 'emptyToNull' },
    { name: 'meterValue', parse: 'number' },
]
export const vendorWorkOrderSerializer: Serializer<WorkOrderModel> = [
    ...workOrderSerializer,
    { name: 'vendor', parse: ['emptyToNull', 'disableOnEdit'] },
]

const woCreateResourceInternal = { ...woResource, resource: woResource.resource + '/internal' }
const woCreateResourceVendor = { ...woResource, resource: woResource.resource + '/vendor' }

export const woCreateResource = (type: WorkOrderType = 'internal') =>
    type === 'vendor' ? woCreateResourceVendor : woCreateResourceInternal

export const woPermissions = {
    reopen: 'reopen',
    close: 'close',
    cancel: 'cancel',
}

export const woAvatarConfig: ResourceAvatar = {
    Icon: Icons.WorkOrders,
}
export const vwoAvatarConfig: ResourceAvatar = {
    Icon: VendorWOIcon,
}
export const woGetAvatarConfig = (record: WorkOrderModel) =>
    record.purchaseOrderData ? vwoAvatarConfig : woAvatarConfig

import { Button } from 'components'
import Icons from 'components/icons'
import { urls } from 'configs/config'
import { useConfirm, useLogout, useAuthProvider, useFinalErrorHandler } from 'hooks'

const DeactivateButton = () => {
    const confirm = useConfirm()
    const authProvider = useAuthProvider()
    const logout = useLogout({
        logoutParams: {
            redirectUrl: urls.deactivate,
        },
    })
    const errorHandler = useFinalErrorHandler()

    return (
        <Button
            variant="contained"
            color="error"
            startIcon={<Icons.Close />}
            size="large"
            onClick={() => {
                confirm({
                    title: 'Are you sure you want to deactivate the company account?',
                    confirmButtonProps: {
                        children: 'deactivate',
                        startIcon: <Icons.Close />,
                    },
                    onConfirm: async () => {
                        try {
                            await authProvider.deactivateCompany()
                            logout()
                        } catch (err) {
                            errorHandler(err)
                        }
                    },
                    confirmType: 'DELETE',
                })
            }}
        >
            deactivate Account
        </Button>
    )
}

export default DeactivateButton

import { VendorModel } from 'appTypes/models'
import {
    CollapsibleContent,
    CollapsibleTogglerAction,
    InfoCardDetails,
    infoCardDetailsGap,
    InfoCardHeader,
    CollapsibleInfoCard,
} from 'components'
import { vendorPaymentMethodsObject } from 'pages/Vendors/constants/config'

const FinancialInformation = () => {
    return (
        <CollapsibleInfoCard>
            <InfoCardHeader
                title="Financial Information"
                action={<CollapsibleTogglerAction />}
            />
            <InfoCardDetails<VendorModel>
                details={[
                    {
                        label: 'Tax ID',
                        source: 'taxId',
                    },
                    {
                        label: 'Default Payment Method',
                        source: ({ paymentMethod }) =>
                            vendorPaymentMethodsObject[paymentMethod]?.name,
                    },
                    {
                        label: 'Default Payment Term',
                        source: ({ paymentTermData }) => paymentTermData?.name,
                    },
                ]}
            />
            <CollapsibleContent>
                <InfoCardDetails<VendorModel>
                    mt={infoCardDetailsGap}
                    details={[
                        {
                            label: 'National Account',
                            source: 'nationalAccount',
                        },
                    ]}
                />
            </CollapsibleContent>
        </CollapsibleInfoCard>
    )
}

export default FinancialInformation

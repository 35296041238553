import { BoxContainer, ListTotalBadge, useArchivedContext } from 'components'
import Header from 'components/pages/header'
import { urls } from 'configs/config'

import UnitsListDescription from './UnitsListDescription'

const UnitsListHeader = () => {
    const isArchived = useArchivedContext()
    return (
        <Header bgColor="transparent">
            <Header.Block>
                <Header.Main>
                    <BoxContainer
                        sx={(theme) => ({
                            gap: '31px',
                            [theme.breakpoints.down('sm')]: {
                                justifyContent: 'space-between',
                            },
                        })}
                    >
                        <Header.Spacer>
                            <Header.Title>Units</Header.Title>
                            <ListTotalBadge />
                        </Header.Spacer>
                        <UnitsListDescription
                            route1={urls.unitsArchived}
                            route2={urls.units}
                        />
                    </BoxContainer>
                </Header.Main>
                {!isArchived && (
                    <Header.Aside fixed>
                        <Header.AddButton>CREATE UNIT</Header.AddButton>
                    </Header.Aside>
                )}
            </Header.Block>
        </Header>
    )
}

export default UnitsListHeader

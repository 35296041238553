import { TextFieldProps } from '@mui/material'
import { alpha } from '@mui/material/styles'
import IMask, { AnyMaskedOptions } from 'imask'
import parsePhoneNumber from 'libphonenumber-js'
import { InputProps } from 'react-admin'

import IMaskInput from 'components/inputs/IMaskInput'
import { IMaskInputProps } from 'components/inputs/IMaskInput/IMaskInput'
import Typography from 'components/mui/Typography'

import type { Theme } from '@mui/material'

type MaskType = AnyMaskedOptions & Pick<IMaskInputProps, 'changeValueOnBlur' | 'calculatedProps'>

export const textMask = {
    mask: String,
}

export const numberMask: MaskType = {
    mask: Number,
    signed: true,
    thousandsSeparator: ',',
    radix: '.',
    max: Number('9'.repeat(15)),
}
export const integerMask: MaskType = {
    ...numberMask,
    scale: 0,
}
export const integerPositiveMask: MaskType = {
    ...integerMask,
    signed: false,
    validate: (value: string) => {
        return value !== '0'
    },
}
export const integerNonNegativeMask: MaskType = {
    ...integerMask,
    signed: false,
}

export const integerNonNegativeSpacedMask: MaskType = {
    ...integerNonNegativeMask,
    thousandsSeparator: ' ',
}
export const integerSpacedMask: MaskType = {
    ...integerMask,
    thousandsSeparator: ' ',
}
export const doubleMask: MaskType = {
    ...numberMask,
    changeValueOnBlur: (value) => {
        if (value === '-0') {
            return ''
        }
        return undefined
    },
}

export const floatMask: MaskType = {
    ...doubleMask,
    scale: 3,
}
export const floatPositiveMask: MaskType = {
    ...floatMask,
    signed: false,
}
export const floatNonNegativeMask: MaskType = {
    ...floatMask,
    signed: false,
}

export const decimalMask: MaskType = {
    ...doubleMask,
    scale: 2,
}

export const priceMask: MaskType = { ...floatMask, normalizeZeros: false }

export const quantityMask: MaskType = floatMask

export const priceNonNegativeMask: MaskType = {
    ...priceMask,
    signed: false,
}

export const paymentCheckMask: MaskType = {
    mask: '000000000000000000000000000000',
    eager: true,
    calculatedProps: ({ value }) => ({
        eager: Boolean(value),
    }),
}
export const phoneMask: MaskType = {
    mask: '(000) 000-0000',
    eager: true,
    validate: (value: string) => {
        return value !== '('
    },
    calculatedProps: ({ value }) => ({
        eager: Boolean(value),
    }),
}

// format masks

const upperCaseMask: MaskType = {
    mask: /(.*?)/,
    prepare(str) {
        return str.toUpperCase()
    },
}

// resolvers

export const createMaskResolver = (params: AnyMaskedOptions) => {
    const mask = IMask.createMask(params)
    return (value: string | number) => {
        return mask.resolve(String(value))
    }
}

export const phoneMaskResolver = (phone: string) => {
    if (typeof phone !== 'string') {
        return ''
    }
    const parser = parsePhoneNumber(phone.at(0) === '+' ? phone : `+${phone}`)
    if (!parser || !parser.isValid()) {
        return
    }
    return parser.formatInternational()
}

export const priceMaskResolver = createMaskResolver(priceMask)
export const normalizedPriceMaskResolver = createMaskResolver({
    ...priceMask,
    normalizeZeros: false,
})
export const integerMaskResolver = createMaskResolver(integerMask)
export const floatMaskResolver = createMaskResolver(floatMask)
export const integerNonNegativeSpacedMaskResolver = createMaskResolver(integerNonNegativeSpacedMask)
export const integerSpacedMaskResolver = createMaskResolver(integerSpacedMask)

export const resolveDoublePriceMask = (value: number) => {
    if (typeof value === 'undefined' || value === null) {
        return value
    }
    const price = normalizedPriceMaskResolver(String(Math.abs(value || 0).toFixed(2)))
    const isNegative = value < 0 ? '-' : ''
    return `${isNegative}$${price}`
}
export const resolveFloatMask = floatMaskResolver
export const resolveIntegerMask = integerMaskResolver
export const resolveIntegerSpacedMask = integerNonNegativeSpacedMaskResolver

// input masks

interface MaskExtraParams extends Pick<TextFieldProps, 'inputProps' | 'InputProps'> {}

export const inputMask = (params: MaskType) => {
    return ({ inputProps, InputProps }: MaskExtraParams = {}): Partial<TextFieldProps> &
        Partial<InputProps> => {
        return {
            InputProps: {
                ...InputProps,
                inputComponent: IMaskInput,
            },
            inputProps: {
                ...inputProps,
                ...params,
            },
        }
    }
}

export const inputIntegerMask = inputMask(integerMask)
export const inputIntegerMaskParams = inputIntegerMask()

export const inputIntegerSpacedMask = inputMask(integerSpacedMask)
export const inputIntegerSpacedMaskParams = inputIntegerSpacedMask()

export const inputIntegerPositiveMask = inputMask(integerPositiveMask)
export const inputIntegerPositiveMaskParams = inputIntegerPositiveMask()

export const inputIntegerNonNegativeSpacedMask = inputMask(integerNonNegativeSpacedMask)
export const inputIntegerNonNegativeSpacedMaskParams = inputIntegerNonNegativeSpacedMask()

export const inputpaymentCheckMask = inputMask(paymentCheckMask)
export const inputpaymentCheckMaskparams = inputpaymentCheckMask()
export const inputQtyMask = inputMask(quantityMask)
export const inputQtyMaskParams = inputQtyMask()

export const inputPriceMask = inputMask(priceMask)
export const inputPriceMaskParams = inputPriceMask()

export const inputPriceNonNegativeMask = inputMask(priceNonNegativeMask)
export const inputPriceNonNegativeMaskParams = inputPriceNonNegativeMask()

export const customInputIntegerNonNegativeSpacedMask = (value: number) => {
    const newMask = { ...integerNonNegativeSpacedMask, max: Number('9'.repeat(value)) }
    const inputCistomMask = inputMask(newMask)
    return inputCistomMask()
}
const costMaskExtraParams: MaskExtraParams = {
    InputProps: {
        startAdornment: (
            <Typography
                variant="body1"
                sx={{
                    marginRight: '8px',
                    color: (theme: Theme) => alpha(theme.palette.grey[900], 0.54),
                }}
            >
                $
            </Typography>
        ),
    },
}

export const costMaskParams = inputPriceMask(costMaskExtraParams)
export const costNonNegativeMaskParams = inputPriceNonNegativeMask(costMaskExtraParams)
export const costDisabledNonNegativeMaskParams = inputPriceNonNegativeMask(costMaskExtraParams)

export const inputPhoneMask = inputMask(phoneMask)
export const inputPhoneMaskParams = inputPhoneMask()

export const formatPercent = (value: number, useAdaptivePrecision: boolean = false) => {
    if (typeof value === 'undefined' || value === null) {
        return value
    }
    if (value <= 0) {
        value = 0
    }
    if (value >= 100) {
        value = 100
    }
    if (value > 0 && value < 0.1) {
        value = 0.1
    }
    if (useAdaptivePrecision && value < 10) {
        const rounded = Math.round(value)
        const rounded1 = Number.parseFloat(value.toFixed(1))
        if (rounded === rounded1) {
            value = rounded
        } else {
            value = rounded1
        }
    } else {
        value = Math.round(value)
    }
    return `${value}%`
}

export const formatDuration = (seconds: number, short: boolean = false) => {
    const days = Math.floor(seconds / (60 * 60 * 24))
    const hours = parseInt(new Date(seconds * 1000).toISOString().substr(11, 8).split(':')[0])
    if (short) {
        return `${days}d ${hours}h`
    }
    return `${days} days ${hours} hours`
}

export const inputUppercaseMask = inputMask(upperCaseMask)
export const inputUppercaseMaskParams = inputUppercaseMask()

// TODO: create prop for masks in inputs

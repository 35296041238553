import { PartModel } from 'appTypes/models'
import { ResourceAvatar } from 'appTypes/models/ResourceAvatar'
import Icons from 'components/icons'
import { DeleteOneParams } from 'hooks'
import { generateResource, Serializer } from 'utils'

export const partsResourceName = 'parts'

export const partsResource = generateResource({ resource: 'parts', name: 'parts' })

export const unitsOfMeasure = [
    {
        value: 'BX',
        label: 'BOX',
    },
    {
        value: 'CS',
        label: 'CASE',
    },
    {
        value: 'DIS',
        label: 'DISPLAY',
    },
    {
        value: 'EA',
        label: 'EACH',
    },
    {
        value: 'FT',
        label: 'FEET',
    },
    {
        value: 'GAL',
        label: 'GALLON',
    },
    {
        value: 'HR',
        label: 'LABOR HOUR',
    },
    {
        value: 'IN',
        label: 'INCH',
    },
    {
        value: 'LB',
        label: 'POUND',
    },
    {
        value: 'MI',
        label: 'MILE',
    },
    {
        value: 'OZ',
        label: 'OUNCE',
    },
    {
        value: 'PK',
        label: 'PACK',
    },
    {
        value: 'PR',
        label: 'PAIR',
    },
    {
        value: 'PT',
        label: 'PINT',
    },
    {
        value: 'QT',
        label: 'QUART',
    },
]

export const unitsOfMeasureObject = unitsOfMeasure.reduce((obj, current) => {
    obj[current.value] = current.label
    return obj
}, {})

export const deletePartAction: DeleteOneParams = {
    confirmConfig: {
        title: 'Are you sure you want to delete the selected Part?',
    },
}

export const partSerializer: Serializer<PartModel> = [
    'number',
    'description',
    { name: 'image', parse: 'file' },
    { name: 'cost', parse: 'number' },
    'universalProductCode',
    'unitOfMeasure',
    'positionApplicable',
    'serializedPart',
    'manufacturerPartNumber',
    { name: 'component', parse: 'emptyToNull' },
    { name: 'manufacturer', parse: 'emptyToNull' },
    { name: 'tags', parse: 'emptyToNull' },
]
export const partsAvatarConfig: ResourceAvatar = {
    Icon: Icons.Parts,
}

import { ReactElement, useMemo, useState } from 'react'

import { Box, BoxProps, useTheme } from '@mui/material'
import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js'
import { StripeElementChangeEvent, StripeElementStyle } from '@stripe/stripe-js'

import { GridContainerColumns, GridItem, Typography } from 'components'

interface InputWrapperProps extends Omit<BoxProps, 'children'> {
    children: (params: { onChange: (e: StripeElementChangeEvent) => void }) => ReactElement
}

const InputWrapper = ({ children, ...rest }: InputWrapperProps) => {
    const [error, setError] = useState<StripeElementChangeEvent['error']>()

    const changeHandler = (e: StripeElementChangeEvent) => {
        setError(e.error)
    }

    return (
        <>
            <Box
                display="block"
                component="label"
                borderRadius="4px"
                padding="10px 10px"
                border="1px solid #0000003b"
                {...rest}
            >
                {children({
                    onChange: changeHandler,
                })}
            </Box>
            <Typography
                height="40px"
                color="error.main"
                variant="caption"
                display="block"
            >
                {error?.message}
            </Typography>
        </>
    )
}

const PaymentFormContent = () => {
    const theme = useTheme()

    const style: StripeElementStyle = useMemo(
        () => ({
            base: {
                ...theme.typography.inputText,
                color: theme.palette.text.main,
            },
            invalid: {
                color: theme.palette.error.main,
            },
        }),
        [],
    )

    return (
        <>
            <InputWrapper>
                {({ onChange }) => (
                    <CardNumberElement
                        options={{
                            showIcon: true,
                            style,
                        }}
                        onChange={onChange}
                    />
                )}
            </InputWrapper>
            <div />
            <GridContainerColumns>
                <GridItem xs={6}>
                    <InputWrapper>
                        {({ onChange }) => (
                            <CardExpiryElement
                                options={{ style }}
                                onChange={onChange}
                            />
                        )}
                    </InputWrapper>
                </GridItem>

                <GridItem xs={6}>
                    <InputWrapper>
                        {({ onChange }) => (
                            <CardCvcElement
                                options={{ style }}
                                onChange={onChange}
                            />
                        )}
                    </InputWrapper>
                </GridItem>
            </GridContainerColumns>
        </>
    )
}

export default PaymentFormContent

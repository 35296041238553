import { Link as MuiLink, LinkProps } from '@mui/material'
import { Link } from 'react-router-dom'

const AnchorLink = (props: LinkProps<typeof Link>) => (
    <MuiLink
        component={Link}
        {...props}
    />
)

export default AnchorLink

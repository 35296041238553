import { useEffect, useRef } from 'react'

import { Box, ButtonProps, Dialog, Divider } from '@mui/material'
import { useStore } from 'react-admin'

import { BoxContainer, SimpleScrollbar, Typography } from 'components'
import Form from 'components/form/Form'
import Icons from 'components/icons'
import { useSetBlocker, useSubmit } from 'hooks'

import CancelButton from './CancelButton'
import { ConfirmConfig, ConfirmTypes } from './Confirm'
import ConfirmButton from './ConfirmButton'

const typeProps: {
    [key in ConfirmTypes]: {
        confirmButtonProps?: ButtonProps
    }
} = {
    DELETE: {
        confirmButtonProps: {
            color: 'error',
            startIcon: <Icons.Delete />,
            children: 'DELETE',
        },
    },
    INFO: {
        confirmButtonProps: {
            variant: 'contained',
        },
    },
    ARCHIVE: {
        confirmButtonProps: {
            color: 'secondary',
            startIcon: <Icons.Archive />,
            children: 'Archive',
        },
    },
    UNARCHIVE: {
        confirmButtonProps: {
            color: 'secondary',
            startIcon: <Icons.Unarchive />,
            children: 'Unarchive',
        },
    },
}

const ConfirmContent = () => {
    const [confirm, setConfirm] = useStore<ConfirmConfig | null>('confirm', null)
    const canClose = useRef(true)
    const action = useRef(false)

    const {
        title,
        onConfirm,
        content,
        confirmType = 'INFO',
        confirmButtonProps,
        awaitOnConfirm,
        fullPageLoader,
        onClose,
        closeOnError = true,
        cancelButtonProps,
        divideContent,
        formProps,
        disableEnforceFocus,
    } = confirm || ({} as ConfirmConfig)

    const close = () => {
        if (canClose.current) {
            setConfirm(null)
        }
    }

    const defaultProps = typeProps[confirmType]

    useSetBlocker({
        close: () => setConfirm(null),
    })

    useEffect(() => {
        return () => {
            onClose?.(action.current)
        }
    }, [])

    const submit = useSubmit(async (values) => {
        let errors
        if (onConfirm) {
            if (awaitOnConfirm) {
                canClose.current = false
            }
            try {
                await onConfirm({ formValues: values })
            } catch (err) {
                errors = err
            } finally {
                if (awaitOnConfirm) {
                    canClose.current = true
                }
            }
        }
        action.current = true
        if (errors && !closeOnError) {
            throw errors
        }
        close()
    })

    return (
        <Dialog
            disableEnforceFocus={disableEnforceFocus}
            PaperProps={{
                sx: {
                    width: '414px',
                    boxSizing: 'border-box',
                    m: 0,
                    maxHeight: 'fit-content',
                },
            }}
            open
            onClose={close}
            aria-labelledby="Confirm"
        >
            <SimpleScrollbar
                sx={{
                    maxHeight: 'calc(100vh - 40px)',
                }}
            >
                <Form
                    warnWhenUnsavedChanges={false}
                    {...formProps}
                    onSubmit={submit}
                >
                    <Box
                        position="sticky"
                        top="0"
                        bgcolor="white"
                        paddingTop="24px"
                        zIndex="5"
                    >
                        {title && (
                            <>
                                <Typography
                                    px="24px"
                                    pb="12px"
                                    variant="subtitle1"
                                    component="div"
                                    display="flex"
                                    alignItems="center"
                                >
                                    {title}
                                </Typography>
                                {divideContent && <Divider />}
                            </>
                        )}
                    </Box>

                    {content ? (
                        <Typography
                            variant="body2"
                            padding="12px 24px"
                            component="div"
                            color="textPrimary"
                            sx={{ '&:empty': { display: 'none' } }}
                        >
                            {content}
                        </Typography>
                    ) : null}

                    <Box
                        position="sticky"
                        bottom="0"
                        bgcolor="white"
                        zIndex="5"
                    >
                        {divideContent && <Divider />}
                        <BoxContainer
                            justifyContent="space-between"
                            padding="12px 24px 24px"
                        >
                            <CancelButton
                                {...cancelButtonProps}
                                onClick={() => {
                                    action.current = false
                                    close()
                                }}
                            />
                            {confirmButtonProps !== null && (
                                <ConfirmButton
                                    {...defaultProps.confirmButtonProps}
                                    {...(confirmButtonProps as unknown as ButtonProps)}
                                    type={onConfirm ? 'submit' : 'button'}
                                    fullPageLoader={fullPageLoader}
                                />
                            )}
                        </BoxContainer>
                    </Box>
                </Form>
            </SimpleScrollbar>
        </Dialog>
    )
}
export default ConfirmContent

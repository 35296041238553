import { useListContext } from 'react-admin'

import { UnitPMReminderModel } from 'appTypes/models'
import { IconBox, ListTotalBadge, Section, SliderView, useArchivedContext } from 'components'
import Icons from 'components/icons'
import ViewHeader from 'components/pages/headers/ViewHeader'

import useUnitPMDrawerEditor from './PMEditor/useUnitPMDrawerEditor'
import UnitPMCard from './UnitPMCard/UnitPMCard'
import UnitPMEmpty from './UnitPMEmpty'

const UnitPMIndependentSection = () => {
    const listController = useListContext<UnitPMReminderModel>()
    const isArchived = useArchivedContext()

    const open = useUnitPMDrawerEditor()
    let content

    if (listController.total) {
        content = (
            <SliderView
                height="312px"
                items={listController.data?.map((item) => (
                    <UnitPMCard
                        unitPmRecord={item}
                        key={item.id}
                    />
                ))}
            />
        )
    } else {
        content = <UnitPMEmpty type="independent" />
    }

    return (
        <Section>
            <ViewHeader title="Independent">
                <ListTotalBadge />
                <ViewHeader.Content placement="rightMobile">
                    {!isArchived && (
                        <IconBox
                            title="Add Interval"
                            onClick={() => {
                                open({ type: 'independent' })
                            }}
                        >
                            <Icons.Add />
                        </IconBox>
                    )}
                </ViewHeader.Content>
            </ViewHeader>
            {content}
        </Section>
    )
}

export default UnitPMIndependentSection

import { useShowContext } from 'react-admin'

import { IconBox, SliderView, useArchivedContext, Section, ViewHeader, TagsCard } from 'components'
import Icons from 'components/icons'

import { UnitEditDrawerToggler, UnitSliderSkeleton } from '../../components'

import { BasicDetails, Specifications, Engine, Transmission } from './Slides'

const skeletonSlides = new Array(4).fill(0).map((v, i) => <UnitSliderSkeleton key={i} />)
const UnitDetails = () => {
    const isArchived = useArchivedContext()
    const { isLoading } = useShowContext()

    const slides = [
        <BasicDetails />,
        <UnitEditDrawerToggler>
            {({ onClick }) => (
                <TagsCard
                    open={onClick}
                    hideAddButton={isArchived}
                />
            )}
        </UnitEditDrawerToggler>,
        <Specifications />,
        <Engine />,
        <Transmission />,
    ]
    return (
        <Section>
            <ViewHeader
                title="Details"
                loading={isLoading}
            >
                <ViewHeader.Content placement="rightMobile">
                    {isArchived ? null : (
                        <UnitEditDrawerToggler>
                            {({ onClick }) => (
                                <IconBox
                                    title="Edit"
                                    onClick={onClick}
                                >
                                    <Icons.Edit />
                                </IconBox>
                            )}
                        </UnitEditDrawerToggler>
                    )}
                </ViewHeader.Content>
            </ViewHeader>
            <SliderView
                height="154px"
                items={isLoading ? skeletonSlides : slides}
            />
        </Section>
    )
}

export default UnitDetails

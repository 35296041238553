import QrCode2OutlinedIcon from '@mui/icons-material/QrCode2Outlined'
import { useRecordContext } from 'react-admin'

import { PartModel } from 'appTypes/models'
import { ShowHeaderSkeleton, TabType } from 'components'
import { deleteOneAction, downloadOneAction, exportAction } from 'components/actions'
import { Action } from 'components/actions/Actions'
import Icons from 'components/icons'
import Header from 'components/pages/header'
import PartsSubheaderTooltip from 'pages/Parts/components/PartsSubheaderTooltip'
import { deletePartAction } from 'pages/Parts/config/constants'

const actions: Action<PartModel> = (record, { children }) => [
    downloadOneAction({
        children: (params) =>
            children({
                ...params,
                Icon: QrCode2OutlinedIcon,
                key: 'export-pdf',
                title: 'Export QR code',
            }),
        id: record.id,
        filename: `Part-${record.number}-Label`,
        type: 'pdf',
    }),
    exportAction({
        children,
    }),
    deleteOneAction({
        children,
        ...deletePartAction,
    }),
]
const PartShowHeader = ({ tabs }: { tabs: TabType[] }) => {
    const part = useRecordContext<PartModel>()
    if (!part) {
        return <ShowHeaderSkeleton />
    }
    return (
        <Header>
            <Header.Block divider="hideOnMobile">
                <Header.BackButton />
                <Header.Main>
                    <Header.Info
                        title={<Header.Title>{part.number}</Header.Title>}
                        avatar={
                            <Header.Avatar
                                imageSrc={part.image}
                                defaultImage={<Icons.Parts />}
                            />
                        }
                        subheader={
                            <PartsSubheaderTooltip>{part.description}</PartsSubheaderTooltip>
                        }
                    />
                </Header.Main>
                <Header.Aside>
                    <Header.Actions actions={actions} />
                </Header.Aside>
            </Header.Block>
            <Header.Tabs
                tabs={tabs}
                tabWidth="192px"
                tabMinWidth="171px"
            />
        </Header>
    )
}

export default PartShowHeader

import { Tooltip, useTheme, Box } from '@mui/material'
import { alpha } from '@mui/material/styles'
import { inject, observer } from 'mobx-react'

import { SortType } from 'appTypes'
import {
    CardCollapsibleContent,
    CardListConfig,
    formatCK33CodeByLevel,
    List,
    ListAvatar,
    ListBase,
    NotesCollapsibleContent,
    NotesCollapsibleIconHolder,
    PageContent,
    PhotoButton,
    PhotosCollapsibleIconHolder,
    PhotosCollapsibleContent,
} from 'components'
import { DatagridLink } from 'components/Datagrid'
import {
    deleteManyFromListAction,
    deleteOneFromListAction,
    editRedirectInListAction,
    EditRedirectInListAction,
    exportAction,
    multiselectAction,
} from 'components/actions'
import { ListBulkActions } from 'components/list/ListSelection'
import ListFilterDateRangeValueInput from 'components/list/filter/ListFilterDateRangeValueInput'
import { FilterConfig } from 'components/list/filter/ListFilterForm'
import ListFilterRangeInput from 'components/list/filter/ListFilterRangeInput'
import ListFilterValueInput, {
    prepareFilterResource,
} from 'components/list/filter/ListFilterValueInput'
import {
    TagsCollapsibleContent,
    TagsCollapsibleIconHolder,
    TagsField,
} from 'pages/Configure/TagsManagement'
import { AuthStore } from 'providers/authStore'
import { formatDate } from 'utils'
import { resolveDoublePriceMask, costMaskParams } from 'utils/masks'

import { partCk33Label, PartCk33Label } from '../components'
import { deletePartAction, partsAvatarConfig, unitsOfMeasureObject } from '../config/constants'

import { PartsListHeader } from './components'

import type { CK33Model, PartModel } from 'appTypes/models'
import type { DatagridColumnsProps } from 'components'
import type { ListSortContentProps } from 'components/list/ListSortDrawerContent'

const getPhotos = (record: PartModel) => {
    let photos = []
    for (let i = 0; i <= 9; i += 1) {
        if (record['photo' + i]) {
            photos = [...photos, record['photo' + i]]
        }
    }
    return photos
}

const defaultPartSort: SortType<PartModel> = {
    field: 'created',
    order: 'DESC',
}
const sortCfg: ListSortContentProps<PartModel & { photos: string }> = {
    sortBy: [
        { id: 'created', label: 'Created on' },
        { id: 'number', label: 'Part Number' },
        { id: 'description', label: 'Description' },
        { id: 'component', label: <PartCk33Label /> },
        { id: 'manufacturer', label: 'Manufacturer' },
        { id: 'unitOfMeasure', label: 'UOM' },
        { id: 'manufacturerPartNumber', label: 'Manufacturer Part number' },
        { id: 'positionApplicable', label: 'Position Applicable' },
        { id: 'serializedPart', label: 'Serialized Part' },
        { id: 'photos', label: 'Photos' },
        { id: 'cost', label: 'Part Cost' },
    ],
}

const bulkActions: ListBulkActions = ({ children }) => [
    deleteManyFromListAction({
        children,
        confirmConfig: {
            title: 'Are you sure you want to delete the selected Parts',
        },
    }),
]

const filtersCfg: FilterConfig<PartModel & { photos: string }> = {
    filters: [
        {
            id: 'created',
            label: 'Created on',
            filterType: 'range',
            renderComponent: (props) => <ListFilterDateRangeValueInput {...props} />,
        },
        { id: 'number', label: 'Part Number' },
        { id: 'manufacturer', label: 'Manufacturer' },
        {
            id: 'cost',
            label: 'Cost',
            filterType: 'range' as const,
            renderComponent: (props) => (
                <ListFilterRangeInput
                    inputProps={costMaskParams}
                    {...props}
                />
            ),
        },
        { id: 'unitOfMeasure', label: 'UOM' },
        { id: 'tags', label: 'Tags' },
        { id: 'photos', label: 'Photos' },
        { id: 'positionApplicable', label: 'Position Applicable' },
        { id: 'serializedPart', label: 'Serialized Part' },
        {
            id: 'component',
            label: <PartCk33Label />,
            renderComponent: (props) => (
                <ListFilterValueInput
                    dataProviderProps={{
                        getList: {
                            prepareResource: prepareFilterResource,
                        },
                        getMany: {
                            prepareResource: () => 'vmrs/ck33',
                        },
                    }}
                    {...props}
                    makeItemLabel={(record: CK33Model) =>
                        formatCK33CodeByLevel(record.code, record.level) + ' ' + record.text
                    }
                />
            ),
        },
    ],
}

const PartsList = inject('auth')(
    observer(({ auth }: { auth?: AuthStore }) => {
        const theme = useTheme()

        const columnsCfg: DatagridColumnsProps<PartModel & { photos: string }> = {
            resetColumns: {
                tagsData: false,
                positionApplicable: false,
                serializedPart: false,
                photos: false,
                universalProductCode: false,
            },
            columns: [
                {
                    field: 'image',
                    headerName: 'Picture',
                    maxWidth: 72,
                    renderCell: (cell) => (
                        <ListAvatar
                            id={cell.id}
                            imageSrc={cell.value}
                            color={theme.palette.primary.main}
                            defaultImage={<partsAvatarConfig.Icon />}
                        />
                    ),
                },
                {
                    field: 'number',
                    headerName: 'Part Number',
                    flex: 1,
                    renderCell: (params) => <DatagridLink {...params} />,
                },
                {
                    field: 'description',
                    headerName: 'Description',
                    renderCell: ({ value }) =>
                        value ? (
                            <Tooltip
                                placement="bottom-start"
                                title={value}
                            >
                                <Box
                                    sx={{
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                    }}
                                >
                                    {value}
                                </Box>
                            </Tooltip>
                        ) : (
                            value
                        ),
                    flex: 1,
                },
                {
                    field: 'componentData',
                    headerName: partCk33Label(auth),
                    flex: 1,
                    valueGetter: ({ value }) => {
                        const record = value as CK33Model
                        return record
                            ? formatCK33CodeByLevel(record.code, record.level) + ' ' + record.text
                            : null
                    },
                },
                {
                    field: 'manufacturerData',
                    headerName: 'Manufacturer',
                    flex: 1,
                    valueGetter: ({ value }) => value?.description,
                },

                {
                    field: 'manufacturerPartNumber',
                    headerName: 'Manufacturer Part number',
                    flex: 1,
                },
                { field: 'universalProductCode', headerName: 'UPC', flex: 1 },
                {
                    field: 'positionApplicable',
                    headerName: 'Position Applicable',
                    valueGetter: ({ value }) => {
                        return value ? 'Yes' : 'No'
                    },
                },
                {
                    field: 'serializedPart',
                    headerName: 'Serialized Part',
                    valueGetter: ({ value }) => {
                        return value ? 'Yes' : 'No'
                    },
                },
                {
                    field: 'tagsData',
                    headerName: 'Tags',
                    renderCell: ({ value }) => <TagsField tags={value} />,
                },
                {
                    field: 'photos',
                    headerName: 'Photos',
                    renderCell: ({ row }) => <PhotoButton files={getPhotos(row)} />,
                },
                {
                    field: 'created',
                    headerName: 'Created on',
                    flex: 1,
                    renderCell: (cell) => {
                        return formatDate(
                            cell.value,
                            (dateFormats) => dateFormats.shortenedDateTime,
                        )
                    },
                },
                {
                    field: 'unitOfMeasure',
                    headerName: 'UOM',
                    renderCell: ({ value }) => {
                        const uomLabel = unitsOfMeasureObject[value]
                        const content = <div>{value}</div>
                        if (uomLabel) {
                            return <Tooltip title={uomLabel}>{content}</Tooltip>
                        }
                        return null
                    },
                },
                {
                    field: 'cost',
                    headerName: 'Part Cost',
                    headerAlign: 'right',
                    align: 'right',
                    valueFormatter: ({ value }) =>
                        resolveDoublePriceMask(value as PartModel['cost']),
                },
            ],
            actions: ({ row: part }, { children }) => [
                <EditRedirectInListAction
                    id={part.id}
                    children={children}
                    key="show"
                />,
                deleteOneFromListAction({
                    children,
                    id: part.id,
                    ...deletePartAction,
                }),
            ],
        }

        const cardsCfg: CardListConfig<PartModel> = {
            titleSource: 'number',
            subTitleSource: 'description',
            imageSource: 'image',
            defaultImage: <partsAvatarConfig.Icon />,
            withStatus: false,
            colorGetter: () => alpha(theme.palette.grey[900], 0.54),
            details: [
                {
                    source: 'componentData',
                    label: partCk33Label(auth),
                    render: (value) =>
                        value
                            ? formatCK33CodeByLevel(value.code, value.level) + ' ' + value.text
                            : null,
                },
                {
                    source: 'manufacturerData',
                    label: 'Manufacturer',
                    render: (value) => value?.description,
                },
                {
                    source: 'unitOfMeasure',
                    label: 'UOM',
                },
                {
                    source: 'cost',
                    label: 'Part Cost',
                    render: (value) => resolveDoublePriceMask(value),
                },
            ],
            collapsibleContent: (record) => (
                <CardCollapsibleContent
                    content={[
                        {
                            iconHolder: (
                                <TagsCollapsibleIconHolder
                                    tags={record.tagsData}
                                    key="tagsIcon"
                                />
                            ),
                            component: (
                                <TagsCollapsibleContent
                                    tags={record.tagsData}
                                    key="tagsContent"
                                />
                            ),
                        },
                        {
                            iconHolder: (
                                <NotesCollapsibleIconHolder
                                    notes={record.notes}
                                    key="notesIcon"
                                />
                            ),
                            component: (
                                <NotesCollapsibleContent
                                    notes={record.notes}
                                    key="notesContent"
                                />
                            ),
                        },
                        {
                            iconHolder: (
                                <PhotosCollapsibleIconHolder
                                    record={record}
                                    key="photosIcon"
                                />
                            ),
                            component: (
                                <PhotosCollapsibleContent
                                    record={record}
                                    key="photosContent"
                                />
                            ),
                        },
                    ]}
                />
            ),
            actions: ({ id }, { children }) => [
                editRedirectInListAction({
                    children,
                    id,
                }),
                exportAction({
                    children,
                }),
                multiselectAction({
                    children,
                    id,
                }),
                deleteOneFromListAction({
                    children,
                    id,
                    ...deletePartAction,
                }),
            ],
        }

        return (
            <ListBase sort={defaultPartSort}>
                <PartsListHeader />
                <PageContent>
                    <List
                        bulkActions={bulkActions}
                        sortCfg={sortCfg}
                        columnsCfg={columnsCfg}
                        cardsCfg={cardsCfg}
                        filtersCfg={filtersCfg}
                        listFTUProps={{
                            linkText: 'Add Part',
                            secondaryTitle: 'Would you like to add one?',
                        }}
                    />
                </PageContent>
            </ListBase>
        )
    }),
)

export default PartsList

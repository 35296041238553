import { FC, ReactElement } from 'react'

import { useListContext, useRecordContext } from 'react-admin'

import { InfoBadge, ViewHeader } from 'components'
import { formatDate } from 'utils'

import type { UnitModel } from 'appTypes/models'

interface UnitMetersSectionTitleProps {
    action: ReactElement
}

const UnitMetersSectionTitle: FC<UnitMetersSectionTitleProps> = ({ action }) => {
    const { total, isLoading } = useListContext()
    const record = useRecordContext<UnitModel>()

    let lastSynced = ''
    if (record.telematicsData && record.telematicsData?.telematicsStatus !== 'DISCONNECTED') {
        lastSynced =
            ' • Last Synced ' +
            formatDate(record.telematicsData.lastSyncedMeters, 'MMM dd, yyyy HH:mm')
    }

    return (
        <ViewHeader
            title="Meters"
            loading={isLoading}
        >
            <ViewHeader.Content>
                <InfoBadge badgeContent={String(total) + lastSynced} />
            </ViewHeader.Content>
            <ViewHeader.Content placement="rightMobile">{action}</ViewHeader.Content>
        </ViewHeader>
    )
}

export default UnitMetersSectionTitle

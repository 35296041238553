import { CheckCircleOutline, ErrorOutline, HelpOutlineOutlined } from '@mui/icons-material'
import { useTheme } from '@mui/material'
import { RecordContextProvider, useShowContext } from 'react-admin'

import api from 'api/api'
import { UnitModel } from 'appTypes/models'
import {
    BoxContainer,
    InfoCard,
    InfoCardDetails,
    Section,
    SectionTitle,
    Typography,
    Slider,
} from 'components'
import { useQuery } from 'hooks'
import { formatDate, resolveIntegerSpacedMask, titleCase } from 'utils'

type StatusMap = {
    [key in LastDvir['status']]: string
}
const statusMap: StatusMap = {
    safe: 'Safe',
    unsafe: 'Unsafe',
    resolved: 'Resolved',
    corrected: 'Corrected',
    harmless: 'Need Not To Be Corrected',
    satisfactory: 'Without Defects',
    satisfactory_with_defects: 'With Defects',
}

const LastSectionCard1 = ({ isLoading }: { isLoading: boolean }) => {
    return (
        <InfoCard>
            <InfoCardDetails<LastDvir>
                details={[
                    {
                        label: 'Status',
                        source: ({ status }) => {
                            if (isLoading) {
                                return null
                            }
                            const generalStatus = status ? status.toLowerCase() : null
                            const isKnown = Boolean(statusMap[generalStatus])
                            const isBad =
                                generalStatus === 'satisfactory_with_defects' ||
                                generalStatus === 'unsafe'
                            const text = isKnown ? statusMap[generalStatus] : 'Unknown'
                            const icon = isKnown ? (
                                isBad ? (
                                    <ErrorOutline />
                                ) : (
                                    <CheckCircleOutline />
                                )
                            ) : (
                                <HelpOutlineOutlined />
                            )
                            return (
                                <Typography
                                    component="div"
                                    color={
                                        isKnown
                                            ? `charts.${isBad ? 'red' : 'green'}`
                                            : 'text.disabled'
                                    }
                                >
                                    <BoxContainer gap="6px">
                                        {icon}
                                        {text}
                                    </BoxContainer>
                                </Typography>
                            )
                        },
                    },
                    {
                        label: 'Date',
                        source: ({ timestamp }) => formatDate(timestamp, 'MMM dd yyyy hh:mm a'),
                    },
                    {
                        label: 'Inspection Type',
                        source: ({ type }) => {
                            if (!type) {
                                return null
                            }
                            const baseType = type.toLowerCase()

                            if (baseType.includes('trip')) {
                                const tripType = baseType.split('trip')[0]
                                return titleCase(tripType) + '-trip'
                            }
                            return type.charAt(0).toUpperCase() + type.slice(1).toLowerCase()
                        },
                    },
                ]}
            />
        </InfoCard>
    )
}
const LastSectionCard2 = () => {
    return (
        <InfoCard>
            <InfoCardDetails<LastDvir>
                details={[
                    {
                        label: 'Author Name',
                        source: 'authorName',
                    },
                    {
                        label: 'Odometer',
                        source: ({ odometer }) =>
                            odometer && `${resolveIntegerSpacedMask(odometer)} mi`,
                    },
                    {
                        label: 'Location (Note)',
                        source: 'location',
                    },
                ]}
            />
        </InfoCard>
    )
}
type SamsaraDvirStatus = 'safe' | 'unsafe' | 'resolved'
type MotiveDvirStatus = 'corrected' | 'harmless' | 'satisfactory_with_defects' | 'satisfactory'
type LastDvir = {
    authorName: string
    id: string
    status: SamsaraDvirStatus | MotiveDvirStatus
    location: string
    odometer: number
    timestamp: string
    type: string
    unit: string
}
const UnitDvirLastSection = () => {
    const { record, resource } = useShowContext<UnitModel>()
    const theme = useTheme()
    const { data, isLoading } = useQuery<LastDvir>(['last_dvir'], async () => {
        const res = await api.get(`${resource}/${record.id}/last_dvir`)
        return res
    })
    const slides = [<LastSectionCard1 isLoading={isLoading} />, <LastSectionCard2 />]

    return (
        <Section>
            <SectionTitle as="div">Last DVIR</SectionTitle>
            <RecordContextProvider value={data}>
                <Slider
                    items={slides}
                    slidesToShow={4}
                    responsive={[
                        {
                            breakpoint: theme.breakpoints.values.lg,
                            settings: {
                                slidesToShow: 3,
                            },
                        },
                        {
                            breakpoint: theme.breakpoints.values.md,
                            settings: {
                                slidesToShow: 2,
                            },
                        },
                        {
                            breakpoint: theme.breakpoints.values.sm,
                            settings: {
                                arrows: false,
                                slidesToShow: 1.09,
                                swipe: true,
                            },
                        },
                    ]}
                />
            </RecordContextProvider>
        </Section>
    )
}
export default UnitDvirLastSection

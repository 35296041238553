import { ReactNode } from 'react'

import { Box, BoxProps } from '@mui/material'
import { RaRecord, useRecordContext } from 'react-admin'

import { ExtendRecordType } from 'appTypes'
import { extendRecord } from 'utils'

import { InfoCardLabelTypography, InfoCardRow, InfoCardValueTypography } from './styled'

export interface InfoCardDetailType<T extends RaRecord> extends BoxProps {
    details:
        | {
              label: string | ReactNode
              source: ExtendRecordType<T, string | number | ReactNode>
          }[]
        | ReactNode
    propRecord?: T
}
const InfoCardDetails = <T extends RaRecord>({
    details,
    propRecord,
    ...rest
}: InfoCardDetailType<T>) => {
    const contextRecord = useRecordContext<T>()
    const record = propRecord || contextRecord

    if (record === undefined) {
        return <></>
    }
    return (
        <Box {...rest}>
            {Array.isArray(details)
                ? details.map((detail, index) => (
                      <InfoCardRow
                          key={index}
                          className="infocard-row"
                      >
                          <Box
                              overflow="hidden"
                              textOverflow="ellipsis"
                          >
                              <InfoCardLabelTypography
                                  color="text.disabled"
                                  variant="tooltip"
                              >
                                  {detail.label}
                              </InfoCardLabelTypography>
                          </Box>
                          <Box
                              display="flex"
                              justifyContent="flex-end"
                              overflow="hidden"
                          >
                              <InfoCardValueTypography
                                  color="text.primary"
                                  variant="body2"
                                  component="div"
                              >
                                  {extendRecord(record, detail.source) || '-'}
                              </InfoCardValueTypography>
                          </Box>
                      </InfoCardRow>
                  ))
                : details}
        </Box>
    )
}

export default InfoCardDetails

import { ReactElement } from 'react'

import { ListControllerResult, useListContext } from 'react-admin'

import { ResourceType, useResource } from 'components/resource'

const WithListContext = (props: {
    resource?: string
    children: (context: ListControllerResult, resource: ResourceType) => ReactElement
}) => {
    const context = useListContext(props)
    const resource = useResource()
    return props.children(context, resource)
}

export default WithListContext

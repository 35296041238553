import { NotesTab, useArchivedContext } from 'components'
import { maxLengthValidationText, requiredValidation } from 'utils'

export const noteInputValidations = [maxLengthValidationText, requiredValidation]

const UnitNotes = () => {
    const isArchived = useArchivedContext()
    return <NotesTab removeAddButton={isArchived} />
}
export default UnitNotes

import { useUtilityDrawerContext } from 'components/Drawer/UtilityDrawer'

import { UseWOExtraState } from './useAttachWO'

const UseWOTitle = () => {
    const { extra, extraArgs } = useUtilityDrawerContext()
    const extraState: UseWOExtraState = extra || {}
    const { woType, isVendor } = extraState
    const { id } = extraArgs || {}

    if (woType) {
        return <>Use New {isVendor ? 'Vendor' : ''} Work Order</>
    }

    if (id) {
        return <>Use Existing {isVendor ? 'Vendor' : ''} Work Order</>
    }

    return <>Use New Work Order</>
}

export default UseWOTitle

import { FC } from 'react'

import Add from '@mui/icons-material/Add'
import { ButtonProps } from '@mui/material'
import { Link } from 'react-router-dom'

import { Button } from 'components'
import { basePermissions } from 'configs/constants'
import { renderOnPermission } from 'hocs'
import { useCreateResourcePath } from 'hooks'

export interface AddButtonProps extends Partial<ButtonProps<typeof Link>> {}

const AddButton: FC<AddButtonProps> = renderOnPermission((props) => {
    const createPath = useCreateResourcePath()

    return (
        <Button
            component={Link}
            to={createPath({ type: 'create' })}
            size="large"
            variant="contained"
            startIcon={<Add />}
            sx={{ letterSpacing: '0.46px', lineHeight: '26px', zIndex: 4 }}
            {...props}
        />
    )
}, basePermissions.create)

export default AddButton

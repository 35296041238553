import { inject, observer } from 'mobx-react'
import { Identifier, useShowContext } from 'react-admin'

import { SortType } from 'appTypes'
import { VendorModel } from 'appTypes/models'
import PurchaseHistoryFTU from 'assets/images/no-purchase-history.svg'
import {
    List,
    PageContent,
    ViewHeader,
    ListBase,
    ListTotalBadge,
    CardListConfig,
    Typography,
    ListAvatar,
    DatagridColumnsProps,
    IconBox,
} from 'components'
import {
    ActionChildren,
    deleteManyFromListAction,
    deleteOneFromListAction,
    multiselectAction,
} from 'components/actions'
import Icons from 'components/icons'
import { ListBulkActions } from 'components/list/ListSelection'
import { ListSortContentProps } from 'components/list/ListSortDrawerContent'
import ListFilterDateRangeValueInput from 'components/list/filter/ListFilterDateRangeValueInput'
import { FilterConfig } from 'components/list/filter/ListFilterForm'
import ListFilterRangeInput from 'components/list/filter/ListFilterRangeInput'
import { urls } from 'configs/config'
import LinkCardButton from 'pages/Reports/components/LinkCardButon'
import { vendorsResource } from 'pages/Vendors/constants/config'
import { AuthStore } from 'providers/authStore'
import { costMaskParams, formatDate, resolveDoublePriceMask } from 'utils'

import { PurchaseHistoryDrawerTogler, createResourcePath } from './components'
const actions = (id: Identifier, children: ActionChildren) => [
    <PurchaseHistoryDrawerTogler
        key="edit"
        id={id}
    >
        {(open) =>
            children({
                title: 'Edit',
                Icon: Icons.Edit,
                onClick: open,
            })
        }
    </PurchaseHistoryDrawerTogler>,
    deleteOneFromListAction({
        children,
        id,
        confirmConfig: {
            title: 'Are you sure you want to delete this record?',
        },
    }),
]
const bulkActions: ListBulkActions = ({ children }) => [
    deleteManyFromListAction({
        children,
        confirmConfig: {
            title: 'Are you sure you want to delete this records?',
        },
    }),
]
export interface PurchaseHistoryModel {
    id: Identifier
    vendor: Identifier
    vendorData: VendorModel
    price: number
    purchaseDate: string
}
const defaultSort: SortType<PurchaseHistoryModel> = {
    field: 'purchaseDate',
    order: 'DESC',
}
const sortCfg: ListSortContentProps<PurchaseHistoryModel> = {
    sortBy: [
        { id: 'vendor', label: 'Vendor' },
        { id: 'purchaseDate', label: 'Purcahse Date' },
        { id: 'price', label: 'Part Cost' },
    ],
}
const filterCfg: FilterConfig<PurchaseHistoryModel> = {
    filters: [
        { id: 'vendor', label: 'Vendor' },
        {
            id: 'purchaseDate',
            label: 'Purcahse Date',
            filterType: 'range',
            renderComponent: (props) => <ListFilterDateRangeValueInput {...props} />,
        },
        {
            id: 'price',
            label: 'Part Cost',
            filterType: 'range' as const,
            renderComponent: (props) => (
                <ListFilterRangeInput
                    inputProps={costMaskParams}
                    {...props}
                />
            ),
        },
    ],
}
const columnsConfig: DatagridColumnsProps<PurchaseHistoryModel & { avatar: string }> = {
    columns: [
        {
            field: 'avatar',
            headerName: 'Avatar',
            maxWidth: 72,
            renderCell: ({ row }) => (
                <ListAvatar
                    linkProps={{ 'aria-label': `View Vendor ${row.vendorData.name}` }}
                    id={row.id}
                    customPath={{
                        resource: vendorsResource.resource,
                        id: row.vendor,
                        type: 'edit',
                    }}
                    imageSrc={row.vendorData.logo}
                    defaultImage={<Icons.Vendors />}
                />
            ),
        },
        {
            field: 'vendor',
            headerName: 'Vendor',
            renderCell: ({ row }) => (
                <LinkCardButton
                    text={row.vendorData.name}
                    to={{ type: 'edit', id: row.vendor, resource: vendorsResource.resource }}
                />
            ),
        },
        {
            headerName: 'Purchase Date',
            field: 'purchaseDate',
            valueFormatter: ({ value }) =>
                formatDate(value, (dateFormats) => dateFormats.shortenedDate),
        },
        {
            headerName: 'Part Cost',
            field: 'price',
            valueFormatter: ({ value }) => resolveDoublePriceMask(value),
        },
    ],
    actions: ({ id }, { children }) => actions(id, children),
}
const navigateTo = (id: Identifier) => `${urls.vendors}/${id}`
const cardsConfig: CardListConfig<PurchaseHistoryModel> = {
    titleSource: (record) => record.vendorData.name,
    imageSource: (record) => record.vendorData.logo,
    titleLink: (record) => navigateTo(record.vendor),
    defaultImage: <Icons.Vendors />,
    details: [
        {
            label: 'Purchase Date',
            source: 'purchaseDate',
            render: (value) => formatDate(value, (dateFormats) => dateFormats.shortenedDate),
        },
        {
            label: 'Part Cost',
            source: 'price',
            render: (value) => resolveDoublePriceMask(value),
        },
    ],
    actions: ({ id }, { children }) => [
        ...actions(id, children),
        multiselectAction({
            children,
            id,
        }),
    ],
}
const PurchaseHistory = inject('auth')(
    observer(({ auth }: { auth?: AuthStore }) => {
        const { record } = useShowContext<VendorModel>()
        if (!record) {
            return null
        }

        return (
            <PageContent>
                <ListBase<PurchaseHistoryModel>
                    resource={createResourcePath(record.id)}
                    sort={defaultSort}
                >
                    <ViewHeader title="Purchase History">
                        <ViewHeader.Content>
                            <ListTotalBadge />
                        </ViewHeader.Content>
                        <ViewHeader.Content placement="rightMobile">
                            <PurchaseHistoryDrawerTogler>
                                {(open) => (
                                    <IconBox
                                        onClick={open}
                                        title="Add Manual Entry"
                                    >
                                        <Icons.Add />
                                    </IconBox>
                                )}
                            </PurchaseHistoryDrawerTogler>
                        </ViewHeader.Content>
                    </ViewHeader>
                    <List
                        exportFileName="Part_number_purchase_history"
                        bulkActions={bulkActions}
                        columnsCfg={columnsConfig}
                        cardsCfg={cardsConfig}
                        sortCfg={sortCfg}
                        filtersCfg={filterCfg}
                        listFTUProps={{
                            title: 'No Data',

                            action: (
                                <PurchaseHistoryDrawerTogler>
                                    {(onClick) => (
                                        <Typography
                                            color="primary.main"
                                            onClick={onClick}
                                            sx={{ cursor: 'pointer' }}
                                            variant="body1"
                                        >
                                            Add Manual Entry
                                        </Typography>
                                    )}
                                </PurchaseHistoryDrawerTogler>
                            ),
                            imageSrc: PurchaseHistoryFTU,
                        }}
                    />
                </ListBase>
            </PageContent>
        )
    }),
)

export default PurchaseHistory

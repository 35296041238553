import { ReactNode } from 'react'

import { styled, alpha, Button, ButtonProps, Tooltip } from '@mui/material'
import clsx from 'clsx'

import { StyledElement } from 'components/styled'

import { useCollapsibleContext } from './CollapsibleContext'

type withDisabled = {
    titleOnDisabled: ReactNode
    disabled: boolean
}
type noDisabled = {
    titleOnDisabled?: never
    disabled?: never
}
type CollapsibleIconHolderProps = Omit<ButtonProps, 'title' | 'disabled'> & {
    name: string
    title: ReactNode
} & (noDisabled | withDisabled)
const CollapsibleIconHolder = styled(
    ({
        name,
        className,
        disabled,
        title,
        titleOnDisabled,
        ...props
    }: CollapsibleIconHolderProps) => {
        const { toggleExpand, isExpanded } = useCollapsibleContext()
        const expanded = isExpanded(name)
        return (
            <StyledElement
                sx={({ palette }) => ({
                    '&>.expanded': {
                        background: alpha(palette.text.primary, 0.08),
                    },
                })}
            >
                <Tooltip title={disabled ? titleOnDisabled : title}>
                    <div>
                        <Button
                            {...props}
                            disabled={disabled}
                            className={clsx(className, expanded ? 'expanded' : '')}
                            color="secondary"
                            onClick={() => {
                                toggleExpand(name)
                            }}
                        />
                    </div>
                </Tooltip>
            </StyledElement>
        )
    },
)`
    padding: 5px;
    border: 1px solid ${({ theme }) => alpha(theme.palette.text.main, 0.12)};
    min-width: 0px;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
`
export default CollapsibleIconHolder

import { SyncOutlined } from '@mui/icons-material'
import { styled } from '@mui/material'

const LoadingSpinner = styled(SyncOutlined)(({ theme, color }) => ({
    '@keyframes loading-spinner': {
        '0%': {
            transform: 'rotate(360deg)',
        },
        '100%': {
            transform: 'rotate(0deg)',
        },
    },
    animation: `loading-spinner 1s linear infinite`,
    color: color ?? theme.palette.info.main,
}))

export default LoadingSpinner

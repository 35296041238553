import { useEffect } from 'react'

import { useStoreContext } from 'react-admin'

import { useDidUpdate } from 'hooks'

import { FullPageLoaderState } from './FullPageLoader'

export const useOpenFullPageLoader = () => {
    const { setItem } = useStoreContext()

    return (open: boolean | FullPageLoaderState) => {
        setItem('full-page-loader', open)
    }
}

export const useToggleFullPageLoader = ({
    isOpen,
    state,
}: {
    isOpen?: boolean
    state?: FullPageLoaderState
}) => {
    const setOpen = useOpenFullPageLoader()

    useDidUpdate(() => {
        if (isOpen) {
            setOpen(state || true)
        } else {
            setOpen(false)
        }
    }, [isOpen])

    useEffect(() => {
        return () => setOpen(false)
    }, [])
}

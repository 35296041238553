import { ReactNode } from 'react'

import { styled } from '@mui/material/styles'

import { BillingPaymentIssue } from 'components'
import TelematicsSyncBar from 'components/TelematicsSyncBar'

import FixedTopPart from '../FixedTopPart'
import GlobalProviders from '../GlobalProvider'
import Main from '../Main'
import MainHeader from '../MainHeader'
import Sidebar from '../Sidebar'
import SidebarBackdrop from '../Sidebar/SidebarBackdrop'

interface MainLayoutProps {
    children: ReactNode
    className?: string
}
const MainLayout = styled(({ children, className }: MainLayoutProps) => {
    return (
        <GlobalProviders>
            <FixedTopPart>
                <BillingPaymentIssue />
                <TelematicsSyncBar />
                <MainHeader />
            </FixedTopPart>
            <div className={className}>
                <Sidebar />
                <SidebarBackdrop />
                <Main sx={{ flex: 1 }}>{children}</Main>
            </div>
        </GlobalProviders>
    )
})`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    overflow-x: hidden;
    height: 100%;
`

export default MainLayout

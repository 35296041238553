import { RaResourceContextProvider } from 'components'
import { useClearResource } from 'hooks'

import MembersList from './List/MembersList'

const Members = () => {
    const membersResourceName = 'members'
    useClearResource(membersResourceName)
    return (
        <RaResourceContextProvider value={{ resource: membersResourceName }}>
            <MembersList />
        </RaResourceContextProvider>
    )
}

export default Members

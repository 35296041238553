import { Link } from '@mui/material'

import { ReactComponent as DeactivatedImage } from 'assets/images/deactivated.svg'
import { BoxContainer, CenterText, Typography, Button } from 'components'
import { landingPageUrl, supportEmail } from 'configs/config'

import { AuthBody } from '../components'

const Deactivated = () => {
    return (
        <AuthBody>
            <CenterText>
                <Typography
                    variant="h6"
                    mb="16px"
                >
                    Deactivated Company
                </Typography>
                <Typography variant="body1">
                    To reactivate your company account, <br /> please contact us at
                    <Link href={`mailto:${supportEmail}`}> {supportEmail}</Link>.
                </Typography>
                <BoxContainer
                    justifyContent="center"
                    mt="30px"
                    mb="40px"
                >
                    <DeactivatedImage />
                </BoxContainer>
                <Button
                    variant="text"
                    size="large"
                    href={landingPageUrl}
                >
                    Back to website
                </Button>
            </CenterText>
        </AuthBody>
    )
}

export default Deactivated

import { Icon } from '@mui/material'

import { WorkOrderModel } from 'appTypes/models'

import { woGetAvatarConfig } from '../config/constants'

const WorkOrderIcon = ({ record }: { record: WorkOrderModel }) => {
    const icon = woGetAvatarConfig(record).Icon
    return <Icon component={icon} />
}
export default WorkOrderIcon

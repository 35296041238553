import {
    Alarm,
    AttachMoney,
    CommuteOutlined,
    HistoryToggleOffOutlined,
    PollOutlined,
    Route,
    Storefront,
} from '@mui/icons-material'

import { ReactComponent as CostPerVMRSGroupIcon } from 'assets/images/cost-per-vmrs-group.svg'
import { ReactComponent as DVIRIcon } from 'assets/images/monitor-dvir.svg'
import { ReactComponent as Top3ReasonsIcon } from 'assets/images/top3-reasons.svg'
import Icons from 'components/icons'
import { ConfigureMenuIcon } from 'pages/Configure'
import { partsAvatarConfig } from 'pages/Parts/config/constants'
import { reportsUrls } from 'pages/Reports/Reports'
import { unitAvatarConfig } from 'pages/Units/config/constants'
import { vendorAvatarConfig } from 'pages/Vendors/constants/config'
import { woAvatarConfig } from 'pages/WorkOrders/config/constants'
import { MenuItemInterface } from 'pages/layouts/Sidebar/SidebarNavItem'

import { urls } from './config'

export const menuItems: MenuItemInterface[] = [
    {
        key: 'dashboard',
        to: urls.root,
        primary: 'Dashboard',
        icon: Icons.Dashboard,
    },
    {
        key: 'work-orders',
        to: urls.workOrders,
        primary: 'Work Orders',
        icon: woAvatarConfig.Icon,
    },
    {
        key: 'units',
        primary: 'Units',
        to: urls.units,
        icon: unitAvatarConfig.Icon,
    },
    {
        key: 'vendors',
        primary: 'Vendors',
        to: urls.vendors,
        icon: vendorAvatarConfig.Icon,
        tooltip: 'Vendors',
    },
    {
        key: 'parts',
        primary: 'Parts',
        to: urls.parts,
        icon: partsAvatarConfig.Icon,
    },
    {
        key: 'reports',
        primary: 'Reports',
        to: urls.reports,
        icon: Icons.Reports,
        tooltip: ({ flags }) => 'Reports',
        submenu: ({ flags }) => [
            {
                Icon: AttachMoney,
                title: 'Maintenance Cost',
                key: 'maintenance-cost',
                to: reportsUrls.maintenanceCost,
            },
            {
                Icon: Route,
                title: 'Cost per Meter',
                key: 'cost-per-meter',
                to: reportsUrls.costPerMeter,
            },
            {
                Icon: HistoryToggleOffOutlined,
                title: 'Downtime by Repair Class',
                key: 'downtime',
                to: reportsUrls.downtime,
            },
            {
                Icon: PollOutlined,
                title: 'Cost per Category',
                key: 'cost-per-category',
                to: reportsUrls.costPerCategory,
            },
            {
                Icon: CostPerVMRSGroupIcon,
                title: 'Cost per VMRS Group',
                key: 'cost-per-vmrs-group',
                to: reportsUrls.costPerVMRSGroup,
            },
            {
                Icon: Storefront,
                title: 'Total Vendor Expenses',
                key: 'vendor-expenses',
                to: reportsUrls.vendorExpenses,
            },
            {
                Icon: Top3ReasonsIcon,
                title: 'Cost per Reason for Repair',
                key: 'reason-for-repair',
                to: reportsUrls.costPerReasonForRepair,
            },
            {
                Icon: CommuteOutlined,
                title: 'Cost per Equipment Category',
                key: 'equipment-category',
                to: reportsUrls.costPerEquipmentCategory,
            },
            {
                Icon: Alarm,
                title: 'PM Intervals',
                key: 'intervals',
                to: reportsUrls.intervals,
            },
            {
                Icon: DVIRIcon,
                title: 'Unresolved Defects',
                key: 'defects',
                to: reportsUrls.defects,
            },
        ],
    },
    {
        key: 'configure',
        primary: 'Configure',
        to: urls.configure,
        icon: Icons.SettingsOutlined,
        renderIcon: (params) => <ConfigureMenuIcon {...params} />,
    },
]

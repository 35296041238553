import { Identifier } from 'react-admin'

import CK15Model from './CK15Model'
import CK33Model from './CK33Model'
import CK34Model from './CK34Model'

import type BaseModel from './BaseModel'
import type CauseModel from './CauseModel'

export const WOJobItemTypes = {
    labor: 'LABOR',
    part: 'PART',
    fee: 'FEE',
    tax: 'TAX',
    service: 'SERVICE',
} as const

export type JobItemTypesUnion = typeof WOJobItemTypes[keyof typeof WOJobItemTypes]

export type WOJobItemModels = {
    [WOJobItemTypes.labor]: JobLaborModel
    [WOJobItemTypes.part]: JobPartModel
    [WOJobItemTypes.fee]: JobFeeModel
    [WOJobItemTypes.tax]: JobTaxModel
    [WOJobItemTypes.service]: JobServiceModel
}

export type WOJobItemTypesUnion = keyof WOJobItemModels

export type WOJobItem = WOJobItemModels[keyof WOJobItemModels]

type JobItemType<T extends JobItemTypesUnion> = { type: T }

export interface JobLaborModel extends BaseModel, JobItemType<typeof WOJobItemTypes.labor> {
    description: string
    hourlyRate: number
    hours: number
    cause: number
    causeData: CauseModel
    correction: number
    componentData: CK33Model
    correctionData: CK15Model
    component: Identifier
}

export interface JobPartModel extends BaseModel, JobItemType<typeof WOJobItemTypes.part> {
    positionApplicable: boolean
    partNumber: 'string'
    description: string
    price: number
    qty: number
    unitOfMeasure: string
    component: number
    componenData: CK33Model
    part: number
    partData: CK33Model
    manufacturer: number
    manufacturerData: CK34Model
    position: number
}

interface VWorkorderItemBase<T extends JobItemTypesUnion> extends BaseModel, JobItemType<T> {
    description: string
    price: number
}

export interface JobTaxModel extends VWorkorderItemBase<typeof WOJobItemTypes.tax> {}
export interface JobFeeModel extends VWorkorderItemBase<typeof WOJobItemTypes.fee> {}
export interface JobServiceModel extends VWorkorderItemBase<typeof WOJobItemTypes.service> {}

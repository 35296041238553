import { FC, ReactNode } from 'react'

import { styled, SxProps, TypographyProps } from '@mui/material'
import { LinkProps, Link } from 'react-router-dom'

import { BoxContainer, InfoCard, Typography } from 'components'

const StyledInfoCard = styled(InfoCard)`
    height: 119px;
    display: flex;
    justify-content: center;
    align-items: center;
`
interface BoxLinkProps {
    to: LinkProps['to']
    label: ReactNode | string
    linkProps?: Omit<LinkProps, 'to'>
    typographyProps?: TypographyProps
    icon?: ReactNode
    sx?: SxProps
}
const BoxLink: FC<BoxLinkProps> = ({ label, to, linkProps, typographyProps, icon, sx }) => {
    return (
        <StyledInfoCard sx={sx}>
            <Link
                to={to}
                {...linkProps}
            >
                <Typography
                    gap="12px"
                    color="primary.main"
                    component={BoxContainer}
                    {...typographyProps}
                >
                    {label}
                    {icon}
                </Typography>
            </Link>
        </StyledInfoCard>
    )
}
export default BoxLink

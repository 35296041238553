import { useEffect } from 'react'

import { ReferenceInput, useChoicesContext } from 'react-admin'
import { useFormContext } from 'react-hook-form'

import { CauseModel, JobModel } from 'appTypes/models'
import {
    CK33SelectorWithoutParts,
    AutocompleteInput,
    TextInput,
    SectionTitleInDrawer,
    GridItem,
    GridContainer,
} from 'components'
import { useUtilityDrawerContext } from 'components/Drawer/UtilityDrawer'
import { ReasonForRepairDefaultValueBase } from 'pages/Units/PM/PMEditor/UnitPMForm'
import { requiredValidation, validateMaxLength } from 'utils'

import { FormJobPhotos, JobNotes } from '../WorkOrderJobCard/components'
import { WorkOrderItemsExtra } from '../config/constants'

const RepairTypeValueField = () => {
    const { availableChoices, selectedChoices } = useChoicesContext()
    const { setValue } = useFormContext()

    useEffect(() => {
        if (!availableChoices || !selectedChoices?.length) {
            return
        }
        const repairType = selectedChoices[0]
        setValue('repairType', repairType?.type)
    }, [selectedChoices])
    return null
}

const nameValidation = [validateMaxLength(100), requiredValidation]

interface JobFormProps {
    job?: JobModel
    desabledFields?: { [key in keyof JobModel]?: boolean }
    reasonForRepairDefaultValue?: string
}

const JobForm = ({ job, desabledFields = {}, reasonForRepairDefaultValue }: JobFormProps) => {
    const { extra } = useUtilityDrawerContext()
    const { disabledFields: disabled } = (extra || {}) as WorkOrderItemsExtra

    return (
        <>
            <SectionTitleInDrawer>Basic Details</SectionTitleInDrawer>
            <GridContainer>
                <GridItem>
                    <TextInput
                        disabled={desabledFields.name ?? disabled}
                        source="name"
                        label="Name"
                        validate={nameValidation}
                    />
                </GridItem>
                <GridItem>
                    <CK33SelectorWithoutParts
                        disabled={desabledFields.component ?? disabled}
                        required
                    />
                </GridItem>
                <GridItem>
                    <ReferenceInput
                        source="reasonForRepair"
                        reference="vmrs/ck14"
                        perPage={disabled ? 1 : 100}
                    >
                        <>
                            <AutocompleteInput
                                groupBy={(option) => option.type}
                                optionText={(record) =>
                                    record.id && `${record.code} ${record.description}`
                                }
                                label="Reason for Repair"
                                disabled={desabledFields.reasonForRepair ?? disabled}
                            />
                            <RepairTypeValueField />
                            {reasonForRepairDefaultValue && (
                                <ReasonForRepairDefaultValueBase
                                    code={reasonForRepairDefaultValue}
                                />
                            )}
                        </>
                    </ReferenceInput>
                </GridItem>
                <GridItem>
                    <TextInput
                        label="Repair Type"
                        source="repairType"
                        disabled
                    />
                </GridItem>
                <GridItem>
                    <ReferenceInput
                        source="complaint"
                        reference="vmrs/ck18"
                        perPage={disabled ? 1 : 100}
                    >
                        <AutocompleteInput
                            optionText={(record: CauseModel) =>
                                record.id && `${record.code} ${record.description}`
                            }
                            label="Complaint"
                            disabled={desabledFields.complaint ?? disabled}
                        />
                    </ReferenceInput>
                </GridItem>
                <GridItem>
                    <JobNotes />
                </GridItem>
                <GridItem mb="36px">
                    <FormJobPhotos />
                </GridItem>
            </GridContainer>
        </>
    )
}
export default JobForm

import { FC } from 'react'

import { CommuteOutlined } from '@mui/icons-material'
import { useTheme } from '@mui/material'

import { WidgetPieChartBase } from '../../components'
import { JobItemsCalculations } from '../../components/types'
import { listType, statusColors } from '../types'

interface CostPerEquipmentChartProps {
    list: listType
    total: JobItemsCalculations['total']
    validData: boolean
}
const CostPerEquipmentChart: FC<CostPerEquipmentChartProps> = ({ list, total, validData }) => {
    const { palette } = useTheme()
    const listData = list.data.map((obj, index) => {
        return {
            id: obj.description,
            value: obj.total,
            color: palette.charts[statusColors[index]],
            total,
        }
    })

    return (
        <WidgetPieChartBase
            valid={validData}
            icon={CommuteOutlined}
            sortByValue={false}
            data={[
                ...listData,
                {
                    ...(list.uncategorizedTotal
                        ? {
                              id: 'Uncategorized',
                              value: list.uncategorizedTotal,
                              color: palette.secondary.main,
                              total,
                          }
                        : null),
                },
                {
                    id: 'Other',
                    value: list.sumOfExcludedEquipment,
                    color: palette.charts.disable,
                    total,
                },
            ]}
        />
    )
}
export default CostPerEquipmentChart

import { useWatch } from 'react-hook-form'

import { Typography } from 'components'
import { GridItemLayout } from 'components/pages'
import { resolveDoublePriceMask } from 'utils'

interface LaborPartTotalProps {
    inputOne: string
    inputTwo: string
}

const LaborPartTotal = ({ inputOne, inputTwo }: LaborPartTotalProps) => {
    const qtyHours = useWatch({ name: inputOne })
    const priceCost = useWatch({ name: inputTwo })

    return <>{resolveDoublePriceMask(qtyHours * priceCost)}</>
}

interface JobLaborPartFormTotalProps extends LaborPartTotalProps {
    title: string
}

const JobLaborPartFormTotal = ({ title, inputOne, inputTwo }: JobLaborPartFormTotalProps) => {
    return (
        <GridItemLayout gridColumns={{ xs: 14 }}>
            <Typography
                component="span"
                variant="subtitle1"
                color="text.secondary"
                marginBottom="34px"
                marginTop="11px"
                sx={{ display: 'flex', float: 'right' }}
            >
                {title} &nbsp;
                <Typography
                    color="text.primary"
                    variant="subtitle1"
                >
                    <LaborPartTotal
                        inputOne={inputOne}
                        inputTwo={inputTwo}
                    />
                </Typography>
            </Typography>
        </GridItemLayout>
    )
}

export default JobLaborPartFormTotal

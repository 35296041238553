import { SvgIcon } from '@mui/material'
import { formatDuration as fnsformatDuration, intervalToDuration } from 'date-fns'

import { BoxContainer, Columns, Typography } from 'components'
import { WidgetTooltip } from 'pages/Dashboard/components'
import { DowntimekeysToDataMap } from 'pages/Dashboard/components/Widgets/DowntimeWidget/components/Distribution'
import {
    DowntimeList,
    DowntimeRatioData,
} from 'pages/Dashboard/components/Widgets/DowntimeWidget/types'
import { formatDuration, formatPercent } from 'utils'

interface UnitDowntimeClarificationProps {
    totalDowntimeSeconds: DowntimeRatioData['totalDowntimeSeconds']
    validData: boolean
    list: DowntimeList
}

const UnitDowntimeClarification = ({
    totalDowntimeSeconds,
    validData,
    list,
}: UnitDowntimeClarificationProps) => {
    const duration = (
        <Typography
            component="span"
            variant="chartListItem"
            color="text.secondary"
        >
            {validData ? formatDuration(totalDowntimeSeconds) : '-'}
        </Typography>
    )

    return (
        <Columns
            alignItems="flex-start"
            marginTop="20px"
        >
            {validData ? (
                <WidgetTooltip
                    containerProps={{
                        gap: '8px',
                        padding: '3px',
                    }}
                    title={Object.keys(list).flatMap((id, i) => {
                        const itemData: DowntimeList['SCHEDULED'] = list[id]
                        const itemDecor = DowntimekeysToDataMap[id]
                        return (
                            <BoxContainer
                                minWidth="150px"
                                justifyContent="space-between"
                            >
                                <BoxContainer mr="30px">
                                    <SvgIcon
                                        inheritViewBox
                                        component={itemDecor.icon}
                                        fontSize="small"
                                        sx={(theme) => ({
                                            width: '14px',
                                            height: '14px',
                                            color: theme.palette.charts[itemDecor.color],
                                        })}
                                    />
                                    <Typography
                                        variant="tooltip"
                                        ml="6px"
                                    >
                                        {itemDecor.name}
                                    </Typography>
                                </BoxContainer>
                                <Typography variant="tooltip">
                                    {fnsformatDuration(
                                        intervalToDuration({
                                            start: 0,
                                            end: Number(itemData.total) * 1000,
                                        }),
                                        {
                                            format: ['years', 'months', 'days', 'hours'],
                                        },
                                    )}{' '}
                                    {formatPercent(itemData.percentOfTotalDowntime)}
                                </Typography>
                            </BoxContainer>
                        )
                    })}
                >
                    {duration}
                </WidgetTooltip>
            ) : (
                duration
            )}
        </Columns>
    )
}
export default UnitDowntimeClarification

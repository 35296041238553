import { Routes } from 'react-router-dom'

import { tabRoutes } from 'components/router/LinkTabs'

import AccountSettings from './AccountSettings'
import PersonalInfo from './PersonalInfo'
import { ProfileHeader } from './components'

const { routes, tabs } = tabRoutes([
    {
        value: '',
        label: 'Personal Info',
        element: <PersonalInfo />,
    },
    {
        value: 'settings',
        label: 'Account Settings',
        element: <AccountSettings />,
    },
])

const Profile = () => {
    return (
        <>
            <ProfileHeader tabs={tabs} />
            <Routes>{routes}</Routes>
        </>
    )
}

export default Profile

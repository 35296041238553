import { SvgIcon, alpha } from '@mui/material'
import { Link } from 'react-router-dom'

import { ReactComponent as ClipboardClock } from 'assets/images/clipboard-clock-outline.svg'
import { BoxContainer, Columns, Typography } from 'components'

import { PMWidgetData } from '../types'

export const pmWidgetMap = [
    {
        id: 'overdue',
        name: 'Overdue',
        color: 'red',
    },
    {
        id: 'due',
        name: 'Due Soon',
        color: 'orange',
    },
    {
        id: 'planned',
        name: 'Planned',
        color: 'greenBody',
    },
]
const UnitPmWidgetLabel = ({ data }: { data: PMWidgetData }) => {
    if (!data.total) {
        return (
            <Columns
                gap="3px"
                mt="-5px"
            >
                <Typography variant="h5">None</Typography>
                <Typography
                    component={Link}
                    to="pm"
                    color="primary.main"
                    aria-label="ADD PM INTERVAL"
                    variant="componentButtonSmall"
                >
                    ADD PM INTERVAL
                </Typography>
            </Columns>
        )
    }

    return (
        <Columns
            gap="3px"
            mt="-5px"
        >
            {pmWidgetMap.map((pm) => {
                const count = data[pm.id + 'Count'] || 0
                const workOrdersCount = data[pm.id + 'WorkOrdersCount'] || 0
                return (
                    <BoxContainer key={pm.id}>
                        <Typography
                            variant="subtitle1"
                            mr={`${(1 / count.toString().length) * 16}px`}
                        >
                            {count}
                        </Typography>
                        <Typography
                            variant="chartLabel"
                            mr="4px"
                            sx={(theme) => {
                                const color = theme.palette.charts[pm.color]
                                return {
                                    padding: '2px 4px 1px 4px',
                                    borderRadius: '2px',
                                    color,
                                    background: alpha(color, 0.1),
                                }
                            }}
                        >
                            {pm.name}
                        </Typography>
                        {workOrdersCount ? (
                            <Typography
                                variant="chartLabel"
                                color="text.primary"
                                component={BoxContainer}
                                ml="6px"
                                gap="6px"
                            >
                                <SvgIcon
                                    component={ClipboardClock}
                                    sx={{ width: '16px', height: '16px' }}
                                />
                                {workOrdersCount} in progress
                            </Typography>
                        ) : null}
                    </BoxContainer>
                )
            })}
        </Columns>
    )
}
export default UnitPmWidgetLabel

import { createContext, FC, useContext, useState, ReactNode } from 'react'

import { Menu as MuiMenu, MenuProps } from '@mui/material'

import { IconBox, ResourceType, useResource } from 'components'
import { IconBoxProps } from 'components/IconBox'
import Icons from 'components/icons'

import { Action } from '../Actions'

import ActionsOption from './ActionsOption'

export type MenuItemActions<ActionType = any, ExtraActionsType = {}> = Action<
    ActionType,
    ExtraActionsType
>
// TODO: Action - make actionArgs required id ExtraActionsType is passed
export interface MenuActionProps<T = any, ExtraActionsType = {}>
    extends Pick<
        MenuProps,
        'anchorOrigin' | 'transformOrigin' | 'sx' | 'disablePortal' | 'PaperProps'
    > {
    actions: MenuItemActions<T, ExtraActionsType & { anchorEl?: HTMLElement }>
    record?: any
    className?: string
    icon?: FC
    iconColor?: IconBoxProps['iconColor']
    iconBackgroundColor?: IconBoxProps['backgroundColor']
    resource?: ResourceType
    actionArgs?: ExtraActionsType
    disabled?: boolean
    title?: string
    renderToggler?: (open: MenuActionsRenderToggleArg, isOpen?: boolean) => ReactNode
}

export type MenuActionsRenderToggleArg = ActionsMenuContextResult['open']

const defaultRecord = {}

const ActionsMenuContext = createContext<ActionsMenuContextResult>({} as ActionsMenuContextResult)

interface ActionsMenuContextResult {
    isOpen: boolean
    close: () => void
    open: (event: React.MouseEvent<HTMLElement>) => void
}

export const useActionsMenuContext = (): ActionsMenuContextResult => useContext(ActionsMenuContext)

const ActionsMenu = <T = any, ExtraActionsType = {}>({
    actions,
    renderToggler,
    record = defaultRecord,
    className,
    icon,
    resource: resourceProp,
    actionArgs,
    disabled,
    title,
    iconColor,
    iconBackgroundColor,
    ...props
}: MenuActionProps<T, ExtraActionsType>) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const resource = useResource(resourceProp)

    const actionsContent = actions(record as T, {
        children: (params) => <ActionsOption {...params} />,
        anchorEl,
        resource,
        ...actionArgs,
    })

    if (!actionsContent.length) {
        return null
    }

    const open: ActionsMenuContextResult['open'] = (event) => {
        if (disabled) {
            return
        }
        setAnchorEl(event.currentTarget || (event.target as HTMLElement))
    }
    const close: ActionsMenuContextResult['close'] = () => {
        setAnchorEl(null)
    }

    const Icon = icon || Icons.Options

    const isOpen = Boolean(anchorEl)

    return (
        <ActionsMenuContext.Provider
            value={{
                isOpen,
                open,
                close,
            }}
        >
            {renderToggler ? (
                renderToggler(open, isOpen)
            ) : (
                <IconBox
                    iconColor={iconColor}
                    backgroundColor={iconBackgroundColor}
                    title={title}
                    onClick={open}
                    className={className}
                    disabled={disabled}
                >
                    <Icon />
                </IconBox>
            )}
            <MuiMenu
                autoFocus={false}
                open={isOpen}
                anchorEl={anchorEl}
                onClose={close}
                {...props}
            >
                {actionsContent}
            </MuiMenu>
        </ActionsMenuContext.Provider>
    )
}

export default ActionsMenu

import { UnitModel } from 'appTypes/models'
import { InfoCard, InfoCardDetails, InfoCardHeader } from 'components'

const BasicDetails = () => {
    return (
        <InfoCard>
            <InfoCardHeader title="Basic Details" />
            <InfoCardDetails<UnitModel>
                details={[
                    {
                        label: 'Unit Name',
                        source: 'name',
                    },
                    {
                        label: 'VIN',
                        source: 'vin',
                    },
                    {
                        label: 'License Plate',
                        source: 'licensePlate',
                    },
                ]}
            />
        </InfoCard>
    )
}

export default BasicDetails

import { TypographyProps, BoxProps } from '@mui/material'

import { BoxContainer, Typography } from 'components'

const WidgetListDescription = (props: TypographyProps & BoxProps) => {
    return (
        <Typography
            component={BoxContainer}
            {...props}
        />
    )
}
WidgetListDescription.defaultProps = {
    padding: '10px 20px 20px 20px',
    color: 'text.secondary',
    variant: 'chartListDescription',
}
export default WidgetListDescription

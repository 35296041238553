import { ReactElement } from 'react'

import { useRecordContext } from 'react-admin'

import { RaResourceContextProvider, Section, SliderView, ViewHeader, useResource } from 'components'
import { useClearResource } from 'hooks'
import { VendorSliderSkeleton } from 'pages/Vendors/components'

export const contactsResourceShortName = 'vendor-contacts'

const contactsSkeletonSlides = new Array(4).fill(<VendorSliderSkeleton height="145px" />)
export const UnitMetersFallback = () => (
    <div>
        <Section>
            <ViewHeader
                title={null}
                loading
            />
        </Section>
        <SliderView items={contactsSkeletonSlides} />
    </div>
)
const VendorContactResource = ({ children }: { children: ReactElement }) => {
    const { id } = useRecordContext() || {}
    const resource = useResource()

    const contactsResource = `${resource.resource}/${id}/contacts`

    useClearResource(contactsResource)

    if (!id) {
        return <UnitMetersFallback />
    }

    return (
        <RaResourceContextProvider
            value={{
                resource: contactsResource,
                name: contactsResourceShortName,
            }}
        >
            {children}
        </RaResourceContextProvider>
    )
}

export default VendorContactResource

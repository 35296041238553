import { ReactNode } from 'react'

import { styled, SxProps } from '@mui/material'

import Typography from 'components/mui/Typography'
import { LinkTabs } from 'components/router'
import { LinkTabsProps } from 'components/router/LinkTabs'
import BoxContainer from 'components/styled/BoxContainer'
import StyledElement from 'components/styled/StyledElement'
import { FixedTopPortal } from 'pages/layouts/FixedTopPart'

import PageBlock from '../PageBlock'

import { BackButton, BackButtonProps } from './parts'

const StyledMainBlock = styled(BoxContainer)`
    gap: 24px;
    flex-grow: 1;
    position: relative;
    ${({ theme }) => `
        ${theme.breakpoints.down('sm')} {
            justify-content: center;
        }
    `}
`
const StyledPageBlock = styled(PageBlock)`
    display: flex;
    align-items: center;
    box-sizing: border-box;
    justify-content: space-between;
    padding-top: 32px;
    padding-bottom: 32px;
    ${({ theme }) => `
        ${theme.breakpoints.up('sm')} {
            padding-top: 29px;
            padding-bottom: 29px;
        }
    `}
`
interface CreatePageHeaderProps {
    title: ReactNode
    actionComponent?: ReactNode
    tabs?: LinkTabsProps['tabs']
    tabWidth?: string
    topHeight?: string
    tabsProps?: Omit<LinkTabsProps, 'tabs' | 'tabWidth'>
    backButtonProps?: BackButtonProps
    mainSX?: SxProps
}
const CreatePageHeader = ({
    tabWidth,
    tabs,
    title,
    actionComponent,
    topHeight,
    tabsProps,
    backButtonProps,
    mainSX,
}: CreatePageHeaderProps) => {
    return (
        <FixedTopPortal>
            <StyledElement
                sx={({ palette }) => ({
                    width: '100%',
                    backgroundColor: palette.white,
                })}
            >
                <StyledPageBlock sx={{ height: topHeight }}>
                    <StyledMainBlock sx={mainSX}>
                        <BackButton
                            sx={({ breakpoints }) => ({
                                [breakpoints.down('sm')]: {
                                    position: 'absolute',
                                    left: 0,
                                    top: '50%',
                                    transform: 'translate(0%    ,-50%)',
                                },
                            })}
                            {...backButtonProps}
                        />
                        <Typography
                            variant="h5"
                            color="textPrimary"
                            sx={(theme) => ({
                                typography: { xs: 'h6', sm: 'h5' },
                                [theme.breakpoints.down('sm')]: {
                                    fontSize: 20,
                                },
                            })}
                        >
                            {title}
                        </Typography>
                    </StyledMainBlock>
                    <div id="header-portal-root">{actionComponent}</div>
                </StyledPageBlock>
                {tabs?.length ? (
                    <LinkTabs
                        tabs={tabs}
                        tabWidth={tabWidth}
                        {...tabsProps}
                    />
                ) : null}
            </StyledElement>
        </FixedTopPortal>
    )
}
export default CreatePageHeader

import { ReactNode } from 'react'

import { EditProps, EditBase, useUpdate, RaRecord } from 'react-admin'

import { Form, FormProps } from 'components'
import { UseSubmitExtraArgs, useSubmit } from 'hooks'
import { DataProviderMetaFile } from 'providers/dataProvider'

import { ResourceType, useResource } from './RaResourceContextProvider'
import { makeSubmit, makeSubmitConfig } from './common'

export interface EditCustomProps<RecordType extends RaRecord = any>
    extends Omit<EditProps<RecordType>, 'resource' | 'mutationOptions'>,
        makeSubmitConfig<RecordType>,
        Pick<FormProps, 'formOnError' | 'validate'>,
        Pick<UseSubmitExtraArgs, 'successMessage' | 'successMessageArgs'> {
    children: ReactNode
    className?: string
    warnWhenUnsavedChanges?: boolean
    resource?: ResourceType
    meta?: DataProviderMetaFile
}

const Edit = <RecordType extends RaRecord = any>({
    children,
    makeData,
    className,
    onSuccess,
    serializer,
    warnWhenUnsavedChanges,
    formOnError,
    resource: resourceProp,
    meta,
    validate,
    successMessage,
    successMessageArgs,
    mutationOptions,
    ...rest
}: EditCustomProps<RecordType>) => {
    const resource = useResource(resourceProp)
    const [edit] = useUpdate()

    const submit = useSubmit(
        makeSubmit(edit, {
            resource,
            makeData,
            onSuccess,
            serializer,
            meta,
            mutationOptions,
        }),
        {
            successMessage: successMessage ?? (({ defaultMessages }) => defaultMessages.updated),
            successMessageArgs,
        },
    )

    return (
        <EditBase {...rest}>
            <Form
                validate={validate}
                formOnError={formOnError}
                onSubmit={submit}
                className={className}
                warnWhenUnsavedChanges={warnWhenUnsavedChanges}
            >
                {children}
            </Form>
        </EditBase>
    )
}

export default Edit

import { FC, useEffect, useState } from 'react'

import { CalendarTodayOutlined } from '@mui/icons-material'
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'
import { InputAdornment, TextField } from '@mui/material'
import { MobileDatePicker } from '@mui/x-date-pickers'
import { DatePickerProps } from '@mui/x-date-pickers/DatePicker'
import clsx from 'clsx'
import {
    useInput,
    DateInputProps as RADateInputProps,
    sanitizeInputRestProps,
    InputHelperText,
    FieldTitle,
} from 'react-admin'

import ClearAdornment from 'components/ClearAdornment'
import { useCreateInputId } from 'hooks'
import { dateFormatsObject, formatDate, stringDateFixTimezone } from 'utils'

type DateInputProps = RADateInputProps &
    Pick<
        DatePickerProps<any, any>,
        | 'views'
        | 'shouldDisableDate'
        | 'shouldDisableMonth'
        | 'shouldDisableYear'
        | 'disableFuture'
        | 'openTo'
    > & {
        returnDateFormat?: string
        formatValue?: (value: Date | null) => Date | null
    }

export const DateInput: FC<DateInputProps> = ({
    views = ['year', 'month', 'day'],
    source,
    name,
    resource,
    className,
    defaultValue,
    label,
    helperText,
    margin = 'dense',
    onBlur,
    onChange,
    validate,
    variant = 'outlined',
    returnDateFormat = dateFormatsObject.serverDate,
    disabled,
    formatValue,
    shouldDisableDate,
    shouldDisableMonth,
    shouldDisableYear,
    disableFuture,
    openTo,
    ...rest
}) => {
    const createId = useCreateInputId()

    const { field, fieldState, formState, id, isRequired } = useInput({
        defaultValue,
        name,
        onBlur,
        onChange,
        resource,
        source,
        validate,
        ...rest,
        id: createId({ source, id: rest.id }),
    })

    const [value, setValue] = useState<Date>(null)
    const { error, isTouched } = fieldState
    const { isSubmitted } = formState

    const fieldValue = field.value

    useEffect(() => {
        let newDate: Date | null = null
        if (fieldValue instanceof Date) {
            newDate = fieldValue
        } else {
            // hack for timezone
            newDate = fieldValue ? new Date(stringDateFixTimezone(fieldValue)) : null
        }
        setValue(formatValue ? formatValue(newDate) : newDate)
    }, [fieldValue])

    return (
        <MobileDatePicker
            label={label}
            views={views}
            openTo={openTo || views[0]}
            onAccept={(newDate) => {
                try {
                    field.onChange(
                        returnDateFormat ? formatDate(newDate, returnDateFormat) : newDate,
                    )
                } catch {
                    field.onChange(newDate)
                }
            }}
            value={value}
            onChange={(date) => setValue(date)}
            components={{
                OpenPickerIcon: CalendarTodayOutlined,
            }}
            DialogProps={{
                sx: {
                    '& .PrivatePickersToolbar-dateTitleContainer > button': {
                        display: 'none',
                    },
                },
            }}
            disabled={disabled}
            shouldDisableDate={shouldDisableDate}
            shouldDisableMonth={shouldDisableMonth}
            shouldDisableYear={shouldDisableYear}
            disableFuture={disableFuture}
            renderInput={({ inputRef, ...params }) => {
                return (
                    <TextField
                        className={clsx('ra-input', `ra-input-${source}`, className)}
                        id={id}
                        {...field}
                        {...params}
                        sx={{
                            marginTop: 0,
                        }}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {!isRequired && Boolean(field.value) && (
                                        <ClearAdornment
                                            onClick={(event) => {
                                                event.stopPropagation()
                                                field.onChange(null)
                                            }}
                                        />
                                    )}

                                    <InputAdornment position="end">
                                        <CalendarTodayOutlinedIcon />
                                    </InputAdornment>
                                </>
                            ),
                        }}
                        ref={inputRef}
                        variant={variant}
                        margin={margin}
                        focused={false}
                        error={(isTouched || isSubmitted) && (error || params.error) ? true : false}
                        helperText={
                            <InputHelperText
                                touched={isTouched || isSubmitted}
                                error={error?.message}
                                helperText={helperText}
                            />
                        }
                        label={
                            <FieldTitle
                                label={label}
                                source={source}
                                resource={resource}
                                isRequired={isRequired}
                            />
                        }
                        {...sanitizeInputRestProps(rest)}
                    />
                )
            }}
        />
    )
}

DateInput.defaultProps = {
    defaultValue: null,
}

export default DateInput

import { FC } from 'react'

import { Role } from 'appTypes/models'
import { IconBox, useOpenUtilityDrawer, UtilityDrawerCloseButton } from 'components'
import Icons from 'components/icons'

import UserRolesPermissionsList from './UserRolesPermissionsList'

type ViewMoreActionProps = {
    role: Role
}

export const ViewMoreAction: FC<ViewMoreActionProps> = ({ role }) => {
    const open = useOpenUtilityDrawer()

    return (
        <IconBox
            onClick={() => {
                open({
                    drawerArgs: {
                        title: role.label,
                        renderWrapper: (params) => <div {...params} />,
                        renderContent: () => <UserRolesPermissionsList role={role} />,
                        renderBottomRight: () => null,
                        renderBottomLeft: () => <UtilityDrawerCloseButton />,
                    },
                })
            }}
            title="View More"
        >
            <Icons.Options />
        </IconBox>
    )
}

import { inject, observer } from 'mobx-react'

import UserModel from 'appTypes/models/UserModel'
import {
    GridItemLayout,
    PageContent,
    UploadImage,
    SaveButtonMobileFixed,
    Form,
    TextInput,
    SectionTitle,
    EmailInput,
    TelInput,
    GridBreak,
} from 'components'
import DefaultUserAvatar from 'components/DefaultUserAvatar'
import Icons from 'components/icons'
import { GridContainerColumns } from 'components/pages/GridContainer'
import { useSubmit } from 'hooks'
import { AuthStore } from 'providers/authStore'
import { serialize, Serializer, maxLengthValidation } from 'utils'

const userProfileSerializer: Serializer<UserModel> = [
    'name',
    { name: 'avatar', parse: 'file' },
    'phone',
]

const PersonalInfo = inject('auth')(
    observer(({ auth }: { auth?: AuthStore }) => {
        const user = auth.user

        const submitHandler = useSubmit<UserModel>(
            async (formData) => {
                await auth.updateUser({
                    data: serialize(formData, userProfileSerializer),
                })
            },
            {
                successMessage: ({ defaultMessages }) => defaultMessages.updated,
            },
        )

        return (
            <PageContent>
                <SectionTitle>Basic Information</SectionTitle>
                <Form
                    onSubmit={submitHandler}
                    formRootPathname="personal-info"
                    record={user}
                >
                    <GridContainerColumns>
                        <GridItemLayout>
                            <EmailInput disabled />

                            <UploadImage
                                source="avatar"
                                defaultIcon={<DefaultUserAvatar />}
                            />
                        </GridItemLayout>
                        <GridBreak />
                        <GridItemLayout>
                            <TextInput
                                source="name"
                                label="Full Name"
                                validate={maxLengthValidation}
                            />
                        </GridItemLayout>
                        <GridItemLayout>
                            <TelInput label="Phone Number" />
                        </GridItemLayout>
                    </GridContainerColumns>
                    <SaveButtonMobileFixed startIcon={<Icons.SaveFilled />} />
                </Form>
            </PageContent>
        )
    }),
)

export default PersonalInfo

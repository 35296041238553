import { RaRecord, useListContext, useShowContext } from 'react-admin'

import { JobModel, UnitModel, WorkOrderModel, WorkOrderType } from 'appTypes/models'
import { useOpenUtilityDrawer, useResource } from 'components'
import { isVendorWo } from 'pages/WorkOrders/Overview/WorkOrderOverview'

import UseWOBackButton from './UseWOBackButton'
import UseWOFormContent from './UseWOFormContent'
import UseWONextButton from './UseWONextButton'
import { StepsContextProvider } from './UseWOStepsContext'
import UseWOTitle from './UseWOTitle'
export interface UseWOParams<ParentType extends RaRecord = any> {
    woType?: WorkOrderType
    workOrder?: WorkOrderModel
    parentRecord: ParentType
    refetchList?: string
    options?: {
        jobDisabledFields?: { [key in keyof JobModel]?: boolean }
        jobDefaultValues?: Partial<JobModel>
    }
}

export interface UseWOExtraState<ParentType extends RaRecord = any> {
    woType?: WorkOrderType
    unit: UnitModel
    workOrder?: WorkOrderModel
    parentRecord: ParentType
    isVendor: boolean
    refetchList: string
    options: UseWOParams['options']
}

const useAttachWO = () => {
    const open = useOpenUtilityDrawer()
    const { record } = useShowContext<UnitModel>()
    const resource = useResource()
    const listContext = useListContext()

    return ({ workOrder, woType, parentRecord, refetchList, options }: UseWOParams) => {
        const extra: UseWOExtraState = {
            unit: record,
            woType,
            workOrder,
            parentRecord,
            isVendor: woType ? woType === 'vendor' : isVendorWo(workOrder),
            refetchList,
            options,
        }

        open({
            drawerArgs: {
                title: <UseWOTitle />,
                renderBottomLeft: () => <UseWOBackButton />,
                renderBottomRight: () => <UseWONextButton />,
                renderContent: () => <UseWOFormContent />,
                renderWrapper: (props) => <StepsContextProvider {...props} />,
            },
            extraArgs: {
                id: workOrder?.id,
                resource,
                listContext,
            },
            extra,
        })
    }
}

export default useAttachWO

import { ReactComponent as DVIRIcon } from 'assets/images/monitor-dvir.svg'
import { BoxContainer, StatusText, Typography } from 'components'
import { reportUrl } from 'pages/Reports'
import { createReportResource, unitAvatarConfig } from 'pages/Units/config/constants'

import { Monitor } from '../MonitorComponents'

import { useMonitor } from './useMonitor'

interface dataType {
    unresolvedCount: number
    unresolvedUnitsCount: number
    inProgressCount: number
    inProgressUnitsCount: number
}
const MonitorDVIR = () => {
    const data = useMonitor<dataType>(createReportResource('defects'))
    const createLink = (status: 'Yes' | 'No') => {
        return (
            reportUrl(reportUrl.urls.defects) +
            '?filter=' +
            encodeURIComponent(JSON.stringify({ inProgress: [status] }))
        )
    }
    if (!data) {
        return <Monitor.Skeleton />
    }

    const { unresolvedCount, unresolvedUnitsCount, inProgressCount, inProgressUnitsCount } = data
    return (
        <Monitor>
            <Monitor.Title icon={DVIRIcon}>Unresolved Defects</Monitor.Title>

            <Monitor.ArrowButton navigateTo={reportUrl(reportUrl.urls.defects)} />
            <Monitor.Container>
                <Monitor.Button navigateTo={reportUrl(reportUrl.urls.defects)}>
                    <BoxContainer gap="4px">
                        <Monitor.ValueText>{unresolvedCount || 0}</Monitor.ValueText>
                        <Monitor.Counter image={unitAvatarConfig.Icon}>
                            {unresolvedUnitsCount}
                        </Monitor.Counter>
                    </BoxContainer>
                    <StatusText statusColor={(theme) => theme.palette.charts.red}>
                        Unresolved
                    </StatusText>
                </Monitor.Button>
                <Monitor.Button navigateTo={createLink('Yes')}>
                    <BoxContainer gap="4px">
                        <Monitor.ValueText display="flex">
                            {inProgressCount || 0}
                            <Typography
                                component="span"
                                variant="h5"
                                color="text.disabled"
                            >
                                /{unresolvedCount || 0}
                            </Typography>
                        </Monitor.ValueText>
                        <Monitor.Counter image={unitAvatarConfig.Icon}>
                            {inProgressUnitsCount}
                        </Monitor.Counter>
                    </BoxContainer>
                    <StatusText statusColor={(theme) => theme.palette.primary.main}>
                        In Progress
                    </StatusText>
                </Monitor.Button>
            </Monitor.Container>
        </Monitor>
    )
}

export default MonitorDVIR

import { ReactElement } from 'react'

import { ListControllerResult, RaRecord, useListContext } from 'react-admin'

import { InfoBadge } from 'components/styled'

export interface ListTotalBadgeProps<RecordType extends RaRecord = RaRecord> {
    renderContent?: (
        listContent: ListControllerResult<RecordType>,
    ) => ReactElement | string | number
}

const ListTotalBadge = <RecordType extends RaRecord = RaRecord>({
    renderContent,
}: ListTotalBadgeProps<RecordType>) => {
    const listContext = useListContext()
    const { total, isLoading } = listContext

    if (isLoading || typeof total !== 'number') {
        return null
    }

    return <InfoBadge badgeContent={renderContent ? renderContent(listContext) : String(total)} />
}

export default ListTotalBadge

import { TextFieldProps, Box } from '@mui/material'

import { BoxContainer } from 'components/styled'

import DialogSelectorFilterButtons, {
    DialogSelectorFilterButtonsProps,
} from './DialogSelectorFilterButtons'
import DialogSelectorSearch from './DialogSelectorSearch'

export interface DialogSelectorFilterProps {
    search?: TextFieldProps
    select?: DialogSelectorFilterButtonsProps
}

const DialogSelectorFilter = ({ search, select }: DialogSelectorFilterProps) => {
    return (
        <BoxContainer
            px="20px"
            py="18px"
            bgcolor="gray"
            gap="16px"
        >
            <Box flexGrow={1}>
                <DialogSelectorSearch {...search} />
            </Box>
            {select && <DialogSelectorFilterButtons {...select} />}
        </BoxContainer>
    )
}

export default DialogSelectorFilter

import {
    ListContextProvider,
    RaRecord,
    useListContext,
    useListController,
    useRecordContext,
} from 'react-admin'
import { Link } from 'react-router-dom'

import { WorkOrderModel } from 'appTypes/models'
import {
    CardListCard,
    IconBox,
    InfoBadge,
    RaResourceContextProvider,
    SliderView,
    Section,
    ViewHeader,
} from 'components'
import Icons from 'components/icons'
import { perPageLimit } from 'configs/constants'
import { useCreateResourcePath } from 'hooks'
import { UnitSliderSkeleton } from 'pages/Units/components'
import { woCardsCfg } from 'pages/WorkOrders/List/WorkOrdersList'
import { editRedirectWOFromListAction, pdfOneWOFromListAction } from 'pages/WorkOrders/List/actions'
import { woResource } from 'pages/WorkOrders/config/constants'

import WorkOrderSimpleListNoResults, {
    WorkOrderSimpleListNoResultsProps,
} from './WorkOrderSimpleListNoResults'

const cardsCfg: typeof woCardsCfg = {
    ...woCardsCfg,
    actions: (record, { children }) => [
        editRedirectWOFromListAction({ id: record.id, children }),
        pdfOneWOFromListAction({ children, record }),
    ],
}
type WorkOrderSimpleListActionFilterType = {
    [key in keyof WorkOrderModel]?: [WorkOrderModel[key]]
}
const WorkOrderSimpleListAction = ({ filter }: { filter: WorkOrderSimpleListActionFilterType }) => {
    const createPath = useCreateResourcePath()
    const listContext = useListContext()

    const renderContent = (disabled?: boolean) => (
        <IconBox
            title={disabled ? undefined : 'See All'}
            disabled={disabled}
        >
            <Icons.GridView />
        </IconBox>
    )

    if (listContext.isLoading || !listContext.total) {
        return renderContent(true)
    }

    const woLink: string =
        createPath({ resource: woResource.resource, type: 'list' }) +
        '?filter=' +
        encodeURIComponent(JSON.stringify(filter))

    return <Link to={woLink}>{renderContent()}</Link>
}

interface WorkOrderSimpleListProps<RecordType extends RaRecord>
    extends WorkOrderSimpleListNoResultsProps<RecordType> {
    filter: (record: RecordType) => {
        [key in keyof WorkOrderModel]?: [WorkOrderModel[key]]
    }
}
const skeletonSlides = new Array(4).fill(<UnitSliderSkeleton height="308px" />)

const WorkOrderSimpleList = <RecordType extends RaRecord>({
    filter: filterProp,
    createFormInitialValues,
    workOrderType,
}: WorkOrderSimpleListProps<RecordType>) => {
    const record = useRecordContext<RecordType>()

    const filter: WorkOrderSimpleListActionFilterType = {
        status: ['OPEN'],
        ...filterProp(record),
    }

    const listController = useListController({
        resource: woResource.resource,
        filter,
        perPage: perPageLimit,
        queryOptions: {
            enabled: Boolean(record?.id),
        },
    })
    let content

    if (listController.isLoading || !record?.id) {
        content = <SliderView items={skeletonSlides} />
    } else if (listController.total) {
        content = (
            <SliderView
                items={listController.data.map((wo) => (
                    <CardListCard<WorkOrderModel>
                        cardsCfg={cardsCfg}
                        record={wo}
                    />
                ))}
            />
        )
    } else {
        content = (
            <WorkOrderSimpleListNoResults
                createFormInitialValues={createFormInitialValues}
                workOrderType={workOrderType}
            />
        )
    }

    return (
        <RaResourceContextProvider value={{ resource: woResource.resource }}>
            <ListContextProvider value={listController}>
                <Section>
                    <ViewHeader
                        title="Open Work Orders"
                        loading={listController.isLoading || !record?.id}
                    >
                        <ViewHeader.Content>
                            <InfoBadge badgeContent={String(listController.total)} />
                        </ViewHeader.Content>
                        <ViewHeader.Content placement="rightMobile">
                            <WorkOrderSimpleListAction filter={filter} />
                        </ViewHeader.Content>
                    </ViewHeader>
                    {content}
                </Section>
            </ListContextProvider>
        </RaResourceContextProvider>
    )
}

export default WorkOrderSimpleList

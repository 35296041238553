import { Columns, Typography } from 'components'
import { createReportResource } from 'pages/Units/config/constants'
import { formatPercent } from 'utils'

import WidgetBase, { WidgetSkeleton } from '../../WidgetBase/WidgetBase'
import { useDashboardWidget } from '../../WidgetsForm'

import { ComplianceRateChart, ComplianceRateList } from './components'
import { ComplianceRateResponse } from './types'

const description = `The widget calculates the percentage of preventive maintenance tasks 
completed on time within the selected reporting period according to the fleet's PM schedule.`
let validData = false
const ComplianceRateWidget = () => {
    const data = useDashboardWidget<ComplianceRateResponse>({
        resource: createReportResource('compliance-rate'),
        sideEffect: (data) => {
            validData = data.onTimeCount + data.notOnTimeCount > 0

            return data
        },
    })
    if (!data) {
        return <WidgetSkeleton />
    }
    return (
        <WidgetBase
            headerProps={{
                title: 'Preventive Maintenance Compliance Rate',
                label: validData ? formatPercent(data.onTimePercent) : null,
                tooltipText: description,
                clarification: (
                    <Columns
                        mt="4px"
                        gap="4px"
                    >
                        <Typography
                            variant="chartListItem"
                            color="text.secondary"
                        >
                            {validData ? 'On-time' : '-'}
                        </Typography>
                        <Typography
                            variant="chartListItem"
                            color="text.disabled"
                        >
                            for the selected period
                        </Typography>
                    </Columns>
                ),
                chart: (
                    <ComplianceRateChart
                        validData={validData}
                        data={data}
                    />
                ),
            }}
            infoProps={{
                title: 'Distribution',
                content: (
                    <ComplianceRateList
                        validData={validData}
                        description={description}
                        data={data}
                    />
                ),
            }}
        />
    )
}

export default ComplianceRateWidget

import { Switch, FormControlLabel } from '@mui/material'
import { useNavigate, To } from 'react-router-dom'

import { useArchivedContext } from 'components'

interface UnitsListDescriptionProps {
    route1: To
    route2: To
}

const UnitsListDescription = ({ route1, route2 }: UnitsListDescriptionProps) => {
    const navigate = useNavigate()
    const isArchived = useArchivedContext()
    return (
        <FormControlLabel
            sx={{ m: '0px' }}
            control={<Switch />}
            label="Archived"
            checked={isArchived}
            onChange={(e) => {
                if (isArchived) {
                    return navigate(route2)
                }
                return navigate(route1)
            }}
        />
    )
}

export default UnitsListDescription

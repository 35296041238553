import { FC, ReactElement } from 'react'

import { ChoicesContextProvider, ChoicesContextValue, useChoicesContext } from 'react-admin'
import { useFormContext, UseFormReturn } from 'react-hook-form'

export const notFoundKey = 'not-found-option'

interface ReferenceInputCreateLayerProps {
    children: (params: {
        choices: ChoicesContextValue
        formContext: UseFormReturn
        setValue: (value: any) => void
    }) => ReactElement
}

const emptyChoice = { id: notFoundKey }

const ReferenceInputCreateLayer: FC<ReferenceInputCreateLayerProps> = ({ children }) => {
    const choices = useChoicesContext()
    const formContext = useFormContext()

    const source = choices.source

    return (
        <ChoicesContextProvider
            // Autocomplete does not show List if choices is empty, this is why add something empty
            value={{ ...choices, allChoices: [emptyChoice, ...(choices.allChoices || [])] }}
        >
            {children({
                choices,
                formContext,
                setValue: (value) => {
                    choices.refetch()
                    const currentValue = formContext.getValues(source)
                    formContext.setValue(
                        source,
                        Array.isArray(currentValue) ? [...currentValue, value] : value,
                        {
                            shouldValidate: true,
                            shouldDirty: true,
                        },
                    )
                },
            })}
        </ChoicesContextProvider>
    )
}

export default ReferenceInputCreateLayer

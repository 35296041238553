import { VendorModel } from 'appTypes/models'
import { InfoCard, InfoCardDetails, InfoCardHeader } from 'components'
import { phoneMaskResolver } from 'utils'

const BasicDetails = () => {
    return (
        <InfoCard>
            <InfoCardHeader title="Basic Details" />
            <InfoCardDetails<VendorModel>
                details={[
                    {
                        label: 'Company Phone',
                        source: ({ phone }) => phone && phoneMaskResolver(phone),
                    },
                    {
                        label: 'Company Email',
                        source: 'email',
                    },
                    {
                        label: 'Website',
                        source: 'website',
                    },
                ]}
            />
        </InfoCard>
    )
}

export default BasicDetails

import { FC } from 'react'

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import { Link, To } from 'react-router-dom'

import { IconBox } from 'components'

import HeaderASide from './HeaderAside'

export interface BackButtonProps {
    to?: To
}

const BackButtonBase: FC<BackButtonProps> = ({ to = '..' }) => {
    return (
        <Link
            aria-label="Go back"
            to={to}
        >
            <IconBox>
                <ArrowBackIosNewIcon sx={{ height: 19 }} />
            </IconBox>
        </Link>
    )
}

const HeaderBackButton: FC<BackButtonProps> = (props) => {
    return (
        <HeaderASide>
            <BackButtonBase {...props} />
        </HeaderASide>
    )
}

export default HeaderBackButton

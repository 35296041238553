import { HistoryToggleOffOutlined } from '@mui/icons-material'
import { useTheme } from '@mui/material'

import { WidgetPieChartBase } from '../../components'
import { DowntimeList } from '../types'

interface DowntimeChartProps extends DowntimeList {
    validData: boolean
}
const DowntimeChart = ({ EMERGENCY, NON_SCHEDULED, SCHEDULED, validData }: DowntimeChartProps) => {
    const theme = useTheme()
    return (
        <WidgetPieChartBase
            valid={validData}
            icon={HistoryToggleOffOutlined}
            data={[
                {
                    id: 'Emergency',
                    value: EMERGENCY.percentOfTotalDowntime,
                    color: theme.palette.charts.red,
                },
                {
                    id: 'Non-Scheduled',
                    value: NON_SCHEDULED.percentOfTotalDowntime,
                    color: theme.palette.charts.yellowBody,
                },
                {
                    id: 'Scheduled',
                    value: SCHEDULED.percentOfTotalDowntime,
                    color: theme.palette.charts.greenBody,
                },
            ]}
        />
    )
}
export default DowntimeChart

import { useListContext, useRecordContext } from 'react-admin'

import { VendorContactModel, VendorModel } from 'appTypes/models'
import { GridContainer, GridItem } from 'components'
import { ActionsMenu } from 'components/actions'
import Icons from 'components/icons/'

import { contactActions } from './../actions'

import {
    VendorContactsEmpty,
    VendorContactCard,
    VendorContactDrawerToggler,
    VendorContactsHeader,
} from '.'

const VendorContactsList = () => {
    const { data = [], total, isLoading } = useListContext<VendorContactModel>()
    const vendor = useRecordContext<VendorModel>() || ({} as VendorModel)

    if (isLoading) {
        return null
    }

    return (
        <>
            <VendorContactsHeader
                actions={
                    <ActionsMenu
                        record={vendor}
                        actions={(params, { children }) => [
                            <VendorContactDrawerToggler
                                key="add-contact"
                                children={({ onClick }) =>
                                    children({
                                        Icon: Icons.Add,
                                        title: 'Add Contact Person',
                                        onClick,
                                    })
                                }
                            />,
                        ]}
                    />
                }
            />
            {total ? (
                <GridContainer spacing={{ xs: 3, sm: 6 }}>
                    <>
                        {data.map((contact) => (
                            <GridItem
                                xs={12}
                                sm={6}
                                md={4}
                                lg={3}
                                xl={2}
                                key={contact.id}
                            >
                                <VendorContactCard
                                    contact={contact}
                                    actions={contactActions}
                                />
                            </GridItem>
                        ))}
                    </>
                </GridContainer>
            ) : (
                <VendorContactsEmpty />
            )}
        </>
    )
}

export default VendorContactsList

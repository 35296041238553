import { inject, observer } from 'mobx-react'
import { Routes } from 'react-router-dom'

import { tabRoutes } from 'components/router/LinkTabs'
import { basePermissions } from 'configs/constants'
import { AuthStore } from 'providers/authStore'

import CompanyInfo from './CompanyInfo'
import { companyName } from './CompanyInfo/config/constants'
import ManageAccount from './ManageAccount'
import Members from './Members'
import UserRoles from './UserRoles'
import { CompanyHeader } from './components'

const Company = inject('auth')(
    observer(({ auth }: { auth?: AuthStore }) => {
        const { routes, tabs } = tabRoutes([
            {
                value: '',
                label: 'Info & Billing',
                element: <CompanyInfo />,
            },
            {
                value: 'members',
                label: 'Members',
                element: <Members />,
            },
            {
                value: 'user-roles',
                label: 'User Roles',
                element: <UserRoles />,
            },
            auth.permissions[companyName][basePermissions.destroy]
                ? {
                      value: 'account',
                      label: 'Manage Account',
                      element: <ManageAccount />,
                  }
                : null,
        ])

        return (
            <>
                <CompanyHeader tabs={tabs} />
                <Routes> {routes} </Routes>
            </>
        )
    }),
)

export default Company

import { ReactElement } from 'react'

import { ButtonProps, DialogProps } from '@mui/material'
import { useStore } from 'react-admin'
import { LinkProps } from 'react-router-dom'

import { FormProps } from 'components/form'

import ConfirmContent from './ConfirmContent'

export type ConfirmTypes = 'DELETE' | 'INFO' | 'ARCHIVE' | 'UNARCHIVE'

interface ConfirmationParams {
    formValues: any
}

export interface ConfirmConfig extends Pick<DialogProps, 'disableEnforceFocus'> {
    title?: ReactElement | string
    closeOnError?: boolean
    awaitOnConfirm?: boolean
    fullPageLoader?: boolean
    onConfirm?: (params: ConfirmationParams) => Promise<any> | any
    onClose?: (accepted: boolean) => void
    content?: React.ReactNode
    confirmType?: ConfirmTypes
    confirmButtonProps?: (ButtonProps<any> & Partial<LinkProps>) | null
    cancelButtonProps?: ButtonProps
    divideContent?: boolean
    formProps?: Omit<Partial<FormProps>, 'onSubmit'>
}

const Confirm = () => {
    const [confirm] = useStore<ConfirmConfig | null>('confirm', null)

    if (!confirm) {
        return null
    }

    return <ConfirmContent />
}

export default Confirm

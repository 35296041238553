import { InfoOutlined } from '@mui/icons-material'

import { Billing } from 'appTypes/models'
import { ReactComponent as LogoBillingFree } from 'assets/images/logo-billing-free.svg'
import { ReactComponent as LogoBillingPro } from 'assets/images/logo-small-part.svg'
import { Spacer, Typography } from 'components'

import { TooltipContainer } from './Tooltip/TooltipContainer'
import TooltipDescription from './Tooltip/TooltipDescription'

interface PlanTitleProps {
    subscription: Billing['subscription']
}

const PlanTitle = ({ subscription }: PlanTitleProps) => {
    return (
        <Spacer mt="16px">
            {subscription === 'free' ? (
                <LogoBillingFree
                    width="32px"
                    height="32px"
                />
            ) : (
                <LogoBillingPro
                    width="32px"
                    height="32px"
                />
            )}
            <Typography variant="subtitle1">
                {subscription === 'free' ? ' Early Access' : 'Fleetpal Pro'}
            </Typography>
            <TooltipContainer
                title={<TooltipDescription subscription={subscription} />}
                placement="bottom"
            >
                <InfoOutlined
                    sx={(theme) => ({
                        width: '12px',
                        height: '12px',
                        '&:hover': {
                            color: theme.palette.text.primary,
                        },
                        color: theme.palette.action.disabled,
                    })}
                />
            </TooltipContainer>
        </Spacer>
    )
}

export default PlanTitle

import { ReactElement, FC } from 'react'

import { Identifier } from 'react-admin'

import { TagModel } from 'appTypes/models'
import { ColorInput, TextInput, useOpenUtilityDrawer, UtilityDrawerEditor } from 'components'
import { validateMaxLength, requiredValidation } from 'utils'

import { tagsResource } from '../constants'

interface TagDrawerTogglerProps {
    children: (open: () => void) => ReactElement
    id?: Identifier
    onSuccess?: (record: TagModel) => void
}

const TagDrawerToggler: FC<TagDrawerTogglerProps> = ({ children, id, onSuccess }) => {
    const open = useOpenUtilityDrawer()

    return children(() => {
        open({
            drawerArgs: {
                title: id ? 'Edit Tag' : 'Create Tag',
                renderWrapper: (params) => (
                    <UtilityDrawerEditor
                        {...params}
                        onSuccess={onSuccess}
                    />
                ),
                renderContent: () => <TagForm />,
                renderBottomRight: (render) => render({ label: id ? undefined : 'Create tag' }),
            },
            extraArgs: {
                id,
                resource: tagsResource,
            },
        })
    })
}

export default TagDrawerToggler

const nameValidation = [requiredValidation, validateMaxLength(30)]

const TagForm = () => {
    return (
        <>
            <TextInput
                source="name"
                validate={nameValidation}
                label="Name"
            />

            <ColorInput validate={requiredValidation} />
        </>
    )
}

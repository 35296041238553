import { SortType } from 'appTypes'
import { UnitModel } from 'appTypes/models'
import {
    BoxContainer,
    CardListConfig,
    DatagridColumnsProps,
    PageContent,
    StyledElement,
    dropdownSelectorInDatagridStyle,
} from 'components'
import { DatagridLink } from 'components/Datagrid'
import { ListSortContentProps } from 'components/list/ListSortDrawerContent'
import { FilterConfig } from 'components/list/filter/ListFilterForm'
import ListFilterRangeInput from 'components/list/filter/ListFilterRangeInput'
import ResourceContextProviderWithClearEffect from 'components/resource/ResourceContextProviderWithClearEffect'
import { unitFiltersCfg, unitSortCfg } from 'pages/Units/List/UnitsList'
import { UnitStatusSelector } from 'pages/Units/components'
import {
    archivedUnitResource,
    reportsUnitsResource,
    unitResource,
} from 'pages/Units/config/constants'
import { costMaskParams, formatDate, resolveDoublePriceMask } from 'utils'

import { navigateToUnit } from '../CostPerMeter/CostPerMeter'
import { ArchivedUnitBadge } from '../Downtime/components'
import { ListBaseWithPeriod, ListWithPeriodSelector, ReportsHeader } from '../components'
import LinkArrowButton from '../components/LinkArrowButton'
import LinkCardButton from '../components/LinkCardButon'
import { newNames } from '../utils'

interface totalsModel {
    totalFees: number
    totalLabor: number
    totalParts: number
    totalServices: number
    totalTax: number
}
const defaultSort: SortType<UnitModel> = {
    field: 'total',
    order: 'DESC',
}

const includedFiltersMap: (keyof UnitModel)[] = [
    'number',
    'name',
    'vin',
    'licensePlate',
    'model',
    'modelYear',
    'engineModel',
    'engineHp',
    'transmissionModel',
    'transmissionGears',
    'color',
    'tireSize',
    'status',
    'created',
    'engineVmrsManufacturer',
    'transmissionVmrsManufacturer',
    'vmrsEquipmentCategory',
    'vmrsManufacturer',
    // 'costPerMile',
    // 'costPerHour',
]

const includedSortMap: (keyof UnitModel)[] = [
    'number',
    'name',
    'created',
    'licensePlate',
    'vin',
    'vmrsEquipmentCategory',
    'model',
    'modelYear',
    'color',
    'status',
]
const cardsCfg: CardListConfig<UnitModel & totalsModel> = {
    titleSource: (record) => (
        <BoxContainer>
            <StyledElement
                sx={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                }}
            >
                <LinkCardButton
                    to={{
                        id: record.id,
                        type: 'edit',
                        resource: record.archived
                            ? archivedUnitResource.resource
                            : unitResource.resource,
                    }}
                    variant="inherit"
                    text={record.number}
                />
            </StyledElement>
            {record.archived && <ArchivedUnitBadge />}
        </BoxContainer>
    ),
    defaultImage: null,
    details: [
        { source: 'name', label: 'unit name' },
        { source: 'totalParts', label: 'parts', render: (value) => resolveDoublePriceMask(value) },
        { source: 'totalLabor', label: 'labor', render: (value) => resolveDoublePriceMask(value) },
        {
            source: 'totalServices',
            label: 'services',
            render: (value) => resolveDoublePriceMask(value),
        },
        { source: 'totalFees', label: 'fees', render: (value) => resolveDoublePriceMask(value) },
        { source: 'totalTax', label: 'tax', render: (value) => resolveDoublePriceMask(value) },
        { source: 'total', label: 'total', render: (value) => resolveDoublePriceMask(value) },
    ],
    action: (record) => <LinkArrowButton path={navigateToUnit(record)} />,
}
const columnsCfg: DatagridColumnsProps<UnitModel & totalsModel> = {
    resetColumns: {
        created: false,
        licensePlate: false,
        vin: false,
        vmrsEquipmentCategory: false,
        model: false,
        modelYear: false,
        color: false,
        status: false,
    },
    checkboxSelection: false,
    columns: [
        {
            field: 'number',
            headerName: 'Unit Number',
            renderCell: (params) => {
                const { value, ...restParams } = params
                return (
                    <DatagridLink
                        {...restParams}
                        resource={
                            params.row.archived
                                ? archivedUnitResource.resource
                                : unitResource.resource
                        }
                        value={
                            <BoxContainer>
                                <StyledElement
                                    sx={{
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                    }}
                                >
                                    {value}
                                </StyledElement>
                                {params.row.archived && <ArchivedUnitBadge />}
                            </BoxContainer>
                        }
                    />
                )
            },
        },
        { field: 'name', headerName: 'Unit Name' },
        {
            field: 'totalParts',
            headerName: 'Parts',
            renderCell: ({ value }) => resolveDoublePriceMask(value),
            align: 'right',
            headerAlign: 'right',
        },
        {
            field: 'totalLabor',
            headerName: 'Labor',
            renderCell: ({ value }) => resolveDoublePriceMask(value),
            align: 'right',
            headerAlign: 'right',
        },
        {
            field: 'totalServices',
            headerName: 'Services',
            renderCell: ({ value }) => resolveDoublePriceMask(value),
            align: 'right',
            headerAlign: 'right',
        },
        {
            field: 'totalFees',
            headerName: 'Fees',
            renderCell: ({ value }) => resolveDoublePriceMask(value),
            align: 'right',
            headerAlign: 'right',
        },
        {
            field: 'totalTax',
            headerName: 'Tax',
            renderCell: ({ value }) => resolveDoublePriceMask(value),
            align: 'right',
            headerAlign: 'right',
        },
        {
            field: 'total',
            headerName: 'Total',
            renderCell: ({ value }) => resolveDoublePriceMask(value),
            align: 'right',
            headerAlign: 'right',
        },

        {
            field: 'created',
            headerName: 'Created on',
            renderCell: (cell) => {
                return formatDate(cell.value, (dateFormats) => dateFormats.shortenedDate)
            },
        },
        { field: 'licensePlate', headerName: 'License Plate' },
        { field: 'vin', headerName: 'VIN' },
        {
            field: 'vmrsEquipmentCategory',
            headerName: 'Equipment Category',
            renderCell: ({ row }) => row.vmrsEquipmentCategoryData?.description,
        },
        { field: 'model', headerName: 'Model' },
        { field: 'modelYear', headerName: 'Model Year' },
        { field: 'color', headerName: 'Color' },
        {
            field: 'status',
            headerName: 'Unit Status',
            flex: 1,
            minWidth: 80,

            renderCell: (cell, { listContext }) => {
                return (
                    <UnitStatusSelector
                        sx={dropdownSelectorInDatagridStyle}
                        record={cell.row}
                        onComplete={() => {
                            listContext.refetch()
                        }}
                    />
                )
            },
        },
    ],
    actions: null,
}

const sortCfg: ListSortContentProps<UnitModel> = {
    sortBy: [
        {
            id: 'totalParts',
            label: 'Parts',
        },
        { id: 'totalLabor', label: 'Labor' },
        { id: 'totalServices', label: 'Services' },
        { id: 'totalFees', label: 'Fees' },
        { id: 'totalTax', label: 'Tax' },
        { id: 'total', label: 'Total' },
        ...unitSortCfg.sortBy
            .filter((item) => includedSortMap.some((id) => id === item.id))
            .map((item) => ({
                ...item,
                label: newNames[item.id] || item.label,
            })),
    ],
}
const filtersCfg: FilterConfig<UnitModel> = {
    filters: [
        {
            id: 'totalParts',
            label: 'Parts',
            filterType: 'range' as const,
            renderComponent: (props) => (
                <ListFilterRangeInput
                    inputProps={costMaskParams}
                    {...props}
                />
            ),
        },
        {
            id: 'totalLabor',
            label: 'Labor',
            filterType: 'range' as const,
            renderComponent: (props) => (
                <ListFilterRangeInput
                    inputProps={costMaskParams}
                    {...props}
                />
            ),
        },
        {
            id: 'totalServices',
            label: 'Services',
            filterType: 'range' as const,
            renderComponent: (props) => (
                <ListFilterRangeInput
                    inputProps={costMaskParams}
                    {...props}
                />
            ),
        },
        {
            id: 'totalFees',
            label: 'Fees',
            filterType: 'range' as const,
            renderComponent: (props) => (
                <ListFilterRangeInput
                    inputProps={costMaskParams}
                    {...props}
                />
            ),
        },
        {
            id: 'totalTax',
            label: 'Tax',
            filterType: 'range' as const,
            renderComponent: (props) => (
                <ListFilterRangeInput
                    inputProps={costMaskParams}
                    {...props}
                />
            ),
        },
        {
            id: 'total',
            label: 'Total',
            filterType: 'range' as const,
            renderComponent: (props) => (
                <ListFilterRangeInput
                    inputProps={costMaskParams}
                    {...props}
                />
            ),
        },
        ...unitFiltersCfg.filters
            .filter((item) => includedFiltersMap.some((id) => id === item.id))
            .map((item) => ({
                ...item,
                label: newNames[item.id] || item.label,
            })),
        { id: 'archived', label: 'Archived Unit' },
    ],
}

const preferencesResource = {
    ...reportsUnitsResource,
    name: 'maintenance-cost',
}

const MaintenanceCost = () => {
    return (
        <ResourceContextProviderWithClearEffect value={reportsUnitsResource}>
            <ListBaseWithPeriod
                sort={defaultSort}
                filter={{ withAspects: ['totals'] }}
                preferencesResource={preferencesResource}
            >
                <ReportsHeader
                    renderTotal={({ hasPreviousPage, total: totalCount }) =>
                        `${totalCount} | ${resolveDoublePriceMask(
                            (hasPreviousPage as any).aggregates.totalQueryset || 0,
                        )} total`
                    }
                >
                    Maintenance Cost
                </ReportsHeader>
                <PageContent>
                    <ListWithPeriodSelector
                        exportFileName="maintenance-cost"
                        filtersCfg={filtersCfg}
                        sortCfg={sortCfg}
                        columnsCfg={columnsCfg}
                        cardsCfg={cardsCfg}
                        preferencesResource={preferencesResource}
                    />
                </PageContent>
            </ListBaseWithPeriod>
        </ResourceContextProviderWithClearEffect>
    )
}

export default MaintenanceCost

import { WidgetListDescription } from '../../Widgets/components'

const WidgetListFTU = ({ description }: { description: React.ReactNode }) => {
    // const dimensions = useWidgetContext()
    return <WidgetListDescription>{description}</WidgetListDescription>
    // return dimensions === 'mdY' ? (
    //     <BoxContainer
    //         justifyContent="center"
    //         flex="1"
    //     >
    //         <Icons.WidgetsFTU />
    //     </BoxContainer>
    // ) : (
    //     <WidgetListDescription>{description}</WidgetListDescription>
    // )
}
export default WidgetListFTU

import { alpha, Alert, IconButton, Link } from '@mui/material'
import { inject, observer } from 'mobx-react'

import {
    InfoCard,
    Columns,
    StyledElement,
    IconBox,
    Spacer,
    BoxContainer,
    Typography,
    Button,
} from 'components'
import Icons from 'components/icons'
import renderOnBilling from 'hocs/renderOnBilling'
import { AuthStore } from 'providers/authStore'
import { resolveDoublePriceMask, capitalize, formatDate } from 'utils'

import { TooltipContainer } from '../CurrentPlan/components/Tooltip/TooltipContainer'
import { usePaymentMethodForm } from '../hooks'

import BreakdownTooltip from './components/BreakdownTooltip'
import { useOpenPaymentLimit } from './hooks'

const CurrentBill = inject('auth')(
    observer(({ auth }: { auth?: AuthStore }) => {
        const billing = auth.billing
        const subscription = billing.subscription
        const openPaymentLimit = useOpenPaymentLimit()
        const openPaymentMethod = usePaymentMethodForm()

        return (
            <InfoCard sx={{ padding: '20px' }}>
                <Columns>
                    <Typography
                        variant="chartTitle"
                        color="text.secondary"
                    >
                        Current Bill
                    </Typography>
                    <StyledElement sx={{ mt: '16px' }}>
                        <StyledElement sx={{ display: 'flex' }}>
                            <Typography variant="h4">
                                {resolveDoublePriceMask(billing.toBePaidWorkOrdersForPeriodTotal)}
                            </Typography>

                            <TooltipContainer
                                title={<BreakdownTooltip billing={billing} />}
                                placement="bottom"
                            >
                                <IconButton
                                    aria-label="Breakdown"
                                    sx={{
                                        ml: 'auto',
                                        width: '30px',
                                        height: '30px',
                                    }}
                                    disabled={billing.toBePaidWorkOrdersForPeriodTotal === 0}
                                    component="div"
                                >
                                    <Icons.Calculate
                                        sx={{
                                            width: '15px',
                                            height: '15px',
                                            color: (theme) =>
                                                alpha(theme.palette.text.primary, 0.54),
                                        }}
                                    />
                                </IconButton>
                            </TooltipContainer>
                        </StyledElement>
                        {subscription === 'pro' && (
                            <>
                                {billing.paymentLimit || billing.paymentLimit === 0 ? (
                                    <Spacer>
                                        <Typography
                                            variant="chartsBody"
                                            color="text.secondary"
                                        >
                                            Limit {resolveDoublePriceMask(billing.paymentLimit)}
                                        </Typography>
                                        <Link
                                            // TODO: mui Link does not have typography,
                                            // if component prop is not set
                                            onClick={openPaymentLimit}
                                            component="button"
                                            display="inline-block"
                                        >
                                            Edit
                                        </Link>
                                    </Spacer>
                                ) : (
                                    <Button
                                        onClick={openPaymentLimit}
                                        size="small"
                                        startIcon={<Icons.Add />}
                                        variant="text"
                                    >
                                        Add Limit
                                    </Button>
                                )}
                                <Alert
                                    sx={{ mt: '16px', mb: '20px' /* '24px'*/ }}
                                    severity="info"
                                >
                                    Automatic billing on{' '}
                                    {formatDate(
                                        billing.currentPeriodEnd,
                                        (dateFormats) => dateFormats.shortenedDate,
                                    )}
                                </Alert>
                            </>
                        )}
                        <Typography
                            variant="chartTitle"
                            color="text.secondary"
                        >
                            Payment Methods
                        </Typography>
                        <BoxContainer
                            sx={{
                                mt: '27px',
                            }}
                        >
                            <StyledElement>
                                <Typography
                                    variant="body2"
                                    color="text.primary"
                                    sx={{ lineHeight: 0.9 }}
                                >
                                    {billing.card.brand &&
                                        capitalize(billing.card.brand) +
                                            ' **** ' +
                                            billing.card.last4}
                                </Typography>
                                <Typography
                                    variant="tooltip"
                                    color="text.disabled"
                                >
                                    {billing.card.brand &&
                                        'EXP ' +
                                            (billing.card.expMonth.length > 1 ? '' : '0') +
                                            billing.card.expMonth +
                                            '/' +
                                            billing.card.expYear.substring(2, 4)}
                                </Typography>
                            </StyledElement>
                            <IconBox
                                sx={{ marginLeft: 'auto' }}
                                onClick={() => {
                                    openPaymentMethod('change')
                                }}
                            >
                                <Icons.Edit />
                            </IconBox>
                        </BoxContainer>
                    </StyledElement>
                </Columns>
            </InfoCard>
        )
    }),
)

export default renderOnBilling(CurrentBill)

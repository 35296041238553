import { FC, PropsWithChildren, ReactElement } from 'react'

import {
    ErrorBoundary as SentryErrorBoundary,
    withErrorBoundary as withSentryErrorBoundary,
} from '@sentry/react'

import { Typography } from 'components/mui'

const fallback = <Typography p="5px">Something went wrong!</Typography>

const ErrorBoundary: FC<PropsWithChildren & { fallback?: ReactElement }> = ({
    children,
    fallback,
}) => {
    return <SentryErrorBoundary fallback={fallback}>{children}</SentryErrorBoundary>
}

export default ErrorBoundary

export const withErrorBoundary: typeof withSentryErrorBoundary = (Component) =>
    withSentryErrorBoundary(Component, { fallback })

import { ReactElement, useEffect, useState } from 'react'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import {
    drawerClasses,
    SwipeableDrawer,
    useMediaQuery,
    Theme,
    Box,
    TextFieldProps,
    alpha,
    DrawerProps,
} from '@mui/material'
import {
    RaRecord,
    ListContextProvider,
    useReferenceInputController,
    ChoicesContextProvider,
    useInput,
    Identifier,
    TextInput,
    required,
    UseReferenceInputControllerParams,
    ChoicesContextValue,
    UseInputValue,
    ResettableTextFieldClasses,
} from 'react-admin'

import { BoxContainer } from 'components/styled'
import { useCreateInputId, useSetBlocker } from 'hooks'
import { extendRecord } from 'utils'

import DialogSelectorAppBar, { DialogSelectorAppBarProps } from './DialogSelectorAppBar'
import { DialogSelectorProvider } from './DialogSelectorContext'
import DialogSelectorFilter, { DialogSelectorFilterProps } from './DialogSelectorFilter'
import DialogSelectorList, { DialogSelectorListProps } from './DialogSelectorList'
import DialogSelectorLoadMore from './DialogSelectorLoadMore'
import DialogSelectorResultCount from './DialogSelectorResultCount'

import type { AllProps, ExtendRecordType } from 'appTypes'

export interface DialogSelectorUniversalParam<OptionType extends RaRecord = any> {
    value: string | number
    selected: OptionType
    control: ChoicesContextValue<OptionType>
    input: UseInputValue
    onSelect: (id: Identifier) => void
    defaultFilter: any
    handleOpen: (event?: React.MouseEvent<any, MouseEvent>) => void
}
export interface DialogSelectorProps<OptionType extends RaRecord = any>
    extends Omit<DialogSelectorListProps<OptionType>, 'selectItem'> {
    source: string
    required?: boolean
    titleSource?: ExtendRecordType<OptionType, string | number>
    reference: string
    searchPlaceholder?: string
    defaultSelectorProps?: TextFieldProps
    defaultSelectorValueSource?: ExtendRecordType<
        DialogSelectorUniversalParam<OptionType>,
        string | number
    >
    defaultSelectorHelperText?: (params: DialogSelectorUniversalParam<OptionType>) => string | null
    filter?: DialogSelectorFilterProps
    referenceFilter?: AllProps
    onSelectedChange?: (params: DialogSelectorUniversalParam<OptionType>) => void
    enableGetChoices?: UseReferenceInputControllerParams['enableGetChoices']
    defaultFilter?: any
    renderAboveList?: (params: DialogSelectorUniversalParam<OptionType>) => ReactElement
    renderTop?: (params: DialogSelectorUniversalParam<OptionType>) => ReactElement
    renderNextToResultCount?: (data: DialogSelectorUniversalParam<OptionType>) => ReactElement
    disabled?: boolean
    appBar?: Pick<DialogSelectorAppBarProps, 'leftButton' | 'paddingX'>
    renderToggler?: (params: DialogSelectorUniversalParam<OptionType>) => ReactElement
    onChange?: (control: ChoicesContextValue<OptionType>, id?: Identifier) => void
    queryOptions?: (open: boolean) => UseReferenceInputControllerParams<OptionType>['queryOptions']
}

const requiredField = required('Required field')

const DialogSelector = <OptionType extends RaRecord = any>({
    defaultFilter,
    itemPrimary,
    itemSecondary,
    renderListItem,
    source,
    defaultSelectorProps,
    titleSource,
    reference,
    required,
    referenceFilter,
    defaultSelectorValueSource,
    defaultSelectorHelperText,
    filter,
    enableGetChoices,
    renderNoResults,
    onSelectedChange,
    renderAboveList,
    renderTop,
    disabled,
    renderNextToResultCount,
    appBar,
    noResults,
    renderToggler,
    onChange,
    queryOptions,
}: DialogSelectorProps<OptionType>) => {
    const [open, setOpen] = useState(false)

    const handleOpen = (e: React.MouseEvent<any, MouseEvent>) => {
        const target = e?.target as HTMLElement
        // Don't open if RA clear button clicked
        if (
            disabled ||
            (target &&
                (target.classList.contains(ResettableTextFieldClasses.clearButton) ||
                    target.classList.contains(ResettableTextFieldClasses.clearIcon)))
        ) {
            return null
        }
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    useSetBlocker(
        {
            close: handleClose,
        },
        {
            isOpen: open,
        },
    )

    const isExtraSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down(theme.props.mobileViewBreakpoint),
    )
    const anchor: DrawerProps['anchor'] = isExtraSmall ? 'bottom' : 'right'

    const controllerProps = useReferenceInputController<OptionType>({
        filter: referenceFilter,
        reference,
        source,
        enableGetChoices,
        // For test purposes
        // @ts-ignore
        perPage: window.DIALOG_SELECTOR_PERPAGE || 300,
        queryOptions: queryOptions?.(open),
    })

    const createId = useCreateInputId()

    const input = useInput({
        source,
        id: createId(source),
    })

    const { field } = input

    const selected = controllerProps.selectedChoices?.[0]

    const onSelect: DialogSelectorUniversalParam['onSelect'] = (id) => {
        if (onChange) {
            onChange(controllerProps, id)
        } else {
            field.onChange(id)
        }
        handleClose()
    }

    const data: DialogSelectorUniversalParam<OptionType> = {
        value: field.value,
        selected,
        control: controllerProps,
        input,
        onSelect,
        defaultFilter: defaultFilter || {},
        handleOpen,
    }

    useEffect(() => {
        if (!open) {
            controllerProps.setFilters(defaultFilter || {}, {}, false)
        }
    }, [open])

    useEffect(() => {
        onSelectedChange?.(data)
    }, [selected?.id])

    return (
        <DialogSelectorProvider value={data}>
            <ListContextProvider value={controllerProps as any}>
                <ChoicesContextProvider value={controllerProps}>
                    <div>
                        {renderToggler ? (
                            renderToggler(data)
                        ) : (
                            // @ts-ignore
                            <TextInput
                                id={input.id}
                                {...defaultSelectorProps}
                                sx={{
                                    input: {
                                        cursor: disabled ? null : 'pointer !important',
                                        // TODO: check if ellipsis can't be applied to all inputs
                                        textOverflow: 'ellipsis',
                                    },
                                }}
                                {...(required ? { validate: requiredField } : { resettable: true })}
                                variant="outlined"
                                size="medium"
                                focused={false}
                                onClick={handleOpen}
                                source={source}
                                disabled={disabled}
                                InputProps={{
                                    endAdornment: (
                                        <ArrowForwardIcon
                                            onClick={handleOpen}
                                            sx={{
                                                color: (theme) =>
                                                    alpha(theme.palette.grey[900], 0.54),
                                                cursor: disabled ? null : 'pointer !important',
                                            }}
                                        />
                                    ),
                                }}
                                inputProps={{
                                    onFocus: (e) => e.target.blur(),
                                    readOnly: true,
                                    value:
                                        (defaultSelectorValueSource
                                            ? extendRecord(data, defaultSelectorValueSource)
                                            : field.value) || '',
                                }}
                                helperText={defaultSelectorHelperText?.(data)}
                            />
                        )}
                        <SwipeableDrawer
                            PaperProps={{
                                sx: {
                                    height: '100%',
                                    [`&.${drawerClasses.paperAnchorRight}`]: {
                                        maxWidth: '100%',
                                        width: '390px',
                                    },
                                },
                            }}
                            anchor={anchor}
                            open={open}
                            onClose={handleClose}
                            onOpen={handleOpen}
                        >
                            <DialogSelectorAppBar
                                {...appBar}
                                onClose={handleClose}
                                selected={selected}
                                titleSource={titleSource}
                            />
                            {/* HACK: for FilterLiveSearch */}
                            <DialogSelectorFilter {...filter} />
                            <Box
                                overflow="auto"
                                height="100%"
                                display="flex"
                                flexDirection="column"
                            >
                                <Box p="20px">
                                    {renderTop?.(data)}
                                    <BoxContainer justifyContent="space-between">
                                        {renderNextToResultCount?.(data)}
                                        <DialogSelectorResultCount />
                                    </BoxContainer>
                                    {renderAboveList?.(data)}
                                </Box>
                                <DialogSelectorList
                                    renderNoResults={renderNoResults}
                                    noResults={noResults}
                                    itemPrimary={itemPrimary}
                                    itemSecondary={itemSecondary}
                                    selectItem={onSelect}
                                    renderListItem={renderListItem}
                                />
                            </Box>
                            <DialogSelectorLoadMore />
                        </SwipeableDrawer>
                    </div>
                </ChoicesContextProvider>
            </ListContextProvider>
        </DialogSelectorProvider>
    )
}

export default DialogSelector

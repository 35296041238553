import { useEffect } from 'react'

import { inject } from 'mobx-react'

import { useFlags } from 'hooks'
import { AuthStore } from 'providers/authStore'

const MobxSyncFlags = inject('auth')(({ auth }: { auth?: AuthStore }) => {
    const flags = useFlags()

    useEffect(() => {
        auth.updateFlags(flags)
    }, [flags])

    return null
})

export default MobxSyncFlags

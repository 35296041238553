import { BoxContainer, Columns, Status, Typography } from 'components'
import { WidgetListFTU } from 'pages/Dashboard/components/WidgetBase/styled'
import { formatPercent } from 'utils'

import { ListItem } from '../../components'
import { ComplianceRateResponse } from '../types'
interface ComplianceRateListProps {
    validData: boolean
    description: string
    data: ComplianceRateResponse
}

const ComplianceRateList = ({ data, validData, description }: ComplianceRateListProps) => {
    if (!validData) {
        return <WidgetListFTU description={description} />
    }
    return (
        <Columns
            width="100%"
            gap="30px"
        >
            <Typography
                component={ListItem}
                variant="chartListItem"
            >
                <BoxContainer>
                    <Status
                        iconColor={(theme) => theme.palette.charts.greenBody}
                        mr="13px"
                    />
                    On-Time (Planned + Due Soon)
                </BoxContainer>
                {formatPercent(data.onTimePercent)}
            </Typography>
            <Typography
                component={ListItem}
                variant="chartListItem"
            >
                <BoxContainer>
                    <Status
                        iconColor={(theme) => theme.palette.charts.red}
                        mr="13px"
                    />
                    Not On-Time (Overdue)
                </BoxContainer>
                {formatPercent(data.notOnTimePercent)}
            </Typography>
        </Columns>
    )
}
export default ComplianceRateList

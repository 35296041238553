import { formControlLabelClasses } from '@mui/material'
import { BooleanInput } from 'react-admin'
import { useWatch } from 'react-hook-form'

import { Billing } from 'appTypes/models'
import {
    AutocompleteInput,
    EmailInput,
    SectionTitleInDrawer,
    TelInput,
    TextInput,
} from 'components'
import { stateChoices } from 'configs/constants'
import { renderOnBilling } from 'hocs'
import { capitalize, emailValidation, maxLengthValidation, requiredValidation } from 'utils'

export const sameAsBilling: keyof Pick<Billing['billingDetails'], 'sameAsBillingInfo'> =
    'sameAsBillingInfo'

export const companyNameValidation = [maxLengthValidation, requiredValidation]

const CompanyFormContent = ({ prefix = '' }: { prefix?: string }) => {
    const getName = (name: string) => (prefix === '' ? name : prefix + capitalize(name))

    return (
        <>
            <SectionTitleInDrawer>
                {prefix === '' ? 'Basic Information' : 'Billing Information'}
            </SectionTitleInDrawer>
            <TextInput
                source={getName('name')}
                label="Company Name"
                validate={companyNameValidation}
            />
            <TelInput
                source={getName('phone')}
                label="Phone Number"
                validate={requiredValidation}
            />
            <EmailInput
                source={getName('email')}
                validate={emailValidation}
            />

            <SectionTitleInDrawer>Address</SectionTitleInDrawer>
            <TextInput
                source={getName('address')}
                label="Address Line 1"
                validate={maxLengthValidation}
            />
            <TextInput
                source={getName('address2')}
                label="Address Line 2"
                validate={maxLengthValidation}
            />
            <TextInput
                source={getName('city')}
                label="City"
                validate={maxLengthValidation}
            />
            <AutocompleteInput
                choices={stateChoices}
                source={getName('state')}
                label="State"
                matchSuggestion={undefined}
            />
            <TextInput
                source={getName('zipCode')}
                label="ZIP Code"
            />
        </>
    )
}

const BillingFormContent = renderOnBilling(() => {
    const same = useWatch({ name: sameAsBilling })

    return (
        <>
            <BooleanInput
                source={sameAsBilling}
                label="Same as Billing Info?"
                sx={{
                    [`& .${formControlLabelClasses.root}`]: {
                        flexDirection: 'row-reverse',
                        justifyContent: 'space-between',
                        margin: 0,
                    },
                    [`& .${formControlLabelClasses.label}`]: {
                        typography: (theme) => theme.typography.h6,
                    },
                }}
            />
            {!same && <CompanyFormContent prefix="billing" />}
        </>
    )
})

const CompanyInfoDrawerForm = () => {
    return (
        <>
            <CompanyFormContent />
            <BillingFormContent />
        </>
    )
}

export default CompanyInfoDrawerForm

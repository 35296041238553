import { FC } from 'react'

import { formControlLabelClasses } from '@mui/material'
import { BooleanInput } from 'react-admin'

import { BoxContainer, EllipsisOverflow, StatusText, Typography } from 'components'
import { uppercaseFormat } from 'utils'

interface LabelSwitchProps {
    children: string
    label: 'pm' | 'dvir'
    source: string
}

const LabelSwitch: FC<LabelSwitchProps> = ({ children, label, source }) => {
    return (
        <BoxContainer>
            <BooleanInput
                sx={{ [`& .${formControlLabelClasses.root}`]: { marginRight: 0 } }}
                source={source}
                helperText={false}
                defaultValue
                label={null}
            />
            <BoxContainer
                textOverflow="ellipsis"
                justifyContent="space-between"
                overflow="hidden"
                width="100%"
            >
                <Typography
                    component={EllipsisOverflow}
                    variant="body1"
                    mr="16px"
                >
                    {children}
                </Typography>
                <StatusText
                    color={(theme) => theme.palette.primary.main}
                    bgcolor={(theme) => theme.palette.primary.main}
                >
                    {uppercaseFormat(label)}
                </StatusText>
            </BoxContainer>
        </BoxContainer>
    )
}

export default LabelSwitch

import { Identifier, ListControllerResult } from 'react-admin'

import { VendorModel } from 'appTypes/models'
import { Columns, ResourceType, TextareaInput, TextInput } from 'components'
import { DrawerState } from 'components/Drawer/UtilityDrawer'
import UtilityDrawerListEditor from 'components/Drawer/UtilityDrawerListEditor'
import { JobLaborForm, jobLaborSerializer } from 'pages/WorkOrders/Jobs/Labor'
import { JobPartForm, jobPartSerializer } from 'pages/WorkOrders/Jobs/Parts'
import { WorkOrderItemsExtra } from 'pages/WorkOrders/Jobs/config/constants'
import { costMaskParams, maxLengthValidationText, requiredValidation } from 'utils'

import { LaborFormExtra } from '.'
const descriptionValidation = [maxLengthValidationText, requiredValidation]
export const editPartActionExtraArgs = (
    resource: ResourceType,
    listContext: ListControllerResult,
    id: Identifier,
    onSuccess: () => void,
    disabledFields: boolean,
    vendor: Pick<VendorModel, 'id' | 'name'> | null,
): DrawerState => ({
    drawerArgs: {
        renderTopRight: (render) =>
            render({
                onSuccess,
                disabled: disabledFields,
                title: disabledFields ? 'Reopen the WO to delete this item' : undefined,
                confirmConfig: {
                    title: 'Are you sure you want to delete this item?',
                },
            }),
        title: disabledFields ? 'View Part' : 'Edit Part',
        renderWrapper: (params) => (
            <UtilityDrawerListEditor
                {...params}
                onSuccess={onSuccess}
                serializer={jobPartSerializer}
            />
        ),
        renderContent: () => <JobPartForm />,
        renderBottomRight: disabledFields ? () => null : undefined,
        renderBottomLeft: disabledFields ? (render) => render({ children: 'Close' }) : undefined,
    },
    extraArgs: {
        listContext,
        resource,
        id,
    },
    extra: {
        disabledFields,
        vendor,
    } as WorkOrderItemsExtra & {
        vendor: Pick<VendorModel, 'id' | 'name'> | null
    },
})

export const editLaborActionExtraArgs = (
    resource: ResourceType,
    listContext: ListControllerResult,
    id,
    onSuccess: () => void,
    disabledFields: boolean,
): DrawerState => ({
    drawerArgs: {
        renderTopRight: (render) =>
            render({
                onSuccess,
                disabled: disabledFields,
                title: disabledFields ? 'Reopen the WO to delete this item' : undefined,
                confirmConfig: {
                    title: 'Are you sure you want to delete this item?',
                },
            }),
        renderWrapper: (params) => (
            <UtilityDrawerListEditor
                {...params}
                onSuccess={onSuccess}
                serializer={jobLaborSerializer}
            />
        ),
        title: disabledFields ? 'View Labor' : 'Edit Labor',
        renderContent: () => <JobLaborForm />,
        renderBottomRight: disabledFields ? () => null : undefined,
        renderBottomLeft: disabledFields ? (render) => render({ children: 'Close' }) : undefined,
    },
    extraArgs: {
        listContext,
        resource,
        id,
    },
    extra: {
        disabledFields,
    } as LaborFormExtra,
})
export const editVWOItemDrawerArgs = (
    name: string,
    resource: ResourceType,
    listContext: ListControllerResult,
    id: Identifier,
    onSuccess: () => void,
    disabledFields: boolean,
): DrawerState => ({
    drawerArgs: {
        renderTopRight: (render) =>
            render({
                onSuccess,
                disabled: disabledFields,
                title: disabledFields ? 'Reopen the WO to delete this item' : undefined,
                confirmConfig: {
                    title: 'Are you sure you want to delete this item?',
                },
            }),
        title: disabledFields ? `View ${name}` : `Edit ${name}`,
        renderWrapper: (args) => (
            <UtilityDrawerListEditor
                onSuccess={onSuccess}
                {...args}
            />
        ),
        renderContent: () => (
            <Columns gap="10px">
                <TextareaInput
                    source="description"
                    label="Description"
                    disabled={disabledFields}
                    validate={descriptionValidation}
                />
                <TextInput
                    source="price"
                    label="Amount"
                    {...costMaskParams}
                    disabled={disabledFields}
                    validate={requiredValidation}
                />
            </Columns>
        ),
        renderBottomRight: disabledFields ? () => null : undefined,
        renderBottomLeft: disabledFields ? (render) => render({ children: 'Close' }) : undefined,
    },
    extraArgs: {
        listContext,
        resource,
        id,
    },
    extra: {
        disabledFields,
    } as WorkOrderItemsExtra,
})

import { inject, observer } from 'mobx-react'
import { Validator } from 'react-admin'

import Google from 'assets/images/social-google.svg'
import {
    GridItemLayout,
    PageContent,
    SaveButtonMobileFixed,
    Form,
    SectionTitle,
    BoxContainer,
    Typography,
    ThemeButton,
    CombinedInputs,
    PasswordInput,
    StyledImg,
    GridContainerColumns,
} from 'components'
import Icons from 'components/icons'
import { useSubmit, useAuthProvider, useFlags } from 'hooks'
import { AuthStore } from 'providers/authStore'
import { passwordValidation, requiredValidation, validatePasswordMatch } from 'utils'

interface FormValues {
    currentPassword: string
    newPassword: string
}
interface AccountSettingsProps {
    auth?: AuthStore
}

const passwordMatchValidation: Validator[] = [
    requiredValidation,
    validatePasswordMatch('newPassword'),
]

const AccountSettings = inject('auth')(
    observer(({ auth }: AccountSettingsProps) => {
        const user = auth.user
        const authProvider = useAuthProvider()
        const { flagDarkMode } = useFlags()

        const submitHandler = useSubmit<FormValues>(
            async ({ currentPassword, newPassword }) => {
                await authProvider.changePassword(currentPassword, newPassword)
                await auth.fetchUser()
            },
            {
                successMessage: ({ defaultMessages }) => defaultMessages.updated,
            },
        )

        return (
            <PageContent>
                <SectionTitle>
                    {user.hasUsablePassword ? 'Change Password' : 'Set Password'}
                </SectionTitle>
                <Form
                    resetOnSuccess
                    onSubmit={submitHandler}
                    formRootPathname="account-settings"
                >
                    <GridContainerColumns>
                        <GridItemLayout>
                            {user.hasUsablePassword ? (
                                <PasswordInput
                                    source="currentPassword"
                                    label="Your Current Password"
                                    autoComplete="new-password"
                                    validate={requiredValidation}
                                />
                            ) : (
                                <BoxContainer mb="32px">
                                    <StyledImg
                                        src={Google}
                                        alt="google"
                                        width="24px"
                                        sx={{ mr: '10px' }}
                                    />
                                    <Typography variant="body1">Registered with Google</Typography>
                                </BoxContainer>
                            )}

                            <PasswordInput
                                source="newPassword"
                                label="New Password"
                                validate={passwordValidation}
                                helperText="Use at least 8 characters"
                            />
                            <CombinedInputs
                                sources={['confirmPassword']}
                                track={['newPassword']}
                            />
                            <PasswordInput
                                source="confirmPassword"
                                validate={passwordMatchValidation}
                                label="Confirm New Password"
                            />
                        </GridItemLayout>
                    </GridContainerColumns>
                    <SaveButtonMobileFixed startIcon={<Icons.SaveFilled />} />
                </Form>
                {flagDarkMode && (
                    <BoxContainer>
                        <ThemeButton mode="light" />
                        <ThemeButton mode="system" />
                        <ThemeButton mode="dark" />
                    </BoxContainer>
                )}
            </PageContent>
        )
    }),
)

export default AccountSettings

import { ReactElement } from 'react'

import { ListControllerResult, useShowContext } from 'react-admin'

import { Columns, ResourceType, TextareaInput, TextInput, useOpenUtilityDrawer } from 'components'
import UtilityDrawerListEditor from 'components/Drawer/UtilityDrawerListEditor'
import { useWoActionIsDisabled } from 'pages/WorkOrders/hooks'
import { costMaskParams, maxLengthValidationText, requiredValidation } from 'utils'

interface DrawerProps {
    children: (params: { onClick: () => void }) => ReactElement
    title: string
    resource: ResourceType
    listContext: ListControllerResult
    itemResource: string
}
const descriptionValidation = [maxLengthValidationText, requiredValidation]

const VendorWorkOrderExtraActionsDrawerToggler = ({
    itemResource,
    listContext,
    resource,
    children,
    title,
}: DrawerProps) => {
    const open = useOpenUtilityDrawer()
    const { refetch, record } = useShowContext()

    const disabled = useWoActionIsDisabled({ workOrder: record })
    return children({
        onClick: disabled
            ? () => {
                  /* * */
              }
            : () =>
                  open({
                      extraArgs: {
                          resource: {
                              ...resource,
                              resource: resource.resource + `/add_${itemResource}`,
                          },
                          listContext,
                      },
                      drawerArgs: {
                          title,
                          renderWrapper: (args) => (
                              <UtilityDrawerListEditor
                                  onSuccess={refetch}
                                  {...args}
                              />
                          ),
                          renderContent: () => (
                              <Columns gap="10px">
                                  <TextareaInput
                                      source="description"
                                      label="Description"
                                      validate={descriptionValidation}
                                  />
                                  <TextInput
                                      source="price"
                                      label="Amount"
                                      {...costMaskParams}
                                      validate={requiredValidation}
                                  />
                              </Columns>
                          ),
                          renderBottomRight: (render) => render({ label: title }),
                      },
                  }),
    })
}

export default VendorWorkOrderExtraActionsDrawerToggler

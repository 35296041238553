import { PropsWithChildren } from 'react'

import { PlusOneOutlined } from '@mui/icons-material'
import { styled, SvgIcon } from '@mui/material'
import { alpha } from '@mui/material/styles'
import { useTranslate } from 'react-admin'

import { PMReportsModel, UnitPMIntervalIntersection } from 'appTypes/models'
import { BoxContainer, Columns, Typography } from 'components'
import { ActionsMenu } from 'components/actions'
import { getMeterTypeAdornment } from 'pages/Units/Meters/constants'
import { getLeft, getLeftUnitType } from 'pages/Units/PM/UnitPMCard/BottomSection'
import { getColor, getImage } from 'pages/Units/PM/UnitPMCard/UnitPMCard'
import { capitalizeWithLowerCase, formatDate, resolveIntegerSpacedMask } from 'utils'
interface OverlaySectionProps {
    title: string
}
interface StatusSectionProps {
    status: UnitPMIntervalIntersection['status']
    gap?: string
    colorful?: boolean
}
export const StatusSection = ({ status, gap = '18px', colorful = false }: StatusSectionProps) => {
    const formattedStatus = capitalizeWithLowerCase(status)
    return (
        <BoxContainer
            component="span"
            gap={gap}
        >
            <SvgIcon
                component={getImage(status)}
                sx={{
                    color: getColor(status),
                    width: '16px',
                    height: '16px',
                }}
            />
            <Typography
                variant="menuItem"
                color={colorful ? getColor(status) : 'text.primary'}
            >
                {formattedStatus === 'Due' ? formattedStatus + ' Soon' : formattedStatus}
            </Typography>
        </BoxContainer>
    )
}
const OverlaySection = ({ title, children }: OverlaySectionProps & PropsWithChildren) => {
    return (
        <Typography
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            color="text.disabled"
            variant="menuItem"
            display="flex"
        >
            {title}&nbsp;
            <Typography
                overflow="hidden"
                color="text.primary"
                display="flex"
            >
                {children}
            </Typography>
        </Typography>
    )
}
const BadgeButtonContainer = styled(BoxContainer)`
    color: ${({ theme }) => theme.palette.primary.main};
    background-color: ${({ theme }) => alpha(theme.palette.primary.main, 0.12)};
    height: 18px;
    width: 20px;
    cursor: pointer;
    justify-content: center;
    border-radius: 2px;
`
const IntervalsShowMoreButton = ({ record }: { record: PMReportsModel }) => {
    const translate = useTranslate()
    const interval = record.intervals[1]

    if (!interval) {
        return null
    }

    const intervalMesure = capitalizeWithLowerCase(getLeftUnitType(interval.type))

    return (
        <ActionsMenu
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            renderToggler={(open) => (
                <BadgeButtonContainer
                    component="span"
                    onClick={open}
                >
                    <SvgIcon
                        component={PlusOneOutlined}
                        sx={{ width: '16px', height: '16px' }}
                    />
                </BadgeButtonContainer>
            )}
            actions={() => [
                <Columns
                    key="content"
                    maxWidth="300px"
                    p="4px 12px"
                    gap="12px"
                >
                    <Typography
                        color="text.primary"
                        variant="menuItem"
                        textTransform="capitalize"
                    >
                        {translate(interval.type).toLocaleLowerCase()} Meter
                    </Typography>
                    <StatusSection status={interval.status} />
                    <OverlaySection title="Last Service">
                        {interval.type === 'TIME'
                            ? formatDate(
                                  record.lastDone,
                                  (dateFormats) => dateFormats.shortenedDate,
                              )
                            : `${resolveIntegerSpacedMask(
                                  interval.lastDoneMeterValue,
                              )} ${getMeterTypeAdornment(interval.type)}`}
                    </OverlaySection>
                    <OverlaySection title="Next Service">
                        {interval.type === 'TIME'
                            ? formatDate(
                                  interval.overdue,
                                  (dateFormats) => dateFormats.shortenedDate,
                              )
                            : `${resolveIntegerSpacedMask(
                                  interval.overdue,
                              )} ${getMeterTypeAdornment(interval.type)}`}
                    </OverlaySection>
                    <OverlaySection title={`Left (${intervalMesure})`}>
                        <Typography
                            component="span"
                            textOverflow="ellipsis"
                            overflow="hidden"
                            whiteSpace="nowrap"
                        >
                            {getLeft(interval)} {getMeterTypeAdornment(interval.type)}
                        </Typography>
                        {getLeft(interval).charAt(0) !== '0' && interval.status === 'OVERDUE' && (
                            <Typography component="span">&nbsp;overdue</Typography>
                        )}
                    </OverlaySection>
                    <OverlaySection
                        title={`Interval (${
                            interval.type === 'TIME'
                                ? capitalizeWithLowerCase(interval.valueType)
                                : intervalMesure
                        })`}
                    >
                        {`${resolveIntegerSpacedMask(interval.value)} ${
                            interval.type === 'TIME'
                                ? interval.valueType.toLowerCase() + 's'
                                : getMeterTypeAdornment(interval.type)
                        }`}
                    </OverlaySection>
                </Columns>,
            ]}
        />
    )
}
export default IntervalsShowMoreButton

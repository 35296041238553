import { styled } from '@mui/material'

const SROnly = styled('div')`
    display: none !important;
`

SROnly.defaultProps = {
    className: 'sr-only',
}

export default SROnly

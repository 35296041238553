import { CompanyModel } from 'appTypes/models'
import {
    InfoCard,
    InfoCardDetails,
    InfoCardHeader,
    IconBox,
    InfoCardDetailType,
    Typography,
} from 'components'
import Icons from 'components/icons'
import { basePermissions } from 'configs/constants'
import { renderOnPermission } from 'hocs'
import { phoneMaskResolver } from 'utils'

import { companyName } from './config/constants'

import { CompanyInfoEditDrawerToggler } from '.'

const detailsDetails: InfoCardDetailType<CompanyModel>['details'] = [
    {
        label: 'Company Name',
        source: 'name',
    },
    {
        label: 'Phone Number',
        source: ({ phone }) => phoneMaskResolver(phone),
    },
    {
        label: 'Email',
        source: 'email',
    },
    {
        label: 'Address',
        source: 'address',
    },
    {
        label: 'City',
        source: 'city',
    },
    {
        label: 'State',
        source: 'state',
    },
    {
        label: 'Zip Code',
        source: 'zipCode',
    },
]

const EditAction = renderOnPermission(
    () => {
        return (
            <CompanyInfoEditDrawerToggler>
                {({ onClick }) => (
                    <IconBox
                        onClick={onClick}
                        title="Edit"
                    >
                        <Icons.Edit />
                    </IconBox>
                )}
            </CompanyInfoEditDrawerToggler>
        )
    },
    basePermissions.update,
    companyName,
)

const CompanyInfoDetailsCard = () => {
    return (
        <InfoCard>
            <InfoCardHeader
                title={<Typography variant="subtitle2">Company Information </Typography>}
                action={<EditAction />}
            />
            <InfoCardDetails details={detailsDetails} />
        </InfoCard>
    )
}
export default CompanyInfoDetailsCard

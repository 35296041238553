import { ReactNode } from 'react'

import { SvgIconComponent } from '@mui/icons-material'
import { styled, SvgIcon, TypographyProps, useTheme } from '@mui/material'
import { DatumId, Pie, PieTooltipProps } from '@nivo/pie'
import { TooltipWrapper } from '@nivo/tooltip'

import { ChartCenterContent, ChartContainer, Columns, Typography } from 'components'
import { formatPercent, generateNotForwardedProps, resolveDoublePriceMask } from 'utils'

import { useWidgetTooltipContext } from '../../WidgetBase/WidgetBase'
import { TooltipBox } from '../../WidgetTooltip'
interface WidgetPieTooltipRowProps extends TypographyProps {
    widgetMaxWidth: number
}

const WidgetTooltipRowTypography = styled(Typography, {
    shouldForwardProp: generateNotForwardedProps<WidgetPieTooltipRowProps>(['widgetMaxWidth']),
})<WidgetPieTooltipRowProps>`
    ${(props) =>
        `
         max-width: ${props.widgetMaxWidth}px;
        `}
`

const WidgetPieTooltipRow = (props: TypographyProps) => {
    const widgetMaxWidth = useWidgetTooltipContext()
    if (!props.children?.toString().length) {
        return null
    }

    return (
        <WidgetTooltipRowTypography
            {...props}
            widgetMaxWidth={widgetMaxWidth}
            variant="tooltip"
            color="white"
        />
    )
}
interface WidgetPieTooltipProps<PieData extends defaultPieData>
    extends PieTooltipProps<PieData>,
        Pick<WidgetPieChartBaseProps<PieData>, 'noDataId' | 'renderContent'> {}

export const calculateWidgetValue = (total: number, value: number) =>
    formatPercent(total ? (value / total) * 100 : value)
const WidgetPieTooltip = <PieData extends defaultPieData>({
    datum,
    renderContent,
    noDataId,
}: WidgetPieTooltipProps<PieData>) => {
    const data = datum.data
    const { id, value, total } = data
    if (value <= 0 || !value) {
        return null
    }
    if (datum.id === noDataId) {
        return <></>
    }
    const currValue = calculateWidgetValue(total, value)
    return (
        <TooltipWrapper
            anchor="left"
            position={[0, 0]}
        >
            <TooltipBox>
                <Columns>
                    {renderContent ? (
                        renderContent(data).map((v, i) => (
                            <WidgetPieTooltipRow key={i}>{v}</WidgetPieTooltipRow>
                        ))
                    ) : (
                        <>
                            <WidgetPieTooltipRow>{`${id} ${currValue}`}</WidgetPieTooltipRow>
                            {total && (
                                <WidgetPieTooltipRow>
                                    {resolveDoublePriceMask(value)}
                                </WidgetPieTooltipRow>
                            )}
                        </>
                    )}
                </Columns>
            </TooltipBox>
        </TooltipWrapper>
    )
}

interface WidgetPieChartBaseProps<PieData extends defaultPieData> {
    data: PieData[]
    valid?: boolean
    icon?: SvgIconComponent
    noDataId?: string
    renderContent?: (params: PieData) => ReactNode[]
    sortByValue?: boolean
}

type defaultPieData = {
    id: DatumId
    color: string
    value: number
    total?: number
}

export type ExtendPieData<obj> = defaultPieData & obj

const WidgetPieChartBase = <PieData extends defaultPieData>({
    valid = true,
    icon,
    data,
    renderContent,
    noDataId = 'Empty',
    sortByValue = true,
}: WidgetPieChartBaseProps<PieData>) => {
    const { palette } = useTheme()

    return (
        <ChartContainer>
            <Pie<PieData>
                colors={{ datum: 'data.color' }}
                width={100}
                height={100}
                data={
                    valid
                        ? data
                        : ([{ id: noDataId, value: 1, color: palette.charts.disable }] as PieData[])
                }
                margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
                innerRadius={0.8}
                padAngle={0}
                cornerRadius={0}
                sortByValue={sortByValue}
                activeOuterRadiusOffset={valid ? 4.5 : null}
                borderWidth={0}
                tooltip={(props) => (
                    <WidgetPieTooltip
                        {...props}
                        renderContent={renderContent}
                        noDataId={noDataId}
                    />
                )}
                enableArcLinkLabels={false}
                enableArcLabels={false}
            />
            {icon ? (
                <ChartCenterContent
                    display="flex"
                    alignItems="center"
                >
                    <SvgIcon
                        color="secondary"
                        component={icon}
                        sx={{
                            width: '30px',
                            height: '30px',
                            opacity: '0.3',
                        }}
                    />
                </ChartCenterContent>
            ) : null}
        </ChartContainer>
    )
}
export default WidgetPieChartBase

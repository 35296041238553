import { UploadFileOutlined } from '@mui/icons-material'

import { IconBox, InfoBadge, NonDisplayedInput, ViewHeader } from 'components'

import { MAX_PHOTO_COUNT } from './utils'

const PhotoTabHeader = ({
    photosCount,
    upload,
}: {
    photosCount: number
    upload: (event: any) => Promise<void>
}) => {
    const maxReached = photosCount === MAX_PHOTO_COUNT
    return (
        <ViewHeader title="Photos">
            <ViewHeader.Content>
                <InfoBadge badgeContent={photosCount || 0} />
            </ViewHeader.Content>

            <ViewHeader.Content placement="rightMobile">
                <label>
                    <NonDisplayedInput
                        type="file"
                        disabled={maxReached}
                        onChange={upload}
                        accept="image/*"
                    />
                    <IconBox
                        title={
                            maxReached
                                ? 'Maximum image limit (10) reached'
                                : 'Add up to 10 images, max size 10 MB each'
                        }
                        disabled={maxReached}
                    >
                        <UploadFileOutlined />
                    </IconBox>
                </label>
            </ViewHeader.Content>
        </ViewHeader>
    )
}

export default PhotoTabHeader

import { createContext, FC, useContext, useState, ReactNode } from 'react'

import { Theme } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { inject } from 'mobx-react'

import { ResourceType, useResource } from 'components/resource'
import { AuthStore } from 'providers/authStore'

export enum ListViewMode {
    list,
    grid,
}

interface ListViewContextValue {
    showColumns: boolean
    toggleView: (view: ListViewMode) => void
    setShowColumns: (toggler: boolean | ((open: boolean) => boolean)) => void
    viewMode: ListViewMode
}

const ListViewContext = createContext<ListViewContextValue>({} as ListViewContextValue)

export const useListView = (): ListViewContextValue => useContext(ListViewContext)

interface ListViewProviderProps {
    preferencesResource?: ResourceType
    children: ReactNode
}

export const ListViewProvider: FC<ListViewProviderProps & { auth?: AuthStore }> = inject('auth')(
    ({ children, auth, preferencesResource }) => {
        const resource = useResource(preferencesResource)
        const isColumns = auth.preferences.resources[resource.name]?.showColumns
        const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
        const [showColumns, setShowColumns] = useState(Boolean(isColumns ?? true))
        const viewMode = showColumns && !isSmall ? ListViewMode.list : ListViewMode.grid

        const toggleView = (view: ListViewMode) => {
            const newView = ListViewMode.list === view

            if (newView !== showColumns) {
                auth.updatePreferences({
                    resources: {
                        [resource.name]: {
                            showColumns: newView,
                        },
                    },
                })
                setShowColumns(newView)
            }
        }

        return (
            <ListViewContext.Provider
                value={{
                    showColumns,
                    toggleView,
                    setShowColumns,
                    viewMode,
                }}
            >
                {children}
            </ListViewContext.Provider>
        )
    },
)

export default ListViewProvider

import { useRef } from 'react'

import { styled } from '@mui/material'
import { modalClasses } from '@mui/material/Modal'

import { CollapsibleContextProvider, useCollapsibleContext } from 'components/Collapsible'
import { CollapsibleContextProviderProps } from 'components/Collapsible/CollapsibleContextProvider'
import { useClickAway } from 'hooks'

import InfoCard, { InfoCardProps } from './InfoCard'

const ActiveInfoCard = styled(InfoCard, {
    shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive?: boolean }>(({ isActive, theme }) => ({
    boxShadow: isActive ? theme.shadows[10] : undefined,
}))

export interface CollapsibleInfoCardProps
    extends InfoCardProps,
        Pick<CollapsibleContextProviderProps, 'defaultOpen' | 'sameParent'> {
    disableActiveState?: boolean
}

const CollapsibleInfoCardContent = (props: CollapsibleInfoCardProps) => {
    const { isEmpty, close } = useCollapsibleContext()

    const isCollapsed = !isEmpty

    const ref = useRef<HTMLDivElement>()

    useClickAway(
        ref,
        () => {
            // if there are modals in the DOM, don't close
            if (document.querySelector(`body > .${modalClasses.root}`)) {
                return
            }
            close()
        },
        {
            enabled: isCollapsed,
        },
    )

    return (
        <ActiveInfoCard
            isActive={isCollapsed}
            ref={ref}
            {...props}
        />
    )
}

const CollapsibleInfoCard = ({
    disableActiveState,
    defaultOpen,
    sameParent,
    ...props
}: CollapsibleInfoCardProps) => {
    return (
        <CollapsibleContextProvider
            sameParent={sameParent}
            defaultOpen={defaultOpen}
        >
            {disableActiveState ? (
                <InfoCard {...props} />
            ) : (
                <CollapsibleInfoCardContent {...props} />
            )}
        </CollapsibleContextProvider>
    )
}

export default CollapsibleInfoCard

import { Theme, useMediaQuery } from '@mui/material'
import { RaRecord, useRecordContext } from 'react-admin'

import { ActionsMenu, MenuActionProps } from 'components/actions'
import { useResource } from 'components/resource'
import { Spacer } from 'components/styled'

import HeaderDesktopAction from './HeaderDesktopAction'

interface HeaderActionsProps<RecordType extends RaRecord = any> {
    actions: MenuActionProps<RecordType>['actions']
}

const HeaderActions = <RecordType extends RaRecord = any>({
    actions,
}: HeaderActionsProps<RecordType>) => {
    const match = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'))
    const record = useRecordContext<RecordType>()
    const resource = useResource()

    return (
        <div>
            {match ? (
                <ActionsMenu<RecordType>
                    actions={actions}
                    record={record}
                />
            ) : (
                <Spacer>
                    {actions(record, {
                        children: (params) => <HeaderDesktopAction {...params} />,
                        resource,
                    })}
                </Spacer>
            )}
        </div>
    )
}

export default HeaderActions

import { dateRangeObject } from 'utils'

export const periodSelectorDefaultDate = 'last-30-days'

export const periodSelectorFormValues = (): PeriodSelectorFormValues => {
    const [periodSelectorDefaultFrom, periodSelectorDefaultTo] =
        dateRangeObject[periodSelectorDefaultDate].values('string')

    return {
        date: periodSelectorDefaultDate,
        dateFrom: periodSelectorDefaultFrom,
        dateTo: periodSelectorDefaultTo,
    }
}

export interface PeriodSelectorFormValues {
    dateFrom: string
    dateTo: string
    date: string
}

export interface PeriodFilter {
    periodStart: string
    periodEnd: string
}

import { SyncOutlined } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { useListContext } from 'react-admin'

import { TelematicsUnitModel } from 'appTypes/models'
import { Button, HideOnBreakpoint } from 'components'

import { useSyncedStateContext } from '../utils/SyncedStateContext'

const SyncOnManyFromListAction = () => {
    const { selectedIds, data } = useListContext<TelematicsUnitModel>()
    const { handleSyncedChange, synced } = useSyncedStateContext()
    const onClick = () => {
        const newState = {}
        selectedIds.forEach((selectedId) => {
            const item = data.find((item) => item.id === selectedId)
            if (synced[item.telematicsId] === false || !item.sync) {
                newState[item.telematicsId] = true
            }
        })
        handleSyncedChange(newState)
    }
    return (
        <HideOnBreakpoint
            breakpoint="sm"
            replaceWith={
                <IconButton
                    size="small"
                    sx={{
                        color: (theme) => theme.palette.text.primary,
                    }}
                    onClick={onClick}
                >
                    <SyncOutlined
                        sx={{
                            width: '20px',
                            height: '20px',
                        }}
                    />
                </IconButton>
            }
        >
            <Button
                size="small"
                startIcon={<SyncOutlined />}
                onClick={onClick}
            >
                Sync ON
            </Button>
        </HideOnBreakpoint>
    )
}

export default SyncOnManyFromListAction

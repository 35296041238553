import { LegacyRef, useEffect, useState } from 'react'

import { Item } from 'react-photoswipe-gallery'

import { StyledElement } from 'components'
type DimensionsType = {
    width: number
    height: number
}
const PhotoButtonImage = ({ file }: { file: string }) => {
    const [dimensions, setDimensions] = useState<DimensionsType>({
        width: 0,
        height: 0,
    })
    useEffect(() => {
        let image = new Image()
        image.onload = () => {
            setDimensions({
                width: image?.naturalWidth,
                height: image?.naturalHeight,
            })
        }
        image.src = file
        return () => {
            image = null
        }
    }, [file])
    return (
        <Item
            id={file}
            key={file}
            original={file}
            width={dimensions.width}
            height={dimensions.height}
        >
            {({ ref }) => <StyledElement ref={ref as LegacyRef<HTMLDivElement>} />}
        </Item>
    )
}

export default PhotoButtonImage

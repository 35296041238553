import { FC } from 'react'

import { Identifier } from 'react-admin'
import { Link, LinkProps } from 'react-router-dom'

import DataAvatar, { DataAvatarProps } from 'components/DataAvatar'
import { UseCreateResourcePathParams, useCreateResourcePath } from 'hooks'

interface ListAvatarProps extends Omit<DataAvatarProps, 'id'> {
    id: Identifier
    linkProps?: Omit<LinkProps, 'to'>
    customPath?: UseCreateResourcePathParams
}
const ListAvatar: FC<ListAvatarProps> = ({ id, linkProps, customPath, ...props }) => {
    const createPath = useCreateResourcePath()

    const to: UseCreateResourcePathParams = {
        id,
        type: 'edit',
    }

    return (
        <Link
            to={createPath(customPath || to)}
            aria-label="View Record"
            {...linkProps}
        >
            <DataAvatar {...props} />
        </Link>
    )
}
export default ListAvatar

import { ReactNode } from 'react'

import { Box, styled } from '@mui/material'
import clsx from 'clsx'

import headerClasses from './classes'

interface HeaderMainProps {
    children: ReactNode
    className?: string
}

const HeaderMain = styled(({ children, className }: HeaderMainProps) => {
    return <Box className={clsx(className, headerClasses.main)}>{children}</Box>
})<HeaderMainProps>`
    flex-grow: 1;
    grid-area: main;
    overflow: hidden;

    ${({ theme }) => `
        ${theme.breakpoints.down('sm')} {
            .${headerClasses.mobileCenter} & {
                text-align: center;
                justify-content: center;
            }
        }
    `}
`

export default HeaderMain

import set from 'lodash/set'
import unset from 'lodash/unset'
import { memoryStore, Store } from 'react-admin'

export const globalStore = {}

const storeConstructor = (): Store => {
    const defaultStore = memoryStore(globalStore)

    const setModifier = (key: string, cb: Function) => {
        set(globalStore, key, (args: any) =>
            cb(
                {
                    set: defaultStore.setItem,
                    get: defaultStore.getItem,
                },
                args,
            ),
        )
    }

    const addState = (key: string, value: any) => {
        set(globalStore, key, value)
    }

    const setIfDifferent = (key: string, value: any) => {
        if (defaultStore.getItem(key, undefined) !== value) {
            defaultStore.setItem(key, value)
        }
    }

    const publish = (key: string, value: any) => {
        defaultStore.setItem(key, value)
        unset(globalStore, key)
    }

    return {
        ...defaultStore,
        setModifier,
        addState,
        setIfDifferent,
        publish,
    }
}

const store = storeConstructor()

export default store

declare module 'ra-core' {
    interface Store {
        publish: (key: string, value: any) => void
        addState: (key: string, value: any) => void
        setIfDifferent: (key: string, value: any) => void
        setModifier: (key: string, cb: Function) => void
    }
}

import { styled, Alert, alertClasses } from '@mui/material'
import { inject, observer } from 'mobx-react'

import Button from 'components/mui/Button'
import Typography from 'components/mui/Typography'
import { renderOnBilling } from 'hocs'
import { AuthStore } from 'providers/authStore'

interface TopBarProps {
    className?: string
}

const BillingPaymentIssue = inject('auth')(
    observer(({ className, auth }: TopBarProps & { auth?: AuthStore }) => {
        const unpaidInvoices = auth.user.membership.billing.unpaidInvoices

        if (!unpaidInvoices?.length) {
            return null
        }

        return (
            <Alert
                severity="error"
                className={className}
                action={
                    <Button
                        component="a"
                        href={unpaidInvoices[0].url}
                        target="_blank"
                        rel="noopener noreferrer"
                        size="small"
                        color="inherit"
                        sx={{ width: 'max-content' }}
                    >
                        Pay Now
                    </Button>
                }
            >
                <Typography
                    color="inherit"
                    variant="body2"
                >
                    There is an issue with your payment details. Please update your payment
                    information.
                </Typography>
            </Alert>
        )
    }),
)

export default styled(renderOnBilling(BillingPaymentIssue))`
    min-height: 60px;
    display: flex;
    align-items: center;
    padding: 6px 22px;

    & .${alertClasses.action} {
        margin-right: 0;
    }
`

import { VendorModel } from 'appTypes/models'
import { InfoCardDetails, InfoCardHeader, InfoCard } from 'components'
import { vendorAddressInfo } from 'pages/Vendors/constants/config'

const AddressDetails = () => {
    return (
        <InfoCard>
            <InfoCardHeader title="Address" />
            <InfoCardDetails<VendorModel>
                details={[
                    {
                        label: 'Address Line 1',
                        source: 'address',
                    },
                    {
                        label: 'Address Line 2',
                        source: 'address2',
                    },
                    {
                        label: vendorAddressInfo.label,
                        source: vendorAddressInfo.value,
                    },
                ]}
            />
        </InfoCard>
    )
}

export default AddressDetails

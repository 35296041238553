import { styled, Box, formControlLabelClasses } from '@mui/material'

import PageBlock from 'components/pages/PageBlock'

export const PageBlockElement = styled(PageBlock)`
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 85px;
    box-sizing: border-box;
    ${({ theme }) => theme.breakpoints.up('sm')} {
        padding-top: 20px;
        padding-bottom: 23px;
    }
`

export const LeftSide = styled(Box)`
    flex-grow: 1;
    display: flex;
    gap: 31px;
    & .${formControlLabelClasses.root} {
        margin-left: -4px;
    }
    ${({ theme }) => `
        ${theme.breakpoints.down('sm')} {
            justify-content: space-between;
            & .${formControlLabelClasses.root}{
                margin-right: 0px;
            }
        `}
`

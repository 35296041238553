import { useRecordContext } from 'react-admin'

import { WorkOrderModel } from 'appTypes/models'
import { IconBox } from 'components'
import Icons from 'components/icons'

import WorkOrderEditDrawerToggler from './WorkOrderEditDrawer'

const WorkOrderEditButton = () => {
    const record = useRecordContext<WorkOrderModel>()

    if (record?.status !== 'OPEN') {
        return null
    }

    return (
        <WorkOrderEditDrawerToggler>
            {({ onClick }) => (
                <IconBox
                    onClick={onClick}
                    title="Edit"
                >
                    <Icons.Edit />
                </IconBox>
            )}
        </WorkOrderEditDrawerToggler>
    )
}

export default WorkOrderEditButton

import { Box, SvgIcon, Stack, darken, alpha } from '@mui/material'
import { inject, observer } from 'mobx-react'

import { ReactComponent as LogoWhite } from 'assets/images/logo-white.svg'
import { StatusText, Typography, Button } from 'components'
import Icons from 'components/icons'
import { usePaymentMethodForm } from 'pages/Company/CompanyInfo/hooks'
import { AuthStore } from 'providers/authStore'

const features: string[] = [
    'Unlimited Work Orders',
    '24/7 Customer Support',
    'NFC Keychain',
    'Everything in Early Access',
]

const ProPlan = inject('auth')(
    observer(({ auth }: { auth?: AuthStore }) => {
        const billing = auth.billing
        const subscription = billing.subscription
        const openPaymentMethod = usePaymentMethodForm()

        return (
            <Box
                bgcolor="primary.main"
                color="white"
                p="20px"
                borderRadius="4px"
                boxShadow={1}
            >
                <StatusText statusColor={(theme) => theme.palette.white}>RECOMMENDED</StatusText>
                <Box
                    display="flex"
                    alignItems="center"
                    mb="20px"
                    mt="19px"
                >
                    <SvgIcon
                        component={LogoWhite}
                        sx={{ width: '32px', height: '32px' }}
                        inheritViewBox
                    />
                    <Typography
                        color="inherit"
                        ml="12px"
                        variant="subtitle1"
                    >
                        Fleetpal Pro
                    </Typography>
                </Box>

                <Typography
                    variant="h4"
                    color="inherit"
                >
                    ${billing.workOrderPrice}
                </Typography>
                <Typography
                    variant="body2"
                    fontWeight={700}
                    mb="10px"
                    color="inherit"
                >
                    Per Work Order
                </Typography>
                <Typography
                    variant="body2"
                    mb="30px"
                    color="inherit"
                >
                    Only Pay for What You Need
                </Typography>
                {subscription === 'free' ? (
                    <Button
                        fullWidth
                        size="large"
                        variant="outlined"
                        sx={{
                            bgcolor: 'white',
                            color: 'text.primary',
                            '&:hover': {
                                backgroundColor: darken('#ffffff', 0.1),
                            },
                        }}
                        onClick={() => {
                            openPaymentMethod('upgrade')
                        }}
                    >
                        Upgrade
                    </Button>
                ) : (
                    <Button
                        fullWidth
                        disabled
                        variant="contained"
                        size="large"
                        sx={{
                            backgroundColor: (theme) =>
                                alpha(theme.palette.action.disabled, 0.12) + ' !important',
                            color: (theme) =>
                                alpha(theme.palette.text.primary, 0.26) + ' !important',
                        }}
                    >
                        Current plan
                    </Button>
                )}
                <Stack
                    gap="12px"
                    mt="20px"
                >
                    {features.map((text, i) => (
                        <Typography
                            key={i}
                            variant="body2"
                            color="inherit"
                            display="flex"
                            alignItems="center"
                        >
                            <Icons.Check
                                sx={{
                                    width: '16px',
                                    height: '16pxx',
                                    mr: '6px',
                                }}
                                color="inherit"
                            />
                            {text}
                        </Typography>
                    ))}
                </Stack>
            </Box>
        )
    }),
)

export default ProPlan

import { inject, observer } from 'mobx-react'
import { Route, Routes as RouterRoutes } from 'react-router-dom'

import { urls } from 'configs/config'
import {
    Login,
    AcceptInvitation,
    Register,
    RegisterConfirm,
    ResetPassword,
    ResetPasswordDone,
    ResetPasswordConfirm,
    NotFound,
    Parts,
    Reports,
    Units,
    WorkOrders,
    Profile,
    Company,
    Configure,
    Deactivate,
    ChangePlan,
    PaymentHistory,
    Vendors,
    RegisterConfirmPage,
    Dashboard,
} from 'pages'
import { Deactivated } from 'pages/Auth/Login'
import { LogoutInvatation } from 'pages/Company/AcceptInvitation'
import { MainLayout } from 'pages/layouts'
import { AuthStore } from 'providers/authStore'

import { RemoveTrailingSlash } from './RemoveTrailingSlash'
import ScrollToTopOnRouteChange from './ScrollToTopOnRouteChange'
import { AnonymousRequired, LoginRequired } from './guards'

const Routes = inject('auth')(
    observer(({ auth }: { auth?: AuthStore }) => {
        const billing = auth.billing

        return (
            <>
                <RemoveTrailingSlash />
                <RouterRoutes>
                    <Route
                        path={urls.root}
                        element={
                            <LoginRequired>
                                <MainLayout>
                                    <Dashboard />
                                </MainLayout>
                            </LoginRequired>
                        }
                    />

                    <Route
                        path={urls.workOrders + '/*'}
                        element={
                            <LoginRequired>
                                <MainLayout>
                                    <WorkOrders />
                                </MainLayout>
                            </LoginRequired>
                        }
                    />
                    <Route
                        path={urls.unitsArchived + '/*'}
                        element={
                            <LoginRequired>
                                <MainLayout>
                                    <Units isArchived />
                                </MainLayout>
                            </LoginRequired>
                        }
                    />
                    <Route
                        path={urls.units + '/*'}
                        element={
                            <LoginRequired>
                                <MainLayout>
                                    <Units />
                                </MainLayout>
                            </LoginRequired>
                        }
                    />
                    <Route
                        path={urls.parts + '/*'}
                        element={
                            <LoginRequired>
                                <MainLayout>
                                    <Parts />
                                </MainLayout>
                            </LoginRequired>
                        }
                    />
                    <Route
                        path={urls.reports + '/*'}
                        element={
                            <LoginRequired>
                                <MainLayout>
                                    <Reports />
                                </MainLayout>
                            </LoginRequired>
                        }
                    />
                    <Route
                        path={urls.configure + '/*'}
                        element={
                            <LoginRequired>
                                <MainLayout>
                                    <Configure />
                                </MainLayout>
                            </LoginRequired>
                        }
                    />

                    <Route
                        path={urls.userProfile + '/*'}
                        element={
                            <LoginRequired>
                                <MainLayout>
                                    <Profile />
                                </MainLayout>
                            </LoginRequired>
                        }
                    />
                    <Route
                        path={urls.company + '/*'}
                        element={
                            <LoginRequired>
                                <MainLayout>
                                    <Company />
                                </MainLayout>
                            </LoginRequired>
                        }
                    />

                    {billing && (
                        <Route
                            path={urls.changePlan}
                            element={
                                <LoginRequired>
                                    <MainLayout>
                                        <ChangePlan />
                                    </MainLayout>
                                </LoginRequired>
                            }
                        />
                    )}

                    {billing && (
                        <Route
                            path={urls.paymentHistory}
                            element={
                                <LoginRequired
                                    redirect={() =>
                                        billing.subscription === 'pro' ? false : urls.company
                                    }
                                >
                                    <MainLayout>
                                        <PaymentHistory />
                                    </MainLayout>
                                </LoginRequired>
                            }
                        />
                    )}

                    <Route
                        path={urls.login}
                        element={
                            <AnonymousRequired>
                                <Login />
                            </AnonymousRequired>
                        }
                    />
                    <Route
                        path={urls.register}
                        element={
                            <AnonymousRequired>
                                <Register />
                            </AnonymousRequired>
                        }
                    />
                    <Route
                        path={urls.registerConfirm}
                        element={<RegisterConfirm />}
                    />
                    <Route
                        path={urls.registerConfirmEmail}
                        element={<RegisterConfirmPage />}
                    />
                    <Route
                        path={urls.passwordReset}
                        element={
                            <AnonymousRequired>
                                <ResetPassword />
                            </AnonymousRequired>
                        }
                    />
                    <Route
                        path={urls.passwordResetDone}
                        element={
                            <AnonymousRequired>
                                <ResetPasswordDone />
                            </AnonymousRequired>
                        }
                    />
                    <Route
                        path={urls.passwordResetConfirm}
                        element={
                            <AnonymousRequired>
                                <ResetPasswordConfirm />
                            </AnonymousRequired>
                        }
                    />
                    <Route
                        path={urls.invitationAuth}
                        element={<LogoutInvatation />}
                    />
                    <Route
                        path={urls.invitationsAccept}
                        element={
                            <AnonymousRequired>
                                <AcceptInvitation />
                            </AnonymousRequired>
                        }
                    />
                    <Route
                        path={urls.deactivate}
                        element={
                            <AnonymousRequired>
                                <Deactivate />
                            </AnonymousRequired>
                        }
                    />
                    <Route
                        path={urls.deactivated}
                        element={
                            <AnonymousRequired>
                                <Deactivated />
                            </AnonymousRequired>
                        }
                    />
                    <Route
                        path={urls.vendors + '/*'}
                        element={
                            <LoginRequired>
                                <MainLayout>
                                    <Vendors />
                                </MainLayout>
                            </LoginRequired>
                        }
                    />

                    {/* <Route
                        path="test"
                        element={(
                            <LoginRequired><MainLayout><TestPage /></MainLayout></LoginRequired>
                        )}
                    /> */}

                    <Route
                        path="*"
                        element={<NotFound />}
                    />
                </RouterRoutes>
                <ScrollToTopOnRouteChange />
            </>
        )
    }),
)

export default Routes

import { Stack } from '@mui/material'
import { inject, observer } from 'mobx-react'
import { RecordContextProvider } from 'react-admin'

import { GridContainer, PageContent, GridItem, SectionTitle } from 'components'
import { AuthStore } from 'providers/authStore'

import CompanyInfoDetailsCard from './CompanyInfoDetailsCard'
import CompanyPaymentHistory from './CompanyPaymentHistory'
import CurrentBill from './CurrentBill/CurrentBill'
import CurrentPlan from './CurrentPlan/CurrentPlan'

const CompanyInfo = inject('auth')(
    observer(({ auth }: { auth?: AuthStore }) => {
        const company = auth.currentCompany

        return (
            <RecordContextProvider value={company}>
                <PageContent>
                    <SectionTitle>Basic Information</SectionTitle>
                    <GridContainer>
                        <GridItem md={4}>
                            <Stack spacing="30px">
                                {auth.billing && (
                                    <>
                                        <CurrentPlan />
                                        {auth.billing.subscription === 'pro' && (
                                            <>
                                                <CurrentBill />
                                                <CompanyPaymentHistory />
                                            </>
                                        )}
                                    </>
                                )}
                                <CompanyInfoDetailsCard />
                            </Stack>
                        </GridItem>
                    </GridContainer>
                </PageContent>
            </RecordContextProvider>
        )
    }),
)

export default CompanyInfo

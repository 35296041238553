import { FileDownloadOutlined } from '@mui/icons-material'

import { ActionChildren } from './Actions'

interface ExportActionProps {
    children: ActionChildren
}

export const ExportAction = ({ children }: ExportActionProps) => {
    return children({
        disabled: true,
        Icon: FileDownloadOutlined,
        title: 'Export (Coming Soon)',
        onClick: () => {
            //
        },
    })
}

export const exportAction = (params: ExportActionProps) => (
    <ExportAction
        {...params}
        key="export"
    />
)

import { Identifier, useListContext, useShowContext } from 'react-admin'

import { UnitModel } from 'appTypes/models'
import { useArchivedContext, useOpenUtilityDrawer, useResource } from 'components'
import UtilityDrawerListEditor from 'components/Drawer/UtilityDrawerListEditor'

import { PMLastMetersContextProvider } from '../PMLastMeters'
import { deleteUnitPMIntervalConfig, unitPMIntervalSerializator } from '../constants'

import UnitPMForm from './UnitPMForm'

interface UnitPMDrawerTogglerParams {
    id?: Identifier
    type: 'dependent' | 'independent'
}

export interface UnitPMDrawerEditorExtraState {
    unit: UnitModel
    isArchived: boolean
}

const useUnitPMDrawerEditor = () => {
    const open = useOpenUtilityDrawer()
    const listContext = useListContext()
    const resource = useResource()
    const { record } = useShowContext()
    const isArchived = useArchivedContext()
    return ({ id, type }: UnitPMDrawerTogglerParams) => {
        open({
            drawerArgs: {
                title: id
                    ? isArchived
                        ? 'View Interval'
                        : 'Edit Interval'
                    : `Create Interval (${type === 'independent' ? 'Independent' : 'Dependent'})`,
                renderWrapper: (props) => (
                    <PMLastMetersContextProvider>
                        <UtilityDrawerListEditor
                            serializer={unitPMIntervalSerializator}
                            {...props}
                        />
                    </PMLastMetersContextProvider>
                ),
                renderContent: () => <UnitPMForm />,
                renderBottomRight: isArchived ? () => null : (renderButton) => renderButton(),
                renderBottomLeft: isArchived
                    ? (render) => render({ children: 'Close' })
                    : undefined,

                renderTopRight: (renderButton) =>
                    id
                        ? renderButton({ disabled: isArchived, ...deleteUnitPMIntervalConfig })
                        : null,
            },
            extraArgs: {
                resource: { ...resource, resource: resource.resource + (id ? '' : '/' + type) },
                id,
                listContext,
            },
            extra: {
                unit: record,
                isArchived,
            } as UnitPMDrawerEditorExtraState,
        })
    }
}

export default useUnitPMDrawerEditor

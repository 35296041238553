import { useQuery as useReactQuery, UseQueryOptions } from 'react-query'

import useErrorHandler from './useErrorHandler'

const useQuery = <ResponseType = any>(
    key: any[] | string,
    query: (p: any) => Promise<ResponseType>,
    options?: Omit<UseQueryOptions<ResponseType>, 'meta'> & {
        meta?: UseQueryMeta
    },
) => {
    const onError = useErrorHandler()

    return useReactQuery<ResponseType>(key, query, {
        onError,
        ...options,
    })
}

export default useQuery

export type UseQueryMeta = {
    refetchState?: boolean
}

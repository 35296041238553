import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined'
import { Theme } from '@mui/material'
import { useResourceContext } from 'react-admin'

import { SortType } from 'appTypes'
import {
    StyledElement,
    List,
    ListBase,
    PageContent,
    DatagridColumnsProps,
    HideArchived,
    useArchivedContext,
    Status,
    ListAvatar,
    dropdownSelectorInDatagridStyle,
    Spacer,
    NotesCollapsibleContent,
    NotesCollapsibleIconHolder,
    CardCollapsibleContent,
} from 'components'
import { DatagridLink } from 'components/Datagrid'
import { DropdownIconText } from 'components/IconText'
import {
    deleteManyFromListAction,
    deleteOneFromListAction,
    archiveOneFromListAction,
    archiveManyFromListAction,
    multiselectAction,
    editRedirectInListAction,
} from 'components/actions'
import { ListBulkActions } from 'components/list/ListSelection'
import { ListSortContentProps } from 'components/list/ListSortDrawerContent'
import ListFilterDateRangeValueInput from 'components/list/filter/ListFilterDateRangeValueInput'
import { FilterConfig } from 'components/list/filter/ListFilterForm'
import ListFilterRangeInput from 'components/list/filter/ListFilterRangeInput'
import ListFilterValueInput from 'components/list/filter/ListFilterValueInput'
import { useFlags } from 'hooks'
import {
    TagsCollapsibleContent,
    TagsCollapsibleIconHolder,
    TagsField,
} from 'pages/Configure/TagsManagement'
import { formatDate } from 'utils'
import { resolveIntegerSpacedMask, resolveDoublePriceMask, costMaskParams } from 'utils/masks'

import {
    TitleWithTelematicsStatus,
    UnitStatusSelector,
    UnitIntegrationsStatusPopper,
    UnitDeactivatedBadge,
} from '../components'
import {
    archiveUnitActionParams,
    deleteUnitAction,
    unitResource,
    unitStatuses,
} from '../config/constants'

import { UnitsListHeader } from './components'

import type { GridCellParams } from '@mui/x-data-grid'
import type { UnitModel, MeterModel } from 'appTypes/models'
import type { CardListConfig } from 'components'

const defaultUnitSort: SortType<UnitModel> = {
    field: 'created',
    order: 'DESC',
}

const defaultArchivedUnitSort: SortType<UnitModel> = {
    field: 'archived',
    order: 'DESC',
}

const DefaultIcon = () => {
    const isArchived = useArchivedContext()

    return <LocalShippingOutlinedIcon sx={isArchived ? { opacity: 0.54 } : undefined} />
}

const dataAvatarColor = (isArchived: boolean) =>
    isArchived ? (theme: Theme) => theme.palette.text.primary : undefined

export const unitFiltersCfg: FilterConfig<UnitModel> = {
    filters: [
        {
            id: 'created',
            label: 'Created on',
            filterType: 'range',
            renderComponent: (props) => <ListFilterDateRangeValueInput {...props} />,
        },
        { id: 'number', label: 'Unit Number' },
        { id: 'name', label: 'Name' },
        { id: 'vin', label: 'VIN' },
        { id: 'licensePlate', label: 'License Plate' },
        {
            id: 'status',
            label: 'Status',
            renderComponent: (props) => (
                <ListFilterValueInput
                    {...props}
                    inputText={(option) => option.id}
                    makeItemLabel={(record) => {
                        const status = unitStatuses[record.id as keyof typeof unitStatuses]
                        if (status) {
                            return (
                                <DropdownIconText>
                                    <Status
                                        size="8px"
                                        iconColor={status.iconColor}
                                    />
                                    {status.text}
                                </DropdownIconText>
                            )
                        }

                        return record.id
                    }}
                />
            ),
        },
        { id: 'unresolvedDefects', label: 'Unresolved Defects' },
        {
            id: 'pmIntervalsCount',
            label: 'PM Intervals',
        },
        { id: 'vmrsEquipmentCategory', label: 'Equipment Category' },
        { id: 'vmrsManufacturer', label: 'Manufacturer/Make' },
        { id: 'model', label: 'Model' },
        { id: 'modelYear', label: 'Model Year' },
        { id: 'engineVmrsManufacturer', label: 'Engine Make' },
        { id: 'engineModel', label: 'Engine Model' },
        { id: 'engineHp', label: 'Engine HP' },
        { id: 'transmissionVmrsManufacturer', label: 'Transmission Make' },
        { id: 'transmissionModel', label: 'Transmission Model' },
        { id: 'transmissionGears', label: 'Transmission Gears' },
        { id: 'color', label: 'Color' },
        { id: 'tireSize', label: 'Tire Size' },
        {
            id: 'total',
            label: 'Maintenance Cost',
            filterType: 'range' as const,
            renderComponent: (props) => (
                <ListFilterRangeInput
                    inputProps={costMaskParams}
                    {...props}
                />
            ),
        },
        {
            id: 'costPerMile',
            label: 'Cost per Mile',
            filterType: 'range' as const,
            renderComponent: (props) => (
                <ListFilterRangeInput
                    inputProps={costMaskParams}
                    {...props}
                />
            ),
        },
        {
            id: 'costPerHour',
            label: 'Cost per Engine Hour',
            filterType: 'range' as const,
            renderComponent: (props) => (
                <ListFilterRangeInput
                    inputProps={costMaskParams}
                    {...props}
                />
            ),
        },
        { id: 'tags', label: 'Tags' },
    ],
}

const FilterArchivedCfg: FilterConfig<UnitModel> = {
    ...unitFiltersCfg,
    filters: [
        {
            id: 'archived',
            label: 'Archived on',
        },
        ...unitFiltersCfg.filters.filter((sort) => sort.id !== 'unresolvedDefects'),
    ],
}

export const unitSortCfg: ListSortContentProps<UnitModel> = {
    sortBy: [
        { id: 'created', label: 'Created on' },
        { id: 'number', label: 'Unit Number' },
        { id: 'name', label: 'Name' },
        { id: 'licensePlate', label: 'License Plate' },
        { id: 'vin', label: 'VIN' },
        { id: 'vmrsEquipmentCategory', label: 'Equipment Category' },
        { id: 'model', label: 'Model' },
        { id: 'modelYear', label: 'Model Year' },
        { id: 'color', label: 'Color' },
        { id: 'status', label: 'Status' },
        { id: 'unresolvedDefects', label: 'Unresolved Defects' },
        { id: 'pmIntervalsCount', label: 'PM Intervals' },
        { id: 'total', label: 'Maintenance Cost' },
        { id: 'costPerMile', label: 'Cost per Mile' },
        { id: 'costPerHour', label: 'Cost per Engine Hour' },
    ],
}

const sortArchivedCfg: ListSortContentProps<UnitModel> = {
    ...unitSortCfg,
    sortBy: [
        {
            id: 'archived',
            label: 'Archived on',
        },
        ...unitSortCfg.sortBy.filter((sort) => sort.id !== 'unresolvedDefects'),
    ],
}

const getLastMetersByType = (params: GridCellParams, type: MeterModel['type']) => {
    const meter = params.row.lastMeters?.find((o) => o.type === type)
    return meter
}

const UnitsList = () => {
    const isArchived = useArchivedContext()
    const resource = useResourceContext()

    const bulkActions: ListBulkActions<UnitModel> = ({ children, listContext }) => {
        return [
            <HideArchived
                key="archived-actions"
                replaceWith={deleteManyFromListAction({
                    children,
                    ...deleteUnitAction(
                        listContext.selectedIds.some(
                            (selectedId) =>
                                listContext.data.find(({ id }) => selectedId === id)
                                    .hasRelatedWorkOrders,
                        ),
                    ),
                })}
            />,
            archiveManyFromListAction({
                ...archiveUnitActionParams(
                    isArchived,
                    Boolean(
                        listContext.data.find(
                            (unit) =>
                                unit.telematicsData && listContext.selectedIds.includes(unit.id),
                        ),
                    ),
                    listContext.selectedIds.some(
                        (selectedId) =>
                            listContext.data.find(({ id }) => selectedId === id).hasOpenWorkOrders,
                    ),
                ),
                children,
            }),
        ]
    }

    const columnsCfg: DatagridColumnsProps<
        UnitModel & {
            lastOdometerDate: string
            lastEngineHoursDate: string
            lastHubometerDate: string
        }
    > = {
        resetColumns: {
            vmrsEquipmentCategory: false,
            color: false,
            costPerMile: false,
            costPerHour: false,
            pmIntervalsCount: false,
            vmrsManufacturer: false,
            tireSize: false,
            engineVmrsManufacturer: false,
            engineModel: false,
            engineHp: false,
            lastOdometerDate: false,
            lastEngineHoursDate: false,
            lastEngineHours: false,
            lastHubometerDate: false,
            lastHubometer: false,
            transmissionVmrsManufacturer: false,
            transmissionModel: false,
            transmissionGears: false,
            unresolvedDefects: false,
            tagsData: false,
        },
        columns: [
            {
                field: 'photo',
                headerName: 'Avatar',
                maxWidth: 72,
                renderCell: (cell) => (
                    <ListAvatar
                        linkProps={{
                            'aria-label': `View Unit with unit number ${cell.row.number}`,
                        }}
                        id={cell.id}
                        imageSrc={cell.value}
                        color={dataAvatarColor(isArchived)}
                        defaultImage={<DefaultIcon />}
                    />
                ),
            },
            {
                field: 'number',
                headerName: 'Unit Number',
                flex: 1,
                renderCell: (params) => {
                    const { value, ...restParams } = params
                    return (
                        <Spacer overflow="hidden">
                            <DatagridLink
                                {...restParams}
                                value={
                                    <StyledElement
                                        sx={{
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                        }}
                                    >
                                        {value}
                                    </StyledElement>
                                }
                            />
                            {(params.row.telematicsData?.telematicsStatus === 'CONNECTED' ||
                                params.row.telematicsData?.error) && (
                                <UnitIntegrationsStatusPopper
                                    telematicData={params.row.telematicsData}
                                />
                            )}
                            {params.row.telematicsData?.status === 'DEACTIVATED' && (
                                <UnitDeactivatedBadge
                                    provider={params.row.telematicsData.provider}
                                />
                            )}
                        </Spacer>
                    )
                },
            },
            { field: 'name', headerName: 'Name', flex: 1 },
        ],
        actions: ({ row: unit }, { children }) => [
            editRedirectInListAction({
                children,
                id: unit.id,
            }),
            archiveOneFromListAction({
                ...archiveUnitActionParams(
                    isArchived,
                    Boolean(unit.telematicsData),
                    unit.hasOpenWorkOrders,
                ),

                children,
                id: unit.id,
            }),
            <HideArchived
                key="delete"
                replaceWith={deleteOneFromListAction({
                    children,
                    id: unit.id,
                    ...deleteUnitAction(unit.hasRelatedWorkOrders),
                })}
            />,
        ],
    }

    if (isArchived) {
        columnsCfg.columns.push({
            field: 'archived',
            headerName: 'Archived On',
            renderCell: (cell) => {
                return formatDate(cell.value, (dateFormats) => dateFormats.shortenedDateTime)
            },
        })
    }

    columnsCfg.columns.push(
        { field: 'licensePlate', headerName: 'License Plate', flex: 1 },
        { field: 'vin', headerName: 'VIN', flex: 1 },
        {
            field: 'vmrsManufacturer',
            headerName: 'Manufacturer/Make',
            renderCell: ({ row }) => row.vmrsManufacturerData?.description,
        },
        {
            field: 'vmrsEquipmentCategory',
            headerName: 'Equipment Category',
            renderCell: ({ row }) => row.vmrsEquipmentCategoryData?.description,
        },
        { field: 'model', headerName: 'Model', flex: 1 },
        { field: 'modelYear', headerName: 'Model Year', flex: 1 },
        { field: 'color', headerName: 'Color', flex: 1 },
        { field: 'tireSize', headerName: 'Tire Size', flex: 1 },
        {
            field: 'engineVmrsManufacturer',
            headerName: 'Engine Make',
            renderCell: ({ row }) => row.engineVmrsManufacturerData?.description,
        },
        { field: 'engineModel', headerName: 'Engine Model', flex: 1 },
        { field: 'engineHp', headerName: 'Engine HP', flex: 1 },
        {
            field: 'transmissionVmrsManufacturer',
            headerName: 'Transmission Make',
            renderCell: ({ row }) => row.transmissionVmrsManufacturerData?.description,
        },
        { field: 'transmissionModel', headerName: 'Transmission Model', flex: 1 },
        { field: 'transmissionGears', headerName: 'Transmission Gears', flex: 1 },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            minWidth: 80,

            renderCell: (cell, { listContext }) => {
                return (
                    <UnitStatusSelector
                        sx={dropdownSelectorInDatagridStyle}
                        record={cell.row}
                        onComplete={() => {
                            listContext.refetch()
                        }}
                    />
                )
            },
        },
        { field: 'unresolvedDefects', headerName: 'Unresolved Defects' },
        { field: 'pmIntervalsCount', headerName: 'PM Intervals' },
        {
            field: 'lastOdometer',
            headerName: 'Odometer (Last Known)',
            flex: 1,
            renderCell: (params) => {
                const meter = getLastMetersByType(params, 'ODOMETER')
                return meter ? resolveIntegerSpacedMask(meter.value) : null
            },
        },
        {
            field: 'lastOdometerDate',
            headerName: 'Odometer Date',
            flex: 1,
            renderCell: (params) => {
                const meter = getLastMetersByType(params, 'ODOMETER')
                return formatDate(meter?.timestamp, (dateFormats) => dateFormats.shortenedDateTime)
            },
        },
        {
            field: 'lastEngineHours',
            headerName: 'Engine Hours (Last Known)',
            flex: 1,
            renderCell: (params) => {
                const meter = getLastMetersByType(params, 'ENGINE_HOURS')
                return meter ? resolveIntegerSpacedMask(meter.value) : null
            },
        },
        {
            field: 'lastEngineHoursDate',
            headerName: 'Engine Hours Date',
            flex: 1,
            renderCell: (params) => {
                const meter = getLastMetersByType(params, 'ENGINE_HOURS')
                return formatDate(meter?.timestamp, (dateFormats) => dateFormats.shortenedDateTime)
            },
        },
        {
            field: 'lastHubometer',
            headerName: 'Hubometer (Last Known)',
            flex: 1,
            renderCell: (params) => {
                const meter = getLastMetersByType(params, 'HUBOMETER')
                return meter ? resolveIntegerSpacedMask(meter.value) : null
            },
        },
        {
            field: 'lastHubometerDate',
            headerName: 'Hubometer Date',
            flex: 1,
            renderCell: (params) => {
                const meter = getLastMetersByType(params, 'HUBOMETER')
                return formatDate(meter?.timestamp, (dateFormats) => dateFormats.shortenedDateTime)
            },
        },
        {
            field: 'total',
            headerName: 'Maintenance Cost',
            flex: 1,
            headerAlign: 'right',

            align: 'right',
            renderCell: ({ value }) =>
                value || value === 0 ? resolveDoublePriceMask(value) : null,
        },
        {
            field: 'costPerMile',
            headerName: 'Cost per Mile',
            flex: 1,
            align: 'right',
            headerAlign: 'right',

            renderCell: ({ value }) =>
                value || value === 0 ? resolveDoublePriceMask(value) : null,
        },
        {
            field: 'costPerHour',
            headerName: 'Cost per Engine Hour',
            flex: 1,
            align: 'right',
            headerAlign: 'right',

            renderCell: ({ value }) =>
                value || value === 0 ? resolveDoublePriceMask(value) : null,
        },
        {
            field: 'created',
            headerName: 'Created on',
            flex: 1,
            renderCell: (cell) => {
                return formatDate(cell.value, (dateFormats) => dateFormats.shortenedDateTime)
            },
        },
        {
            field: 'tagsData',
            headerName: 'Tags',
            renderCell: ({ value }) => <TagsField tags={value} />,
        },
    )

    const removedColumnsMap = [
        'vmrsManufacturer',
        'tireSize',
        'engineVmrsManufacturer',
        'engineModel',
        'transmissionVmrsManufacturer',
        'transmissionModel',
        'transmissionGears',
        'lastOdometerDate',
        'lastEngineHoursDate',
        'lastHubometer',
        'lastHubometerDate',
        'engineHp',
    ]
    const { flag621UnitListAllColumns } = useFlags()
    if (!flag621UnitListAllColumns) {
        columnsCfg.columns = columnsCfg.columns.filter((item) =>
            removedColumnsMap.includes(item.field) ? false : true,
        )
    }

    const cardsCfg: CardListConfig<UnitModel> = {
        titleSource: (record) => <TitleWithTelematicsStatus record={record} />,
        subTitleSource: (record, { listContext }) => (
            <UnitStatusSelector
                record={record}
                onComplete={() => {
                    listContext.refetch()
                }}
            />
        ),
        avatarColor: dataAvatarColor(isArchived),
        styles: {
            subheader: {
                ml: isArchived ? undefined : '-5px',
            },
        },
        avatarOpacity: isArchived ? 0.12 : undefined,
        imageSource: 'photo',
        defaultImage: <DefaultIcon />,
        collapsibleContent: (record) => (
            <CardCollapsibleContent
                content={[
                    {
                        iconHolder: (
                            <NotesCollapsibleIconHolder
                                notes={record.notes}
                                key="notesIcon"
                            />
                        ),
                        component: (
                            <NotesCollapsibleContent
                                notes={record.notes}
                                key="notesContent"
                            />
                        ),
                    },
                    {
                        iconHolder: (
                            <TagsCollapsibleIconHolder
                                tags={record.tagsData}
                                key="tagsIcon"
                            />
                        ),
                        component: (
                            <TagsCollapsibleContent
                                tags={record.tagsData}
                                key="tagsContent"
                            />
                        ),
                    },
                ]}
            />
        ),
        details: [
            { source: 'name', label: 'unit name' },
            { source: 'licensePlate', label: 'license plate' },
            { source: 'vin', label: 'vin' },
            { source: 'model', label: 'model' },
            { source: 'modelYear', label: 'model year' },
            { source: 'color', label: 'color' },
        ],
        actions: (
            { id, telematicsData, hasRelatedWorkOrders, hasOpenWorkOrders },
            { children },
        ) => [
            editRedirectInListAction({
                children,
                id,
            }),
            // exportAction({
            //     children,
            // }),
            multiselectAction({
                children,
                id,
            }),
            archiveOneFromListAction({
                ...archiveUnitActionParams(isArchived, Boolean(telematicsData), hasOpenWorkOrders),
                children,
                id,
            }),
            isArchived
                ? deleteOneFromListAction({
                      children,
                      id,
                      ...deleteUnitAction(hasRelatedWorkOrders),
                  })
                : null,
        ],
    }

    return (
        <ListBase
            sort={isArchived ? defaultArchivedUnitSort : defaultUnitSort}
            preferencesResource={unitResource}
            key={resource}
            filter={{ withAspects: ['cost_per_meter'] }}
        >
            <UnitsListHeader />
            <PageContent>
                <List
                    bulkActions={bulkActions}
                    preferencesResource={unitResource}
                    sortCfg={isArchived ? sortArchivedCfg : unitSortCfg}
                    columnsCfg={columnsCfg}
                    cardsCfg={cardsCfg}
                    filtersCfg={isArchived ? FilterArchivedCfg : unitFiltersCfg}
                    listFTUProps={{
                        linkText: isArchived ? '' : 'Add New Unit',
                        secondaryTitle: isArchived ? '' : 'Would you like to add one?',
                    }}
                />
            </PageContent>
        </ListBase>
    )
}

export default UnitsList

import { useCallback } from 'react'

import CheckBox, { CheckboxProps } from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup, { FormGroupProps } from '@mui/material/FormGroup'
import FormHelperText from '@mui/material/FormHelperText'
import clsx from 'clsx'
import { FieldTitle, useInput } from 'ra-core'
import { CommonInputProps, InputHelperText, sanitizeInputRestProps } from 'react-admin'

import { useCreateInputId } from 'hooks'

interface ExtraCheckboxInputProps extends CheckboxInputProps {
    disableLabelClick?: boolean
}

const CheckboxInput = (props: ExtraCheckboxInputProps) => {
    const {
        className,
        defaultValue = false,
        format,
        label,
        fullWidth,
        helperText,
        onBlur,
        onChange,
        onFocus,
        disabled,
        parse,
        resource,
        source,
        validate,
        disableLabelClick,
        ...rest
    } = props

    const createId = useCreateInputId()

    const {
        id,
        field,
        isRequired,
        fieldState: { error, invalid, isTouched },
        formState: { isSubmitted },
    } = useInput({
        defaultValue,
        format,
        parse,
        resource,
        source,
        onBlur,
        onChange,
        validate,
        type: 'checkbox',
        ...rest,
        id: createId({ source, id: rest.id }),
    })

    const handleChange = useCallback(
        (event) => {
            field.onChange(event)
            // Ensure field is considered as touched
            field.onBlur()
        },
        [field],
    )

    return (
        <FormGroup
            className={clsx('ra-input', `ra-input-${source}`, className)}
            sx={{ pointerEvents: disableLabelClick ? 'none' : 'auto' }}
            {...sanitizeInputRestProps(rest)}
        >
            <FormControlLabel
                control={
                    <CheckBox
                        id={id}
                        name={field.name}
                        color="primary"
                        onChange={handleChange}
                        onFocus={onFocus}
                        checked={field.value}
                        sx={{ pointerEvents: 'auto' }}
                        {...sanitizeInputRestProps(rest)}
                        disabled={disabled}
                    />
                }
                label={
                    <FieldTitle
                        label={label}
                        source={source}
                        resource={resource}
                        isRequired={isRequired}
                    />
                }
            />
            <FormHelperText error={(isTouched || isSubmitted) && invalid}>
                <InputHelperText
                    touched={isTouched || isSubmitted}
                    error={error?.message}
                    helperText={helperText}
                />
            </FormHelperText>
        </FormGroup>
    )
}

export type CheckboxInputProps = CommonInputProps &
    CheckboxProps &
    Omit<FormGroupProps, 'defaultValue' | 'onChange' | 'onBlur' | 'onFocus'>

export default CheckboxInput

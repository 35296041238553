import { ReactElement } from 'react'

import { Identifier, useListContext, useShowContext } from 'react-admin'

import { WoPaymentModel } from 'appTypes/models'
import { useOpenUtilityDrawer, useResource } from 'components'
import UtilityDrawerListEditor from 'components/Drawer/UtilityDrawerListEditor'
import { DeleteOneParams } from 'hooks'
import { WorkOrderItemsExtra } from 'pages/WorkOrders/Jobs/config/constants'
import { useWoActionIsDisabled } from 'pages/WorkOrders/hooks'
import { Serializer } from 'utils'

import PaymentForm from './components/PaymentForm'

export interface PaymentDrawerTogglerProps {
    id?: Identifier
    children: (params: { disabled: boolean; onClick: () => void }) => ReactElement
    paymentMethod?: string
}

const WorkOrderPaymentEditDrawerToggler = ({
    children,
    id,
    paymentMethod,
}: PaymentDrawerTogglerProps) => {
    const open = useOpenUtilityDrawer()
    const disabled = useWoActionIsDisabled()

    const resource = useResource()
    const { refetch } = useShowContext()

    const listContext = useListContext()

    return children({
        disabled,
        onClick: () => {
            open({
                extraArgs: {
                    id,
                    resource,
                    listContext,
                },
                drawerArgs: {
                    title: id ? (disabled ? 'View Payment' : 'Edit Payment') : 'Add Payment',
                    renderWrapper: (params) => (
                        <UtilityDrawerListEditor
                            {...params}
                            onSuccess={refetch}
                            serializer={PaymentSerializer}
                        />
                    ),
                    renderContent: () => <PaymentForm />,
                    renderBottomRight: disabled
                        ? () => null
                        : (render) => render({ label: id ? undefined : 'Add Payment' }),
                    renderTopRight:
                        id && !disabled
                            ? (render) => render({ ...deletePaymentAction(refetch) })
                            : null,
                },
                extra: {
                    paymentMethod,
                    disabledFields: disabled,
                } as PaymentFormExtra,
            })
        },
    })
}

export default WorkOrderPaymentEditDrawerToggler

const PaymentSerializer: Serializer<WoPaymentModel> = [
    'id',
    { name: 'amount', parse: 'number' },
    { name: 'date', parse: 'date' },
    {
        name: 'method',
        parse: 'emptyToNull',
    },
    'number',
]

export const deletePaymentAction = (onSuccess?: () => void): DeleteOneParams => ({
    onSuccess,
    confirmConfig: {
        title: 'Are you sure you want to delete the selected Payment?',
    },
})

interface PaymentFormExtra extends WorkOrderItemsExtra {
    paymentMethod: string
}

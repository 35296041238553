import { useRecordContext } from 'react-admin'

import { UnitModel } from 'appTypes/models'
import { PageContent, SliderView } from 'components'

import UnitMap from '../UnitMap'
import {
    UnitCostPerMileWidget,
    UnitCostWidget,
    SinceLastServiceTrackWidget,
    UnitProgress,
    UnitPMWidget,
    UnitDowntimeWidget,
    UnitDVIRWidget,
} from '../Widgets'

const TopSection = () => {
    const record = useRecordContext<UnitModel>()

    const showUnitMap =
        record?.location &&
        !record?.archived &&
        record?.telematicsData?.telematicsStatus === 'CONNECTED'

    const showDVIRwidget =
        record?.telematicsData?.telematicsStatus === 'CONNECTED' &&
        record?.telematicsData?.status === 'ACTIVE'

    const content = (
        <SliderView
            items={[
                showDVIRwidget && <UnitDVIRWidget />,
                record && !record?.archived && <UnitPMWidget />,
                <UnitCostPerMileWidget />,
                <UnitCostWidget />,
                <UnitDowntimeWidget />,
                record?.lastCompletedWorkOrder && <SinceLastServiceTrackWidget />,
                <UnitProgress />,
            ].filter(Boolean)}
        />
    )

    if (showUnitMap) {
        return (
            <>
                <UnitMap />
                <PageContent
                    py="0 !important"
                    mt="-72px"
                    // TODO: required to remove flex grow if multiple page contents in same page. It takes so much space on large screens
                    flexGrow="initial !important"
                >
                    {content}
                </PageContent>
            </>
        )
    }

    return (
        <PageContent
            bgcolor={(theme) => theme.palette.darkGray}
            flexGrow="initial !important"
        >
            {content}
        </PageContent>
    )
}

export default TopSection

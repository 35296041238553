import { Alert } from '@mui/material'

import { Section, SectionTitle } from 'components'

import DeactivateButton from './DeactivateButton'

const DeactivateCompanyAccountSection = () => {
    return (
        <Section>
            <SectionTitle>Deactivate Company Account</SectionTitle>
            <Alert
                variant="standard"
                severity="warning"
                sx={{ mb: '24px' }}
            >
                Deactivating your company account will disable temporarily all associated user
                accounts. All company data will remain intact. Reactivate at any time.
            </Alert>

            <DeactivateButton />
        </Section>
    )
}

export default DeactivateCompanyAccountSection

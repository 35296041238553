import { ReactNode, useRef } from 'react'

import { UploadFile } from '@mui/icons-material'
import { Button, Typography, Alert, Tooltip } from '@mui/material'
import {
    useCreatePath,
    useListContext,
    useRecordContext,
    useShowContext,
    useUpdate,
} from 'react-admin'
import { useFormContext, useWatch } from 'react-hook-form'

import { JobModel, WorkOrderModel } from 'appTypes/models'
import {
    ArrayControllerContextProvider,
    ArrayControllerElements,
    BoxContainer,
    Columns,
    Gallery,
    GalleryItemGrid,
    GalleryProps,
    InfoBadge,
    NonDisplayedInput,
    getRecordPhotos,
    useArrayControllerContext,
} from 'components'
import { useUtilityDrawerContext } from 'components/Drawer/UtilityDrawer'
import GalleryItem, { RawGalleryItemProps } from 'components/Gallery/GalleryItem'
import { useFinalErrorHandler, useNotify } from 'hooks'
import {
    indexes,
    photoName,
} from 'pages/WorkOrders/Invoice/components/InvoiceDrawer/components/Attachments'
import { woResource } from 'pages/WorkOrders/config/constants'
import { serialize } from 'utils'

const MAX_PHOTO_COUNT = 10

interface JobPhotosContentProps extends Pick<GalleryProps, 'onGalleryClose' | 'onGalleryStart'> {
    add: (index: number, file: string | File) => void
    remove: (index: number) => void
    count: number
    content: ReactNode
}
interface JobPhotoProps extends Omit<RawGalleryItemProps, 'src'> {
    index: number
}
const FormJobPhoto = (props: JobPhotoProps) => {
    const source = photoName(props.index)
    const file: string | File = useWatch({ name: source })
    if (!file) {
        return null
    }
    return (
        <GalleryItem
            file={file}
            {...props}
        />
    )
}

const JobPhotosContent = ({ remove, add, count, content, ...rest }: JobPhotosContentProps) => {
    let finalContent = <Alert severity="info">No Photos Added</Alert>
    const galleryOpener = useRef<{ open: (i: number) => void }>(null)

    const { extra }: { extra: { disabledFields: boolean } } = useUtilityDrawerContext()

    const { record: woRecord } = useShowContext<WorkOrderModel>()
    const woClosed = extra ? extra.disabledFields : woRecord ? woRecord.status === 'CLOSED' : false

    const notify = useNotify()
    const upload = async (event, index?: number) => {
        const file = event.target.files?.[0]
        if (!file) {
            return
        }

        const type = file.type
        if (!type.startsWith('image')) {
            notify('Invalid file format', {
                type: 'error',
            })
        } else if (file.size > 10000000) {
            notify('The maximum allowed file size is 10 MB', {
                type: 'error',
            })
        } else {
            add(index, file)
        }
        event.target.value = null
    }

    if (count) {
        finalContent = (
            <Gallery
                {...rest}
                getOpener={(opener) => {
                    galleryOpener.current = opener
                }}
                onImageDelete={(galleryCtx) => {
                    const item = galleryCtx.getItemData(galleryCtx.currIndex)
                    remove(Number(item.element.id))
                }}
            >
                {content}
            </Gallery>
        )
    }

    return (
        <Columns
            gap="14px"
            mt="10px"
        >
            <BoxContainer justifyContent="space-between">
                <Typography
                    color="text.secondary"
                    variant="chartTitle"
                    component={BoxContainer}
                    gap="8px"
                >
                    Photos <InfoBadge badgeContent={count} />
                </Typography>
                <BoxContainer gap="17px">
                    <Tooltip
                        title={
                            woClosed
                                ? 'Reopen the WO to add photo'
                                : count >= MAX_PHOTO_COUNT
                                ? 'Maximum image limit (10) reached'
                                : 'Add up to 10 images, max size 10 MB each'
                        }
                    >
                        <label>
                            <NonDisplayedInput
                                type="file"
                                disabled={count >= MAX_PHOTO_COUNT || woClosed}
                                onChange={upload}
                                accept="image/*"
                            />
                            <Button
                                variant="text"
                                color="primary"
                                size="small"
                                component="div"
                                disabled={count >= MAX_PHOTO_COUNT || woClosed}
                                startIcon={<UploadFile />}
                            >
                                UPLOAD FILE
                            </Button>
                        </label>
                    </Tooltip>
                </BoxContainer>
            </BoxContainer>
            {finalContent}
        </Columns>
    )
}

interface JobPhotosProps extends Pick<JobPhotosContentProps, 'onGalleryClose' | 'onGalleryStart'> {
    inForm?: boolean
}
const ExtendedJobPhotosContent = (props: Omit<JobPhotosProps, 'files'>) => {
    const form = useFormContext()
    const arrayController = useArrayControllerContext()

    const setFile = (index: number, file: File) => {
        form.setValue(photoName(index), file, {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true,
        })
    }
    const addFile = (index: number, file: File) => {
        let freeItem = index
        if (typeof index === 'undefined') {
            freeItem = arrayController.append()
        }
        setFile(freeItem, file)
    }
    const removeFile = (index: number) => {
        setFile(index, null)
        arrayController.remove({ item: index, index })
    }
    return (
        <JobPhotosContent
            {...props}
            count={arrayController.array.length}
            content={
                <GalleryItemGrid
                    gridHeight="78px"
                    gap="12px"
                >
                    <ArrayControllerElements onDelete={({ item }) => removeFile(item)}>
                        {({ item }) => {
                            return (
                                <FormJobPhoto
                                    id={String(item)}
                                    index={item}
                                    key={item}
                                    sx={{
                                        height: '78px',
                                    }}
                                />
                            )
                        }}
                    </ArrayControllerElements>
                </GalleryItemGrid>
            }
            add={addFile}
            remove={removeFile}
        />
    )
}
export const FormJobPhotos = (props: Omit<JobPhotosProps, 'files'>) => {
    const { getValues } = useFormContext()

    return (
        <ArrayControllerContextProvider
            initialArray={() => indexes.filter((index) => getValues(photoName(index)))}
        >
            <ExtendedJobPhotosContent {...props} />
        </ArrayControllerContextProvider>
    )
}

export const CardJobPhotos = ({ ...props }: JobPhotosProps) => {
    const record = useRecordContext<JobModel>()

    const { refetch } = useListContext()
    const recordPhotos = getRecordPhotos(record)
    const photos = Object.keys(recordPhotos.files).map((key, i) => ({
        id: i,
        file: recordPhotos.files[key],
    }))

    const [update] = useUpdate()
    const { record: woRecord } = useShowContext<WorkOrderModel>()

    const notify = useNotify()
    const createPath = useCreatePath()

    const errorHandler = useFinalErrorHandler()
    const generalErrorHandler = (e, id: string) => {
        if (e[id]) {
            notify(e[id].message, {
                type: 'error',
            })
        } else {
            errorHandler(e)
        }
    }
    const addPhoto = async (index: number, file: string | File) => {
        let freeItem = index
        console.log(freeItem)
        if (typeof index === 'undefined') {
            freeItem = Number(photos.find((data) => !data.file).id)
        }
        const id = photoName(freeItem)
        const data = serialize({ [id]: file }, [{ name: id, parse: 'file' }])
        try {
            await update(
                createPath({ resource: woResource.resource, id: woRecord.id, type: 'edit' }) +
                    `/jobs`,
                { data, id: record.id },
                { returnPromise: true },
            )
            refetch()
        } catch (e) {
            generalErrorHandler(e, id)
        }
    }
    const removePhoto = async (index: number) => {
        const id = photoName(index)
        try {
            await update(
                createPath({ resource: woResource.resource, id: woRecord.id, type: 'edit' }) +
                    '/jobs',
                { data: { [id]: null }, id: record.id },
                { returnPromise: true },
            )
            refetch()
        } catch (e) {
            generalErrorHandler(e, id)
        }
    }
    return (
        <JobPhotosContent
            count={recordPhotos.count}
            content={
                <GalleryItemGrid
                    gridHeight="89px"
                    height="89px"
                    gap="16px"
                >
                    {photos.map((file) => {
                        return (
                            <GalleryItem
                                id={String(file.id)}
                                file={file.file}
                                key={file.id}
                                sx={{
                                    height: 'inherit',
                                }}
                            />
                        )
                    })}
                </GalleryItemGrid>
            }
            remove={removePhoto}
            add={addPhoto}
            {...props}
        />
    )
}

import { ResourceContextProvider } from 'react-admin'

import { WorkOrderModel } from 'appTypes/models'
import { Create, PageContent } from 'components'

import { WorkOrderForm } from '../components'
import { woResource, workOrderSerializer } from '../config/constants'
import { useWoLimit } from '../hooks'

import { CreateWorkOrderHeader } from './components'
const WorkOrderCreate = () => {
    const { hasError, woLimitReachedAction } = useWoLimit()

    return (
        <ResourceContextProvider value={woResource.resource + '/internal'}>
            <Create<WorkOrderModel>
                serializer={workOrderSerializer}
                redirect="edit"
                redirectInfo={{
                    resource: woResource,
                }}
                formOnError={({ errors, defaultOnError, reset }) => {
                    if (hasError(errors)) {
                        reset(
                            {},
                            {
                                keepValues: true,
                                keepDirty: true,
                                keepTouched: true,
                            },
                        )
                        woLimitReachedAction()
                        return
                    }

                    defaultOnError()
                }}
            >
                <CreateWorkOrderHeader />
                <PageContent>
                    <WorkOrderForm />
                </PageContent>
            </Create>
        </ResourceContextProvider>
    )
}

export default WorkOrderCreate

import { FC } from 'react'

import { useWatch } from 'react-hook-form'

import { WorkOrderModel } from 'appTypes/models'
import {
    AutocompleteInput,
    DateTimeInput,
    GridBreak,
    GridContainerColumns,
    GridItemLayoutInDrawer,
    ReferenceInput,
    ReferenceInputCreateLayer,
    SectionTitleInDrawer,
    SelectInput,
    TextInput,
    TextareaInput,
} from 'components'
import { DropdownIconText } from 'components/IconText'
import VendorDrawerToggler from 'pages/Vendors/components/VendorDrawerToggler'
import { vendorsResource } from 'pages/Vendors/constants/config'
import { maxLengthValidationText, requiredValidation } from 'utils'

import { prioritiesArray, repairClassArray } from '../config/constants'

import UnitField from './UnitField'
import { WorkOrderFormProps } from './WorkOrderForm'
import WorkOrderFormMeter from './WorkOrderFormMeter'

export const VendorField: FC<{ canEditVendor?: boolean; readOnlySource?: string }> = ({
    readOnlySource,
    canEditVendor = false,
}) => {
    const id = useWatch({ name: 'id' })

    if (!canEditVendor && id) {
        return (
            <TextInput
                source={readOnlySource || 'purchaseOrderData.vendorData.name'}
                label="Vendor"
                disabled
            />
        )
    }

    return (
        <ReferenceInput
            reference={vendorsResource.resource}
            source="vendor"
        >
            <ReferenceInputCreateLayer>
                {({ setValue }) => {
                    return (
                        <AutocompleteInput
                            renderCreateOption={(renderOption) => (
                                <VendorDrawerToggler
                                    onSuccess={(record) => {
                                        setValue(record.id)
                                    }}
                                    children={(onClick) =>
                                        renderOption({
                                            children: '+ Create New Vendor',
                                            onClick,
                                        })
                                    }
                                />
                            )}
                            label="Vendor"
                            optionText="name"
                            validate={requiredValidation}
                        />
                    )
                }}
            </ReferenceInputCreateLayer>
        </ReferenceInput>
    )
}

const VendorWorkOrderForm: FC<WorkOrderFormProps & { canEditVendor?: boolean }> = ({
    canEditVendor,
    inputsProps,
}) => {
    return (
        <>
            <SectionTitleInDrawer>Vendor Details</SectionTitleInDrawer>
            <GridContainerColumns>
                <GridItemLayoutInDrawer>
                    <VendorField canEditVendor={canEditVendor} />
                </GridItemLayoutInDrawer>
            </GridContainerColumns>
            <SectionTitleInDrawer>Basic Details</SectionTitleInDrawer>
            <GridContainerColumns>
                <GridItemLayoutInDrawer>
                    <UnitField {...inputsProps?.unit} />
                </GridItemLayoutInDrawer>
                <GridItemLayoutInDrawer>
                    <SelectInput
                        optionText={(option: typeof prioritiesArray[0]) => (
                            <DropdownIconText>
                                {option.icon} {option.name}
                            </DropdownIconText>
                        )}
                        label="Priority"
                        source="priority"
                        validate={requiredValidation}
                        choices={prioritiesArray}
                        disableEmptyValue
                        defaultValue="LOW"
                    />
                </GridItemLayoutInDrawer>
            </GridContainerColumns>

            <WorkOrderFormMeter />

            <SectionTitleInDrawer>Repair Details</SectionTitleInDrawer>
            <GridContainerColumns>
                <GridItemLayoutInDrawer>
                    <SelectInput
                        optionText={(option: typeof repairClassArray[0]) => (
                            <DropdownIconText>
                                {option.icon} {option.name}
                            </DropdownIconText>
                        )}
                        label="Repair Class"
                        source="repairPriorityClass"
                        choices={repairClassArray}
                        validate={requiredValidation}
                        disableEmptyValue
                    />
                </GridItemLayoutInDrawer>

                <GridItemLayoutInDrawer>
                    <DateTimeInput
                        source="started"
                        label="Start Date &amp; Time"
                        validate={requiredValidation}
                        defaultValue={new Date()}
                    />
                </GridItemLayoutInDrawer>

                <GridBreak />

                <GridBreak />
                <GridItemLayoutInDrawer>
                    <TextareaInput<WorkOrderModel>
                        source="description"
                        label="Complaint"
                        validate={maxLengthValidationText}
                    />
                </GridItemLayoutInDrawer>
            </GridContainerColumns>
        </>
    )
}

export default VendorWorkOrderForm

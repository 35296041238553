import { Tooltip } from '@mui/material'

import { WorkOrderModel } from 'appTypes/models'
import { StyledElement, IconText, InfoCard, InfoCardDetails } from 'components'
import { useIsVenderWo } from 'pages/WorkOrders/Overview/WorkOrderOverview'
import { priorities, repairClass } from 'pages/WorkOrders/config/constants'
import { formatDate } from 'utils'

const DatePriorityClass = () => {
    const isWOVendorWO = useIsVenderWo()

    return (
        <InfoCard>
            <InfoCardDetails<WorkOrderModel>
                details={[
                    {
                        label: 'Start Date & time',
                        source: ({ started }) => formatDate(started, 'MMM dd yyyy h:mm aaa'),
                    },
                    {
                        label: 'Priority',
                        source: ({ priority }) => (
                            <StyledElement sx={{ display: 'flex' }}>
                                <IconText {...priorities[priority]} />
                            </StyledElement>
                        ),
                    },
                    {
                        label: 'Repair class',
                        source: ({ repairPriorityClass }) =>
                            repairPriorityClass ? (
                                <>
                                    {repairClass[repairPriorityClass].icon}{' '}
                                    {repairClass[repairPriorityClass].text}
                                </>
                            ) : null,
                    },
                    {
                        label: isWOVendorWO ? 'Complaint' : 'Description',
                        source: ({ description }) =>
                            description ? (
                                <Tooltip
                                    placement="bottom-start"
                                    title={description}
                                >
                                    <span>{description}</span>
                                </Tooltip>
                            ) : (
                                description
                            ),
                    },
                ]}
            />
        </InfoCard>
    )
}

export default DatePriorityClass

import { FC, forwardRef, MouseEventHandler, ReactNode } from 'react'

import clsx from 'clsx'
import { NavLinkProps as RRDNavLinkProps, NavLink as BaseNavLink, To } from 'react-router-dom'

interface NavLinkProps {
    activeClassName?: string
    style?: RRDNavLinkProps['style']
    to: To
    className?: string
    children: ReactNode
    onClick?: MouseEventHandler<HTMLAnchorElement>
}

const NavLink: FC<NavLinkProps> = forwardRef<HTMLAnchorElement, NavLinkProps>(
    ({ activeClassName = 'active', to, children, onClick, ...props }, ref) => {
        return (
            <BaseNavLink
                {...props}
                children={children}
                to={to}
                onClick={onClick}
                ref={ref}
                className={({ isActive }) => clsx(props.className, { [activeClassName]: isActive })}
            />
        )
    },
)

export default NavLink

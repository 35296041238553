import { styled } from '@mui/material'

import { Columns } from 'components'

const ColumnsContainer = styled(Columns)`
    height: 61px;
    gap: 8px;
    flex: 1;
    padding: 4px;
    align-items: baseline;
`
export default ColumnsContainer

import { SubmitButton } from 'components'
import { useUtilityDrawerContext } from 'components/Drawer/UtilityDrawer'

const IntegrationDrawerCloseButton = () => {
    const { forceClose } = useUtilityDrawerContext()
    return (
        <SubmitButton
            label="Close"
            type="button"
            onClick={() => {
                forceClose()
            }}
            icon={null}
        />
    )
}

export default IntegrationDrawerCloseButton

import { StatusType } from 'appTypes'
import { MemberModel } from 'appTypes/models'

export const memberStatuses: {
    [key in MemberModel['invitationStatus']]: StatusType
} = {
    ACCEPTED: {
        text: 'Accepted',
        iconColor: (theme) => theme.palette.success.main,
    },
    INVITATION_SENT: {
        text: 'Invitation Sent',
        iconColor: (theme) => theme.palette.primary.main,
    },
    EXPIRED: {
        text: 'Expired',
        iconColor: (theme) => theme.palette.error.main,
    },
}

import React, { ReactNode } from 'react'

import { Sync, SyncDisabled } from '@mui/icons-material'
import { alpha } from '@mui/material'
import { useListContext } from 'react-admin'

import { TelematicsUnitModel } from 'appTypes/models'
import { BoxContainer, ListTotalBadge, StatusText, Typography } from 'components'
import Header from 'components/pages/header'
import { capitalize } from 'utils'

import { StyledSVG } from '../../components/ConnectedUnitsStatus'
import { ItemPropTypes } from '../../components/Item'
import { calculateSyncedUnits } from '../IntegrationsUnitListDialog'
import { useSyncedStateContext } from '../utils/SyncedStateContext'
const StatusBadge = ({ children, synced }: { synced?: boolean; children: ReactNode }) => {
    return (
        <BoxContainer gap="4px">
            <StatusText
                statusColor={(theme) =>
                    synced ? theme.palette.primary.main : alpha(theme.palette.text.primary, 0.26)
                }
                sx={{
                    boxSizing: 'border-box',
                    width: '16px',
                    height: '16px',
                    padding: '1.5px !important',
                }}
            >
                <StyledSVG component={synced ? Sync : SyncDisabled} />
            </StatusText>
            <Typography
                variant="body2"
                color="text.primary"
            >
                {children}
            </Typography>
        </BoxContainer>
    )
}
const IntegrationsUnitListDialogHeader = ({ item }: { item: ItemPropTypes['item'] }) => {
    const { synced } = useSyncedStateContext()
    const { data } = useListContext<TelematicsUnitModel>()
    if (!data) {
        return null
    }
    const syncedUnits = calculateSyncedUnits(synced, item.vehiclesSynced)
    return (
        <Header>
            <Header.Block>
                <Header.Main>
                    <Header.Spacer>
                        <Header.Title variant="h6">
                            Units from {capitalize(item.provider)}
                        </Header.Title>
                        <BoxContainer gap="8px">
                            <ListTotalBadge renderContent={() => item.vehiclesTotal} />
                            <StatusBadge synced>{syncedUnits}</StatusBadge>
                            <StatusBadge>{item.vehiclesTotal - syncedUnits}</StatusBadge>
                        </BoxContainer>
                    </Header.Spacer>
                </Header.Main>
            </Header.Block>
        </Header>
    )
}

export default IntegrationsUnitListDialogHeader

import { StyledElement, TabType } from 'components'
import Header from 'components/pages/header'

const CompanyHeader = ({ tabs }: { tabs: TabType[] }) => {
    return (
        <Header fixed>
            <Header.Block
                divider={false}
                mobileCenter
            >
                <Header.BackButton />
                <Header.Main>
                    <Header.Title>Company Account</Header.Title>
                </Header.Main>
                <Header.Aside>
                    <StyledElement id="header-portal-root" />
                </Header.Aside>
            </Header.Block>
            <Header.Tabs
                tabs={tabs}
                tabWidth="188px"
            />
        </Header>
    )
}

export default CompanyHeader

import {
    subDays,
    startOfWeek,
    startOfMonth,
    startOfQuarter,
    startOfYear,
    isValid,
    format,
} from 'date-fns'

const lastNDays = (n: number, to = new Date()): [Date, Date] => {
    const from = subDays(to, n - 1)
    return [from, to]
}

type DateRangeValue = [Date, Date]
type dateReturnType = 'date' | 'string'
type dateRangeType = 'from' | 'to'
type defaultRangeReturnFormat = 'date'
type DateRangeReturnFormat<T extends dateReturnType = defaultRangeReturnFormat> = T extends 'date'
    ? Date
    : string

interface Range {
    id: string
    name: string
    values: <DateReturnType extends dateReturnType = defaultRangeReturnFormat>(
        returnType?: DateReturnType,
    ) => [DateRangeReturnFormat<DateReturnType>, DateRangeReturnFormat<DateReturnType>]
}

export const dateFormatsObject = {
    fullDateTime: 'MMMM dd, yyyy hh:mm a',
    shortenedDateTime: 'MMM dd yyyy h:mm a',
    shortenedDate: 'MMM dd yyyy',
    serverDateTime: "yyyy-MM-dd'T'HH:mm:ssxxxx",
    serverDate: 'yyyy-MM-dd',
}
export const formatDate = (
    date: Date | string,
    formatType: string | ((dateFormats: typeof dateFormatsObject) => string),
) => {
    if (!date) {
        return ''
    }
    return format(
        typeof date === 'string' ? new Date(date) : date,
        typeof formatType === 'function' ? formatType(dateFormatsObject) : formatType,
    )
}
export const dateTimeRangeFormats: {
    [key in dateRangeType | 'format']: string
} = {
    format: dateFormatsObject.serverDateTime,
    from: dateFormatsObject.serverDateTime.replace('HH:mm:ss', '00:00:00'),
    to: dateFormatsObject.serverDateTime.replace('HH:mm:ss', '23:59:59'),
}

export const dateRangeFormat = <DateReturnType extends dateReturnType = defaultRangeReturnFormat>(
    date: Date | string,
    {
        dateRange = 'from',
        returnType,
    }: {
        dateRange?: dateRangeType
        returnType?: DateReturnType
    } = {},
): DateRangeReturnFormat<DateReturnType> => {
    const formatedDate = formatDate(date, dateTimeRangeFormats[dateRange])

    if (returnType === 'string') {
        return formatedDate as DateRangeReturnFormat<DateReturnType>
    }

    return new Date(formatedDate) as DateRangeReturnFormat<DateReturnType>
}

const dateRangeDates: (Omit<Range, 'values'> & {
    values: () => DateRangeValue
})[] = [
    {
        id: 'today',
        name: 'Today',
        values: () => {
            const now = new Date()
            return [now, now]
        },
    },
    {
        id: 'last-7-days',
        name: 'Last 7 Days',
        values: () => lastNDays(7),
    },
    {
        id: 'last-30-days',
        name: 'Last 30 Days',
        values: () => lastNDays(30),
    },
    {
        id: 'this-week',
        name: 'This Week',
        values: () => {
            const now = new Date()
            const from = startOfWeek(now)
            return [from, now]
        },
    },
    {
        id: 'last-week',
        name: 'Last Week',
        values: () => {
            const now = new Date()
            const startOfThisWeek = startOfWeek(now)
            const to = subDays(startOfThisWeek, 1)
            const from = startOfWeek(to)
            return [from, to]
        },
    },
    {
        id: 'this-month',
        name: 'This Month',
        values: () => {
            const now = new Date()
            const from = startOfMonth(now)
            return [from, now]
        },
    },
    {
        id: 'last-month',
        name: 'Last Month',
        values: () => {
            const now = new Date()
            const startOfThisMonth = startOfMonth(now)
            const to = subDays(startOfThisMonth, 1)
            const from = startOfMonth(to)
            return [from, to]
        },
    },
    {
        id: 'this-quarter',
        name: 'This Quarter',
        values: () => {
            const now = new Date()
            const from = startOfQuarter(now)
            return [from, now]
        },
    },
    {
        id: 'last-quarter',
        name: 'Last Quarter',
        values: () => {
            const now = new Date()
            const startOfThisQuarter = startOfQuarter(now)
            const to = subDays(startOfThisQuarter, 1)
            const from = startOfQuarter(to)
            return [from, to]
        },
    },
    {
        id: 'ytd',
        name: 'YTD',
        values: () => {
            const now = new Date()
            const from = startOfYear(now)
            return [from, now]
        },
    },
    {
        id: 'last-year',
        name: 'Last Year',
        values: () => {
            const now = new Date()
            const startOfThisYear = startOfYear(now)
            const to = subDays(startOfThisYear, 1)
            const from = startOfYear(to)
            return [from, to]
        },
    },
]

export const dateRanges: Range[] = dateRangeDates.map((dateRange) => ({
    ...dateRange,
    values: (returnType) => {
        const [from, to] = dateRange.values()
        return [
            dateRangeFormat(from, { returnType, dateRange: 'from' }),
            dateRangeFormat(to, { returnType, dateRange: 'to' }),
        ]
    },
}))

export const dateRangeObject = dateRanges.reduce((obj, choice) => {
    obj[choice.id] = choice

    return obj
}, {} as { [key: string]: Range })

export const dateReadableFormat = (date: string | Date) => {
    return formatDate(date, dateFormatsObject.shortenedDate + ' h:mm aaa')
}

export const dateTimeParse = (date: string | Date) => {
    if (!date) {
        return null
    }
    const newDate = new Date(date)
    newDate.setSeconds(0, 0)
    if (!isValid(newDate)) {
        return null
    }

    return formatDate(newDate, dateFormatsObject.serverDateTime)
}

export const dateParse = (date: string | Date) => {
    if (!date) {
        return null
    }
    const newDate = date instanceof Date ? date : new Date(stringDateFixTimezone(date))

    if (!isValid(newDate)) {
        return null
    }

    return formatDate(newDate, dateFormatsObject.serverDate)
}

export const stringDateFixTimezone = (date: string | number): string | null => {
    if (!date) {
        return null
    }

    let newDate = String(date)
    if (newDate.length === 4) {
        newDate += '-03-03'
    }

    return newDate.split('T')[0] + 'T12:00:00'
}

import { ElementType } from 'react'

import { SvgIcon, styled, Theme } from '@mui/material'
import { SxProps } from '@mui/material/styles'

import { StyledElement } from 'components'
const Circle = styled(StyledElement)`
    z-index: -1;
    width: 86px;
    height: 86px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
`

const SuccessCircle = ({ sx, icon }: { sx: SxProps<Theme>; icon: ElementType<any> }) => {
    return (
        <Circle sx={sx}>
            <SvgIcon
                inheritViewBox
                sx={{
                    width: '36px',
                    height: '36px',
                }}
                component={icon}
            />
        </Circle>
    )
}
export default SuccessCircle

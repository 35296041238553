import { useCreatePath, useShowContext, useUpdate } from 'react-admin'

import { GridContainer, PageContent, getRecordPhotos } from 'components'
import { useFinalErrorHandler, useNotify } from 'hooks'

import { partsResource } from '../config/constants'

import { NoPhotosCard, PartPhotoGallery, PhotoTabHeader } from './components'

const PartPhotos = () => {
    const { record, isFetching, refetch } = useShowContext()
    const notify = useNotify()
    const [update] = useUpdate()

    const errorHandler = useFinalErrorHandler()
    const photoErrorHandler = (error: any, id: string) => {
        if (error?.[id]) {
            notify(error?.[id].message, {
                type: 'error',
            })
        } else {
            errorHandler(error)
        }
    }
    const createPath = useCreatePath()
    if (!record && isFetching) {
        return null
    }

    const { files, count } = getRecordPhotos(record)
    const upload = async (event) => {
        const file = event.target.files?.[0]
        if (!file) {
            return
        }
        const freePhoto = Object.keys(files).find((photoIndex) => {
            return !files[photoIndex]
        })
        const type = file.type
        if (!type.startsWith('image') || type.includes('svg')) {
            notify('Invalid file format', {
                type: 'error',
            })
        } else if (file.size > 10000000) {
            notify('The maximum allowed file size is 10 MB', {
                type: 'error',
            })
        } else {
            try {
                await update(
                    createPath({ resource: partsResource.resource, id: record.id, type: 'edit' }),
                    { data: { [freePhoto]: file } },
                    { returnPromise: true },
                )
                refetch()
            } catch (error) {
                photoErrorHandler(error, freePhoto)
            }
        }
        event.target.value = null
    }
    const deletePhoto = async (id: string) => {
        try {
            await update(
                createPath({ resource: partsResource.resource, id: record.id, type: 'edit' }),
                { data: { [id]: null } },
                { returnPromise: true },
            )
            refetch()
        } catch (e) {
            photoErrorHandler(e, id)
        }
    }
    return (
        <PageContent>
            <PhotoTabHeader
                photosCount={count}
                upload={upload}
            />
            <GridContainer>
                {count ? (
                    <PartPhotoGallery
                        photos={files}
                        deletePhoto={deletePhoto}
                    />
                ) : (
                    <NoPhotosCard upload={upload} />
                )}
            </GridContainer>
        </PageContent>
    )
}

export default PartPhotos

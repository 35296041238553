import { styled } from '@mui/material'

const QRScannerMenu = styled('div')`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    width: 100%;

    ${({ theme }) => `
        ${theme.breakpoints.up('sm')} {
            flex-direction: row;
            gap: 32px;
            bottom: 29px;
        }
    `}
`

export default QRScannerMenu

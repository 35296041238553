import api from 'api/api'
import { useQuery } from 'hooks'

export const useMonitor = <Response = unknown,>(resource: string, queryData?: any) => {
    const query = useQuery<Response>([resource], async () => {
        const data: Response = await api.get(resource, queryData)
        return data
    })
    return query.data
}

import { useEffect } from 'react'

import { useLDClient } from 'launchdarkly-react-client-sdk'
import { inject, observer } from 'mobx-react'

import config from 'configs/config'
import { AuthStore } from 'providers/authStore'

const UserObserver = inject('auth')(
    observer(({ auth }: { auth?: AuthStore }) => {
        const user = auth.user
        const ldClient = useLDClient()
        const userId = user?.id

        useEffect(() => {
            if (user) {
                if (config.SENTRY) {
                    config.SENTRY.configureScope((scope) =>
                        scope.setUser({ pk: user.id, email: user.email }),
                    )
                }
                window.Intercom &&
                    window.Intercom('boot', {
                        ...window.intercomSettings,
                        name: user.name,
                        // eslint-disable-next-line camelcase
                        created_at: user.dateJoined,
                        email: user.email,
                        // eslint-disable-next-line camelcase
                        user_hash: user.intercomUserHash,
                    })
            } else {
                if (config.SENTRY) {
                    config.SENTRY.configureScope((scope) => scope.setUser(null))
                }

                window.Intercom && window.Intercom('shutdown')

                ldClient.identify({ anonymous: true })
            }
        }, [userId])

        return null
    }),
)

export default UserObserver

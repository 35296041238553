import { Optional } from 'appTypes'
import { parseEmail } from 'utils'

import TextInput, { TextInputProps } from './TextInput'

interface EmailInputProps extends Optional<TextInputProps, 'source'> {}

const EmailInput = (props: EmailInputProps) => {
    return (
        <TextInput
            type="email"
            source="email"
            label="Email"
            parse={parseEmail}
            {...props}
        />
    )
}

export default EmailInput

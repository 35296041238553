import { RaRecord } from 'react-admin'

import { HideArchived, NoResultsCard } from 'components'

import WorkOrderSimpleListNoResultsCreateAction, {
    WorkOrderSimpleListNoResultsCreateActionProps,
} from './WorkOrderSimpleListNoResultsCreateAction'

export interface WorkOrderSimpleListNoResultsProps<RecordType extends RaRecord>
    extends WorkOrderSimpleListNoResultsCreateActionProps<RecordType> {}

const WorkOrderSimpleListNoResults = <RecordType extends RaRecord>(
    props: WorkOrderSimpleListNoResultsProps<RecordType>,
) => {
    return (
        <NoResultsCard
            direction="row"
            height="180px"
            title="No Open Work Orders"
            imageSrc={(images) => images.listEmpty}
            imageProps={{
                width: '107px',
                height: '92px',
                mb: '20px',
                mr: {
                    xs: '16px',
                    sm: '20px',
                },
            }}
            action={
                <HideArchived>
                    <WorkOrderSimpleListNoResultsCreateAction {...props} />
                </HideArchived>
            }
        />
    )
}

export default WorkOrderSimpleListNoResults

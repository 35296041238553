import { useTheme } from '@mui/material'

import { Slider as BaseSlider, SliderProps, gridItemLayoutColumns } from 'components'

export interface SliderViewProps extends Omit<SliderProps, 'gap' | 'slidesToShow' | 'responsive'> {}

const SliderView = (props: SliderViewProps) => {
    const theme = useTheme()

    return (
        <BaseSlider
            {...props}
            gap={{
                xs: '12px',
                sm: '23px',
            }}
            slidesToShow={4}
            responsive={[
                {
                    breakpoint: theme.breakpoints.values.xl,
                    settings: {
                        slidesToShow: 12 / gridItemLayoutColumns.lg,
                    },
                },
                {
                    breakpoint: theme.breakpoints.values.lg,
                    settings: {
                        slidesToShow: 12 / gridItemLayoutColumns.md,
                    },
                },
                {
                    breakpoint: theme.breakpoints.values.md,
                    settings: {
                        arrows: false,
                        slidesToShow: 1.5,
                        swipe: true,
                    },
                },
                {
                    breakpoint: theme.breakpoints.values.sm,
                    settings: {
                        arrows: false,
                        slidesToShow: 1.09,
                        swipe: true,
                    },
                },
            ]}
        />
    )
}

export default SliderView

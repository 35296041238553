import SaveIcon from '@mui/icons-material/Save'
import { SaveButtonProps } from 'react-admin'

import { SubmitButton } from 'components'
import Icons from 'components/icons'

import { useUtilityDrawerContext } from './UtilityDrawer'

interface UtilityDrawerSaveButtonProps extends SaveButtonProps {
    formType?: 'create' | 'edit'
}

const UtilityDrawerSaveButton = ({ formType, ...props }: UtilityDrawerSaveButtonProps) => {
    const { extraArgs = {} } = useUtilityDrawerContext()
    const isEditMode = Boolean(
        (formType && formType === 'edit') ?? (extraArgs.id || extraArgs.type === 'edit'),
    )
    const Icon = isEditMode ? SaveIcon : Icons.Add

    return (
        <SubmitButton
            size="large"
            variant="contained"
            icon={<Icon fontSize="large" />}
            type="submit"
            label={isEditMode ? 'SAVE' : 'CREATE'}
            {...props}
        />
    )
}

export default UtilityDrawerSaveButton

import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined'
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined'
import { inject } from 'mobx-react'
import { Identifier, useListContext } from 'react-admin'

import api from 'api'
import { ServerError, SortType } from 'appTypes'
import { DataAvatar, List, IconText, PageContent, Status } from 'components'
import DefaultUserAvatar from 'components/DefaultUserAvatar'
import {
    ActionChildren,
    deleteManyFromListAction,
    deleteOneFromListAction,
    multiselectAction,
} from 'components/actions'
import ListBase from 'components/list/ListBase'
import { ListBulkActions } from 'components/list/ListSelection'
import { ListSortContentProps } from 'components/list/ListSortDrawerContent'
import { basePermissions } from 'configs/constants'
import { renderOnPermission } from 'hocs'
import { useResourcePermissions, useNotify } from 'hooks'
import { AuthStore } from 'providers/authStore'
import { formatDate, hasPermission, phoneMaskResolver } from 'utils'

import { memberStatuses } from '../../config/constants'
import { MemberInviteOpenDrawerButton } from '../Invite'
import { EditMemberAction } from '../components'
import { membersPermissions } from '../config/constants'

import { MembersListHeader } from './compoents'

import type { MemberModel, UserRole } from 'appTypes/models'
import type { DatagridColumnsProps, CardListConfig } from 'components'

const defaultMemberSort: SortType<MemberModel> = {
    field: 'created',
    order: 'DESC',
}

const sortCfg: ListSortContentProps<MemberModel & { invitation_status: string }> = {
    sortBy: [
        { id: 'created', label: 'Invitation Created' },
        { id: 'name', label: 'Name' },
        { id: 'invitationStatus', label: 'Invitation Status' },
        { id: 'email', label: 'Email Address' },
        { id: 'phone', label: 'Phone Number' },
    ],
}
const deleteMemberAction = {
    confirmConfig: {
        title: 'Are you sure you want to delete the selected Member?',
    },
}

const bulkActions: ListBulkActions = ({ children }) => {
    return [
        deleteManyFromListAction({
            children,
            confirmConfig: {
                title: 'Are you sure want to delete the selected Members?',
            },
        }),
    ]
}

const ResendAction = renderOnPermission(
    ({ children, id }: { children: ActionChildren; id: Identifier }) => {
        const notify = useNotify()
        const listContext = useListContext()
        const resend = async () => {
            try {
                await api.post(listContext.resource + '/' + id + '/resend_invitation')
                listContext.refetch()
                notify('Invitation sent', { type: 'success' })
            } catch (err) {
                notify((err as ServerError).message, { type: 'error' })
            }
        }
        return children({
            Icon: RefreshOutlinedIcon,
            title: 'Resend',
            onClick: resend,
        })
    },
    membersPermissions.resendInvitation,
)

const MembersList = inject('auth')(({ auth }: { auth?: AuthStore }) => {
    const userId = auth.user.id
    const permissions = useResourcePermissions()

    const isMemberLoggedUser = (member: MemberModel) => member.userId === userId
    const isLimitedRole = auth.user.membership.role === 'limited'
    const columnsCfg: DatagridColumnsProps<MemberModel> = {
        checkboxSelection: !isLimitedRole,
        columns: [
            {
                field: 'avatar',
                headerName: 'Avatar',
                flex: 1,
                maxWidth: 72,
                renderCell: (cell) => (
                    <DataAvatar
                        imageSrc={cell.value}
                        defaultImage={
                            <DefaultUserAvatar
                                user={cell.row}
                                fontSize="medium"
                            />
                        }
                    />
                ),
            },
            { field: 'email', headerName: 'Email Address', flex: 1 },
            {
                field: 'role',
                headerName: 'Role',
                flex: 1,
                renderCell: ({ row }) => row.roleData?.label,
            },
            {
                field: 'invitationStatus',
                headerName: 'Invitation Status',
                flex: 1,
                renderCell: (cell) => {
                    const status = memberStatuses[cell.value as MemberModel['invitationStatus']]
                    if (!status) {
                        return null
                    }
                    return (
                        <IconText
                            icon={
                                <Status
                                    iconColor={status.iconColor}
                                    size="8px"
                                />
                            }
                            text={status.text}
                        />
                    )
                },
            },
            {
                field: 'created',
                headerName: 'Invitation Created',
                flex: 1,
                renderCell: (cell) =>
                    formatDate(cell.value, (dateFormats) => dateFormats.shortenedDateTime),
            },
            {
                field: 'name',
                headerName: 'Name',
                flex: 1,
            },
            {
                field: 'phone',
                headerName: 'Phone Number',
                flex: 1,
                valueFormatter: ({ value }) => phoneMaskResolver(value as string),
            },
        ],
        // TODO: refactor. Allow actions to disable if column 'Actions' is visible
        actions: hasPermission(
            permissions[basePermissions.update] ||
                permissions[basePermissions.destroy] ||
                permissions[membersPermissions.resendInvitation],
        )
            ? ({ row: member }, { children }) => {
                  const isLoggedUser = isMemberLoggedUser(member)

                  return [
                      <EditMemberAction
                          id={member.id}
                          key="edit"
                      >
                          {(editMember) =>
                              children({
                                  Icon: ManageAccountsOutlinedIcon,
                                  title: 'Change Role',
                                  titleOnDisabled: 'Own Role cannot be changed',
                                  onClick: editMember,
                                  disabled: isLimitedRole || isLoggedUser,
                              })
                          }
                      </EditMemberAction>,
                      deleteOneFromListAction({
                          children: (params) =>
                              children({
                                  ...params,
                                  disabled: isLimitedRole || isLoggedUser || params.disabled,
                                  titleOnDisabled: isLoggedUser
                                      ? 'Own account cannot be deleted'
                                      : undefined,
                              }),
                          id: member.id,
                          ...deleteMemberAction,
                      }),
                      member.invitationStatus === 'ACCEPTED' ? null : (
                          <ResendAction
                              key="resend"
                              id={member.id}
                              children={(params) =>
                                  children({
                                      ...params,
                                      disabled: isLoggedUser || params.disabled,
                                  })
                              }
                          />
                      ),
                  ]
              }
            : null,
    }

    const cardsCfg: CardListConfig<MemberModel> = {
        titleSource: (record) => {
            return record.email
        },
        subTitleSource: (record) => {
            return record.invitationStatus.replace('_', ' ')
        },
        disableTitleLink: true,
        withStatus: true,
        colorGetter: (record) => {
            const status = memberStatuses[record.invitationStatus]
            if (!status) {
                return null
            }
            return status.iconColor
        },
        imageSource: 'avatar',
        defaultImage: (record) => (
            <DefaultUserAvatar
                user={record}
                fontSize="medium"
            />
        ),
        details: [
            { source: 'name', label: 'name' },
            {
                source: 'phone',
                label: 'phone number',
                render: (value) => phoneMaskResolver(value),
            },
            {
                source: 'roleData',
                label: 'user role',
                render: (value) => (value as UserRole).label,
            },
        ],
        actionsDisabled: (member) => isMemberLoggedUser(member) || isLimitedRole,
        actions: ({ id, invitationStatus }, { children }) => [
            <EditMemberAction
                id={id}
                key="edit"
            >
                {(editMember) =>
                    children({
                        Icon: ManageAccountsOutlinedIcon,
                        title: 'Change Role',
                        onClick: editMember,
                    })
                }
            </EditMemberAction>,
            // exportAction({
            //     children,
            // }),
            multiselectAction({
                children,
                id,
            }),
            deleteOneFromListAction({
                children,
                id,
                ...deleteMemberAction,
            }),
            invitationStatus === 'ACCEPTED' ? null : (
                <ResendAction
                    key="resend"
                    id={id}
                    children={children}
                />
            ),
        ],
    }

    return (
        <ListBase<MemberModel> sort={defaultMemberSort}>
            <MemberInviteOpenDrawerButton />
            <MembersListHeader />
            <PageContent>
                <List
                    bulkActions={bulkActions}
                    sortCfg={sortCfg}
                    columnsCfg={columnsCfg}
                    cardsCfg={cardsCfg}
                    listFTUProps={{
                        linkText: 'Add New Members',
                        secondaryTitle: 'Please, Add New Members',
                    }}
                />
            </PageContent>
        </ListBase>
    )
})

export default MembersList

import noVendorContactsIcon from 'assets/images/no-vendor-contacts.svg'
import { NoResultsCard, Typography } from 'components'

import VendorContactDrawerToggler from './VendorContactDrawerToggler'

const VendorContactsEmpty = () => {
    return (
        <NoResultsCard
            title="No Items"
            height="145px"
            direction="row"
            imageSrc={noVendorContactsIcon}
            imageProps={{
                alt: 'No Contacts',
                width: '107px',
                mr: {
                    xs: '16px',
                    sm: '20px',
                },
            }}
            action={
                <VendorContactDrawerToggler>
                    {({ onClick }) => (
                        <Typography
                            variant="body1"
                            color="primary"
                            sx={{ cursor: 'pointer' }}
                            onClick={onClick}
                        >
                            Add Contact Person
                        </Typography>
                    )}
                </VendorContactDrawerToggler>
            }
        />
    )
}

export default VendorContactsEmpty

import { FC } from 'react'

import { inputLabelClasses } from '@mui/material'
import camelCase from 'lodash/camelCase'
import { useWatch } from 'react-hook-form'

import { MeterModel } from 'appTypes/models'
import { StyledElement, TextInput, Typography } from 'components'
import { getMeterTypeAdornment } from 'pages/Units/Meters/constants'
import { LastDoneHelperTextBase } from 'pages/Units/PM/PMEditor'
import { LastDoneHelperTextProps } from 'pages/Units/PM/PMEditor/LastDoneHelperTextBase'
import { inputIntegerNonNegativeSpacedMaskParams, requiredValidation, titleCase } from 'utils'

import { MeterType } from './WoCloseFields'

interface WOResetPMHelperTextProps extends Omit<LastDoneHelperTextProps, 'lastDone' | 'meters'> {
    name: string
}

const WOResetPMHelperText = ({ name, ...props }: WOResetPMHelperTextProps) => {
    const lastDone = useWatch({ name })
    return (
        <LastDoneHelperTextBase
            {...props}
            lastDone={lastDone}
        />
    )
}

const pmMeterInputSource = (type) => `${camelCase(type)}Value`
const meterTypes: MeterModel['type'][] = ['ENGINE_HOURS', 'HUBOMETER', 'ODOMETER']
export const pmMeterSources = meterTypes.map(pmMeterInputSource)

const PmMeterInputs: FC<{ meter: MeterType }> = ({ meter }) => {
    const meterPms = useWatch({ name: meter.pmIds.map((id) => `pm.${id}`) })

    const disabled = meterPms.every((pm) => pm === false)
    const name = pmMeterInputSource(meter.type)

    return (
        <StyledElement key={meter.id}>
            <Typography
                variant="subtitle2"
                mb="11px"
            >
                {titleCase(meter.type)}
            </Typography>
            <TextInput
                disabled={disabled}
                source={name}
                sx={(theme) => ({
                    [`& .${inputLabelClasses.root} span`]: {
                        color: disabled
                            ? `${theme.palette.text.disabled} !important`
                            : theme.palette.text.primary,
                    },
                })}
                helperText={
                    meter.value ? (
                        <WOResetPMHelperText
                            meter={meter as MeterModel}
                            meterType={meter.type}
                            value={meter.value}
                            name={name}
                        />
                    ) : null
                }
                label="Reading at Completion Date"
                validate={disabled ? undefined : requiredValidation}
                InputProps={{
                    endAdornment: (
                        <Typography color="text.disabled">
                            {getMeterTypeAdornment(meter.type)}
                        </Typography>
                    ),
                }}
                {...inputIntegerNonNegativeSpacedMaskParams}
            />
        </StyledElement>
    )
}

export default PmMeterInputs

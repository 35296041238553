import { VendorModel } from 'appTypes/models'
import { ResourceAvatar } from 'appTypes/models/ResourceAvatar'
import Icons from 'components/icons'
import { DeleteOneParams } from 'hooks'
import { Serializer } from 'utils'

export const vendorsResource = {
    name: 'vendors',
    resource: 'vendors',
}

export const deleteVendorParams: DeleteOneParams = {
    confirmConfig: {
        title: 'Are you sure you want to delete this Vendor?',
    },
}

export const vendorTypes: { id: VendorModel['type']; name: string }[] = [
    {
        id: 'REPAIR_SERVICES',
        name: 'Repair Services',
    },
    {
        id: 'PARTS_SUPPLY',
        name: 'Parts Supply',
    },
]

export const vendorTypesObject = vendorTypes.reduce((obj, type) => {
    obj[type.id] = type
    return obj
}, {} as { [key in VendorModel['type']]: { id: VendorModel['type']; name: string } })

export const vendorPaymentMethods: {
    id: VendorModel['paymentMethod']
    name: string
}[] = [
    {
        id: 'CASH',
        name: 'Cash',
    },
    {
        id: 'ON_ACCOUNT',
        name: 'On Account',
    },
    {
        id: 'CARD',
        name: 'Card',
    },
    {
        id: 'CHECK',
        name: 'Check',
    },
]

export const vendorPaymentMethodsObject = vendorPaymentMethods.reduce(
    (obj, type) => {
        obj[type.id] = type
        return obj
    },
    {} as {
        [key in VendorModel['paymentMethod']]: {
            id: VendorModel['paymentMethod']
            name: string
        }
    },
)

export const vendorsSerializer: Serializer<VendorModel> = [
    'name',
    'type',
    {
        name: 'logo',
        parse: 'file',
    },
    'phone',
    { name: 'email', parse: 'email' },
    { name: 'website', parse: 'lowerCase' },
    'address',
    'address2',
    'city',
    'state',
    'zipCode',
    'taxId',
    'nationalAccount',
    {
        name: 'paymentMethod',
        parse: 'emptyToNull',
    },
    {
        name: 'paymentTerm',
        parse: 'emptyToNull',
    },
    { name: 'tags', parse: 'emptyToNull' },
]

export const vendorAddressInfo = {
    label: 'City, State, Zip',
    value: (vendor: VendorModel): string =>
        [vendor.city, vendor.state, vendor.zipCode].filter((item) => item).join(', '),
}
export const vendorAvatarConfig: ResourceAvatar = {
    Icon: Icons.Vendors,
}

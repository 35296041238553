import { ReactElement } from 'react'

import { Box, CircularProgress, List } from '@mui/material'
import { Identifier, RaRecord, useChoicesContext } from 'react-admin'

import { ExtendRecordType } from 'appTypes'
import { NoResultsCardProps } from 'components/NoResultsCard/NoResultsCard'
import { filterSearchText } from 'configs/constants'

import DialogSelectorListItem, { DialogSelectorListItemProps } from './DialogSelectorListItem'
import DialogSelectorNoResults from './DialogSelectorNoResults'

export interface DialogSelectorListProps<T extends RaRecord = any> {
    itemPrimary?: ExtendRecordType<T, string | string[] | ReactElement>
    itemSecondary?: ExtendRecordType<T, string | string[]>
    noResults?: (params: { searchValue: string; filterValues: any }) => {
        title?: string
        text?: string
        image?: NoResultsCardProps['imageSrc']
    }
    selectItem: (id: Identifier) => void
    renderNoResults?: (params: { filterValues: any; searchValue: any }) => ReactElement | undefined
    renderListItem?: (
        record: T,
        render: (params?: Partial<DialogSelectorListItemProps<T>>) => ReactElement,
    ) => ReactElement
}

const DialogSelectorList = ({
    itemPrimary = 'title',
    itemSecondary = 'text',
    noResults,
    selectItem,
    renderNoResults,
    renderListItem,
}: DialogSelectorListProps) => {
    const { availableChoices, isFetching, filterValues } = useChoicesContext()

    if (isFetching) {
        return (
            <Box
                display="flex"
                height="100%"
                justifyContent="center"
                alignItems="center"
            >
                <CircularProgress size="80px" />
            </Box>
        )
    }

    if (!availableChoices.length) {
        if (renderNoResults) {
            const noResultRender = renderNoResults({
                filterValues,
                searchValue: filterValues[filterSearchText],
            })
            if (typeof noResultRender !== 'undefined') {
                return noResultRender
            }
        }

        const noResultsConfig = noResults?.({
            searchValue: filterValues[filterSearchText],
            filterValues,
        })

        return <DialogSelectorNoResults {...noResultsConfig} />
    }

    return (
        <List
            sx={{ display: 'flex', height: '100%', flexDirection: 'column' }}
            disablePadding
            role="listbox"
            aria-label="Select option"
        >
            {availableChoices.map((choice) => {
                const render = (params?: any) => {
                    return (
                        <DialogSelectorListItem
                            choice={choice}
                            onSelect={(e, choice) => selectItem(choice.id)}
                            itemPrimary={itemPrimary}
                            itemSecondary={itemSecondary}
                            key={choice.id}
                            {...params}
                        />
                    )
                }

                if (renderListItem) {
                    return renderListItem(choice, render)
                }

                return render()
            })}
        </List>
    )
}

export default DialogSelectorList

import { FC } from 'react'

import { WOJobItem, WOJobItemTypes, WOJobItemTypesUnion } from 'appTypes/models'

import LineItemsTooltipContent from './LineItemsTooltipContent'
import { useJobItemTranslate } from './WorkOrderJobCardItems'
import { LineItemsMap } from './useJobItemsTooltipData'

interface ItemTotalsType extends Record<WOJobItemTypesUnion, number> {}

const itemsTotalInitial: ItemTotalsType = {
    [WOJobItemTypes.part]: null,
    [WOJobItemTypes.labor]: null,
    [WOJobItemTypes.fee]: null,
    [WOJobItemTypes.service]: null,
    [WOJobItemTypes.tax]: null,
}

const LineItemsTooltipTitle: FC<{ data: WOJobItem[] }> = ({ data }) => {
    const itemsTranslate = useJobItemTranslate()

    const itemTotals = data.reduce(
        (prev, obj) => {
            const { total } = itemsTranslate(obj)
            const itemTotal = typeof total === 'function' ? total() : total
            prev[obj.type] = prev[obj.type] + itemTotal
            return prev
        },
        { ...itemsTotalInitial },
    )

    const mutatedItems = LineItemsMap.map((item) => ({
        ...item,
        total: itemTotals[item.id],
    }))

    return (
        <LineItemsTooltipContent
            data={mutatedItems}
            allWhite
        />
    )
}

export default LineItemsTooltipTitle

import { ResourceWithClearEffect } from 'components'
import { useDataProviderConfig } from 'providers/dataProvider'

import VendorCreate from './Create'
import VendorsList from './List'
import VendorShow from './Show'
import { vendorsResource } from './constants/config'

const dpConfig = {
    queryParams: () => ({ withAspects: ['totals'] }),
}

const Vendors = () => {
    useDataProviderConfig(vendorsResource.resource, {
        getOne: dpConfig,
        update: dpConfig,
    })
    return (
        <ResourceWithClearEffect
            name={vendorsResource}
            list={VendorsList}
            create={VendorCreate}
            edit={VendorShow}
        />
    )
}

export default Vendors

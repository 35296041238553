import { BoxContainer, ListTotalBadge } from 'components'
import Header from 'components/pages/header'
import { capitalize } from 'utils'

import { NewUnits } from '../../components'
import ConnectedUnitsStatus from '../../components/ConnectedUnitsStatus'

import ConfigureButton, { ConfigureButtonProps } from './ConfigureButton'
import ShouldSync from './ShouldSync'
interface IntegrationsUnitListHeaderProps extends ConfigureButtonProps {}

const IntegrationsUnitListHeader = ({
    item,
    refetchTelematics,
}: IntegrationsUnitListHeaderProps) => {
    return (
        <Header>
            <ShouldSync provider={item.provider} />
            <Header.Block>
                <Header.Main>
                    <Header.Spacer
                        sx={(theme) => ({
                            [theme.breakpoints.down('sm')]: {
                                flexDirection: 'column',
                                gap: 0,
                                alignItems: 'flex-start',
                            },
                        })}
                    >
                        <Header.Title>Units from {capitalize(item.provider)}</Header.Title>
                        <BoxContainer gap="8px">
                            <ListTotalBadge renderContent={() => item.vehiclesTotal} />
                            <BoxContainer gap="16px">
                                <ConnectedUnitsStatus type="sync">
                                    {item.vehiclesSynced}
                                </ConnectedUnitsStatus>
                                <ConnectedUnitsStatus type="error">
                                    {item.vehiclesWithError}
                                </ConnectedUnitsStatus>
                                <BoxContainer gap="4px">
                                    <ConnectedUnitsStatus type="notImported">
                                        {item.vehiclesNotSynced}
                                    </ConnectedUnitsStatus>
                                    <NewUnits integration={item} />
                                </BoxContainer>
                            </BoxContainer>
                        </BoxContainer>
                    </Header.Spacer>
                </Header.Main>
                <Header.Aside>
                    <ConfigureButton
                        item={item}
                        refetchTelematics={refetchTelematics}
                    />
                </Header.Aside>
            </Header.Block>
        </Header>
    )
}

export default IntegrationsUnitListHeader

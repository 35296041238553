import { useFormContext } from 'react-hook-form'

import { useUtilityDrawerContext } from './UtilityDrawer'
import UtilityDrawerCloseButtonBase, {
    UtilityDrawerCloseButtonBaseProps,
} from './UtilityDrawerCloseButtonBase'

export interface UtilityDrawerCancelButtonProps extends UtilityDrawerCloseButtonBaseProps {
    customAction?: () => void
}

const UtilityDrawerCancelButton = ({ customAction, ...props }: UtilityDrawerCancelButtonProps) => {
    const { forceClose } = useUtilityDrawerContext()
    const { reset, formState } = useFormContext()

    return (
        <UtilityDrawerCloseButtonBase
            disabled={formState.isSubmitting}
            {...props}
            onClick={
                customAction ??
                ((e) => {
                    props.onClick?.(e)
                    reset()
                    forceClose()
                })
            }
            children={props.children ?? 'Cancel'}
        />
    )
}

export default UtilityDrawerCancelButton

import { FC, ReactElement } from 'react'

import { Identifier, useListContext } from 'react-admin'

import { basePermissions } from 'configs/constants'
import { renderOnPermission } from 'hocs'

import { useOpenMetersDrawer } from '../hooks'

interface UnitMetersDrawerTogglerProps {
    id?: Identifier
    children: (params: { onClick: () => void; disabled?: boolean }) => ReactElement
    disabled?: boolean
}

const renderOnMeterPermission = (Component: FC<UnitMetersDrawerTogglerProps>) => {
    return renderOnPermission(Component, ({ props, permissions }) =>
        props.id ? permissions[basePermissions.update] : permissions[basePermissions.create],
    )
}

export const UnitMetersDrawerListToggler = renderOnMeterPermission(
    ({ children, id, disabled }: UnitMetersDrawerTogglerProps) => {
        const open = useOpenMetersDrawer()
        const listContext = useListContext()

        return children({
            disabled: disabled ?? Boolean(listContext.selectedIds.length),
            onClick: () => {
                open({
                    extraArgs: {
                        id,
                        listContext,
                    },
                })
            },
        })
    },
)

export const UnitMetersDrawerToggler = renderOnMeterPermission(
    ({ children, id }: Omit<UnitMetersDrawerTogglerProps, 'disabled'>) => {
        const open = useOpenMetersDrawer()

        return children({
            onClick: () => {
                open({
                    extraArgs: {
                        id,
                    },
                })
            },
        })
    },
)

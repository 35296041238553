import { AxiosError } from 'axios'
import { RaRecord } from 'react-admin'

import { AllProps } from 'appTypes'
import { Edit } from 'components'
import { EditCustomProps } from 'components/resource/Edit'
import { FinalErrorHandlerParams, useFinalErrorHandler } from 'hooks'

import { useUtilityDrawerContext } from './UtilityDrawer'
import { UtilityDrawerExtraProps } from './UtilityDrawerEditor'
import UtilityDrawerFormMakeSubmittable from './UtilityDrawerFormMakeSubmittable'
import UtilityDrawerWarnWhenUnsavedChanges from './UtilityDrawerWarnWhenUnsavedChanges'

export interface UtilityDrawerEditCustomProps<RecordType extends RaRecord = any>
    extends EditCustomProps<RecordType> {
    disableCloseOnSubmit?: boolean
    handleQueryErrors?: FinalErrorHandlerParams
}

type UtilityDrawerEditProps<RecordType extends RaRecord = any> =
    UtilityDrawerEditCustomProps<RecordType> &
        UtilityDrawerExtraProps & {
            record?: AllProps
            submittableAtBeginning?: boolean
        }

const UtilityDrawerEdit = <RecordType extends RaRecord = any>({
    disableCloseOnSubmit,
    submittableAtBeginning,
    handleQueryErrors,
    ...props
}: UtilityDrawerEditProps<RecordType>) => {
    const { forceClose, extraArgs } = useUtilityDrawerContext()
    const onSuccess = (params) => {
        if (!disableCloseOnSubmit) {
            forceClose()
        }
        props.onSuccess?.(params)
    }
    const errorHandler = useFinalErrorHandler()
    return (
        <Edit
            {...props}
            queryOptions={{
                ...props.queryOptions,
                onError: (err: AxiosError) => {
                    errorHandler(err, handleQueryErrors)
                    props.queryOptions?.onError?.(err)
                    if (err.status === 404) {
                        forceClose()
                    }
                },
            }}
            id={extraArgs!.id}
            onSuccess={onSuccess}
            warnWhenUnsavedChanges={false}
            children={
                <>
                    <UtilityDrawerWarnWhenUnsavedChanges />
                    {submittableAtBeginning && <UtilityDrawerFormMakeSubmittable />}
                    {props.children}
                </>
            }
        />
    )
}

export default UtilityDrawerEdit

import { useEffect, useRef } from 'react'

const useClickAway = (
    ref: ReturnType<typeof useRef<HTMLElement>>,
    onOutsideClick: () => void,
    { enabled = true } = {},
) => {
    useEffect(() => {
        if (!enabled) {
            return
        }

        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                onOutsideClick()
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [enabled])
}

export default useClickAway

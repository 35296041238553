import api from 'api'
import { AllProps } from 'appTypes'
import { WindowURL } from 'configs/constants'

export const fileType = (url: string): Lowercase<string> => {
    return url.split(/[#?]/)[0].split('.').pop().trim().toLowerCase() as Lowercase<string>
}

export const simpleDownload = ({ download, href }: { download: string; href: string }) => {
    const a = document.createElement('a')
    a.download = download
    a.href = href
    a.classList.add('d-none')
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
}

interface DownloadFileParams {
    href: string
    filename: string
    type: string
    headers?: AllProps
    queryParams?: AllProps
}

export const downloadFile = async ({
    href,
    filename,
    type,
    queryParams,
    headers,
}: DownloadFileParams) => {
    await api
        .get(href, queryParams, {
            responseType: 'blob',
            headers,
        })
        .then((blob) => {
            const newBlob = new Blob([blob], { type })

            const data = WindowURL.createObjectURL(newBlob)

            simpleDownload({ download: filename, href: data })

            setTimeout(() => {
                // For Firefox it is necessary to delay revoking the ObjectURL
                WindowURL.revokeObjectURL(data)
            }, 100)
        })
}

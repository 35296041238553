import { useChoicesContext, ChoicesContextValue } from 'react-admin'

const useReferenceInputTotal = () => {
    const choicesContext = useChoicesContext()

    return calcReferenceInputTotal(choicesContext)
}

export default useReferenceInputTotal

export const calcReferenceInputTotal = ({
    total,
    selectedChoices,
    availableChoices,
}: ChoicesContextValue) => {
    let finalTotal = total
    const selectedId = selectedChoices?.[0]?.id

    if (availableChoices.length === 0) {
        finalTotal = 0
    } else if (selectedId && !availableChoices.some((record) => record.id === selectedId)) {
        finalTotal -= 1
    }

    return finalTotal
}

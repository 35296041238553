import { useEffect, useState } from 'react'

import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import { InputAdornment } from '@mui/material'
import { MobileDateTimePicker, MobileDateTimePickerProps } from '@mui/x-date-pickers'
import { CalendarOrClockPickerView } from '@mui/x-date-pickers/internals/models'
import { DateInputProps as RADateInputProps, TextInputProps, useInput } from 'react-admin'

import { TextInput } from 'components'
import ClearAdornment from 'components/ClearAdornment'
import { useInputState } from 'components/context/InputStateContext'
import { formatDate as _format, dateFormatsObject } from 'utils'

type DateInputProps = {
    returnDateFormat?: string
    disableFuture?: boolean
} & RADateInputProps &
    Pick<MobileDateTimePickerProps<any, any>, 'views'>

const defaultViews: CalendarOrClockPickerView[] = ['year', 'month', 'day', 'hours', 'minutes']

const DateTimeInput = (props: DateInputProps) => {
    const {
        views = defaultViews,
        source: sourceProp,
        className,
        label,
        helperText,
        disableFuture = false,
        validate,
        variant = 'outlined',
        returnDateFormat = dateFormatsObject.serverDateTime,
        disabled,
    } = props

    const { field, isRequired } = useInput(props)
    const { source, ...state } = useInputState(sourceProp, { disabled })
    const [value, setValue] = useState<Date>(null)

    const fieldValue = field.value

    useEffect(() => {
        let newDate: Date | null = null
        if (fieldValue instanceof Date) {
            newDate = fieldValue
        } else {
            newDate = fieldValue ? new Date(String(fieldValue)) : null
        }
        setValue(newDate)
    }, [fieldValue])

    return (
        <MobileDateTimePicker
            className={className}
            label={label}
            views={views}
            disableFuture={disableFuture}
            onAccept={(newDate) => {
                try {
                    field.onChange(returnDateFormat ? _format(newDate, returnDateFormat) : newDate)
                } catch {
                    field.onChange(newDate)
                }
            }}
            value={value}
            onChange={(currentFieldValue) => {
                setValue(currentFieldValue)
            }}
            {...state}
            renderInput={({ inputRef, label, ...params }) => (
                <TextInput
                    focused={false}
                    validate={validate}
                    ref={inputRef}
                    {...params}
                    variant={variant}
                    source={source}
                    helperText={helperText}
                    label={label as TextInputProps['label']}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {!isRequired && Boolean(field.value) && (
                                    <ClearAdornment
                                        onClick={(event) => {
                                            event.stopPropagation()
                                            field.onChange(null)
                                        }}
                                    />
                                )}

                                <InputAdornment position="end">
                                    <CalendarMonthOutlinedIcon />
                                </InputAdornment>
                            </>
                        ),
                    }}
                />
            )}
        />
    )
}

DateTimeInput.defaultProps = {
    defaultValue: null,
}

export default DateTimeInput

import { ComponentProps } from 'react'

import { useChoicesContext, AutocompleteInput as RAutocompleteInput, RaRecord } from 'react-admin'

import { useInputState } from 'components/context/InputStateContext'
import { useCreateInputId } from 'hooks'

import { notFoundKey } from '../ReferenceInputCreateLayer'

import { CreateOption, ListBoxProps } from './ListBox'

const defaultMatchSuggestion = () => true
export interface AutocompleteInputBaseProps<RecordType extends RaRecord = any>
    extends ComponentProps<typeof RAutocompleteInput> {
    source?: Extract<keyof RecordType, string>
    handleScroll?: ListBoxProps['onScroll']
    renderCreateOption?: CreateOption
}
const AutocompleteInputBase = ({
    handleScroll,
    ListboxProps,
    renderCreateOption,
    ...props
}: AutocompleteInputBaseProps) => {
    const { source } = useChoicesContext({
        choices: props.choices,
    })

    const createId = useCreateInputId()
    const { source: finalSource, ...state } = useInputState(props.source || source || '', {
        disabled: props.disabled,
    })

    return (
        <RAutocompleteInput
            fullWidth
            defaultValue=""
            size="medium"
            variant="outlined"
            matchSuggestion={defaultMatchSuggestion}
            filterSelectedOptions={false}
            {...props}
            {...state}
            id={createId({ source: finalSource, id: props.id })}
            format={(value) => {
                const v = props.format ? props.format(value) : value
                return v ? v : ''
            }}
            ListboxProps={{
                onScroll: handleScroll,
                // @ts-ignore
                renderCreateOption,
                ...ListboxProps,
            }}
            {...(renderCreateOption && {
                getOptionDisabled: (record) => {
                    return record.id === notFoundKey || props.getOptionDisabled?.(record)
                },
                // inputText: (record) =>
                //     (record.id === notFoundKey ? props.noOptionsText : props.inputText?.(record)),
                // optionText: (record) => (
                //      record.id === notFoundKey
                //          ? props.noOptionsText
                //          : (typeof props.optionText === 'function' ? props.optionText(record) : props.optionText)),
            })}
        />
    )
}

AutocompleteInputBase.defaultProps = {
    noOptionsText: 'No options',
}
export default AutocompleteInputBase

import { inject, observer } from 'mobx-react'

import { SortType } from 'appTypes'
import { CK33Levels, CK33Model, PMReportsModel, UnitModel } from 'appTypes/models'
import {
    BoxContainer,
    CardListConfig,
    DatagridColumnsProps,
    List,
    ListBase,
    PageContent,
    Typography,
    formatCK33CodeByLevel,
    GetCk33InfoArgs,
    getCk33Info,
} from 'components'
import { ListSortContentProps } from 'components/list/ListSortDrawerContent'
import ListFilterDateRangeValueInput from 'components/list/filter/ListFilterDateRangeValueInput'
import { FilterConfig } from 'components/list/filter/ListFilterForm'
import ListFilterNoResults from 'components/list/filter/ListFilterNoResults'
import ListFilterValueInput, {
    prepareFilterResource,
} from 'components/list/filter/ListFilterValueInput'
import ResourceContextProviderWithClearEffect from 'components/resource/ResourceContextProviderWithClearEffect'
import { urls } from 'configs/config'
import { unitFiltersCfg } from 'pages/Units/List/UnitsList'
import { getMeterTypeAdornment } from 'pages/Units/Meters/constants'
import { getLeft } from 'pages/Units/PM/UnitPMCard/BottomSection'
import { pmResource } from 'pages/Units/config/constants'
import { AuthStore } from 'providers/authStore'
import { capitalize, capitalizeWithLowerCase, formatDate, resolveIntegerSpacedMask } from 'utils'

import { ReportsHeader } from '../components'
import IntervalsShowMoreButton, { StatusSection } from '../components/IntervalsShowMoreButton'
import LinkArrowButton from '../components/LinkArrowButton'
import LinkCardButton from '../components/LinkCardButon'
import { newNames } from '../utils'

const pmCk33Label = (auth: GetCk33InfoArgs) => getCk33Info(auth, CK33Levels.COMPONENT).label
const PmCk33Label = inject('auth')(
    observer(({ auth }: { auth?: AuthStore }) => <>{pmCk33Label(auth)}</>),
)

const defaultSort: SortType = {
    field: 'status',
    order: 'DESC',
}
const timeIntervalAdornment = (value: number, adornment: string) => {
    if (value === 1) {
        return adornment.toLocaleLowerCase()
    }
    return adornment.toLocaleLowerCase() + 's'
}
const sortCfg: ListSortContentProps<
    PMReportsModel & { unit: string; workOrder: string; dependency: string; status: string }
> = {
    sortBy: [
        { id: 'name', label: 'Name' },
        { id: 'unit', label: 'Unit Number' },
        {
            id: 'component',
            label: <PmCk33Label />,
        },
        { id: 'reasonForRepair', label: 'Reason for Repair' },
        { id: 'workOrder', label: 'WOs Attached' },
        { id: 'dependency', label: 'Dependency ' },
        { id: 'lastDone', label: 'Last Done Date' },
        { id: 'status', label: 'Status' },
    ],
}
const filtersCfg: FilterConfig<
    PMReportsModel & UnitModel & { unit: string; workOrder: string; dependency: string }
> = {
    filters: [
        {
            id: 'name',
            label: 'Name',
        },
        {
            id: 'unit',
            label: 'Unit Number',
        },
        {
            id: 'component',
            label: <PmCk33Label />,
            renderComponent: (props) => (
                <ListFilterValueInput
                    dataProviderProps={{
                        getList: {
                            prepareResource: prepareFilterResource,
                        },
                        getMany: {
                            prepareResource: () => 'vmrs/ck33',
                        },
                    }}
                    {...props}
                    makeItemLabel={(record: CK33Model) =>
                        formatCK33CodeByLevel(record.code, record.level) + ' ' + record.text
                    }
                />
            ),
        },
        {
            id: 'reasonForRepair',
            label: 'Reason for Repair',
        },
        {
            id: 'workOrder',
            label: 'WOs Attached',
        },
        {
            id: 'dependency',
            label: 'Dependency ',
            makeItemLabel: ({ id }) =>
                typeof id === 'string' ? capitalizeWithLowerCase(id as string) : `Position ${id}`,
        },
        {
            id: 'lastDone',
            label: 'Last Done Date',
            filterType: 'range',
            renderComponent: (props) => <ListFilterDateRangeValueInput {...props} />,
        },
        {
            id: 'status',
            label: 'Status',
            makeItemLabel: ({ id }) => {
                const label = capitalizeWithLowerCase(id as string)
                return label === 'Due' ? label + ' Soon' : label
            },
        },
    ],
}
const includedFiltersMap: (keyof UnitModel)[] = [
    'name',
    'vin',
    'licensePlate',
    'model',
    'modelYear',
    'engineModel',
    'engineHp',
    'transmissionModel',
    'transmissionGears',
    'color',
    'tireSize',
    'status',
    'created',
    'engineVmrsManufacturer',
    'transmissionVmrsManufacturer',
    'vmrsEquipmentCategory',
    'vmrsManufacturer',
]

filtersCfg.filters.push(
    ...unitFiltersCfg.filters
        .filter((item) => includedFiltersMap.some((id) => id === item.id))
        .map((item) => ({
            ...item,
            id: ('unit' + capitalize(item.id)) as keyof UnitModel,
            label: newNames[item.id] || item.label,
        })),
)

const navigateTo = (id: string) => `${urls.units}/${id}/pm`

const PMIntervals = inject('auth')(
    observer(({ auth }: { auth?: AuthStore }) => {
        const columnsCfg: DatagridColumnsProps<
            PMReportsModel & {
                lastService: string
                nextService: string
                left: string
            }
        > = {
            checkboxSelection: false,
            columns: [
                {
                    field: 'name',
                    headerName: 'Name',
                    renderCell: ({ row }) => (
                        <LinkCardButton
                            text={row.name}
                            to={navigateTo(row.unitData.id)}
                        />
                    ),
                },
                {
                    field: 'unitData',
                    headerName: 'Unit Number',
                    renderCell: ({ value }) => (
                        <LinkCardButton
                            text={value.number}
                            to={`${urls.units}/${value.id}`}
                        />
                    ),
                },
                {
                    field: 'componentData',
                    headerName: pmCk33Label(auth),
                    valueFormatter: ({ value }) =>
                        value?.code
                            ? `${formatCK33CodeByLevel(value.code, value.level)} ${value.text}`
                            : '',
                },
                {
                    field: 'reasonForRepairData',
                    headerName: 'Reason for Repair',
                    valueFormatter: ({ value }) => value?.description || '',
                },
                {
                    field: 'workOrderData',
                    headerName: 'WOs Attached',
                    renderCell: ({ value }) =>
                        value && (
                            <LinkCardButton
                                text={value.number}
                                to={`${urls.workOrders}/${value.id}`}
                            />
                        ),
                },
                {
                    field: 'rank',
                    headerName: 'Dependency',
                    valueFormatter: ({ value }) => (value ? `Position ${value}` : 'Independent'),
                },
                {
                    field: 'lastDone',
                    headerName: 'Last Done Date',
                    valueFormatter: ({ value }) =>
                        formatDate(value, (dateFormats) => dateFormats.shortenedDate),
                },
                {
                    field: 'intervals',
                    headerName: 'Interval',
                    renderCell: ({ value, row }) => (
                        <BoxContainer
                            component="span"
                            gap="10px"
                        >
                            <Typography
                                component="span"
                                color="inherit"
                                variant="inherit"
                            >
                                {`${resolveIntegerSpacedMask(value[0].value)} ${
                                    value[0].type === 'TIME'
                                        ? timeIntervalAdornment(value[0].value, value[0].valueType)
                                        : getMeterTypeAdornment(value[0].type)
                                }`}
                            </Typography>
                            <IntervalsShowMoreButton record={row} />
                        </BoxContainer>
                    ),
                    minWidth: 120,
                },

                {
                    field: 'lastService',
                    headerName: 'Last Service',
                    renderCell: ({ row }) =>
                        row.intervals[0].type === 'TIME'
                            ? formatDate(row.lastDone, (dateFormats) => dateFormats.shortenedDate)
                            : `${resolveIntegerSpacedMask(
                                  row.intervals[0].lastDoneMeterValue,
                              )} ${getMeterTypeAdornment(row.intervals[0].type)}`,
                },
                {
                    field: 'nextService',
                    headerName: 'Next Service',
                    renderCell: ({ row }) =>
                        row.intervals[0].type === 'TIME'
                            ? formatDate(
                                  row.intervals[0].overdue,
                                  (dateFormats) => dateFormats.shortenedDate,
                              )
                            : `${resolveIntegerSpacedMask(
                                  row.intervals[0].overdue,
                              )} ${getMeterTypeAdornment(row.intervals[0].type)}`,
                },
                {
                    field: 'left',
                    headerName: 'Left',
                    renderCell: ({ row }) => {
                        const left = getLeft(row.intervals[0])
                        return (
                            <BoxContainer
                                textOverflow="ellipsis"
                                overflow="hidden"
                                whiteSpace="nowrap"
                                gap="5px"
                            >
                                <Typography
                                    overflow="hidden"
                                    color="inherit"
                                    variant="inherit"
                                >
                                    {left} {getMeterTypeAdornment(row.intervals[0].type)}
                                </Typography>
                                {row.intervals[0].status === 'OVERDUE' && left[0] !== '0'
                                    ? 'overdue'
                                    : ''}
                            </BoxContainer>
                        )
                    },
                },
                {
                    field: 'mostUrgentStatus',
                    headerName: 'Status',
                    renderCell: ({ row }) => (
                        <StatusSection
                            gap="6px"
                            status={row.intervals[0].status}
                            colorful
                        />
                    ),
                },
            ],
            actions: null,
        }
        const cardsCfg: CardListConfig<PMReportsModel> = {
            titleSource: 'name',
            defaultImage: null,
            titleLink: (record) => navigateTo(record.unitData.id),
            details: [
                {
                    source: 'unitData',
                    label: 'Unit Number',
                    render: (value) => (
                        <LinkCardButton
                            text={value.number}
                            to={`${urls.units}/${value.id}`}
                        />
                    ),
                },
                {
                    source: 'componentData',
                    label: pmCk33Label(auth),
                    render: (value) =>
                        value?.code
                            ? `${formatCK33CodeByLevel(value.code, value.level)} ${value.text}`
                            : '',
                },
                {
                    source: 'reasonForRepairData',
                    label: 'Reason for Repair',
                    render: (value) => value?.description || '',
                },
                {
                    source: 'workOrderData',
                    label: 'WOs Attached',
                    render: (value) =>
                        value && (
                            <LinkCardButton
                                text={value.number}
                                to={`${urls.workOrders}/${value.id}`}
                            />
                        ),
                },
                {
                    source: 'rank',
                    label: 'Dependency',
                    render: (value) => (value ? `Position ${value}` : 'Independent'),
                },
                {
                    source: 'lastDone',
                    label: 'Last Done Date',
                    render: (value) =>
                        formatDate(value, (dateFormats) => dateFormats.shortenedDate),
                },
                {
                    source: 'intervals',
                    label: 'Interval',
                    render: (value, record) => (
                        <BoxContainer
                            component="span"
                            gap="10px"
                        >
                            <Typography component="span">
                                {`${resolveIntegerSpacedMask(value[0].value)} ${
                                    value[0].type === 'TIME'
                                        ? timeIntervalAdornment(value[0].value, value[0].valueType)
                                        : getMeterTypeAdornment(value[0].type)
                                }`}
                            </Typography>
                            <IntervalsShowMoreButton record={record} />
                        </BoxContainer>
                    ),
                },
                {
                    source: 'intervals',
                    label: 'Last Service',
                    render: (value, record) =>
                        value[0].type === 'TIME'
                            ? formatDate(
                                  record.lastDone,
                                  (dateFormats) => dateFormats.shortenedDate,
                              )
                            : `${resolveIntegerSpacedMask(
                                  value[0].lastDoneMeterValue,
                              )} ${getMeterTypeAdornment(value[0].type)}`,
                },
                {
                    source: 'intervals',
                    label: 'Next Service',
                    render: (value) =>
                        value[0].type === 'TIME'
                            ? formatDate(
                                  value[0].overdue,
                                  (dateFormats) => dateFormats.shortenedDate,
                              )
                            : `${resolveIntegerSpacedMask(
                                  value[0].overdue,
                              )} ${getMeterTypeAdornment(value[0].type)}`,
                },
                {
                    source: 'intervals',
                    label: 'Left',
                    render: (value) => {
                        const left = getLeft(value[0])
                        return `${left} ${getMeterTypeAdornment(value[0].type)} ${
                            value[0].status === 'OVERDUE' && left[0] !== '0' ? 'overdue' : ''
                        }
            `
                    },
                },
                {
                    source: 'intervals',
                    label: 'Status',
                    render: (value) => (
                        <StatusSection
                            gap="6px"
                            status={value[0].status}
                            colorful
                        />
                    ),
                },
            ],
            action: (record) => <LinkArrowButton path={navigateTo(record.unitData.id)} />,
        }

        return (
            <ResourceContextProviderWithClearEffect value={pmResource}>
                <ListBase sort={defaultSort}>
                    <ReportsHeader>PM Intervals</ReportsHeader>
                    <PageContent>
                        <List
                            exportFileName="pm-intervals"
                            renderNoResults={() => (
                                <ListFilterNoResults
                                    disableFilter
                                    subtitle={
                                        <>
                                            No results match your criteria.
                                            <br />
                                            Try modifying the search or filters
                                        </>
                                    }
                                />
                            )}
                            filtersCfg={filtersCfg}
                            sortCfg={sortCfg}
                            columnsCfg={columnsCfg}
                            cardsCfg={cardsCfg}
                        />
                    </PageContent>
                </ListBase>
            </ResourceContextProviderWithClearEffect>
        )
    }),
)

export default PMIntervals

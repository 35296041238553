import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import { styled, SvgIconProps, SxProps, Theme } from '@mui/material'
import { Link, LinkProps } from 'react-router-dom'

import { IconBox } from 'components'

export interface BackButtonProps {
    linkProps?: Partial<LinkProps>
    iconProps?: SvgIconProps
    sx?: SxProps<Theme>
}
const StyledLink = styled(Link)``
const BackButton = ({ linkProps, iconProps, sx }: BackButtonProps) => {
    return (
        <StyledLink
            aria-label="Go back"
            to=".."
            {...linkProps}
            sx={sx}
        >
            <IconBox>
                <ArrowBackIosNewIcon
                    sx={{ height: 19 }}
                    {...iconProps}
                />
            </IconBox>
        </StyledLink>
    )
}

export default BackButton

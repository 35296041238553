import { DateInput, SelectInput, TextInput } from 'components'
import { useUtilityDrawerContext } from 'components/Drawer/UtilityDrawer'
import { vendorPaymentMethods } from 'pages/Vendors/constants/config'
import { WorkOrderItemsExtra } from 'pages/WorkOrders/Jobs/config/constants'
import { costNonNegativeMaskParams, inputpaymentCheckMaskparams, requiredValidation } from 'utils'

const PaymentForm = () => {
    const { extra } = useUtilityDrawerContext()
    const { disabledFields: disabled } = (extra || {}) as WorkOrderItemsExtra

    return (
        <>
            <TextInput
                source="amount"
                label="Amount Paid"
                disabled={disabled}
                validate={requiredValidation}
                {...costNonNegativeMaskParams}
            />

            <DateInput
                source="date"
                label="Payment Date"
                disabled={disabled}
                defaultValue={new Date()}
                validate={requiredValidation}
            />

            <SelectInput
                source="method"
                label="Payment Method"
                disabled={disabled}
                disableEmptyValue
                defaultValue={extra.paymentMethod}
                choices={vendorPaymentMethods}
            />
            <TextInput
                source="number"
                disabled={disabled}
                label="Payment/Check Number"
                {...inputpaymentCheckMaskparams}
            />
        </>
    )
}
export default PaymentForm

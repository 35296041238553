import { Box } from '@mui/material'

import { Invoice } from 'appTypes/models'
import {
    IconBox,
    InfoCard,
    InfoCardDetails,
    InfoCardHeader,
    LinkButton,
    StatusText,
    Typography,
} from 'components'
import Icons from 'components/icons'
import { formatDate, resolveDoublePriceMask } from 'utils'

const PymentHistoryCard = ({ invoice }: { invoice: Invoice }) => {
    const { status, id, number, created, total, invoicePdf } = invoice
    return (
        <InfoCard sx={{ height: '100%' }}>
            <InfoCardHeader
                title={
                    <Box
                        display="flex"
                        alignItems="center"
                        width="100%"
                        minHeight="24px"
                    >
                        <Typography
                            variant="subtitle2"
                            color={(theme) => theme.palette.text.primary}
                        >
                            {number}
                        </Typography>
                        <Box
                            display="flex"
                            alignItems="center"
                            ml="auto"
                        >
                            {(status === 'open' || status === 'paid') && (
                                <StatusText
                                    statusType={status === 'open' ? 'error' : 'success'}
                                    ml="auto"
                                    mr="16px"
                                >
                                    {status === 'open' ? 'Payment issue' : 'Paid'}
                                </StatusText>
                            )}
                            {invoicePdf && (
                                <LinkButton
                                    href={invoicePdf}
                                    download
                                >
                                    <IconBox title="Download">
                                        <Icons.Export />
                                    </IconBox>
                                </LinkButton>
                            )}
                        </Box>
                    </Box>
                }
            />
            <InfoCardDetails<Pick<Invoice, 'created' | 'total' | 'id'>>
                details={[
                    {
                        label: 'Date',
                        source: ({ created }) =>
                            formatDate(created, (dateFormats) => dateFormats.shortenedDate),
                    },
                    {
                        label: 'Total',
                        source: ({ total }) => resolveDoublePriceMask(total),
                    },
                ]}
                propRecord={{ created, total, id }}
            />
        </InfoCard>
    )
}

export default PymentHistoryCard

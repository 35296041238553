import { FC } from 'react'

import { CalendarToday } from '@mui/icons-material'
import { alpha, styled, useTheme } from '@mui/material'

// import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { BoxContainer, Spacer } from 'components'
import Icons from 'components/icons'
import { integerNonNegativeSpacedMaskResolver } from 'utils'
const StyledSpan = styled('span', {
    shouldForwardProp: (prop) => prop !== 'tint',
})<{ tint?: string }>(({ tint }) => ({
    color: tint,
    padding: '5px',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'cennter',
    borderRadius: '4px',
    background: alpha(tint, 0.15),
}))
export interface SinceLastServiceTrackWidgetLabelProps {
    milesSinceLastService: number | null
    timeSinceLastService: number | null
}
const Label: FC<SinceLastServiceTrackWidgetLabelProps> = ({
    milesSinceLastService,
    timeSinceLastService,
}) => {
    const theme = useTheme()

    return (
        <Spacer variant="md">
            <BoxContainer gap="6px">
                <StyledSpan tint={theme.palette.charts.greenBody}>
                    <CalendarToday
                        sx={{
                            width: '14px',
                            height: '14px',
                        }}
                    />
                </StyledSpan>
                {Math.floor(timeSinceLastService)} Days
            </BoxContainer>
            <BoxContainer gap="6px">
                <StyledSpan tint={theme.palette.charts.purple}>
                    <Icons.Odometer
                        sx={{
                            width: '14px',
                            height: '14px',
                        }}
                    />
                </StyledSpan>
                {milesSinceLastService
                    ? `${integerNonNegativeSpacedMaskResolver(milesSinceLastService)} mi`
                    : null}
            </BoxContainer>
        </Spacer>
    )
}
export default Label

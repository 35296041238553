import { Link } from 'react-router-dom'

import { Button } from 'components'
import Icons from 'components/icons'
const ConnectButton = ({ navigateTo }: { navigateTo: string }) => {
    return (
        <Button
            startIcon={<Icons.Add />}
            size="small"
            component={Link}
            to={navigateTo}
        >
            Connect
        </Button>
    )
}
export default ConnectButton

import { ReactElement } from 'react'

import { useElements, useStripe } from '@stripe/react-stripe-js'
import { SaveButtonProps } from 'react-admin'

import { UtilityDrawerCancelButtonProps } from 'components'

import { useCheckoutFormIsLoading } from './PaymentFormWrapper'

export const PaymentFormSubmitButton = ({
    render,
}: {
    render: (params: SaveButtonProps) => ReactElement
}) => {
    const stripe = useStripe()
    const elements = useElements()
    const isLoading = useCheckoutFormIsLoading()
    const disabled: boolean = !stripe || !elements || isLoading

    return render({
        label: 'Add Payment Method',
        alwaysEnable: !disabled,
        disabled,
        saving: isLoading,
    })
}

export const PaymentFormCancelButton = ({
    render,
}: {
    render: (params: UtilityDrawerCancelButtonProps) => ReactElement
}) => {
    const stripe = useStripe()
    const elements = useElements()
    const isLoading = useCheckoutFormIsLoading()
    const disabled: boolean = !stripe || !elements || isLoading

    return render({ disabled })
}

import { ButtonProps } from '@mui/material'
import { useFormState } from 'react-hook-form'

import { Button } from 'components'

const CancelButton = (props: ButtonProps) => {
    const { isSubmitting } = useFormState()

    return (
        <Button
            size="medium"
            disabled={isSubmitting}
            type="button"
            children="Cancel"
            {...props}
        />
    )
}
export default CancelButton

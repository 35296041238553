import { Palette } from '@mui/material'

import { JobItemsCalculations, JobItemTotals } from '../components/types'

export type maxTotalReasonsType = {
    code: string
    description: string
    type: string
    id: number
    total: number
    totalPercent: number
} & JobItemTotals

export interface ResponseType extends JobItemsCalculations {
    maxTotalReasonsForRepair: maxTotalReasonsType[]
}
export interface QueryResponse extends Omit<ResponseType, 'maxTotalReasonsForRepair'> {
    list: listType
}
export interface listType {
    data: maxTotalReasonsType[]
    top3Percentage: number
    top3Total: number
    sumOfExcludedReasons: number
    sumPercentageOfExcludedReasons: number
}

type Top3ReasonsColorProps = keyof Palette['charts']
export const statusColors: Top3ReasonsColorProps[] = ['red', 'yellowBody', 'green']

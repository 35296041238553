import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'
import TagManager from 'react-gtm-module'

const env = {
    get(name, defaultValue) {
        return process.env[`REACT_APP_${name}`] || defaultValue
    },
    str(name, defaultValue) {
        return this.get(name, defaultValue)
    },
    bool(name, defaultValue) {
        return this.parse(name, defaultValue)
    },
    parse(name, defaultValue) {
        const val = this.get(name)
        return val ? JSON.parse(val) : defaultValue
    },
}

const DEBUG = env.bool('DEBUG', false)

const LAUNCH_DARKLY_CLIENT_ID = env.get('LAUNCH_DARKLY_CLIENT_ID', '')

const API_ENDPOINT = env.get('API_ENDPOINT', 'http://localhost:8000')

const VERSION = env.get('VERSION', '')

const ENVIRONMENT = env.get('ENVIRONMENT', 'local')

const API_TOKEN_KEY = 'api_token'

const VAPID_PUBLIC_KEY = env.get('VAPID_PUBLIC_KEY', '')

const GOOGLE_CLIENT_ID = env.get('GOOGLE_CLIENT_ID', '')

export const STRIPE_PUBLIC_KEY = env.get('STRIPE_PUBLIC_KEY', '')

export const MUIX_KEY = env.get('MUIX_KEY', '')

export const GOOGLE_MAP_API_KEY = env.get('GOOGLE_MAP_API_KEY', '')

const GOOGLE_TAG_MANAGER_ID = env.get('GOOGLE_TAG_MANAGER_ID', '')
if (GOOGLE_TAG_MANAGER_ID) {
    TagManager.initialize({ gtmId: GOOGLE_TAG_MANAGER_ID })
}
const intercomKey = env.get('INTERCOM_KEY', '')

export const supportLauncherSelector = 'support-launcher'

if (intercomKey) {
    window.intercomSettings = {
        // eslint-disable-next-line camelcase
        api_base: 'https://api-iam.intercom.io',
        // eslint-disable-next-line camelcase
        app_id: intercomKey,
        // eslint-disable-next-line camelcase
        custom_launcher_selector: '.' + supportLauncherSelector,
    }
}

const sentryDsn = env.get('SENTRY_DSN', '')
let _sentry: typeof Sentry | null = null
if (sentryDsn) {
    _sentry = Sentry
    _sentry.init({
        dsn: sentryDsn,
        release: VERSION,
        autoSessionTracking: false,
        integrations: [new Integrations.BrowserTracing()],
        environment: ENVIRONMENT,

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    })
}
const SENTRY = _sentry

export const SAMSARA_APP_ID = env.get('SAMSARA_APP_ID', '')
export const MOTIVE_APP_ID = env.get('MOTIVE_APP_ID', '')

export const landingPageUrl = 'https://fleetpal.io'

export const urls = {
    root: '/',
    register: '/registration',
    registerConfirm: '/registration-confirm/:key',
    registerConfirmEmail: '/registration-email-confirm',
    login: '/login',
    logout: '/logout',
    setPassword: '/set-password',
    passwordReset: '/reset-password',
    passwordResetDone: '/reset-password/done',
    passwordResetConfirm: '/reset-password/:uid/:token',
    userProfile: '/profile',
    tos: 'https://www.iubenda.com/terms-and-conditions/56685224',

    units: '/units',
    unitsArchived: '/units/archived',
    workOrders: '/work-orders',
    configure: '/configure',
    members: '/members',
    company: '/company',
    changePlan: '/change-plan',
    parts: '/parts',
    invitationsExpired: '/invitations/expired',
    invitationsAccept: '/invitations/accept/:token',
    invitationAuth: '/invitations/auth/:token',
    deactivate: '/deactivate',
    deactivated: '/deactivated',
    paymentHistory: '/payment-history',

    error404: '/error404',
    blank: '/notfound',
    reports: '/reports',
    vendors: '/vendors',
}

const all = {
    urls,
    DEBUG,
    API_ENDPOINT,
    API_TOKEN_KEY,
    VERSION,
    VAPID_PUBLIC_KEY,
    GOOGLE_CLIENT_ID,
    SENTRY,
    LAUNCH_DARKLY_CLIENT_ID,
    SAMSARA_APP_ID,
    MOTIVE_APP_ID,
}

export default all

export const supportEmail = 'support@fleetpal.io'

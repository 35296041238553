import { FC } from 'react'

import { Box } from '@mui/material'

import { BoxContainer, Status, Typography, EllipsisOverflow } from 'components'
import { useWidgetContext } from 'pages/Dashboard/components/GridLayout'
import { WidgetListContainer, WidgetListFTU } from 'pages/Dashboard/components/WidgetBase/styled'
import LineItemsTooltip from 'pages/WorkOrders/Jobs/WorkOrderJobCard/components/LineItemsTooltip'
import { resolveDoublePriceMask } from 'utils'

import { ListItem } from '../../components'
import { listType, statusColors } from '../types'

interface CostPerRepairListProps {
    list: listType
    description: string
    isValid: boolean
}
const CostPerRepairList: FC<CostPerRepairListProps> = ({ list, description, isValid }) => {
    const dimensions = useWidgetContext()

    const { data } = list

    if (!isValid) {
        return <WidgetListFTU description={description} />
    }

    const List = data.map(({ id, description, type, total, code, ...ratioData }, i) => {
        if (total == null) {
            return null
        }
        return (
            <Typography
                component={ListItem}
                variant="chartListItem"
                key={`${description}-${i}`}
            >
                <BoxContainer
                    sx={{
                        whiteSpace: 'nowrap',
                        pr: '10px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    <Box marginRight="13px">
                        <Status
                            size="8px"
                            iconColor={(theme) => theme.palette.charts[statusColors[i]]}
                        />
                    </Box>
                    <Box>{`${code ? code.substring(0, 3) : ''} ${description}`}</Box>
                    {dimensions !== 'mdY' && (
                        <Typography
                            component={EllipsisOverflow}
                            color="text.disabled"
                            variant="chartListItem"
                            ml="8px"
                            lineHeight="150%"
                        >
                            {type}
                        </Typography>
                    )}
                </BoxContainer>

                <LineItemsTooltip
                    data={ratioData}
                    allWhite
                >
                    {resolveDoublePriceMask(total)}
                </LineItemsTooltip>
            </Typography>
        )
    })
    return <WidgetListContainer>{List}</WidgetListContainer>
}
export default CostPerRepairList

import { FC } from 'react'

import { AvTimerOutlined, CalendarTodayOutlined } from '@mui/icons-material'

import { MeterModel } from 'appTypes/models'
import { ReactComponent as HubometerIcon } from 'assets/images/hubometer.svg'
import Icons from 'components/icons'
import { Serializer } from 'utils'

export const meterSourceMap: { [key in MeterModel['source']]: string } = {
    SAMSARA: 'Samsara',
    MOTIVE: 'Motive',
    WORK_ORDER: 'Work Order',
    PM_SCHEDULE: 'PM Schedule',
    MANUAL: 'Manual',
}

export const meterTypesConfig: { [key in MeterConfig['id']]: MeterConfig } = {
    ENGINE_HOURS: {
        Icon: AvTimerOutlined,
        name: 'Engine Hours',
        id: 'ENGINE_HOURS',
        adornment: 'h',
    },
    HUBOMETER: { Icon: HubometerIcon, name: 'Hubometer', id: 'HUBOMETER', adornment: 'mi' },
    ODOMETER: { Icon: Icons.Odometer, name: 'Odometer', id: 'ODOMETER', adornment: 'mi' },
    TIME: { Icon: CalendarTodayOutlined, name: 'Time', id: 'TIME', adornment: '' },
}

export const getMeterTypeAdornment = (type: MeterConfig['id']) => {
    return meterTypesConfig[type]?.adornment
}

export const meterTypeChoices: MeterConfig[] = [
    meterTypesConfig.ODOMETER,
    meterTypesConfig.ENGINE_HOURS,
    meterTypesConfig.HUBOMETER,
]

export const meterSerializer: Serializer<MeterModel & { timestamp: string }> = [
    'type',
    { name: 'value', parse: 'number' },
    { name: 'timestamp', parse: 'dateTime' },
]

interface MeterConfig {
    Icon: FC
    name: string
    id: MeterModel['type'] | 'TIME'
    adornment: string
}

export const getMeterIcon = (type: MeterConfig['id']) => {
    const Comp = meterTypesConfig[type]
    if (!Comp) {
        return null
    }
    return <Comp.Icon />
}

import { Alert, Box } from '@mui/material'
import { inject, observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'

import {
    StyledElement,
    GridContainerColumns,
    GridItem,
    locationStateBlockerForceRedirect,
    PageContent,
    StatusText,
    Typography,
    Button,
} from 'components'
import { landingPageUrl, urls } from 'configs/config'
import { useNotify, useConfirm, useFinalErrorHandler } from 'hooks'
import CurrentPlan from 'pages/Company/CompanyInfo/CurrentPlan/CurrentPlan'
import { AuthStore } from 'providers/authStore'
import { resolveDoublePriceMask } from 'utils'

import { ChangePlanHeader, EnterpricePlan, ProPlan } from './components'

const ChangePlan = inject('auth')(
    observer(({ auth }: { auth?: AuthStore }) => {
        const subscription = auth.billing.subscription
        const isEarchAccess = subscription === 'free'
        const confirm = useConfirm()
        const notify = useNotify()
        const navigate = useNavigate()
        const errorHandler = useFinalErrorHandler()

        // TODO: refactor CurrentPlan button props
        return (
            <>
                <ChangePlanHeader />
                <PageContent>
                    <GridContainerColumns rowSpacing="12px">
                        <GridItem md={4}>
                            <CurrentPlan
                                topTitle={
                                    isEarchAccess ? undefined : (
                                        <StatusText
                                            statusColor={(theme) => theme.palette.text.primary}
                                        >
                                            Limited Work Orders
                                        </StatusText>
                                    )
                                }
                                subscription="free"
                                buttonProps={{
                                    component: isEarchAccess ? undefined : 'div',
                                    to: undefined,
                                    children: isEarchAccess ? 'Current plan' : 'Downgrade',
                                    disabled: isEarchAccess,
                                    onClick: isEarchAccess
                                        ? undefined
                                        : () => {
                                              confirm({
                                                  awaitOnConfirm: true,
                                                  fullPageLoader: true,
                                                  title: 'Are you sure you want to Downgrade?',
                                                  content: (
                                                      <>
                                                          <Typography
                                                              variant="body2"
                                                              color="text.primary"
                                                              mb="12px"
                                                          >
                                                              Downgrading will trigger immediate
                                                              charge with the amount of your current
                                                              bill and will remove your payment
                                                              method. It won’t delete your current
                                                              Work Orders, Units or Parts.
                                                          </Typography>
                                                          <Alert severity="info">
                                                              You'll be charged the amount of your
                                                              current bill{' '}
                                                              <StyledElement
                                                                  as="span"
                                                                  sx={{
                                                                      fontWeight: 'bold',
                                                                  }}
                                                              >
                                                                  {resolveDoublePriceMask(
                                                                      auth.billing
                                                                          .toBePaidWorkOrdersForPeriodTotal,
                                                                  )}
                                                              </StyledElement>
                                                          </Alert>
                                                      </>
                                                  ),
                                                  onConfirm: async () => {
                                                      try {
                                                          await auth.downgradeSubscription()
                                                          notify('Successfully downgraded', {
                                                              type: 'success',
                                                          })
                                                      } catch (error) {
                                                          errorHandler(error)
                                                      }
                                                  },
                                                  confirmButtonProps: {
                                                      children: 'Downgrade',
                                                  },
                                                  onClose: (accepted) => {
                                                      if (accepted) {
                                                          navigate(urls.company, {
                                                              state: locationStateBlockerForceRedirect,
                                                          })
                                                      }
                                                  },
                                              })
                                          },
                                }}
                            />
                        </GridItem>
                        <GridItem md={4}>
                            <ProPlan />
                        </GridItem>
                        <GridItem md={4}>
                            <EnterpricePlan />
                        </GridItem>
                    </GridContainerColumns>
                    <Box
                        mt="24px"
                        textAlign="center"
                    >
                        <a
                            href={landingPageUrl + '/pricing'}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Button
                                variant="text"
                                size="small"
                            >
                                See Full Comparison
                            </Button>
                        </a>
                    </Box>
                </PageContent>
            </>
        )
    }),
)

export default ChangePlan

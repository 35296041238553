import { useRef } from 'react'

import { useReferenceInputInfiniteScroll } from 'hooks'

import AutocompleteInputBase, { AutocompleteInputBaseProps } from './AutocompleteInputBase'
import ListBox, { ExtendedListBoxProps } from './ListBox'

export interface AutocompleteInputProps extends AutocompleteInputBaseProps {
    ListboxProps?: ExtendedListBoxProps &
        AutocompleteInputBaseProps['ListboxProps'] & { ref?: (node: any) => void }
}

const InfiniteAutocompleteInput = (props: AutocompleteInputProps) => {
    const { loadMoreResults, reset, choicesController } = useReferenceInputInfiniteScroll({
        multiple: props.multiple,
    })
    const { filterValues } = choicesController || {}
    const listboxRef = useRef()

    const handleScroll = (event) => {
        const listboxNode = event.currentTarget
        const position = listboxNode.scrollTop + listboxNode.clientHeight

        if (listboxNode.scrollHeight - position <= 1) {
            loadMoreResults()
        }
    }

    return (
        <AutocompleteInputBase
            {...props}
            {...(!props.choices && {
                handleScroll,
                onClose: () => {
                    // the input will reset itself if filter applied
                    if ('q' in filterValues) {
                        return
                    }
                    reset()
                },
            })}
            ListboxComponent={ListBox}
            ListboxProps={{ ref: listboxRef, ...props.ListboxProps }}
            defaultValue={props.defaultValue ?? (props.multiple ? [] : null)}
        />
    )
}

export default InfiniteAutocompleteInput

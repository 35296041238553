import { ReactNode, ReactElement } from 'react'

import { useArchivedContext } from 'components'

interface HideArchivedProps {
    children?: ReactNode
    replaceWith?: ReactElement
}

const HideArchived = ({ children, replaceWith }: HideArchivedProps) => {
    const isArchived = useArchivedContext()

    if (!isArchived) {
        return <>{children}</>
    }

    return replaceWith
}

export default HideArchived

import React, { FC, ReactNode, createContext, useContext, useState } from 'react'

import { MeterModel } from 'appTypes/models'

export type PMLastMetersContextResult = {
    meters: {
        [key in MeterModel['type']]?: MeterModel | null
    }
    setMeters: React.Dispatch<React.SetStateAction<PMLastMetersContextResult['meters']>>
}

const PMLastMetersContext = createContext<PMLastMetersContextResult>(null)

export const usePMLastMetersContext = () => useContext(PMLastMetersContext)

export const PMLastMetersContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [meters, setMeters] = useState<PMLastMetersContextResult['meters']>({})

    return (
        <PMLastMetersContext.Provider value={{ meters, setMeters }}>
            {children}
        </PMLastMetersContext.Provider>
    )
}

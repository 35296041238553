import { ElementType } from 'react'

import { LinkOffOutlined, LinkOutlined } from '@mui/icons-material'
import { styled, alpha, Tooltip, SvgIcon } from '@mui/material'

import { BoxContainer, Typography, TypographyProps } from 'components'
export { default as WorkOrderJobCardItems } from './WorkOrderJobCardItems'
export * from './WorkOrderJobCardItems'
export { default as VendorWorkOrderExtraActionsDrawerToggler } from './VendorWorkOrderExtraActionsDrawerToggler'
export { default as useJobItemsTooltipData } from './useJobItemsTooltipData'
export { LineItemsMap } from './useJobItemsTooltipData'
export { default as JobNotes } from './JobNotes'
export * from './JobPhotos'

interface JobSourceLabelProps extends TypographyProps {
    isSourceDeleted?: boolean
    disableIcon?: boolean
}
export const JobSourceLabel = styled(
    ({ isSourceDeleted, disableIcon, children, ...props }: JobSourceLabelProps) => {
        const content = (
            <Typography
                variant="chartLabel"
                component={BoxContainer}
                gap="3px"
                {...props}
            >
                {disableIcon ? null : (
                    <SvgIcon
                        component={isSourceDeleted ? LinkOffOutlined : LinkOutlined}
                        sx={{
                            width: '14px',
                            height: '14px',
                        }}
                    />
                )}
                {children}
            </Typography>
        )
        if (isSourceDeleted) {
            return (
                <Tooltip
                    title="This job was previously linked to DVIR"
                    placement="bottom"
                >
                    {content}
                </Tooltip>
            )
        }
        return content
    },
)<{ component?: ElementType; isSourceDeleted?: boolean }>(
    ({ isSourceDeleted, theme }) => `
    padding: 4px 6px;
    border-radius: 2px;
    background: ${alpha(
        isSourceDeleted ? theme.palette.text.primary : theme.palette.primary.main,
        0.12,
    )};
    color: ${isSourceDeleted ? theme.palette.text.primary : theme.palette.primary.main};
`,
)

import { FC } from 'react'

import { MeterModel } from 'appTypes/models'
import { integerNonNegativeSpacedMaskResolver } from 'utils'

import { PMLastMeterHelperText, PMLastMetersContextResult } from '../PMLastMeters'

export interface LastDoneHelperTextProps {
    lastDone: number
    meterType: MeterModel['type']
    value: number
    meters?: PMLastMetersContextResult['meters']
    meter?: MeterModel
}

const LastDoneHelperTextBase: FC<LastDoneHelperTextProps> = ({
    lastDone,
    meterType,
    value,
    meters,
    meter,
}) => {
    if (!lastDone) {
        return (
            <PMLastMeterHelperText
                meterType={meterType}
                meters={meters}
                meter={meter}
            />
        )
    }

    return (
        <>
            Next service will be at{' '}
            {integerNonNegativeSpacedMaskResolver((Number(lastDone) || 0) + (Number(value) || 0))}
        </>
    )
}

export default LastDoneHelperTextBase

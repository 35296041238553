import { MouseEventHandler, ReactElement } from 'react'

import { Box, Tooltip, Typography, alpha, useTheme } from '@mui/material'
import { inject, observer } from 'mobx-react'
import { Form, useListContext, useRecordContext, useShowContext } from 'react-admin'

import api from 'api'
import { SortType } from 'appTypes'
import { CK33Model, PartModel } from 'appTypes/models'
import CrossReferenceFTU from 'assets/images/crossReferenceFtu.svg'
import { ReactComponent as DeleteIcon } from 'assets/images/remove-cross-reference.svg'
import {
    List,
    PageContent,
    ViewHeader,
    ListBase,
    DatagridColumnsProps,
    CardListConfig,
    ListAvatar,
    formatCK33CodeByLevel,
    IconBox,
    ListTotalBadge,
    ResourceType,
    BoxContainer,
    CardCollapsibleContent,
    PhotosCollapsibleIconHolder,
    PhotosCollapsibleContent,
    NotesCollapsibleIconHolder,
    NotesCollapsibleContent,
} from 'components'
import { deleteOneFromListAction } from 'components/actions'
import Icons from 'components/icons'
import { ListSortContentProps } from 'components/list/ListSortDrawerContent'
import { DeleteOneParams, useCreateResourcePath, useNotify } from 'hooks'
import {
    TagsCollapsibleContent,
    TagsCollapsibleIconHolder,
    TagsField,
} from 'pages/Configure/TagsManagement'
import LinkCardButton from 'pages/Reports/components/LinkCardButon'
import { AuthStore } from 'providers/authStore'
import { formatDate, resolveDoublePriceMask } from 'utils'

import { PartCk33Label, PartSelector, partCk33Label } from '../components'
import { unitsOfMeasureObject, partsAvatarConfig, partsResource } from '../config/constants'

const removeReferenceProps: DeleteOneParams = {
    confirmConfig: {
        title: 'Are you sure you want to remove this cross-reference?',
        content: 'This action will also remove the cross-reference link reciprocally.',
        confirmButtonProps: { children: 'Remove' },
    },
}

export const AddReferenceButton = ({
    path,
    renderElement,
}: {
    path: string
    renderElement?: (onClick: () => void) => ReactElement
}) => {
    const notify = useNotify()
    const { record } = useShowContext()
    const { refetch, isLoading } = useListContext<PartModel>()
    if (isLoading) {
        return null
    }

    return (
        <Form>
            <PartSelector
                successMessage=""
                noResults={({ searchValue }) => {
                    if (!searchValue) {
                        return {
                            title: 'No Parts to Add',
                            text: 'To add a Cross-reference, you need to create new part.',
                            image: (images) => images.listEmpty,
                            imageWidth: '180px',
                        }
                    }
                }}
                source="part"
                referenceFilter={{ availableForCrossReference: record.id }}
                renderToggler={({ handleOpen }) => {
                    const onClick = () => {
                        handleOpen()
                    }
                    return renderElement ? (
                        renderElement(onClick)
                    ) : (
                        <IconBox
                            onClick={onClick}
                            title="Add Cross-reference"
                        >
                            <Icons.Add />
                        </IconBox>
                    )
                }}
                queryOptions={(open) => ({
                    enabled: open,
                })}
                onChange={(control, id) => {
                    if (id) {
                        api.post(path, { [control.source]: id }).then(() => {
                            refetch()
                            notify('Successfully created', {
                                type: 'success',
                            })
                        })
                    }
                }}
            />
        </Form>
    )
}
const DeleteCardButton = ({ onClick }: { onClick: MouseEventHandler<HTMLDivElement> }) => {
    return (
        <IconBox
            onClick={onClick}
            title="Remove Cross-reference"
        >
            <DeleteIcon />
        </IconBox>
    )
}

const defaultPartUsageSort: SortType<PartModel> = {
    field: 'created',
    order: 'DESC',
}
export const sortCfg: ListSortContentProps<PartModel> = {
    sortBy: [
        { id: 'created', label: 'Created on' },
        { id: 'number', label: 'Part Number' },
        { id: 'description', label: 'Description' },
        { id: 'component', label: <PartCk33Label /> },
        { id: 'manufacturer', label: 'Manufacturer' },
        {
            id: 'manufacturerPartNumber',
            label: 'Manufacturer Part number',
        },
        { id: 'cost', label: 'Part Cost' },
        { id: 'unitOfMeasure', label: 'UOM' },
    ],
}

export const getCrossReferenceCardsCfg = (
    navigateTo: (record: PartModel) => string,
    auth: any,
    resource?: ResourceType,
): CardListConfig<PartModel> => {
    return {
        titleSource: 'number',
        subTitleSource: 'description',
        imageSource: 'image',
        defaultImage: <partsAvatarConfig.Icon />,
        withStatus: false,
        titleLink: (record) => navigateTo(record),
        colorGetter: (record, theme) => alpha(theme.palette.grey[900], 0.54),
        details: [
            {
                source: 'componentData',
                label: partCk33Label(auth),
                render: (value) => {
                    const record = value as CK33Model

                    return record
                        ? formatCK33CodeByLevel(record.code, record.level) + ' ' + record.text
                        : null
                },
            },
            {
                source: 'manufacturerData',
                label: 'Manufacturer',
                render: (value) => value?.description,
            },

            {
                source: 'unitOfMeasure',
                label: 'UOM',
            },
            {
                source: 'cost',
                label: 'Part Cost',
                render: (value) => resolveDoublePriceMask(value),
            },
        ],
        collapsibleContent: (record) => (
            <CardCollapsibleContent
                content={[
                    {
                        iconHolder: (
                            <TagsCollapsibleIconHolder
                                tags={record.tagsData}
                                key="tagsIcon"
                            />
                        ),
                        component: (
                            <TagsCollapsibleContent
                                tags={record.tagsData}
                                key="tagsContent"
                            />
                        ),
                    },
                    {
                        iconHolder: (
                            <NotesCollapsibleIconHolder
                                notes={record.notes}
                                key="notesIcon"
                            />
                        ),
                        component: (
                            <NotesCollapsibleContent
                                notes={record.notes}
                                key="notesContent"
                            />
                        ),
                    },
                    {
                        iconHolder: (
                            <PhotosCollapsibleIconHolder
                                record={record}
                                key="photosIcon"
                            />
                        ),
                        component: (
                            <PhotosCollapsibleContent
                                record={record}
                                key="photosContent"
                            />
                        ),
                    },
                ]}
            />
        ),
        action: (row) =>
            deleteOneFromListAction({
                notifyMessage: 'Successfully removed.',
                id: row.id,
                resource,
                children: ({ onClick }) => <DeleteCardButton onClick={onClick} />,
                ...removeReferenceProps,
            }),
    }
}

const CrossReference = inject('auth')(
    observer(({ auth }: { auth?: AuthStore }) => {
        const createPath = useCreateResourcePath()
        const record = useRecordContext<PartModel>()
        const theme = useTheme()
        if (!record) {
            return null
        }
        const navigateTo = (record: PartModel) =>
            createPath({
                resource: partsResource.resource,
                id: record.id,
                type: 'edit',
            })

        const columnsCfg: DatagridColumnsProps<PartModel> = {
            checkboxSelection: false,
            columns: [
                {
                    field: 'image',
                    headerName: 'Picture',
                    maxWidth: 72,
                    renderCell: ({ row }) => (
                        <ListAvatar
                            id={row.id}
                            imageSrc={row.image}
                            color={theme.palette.primary.main}
                            defaultImage={<partsAvatarConfig.Icon />}
                            customPath={{
                                resource: partsResource.resource,
                                type: 'edit',
                                id: row.id,
                            }}
                        />
                    ),
                },
                {
                    field: 'number',
                    headerName: 'Part Number',
                    flex: 1,
                    renderCell: ({ row }) => (
                        <LinkCardButton
                            text={row.number}
                            to={{
                                resource: partsResource.resource,
                                type: 'edit',
                                id: row.id,
                            }}
                        />
                    ),
                },
                {
                    field: 'description',
                    headerName: 'Description',
                    renderCell: ({ row }) =>
                        row.description ? (
                            <Tooltip
                                placement="bottom-start"
                                title={row.description}
                            >
                                <Box
                                    sx={{
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                    }}
                                >
                                    {row.description}
                                </Box>
                            </Tooltip>
                        ) : (
                            row.description
                        ),
                    flex: 1,
                },
                {
                    field: 'componentData',
                    headerName: partCk33Label(auth),
                    flex: 1,
                    valueGetter: ({ row }) => {
                        const record = row.componentData as CK33Model
                        return record
                            ? formatCK33CodeByLevel(record.code, record.level) + ' ' + record.text
                            : null
                    },
                },
                {
                    field: 'manufacturerData',
                    headerName: 'Manufacturer',
                    flex: 1,
                    valueGetter: ({ row }) => row.manufacturerData?.description,
                },
                {
                    field: 'manufacturerPartNumber',
                    headerName: 'Manufacturer Part number',
                    flex: 1,
                    valueGetter: ({ row }) => row.manufacturerPartNumber,
                },
                {
                    field: 'created',
                    headerName: 'Created on',
                    flex: 1,
                    renderCell: (cell) => {
                        return formatDate(
                            cell.value,
                            (dateFormats) => dateFormats.shortenedDateTime,
                        )
                    },
                },
                {
                    field: 'tagsData',
                    headerName: 'Tags',
                    renderCell: ({ row }) => <TagsField tags={row.tagsData} />,
                },
                {
                    field: 'unitOfMeasure',
                    headerName: 'UOM',
                    renderCell: ({ row }) => {
                        const uomLabel = unitsOfMeasureObject[row.unitOfMeasure]
                        const content = <div>{row.unitOfMeasure}</div>
                        if (uomLabel) {
                            return <Tooltip title={uomLabel}>{content}</Tooltip>
                        }
                        return null
                    },
                },
                {
                    field: 'cost',
                    headerName: 'Part Cost',
                    headerAlign: 'right',
                    align: 'right',
                    valueGetter: ({ row }) => resolveDoublePriceMask(row.cost as PartModel['cost']),
                },
            ],
            actions: ({ row }, { children }) => [
                deleteOneFromListAction({
                    id: row.id,
                    notifyMessage: 'Successfully removed.',
                    children: (params) =>
                        children({
                            ...params,
                            Icon: DeleteIcon,
                            title: 'Remove Cross-reference',
                        }),
                    ...removeReferenceProps,
                }),
            ],
        }
        const path =
            createPath({
                id: record.id,
                type: 'edit',
            }) + '/cross-references'
        const crossReferenceResource: ResourceType = {
            resource: path,
            name: 'part-cross-references',
        }
        return (
            <PageContent>
                <ListBase<PartModel>
                    resource={crossReferenceResource.resource}
                    preferencesResource={crossReferenceResource}
                    sort={defaultPartUsageSort}
                >
                    <ViewHeader title="Cross-reference">
                        <ViewHeader.Content>
                            <ListTotalBadge />
                        </ViewHeader.Content>
                        <ViewHeader.Content placement="rightMobile">
                            <AddReferenceButton path={path} />
                        </ViewHeader.Content>
                    </ViewHeader>
                    <List
                        disableExportButton
                        disableManageColumns
                        columnsCfg={columnsCfg}
                        preferencesResource={crossReferenceResource}
                        cardsCfg={getCrossReferenceCardsCfg(navigateTo, auth)}
                        sortCfg={sortCfg}
                        listFTUProps={{
                            title: (
                                <BoxContainer
                                    flexWrap="wrap"
                                    justifyContent="center"
                                >
                                    <span>There are currently no</span>&nbsp;
                                    <span>cross-references.</span>
                                </BoxContainer>
                            ),
                            secondaryTitle:
                                'Once cross-reference parts are added, they will be displayed here.',
                            action: (
                                <AddReferenceButton
                                    path={path}
                                    renderElement={(onClick) => (
                                        <Typography
                                            color="primary.main"
                                            onClick={onClick}
                                            sx={{ cursor: 'pointer' }}
                                            variant="body1"
                                        >
                                            Add Cross-reference
                                        </Typography>
                                    )}
                                />
                            ),
                            imageSrc: CrossReferenceFTU,
                        }}
                    />
                </ListBase>
            </PageContent>
        )
    }),
)
export default CrossReference

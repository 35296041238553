import { ReactComponent as SupportIcon } from 'assets/images/support.svg'
import { BoxContainer, Typography } from 'components'
import { supportLauncherSelector } from 'configs/config'

import SidebarHide from './SidebarHide'
import SidebarTooltip from './SidebarTooltip'

const title = 'Help and Support'

const SidebarSupport = () => {
    return (
        <BoxContainer
            sx={{
                padding: '8px 7px 8px 16px',
                cursor: 'pointer',
                marginBottom: '8px',
            }}
            className={supportLauncherSelector}
        >
            <SidebarTooltip
                title={title}
                placement="right"
                arrow
                sx={{ flexShrink: 0 }}
            >
                <div
                    role="img"
                    aria-label={title}
                >
                    <SupportIcon />
                </div>
            </SidebarTooltip>
            <SidebarHide>
                <Typography
                    sx={{
                        marginLeft: '28px',
                        fontWeight: '400',
                        fontSize: '16px',
                        lineHeight: '150%',
                        letterSpacing: '0.15px',
                    }}
                    variant="subtitle2"
                >
                    Help and Support
                </Typography>
            </SidebarHide>
        </BoxContainer>
    )
}

export default SidebarSupport

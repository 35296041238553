import { PercentOutlined, SvgIconComponent, WorkOutlineOutlined } from '@mui/icons-material'
import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined'
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined'
import { useTheme } from '@mui/material'

import { WOJobItemTypes } from 'appTypes/models'
import { ReactComponent as FeeIcon } from 'assets/images/fee.svg'
import {
    JobItemsCalculations,
    unitClarificationMap,
} from 'pages/Dashboard/components/Widgets/components/types'

import { WorkOrderItemsMapType } from './WorkOrderJobCardItems'

export type JobLineItemsMapType = {
    id: keyof WorkOrderItemsMapType
    label: string
    icon: SvgIconComponent
    total?: number
    totalPercent?: number
    color?: string
}[]

export const LineItemsMap: JobLineItemsMapType = [
    {
        id: WOJobItemTypes.part,
        label: 'Parts',
        icon: BuildCircleOutlinedIcon,
    },
    {
        id: WOJobItemTypes.labor,
        label: 'Labor',
        icon: ScheduleOutlinedIcon,
    },
    {
        id: WOJobItemTypes.service,
        label: 'Services',
        icon: WorkOutlineOutlined,
    },
    {
        id: WOJobItemTypes.fee,
        label: 'Fee',
        icon: FeeIcon,
    },
    {
        id: WOJobItemTypes.tax,
        label: 'Tax',
        icon: PercentOutlined,
    },
]

export type useJobItemsTooltipDataOptions = {
    allWhite?: boolean
}
const useJobItemsTooltipData = () => {
    const { palette } = useTheme()
    return (
        ratioData: Partial<JobItemsCalculations> & { id?: string },
        options?: useJobItemsTooltipDataOptions,
    ) =>
        LineItemsMap.map((item) => {
            const currItemMap = unitClarificationMap.find((itemMap) => itemMap.id === item.id)

            return {
                ...item,
                ...{
                    color: options?.allWhite ? palette.white : palette.charts[currItemMap.tint],
                    total:
                        ratioData.id === 'uncategorized' && ratioData[currItemMap.total] === 0
                            ? null
                            : ratioData[currItemMap.total],
                },
            }
        })
}

export default useJobItemsTooltipData

import { Theme } from '@mui/material'
import { useChoicesContext } from 'react-admin'

import Icons from 'components/icons'
import Typography from 'components/mui/Typography'
import { Spacer } from 'components/styled'
import { useReferenceInputTotal } from 'hooks'

const DialogSelectorResultCount = () => {
    const { isFetching } = useChoicesContext()

    const total = useReferenceInputTotal()

    if (isFetching) {
        return null
    }

    return (
        <Spacer ml="auto">
            <Icons.SearchOutlined
                sx={(theme: Theme) => ({
                    color: theme.palette.text.disabled,
                    width: '16px',
                })}
            />
            <Typography
                variant="tooltip"
                color="text.disabled"
                textTransform="uppercase"
            >
                {total} Results
            </Typography>
        </Spacer>
    )
}

export default DialogSelectorResultCount

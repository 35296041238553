import { ReactElement, useState, useRef, useLayoutEffect } from 'react'

import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner'
import { ListControllerResult, useShowContext } from 'react-admin'
import { useFormContext } from 'react-hook-form'

import { JobModel, PartModel, WorkOrderModel } from 'appTypes/models'
import { Button, ResourceType, useOpenUtilityDrawer, QRScanner } from 'components'
import UtilityDrawerListEditor from 'components/Drawer/UtilityDrawerListEditor'
import { renderOnPermission } from 'hocs'
import { useDataProvider, useFinalErrorHandler, useNotify } from 'hooks'
import { partsResource } from 'pages/Parts/config/constants'
import { woJobItemPermissions } from 'pages/WorkOrders'
import { JobLaborCreate, JobLaborForm } from 'pages/WorkOrders/Jobs/Labor'
import { WorkOrderItemsExtra } from 'pages/WorkOrders/Jobs/config/constants'
import { useIsVenderWo } from 'pages/WorkOrders/Overview/WorkOrderOverview'
import { useWoActionIsDisabled } from 'pages/WorkOrders/hooks'

import { JobPartForm, jobPartSerializer } from '../../../../Parts'
export interface LaborFormExtra extends WorkOrderItemsExtra {
    job: JobModel
}

const PartQRScanner = () => {
    const [isOpen, setIsOpen] = useState(false)
    const partId = useRef<PartModel['id']>(null) // prevents decoding the qr code for multiple times

    const dataProvider = useDataProvider()
    const close = () => {
        setIsOpen(false)
    }
    const notify = useNotify()
    const { setValue } = useFormContext()
    const errorHandler = useFinalErrorHandler()

    useLayoutEffect(() => {
        if (isOpen) {
            partId.current = null
        }
    }, [isOpen])

    return (
        <>
            <QRScanner
                isOpen={isOpen}
                close={close}
                onDecode={async ({ data }) => {
                    if (partId.current) {
                        return
                    }
                    try {
                        const url = new URL(data)
                        const pathnameParts = url.pathname.split('/')
                        const id = pathnameParts.pop() || pathnameParts.pop()
                        if (id) {
                            partId.current = id
                            try {
                                const part = await dataProvider.getOne<PartModel>(
                                    partsResource.resource,
                                    { id },
                                )
                                setValue('part', part.data.id, {
                                    shouldDirty: true,
                                })
                                notify('Part Selected', {
                                    type: 'success',
                                })
                            } catch (err) {
                                if (err.status === 404) {
                                    throw new Error()
                                }
                                errorHandler(err)
                            }
                        } else {
                            partId.current = '1'
                            throw new Error()
                        }
                    } catch {
                        notify('No Part information found on QR code', {
                            type: 'error',
                        })
                    } finally {
                        close()
                    }
                }}
            />
            <Button
                startIcon={<QrCodeScannerIcon />}
                variant="text"
                size="small"
                onClick={() => setIsOpen(true)}
            >
                Fill via QR Code
            </Button>
        </>
    )
}

export const PartCreateDrawerToggler = renderOnPermission(
    ({
        children,
        resource,
        listContext,
    }: {
        children: (params: { onClick: () => void }) => ReactElement
        resource: ResourceType
        listContext: ListControllerResult
    }) => {
        const open = useOpenUtilityDrawer()
        const { refetch, record } = useShowContext<WorkOrderModel>()
        const disabled = useWoActionIsDisabled({ workOrder: record })
        const isVendorWo = useIsVenderWo()

        return children({
            onClick: disabled
                ? () => {
                      /* */
                  }
                : () => {
                      open({
                          extraArgs: {
                              listContext,
                              resource: {
                                  ...resource,
                                  resource: resource.resource + '/add_part',
                              },
                          },
                          drawerArgs: {
                              title: 'Add Part',
                              renderWrapper: (params) => (
                                  <UtilityDrawerListEditor
                                      onSuccess={refetch}
                                      {...params}
                                      serializer={jobPartSerializer}
                                  />
                              ),
                              renderContent: () => <JobPartForm />,
                              renderBottomRight: (render) => render({ label: 'Add Part' }),
                              renderTopRight: () => (isVendorWo ? null : <PartQRScanner />),
                          },
                          extra: {
                              vendor: record.purchaseOrderData
                                  ? {
                                        name: record.purchaseOrderData.vendorData.name,
                                        id: record.purchaseOrderData.vendorData.id,
                                    }
                                  : null,
                          },
                      })
                  },
        })
    },
    woJobItemPermissions.addPart,
)

export const LaborCreateDrawerToggler = renderOnPermission(
    ({
        children,
        resource,
        listContext,
        job,
    }: {
        children: (params: { onClick: () => void }) => ReactElement
        resource: ResourceType
        listContext: ListControllerResult
        job: JobModel
    }) => {
        const open = useOpenUtilityDrawer()
        const { refetch, record } = useShowContext()
        const isVendorWo = useIsVenderWo()

        const disabled = useWoActionIsDisabled({ workOrder: record })

        return children({
            onClick: disabled
                ? () => {
                      /* */
                  }
                : () => {
                      open({
                          extraArgs: {
                              listContext,
                              resource,
                          },
                          drawerArgs: {
                              title: 'Add Labor',
                              renderWrapper: (params) => (
                                  <JobLaborCreate
                                      {...params}
                                      onSuccess={refetch}
                                      isVendorWo={isVendorWo}
                                  />
                              ),
                              renderContent: () => <JobLaborForm />,
                              renderBottomRight: (render) => render({ label: 'Add Labor' }),
                          },
                          extra: {
                              job,
                          } as LaborFormExtra,
                      })
                  },
        })
    },
    woJobItemPermissions.addLabor,
)

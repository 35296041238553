import { FC, useEffect } from 'react'

import { Identifier } from 'react-admin'
import { useWatch } from 'react-hook-form'

import api from 'api/api'
import { useFinalErrorHandler } from 'hooks'
import { getMetersResource } from 'pages/Units/Meters/components/UnitMetersResource'
import { dateTimeParse } from 'utils'

import { PMLastMetersContextResult, usePMLastMetersContext } from './PMLastMetersContext'

const PMLastMetersFetch: FC<{ unitId: Identifier; archived: string | boolean }> = ({
    unitId,
    archived,
}) => {
    const value = useWatch({ name: 'lastDone' })
    const errorHandler = useFinalErrorHandler()
    const { setMeters } = usePMLastMetersContext()

    useEffect(() => {
        if (archived) {
            return
        }
        ;(async () => {
            try {
                const meters: { currentMeters: PMLastMetersContextResult['meters'] } =
                    await api.get(getMetersResource(unitId) + '/current', {
                        now: dateTimeParse(value || new Date()),
                    })
                setMeters(meters.currentMeters)
            } catch (err) {
                errorHandler(err)
            }
        })()
    }, [value])

    return null
}

export default PMLastMetersFetch

import { SortType } from 'appTypes'
import { Ck2ReportModel, UnitModel } from 'appTypes/models'
import { CardListConfig, DatagridColumnsProps, PageContent } from 'components'
import { ListSortContentProps } from 'components/list/ListSortDrawerContent'
import { FilterConfig } from 'components/list/filter/ListFilterForm'
import ListFilterRangeInput from 'components/list/filter/ListFilterRangeInput'
import ResourceContextProviderWithClearEffect from 'components/resource/ResourceContextProviderWithClearEffect'
import { ck2Resource } from 'pages/Units/config/constants'
import { costMaskParams, resolveDoublePriceMask } from 'utils'

import { ListWithPeriodSelector, ReportsHeader, ListBaseWithPeriod } from '../components'
const defaultSort: SortType<UnitModel> = {
    field: 'total',
    order: 'DESC',
}
const sortCfg: ListSortContentProps<Ck2ReportModel> = {
    sortBy: [
        { id: 'description', label: 'Equipment Category' },
        { id: 'code', label: 'Code' },
        {
            id: 'totalParts',
            label: 'Parts',
        },
        { id: 'totalLabor', label: 'Labor' },
        { id: 'totalServices', label: 'Services' },
        { id: 'totalFees', label: 'Fees' },
        { id: 'totalTax', label: 'Tax' },
        { id: 'total', label: 'Total Cost' },
    ],
}
const cardsCfg: CardListConfig<Ck2ReportModel> = {
    titleSource: 'description',
    defaultImage: null,
    disableTitleLink: true,
    details: [
        { source: 'code', label: 'Code' },
        { source: 'totalParts', label: 'parts', render: (value) => resolveDoublePriceMask(value) },
        { source: 'totalLabor', label: 'labor', render: (value) => resolveDoublePriceMask(value) },
        {
            source: 'totalServices',
            label: 'services',
            render: (value) => resolveDoublePriceMask(value),
        },
        { source: 'totalFees', label: 'fees', render: (value) => resolveDoublePriceMask(value) },
        { source: 'totalTax', label: 'tax', render: (value) => resolveDoublePriceMask(value) },
        { source: 'total', label: 'total cost', render: (value) => resolveDoublePriceMask(value) },
    ],
}

const columnsCfg: DatagridColumnsProps<Ck2ReportModel> = {
    checkboxSelection: false,
    columns: [
        {
            field: 'description',
            headerName: 'Equipment Category',
        },
        { field: 'code', headerName: 'Code' },
        {
            field: 'totalParts',
            headerName: 'Parts',
            renderCell: ({ value }) => resolveDoublePriceMask(value),
            align: 'right',
            headerAlign: 'right',
        },
        {
            field: 'totalLabor',
            headerName: 'Labor',
            renderCell: ({ value }) => resolveDoublePriceMask(value),
            align: 'right',
            headerAlign: 'right',
        },
        {
            field: 'totalServices',
            headerName: 'Services',
            renderCell: ({ value }) => resolveDoublePriceMask(value),
            align: 'right',
            headerAlign: 'right',
        },
        {
            field: 'totalFees',
            headerName: 'Fees',
            renderCell: ({ value }) => resolveDoublePriceMask(value),
            align: 'right',
            headerAlign: 'right',
        },
        {
            field: 'totalTax',
            headerName: 'Tax',
            renderCell: ({ value }) => resolveDoublePriceMask(value),
            align: 'right',
            headerAlign: 'right',
        },
        {
            field: 'total',
            headerName: 'Total Cost',
            renderCell: ({ value }) => resolveDoublePriceMask(value),
            align: 'right',
            headerAlign: 'right',
        },
    ],
    actions: null,
}
const filtersCfg: FilterConfig<Ck2ReportModel> = {
    filters: [
        { id: 'description', label: 'Equipment Category' },
        { id: 'code', label: 'Code' },
        {
            id: 'totalParts',
            label: 'Parts',
            filterType: 'range',
            renderComponent: (props) => (
                <ListFilterRangeInput
                    inputProps={costMaskParams}
                    {...props}
                />
            ),
        },
        {
            id: 'totalLabor',
            label: 'Labor',
            filterType: 'range',
            renderComponent: (props) => (
                <ListFilterRangeInput
                    inputProps={costMaskParams}
                    {...props}
                />
            ),
        },
        {
            id: 'totalServices',
            label: 'Services',
            filterType: 'range',
            renderComponent: (props) => (
                <ListFilterRangeInput
                    inputProps={costMaskParams}
                    {...props}
                />
            ),
        },
        {
            id: 'totalFees',
            label: 'Fees',
            filterType: 'range',
            renderComponent: (props) => (
                <ListFilterRangeInput
                    inputProps={costMaskParams}
                    {...props}
                />
            ),
        },
        {
            id: 'totalTax',
            label: 'Tax',
            filterType: 'range',
            renderComponent: (props) => (
                <ListFilterRangeInput
                    inputProps={costMaskParams}
                    {...props}
                />
            ),
        },
        {
            id: 'total',
            label: 'Total Cost',
            filterType: 'range',
            renderComponent: (props) => (
                <ListFilterRangeInput
                    inputProps={costMaskParams}
                    {...props}
                />
            ),
        },
    ],
}
const CostPerEquipmentCategoryCK2 = () => {
    return (
        <ResourceContextProviderWithClearEffect value={ck2Resource}>
            <ListBaseWithPeriod
                sort={defaultSort}
                filter={{ withAspects: ['totals'] }}
            >
                <ReportsHeader<Ck2ReportModel>
                    renderTotal={({ total: totalCount, hasPreviousPage }) => {
                        const aggregates = (hasPreviousPage as any).aggregates || {}

                        return `${totalCount} • ${resolveDoublePriceMask(
                            aggregates.totalQueryset || 0,
                        )} total | Uncategorized • ${resolveDoublePriceMask(
                            aggregates.totalUncategorizedQuerset || 0,
                        )}`
                    }}
                >
                    Cost per Equipment Category
                </ReportsHeader>
                <PageContent>
                    <ListWithPeriodSelector
                        exportFileName="cost-per-equipment-category"
                        filtersCfg={filtersCfg}
                        sortCfg={sortCfg}
                        columnsCfg={columnsCfg}
                        cardsCfg={cardsCfg}
                    />
                </PageContent>
            </ListBaseWithPeriod>
        </ResourceContextProviderWithClearEffect>
    )
}

export default CostPerEquipmentCategoryCK2

import { AllProps } from 'appTypes'

export const arrayOfStringToObject = <T, V>(params: (keyof T)[], value?: V) => {
    const obj = {} as { [K in keyof T]: V }
    for (const prop of params) {
        obj[prop] = value
    }

    return obj
}

export const compose = (...funcs) =>
    funcs.reduce(
        (a, b) =>
            (...args) =>
                a(b(...args)),
        (arg) => arg,
    )

export const firstElementOfObject = (obj): [string, any] | null => {
    for (const property in obj) {
        if (!Object.prototype.hasOwnProperty.call(obj, property)) {
            continue
        }
        return [property, obj[property]]
    }

    return null
}

export const objectIsEmpty = (obj: AllProps): boolean => {
    for (const property in obj) {
        if (!Object.prototype.hasOwnProperty.call(obj, property)) {
            continue
        }
        return false
    }
    return true
}

export const arrayObjectsToObject = (arr, key = 'id') => {
    const obj = {}

    arr.forEach((item) => {
        obj[item[key]] = item
    })

    return obj
}

export const normaliseRange = (value, max, min = 0) => ((value - min) * 100) / (max - min)

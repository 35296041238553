import { styled } from '@mui/material'

import SimpleScrollbar from 'components/SimpleScrollbar'

const CardCollapsibleScrollbar = styled(SimpleScrollbar)`
    padding-bottom: 16px;
    padding-top: 10px;
    margin-bottom: calc(16px * -1);
`
export default CardCollapsibleScrollbar

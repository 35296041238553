import { ListTotalBadge } from 'components'
import Header from 'components/pages/header'

const MembersListHeader = () => {
    return (
        <Header bgColor="transparent">
            <Header.Block>
                <Header.Main>
                    <Header.Spacer>
                        <Header.Title>Members</Header.Title>
                        <ListTotalBadge />
                    </Header.Spacer>
                </Header.Main>
            </Header.Block>
        </Header>
    )
}

export default MembersListHeader

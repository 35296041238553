import { ComponentProps } from 'react'

import { Tooltip, TooltipProps } from '@mui/material'
import { alpha, styled } from '@mui/material/styles'

import { generateNotForwardedProps, themeColor, ThemeColorType } from 'utils'

import { StyledElement } from './styled'

export interface IconBoxProps
    extends Partial<Pick<TooltipProps, 'title'>>,
        Omit<ComponentProps<'div'>, 'title'> {
    disabled?: boolean
    iconColor?: ThemeColorType
    backgroundColor?: ThemeColorType
    titleProps?: Omit<TooltipProps, 'title' | 'children'>
}

const IconBox = styled(
    ({ title, titleProps, ...rest }: IconBoxProps) => {
        const content = (
            <StyledElement
                role="button"
                aria-label={typeof title === 'string' ? title : 'Action'}
                {...rest}
            />
        )

        if (title) {
            return (
                <Tooltip
                    title={title}
                    {...titleProps}
                >
                    {content}
                </Tooltip>
            )
        }

        return content
    },
    {
        shouldForwardProp: generateNotForwardedProps<IconBoxProps>([
            'disabled',
            'iconColor',
            'backgroundColor',
        ]),
    },
)<IconBoxProps>`
    background-color: ${({ theme, backgroundColor }) =>
        backgroundColor
            ? themeColor(backgroundColor, theme)
            : alpha(theme.palette.text.main, 0.04)};
    height: 24px;
    width: 24px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 2px;
    box-sizing: border-box;
    ${({ disabled }) =>
        disabled
            ? `
        opacity: 0.5;
        cursor: unset;
    `
            : ''}

    svg {
        color: ${({ theme, iconColor }) =>
            iconColor ? themeColor(iconColor, theme) : theme.palette.text.primary};
        width: 100%;
        height: 100%;
    }
`

export default IconBox

import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import copy from 'copy-to-clipboard'

import { useNotify } from 'hooks'

import { ActionChildren } from './Actions'

interface ExportActionProps {
    children: ActionChildren
    copyMessage: string
    title: string
}

export const CopyAction = ({ children, copyMessage, title }: ExportActionProps) => {
    const notify = useNotify()

    return children({
        Icon: ContentCopyOutlinedIcon,
        title,
        onClick: () => {
            copy(copyMessage || '')
            notify('Copy to clipboard', {
                type: 'success',
            })
        },
    })
}

export const copyAction = (params: ExportActionProps) => (
    <CopyAction
        {...params}
        key={'copy-to-clipboard-' + params.copyMessage}
    />
)

import { useRef } from 'react'

import { StorefrontOutlined } from '@mui/icons-material'

import { useResizeObserverWidth } from 'hooks'
import { reportUrl } from 'pages/Reports'
import { createReportResource } from 'pages/Units/config/constants'
import { resolveDoublePriceMask } from 'utils'

import WidgetBase, { WidgetSkeleton } from '../../WidgetBase/WidgetBase'
import { useCreateDashboardWidgetLink, useDashboardWidget } from '../../WidgetsForm'
import { unitGetRoundedPercents } from '../CostPerUnitWidget'
import { Clarification, CostPerUnitChart } from '../CostPerUnitWidget/components'

import { VendorExpensesList } from './components'
import { VendorExpensesResponse } from './types'
const description = `The sum of all costs, fees, and taxes associated with Vendor Work Orders 
for the selected period is displayed alongside a breakdown by Vendor.`

let validData = false
const VendorExpensesWidget = () => {
    const createLink = useCreateDashboardWidgetLink()

    const elementRef = useRef()
    const isUnderWidthPoint = useResizeObserverWidth(elementRef, 360)
    const data = useDashboardWidget<VendorExpensesResponse>({
        resource: createReportResource('cost-per-vendor'),
        sideEffect: (data) => {
            const finalData = unitGetRoundedPercents(data)

            validData = finalData.total > 0
            return finalData as VendorExpensesResponse
        },
    })
    if (!data) {
        return <WidgetSkeleton />
    }
    const { maxTotalVendors, ...ratioData }: VendorExpensesResponse = data

    return (
        <WidgetBase
            ref={elementRef}
            link={createLink(reportUrl(reportUrl.urls.vendorExpenses))}
            headerProps={{
                title: 'Total Vendor Expenses',
                label:
                    typeof ratioData.total === 'number'
                        ? resolveDoublePriceMask(ratioData.total)
                        : null,
                tooltipText: description,
                clarification: (
                    <Clarification
                        {...ratioData}
                        showTooltip={isUnderWidthPoint}
                        validData={validData}
                    />
                ),
                chart: (
                    <CostPerUnitChart
                        PieIcon={StorefrontOutlined}
                        {...ratioData}
                        validData={validData}
                    />
                ),
            }}
            infoProps={{
                title: 'Vendors by Expenses',
                content: (
                    <VendorExpensesList
                        data={maxTotalVendors}
                        description={description}
                        validData={validData}
                    />
                ),
            }}
        />
    )
}
export default VendorExpensesWidget

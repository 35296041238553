import { useLayoutEffect } from 'react'

import { stringify } from 'query-string'
import { DataProvider as RADataProvider, Identifier, PaginationPayload } from 'react-admin'

import { CApi } from 'api'
import { NoStringIndex, ReplaceReturnType } from 'appTypes'
import { emptyPerPage, emptyOptionValue, filterSearchText } from 'configs/constants'

import type { AuthStore } from './authStore'

const getPaginationQuery = (pagination: PaginationPayload) => {
    return {
        offset:
            pagination.page < 0
                ? Math.abs(pagination.page)
                : (pagination.page - 1) * pagination.perPage,
        limit: pagination.perPage,
    }
}

const getFilterQuery = (filter) => {
    const { q, [filterSearchText]: searchedText, ...otherSearchParams } = filter
    return {
        ...otherSearchParams,
        [filterSearchText]: searchedText || q,
    }
}

export const getOrderingQuery = (sort) => {
    const { field, order } = sort
    if (field === 'id') {
        return {}
    }
    return {
        ordering: `${order === 'ASC' ? '' : '-'}${field}`,
    }
}
export type DataProviderConfigType = {
    [KEY in keyof ReturnType<typeof dataProvider>]?: {
        prepareResource?: (resource: string) => string
        prepareDataAfterResponse?: (
            data: any,
            params: Parameters<ReturnType<typeof dataProvider>[KEY]>[1],
        ) => Awaited<ReturnType<ReturnType<typeof dataProvider>[KEY]>>['data']
        shouldStopRequest?: boolean
        makeResponse?: ReplaceReturnType<
            ReturnType<typeof dataProvider>[KEY],
            Awaited<ReturnType<ReturnType<typeof dataProvider>[KEY]>>['data']
        >
        queryParams?: () => object
    } & (KEY extends 'getOne' | 'update'
        ? {
              makeId?: (id: Identifier) => Identifier
          }
        : {}) &
        (KEY extends 'getMany' ? { singleRequest?: boolean } : {})
}

export interface DataProvider extends NoStringIndex<RADataProvider> {
    createMany: RADataProvider['create']
}

export const dataProviderConfig: { [key: string]: DataProviderConfigType } = {
    tags: {
        getMany: {
            singleRequest: true,
        },
    },
}

export const useDataProviderConfig = (resource: string | null, config: DataProviderConfigType) => {
    useLayoutEffect(() => {
        if (resource) {
            dataProviderConfig[resource] = config
            return () => {
                delete dataProviderConfig[resource]
            }
        }
    }, [resource])
}

type FileName = string | string[]
export interface DataProviderMetaFile {
    files?: FileName
    [key: string]: any
}
const dataProvider = (authStore: AuthStore): DataProvider => {
    const api: CApi = authStore.api
    const getOneJson = (resource: string, id: Identifier, query?: object) => {
        const queryParams = stringify(query)
        return api.sendRequest(
            'get',
            `${resource}${id ? `/${id}` : ''}${queryParams ? `?${queryParams}` : ''}`,
        )
    }

    return {
        getList: async (resourceProp, params) => {
            const defaultConfig = dataProviderConfig[resourceProp]?.getList || {}
            if (defaultConfig.shouldStopRequest) {
                // return Promise.resolve().then(() => {
                //     return { data: params.ids.map((id) => ({ id })) }
                // })
                return Promise.resolve({ data: [] })
            }
            if (defaultConfig.makeResponse) {
                const data = defaultConfig.makeResponse(resourceProp, params)
                return Promise.resolve({
                    data,
                    total: data.length,
                })
            }
            const query = {
                ...getFilterQuery(params.filter),
                ...getPaginationQuery(params.pagination),
                ...getOrderingQuery(params.sort),
            }
            if (defaultConfig.makeResponse) {
                const data = defaultConfig.makeResponse(resourceProp, params)
                return Promise.resolve({
                    data,
                    total: data.length,
                })
            }
            let fixedResource = resourceProp
            if (defaultConfig.prepareResource) {
                fixedResource = defaultConfig.prepareResource(resourceProp)
            }

            const json =
                params.pagination.perPage === emptyPerPage
                    ? []
                    : await api.sendRequest(
                          'get',
                          `${fixedResource}?${stringify(
                              defaultConfig.queryParams
                                  ? { ...query, ...defaultConfig.queryParams() }
                                  : query,
                          )}`,
                      )

            let fixedData = json.results || json
            if (defaultConfig.prepareDataAfterResponse) {
                fixedData = defaultConfig.prepareDataAfterResponse(fixedData, params)
            }

            const getPrev = () => {
                const aggregates = json.aggregates
                if (!aggregates) {
                    return Boolean(json.prev)
                }
                // eslint-disable-next-line no-new-wrappers
                const hasNext = new Boolean(json.next)
                ;(hasNext as any).aggregates = aggregates
                return hasNext as boolean
            }

            return {
                data: fixedData,
                total: json.count || fixedData.length,
                pageInfo: {
                    hasNextPage: Boolean(json.next),
                    hasPreviousPage: getPrev(),
                },
            }
        },
        getOne: async (resourceProp, params) => {
            const defaultConfig = dataProviderConfig[resourceProp]?.getOne || {}
            if (defaultConfig.shouldStopRequest) {
                return new Promise(() => {
                    /* empty */
                })
            }
            let fixedResource = resourceProp
            if (defaultConfig.prepareResource) {
                fixedResource = defaultConfig.prepareResource(resourceProp)
            }
            const data = await getOneJson(
                fixedResource,
                defaultConfig.makeId ? defaultConfig.makeId(params.id) : params.id,
                { ...params.meta?.query, ...defaultConfig.queryParams?.() },
            )
            return { data }
        },
        getMany: (resourceProp, params) => {
            const defaultConfig = dataProviderConfig[resourceProp]?.getMany || {}
            if (defaultConfig.shouldStopRequest) {
                // return Promise.resolve().then(() => {
                //     return { data: params.ids.map((id) => ({ id })) }
                // })
                return Promise.resolve({ data: [] })
            }
            if (defaultConfig.makeResponse) {
                return Promise.resolve({
                    data: defaultConfig.makeResponse(resourceProp, params),
                })
            }
            let fixedResource = resourceProp
            if (defaultConfig.prepareResource) {
                fixedResource = defaultConfig.prepareResource(resourceProp)
            }

            let request: Promise<any>

            if (defaultConfig.singleRequest) {
                if (params.ids.length === 1 && params.ids[0] === emptyOptionValue) {
                    request = new Promise((resolve) => {
                        resolve([{ id: emptyOptionValue }])
                    })
                } else {
                    request = api
                        .get(
                            fixedResource +
                                '?' +
                                stringify(
                                    { id: params.ids.filter((id) => id !== emptyOptionValue) },
                                    {
                                        arrayFormat: 'comma',
                                    },
                                ),
                        )
                        .then((data) => [...data.results, { id: emptyOptionValue }])
                }
            } else {
                request = Promise.all(
                    params.ids.map((id) => {
                        if (id === emptyOptionValue) {
                            return { id: emptyOptionValue }
                        }
                        return getOneJson(fixedResource, id)
                    }),
                )
            }

            return request.then((data) => ({
                data: defaultConfig.prepareDataAfterResponse
                    ? defaultConfig.prepareDataAfterResponse(data, params)
                    : data,
            }))
        },

        getManyReference: async (resourceProp, params) => {
            const query = {
                ...getFilterQuery(params.filter),
                ...getPaginationQuery(params.pagination),
                ...getOrderingQuery(params.sort),
                [params.target]: params.id,
            }
            const json = await api.sendRequest('get', `${resourceProp}?${stringify(query)}`)
            return {
                data: json.results,
                total: json.count,
            }
        },
        update: async (resourceProp, params) => {
            const defaultConfig = dataProviderConfig[resourceProp]?.update || {}
            const data = dataAndFiles(params)
            const id = defaultConfig.makeId ? defaultConfig.makeId(params.id) : params.id

            const json = await api.sendRequest(
                'patch',
                `${resourceProp}${id ? `/${id}` : ''}${
                    defaultConfig.queryParams || params.meta?.query
                        ? '?' +
                          stringify({ ...params.meta?.query, ...defaultConfig.queryParams?.() })
                        : ''
                }`,
                data,
            )
            return { data: json }
        },
        updateMany: (resourceProp, params) => {
            return Promise.all(
                params.ids.map((id) =>
                    api.sendRequest('patch', `${resourceProp}/${id}/`, params.data),
                ),
            ).then((responses) => ({ data: responses.map((json) => json.id) }))
        },
        create: async (resourceProp, params) => {
            const data = dataAndFiles(params)

            const json = await api.sendRequest('post', `${resourceProp}`, data)
            return {
                data: json,
            }
        },
        delete: (resourceProp, params) => {
            return api
                .sendRequest('delete', `${resourceProp}/${params.id}`, null, {
                    params: params?.previousData?.params,
                })
                .then(() => ({ data: params.previousData }))
        },
        deleteMany: (resourceProp, params) => {
            if (!params.ids.length) {
                return Promise.resolve().then(() => ({ data: [] }))
            }

            return api
                .sendRequest('delete', `${resourceProp}?id=${params.ids.join(',')}`, null, {
                    headers: { 'X-BULK-OPERATION': true },
                })
                .then(() => ({ data: params.ids }))
        },
        createMany: (resource, params) => {
            return api.post(resource, params.data)
        },
    }
}

export function dataAndFiles(params: { data: any }) {
    let hasFile = false
    let data = params.data

    data = new FormData()
    // This loop takes about 1-3ms, no need to skip it
    Object.entries(params.data).forEach((entry: any) => {
        const [k, v] = entry
        if (typeof v === 'undefined') {
            return
        }
        const file: File = v?.rawFile || (v instanceof File && v)
        if (!file) {
            data.append(k, v === null ? '' : v)
            return
        }
        data.append(k, file, file.name)
        hasFile = true
    })

    return hasFile ? data : params.data
}

export default dataProvider

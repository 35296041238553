import { RaRecord } from 'react-admin'

import { SortType } from 'appTypes'
import { CK33ReportModel, UnitModel } from 'appTypes/models'
import { CardListConfig, DatagridColumnsProps, PageContent } from 'components'
import { ListSortContentProps } from 'components/list/ListSortDrawerContent'
import { FilterConfig } from 'components/list/filter/ListFilterForm'
import ListFilterValueInput, {
    prepareFilterResource,
} from 'components/list/filter/ListFilterValueInput'
import ResourceContextProviderWithClearEffect from 'components/resource/ResourceContextProviderWithClearEffect'
import { ck33Resource } from 'pages/Units/config/constants'
import { capitalize, resolveDoublePriceMask } from 'utils'

import {
    costPerCategoryCardsCfg,
    costPerCategoryColumnsCfg,
    costPerCategoryFiltersCfg,
    costPerCategoryIncludedFiltersMap,
    costPerCategorySortCfg,
} from '../CostPerCategory'
import { ListBaseWithPeriod, ListWithPeriodSelector, ReportsHeader } from '../components'

const defaultSort: SortType<RaRecord> = {
    field: 'totals',
    order: 'DESC',
}
const includedColumnsMap: (keyof CK33ReportModel)[] = [
    'totalParts',
    'totalLabor',
    'totalServices',
    'totalFees',
    'totalTax',
    'total',
]
const includedFiltersMap: (keyof CK33ReportModel | keyof UnitModel)[] = [
    ...includedColumnsMap,
    ...costPerCategoryIncludedFiltersMap.map(
        (key) => ('unitTotals' + capitalize(key)) as keyof UnitModel,
    ),
]
const filterCfg: FilterConfig<CK33ReportModel & UnitModel> = {
    filters: [
        {
            id: 'code',
            label: 'VMRS Group',
            renderComponent: (props) => (
                <ListFilterValueInput
                    dataProviderProps={{
                        getList: {
                            prepareResource: prepareFilterResource,
                        },
                        getMany: {
                            prepareResource: () => 'vmrs/ck33',
                        },
                    }}
                    {...props}
                    makeItemLabel={(record: CK33ReportModel) => record.code + ' ' + record.text}
                />
            ),
        },
        ...costPerCategoryFiltersCfg.filters.filter((item) => includedFiltersMap.includes(item.id)),
    ],
}
const columnsCfg: DatagridColumnsProps<CK33ReportModel> = {
    checkboxSelection: false,
    columns: [
        {
            field: 'code',
            headerName: 'VMRS Group',
            valueFormatter: ({ value }) => value?.substring(1, 2),
        },
        {
            field: 'text',
            headerName: 'Group Description',
        },
        ...costPerCategoryColumnsCfg.columns.filter(({ field }) =>
            includedColumnsMap.includes(field as keyof CK33ReportModel),
        ),
    ],
    actions: null,
}
const cardCfg: CardListConfig<CK33ReportModel> = {
    titleSource: (record) => record.code?.substring(1, 2),
    disableTitleLink: true,
    defaultImage: null,
    details: [
        {
            source: 'text',
            label: 'Group Description',
        },
    ],
}
if (Array.isArray(costPerCategoryCardsCfg.details) && Array.isArray(cardCfg.details)) {
    cardCfg.details.push(
        ...costPerCategoryCardsCfg.details.filter(({ source }) =>
            includedColumnsMap.includes(source),
        ),
    )
}
const sortCfg: ListSortContentProps<CK33ReportModel & { description: string }> = {
    sortBy: [
        { id: 'code', label: 'VMRS Group' },
        { id: 'description', label: 'Group Description' },
        ...costPerCategorySortCfg.sortBy.filter(({ id }) =>
            includedColumnsMap.includes(id as keyof CK33ReportModel),
        ),
    ],
}
const CostPerVMRSGroup = () => {
    return (
        <ResourceContextProviderWithClearEffect value={ck33Resource}>
            <ListBaseWithPeriod
                sort={defaultSort}
                filter={{ withAspects: ['totals_group'] }}
            >
                <ReportsHeader
                    renderTotal={({ total: totalCount, hasPreviousPage }) =>
                        `${totalCount} | ${resolveDoublePriceMask(
                            (hasPreviousPage as any).aggregates.totalQueryset || 0,
                        )} total`
                    }
                >
                    Cost per VMRS Group
                </ReportsHeader>
                <PageContent>
                    <ListWithPeriodSelector
                        exportFileName="cost-per-vmrs-group"
                        filtersCfg={filterCfg}
                        sortCfg={sortCfg}
                        columnsCfg={columnsCfg}
                        cardsCfg={cardCfg}
                    />
                </PageContent>
            </ListBaseWithPeriod>
        </ResourceContextProviderWithClearEffect>
    )
}

export default CostPerVMRSGroup

import { styled, svgIconClasses } from '@mui/material'

import { Button } from 'components'

import SubmitButton from './SubmitButton'

function buttonMobileFixed<ButtonType extends typeof Button | typeof SubmitButton = typeof Button>(
    buttonComponent: ButtonType,
) {
    return styled(buttonComponent)`
        padding: 8px 21px;
        white-space: nowrap;
        & > .${svgIconClasses.root} {
            display: none;
        }
        & .${svgIconClasses.root} {
            width: 24px;
            height: 24px;
        }
        ${({ theme }) => `
        ${theme.breakpoints.down('sm')} {
            z-index: 4;
            position: fixed;
            bottom: 20px;
            right: 20px;
        }
    `}
    `
}
export default buttonMobileFixed

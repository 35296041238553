import { TypographyProps } from '@mui/material'
import { Link } from 'react-router-dom'

import Typography from 'components/mui/Typography'
import { useCreateResourcePath } from 'hooks'

interface CardLinkProps extends Omit<TypographyProps, 'id' | 'ref' | 'component'> {
    id: number | string
    disableLink?: boolean
    to?: string
}
const CardLink = ({ id, disableLink, to, ...props }: CardLinkProps) => {
    const createPath = useCreateResourcePath()

    return (
        <>
            {disableLink ? (
                <Typography
                    color="text.primary"
                    variant="h6"
                    component="div"
                    {...props}
                >
                    {props.children}
                </Typography>
            ) : (
                <Typography
                    variant="h6"
                    component={Link}
                    to={
                        to ||
                        createPath({
                            id,
                            type: 'edit',
                        })
                    }
                    {...props}
                >
                    {props.children}
                </Typography>
            )}
        </>
    )
}
CardLink.defaultProps = {
    id: 3,
    disableLink: false,
}

export default CardLink

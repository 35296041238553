import { CreateButton } from 'components'
import Header from 'components/pages/header'

const VendorCreateHeader = () => {
    return (
        <Header fixed>
            <Header.Block
                divider="hideOnMobile"
                mobileCenter
            >
                <Header.BackButton />
                <Header.Main>
                    <Header.Title>Create Vendor</Header.Title>
                </Header.Main>
                <Header.Aside fixed>
                    <CreateButton />
                </Header.Aside>
            </Header.Block>
        </Header>
    )
}

export default VendorCreateHeader

import { createContext, useContext } from 'react'

import { styled, Modal } from '@mui/material'

import { SetBlocker } from 'components/Blocker'

import QRScannerContent, { QRScannerContentProps } from './QRScannerContent'

const QRScannerModalContext = createContext(null)
export const useQRScannerModalContext = () => useContext(QRScannerModalContext)

interface QRScannerProps extends QRScannerContentProps {
    isOpen: boolean
    close: () => void
}

const QRBody = styled('div')`
    height: 100%;
    position: relative;

    video {
        width: 100%;
        height: 100%;
    }

    .scan-region-highlight {
        border-radius: 24px;
        outline: rgba(0, 0, 0, 0.6) solid 50vmax;
    }

    .scan-region-highlight-svg {
        display: none;
    }
`

const QRScanner = ({ isOpen, close, onDecode }: QRScannerProps) => {
    return (
        <Modal
            open={isOpen}
            onClose={close}
            BackdropProps={{
                sx: {
                    backgroundColor: '#00000090',
                },
            }}
        >
            <QRBody>
                <QRScannerModalContext.Provider value={{ close }}>
                    <SetBlocker close={close} />
                    <QRScannerContent onDecode={onDecode} />
                </QRScannerModalContext.Provider>
            </QRBody>
        </Modal>
    )
}

export default QRScanner

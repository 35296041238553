import { FC } from 'react'

import api from 'api'
import { ReactComponent as MotiveLogo } from 'assets/images/motive-logo.svg'
import { ReactComponent as SamsaraLogo } from 'assets/images/samsara-logo.svg'
import { MOTIVE_APP_ID, SAMSARA_APP_ID } from 'configs/config'
import { useQuery } from 'hooks'
import { authStore } from 'providers'
import { makeid } from 'utils'

const redirectUri = window.location.origin + '/configure/integrations'

const samsaraUrl =
    `https://api.samsara.com/oauth2/authorize\
?client_id=${SAMSARA_APP_ID}\
&redirect_uri=${redirectUri}?oauth2=samsara&response_type=code&state=` + makeid(20)

const motiveUrl = `https://api.gomotive.com/oauth/authorize\
?client_id=${MOTIVE_APP_ID}\
&redirect_uri=${redirectUri}?oauth2=motive\
&response_type=code\
&scope=fault_codes.read+locations.vehicle_locations_single+companies.read\
+vehicles.read+inspection_reports.manage+assets.read+locations.vehicle_locations_list`

type providers = 'samsara' | 'motive'

interface FleetpalUserDataType {
    email: string
    id: number
}
export interface Telematics {
    isActive: boolean

    connectionTimestamp: string

    error: string

    provider: providers

    syncDvirs: boolean

    syncMeters: boolean

    autosaveNewVehicles: boolean

    vehiclesNew: number

    vehiclesNotSynced: number

    vehiclesSynced: number

    vehiclesTotal: number

    vehiclesWithError: number

    fleetpalUserData?: FleetpalUserDataType

    hasUnseenVehicles: boolean
}
export type TelematicsType = {
    [key in providers]: TelematicsExtra & Telematics
}
export interface TelematicsExtra {
    logo: FC
    connectUrl: string
}

export const telematicsExtras: {
    [key in providers]: TelematicsExtra & Pick<Telematics, 'provider'>
} = {
    samsara: {
        connectUrl: samsaraUrl,
        logo: SamsaraLogo,
        provider: 'samsara',
    },
    motive: {
        connectUrl: motiveUrl,
        logo: MotiveLogo,
        provider: 'motive',
    },
}

const useTelematics = () => {
    const list = useQuery<{ results: Telematics[] }>(
        'telematics',
        async () => {
            const response: { results: Telematics[] } = await api.get('telematics')
            return {
                results: response.results,
            }
        },
        {
            meta: {
                refetchState: true,
            },
        },
    )

    const data = list.data?.results || ([] as Telematics[])

    let errorCount = 0
    let runningCount = 0
    let hasUnseenVehicles = false

    const telematics = { ...telematicsExtras } as TelematicsType

    data.forEach((item) => {
        telematics[item.provider] = {
            ...item,
            ...telematics[item.provider],
        }
        if (item.error) {
            errorCount += 1
        } else if (item.isActive) {
            runningCount += 1
        }

        if (item.hasUnseenVehicles) {
            hasUnseenVehicles = true
        }
    })

    return {
        errorCount,
        runningCount,
        list,
        data,
        telematics,
        redirectUri,
        hasUnseenVehicles,
    }
}

export default useTelematics

export const hasIntegrationActiveTasks = (provider: string, tasks?: string[]) => {
    return (tasks || authStore.currentCompany.activeTasks).some((item) => item.startsWith(provider))
}

import { Link } from 'react-router-dom'

import { UnitModel } from 'appTypes/models'
import AllGood from 'assets/images/all-good.svg'
import NoIntegration from 'assets/images/no-integration.svg'
import { Columns, ListFtuCardContainer, NoResultsCard, Typography } from 'components'
import { urls } from 'configs/config'

export const UnitDVIRNoIntegration = ({ archived }: { archived: UnitModel['archived'] }) => {
    return (
        <ListFtuCardContainer
            container
            sx={{ flex: 1 }}
        >
            <NoResultsCard
                transparent
                height="auto"
                title="No Integration"
                subtitle={
                    archived ? null : (
                        <Typography
                            color="inherit"
                            maxWidth="240px"
                        >
                            Connect an integration to view any reported issues with this unit
                        </Typography>
                    )
                }
                imageSrc={NoIntegration}
                imageProps={{
                    mb: {
                        sm: '32px',
                        xs: '16px',
                    },
                    width: {
                        sm: 'auto',
                        xs: '180px',
                    },
                }}
                action={
                    archived ? null : (
                        <Typography
                            component={Link}
                            to={urls.configure + '/integrations'}
                            mt="16px"
                            color="primary"
                            aria-label="Connect"
                        >
                            Connect
                        </Typography>
                    )
                }
            />
        </ListFtuCardContainer>
    )
}
export const UnitDVIRNoData = () => {
    return (
        <ListFtuCardContainer
            container
            sx={{ flex: 1 }}
        >
            <NoResultsCard
                transparent
                height="auto"
                title="Everything Looks Good!"
                subtitle={
                    <Columns>
                        <Typography color="inherit">No defects reported at this time.</Typography>
                        <Typography color="inherit">
                            We'll keep you informed if that changes.
                        </Typography>
                    </Columns>
                }
                imageSrc={AllGood}
                imageProps={{
                    mb: {
                        sm: '32px',
                        xs: '16px',
                    },
                    width: {
                        sm: 'auto',
                        xs: '180px',
                    },
                }}
            />
        </ListFtuCardContainer>
    )
}

import { SettingsOutlined } from '@mui/icons-material'
import { IconButton, alpha } from '@mui/material'
import { observer } from 'mobx-react'

import { Button, HideOnBreakpoint } from 'components'
import { hasIntegrationActiveTasks } from 'components/telematics/hooks/useTelematics'

import { ItemPropTypes } from '../../components/Item'
import useIntegrationDrawerTogler from '../../components/useIntegrationDrawerTogler'

export interface ConfigureButtonProps {
    item: ItemPropTypes['item']
    refetchTelematics: () => void
}

const ConfigureButton = observer(({ item, refetchTelematics }: ConfigureButtonProps) => {
    const openDrawer = useIntegrationDrawerTogler()
    const hasTasks = hasIntegrationActiveTasks(item.provider)

    return (
        <HideOnBreakpoint
            breakpoint="sm"
            replaceWith={
                <IconButton
                    disabled={hasTasks}
                    size="small"
                    sx={{
                        color: (theme) => alpha(theme.palette.text.primary, 0.54),
                    }}
                    onClick={() => openDrawer(item, refetchTelematics)}
                >
                    <SettingsOutlined />
                </IconButton>
            }
        >
            <Button
                disabled={hasTasks}
                size="large"
                startIcon={<SettingsOutlined />}
                onClick={() => openDrawer(item, refetchTelematics)}
            >
                Configure
            </Button>
        </HideOnBreakpoint>
    )
})

export default ConfigureButton

import { ReactElement } from 'react'

import { ListTotalBadge, ViewHeader } from 'components'

interface VendorContactsHeaderProps {
    actions?: ReactElement
}

const VendorContactsHeader = ({ actions }: VendorContactsHeaderProps) => {
    return (
        <ViewHeader title="Contact Persons">
            <ViewHeader.Content>
                <ListTotalBadge />
            </ViewHeader.Content>
            <ViewHeader.Content placement="rightMobile">{actions}</ViewHeader.Content>
        </ViewHeader>
    )
}

export default VendorContactsHeader

import { BooleanInput, ReferenceInput } from 'react-admin'
import { useFormContext } from 'react-hook-form'

import { PartModel } from 'appTypes/models'
import {
    UploadImage,
    CK33Selector,
    AutocompleteInput,
    SelectInput,
    GridBreak,
    TextInput,
    GridItemLayoutInDrawer,
    SectionTitleInDrawer,
    TextareaInput,
} from 'components'
import { GridContainerColumns } from 'components/pages/GridContainer'
import TagInput from 'pages/Configure/TagsManagement/components/TagInput'
import { maxLengthValidationText, requiredValidation, validateMaxLength } from 'utils'
import { costMaskParams } from 'utils/masks'

import { partsAvatarConfig, unitsOfMeasure } from '../config/constants'

export const unitsOfMeasureChoices = unitsOfMeasure.map((o) => ({
    id: o.value,
    name: o.label,
}))
export const DescriptionField = () => {
    const { watch } = useFormContext()
    const componentValue = watch('component')

    return (
        <TextareaInput<PartModel>
            source="description"
            validate={maxLengthValidationText}
            label="Description"
            disabled={!componentValue}
        />
    )
}

export const CK33SelectorComponent = () => {
    const { setValue } = useFormContext<PartModel>()

    return (
        <CK33Selector
            onSelectedChange={({ selected, input }) => {
                if (input.fieldState.isDirty) {
                    setValue('description', selected?.text || '')
                }
            }}
            required
        />
    )
}

const maxCharactersValidate = validateMaxLength(30)

const PartForm = () => {
    return (
        <>
            <SectionTitleInDrawer>Basic Details</SectionTitleInDrawer>
            <GridContainerColumns>
                <GridItemLayoutInDrawer>
                    <TextInput
                        source="number"
                        label="Part No"
                        validate={requiredValidation}
                    />
                </GridItemLayoutInDrawer>
                <GridItemLayoutInDrawer>
                    <CK33SelectorComponent />
                </GridItemLayoutInDrawer>
                <GridBreak />
                <GridItemLayoutInDrawer>
                    <DescriptionField />
                </GridItemLayoutInDrawer>
                <GridBreak />
                <GridItemLayoutInDrawer>
                    <ReferenceInput
                        source="manufacturer"
                        reference="vmrs/ck34"
                        perPage={20}
                    >
                        <AutocompleteInput
                            optionText="description"
                            label="Manufacturer"
                        />
                    </ReferenceInput>
                </GridItemLayoutInDrawer>
                <GridItemLayoutInDrawer>
                    <TextInput
                        source="manufacturerPartNumber"
                        label="Manufacturer Part Number"
                        validate={maxCharactersValidate}
                    />
                </GridItemLayoutInDrawer>
                <GridBreak />
                <GridItemLayoutInDrawer>
                    <UploadImage
                        source="image"
                        buttonUploadText="UPLOAD THUMBNAIL"
                        buttonChangeText="CHANGE THUMBNAIL"
                        defaultIcon={<partsAvatarConfig.Icon />}
                    />
                </GridItemLayoutInDrawer>
                <GridBreak />
                <GridItemLayoutInDrawer>
                    <TextInput
                        label="UPC"
                        source="universalProductCode"
                        validate={maxCharactersValidate}
                    />
                </GridItemLayoutInDrawer>
                <GridBreak />
                <GridItemLayoutInDrawer>
                    <SelectInput
                        label="UOM"
                        source="unitOfMeasure"
                        choices={unitsOfMeasureChoices}
                        disableEmptyValue
                    />
                </GridItemLayoutInDrawer>
                <GridBreak />
                <GridItemLayoutInDrawer>
                    <TextInput
                        source="cost"
                        label="Part Cost"
                        {...costMaskParams}
                    />
                </GridItemLayoutInDrawer>
                <GridBreak />
                <GridItemLayoutInDrawer>
                    <TagInput />
                </GridItemLayoutInDrawer>
            </GridContainerColumns>
            <SectionTitleInDrawer>Settings</SectionTitleInDrawer>
            <GridContainerColumns>
                <GridItemLayoutInDrawer>
                    <BooleanInput
                        source="positionApplicable"
                        label="Position Applicable"
                    />
                </GridItemLayoutInDrawer>
                <GridBreak />
                <GridItemLayoutInDrawer>
                    <BooleanInput
                        source="serializedPart"
                        label="Serialized Part"
                    />
                </GridItemLayoutInDrawer>
            </GridContainerColumns>
        </>
    )
}

export default PartForm

import { ListTotalBadge } from 'components'
import Header from 'components/pages/header'

const PartsListHeader = () => {
    return (
        <Header bgColor="transparent">
            <Header.Block>
                <Header.Main>
                    <Header.Spacer>
                        <Header.Title>Parts - Catalog</Header.Title>
                        <ListTotalBadge />
                    </Header.Spacer>
                </Header.Main>
                <Header.Aside fixed>
                    <Header.AddButton>CREATE PART</Header.AddButton>
                </Header.Aside>
            </Header.Block>
        </Header>
    )
}

export default PartsListHeader

import { useRef } from 'react'

import { useResizeObserverWidth } from 'hooks'
import { reportUrl } from 'pages/Reports'
import { createReportResource } from 'pages/Units/config/constants'
import { resolveDoublePriceMask } from 'utils'

import WidgetBase, { WidgetSkeleton } from '../../WidgetBase/WidgetBase'
import { useCreateDashboardWidgetLink, useDashboardWidget } from '../../WidgetsForm'

import { Clarification, CostPerUnitList, CostPerUnitChart } from './components'
import { CostPerUnitResponse } from './types'
import unitGetRoundedPercents from './unitGetRoundedPercents'

const description = `The Maintenance Cost widget calculates the aggregated expenses
associated with all work orders for the selected reporting period.`

let validData = false

const CostPerUnitWidget = () => {
    const createLink = useCreateDashboardWidgetLink()

    const elementRef = useRef()
    const isUnderWidthPoint = useResizeObserverWidth(elementRef, 360)
    const data = useDashboardWidget<CostPerUnitResponse>({
        resource: createReportResource('cost-per-unit'),
        sideEffect: (data) => {
            const finalData = unitGetRoundedPercents(data)
            validData = finalData.total > 0
            return finalData as CostPerUnitResponse
        },
    })
    if (!data) {
        return <WidgetSkeleton />
    }
    const { maxTotalUnits, ...ratioData } = data

    return (
        <WidgetBase
            ref={elementRef}
            headerProps={{
                title: 'Maintenance Cost',
                label:
                    typeof ratioData.total === 'number'
                        ? resolveDoublePriceMask(ratioData.total)
                        : null,
                tooltipText: description,
                clarification: (
                    <Clarification
                        {...ratioData}
                        showTooltip={isUnderWidthPoint}
                        validData={validData}
                    />
                ),
                chart: (
                    <CostPerUnitChart
                        {...ratioData}
                        validData={validData}
                    />
                ),
            }}
            infoProps={{
                title: 'Top Spenders',
                content: (
                    <CostPerUnitList
                        data={maxTotalUnits}
                        description={description}
                        validData={validData}
                    />
                ),
            }}
            link={createLink(reportUrl(reportUrl.urls.maintenanceCost))}
        />
    )
}
export default CostPerUnitWidget

import { useListContext } from 'react-admin'

import { renderOnPermission } from 'hocs'
import { useFileDownload, UseFileDownloadParams } from 'hooks'

import { ActionChildren, ListActionExtendedOption } from '../Actions'

import { downloadActionBaseOptions } from './downloadActionCommon'

export interface DownloadOneFromListActionProps extends UseFileDownloadParams {
    children: ActionChildren<{}, ListActionExtendedOption>
}

export const DownloadOneFromListAction = renderOnPermission(
    ({ children, ...rest }: DownloadOneFromListActionProps) => {
        const downloadOne = useFileDownload()
        const listContext = useListContext()
        const isMultiselectMode = Boolean(listContext.selectedIds.length)

        return children(
            {
                disabled: isMultiselectMode,
                ...downloadActionBaseOptions[rest.type],
                onClick: () => {
                    downloadOne(rest)
                },
            },
            {
                isMultiselectMode,
                listContext,
            },
        )
    },
    ({ props, permissions }) => permissions[props.type],
)

export const downloadOneFromListAction = (params: DownloadOneFromListActionProps) => (
    <DownloadOneFromListAction
        key={'download-one-from-list-' + params.type}
        {...params}
    />
)

import {
    TextInput,
    GridItem,
    GridContainer,
    FormNonFieldErrors,
    TelInput,
    EmailInput,
} from 'components'
import { PurchaseOrdersContactAlert } from 'pages/WorkOrders/components/WorkOrderPurchaseInvoice'
import { maxLengthValidation, emailValidation } from 'utils'

const VendorContactForm = () => {
    return (
        <>
            <PurchaseOrdersContactAlert />
            <GridContainer>
                <FormNonFieldErrors />
                <GridItem>
                    <TextInput
                        source="name"
                        label="Name"
                        validate={maxLengthValidation}
                    />
                </GridItem>
                <GridItem>
                    <EmailInput validate={emailValidation} />
                </GridItem>
                <GridItem>
                    <TelInput />
                </GridItem>
            </GridContainer>
        </>
    )
}
export default VendorContactForm

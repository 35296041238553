import { styled } from '@mui/material'

import { BoxContainer } from 'components'

export const HeaderLayoutContainer = styled(BoxContainer)`
    justify-content: space-between;
    max-height: 56px;
    padding: 16px 5px 16px 20px;
    background-color: ${({ theme }) => theme.palette.white};
    border-bottom: ${({ theme }) => '1px solid ' + theme.palette.grey[100]};
`

import { ReactNode } from 'react'

import { inject } from 'mobx-react'

import { useUtilityDrawerContext } from 'components/Drawer/UtilityDrawer'
import UtilityDrawerListEditor from 'components/Drawer/UtilityDrawerListEditor'
import { AuthStore } from 'providers/authStore'

import { LaborFormExtra } from '../WorkOrderJobCard/components'
import { jobsItemsShortResourceName } from '../WorkOrderJobItemsResource'

import { jobLaborSerializer } from './constants'

interface JobLaborCreateProps {
    isVendorWo: boolean
    children: ReactNode
    auth?: AuthStore
    onSuccess: () => void
}
const JobLaborCreate = inject('auth')((props: JobLaborCreateProps) => {
    const { extra, extraArgs } = useUtilityDrawerContext()

    const { job } = extra as LaborFormExtra
    const laborHourlyRate = props.auth.user.membership.company.laborHourlyRate

    return (
        <UtilityDrawerListEditor
            {...props}
            resource={{
                name: jobsItemsShortResourceName,
                resource: extraArgs.resource.resource + '/add_labor',
            }}
            defaultValues={{
                ...(props.isVendorWo ? {} : { hourlyRate: laborHourlyRate }),
                component: job.component,
            }}
            serializer={jobLaborSerializer}
        />
    )
})

export default JobLaborCreate

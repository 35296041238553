import { useLayoutEffect, useState } from 'react'

const useResizeObserver = (ref: React.MutableRefObject<undefined>, matchpoint: number) => {
    const [match, setMatch] = useState<boolean>(true)
    useLayoutEffect(() => {
        if (!ref.current) {
            return
        }
        const observer = new ResizeObserver((entries) => {
            setMatch(entries[0].contentRect.width < matchpoint)
        })
        observer.observe(ref.current)
        return () => {
            observer.disconnect()
        }
    }, [ref.current, matchpoint])
    return match
}
export default useResizeObserver

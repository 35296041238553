import api from 'api'
import { ConfirmConfig, ResourceType, useArchivedContext } from 'components'
import { useConfirm, useNotify } from 'hooks'

import { useResource } from '../components/resource/RaResourceContextProvider'

import { useFinalErrorHandler } from './useErrorHandler'

// TODO: refactor this. Remove path
export interface ArchiveParams {
    path: string

    resource?: ResourceType

    notifyMessage?: string

    onSuccess?: (response: any) => void

    confirmConfig?: Partial<Omit<ConfirmConfig, 'onConfirm'>>
}

const useArchive = () => {
    const isArchived = useArchivedContext()
    const notify = useNotify()
    const confirm = useConfirm()
    const resourceFromContext = useResource()

    const action = isArchived ? 'unarchive' : 'archive'
    const errorHandler = useFinalErrorHandler()

    const archiveResource = (
        params: ArchiveParams | ((nestedParams: { resource: ResourceType }) => ArchiveParams),
    ) => {
        const finalParams =
            typeof params === 'function' ? params({ resource: resourceFromContext }) : params

        const {
            path,
            notifyMessage = isArchived ? 'Successfully unarchived!' : 'Successfully archived!',
            confirmConfig,
            onSuccess,
        } = finalParams

        const archive = () => {
            api.post(path + '/' + action)
                .then((response) => {
                    onSuccess?.(response)
                    notify(notifyMessage, { type: 'info' })
                })
                .catch((err) => {
                    errorHandler(err)
                })
        }

        confirm({
            title: `Are you sure you want to ${isArchived ? 'unarchive' : 'archive'}?`,

            confirmType: isArchived ? 'UNARCHIVE' : 'ARCHIVE',
            ...confirmConfig,
            onConfirm: archive,
        })
    }

    return archiveResource
}

export default useArchive

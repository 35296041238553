import { FC } from 'react'

import { AccountCircleOutlined, DomainOutlined, LogoutOutlined } from '@mui/icons-material'
import {
    Menu,
    MenuList,
    MenuItem,
    useMediaQuery,
    Theme,
    styled,
    paperClasses,
    listClasses,
    SvgIcon,
    MenuItemProps,
} from '@mui/material'
import { alpha } from '@mui/material/styles'
import { inject, observer } from 'mobx-react'
import { Link } from 'react-admin'

import { Columns, DataAvatar, StyledElement, Typography } from 'components'
import DefaultUserAvatar from 'components/DefaultUserAvatar'
import { urls } from 'configs/config'
import { useLocationChange, useLogout, useNotify } from 'hooks'
import { AuthStore } from 'providers/authStore'

import SidebarProfileBilling from './SidebarProfileBilling'

// const StyledMembersImage = styled(Members)`
//     width: 20px;
//     height: 20px;
// `
const StyledSpan = styled('span')`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 2px;
    display: inline-block;
`
const ProfileMenuHeader = styled(Columns)`
    gap: 8px;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 16px 0px;
    border-bottom: ${({ theme }) => `1px solid ` + alpha(theme.palette.text.main, 0.12)};
`
interface SidebarProfileMenuProps {
    anchorEl: HTMLElement | null
    onClose: () => void
}

const SidebarProfileMenu: FC<SidebarProfileMenuProps> = ({ anchorEl, onClose }) => {
    const open = Boolean(anchorEl)
    const matches = useMediaQuery((theme: Theme) =>
        theme.breakpoints.up(theme.props.mobileViewBreakpoint),
    )

    return (
        <Menu
            sx={{
                [`& .${paperClasses.root}`]: {
                    width: 264,
                    padding: 0,
                },
                [`& .${listClasses.root}`]: {
                    padding: 0,
                },
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={
                matches
                    ? { vertical: 'center', horizontal: 'right' }
                    : { vertical: 'top', horizontal: 'right' }
            }
            transformOrigin={
                matches
                    ? { vertical: 'bottom', horizontal: 'left' }
                    : { vertical: 'bottom', horizontal: 'center' }
            }
            onClose={onClose}
        >
            <li role="menuitem">
                <MenuContent onClose={onClose} />
            </li>
        </Menu>
    )
}

interface ProfileMenuItemProps extends MenuItemProps {
    text: string
    Icon: typeof SvgIcon
}

export const ProfileMenuItem = ({ text, Icon, ...rest }: ProfileMenuItemProps) => {
    return (
        <MenuItem
            sx={{
                padding: '6px 16px 6px 13px',
                gap: '17px',
            }}
            {...rest}
        >
            <SvgIcon
                component={Icon}
                sx={{
                    width: '20px',
                    height: '20px',
                    color: (theme) => alpha(theme.palette.text.main, 0.54),
                }}
            />
            <Typography variant="menuItem">{text}</Typography>
        </MenuItem>
    )
}

const MenuContent: FC<Omit<SidebarProfileMenuProps, 'anchorEl'> & { auth?: AuthStore }> = inject(
    'auth',
)(
    observer(({ auth, onClose }) => {
        const notify = useNotify()
        const { user } = auth
        const logout = useLogout({
            onSuccess: () => {
                notify("You've been successfully logged out.", {
                    type: 'info',
                })
            },
        })
        useLocationChange(() => onClose())

        return (
            <>
                <ProfileMenuHeader>
                    <DataAvatar
                        sx={(theme) => ({
                            bgcolor: alpha(theme.palette.primary.main, 0.08),
                            color: theme.palette.primary.main,
                            width: '64px',
                            height: '64px',
                        })}
                        imageSrc={user.avatar}
                        defaultImage={<DefaultUserAvatar fontSize="20px" />}
                    />
                    <StyledElement sx={{ display: 'grid', px: '16px' }}>
                        <Typography
                            variant="alertTitle"
                            sx={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {user.name}
                        </Typography>
                    </StyledElement>
                    <Typography
                        sx={{
                            width: '100%',
                            boxSizing: 'border-box',
                            padding: '0px 12px',
                            display: 'flex',
                            justifyContent: 'center',
                            whiteSpace: 'nowrap',
                        }}
                        variant="inputLabel"
                    >
                        {user.membership.roleData.label} •
                        <StyledSpan>{user.membership.company.name}</StyledSpan>
                    </Typography>
                </ProfileMenuHeader>
                <SidebarProfileBilling />
                <Columns
                    sx={{
                        gap: 5,
                        justifyContent: 'center',
                        alignItems: 'left',
                        padding: '8px 0px',
                    }}
                >
                    <MenuList>
                        <Link
                            to={urls.userProfile}
                            role="menuitem"
                        >
                            <ProfileMenuItem
                                role="none"
                                Icon={AccountCircleOutlined}
                                text="Personal Account"
                            />
                        </Link>
                        <Link
                            to={urls.company}
                            role="menuitem"
                        >
                            <ProfileMenuItem
                                role="none"
                                Icon={DomainOutlined}
                                text="Company Account"
                            />
                        </Link>

                        <ProfileMenuItem
                            onClick={logout}
                            Icon={LogoutOutlined}
                            text="Log Out"
                        />
                    </MenuList>
                </Columns>
            </>
        )
    }),
)

export default SidebarProfileMenu

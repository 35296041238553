import { useListContext } from 'react-admin'

import { useArchivedContext } from 'components/context'
import { basePermissions } from 'configs/constants'
import { ArchiveManyFromListParams, useArchiveManyFromList, useResourcePermissions } from 'hooks'
import { hasPermission } from 'utils'

import { ActionChildren, ListActionExtendedOption } from '../Actions'

import { ArchiveActionArchiveOption, archiveActionBaseOptions } from './archiveActionCommon'

interface ArchiveManyFromListActionProps extends ArchiveManyFromListParams {
    children: ActionChildren<{}, ListActionExtendedOption & ArchiveActionArchiveOption>
}

export const ArchiveManyFromListAction = ({
    children,
    ...rest
}: ArchiveManyFromListActionProps) => {
    const archiveMany = useArchiveManyFromList()
    const isArchived = useArchivedContext()
    const listContext = useListContext()

    const permissions = useResourcePermissions(rest.resource)

    if (
        !hasPermission(
            isArchived
                ? permissions[basePermissions.unarchiveBulk]
                : permissions[basePermissions.archiveBulk],
        )
    ) {
        return null
    }

    return children(
        {
            ...archiveActionBaseOptions(isArchived),
            onClick: () => {
                archiveMany(rest)
            },
        },
        {
            isMultiselectMode: Boolean(listContext.selectedIds.length),
            listContext,
            isArchived,
        },
    )
}

export const archiveManyFromListAction = (params: ArchiveManyFromListActionProps) => (
    <ArchiveManyFromListAction
        {...params}
        key="archive-many-from-list"
    />
)

import { AttachMoney } from '@mui/icons-material'
import { useTheme, SvgIcon } from '@mui/material'

import { WidgetPieChartBase } from '../../components'
import { JobItemsCalculations, unitClarificationMap } from '../../components/types'

interface CostPerUnitChartProps extends JobItemsCalculations {
    validData: boolean
    PieIcon?: typeof SvgIcon
}
const CostPerUnitChart = ({
    positiveTotal,
    PieIcon = AttachMoney,
    validData,
    ...ratioData
}: CostPerUnitChartProps) => {
    const { palette } = useTheme()
    const total = positiveTotal

    return (
        <WidgetPieChartBase
            valid={validData}
            icon={PieIcon}
            data={unitClarificationMap.map((jobItem) => ({
                id: jobItem.label,
                value: ratioData[jobItem.total],
                color: palette.charts[jobItem.tint],
                total,
            }))}
        />
    )
}
export default CostPerUnitChart

import { Box, SvgIcon } from '@mui/material'

import { ReactComponent as BlackLogo } from 'assets/images/logo-black.svg'
import { StyledElement, StatusText, Typography, Button } from 'components'
import { landingPageUrl } from 'configs/config'

const EnterpricePlan = () => {
    return (
        <Box
            bgcolor="white"
            borderRadius="4px"
            boxShadow={1}
            p="20px"
            color="text.primary"
        >
            <StatusText statusColor={(theme) => theme.palette.text.primary}>
                Enterprise Pricing
            </StatusText>
            <Box
                mt="19px"
                mb="20px"
                display="flex"
                alignItems="center"
            >
                <SvgIcon
                    component={BlackLogo}
                    sx={{ width: '32px', height: '32px' }}
                    inheritViewBox
                />

                <Typography
                    variant="subtitle1"
                    ml="12px"
                    color="inherit"
                >
                    Fleetpal Enterprise
                </Typography>
            </Box>

            <Typography
                variant="h6"
                color="inherit"
                mb="29px"
            >
                Looking for something else?
            </Typography>

            <Typography
                color="inherit"
                variant="body2"
                mb="40px"
            >
                If you’re a specialized fleet or have over 100 units, you may qualify for{' '}
                <StyledElement
                    as="span"
                    sx={{ fontWeight: 'bold' }}
                >
                    Enterprise Pricing
                </StyledElement>
            </Typography>
            <a
                href={landingPageUrl + '/contact'}
                target="_blank"
                rel="noreferrer"
            >
                <Button
                    variant="contained"
                    fullWidth
                    size="large"
                >
                    Contact Sales Now
                </Button>
            </a>
        </Box>
    )
}

export default EnterpricePlan

import { PropsWithChildren } from 'react'

import { styled } from '@mui/material'
import { Link, To } from 'react-router-dom'

import { Button, Columns } from 'components'
type MonitorButtonProps = {
    navigateTo: To
}
export const MonitorButton = ({ children, navigateTo }: MonitorButtonProps & PropsWithChildren) => {
    return (
        <Button
            color="inherit"
            sx={(theme) => ({
                textTransform: 'inherit',
                justifyContent: 'flex-start',
                padding: '4px',
            })}
            variant="text"
            component={StyledMonitorLink}
            to={navigateTo || ''}
        >
            <Columns
                sx={{ alignItems: 'flex-start' }}
                gap="8px"
            >
                {children}
            </Columns>
        </Button>
    )
}
const StyledMonitorLink = styled(Link)`
    height: 61px;
    flex: 1;
    &:hover {
        color: ${({ theme }) => theme.palette.primary.main};
    }
`
export default MonitorButton

import { JobItemsCalculations } from '../components/types'

export type UncategorizedJobItems = {
    uncategorized: number
    uncategorizedTax: number
    uncategorizedServices: number
    uncategorizedFees: number
    uncategorizedLabor: number
    uncategorizedParts: number
}

const includeUncategorized = <T extends { id: string | number; total: number }>(
    ratioData: Partial<UncategorizedJobItems & JobItemsCalculations>,
    initialArray: T[],
) => {
    return [
        {
            id: 'uncategorized',
            description: 'Uncategorized',
            code: null,
            total: ratioData.uncategorized,
            totalPercent: (ratioData.positiveTotal / ratioData.uncategorized) * 100,
            totalFees: ratioData.uncategorizedFees,
            totalTax: ratioData.uncategorizedTax,
            totalServices: ratioData.uncategorizedServices,
            totalLabor: ratioData.uncategorizedLabor,
            totalParts: ratioData.uncategorizedParts,
        },
        ...initialArray,
    ]
        .filter((a) => a.total !== null && !(a.id === 'uncategorized' && !a.total))
        .sort((a, b) => b.total - a.total)
        .slice(0, 3) as T[]
}
export default includeUncategorized

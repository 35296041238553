import { ReactNode, useEffect, useRef } from 'react'

import { inject, observer } from 'mobx-react'
import {
    ListControllerResult,
    RaRecord,
    useListContext,
    useRemoveFromStore,
    useResourceContext,
} from 'react-admin'

import { UserPreferences } from 'appTypes/models'
import Datagrid, {
    checkboxColumnName,
    DatagridColumnsProps,
    ExportColumnType,
    getVisibleColumns,
} from 'components/Datagrid/Datagrid'
import CardList, { CardListProps } from 'components/list/CardList'
import ListSelection, { ListSelectionProps } from 'components/list/ListSelection'
import ListSortDrawer from 'components/list/ListSortDrawer'
import { ResourceType, useResource } from 'components/resource'
import { filterSearchText } from 'configs/constants'
import { AuthStore } from 'providers/authStore'

import ListInternalContextProvider from './ListInternalContextProvider'
import { ListSortContentProps } from './ListSortDrawerContent'
import ListToolbar, { ListToolbarProps } from './ListToolbar'
import { useListView, ListViewMode } from './ListViewContext'
import RenderList, { RenderListProps } from './RenderList'
import ListFilterDrawer from './filter/ListFilterDrawer'
import { FilterConfig } from './filter/ListFilterForm'
export interface ListProps<RecordType extends RaRecord = any>
    extends ListSelectionProps,
        Pick<RenderListProps, 'renderNoResults' | 'listFTUProps'> {
    exportFileName?: string
    columnsCfg?: DatagridColumnsProps
    cardsCfg?: CardListProps['cardsCfg']
    filtersCfg?: FilterConfig<RecordType>
    sortCfg?: ListSortContentProps<RecordType>
    preferencesResource?: ResourceType
    renderList?: (params: { listContext: ListControllerResult }) => ReactNode
    disableViewsSwitch?: boolean
    toolbarContent?: Pick<ListToolbarProps, 'contentBeforeViews' | 'contentUnderToolbar'>
    excludeFilterFields?: (keyof RecordType)[]
    disableManageColumns?: boolean
    disableExportButton?: boolean
    hideSearch?: boolean
    disableSelectRecord?: (record: RecordType) => string
}

export const excludedFilterNames = [filterSearchText, 'ordering']

const List = inject('auth')(
    observer(
        ({
            exportFileName,
            columnsCfg,
            cardsCfg,
            filtersCfg,
            listFTUProps,
            sortCfg,
            bulkActions,
            auth,
            preferencesResource: preferencesResourceProp,
            renderList,
            disableViewsSwitch,
            toolbarContent,
            renderNoResults,
            excludeFilterFields,
            disableManageColumns,
            disableExportButton,
            hideSearch,
            disableSelectRecord,
        }: ListProps & { auth?: AuthStore }) => {
            const visibleColsRef = useRef<ExportColumnType[]>([])
            const { viewMode } = useListView()

            const listContext = useListContext()
            const preferencesResource = useResource(preferencesResourceProp)
            const finalVisibleColumns = useRef<{ [key: string]: boolean }>({})

            const excludeFields = [
                ...excludedFilterNames,
                ...((excludeFilterFields as string[]) || []),
                ...(filtersCfg?.excludeFields || []),
            ]

            const { selectedIds } = listContext

            const visibleColumns =
                auth.preferences.resources[preferencesResource.name]?.visibleColumns
            const visibleColumnsReset = (
                <VisibleColumnsReset
                    visibleColumns={visibleColumns}
                    visibleColsRef={visibleColsRef}
                    columnsCfg={columnsCfg}
                    finalVisibleColumnsRef={finalVisibleColumns}
                />
            )

            return (
                <ListInternalContextProvider>
                    <ClearListDataOnUnmount />
                    {selectedIds.length === 0 ? (
                        <ListToolbar
                            exportFileName={exportFileName}
                            excludeFields={excludeFields}
                            disableSort={!sortCfg}
                            disableFilter={!filtersCfg}
                            filtersCfg={filtersCfg}
                            resetColumns={columnsCfg.resetColumns}
                            visibleColsRef={visibleColsRef}
                            disableViewsSwitch={disableViewsSwitch}
                            disableManageColumns={disableManageColumns}
                            disableExportButton={disableExportButton}
                            hideSearch={hideSearch}
                            {...toolbarContent}
                        />
                    ) : null}
                    <ListSelection
                        bulkActions={bulkActions}
                        disableSelectRecord={disableSelectRecord}
                    />
                    {filtersCfg && (
                        <ListFilterDrawer
                            filtersCfg={filtersCfg}
                            excludeFields={excludeFields}
                        />
                    )}
                    {sortCfg && <ListSortDrawer {...sortCfg} />}
                    <RenderList
                        renderNoResults={renderNoResults}
                        listFTUProps={listFTUProps}
                        excludeFields={excludeFields}
                    >
                        <>
                            {viewMode !== ListViewMode.list && visibleColumnsReset}

                            {renderList?.({
                                listContext,
                            })}
                            {!renderList &&
                                (viewMode === ListViewMode.list ? (
                                    <>
                                        {visibleColumnsReset}
                                        <Datagrid
                                            disableSelectRecord={disableSelectRecord}
                                            {...columnsCfg}
                                            visibleColsRef={visibleColsRef}
                                            preferencesResource={preferencesResource}
                                            initialVisibleColsRef={finalVisibleColumns}
                                        />
                                    </>
                                ) : (
                                    <CardList
                                        cardsCfg={cardsCfg}
                                        disableSelectRecord={disableSelectRecord}
                                    />
                                ))}
                        </>
                    </RenderList>
                </ListInternalContextProvider>
            )
        },
    ),
)

export default List

interface VisibleColumnsResetProps {
    visibleColumns: UserPreferences['resources']['']['visibleColumns']
    visibleColsRef: React.MutableRefObject<ExportColumnType[]>
    columnsCfg: DatagridColumnsProps
    finalVisibleColumnsRef: React.MutableRefObject<{ [key: string]: boolean }>
}

const VisibleColumnsReset = ({
    visibleColumns,
    visibleColsRef,
    columnsCfg,
    finalVisibleColumnsRef,
}: VisibleColumnsResetProps) => {
    const didMount = useRef(false)
    if (!didMount.current) {
        let columns = columnsCfg?.columns || []

        if (visibleColumns) {
            columns = columns.filter((col) => visibleColumns[col.field] !== false)
            finalVisibleColumnsRef.current = visibleColumns
        } else if (columnsCfg?.resetColumns) {
            columns = columns.filter((col) => columnsCfg.resetColumns[col.field] !== false)
            finalVisibleColumnsRef.current = columnsCfg.resetColumns
        }
        if (typeof finalVisibleColumnsRef.current[checkboxColumnName] === 'boolean') {
            finalVisibleColumnsRef.current.__check__ =
                finalVisibleColumnsRef.current[checkboxColumnName]
            delete finalVisibleColumnsRef.current[checkboxColumnName]
        }
        visibleColsRef.current = getVisibleColumns(columns)
        didMount.current = true
    }

    return null
}

// Clear some data from RA store after leaving the list
const ClearListDataOnUnmount = () => {
    const removeFromStore = useRemoveFromStore()
    const resource = useResourceContext()

    useEffect(() => {
        return () => {
            removeFromStore(`${resource}.selectedIds`)
        }
    }, [])

    return null
}

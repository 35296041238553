import { SvgIcon } from '@mui/material'
import { RaRecord, useShowContext } from 'react-admin'

import { TagModel } from 'appTypes/models'
import { ReactComponent as TagsImage } from 'assets/images/tags.svg'
import {
    BoxContainer,
    Columns,
    InfoBadge,
    InfoCard,
    InfoCardHeader,
    SimpleScrollbar,
    Spacer,
    Typography,
} from 'components'
import infoCardStyles from 'components/InfoCard/styles'
import TagField from 'pages/Configure/TagsManagement/components/TagField'

interface TagsRecord extends RaRecord {
    tagsData: TagModel[]
}

const TagsCard = ({ open, hideAddButton }: { open: () => void; hideAddButton?: boolean }) => {
    const { record } = useShowContext<TagsRecord>()

    if (!record) {
        return null
    }

    const tagsCount = record.tagsData?.length || 0

    return (
        <InfoCard
            sx={{
                pb: '0px',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                overflow: 'hidden',
            }}
        >
            <InfoCardHeader
                dividerProps={{ sx: { mb: 0 } }}
                title={
                    <Spacer variant="sm">
                        <span>Tags</span>
                        <InfoBadge badgeContent={tagsCount} />
                    </Spacer>
                }
            />
            {tagsCount ? (
                <SimpleScrollbar sx={{ height: '100%', overflow: 'auto' }}>
                    <BoxContainer
                        gap="4px"
                        flexWrap="wrap"
                        pt={`var(${infoCardStyles.headerDividerOffsetBottom})`}
                        pb={`var(${infoCardStyles.p})`}
                    >
                        {record?.tagsData.map((tag) => (
                            <TagField
                                tag={tag}
                                key={tag.id}
                            />
                        ))}
                    </BoxContainer>
                </SimpleScrollbar>
            ) : (
                <TagsEmpty
                    open={open}
                    hideAddButton={hideAddButton}
                />
            )}
        </InfoCard>
    )
}

export default TagsCard

const TagsEmpty = ({ open, hideAddButton }: { open: () => void; hideAddButton?: boolean }) => {
    return (
        <BoxContainer
            gap="27px"
            flex="1"
        >
            <SvgIcon
                component={TagsImage}
                inheritViewBox
                sx={{ width: '80px', height: '78px' }}
            />
            <Columns gap="8px">
                <Typography variant="h5">No Tags</Typography>

                {hideAddButton ? null : (
                    <Typography
                        variant="body1"
                        onClick={open}
                        color={(theme) => theme.palette.primary.main}
                        sx={{ cursor: 'pointer' }}
                    >
                        Add Tag
                    </Typography>
                )}
            </Columns>
        </BoxContainer>
    )
}

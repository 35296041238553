import { FC } from 'react'

import { WorkOrderModel } from 'appTypes/models'
import {
    GridBreak,
    DateTimeInput,
    SectionTitleInDrawer,
    GridContainerColumns,
    TextareaInput,
} from 'components'
import { DropdownIconText } from 'components/IconText'
import { GridItemLayoutInDrawer } from 'components/pages/GridItemLayout'
import { requiredValidation, maxLengthValidationText } from 'utils'

import { prioritiesArray, repairClassArray } from '../config/constants'

import UnitField, { WoFromInputProps } from './UnitField'
import WoSelectInput from './WoSelectInput'
import WorkOrderFormMeter from './WorkOrderFormMeter'

export interface WorkOrderFormProps {
    inputsProps?: {
        [key in keyof WorkOrderModel]?: WoFromInputProps
    }
}

const WorkOrderForm: FC<WorkOrderFormProps> = ({ inputsProps = {} }) => {
    return (
        <>
            <SectionTitleInDrawer>Basic Details</SectionTitleInDrawer>
            <GridContainerColumns>
                <GridItemLayoutInDrawer>
                    <UnitField {...inputsProps.unit} />
                </GridItemLayoutInDrawer>
                <GridBreak />
                <GridItemLayoutInDrawer>
                    <WoSelectInput
                        optionText={(option: typeof prioritiesArray[0]) => (
                            <DropdownIconText>
                                {option.icon} {option.name}
                            </DropdownIconText>
                        )}
                        label="Priority"
                        source="priority"
                        validate={requiredValidation}
                        choices={prioritiesArray}
                        disableEmptyValue
                        defaultValue="LOW"
                    />
                </GridItemLayoutInDrawer>
            </GridContainerColumns>

            <WorkOrderFormMeter />

            <SectionTitleInDrawer>Repair Details</SectionTitleInDrawer>
            <GridContainerColumns>
                <GridItemLayoutInDrawer>
                    <WoSelectInput
                        optionText={(option: typeof repairClassArray[0]) => (
                            <DropdownIconText>
                                {option.icon} {option.name}
                            </DropdownIconText>
                        )}
                        label="Repair Class"
                        source="repairPriorityClass"
                        choices={repairClassArray}
                        validate={requiredValidation}
                        disableEmptyValue
                    />
                </GridItemLayoutInDrawer>
                <GridBreak />

                <GridItemLayoutInDrawer>
                    <DateTimeInput
                        source="started"
                        label="Start Date &amp; Time"
                        validate={requiredValidation}
                        defaultValue={new Date()}
                    />
                </GridItemLayoutInDrawer>

                <GridBreak />

                <GridBreak />
                <GridItemLayoutInDrawer>
                    <TextareaInput<WorkOrderModel>
                        source="description"
                        label="Description"
                        validate={maxLengthValidationText}
                    />
                </GridItemLayoutInDrawer>
            </GridContainerColumns>
        </>
    )
}

export default WorkOrderForm

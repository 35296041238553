import { useState, useEffect, FC, ReactNode } from 'react'

import { Elements, useStripe, useElements, CardNumberElement } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { inject } from 'mobx-react'
import { useRecordContext } from 'react-admin'
import { useNavigate } from 'react-router-dom'

import { locationStateBlockerForceRedirect, useToggleFullPageLoader } from 'components'
import { useUtilityDrawerContext } from 'components/Drawer/UtilityDrawer'
import UtilityDrawerForm from 'components/Drawer/UtilityDrawerForm'
import { STRIPE_PUBLIC_KEY, urls } from 'configs/config'
import { useFinalErrorHandler, useNotify } from 'hooks'
import { AuthStore } from 'providers/authStore'

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY)

interface FormProps {
    type: 'upgrade' | 'change'
    children: ReactNode
}

interface LoadingRecord {
    loading: boolean
}

export const useCheckoutFormIsLoading = () => {
    return useRecordContext<LoadingRecord>().loading
}
const paymentCallbackDefault = async (paymentMethodId: string) => {
    //
}

const CheckoutForm: FC<FormProps & { auth?: AuthStore }> = inject('auth')(
    ({ children, auth, type }) => {
        const stripe = useStripe()
        const elements = useElements()
        const notify = useNotify()
        const [loading, setLoading] = useState<boolean>(false)
        const { controller } = useUtilityDrawerContext()
        const navigate = useNavigate()
        const errorHandler = useFinalErrorHandler()

        let paymentCallback = paymentCallbackDefault
        let successMsg = ''
        if (type === 'upgrade') {
            paymentCallback = auth.upgradeBillingToProPlan.bind(auth)
            successMsg = 'Successfully upgraded'
        } else {
            paymentCallback = auth.changePaymentMethod.bind(auth)
            successMsg = 'Successfully changed payment method'
        }

        useEffect(() => {
            if (loading) {
                const unblock = controller.block()

                return unblock
            }
        }, [loading])

        useToggleFullPageLoader({ isOpen: loading })

        const handleSubmit = async () => {
            if (!elements) {
                return
            }

            setLoading(true)

            const payload = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardNumberElement),
            })

            if (payload.error) {
                notify(payload.error.message, {
                    type: 'error',
                })
                setLoading(false)
                return {} // this prevents drawer from closing
            }

            await paymentCallback(payload.paymentMethod.id)
                .then(() => {
                    navigate(urls.company, {
                        state: locationStateBlockerForceRedirect,
                    })
                    notify(successMsg, {
                        type: 'success',
                    })
                })
                .catch((error) => {
                    errorHandler(error)
                })
        }

        const record: LoadingRecord = {
            loading,
        }

        return (
            <UtilityDrawerForm
                record={record}
                onSubmit={handleSubmit}
                warnWhenUnsavedChanges={false}
            >
                {children}
            </UtilityDrawerForm>
        )
    },
)

const PaymentFormWrapper: FC<FormProps> = ({ type, children }) => {
    return (
        <Elements stripe={stripePromise}>
            <CheckoutForm type={type}>{children}</CheckoutForm>
        </Elements>
    )
}

export default PaymentFormWrapper

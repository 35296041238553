import { useRef } from 'react'

import { useStoreContext } from 'react-admin'

import { globalStore } from 'providers/storeProvider'

import useWillUnmount from './useWillUnmount'

const useClearResource = (name: string) => {
    const { removeItem } = useStoreContext()

    // removeItem finishes after the next page render
    // this causes the store to be shared (like filters) between pages that uses same resource
    // this is why delete it before the first render
    const didMount = useRef(false)
    if (!didMount.current) {
        didMount.current = true
        delete globalStore[name]
    }

    useWillUnmount(() => removeItem(name), [name])
}

export default useClearResource

import { ResourceWithClearEffect } from 'components'

import PartCreate from './Create/PartCreate'
import PartsList from './List'
import PartShow from './Show'
import { partsResource } from './config/constants'

const Parts = () => {
    return (
        <ResourceWithClearEffect
            name={partsResource}
            list={PartsList}
            create={PartCreate}
            edit={PartShow}
        />
    )
}

export default Parts

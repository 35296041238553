import { FC } from 'react'

import { useWatch } from 'react-hook-form'

import { UnitPMIntervalMeterType } from 'appTypes/models'

import { LastDoneHelperTextBase } from '../PMEditor'

interface UnitPMCardResetLastDoneHelperTextProps {
    interval: UnitPMIntervalMeterType
    name: string
}

const UnitPMCardResetLastDoneHelperText: FC<UnitPMCardResetLastDoneHelperTextProps> = ({
    interval,
    name,
}) => {
    const lastDone = useWatch({ name })

    return (
        <LastDoneHelperTextBase
            meterType={interval.type}
            value={interval.value}
            lastDone={lastDone}
        />
    )
}

export default UnitPMCardResetLastDoneHelperText

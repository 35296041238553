import { Children, FC } from 'react'

import { Box, BoxProps } from '@mui/material'

const FlexReverse: FC<BoxProps> = ({ children, ...rest }) => {
    return (
        <Box
            display="flex"
            flexDirection="column-reverse"
            {...rest}
        >
            {Children.toArray(children).reverse()}
        </Box>
    )
}

export default FlexReverse

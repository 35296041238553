import { Identifier, useResourceContext } from 'react-admin'

import { AllProps, FileType } from 'appTypes'
import { ResourceType } from 'components'
import { downloadFile } from 'utils'

import { useFinalErrorHandler } from './useErrorHandler'

export interface UseFileDownloadParams {
    id?: Identifier
    url?: string
    filename: string
    type: keyof typeof FileType
    resource?: ResourceType
    headers?: AllProps
    queryParams?: AllProps
}

const useFileDownload = () => {
    const resource = useResourceContext()
    const errorHandler = useFinalErrorHandler()

    return async ({
        id,
        filename,
        type,
        resource: resourceProp,
        headers,
        url,
        queryParams: queryParamsProp,
    }: UseFileDownloadParams) => {
        const queryParams = {
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            ...queryParamsProp,
        }

        try {
            await downloadFile({
                href:
                    url ||
                    (resourceProp ? resourceProp.resource : resource) +
                        '/' +
                        (id ? id + '/' : '') +
                        type,
                filename: filename + '.' + type,
                type: FileType[type],
                headers,
                queryParams,
            })
        } catch (err) {
            errorHandler(err)
        }
    }
}

export default useFileDownload

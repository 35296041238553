import { ListControllerResult, useDeleteMany, useListContext } from 'react-admin'

import useDelete, { UseDeleteParams } from './useDelete'

export interface DeleteManyParams extends Omit<UseDeleteParams, 'onConfirm'> {
    listContext?: ListControllerResult

    onSuccess?: () => void
}

const useDeleteManyFromList = () => {
    const deleteResource = useDelete()
    const listContextFromContext = useListContext()
    const [deleteMany] = useDeleteMany()

    return ({ resource, listContext, onSuccess, ...rest }: DeleteManyParams = {}) => {
        deleteResource({
            ...rest,
            onConfirm: async (params) => {
                const finalResource = resource || params.resource
                const finalListContext = listContext || listContextFromContext
                const { selectedIds, onUnselectItems, refetch } = finalListContext
                await deleteMany(
                    finalResource.resource,
                    { ids: selectedIds },
                    {
                        onSuccess: () => {
                            onSuccess?.()
                            params.onSuccess()
                            onUnselectItems()
                            refetch()
                        },
                        onError: params.onError,
                    },
                )
            },
        })
    }
}

export default useDeleteManyFromList

import { StatusType } from 'appTypes'
import { UnitModel } from 'appTypes/models'
import { ResourceAvatar } from 'appTypes/models/ResourceAvatar'
import Icons from 'components/icons'
import { ArchiveOneParams, DeleteOneParams } from 'hooks'
import { generateResource, Serializer } from 'utils'

export const pmResource = generateResource({
    name: 'pm-schedules',
    resource: 'pm-schedules',
})
export const dvirResource = generateResource({
    name: 'defects',
    resource: 'defects',
})
export const reportsUnitsResource = generateResource({
    name: 'report-units',
    resource: '/reports/units',
})
export const unitResource = generateResource({
    name: 'units',
    resource: 'units',
})
export const ck14Resource = generateResource({
    name: 'reason-for-repair',
    resource: 'vmrs/ck14',
})
export const ck2Resource = generateResource({
    name: 'equipment-category',
    resource: 'vmrs/ck2',
})
export const ck33Resource = generateResource({
    name: 'system-category',
    resource: 'vmrs/ck33',
})
export const createReportResource = (report: string) => `reports/${report}`
export const archivedUnitResource = generateResource({
    name: 'archived-units',
    resource: 'units/archived',
})

export const getUnitResource = (isArchived) => {
    return isArchived ? archivedUnitResource : unitResource
}

export const deleteUnitAction = (hasWO: boolean): DeleteOneParams => {
    if (hasWO) {
        return {
            confirmConfig: {
                title: `This Unit cannot be deleted, because a related Work Order exists.`,
                confirmButtonProps: null,
                cancelButtonProps: { children: 'Close' },
            },
        }
    }
    return {
        confirmConfig: {
            title: 'Are you sure you want to delete the selected Unit?',
        },
    }
}

export const unitStatuses: { [key in UnitModel['status']]: StatusType } = {
    IN_SERVICE: {
        text: 'In Service',
        iconColor: (theme) => theme.palette.success.main,
    },
    OUT_OF_SERVICE: {
        text: 'Out Of Service',
        iconColor: (theme) => theme.palette.error.main,
    },
}

export const archiveUnitActionParams = (
    isArchived: boolean,
    synced: boolean,
    hasWO: boolean,
): ArchiveOneParams => {
    if (hasWO) {
        return {
            confirmConfig: {
                title: `This Unit cannot be archived, because it has an open Work Order.`,
                confirmButtonProps: null,
                cancelButtonProps: { children: 'Close' },
            },
        }
    }

    return {
        confirmConfig: {
            title: `Are you sure you want to ${
                isArchived ? 'unarchive' : 'archive'
            } the selected Unit?`,
            content:
                synced && !isArchived
                    ? "Archived units won't sync new data until reactivated. And your last reported defects won’t be synced with Fleetpal."
                    : undefined,
        },
    }
}
export const unitStatusChoices = Object.entries(unitStatuses).map(([key, value]) => ({
    id: key as UnitModel['status'],
    name: value.text,
    iconColor: value.iconColor,
}))

export const deleteMeterAction: DeleteOneParams = {
    confirmConfig: {
        title: 'Are you sure you want to delete the selected Meter?',
    },
}

export const unitSerializer: Serializer<UnitModel> = [
    'number',
    'name',
    'vin',
    'licensePlate',
    'model',
    { name: 'modelYear', parse: 'year' },
    'engineModel',
    { name: 'engineHp', parse: 'number' },
    { name: 'engineVmrsManufacturer', parse: 'emptyToNull' },
    'transmissionModel',
    { name: 'transmissionGears', parse: 'number' },
    'color',
    'tireSize',
    { name: 'status', parse: 'disableOnEdit' },
    { name: 'photo', parse: 'file' },
    { name: 'vmrsEquipmentCategory', parse: 'emptyToNull' },
    { name: 'vmrsManufacturer', parse: 'emptyToNull' },
    { name: 'transmissionVmrsManufacturer', parse: 'emptyToNull' },
    { name: 'tags', parse: 'emptyToNull' },
]
export const unitAvatarConfig: ResourceAvatar = {
    Icon: Icons.Units,
}

import { Identifier, useRecordContext } from 'react-admin'

import { PartModel } from 'appTypes/models'
import {
    formatCK33CodeByLevel,
    IconBox,
    InfoCard,
    InfoCardDetails,
    InfoCardHeader,
    Section,
    SliderView,
    TagsCard,
    ViewHeader,
} from 'components'
import Icons from 'components/icons'
import { PartCk33Label, PartDrawerToggler, PartSliderSkeleton } from 'pages/Parts/components'
import { resolveDoublePriceMask } from 'utils'

const PartEditOpenDrawerButton = ({ id }: { id?: Identifier }) => {
    return (
        <PartDrawerToggler
            id={id}
            children={({ onClick }) => (
                <IconBox
                    title="Edit"
                    onClick={onClick}
                >
                    <Icons.Edit />
                </IconBox>
            )}
        />
    )
}
const skeletonSlides = new Array(2).fill(0).map((v, i) => <PartSliderSkeleton key={i} />)
const PartDetails = () => {
    const record = useRecordContext<PartModel>()
    const slides = record
        ? [
              <PartDetailsCard />,
              <PartDrawerToggler
                  id={record?.id}
                  children={({ onClick }) => <TagsCard open={onClick} />}
              />,
          ]
        : skeletonSlides
    return (
        <Section>
            <ViewHeader
                title="Details"
                loading={!record}
            >
                <ViewHeader.Content placement="rightMobile">
                    <PartEditOpenDrawerButton id={record?.id} />
                </ViewHeader.Content>
            </ViewHeader>
            <SliderView items={slides} />
        </Section>
    )
}

export const PartDetailsCard = () => {
    return (
        <InfoCard>
            <InfoCardHeader title="Basic Details" />
            <InfoCardDetails
                details={[
                    {
                        label: <PartCk33Label />,
                        source: ({ componentData }) =>
                            componentData
                                ? formatCK33CodeByLevel(componentData.code, componentData.level) +
                                  ' ' +
                                  componentData.text
                                : null,
                    },
                    {
                        label: 'Manufacturer',
                        source: ({ manufacturerData }) =>
                            manufacturerData && manufacturerData.description,
                    },
                    {
                        label: 'Manufacturer Part Number',
                        source: 'manufacturerPartNumber',
                    },
                    {
                        label: 'UOM',
                        source: 'unitOfMeasure',
                    },
                    { label: 'UPC', source: 'universalProductCode' },
                    {
                        label: 'Position Applicable',
                        source: ({ positionApplicable }) => (
                            <>{positionApplicable ? 'Yes' : 'No'}</>
                        ),
                    },
                    {
                        label: 'Serialized Part',
                        source: ({ serializedPart }) => <>{serializedPart ? 'Yes' : 'No'}</>,
                    },
                    {
                        label: 'Part Cost',
                        source: ({ cost }) => resolveDoublePriceMask(cost),
                    },
                ]}
            />
        </InfoCard>
    )
}
export default PartDetails

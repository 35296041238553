import React, { ReactElement } from 'react'

import { RaRecord } from 'react-admin'

import { ExtendRecordType } from 'appTypes'
import Typography from 'components/mui/Typography'
import { BoxContainer } from 'components/styled'
import { extendRecord } from 'utils'

export type t = <T extends RaRecord>(
    val1: ExtendRecordType<T, string>,
    val2: ExtendRecordType<T, string>,
) => (record: T) => ReactElement
const enhancedOptionText: t = (val1, val2) =>
    function (record) {
        return (
            <BoxContainer whiteSpace="pre">
                <Typography color="text.primary">{extendRecord(record, val1)} </Typography>
                <Typography
                    noWrap
                    color="text.disabled"
                >
                    {extendRecord(record, val2)}
                </Typography>
            </BoxContainer>
        )
    }
export default enhancedOptionText

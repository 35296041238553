import { FC, ReactNode } from 'react'

import { useListContext, useResourceContext } from 'react-admin'

import { UserRoleName } from 'appTypes/models'
import { ArrayControllerContextProvider } from 'components'
import UtilityDrawerForm from 'components/Drawer/UtilityDrawerForm'
import { useSubmit, useDataProvider } from 'hooks'

interface Member {
    email: string

    role: UserRoleName
}

export const membersCreateBulkLimit: number = 10

export const MemberInviteWrapper: FC<{ children: ReactNode }> = ({ children }) => {
    const { refetch } = useListContext()
    const dataProvider = useDataProvider()
    const resource = useResourceContext()

    const submitHandler = useSubmit<{ members: Member[] }>(
        async (formData) => {
            const data = {}
            let canSubmit = false
            for (let i = 0; i < membersCreateBulkLimit; i += 1) {
                const emailName = 'email' + i
                if (formData[emailName]) {
                    canSubmit = true
                    data[emailName] = formData[emailName]
                    const roleName = 'role' + i
                    data[roleName] = formData[roleName]
                }
            }

            if (!canSubmit) {
                return null
            }
            const res = await dataProvider.createMany(resource + '/create_bulk', { data })
            refetch()
            return res
        },
        {
            successMessage: (response) => (response ? 'Successfully invited!' : ''),
            successMessageType: 'success',
        },
    )

    return (
        <UtilityDrawerForm
            onSubmit={submitHandler}
            warnWhenUnsavedChanges={false}
        >
            <ArrayControllerContextProvider>{children}</ArrayControllerContextProvider>
        </UtilityDrawerForm>
    )
}

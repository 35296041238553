import { ReactNode } from 'react'

import { CreateProps, CreateBase, useCreate, RaRecord } from 'react-admin'
import { useNavigate } from 'react-router-dom'

import { Form } from 'components'
import { FormProps } from 'components/form'
import { UseSubmitExtraArgs, useCreateResourcePath, useSubmit } from 'hooks'
import { DataProviderMetaFile } from 'providers/dataProvider'

import { ResourceType, useResource } from './RaResourceContextProvider'
import { makeSubmit, makeSubmitConfig } from './common'

export interface CreateCustomProps<RecordType extends RaRecord = any>
    extends Omit<CreateProps<RecordType>, 'resource' | 'mutationOptions'>,
        makeSubmitConfig<RecordType>,
        Pick<FormProps, 'formOnError' | 'validate'>,
        Pick<UseSubmitExtraArgs, 'successMessage' | 'successMessageArgs'> {
    children: ReactNode
    className?: string
    disableSuccessRedirect?: boolean
    defaultValues?: Partial<RecordType> | ((record: RecordType) => Partial<RecordType>)
    warnWhenUnsavedChanges?: boolean
    resource?: ResourceType
    redirect?: 'edit' | 'list'
    redirectInfo?: { resource?: ResourceType }
    meta?: DataProviderMetaFile
}

type formRedirectReturnType = (
    redirect: CreateCustomProps['redirect'],
    resource: string,
    record: RaRecord,
) => void

const useCreateFormRedirect = (): formRedirectReturnType => {
    const navigate = useNavigate()
    const createPath = useCreateResourcePath()

    return (redirect, resource, record) => {
        const path = createPath({
            resource,
            type: redirect,
            id: record.id,
        })
        navigate(path)
    }
}

const Create = <RecordType extends RaRecord = any>({
    children,
    makeData,
    defaultValues,
    onSuccess,
    className,
    disableSuccessRedirect,
    successMessage,
    successMessageArgs,
    serializer,
    warnWhenUnsavedChanges,
    formOnError,
    redirect = 'list',
    redirectInfo = {},
    meta,
    validate,
    mutationOptions,
    ...rest
}: CreateCustomProps<RecordType>) => {
    const resource = useResource(rest.resource)
    const formRedirect = useCreateFormRedirect()
    const [create] = useCreate()

    const submit = useSubmit(
        makeSubmit(create, {
            resource,
            makeData,
            onSuccess: (r) => {
                onSuccess?.(r)
                if (!disableSuccessRedirect) {
                    formRedirect(redirect, redirectInfo.resource?.resource || resource.resource, r)
                }
            },
            serializer,
            meta,
            mutationOptions,
        }),
        {
            successMessage: successMessage ?? (({ defaultMessages }) => defaultMessages.created),
            successMessageArgs,
        },
    )

    return (
        <CreateBase
            {...rest}
            resource={resource.resource}
        >
            <Form
                validate={validate}
                onSubmit={submit}
                className={className}
                defaultValues={defaultValues}
                warnWhenUnsavedChanges={warnWhenUnsavedChanges}
                disableSuccessRedirect={disableSuccessRedirect}
                formOnError={formOnError}
            >
                {children}
            </Form>
        </CreateBase>
    )
}

export default Create

import { MenuItem, MenuItemProps, SvgIcon, Tooltip, alpha } from '@mui/material'
import { Link } from 'react-router-dom'

import Typography from 'components/mui/Typography'

import { BaseActionRenderParams } from '../Actions'

import { useActionsMenuContext } from './ActionsMenu'

const ActionsOption = ({
    Icon,
    title,
    to,
    onClick,
    titleOnDisabled,
    disableCloseOnClick,
    itemColor,
    ...rest
}: BaseActionRenderParams & Omit<MenuItemProps, 'title'>) => {
    const { close } = useActionsMenuContext()
    const content = (
        <span>
            <MenuItem
                {...rest}
                {...(to && { to, component: Link })} // TODO: this makes 'ul > a' in DOM
                onClick={(e) => {
                    onClick?.(e)
                    if (!disableCloseOnClick) {
                        close()
                    }
                }}
                sx={{
                    minHeight: 'unset',
                    height: '36px',
                    '&>.MuiTypography-root': {
                        width: '100%',
                    },
                }}
            >
                <SvgIcon
                    inheritViewBox
                    component={Icon}
                    sx={{
                        width: '24px',
                        height: '24px',
                        marginRight: '18.5px',
                        color: itemColor || ((theme) => alpha(theme.palette.text.primary, 0.54)),
                    }}
                />
                <Typography color={itemColor || 'text.primary'}>{title}</Typography>
            </MenuItem>
        </span>
    )
    // TODO: this renders a>li inside ul.
    return titleOnDisabled && rest.disabled ? (
        <Tooltip title={titleOnDisabled}>{content}</Tooltip>
    ) : (
        <>{content}</>
    )
}

export default ActionsOption

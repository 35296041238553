import { FC } from 'react'

import { cardHeaderClasses } from '@mui/material'
import { Link } from 'react-router-dom'

import { VendorModel, WorkOrderModel } from 'appTypes/models'
import {
    DataAvatar,
    GridContainerColumns,
    GridItemLayout,
    InfoCard,
    InfoCardDetails,
    InfoCardHeader,
    ViewHeader,
    Section,
    Typography,
} from 'components'
import { useCreateResourcePath } from 'hooks'
import {
    vendorAddressInfo,
    vendorAvatarConfig,
    vendorsResource,
    vendorTypesObject,
} from 'pages/Vendors/constants/config'

interface WorkOrderVendorCardProps {
    data: WorkOrderModel
    canceled?: boolean
}

const WorkOrderVendorCard: FC<WorkOrderVendorCardProps> = ({ data, canceled = false }) => {
    const {
        purchaseOrderData: { vendorData },
    } = data

    return (
        <Section>
            <>
                {canceled || <ViewHeader title="Vendor" />}
                {canceled ? (
                    <VendorCard vendorData={vendorData} />
                ) : (
                    <GridContainerColumns>
                        <GridItemLayout>
                            <VendorCard vendorData={vendorData} />
                        </GridItemLayout>
                    </GridContainerColumns>
                )}
            </>
        </Section>
    )
}
export const VendorCard = ({ vendorData }: { vendorData: VendorModel }) => {
    const createPath = useCreateResourcePath()
    if (vendorData) {
        const path = createPath({
            resource: vendorsResource.resource,
            type: 'edit',
            id: vendorData?.id,
        })

        return (
            <InfoCard>
                <InfoCardHeader
                    sx={{
                        p: 0,
                        [`& .${cardHeaderClasses.action}`]: {
                            m: 0,
                        },
                        [`& .${cardHeaderClasses.content}`]: {
                            display: 'flex',
                            flexDirection: 'column',
                            maxWidth: 'calc(100% - 56px)',
                        },
                    }}
                    avatar={
                        <Link to={path}>
                            <DataAvatar
                                defaultImage={<vendorAvatarConfig.Icon />}
                                sx={{ marginBottom: '6px' }}
                                imageSrc={vendorData.logo}
                            />
                        </Link>
                    }
                    title={
                        <Typography
                            variant="avatar-initials"
                            color="primary.main"
                            to={path}
                            fontWeight="500"
                            component={Link}
                            sx={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {vendorData.name}
                        </Typography>
                    }
                    subheader={vendorTypesObject[vendorData.type]?.name}
                    subheaderTypographyProps={{
                        variant: 'inputLabel',
                        color: 'text.primary',
                        marginTop: '3px',
                        fontWeight: '500',
                    }}
                />
                <InfoCardDetails<VendorModel>
                    details={[
                        {
                            label: 'Address Line 1',
                            source: 'address',
                        },
                        {
                            label: 'Address Line 2',
                            source: 'address2',
                        },
                        {
                            label: vendorAddressInfo.label,
                            source: () => vendorAddressInfo.value(vendorData),
                        },
                        {
                            label: 'Tax ID',
                            source: 'taxId',
                        },
                    ]}
                    propRecord={vendorData}
                />
            </InfoCard>
        )
    }

    return null
}
export default WorkOrderVendorCard

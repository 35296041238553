import { ComponentProps, FC } from 'react'

import { TypographyProps } from '@mui/material'
import { styled } from '@mui/material/styles'

import { useInDrawerContext } from 'components/context'
import Typography from 'components/mui/Typography'

interface SectionTypographyProps {
    frontLine?: boolean
}

const SectionTypography = styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'frontLine',
})<SectionTypographyProps>`
    display: flex;
    align-items: center;

    ${({ frontLine, theme }) =>
        frontLine
            ? `
        &::before {
            content: "";
            display: inline-block;
            margin-right: 12px;
            width: 12px;
            background-color: ${theme.palette.text.primary};
            height: 2px;
        }
    `
            : ''}
`

SectionTypography.defaultProps = {
    color: 'text.primary',
    frontLine: false,
    mb: '16px',
}

export default SectionTypography

interface SectionTitleProps
    extends SectionTypographyProps,
        ComponentProps<typeof SectionTypography> {
    smallVariant?: TypographyProps['variant']
    largeVariant?: TypographyProps['variant']
}

const smallVariant: TypographyProps['variant'] = 'subtitle1'
const largeVariant: TypographyProps['variant'] = 'h6'
const fontWeight: number = 500

export const SectionTitle: FC<SectionTitleProps> = ({
    smallVariant: smallVariantProp,
    largeVariant: largeVariantProp,
    ...rest
}) => {
    return (
        <SectionTypography
            typography={{
                xs: smallVariantProp || smallVariant,
                sm: largeVariantProp || largeVariant,
            }}
            fontWeight={fontWeight}
            {...rest}
        />
    )
}

interface SectionTitleSmallProps extends ComponentProps<typeof SectionTypography> {}

export const SectionTitleSmall: FC<SectionTitleSmallProps> = (props) => {
    return (
        <SectionTypography
            variant={smallVariant}
            fontWeight={fontWeight}
            {...props}
        />
    )
}

export const SectionTitleInDrawer: FC<SectionTitleProps> = ({
    smallVariant,
    largeVariant,
    ...rest
}) => {
    const inDrawer = useInDrawerContext()

    if (inDrawer) {
        return (
            <SectionTitleSmall
                variant={smallVariant}
                {...rest}
            />
        )
    }
    // TODO: check if it is possible to add types to typography prop properties: xs, sm, md ...
    return (
        <SectionTitle
            smallVariant={smallVariant}
            largeVariant={largeVariant}
            {...rest}
        />
    )
}

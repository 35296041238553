import { WorkOrderModel } from 'appTypes/models'
import { InfoCard, InfoCardDetails } from 'components'
import { dateReadableFormat } from 'utils'

const ClosedInfo = () => {
    return (
        <InfoCard>
            <InfoCardDetails<WorkOrderModel>
                details={[
                    {
                        label: 'Completion Date & Time',
                        source: ({ completed }) => dateReadableFormat(completed),
                    },
                    {
                        label: 'Closed On date',
                        source: ({ statusUpdated }) => dateReadableFormat(statusUpdated),
                    },
                    {
                        label: 'Closed by',
                        source: ({ statusUpdatedBy }) =>
                            statusUpdatedBy?.name || statusUpdatedBy?.email,
                    },
                ]}
            />
        </InfoCard>
    )
}

export default ClosedInfo

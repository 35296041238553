import { ReactElement } from 'react'

import { inject, observer } from 'mobx-react'
import { useLocation, Navigate } from 'react-router-dom'

import { urls } from 'configs/config'
import { AuthStore } from 'providers/authStore'

interface LoginRequiredProps {
    auth?: AuthStore

    children: ReactElement

    redirect?: (auth: AuthStore) => string | boolean
}

const LoginRequired = inject('auth')(
    observer(({ children, auth, redirect }: LoginRequiredProps) => {
        const pathname = useLocation().pathname

        if (!auth.user) {
            const redirectUrl = pathname === urls.root ? '' : pathname

            return (
                <Navigate
                    to={
                        auth.redirectUrl || urls.login + (redirectUrl ? `?next=${redirectUrl}` : '')
                    }
                />
            )
        }

        const shouldRedirect = redirect?.(auth)

        if (shouldRedirect) {
            return <Navigate to={typeof shouldRedirect === 'string' ? shouldRedirect : urls.root} />
        }

        // TODO add another Component just for this.   maybe ?
        if (window.heap && auth.user.email.endsWith('@fleetpal.io')) {
            window.heap.addUserProperties({ isFleetpalTeam: true })
        }

        return children
    }),
)

export default LoginRequired

import { useRecordContext } from 'react-admin'

import { SortType } from 'appTypes'
import { JobPartModel, PartModel, VendorModel, WorkOrderModel } from 'appTypes/models'
import PartsUsageFTU from 'assets/images/partsUsageFtu.svg'
import {
    List,
    PageContent,
    ViewHeader,
    ListBase,
    DatagridColumnsProps,
    CardListConfig,
    BoxContainer,
    StyledElement,
    ListTotalBadge,
    ResourceType,
} from 'components'
import { DatagridLink } from 'components/Datagrid'
import { ListSortContentProps } from 'components/list/ListSortDrawerContent'
import { urls } from 'configs/config'
import { useCreateResourcePath } from 'hooks'
import { LinkArrowButton } from 'pages/Reports/components'
import LinkCardButton from 'pages/Reports/components/LinkCardButon'
import { unitResource } from 'pages/Units/config/constants'
import { woAvatarConfig, woResource } from 'pages/WorkOrders/config/constants'
import { formatDate, resolveDoublePriceMask } from 'utils'

type PartUsageModel = {
    completedOn: string
    totalCost: number
    workOrder: WorkOrderModel['id']
    workOrderData: WorkOrderModel
    vendorData: VendorModel
} & Pick<WorkOrderModel, 'unit' | 'vendor' | 'unitData'> &
    Pick<JobPartModel, 'id' | 'price' | 'qty'>
const defaultPartUsageSort: SortType<PartUsageModel> = {
    field: 'completedOn',
    order: 'DESC',
}
const sortCfg: ListSortContentProps<PartUsageModel> = {
    sortBy: [
        { id: 'workOrder', label: 'Work Order' },
        { id: 'unit', label: 'Unit' },
        { id: 'completedOn', label: 'Completed On' },
        { id: 'vendor', label: 'Vendor' },
        { id: 'qty', label: 'Quantity' },
        { id: 'price', label: 'Part Cost' },
        { id: 'totalCost', label: 'Total Cost' },
    ],
}
const columnsCfg: DatagridColumnsProps<PartUsageModel> = {
    columns: [
        {
            field: 'workOrder',
            headerName: 'Work Order',
            renderCell: ({ row, ...rest }) => {
                return (
                    <DatagridLink
                        {...rest}
                        row={{ ...row, id: row.workOrder }}
                        resource={woResource.resource}
                        value={
                            <BoxContainer>
                                <StyledElement
                                    sx={{
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                    }}
                                >
                                    {row.workOrderData.number}
                                </StyledElement>
                            </BoxContainer>
                        }
                    />
                )
            },
            flex: 1,
        },
        {
            field: 'unit',
            headerName: 'Unit',
            renderCell: ({ row, ...rest }) => {
                return (
                    <DatagridLink
                        {...rest}
                        row={{ ...row, id: row.unit }}
                        resource={unitResource.resource}
                        value={
                            <BoxContainer>
                                <StyledElement
                                    sx={{
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                    }}
                                >
                                    {row.unitData.number}
                                </StyledElement>
                            </BoxContainer>
                        }
                    />
                )
            },
            flex: 1,
        },
        {
            field: 'completedOn',
            headerName: 'Completed On',
            valueGetter: ({ value }) =>
                formatDate(value, (dateformats) => dateformats.shortenedDateTime),
            flex: 1,
        },
        {
            field: 'vendor',
            headerName: 'Vendor',
            valueGetter: ({ row }) => row.vendorData?.name,
            flex: 1,
        },
        {
            field: 'qty',
            headerName: 'Quantity',
            flex: 1,
            valueFormatter: ({ value }) => (value == null ? null : String(value)),
        },
        {
            field: 'price',
            headerName: 'Part Cost',
            flex: 1,
            headerAlign: 'right',
            align: 'right',
            valueFormatter: ({ value }) => resolveDoublePriceMask(value as PartModel['cost']),
        },
        {
            field: 'totalCost',
            headerName: 'Total Cost',
            flex: 1,
            headerAlign: 'right',
            align: 'right',
            valueFormatter: ({ value }) =>
                resolveDoublePriceMask(value as PartUsageModel['totalCost']),
        },
    ],
    actions: null,
    checkboxSelection: false,
}

const PartUsage = () => {
    const createPath = useCreateResourcePath()
    const record = useRecordContext<PartModel>()
    if (!record) {
        return null
    }
    const navigateTo = ({ workOrder }: PartUsageModel) =>
        createPath({
            resource: woResource.resource,
            id: workOrder,
            type: 'edit',
        })
    const cardsCfg: CardListConfig<PartUsageModel> = {
        titleSource: (record) => record.workOrderData.number,
        titleLink: (record) => navigateTo(record),
        defaultImage: <woAvatarConfig.Icon />,
        withStatus: false,
        details: [
            {
                source: 'unit',
                label: 'Unit',
                render: (value, record) => (
                    <LinkCardButton
                        text={record.unitData.number}
                        to={`${urls.units}/${value}`}
                    />
                ),
            },
            {
                source: 'completedOn',
                label: 'Completed on',
                render: (value) => formatDate(value, (dateFormats) => dateFormats.fullDateTime),
            },
            {
                source: 'vendor',
                label: 'Vendor',
                render: (v, record) => record.vendorData?.name,
            },
            {
                source: 'qty',
                label: 'Quantity',
                render: (v) => (v == null ? null : v.toString()),
            },
            {
                source: 'price',
                label: 'Part Cost',
                render: (value) => resolveDoublePriceMask(value),
            },
            {
                source: 'totalCost',
                label: 'Total Cost',
                render: (value) => resolveDoublePriceMask(value),
            },
        ],
        action: (record) => <LinkArrowButton path={navigateTo(record)} />,
    }
    const path = createPath({
        id: record.id,
        type: 'edit',
    })
    const partUsageResource: ResourceType = { resource: path + '/usage', name: 'part-usage' }

    return (
        <PageContent>
            <ListBase<PartUsageModel>
                resource={partUsageResource.resource}
                preferencesResource={partUsageResource}
                sort={defaultPartUsageSort}
            >
                <ViewHeader title="Part Usage">
                    <ViewHeader.Content>
                        <ListTotalBadge />
                    </ViewHeader.Content>
                </ViewHeader>

                <List
                    columnsCfg={columnsCfg}
                    cardsCfg={cardsCfg}
                    disableManageColumns
                    preferencesResource={partUsageResource}
                    sortCfg={sortCfg}
                    exportFileName={`${record.number}_usage`}
                    listFTUProps={{
                        linkText: null,
                        title: 'No Data',
                        imageSrc: PartsUsageFTU,
                    }}
                />
            </ListBase>
        </PageContent>
    )
}
export default PartUsage

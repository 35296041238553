import { BoxContainer, Columns, Skeleton } from 'components'

import Monitor from './Monitor'

const MonitorSkeleton = () => {
    return (
        <Monitor>
            <Columns
                gap="10px"
                height="100%"
            >
                <Skeleton
                    height="8px"
                    width="121px"
                />
                <BoxContainer
                    width="100%"
                    height="100%"
                    gap="12px"
                >
                    <Skeleton />
                    <Skeleton />
                </BoxContainer>
            </Columns>
        </Monitor>
    )
}
export default MonitorSkeleton

import { ReactElement } from 'react'

import { Identifier } from 'react-admin'

import { ResourceType, useOpenUtilityDrawer, useResource } from 'components'
import UtilityDrawerEditor, {
    UtilityDrawerEditorProps,
} from 'components/Drawer/UtilityDrawerEditor'

import { partSerializer } from '../config/constants'

import PartForm from './PartForm'

interface PartDrawerTogglerProps extends Pick<UtilityDrawerEditorProps, 'onSuccess'> {
    children: (params: { onClick: () => void }) => ReactElement
    id?: Identifier
    title?: string
    resource?: ResourceType
    successMessage?: string
}

const PartDrawerToggler = ({
    children,
    id,
    title = 'Edit Details',
    resource: resourceProp,
    onSuccess,
    successMessage,
}: PartDrawerTogglerProps) => {
    const open = useOpenUtilityDrawer()
    const resource = useResource(resourceProp)

    return children({
        onClick: () => {
            open({
                extraArgs: {
                    id,
                    resource,
                },
                drawerArgs: {
                    title,
                    renderWrapper: (params) => (
                        <UtilityDrawerEditor
                            successMessage={successMessage}
                            onSuccess={onSuccess}
                            {...params}
                            serializer={partSerializer}
                        />
                    ),
                    renderContent: () => <PartForm />,
                },
            })
        },
    })
}

export default PartDrawerToggler

import { ListAltOutlined } from '@mui/icons-material'
import { Tooltip } from '@mui/material'

import { WOJobItem } from 'appTypes/models'
import { BoxContainer, WithCollapsibleController, Typography } from 'components'

import LineItemsTooltipTitle from './LineItemsTooltipTitle'

interface WorkOrderJobCardSummaryProps {
    data: WOJobItem[]
}

const WorkOrderJobCardSummary = ({ data }: WorkOrderJobCardSummaryProps) => {
    return (
        <>
            <BoxContainer justifyContent="space-between">
                <BoxContainer>
                    <ListAltOutlined
                        sx={(theme) => ({
                            width: '16px',
                            height: '16px',
                            color: theme.palette.text.secondary,
                        })}
                    />
                    <Tooltip
                        enterTouchDelay={0}
                        leaveTouchDelay={5000}
                        componentsProps={{
                            tooltip: {
                                sx: {
                                    padding: '0px',
                                },
                            },
                        }}
                        title={<LineItemsTooltipTitle data={data} />}
                    >
                        <Typography
                            component={BoxContainer}
                            color="text.secondary"
                            ml="5px"
                            variant="chartListItem"
                        >
                            {data.length} Line Items
                        </Typography>
                    </Tooltip>
                </BoxContainer>
                <WithCollapsibleController>
                    {({ isExpanded, toggleExpand }) => (
                        <Typography
                            component={BoxContainer}
                            color="primary"
                            gap="5px"
                            sx={{
                                cursor: 'pointer',
                            }}
                            fontSize="12px"
                            onClick={toggleExpand}
                            variant="chartListItem"
                        >
                            {isExpanded ? `SEE LESS` : `SEE MORE `}
                        </Typography>
                    )}
                </WithCollapsibleController>
            </BoxContainer>
        </>
    )
}
export default WorkOrderJobCardSummary

import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined'
import { ReferenceInput } from 'react-admin'
import { useWatch } from 'react-hook-form'

import { UnitModel } from 'appTypes/models'
import {
    UploadImage,
    AutocompleteInput,
    SelectInput as SelectInputBase,
    DateInput,
    GridContainerColumns,
    GridBreak,
    Status,
    TextInput as TextInputBase,
    SectionTitleInDrawer,
} from 'components'
import { GridItemLayoutInDrawer } from 'components/pages/GridItemLayout'
import TagInput from 'pages/Configure/TagsManagement/components/TagInput'
import {
    requiredValidation,
    inputIntegerPositiveMaskParams,
    validateMaxNum,
    maxLengthValidation,
    validateMaxLength,
    inputUppercaseMaskParams,
} from 'utils'

import { unitStatusChoices } from '../config/constants'
const SelectInput = SelectInputBase<UnitModel>
const TextInput = TextInputBase<UnitModel>

const engineHPValidation = validateMaxNum(20000)
const gearsValidation = validateMaxNum(100)
const maxLengthValidation17 = validateMaxLength(17)
const maxLengthValidation20 = validateMaxLength(20)

const StatusSelect = () => {
    const id = useWatch({ name: 'id' })

    if (id) {
        return null
    }

    return (
        <SelectInput
            optionText={(option: typeof unitStatusChoices[0]) => (
                <>
                    <Status
                        size="8px"
                        iconColor={option.iconColor}
                        mr="10px"
                    />
                    {option.name}
                </>
            )}
            label="Status"
            source="status"
            choices={unitStatusChoices}
            validate={requiredValidation}
            disableEmptyValue
        />
    )
}

const UnitForm = () => {
    return (
        <>
            <SectionTitleInDrawer>Basic Details</SectionTitleInDrawer>
            <GridContainerColumns>
                <GridItemLayoutInDrawer>
                    <StatusSelect />
                </GridItemLayoutInDrawer>
                <GridItemLayoutInDrawer>
                    <TextInput
                        source="number"
                        label="Unit Number"
                        validate={requiredValidation}
                    />
                </GridItemLayoutInDrawer>
                <GridBreak />
                <GridItemLayoutInDrawer>
                    <UploadImage
                        source="photo"
                        defaultIcon={<LocalShippingOutlinedIcon />}
                    />
                </GridItemLayoutInDrawer>
                <GridBreak />
                <GridItemLayoutInDrawer>
                    <TextInput
                        validate={maxLengthValidation}
                        source="name"
                        label="Unit Name"
                    />
                </GridItemLayoutInDrawer>
                <GridBreak />
                <GridItemLayoutInDrawer>
                    <TextInputBase
                        source="vin"
                        label="VIN"
                        validate={maxLengthValidation17}
                        {...inputUppercaseMaskParams}
                    />
                </GridItemLayoutInDrawer>
                <GridItemLayoutInDrawer>
                    <TextInputBase
                        source="licensePlate"
                        label="License Plate"
                        validate={maxLengthValidation20}
                        {...inputUppercaseMaskParams}
                    />
                </GridItemLayoutInDrawer>
                <GridBreak />
                <GridItemLayoutInDrawer>
                    <TagInput />
                </GridItemLayoutInDrawer>
            </GridContainerColumns>
            <GridBreak />
            <SectionTitleInDrawer>Specifications</SectionTitleInDrawer>
            <GridContainerColumns>
                <GridItemLayoutInDrawer>
                    <ReferenceInput
                        source="vmrsEquipmentCategory"
                        reference="vmrs/ck2"
                    >
                        <AutocompleteInput
                            optionText="description"
                            label="Equipment"
                        />
                    </ReferenceInput>
                </GridItemLayoutInDrawer>
                <GridItemLayoutInDrawer>
                    <ReferenceInput
                        source="vmrsManufacturer"
                        reference="vmrs/ck34"
                        perPage={20}
                    >
                        <AutocompleteInput
                            optionText="description"
                            label="Manufacturer"
                        />
                    </ReferenceInput>
                </GridItemLayoutInDrawer>
                <GridBreak />
                <GridItemLayoutInDrawer>
                    <TextInput
                        source="model"
                        label="Model"
                        validate={maxLengthValidation}
                    />
                </GridItemLayoutInDrawer>
                <GridItemLayoutInDrawer>
                    <DateInput
                        source="modelYear"
                        label="Model Year"
                        views={['year']}
                    />
                </GridItemLayoutInDrawer>
                <GridBreak />

                <GridItemLayoutInDrawer>
                    <TextInput
                        source="color"
                        label="Color"
                        validate={maxLengthValidation20}
                    />
                </GridItemLayoutInDrawer>
                <GridItemLayoutInDrawer>
                    <TextInput
                        source="tireSize"
                        label="Tire Size"
                        validate={maxLengthValidation}
                    />
                </GridItemLayoutInDrawer>
            </GridContainerColumns>
            <GridBreak />
            <SectionTitleInDrawer
                smallVariant="subtitle2"
                largeVariant="subtitle1"
                frontLine
            >
                Engine
            </SectionTitleInDrawer>
            <GridContainerColumns>
                <GridItemLayoutInDrawer>
                    <ReferenceInput
                        source="engineVmrsManufacturer"
                        reference="vmrs/ck34"
                        perPage={20}
                    >
                        <AutocompleteInput
                            optionText="description"
                            label="Manufacturer/Make"
                        />
                    </ReferenceInput>
                </GridItemLayoutInDrawer>
                <GridItemLayoutInDrawer>
                    <TextInput
                        source="engineModel"
                        label="Model"
                        validate={maxLengthValidation}
                    />
                </GridItemLayoutInDrawer>
                <GridBreak />

                <GridItemLayoutInDrawer>
                    <TextInputBase
                        source="engineHp"
                        label="HP"
                        validate={engineHPValidation}
                        {...inputIntegerPositiveMaskParams}
                    />
                </GridItemLayoutInDrawer>
            </GridContainerColumns>
            <GridBreak />
            <SectionTitleInDrawer
                smallVariant="subtitle2"
                largeVariant="subtitle1"
                frontLine
            >
                Transmission
            </SectionTitleInDrawer>
            <GridContainerColumns>
                <GridItemLayoutInDrawer>
                    <ReferenceInput
                        source="transmissionVmrsManufacturer"
                        reference="vmrs/ck34"
                        perPage={20}
                    >
                        <AutocompleteInput
                            optionText="description"
                            label="Manufacturer/Make"
                        />
                    </ReferenceInput>
                </GridItemLayoutInDrawer>
                <GridItemLayoutInDrawer>
                    <TextInput
                        source="transmissionModel"
                        label="Model"
                        validate={maxLengthValidation}
                    />
                </GridItemLayoutInDrawer>
                <GridBreak />

                <GridItemLayoutInDrawer>
                    <TextInputBase
                        source="transmissionGears"
                        label="Gears"
                        validate={gearsValidation}
                        {...inputIntegerPositiveMaskParams}
                    />
                </GridItemLayoutInDrawer>
            </GridContainerColumns>
        </>
    )
}

export default UnitForm

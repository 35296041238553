import { WorkOrderModel } from 'appTypes/models'
import { ActionsMenu, MenuActionProps } from 'components/actions'
import { UseCreateResourcePathParams } from 'hooks'

import { vwoAvatarConfig, woAvatarConfig } from '../config/constants'
import { useWoCreateLink } from '../hooks'

interface WorkOrderCreateMenuProps
    extends Omit<MenuActionProps<WorkOrderModel>, 'actions'>,
        Pick<UseCreateResourcePathParams, 'queryParams'> {}

const WorkOrderCreateMenu = ({ queryParams, ...props }: WorkOrderCreateMenuProps) => {
    const { clickHandler, to } = useWoCreateLink()

    return (
        <ActionsMenu
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            {...props}
            renderToggler={(open) =>
                props.renderToggler((event) => {
                    clickHandler(event, open)
                })
            }
            actions={(params, { children }) => [
                children({
                    Icon: woAvatarConfig.Icon,
                    title: 'Create Internal Work Order',
                    key: 'create-wo',
                    to: to({ queryParams }),
                }),
                children({
                    Icon: vwoAvatarConfig.Icon,
                    title: 'Create Vendor Work Order',
                    key: 'create-vendorWo',
                    to: to({ workOrderType: 'vendor', queryParams }),
                }),
            ]}
        />
    )
}

export default WorkOrderCreateMenu

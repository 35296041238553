import { GridContainer, GridItemLayout, PageContent } from 'components'

import { DeactivateCompanyAccountSection, DeleteCompanyAccountSection } from './components'

const ManageAccount = () => {
    return (
        <PageContent>
            <GridContainer>
                <GridItemLayout>
                    <DeactivateCompanyAccountSection />
                    <DeleteCompanyAccountSection />
                </GridItemLayout>
            </GridContainer>
        </PageContent>
    )
}

export default ManageAccount

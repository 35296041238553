import { SortType } from 'appTypes'
import { CK33ReportModel, UnitModel } from 'appTypes/models'
import { CardListConfig, DatagridColumnsProps, PageContent } from 'components'
import { ListSortContentProps } from 'components/list/ListSortDrawerContent'
import { FilterConfig } from 'components/list/filter/ListFilterForm'
import ListFilterRangeInput from 'components/list/filter/ListFilterRangeInput'
import ListFilterValueInput, {
    prepareFilterResource,
} from 'components/list/filter/ListFilterValueInput'
import ResourceContextProviderWithClearEffect from 'components/resource/ResourceContextProviderWithClearEffect'
import { unitFiltersCfg } from 'pages/Units/List/UnitsList'
import { ck33Resource } from 'pages/Units/config/constants'
import { capitalize, costMaskParams, resolveDoublePriceMask } from 'utils'

import { ListBaseWithPeriod, ListWithPeriodSelector, ReportsHeader } from '../components'
import { newNames } from '../utils'
const defaultSort: SortType<CK33ReportModel> = {
    field: 'total',
    order: 'DESC',
}
export const costPerCategoryIncludedFiltersMap: (keyof UnitModel)[] = [
    'number',
    'name',
    'vin',
    'licensePlate',
    'model',
    'modelYear',
    'engineModel',
    'engineHp',
    'transmissionModel',
    'transmissionGears',
    'color',
    'tireSize',
    'status',
    'created',
    'engineVmrsManufacturer',
    'transmissionVmrsManufacturer',
    'vmrsEquipmentCategory',
    'vmrsManufacturer',
]
export const costPerCategorySortCfg: ListSortContentProps<
    CK33ReportModel & { description: string }
> = {
    sortBy: [
        { id: 'code', label: 'System' },
        { id: 'description', label: 'System Description' },
        {
            id: 'totalParts',
            label: 'Parts',
        },
        { id: 'totalLabor', label: 'Labor' },
        { id: 'totalServices', label: 'Services' },
        { id: 'totalFees', label: 'Fees' },
        { id: 'totalTax', label: 'Tax' },
        { id: 'total', label: 'Total Cost' },
    ],
}
export const costPerCategoryFiltersCfg: FilterConfig<CK33ReportModel & UnitModel> = {
    filters: [
        {
            id: 'code',
            label: 'System',
            renderComponent: (props) => (
                <ListFilterValueInput
                    dataProviderProps={{
                        getList: {
                            prepareResource: prepareFilterResource,
                        },
                        getMany: {
                            prepareResource: () => 'vmrs/ck33',
                        },
                    }}
                    {...props}
                    makeItemLabel={(record: CK33ReportModel) => record.code + ' ' + record.text}
                />
            ),
        },
        {
            id: 'totalParts',
            label: 'Parts',
            filterType: 'range' as const,
            renderComponent: (props) => (
                <ListFilterRangeInput
                    inputProps={costMaskParams}
                    {...props}
                />
            ),
        },
        {
            id: 'totalLabor',
            label: 'Labor',
            filterType: 'range' as const,
            renderComponent: (props) => (
                <ListFilterRangeInput
                    inputProps={costMaskParams}
                    {...props}
                />
            ),
        },
        {
            id: 'totalServices',
            label: 'Services',
            filterType: 'range' as const,
            renderComponent: (props) => (
                <ListFilterRangeInput
                    inputProps={costMaskParams}
                    {...props}
                />
            ),
        },
        {
            id: 'totalFees',
            label: 'Fees',
            filterType: 'range' as const,
            renderComponent: (props) => (
                <ListFilterRangeInput
                    inputProps={costMaskParams}
                    {...props}
                />
            ),
        },
        {
            id: 'totalTax',
            label: 'Tax',
            filterType: 'range' as const,
            renderComponent: (props) => (
                <ListFilterRangeInput
                    inputProps={costMaskParams}
                    {...props}
                />
            ),
        },
        {
            id: 'total',
            label: 'Total Cost',
            filterType: 'range' as const,
            renderComponent: (props) => (
                <ListFilterRangeInput
                    inputProps={costMaskParams}
                    {...props}
                />
            ),
        },
        ...unitFiltersCfg.filters
            .filter((item) => costPerCategoryIncludedFiltersMap.some((id) => id === item.id))
            .map((item) => ({
                ...item,
                id: ('unitTotals' + capitalize(item.id)) as keyof UnitModel,
                label: newNames[item.id] || item.label,
            })),
    ],
}

export const costPerCategoryColumnsCfg: DatagridColumnsProps<CK33ReportModel> = {
    checkboxSelection: false,
    columns: [
        {
            field: 'code',
            headerName: 'System',
        },
        {
            field: 'text',
            headerName: 'System Description',
        },
        {
            field: 'totalParts',
            headerName: 'Parts',
            valueFormatter: ({ value }) => resolveDoublePriceMask(value),
            align: 'right',
            headerAlign: 'right',
        },
        {
            field: 'totalLabor',
            headerName: 'Labor',
            valueFormatter: ({ value }) => resolveDoublePriceMask(value),
            align: 'right',
            headerAlign: 'right',
        },
        {
            field: 'totalServices',
            headerName: 'Services',
            valueFormatter: ({ value }) => resolveDoublePriceMask(value),
            align: 'right',
            headerAlign: 'right',
        },
        {
            field: 'totalFees',
            headerName: 'Fees',
            valueFormatter: ({ value }) => resolveDoublePriceMask(value),
            align: 'right',
            headerAlign: 'right',
        },
        {
            field: 'totalTax',
            headerName: 'Tax',
            valueFormatter: ({ value }) => resolveDoublePriceMask(value),
            align: 'right',
            headerAlign: 'right',
        },
        {
            field: 'total',
            headerName: 'Total Cost',
            valueFormatter: ({ value }) => resolveDoublePriceMask(value),
            align: 'right',
            headerAlign: 'right',
        },
    ],
    actions: null,
}
export const costPerCategoryCardsCfg: CardListConfig<CK33ReportModel> = {
    titleSource: 'code',
    disableTitleLink: true,
    defaultImage: null,
    details: [
        {
            source: 'text',
            label: 'system description',
        },
        { source: 'totalParts', label: 'parts', render: (value) => resolveDoublePriceMask(value) },
        { source: 'totalLabor', label: 'labor', render: (value) => resolveDoublePriceMask(value) },
        {
            source: 'totalServices',
            label: 'services',
            render: (value) => resolveDoublePriceMask(value),
        },
        { source: 'totalFees', label: 'fees', render: (value) => resolveDoublePriceMask(value) },
        { source: 'totalTax', label: 'tax', render: (value) => resolveDoublePriceMask(value) },
        { source: 'total', label: 'total cost', render: (value) => resolveDoublePriceMask(value) },
    ],
}

const CostPerCategoryCK31 = () => {
    return (
        <ResourceContextProviderWithClearEffect value={ck33Resource}>
            <ListBaseWithPeriod
                sort={defaultSort}
                filter={{ withAspects: ['totals_system'] }}
            >
                <ReportsHeader<CK33ReportModel>
                    renderTotal={({ total: totalCount, hasPreviousPage }) =>
                        `${totalCount} | ${resolveDoublePriceMask(
                            (hasPreviousPage as any).aggregates.totalQueryset || 0,
                        )} total`
                    }
                >
                    Cost per Category
                </ReportsHeader>
                <PageContent>
                    <ListWithPeriodSelector
                        exportFileName="cost-per-category"
                        filtersCfg={costPerCategoryFiltersCfg}
                        sortCfg={costPerCategorySortCfg}
                        columnsCfg={costPerCategoryColumnsCfg}
                        cardsCfg={costPerCategoryCardsCfg}
                    />
                </PageContent>
            </ListBaseWithPeriod>
        </ResourceContextProviderWithClearEffect>
    )
}

export default CostPerCategoryCK31

import { ReactElement } from 'react'

import { AllPermissions } from 'appTypes/models'
import { useOpenUtilityDrawer } from 'components/Drawer'
import { ResourceType } from 'components/resource'
import { useResourcePermissions } from 'hooks'

interface UtilityDrawerActionProps {
    children: (
        params: ReturnType<typeof useOpenUtilityDrawer>,
        options: { hasPermission: boolean },
    ) => ReactElement
    permission?: keyof AllPermissions
    resource?: ResourceType
    renderOnNoPermission?: boolean
}

const UtilityDrawerAction = ({
    children,
    permission,
    resource,
    renderOnNoPermission,
}: UtilityDrawerActionProps) => {
    const open = useOpenUtilityDrawer()
    const permissions = useResourcePermissions(resource)
    const hasPermission = Boolean(permission && permissions[permission] !== false)

    if (!renderOnNoPermission && !hasPermission) {
        return null
    }

    return children(
        hasPermission
            ? open
            : () => {
                  /* */
              },
        {
            hasPermission,
        },
    )
}

export default UtilityDrawerAction

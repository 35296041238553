import { ListBase } from 'react-admin'

import { VendorModel } from 'appTypes/models'
import { PageContent, FlexReverse } from 'components'
import WorkOrderSimpleList from 'pages/WorkOrders/SimpleList'

import { VendorContactResource } from '../Contacts/components'

import VendorContacts from './VendorContacts'
import VendorDetails from './VendorDetails'
import VendorTopSection from './VendorTopSection'

const VendorOverview = () => {
    return (
        <>
            <VendorTopSection />
            <PageContent>
                <FlexReverse>
                    <VendorDetails />
                    <VendorContactResource>
                        <ListBase perPage={3}>
                            <VendorContacts />
                        </ListBase>
                    </VendorContactResource>
                    <WorkOrderSimpleList<VendorModel>
                        workOrderType="vendor"
                        createFormInitialValues={(record) => ({
                            vendor: record?.id,
                        })}
                        filter={(record) => ({
                            vendor: [record?.name],
                        })}
                    />
                </FlexReverse>
            </PageContent>
        </>
    )
}

export default VendorOverview

import { ReactElement, ReactNode } from 'react'

import { Divider, Theme, useMediaQuery, Box, TypographyProps, useTheme } from '@mui/material'
import { RaRecord, useRecordContext } from 'react-admin'

import { ExtendRecordType, StatusType } from 'appTypes'
import { BoxContainer, LinkTabs, useResource, Spacer } from 'components'
import { ActionsMenu, MenuActionProps } from 'components/actions'
import Typography from 'components/mui/Typography'
import { StyledElement } from 'components/styled'
import { FixedTopPortal } from 'pages/layouts/FixedTopPart'
import { extendRecord, ThemeColorType } from 'utils'

import { BackButton, HeaderStatus } from '../parts'

import HeaderDesktopAction from './components/HeaderDesktopAction'
import StyledHeaderMainBlock, {
    SHOW_HEADER_BREAKPOINT,
    StyledHeaderDataAvatar,
    StyledHeaderTitle,
} from './components/StyledComponents'

import type { LinkTabsProps } from 'components/router/LinkTabs'

export interface ViewPageHeaderProps<RecordType extends RaRecord = any>
    extends MenuActionProps<RecordType> {
    titleSource: ExtendRecordType<RecordType, ReactNode>
    subTitleSource?: ExtendRecordType<RecordType, ReactNode>
    subTitleProps?: TypographyProps
    imageSource?: ExtendRecordType<RecordType, ReactNode> | false | string
    status?: (params: RecordType) => StatusType
    tabs?: LinkTabsProps['tabs']
    tabWidth?: string
    avatarColor?: (params: RecordType) => ThemeColorType
    defaultImage: ReactElement
}

const ViewPageHeader = <RecordType extends RaRecord = any>({
    defaultImage,
    avatarColor,
    actions,
    tabWidth,
    tabs,
    imageSource,
    titleSource,
    subTitleSource,
    subTitleProps,
    status,
}: ViewPageHeaderProps<RecordType>) => {
    const record = useRecordContext<RecordType>()

    const match = useMediaQuery<Theme>((theme) => theme.breakpoints.down(SHOW_HEADER_BREAKPOINT))
    const resource = useResource()
    const theme = useTheme()

    if (!record) {
        return null
    }

    const statusData = status?.(record)
    const color = avatarColor?.(record)

    return (
        <FixedTopPortal>
            <StyledElement sx={{ width: '100%' }}>
                <StyledHeaderMainBlock>
                    <BackButton
                        sx={({ breakpoints }) => ({
                            marginRight: '16px',
                            [breakpoints.up(SHOW_HEADER_BREAKPOINT)]: {
                                marginRight: '24px',
                            },
                        })}
                    />
                    <BoxContainer sx={{ width: '100%', overflow: 'hidden' }}>
                        {imageSource !== false && (
                            <StyledHeaderDataAvatar
                                color={color || theme.palette.primary.main}
                                defaultImage={defaultImage}
                                imageSrc={record[imageSource as string]}
                            />
                        )}
                        <Box
                            overflow="hidden"
                            width={{
                                xs: '100%',
                                [SHOW_HEADER_BREAKPOINT]: 'auto',
                            }}
                        >
                            <StyledHeaderTitle
                                variant="h5"
                                color="textPrimary"
                            >
                                {extendRecord(record, titleSource)}
                            </StyledHeaderTitle>
                            <Box
                                display="flex"
                                gap="11px"
                                mt={{ xs: 0, [SHOW_HEADER_BREAKPOINT]: '2px' }}
                            >
                                {status && <HeaderStatus {...statusData} />}
                                {subTitleSource ? (
                                    <Typography
                                        display="block"
                                        variant="tooltip"
                                        color="text.disabled"
                                        whiteSpace="nowrap"
                                        textOverflow="ellipsis"
                                        overflow="hidden"
                                        sx={{ fontSize: '12px' }}
                                        {...subTitleProps}
                                    >
                                        {extendRecord(record, subTitleSource)}
                                    </Typography>
                                ) : null}
                            </Box>
                        </Box>
                    </BoxContainer>
                    <div>
                        {match ? (
                            <ActionsMenu
                                actions={actions}
                                record={record}
                            />
                        ) : (
                            <Spacer ml="80px">
                                {actions(record, {
                                    children: (params) => <HeaderDesktopAction {...params} />,
                                    resource,
                                })}
                            </Spacer>
                        )}
                    </div>
                </StyledHeaderMainBlock>
                {match ? null : <Divider sx={{ background: 'white' }} />}
                {tabs?.length ? (
                    <LinkTabs
                        tabs={tabs}
                        tabWidth={tabWidth}
                    />
                ) : null}
            </StyledElement>
        </FixedTopPortal>
    )
}

ViewPageHeader.defaultProps = {
    imageSource: false,
}

export default ViewPageHeader

import { ComponentType, FC } from 'react'

import useFlags, { flagName, Flags } from 'hooks/useFlags'

/**
 ** hides component body if selected flag is false
 */
const renderOnFlag = <P = any,>(
    isFlag: flagName | ((flags: Flags) => boolean),
    Component: ComponentType<P>,
): FC<P> => {
    return function (props: P) {
        const flags = useFlags()

        if (typeof isFlag === 'function') {
            if (!isFlag(flags)) {
                return null
            }
        } else if (!flags[isFlag]) {
            return null
        }

        return <Component {...props} />
    }
}

export default renderOnFlag

import { Columns, Skeleton, SkeletonSliderCardContainer } from 'components'

const VendorSliderSkeleton = ({ height }: { height?: `${number}px` }) => {
    return (
        <SkeletonSliderCardContainer
            sx={{
                height,
            }}
        >
            <Columns
                gap="10px"
                height="100%"
            >
                <Skeleton
                    height="8px"
                    width="120px"
                    sx={{
                        my: '20px',
                    }}
                />
                <Skeleton />
            </Columns>
        </SkeletonSliderCardContainer>
    )
}
export default VendorSliderSkeleton

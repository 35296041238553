import { FC, ReactNode } from 'react'

import {
    CompanyStateUpdate,
    GoogleMapProvider,
    FormInfo,
    InDrawerContextProvider,
    ResourceDataContextProvider,
    TelematicsContextProvider,
    UtilityDrawerProvider,
} from 'components'
import { SyncProvider } from 'components/Sync'
import { InputStateContextProvider } from 'components/context/InputStateContext'

import { SidebarProvider } from '../Sidebar'
const GlobalProviders: FC<{ children: ReactNode }> = ({ children }) => {
    return (
        <SidebarProvider>
            <CompanyStateUpdate />
            <SyncProvider>
                <FormInfo>
                    <ResourceDataContextProvider>
                        <UtilityDrawerProvider>
                            <TelematicsContextProvider>
                                <InputStateContextProvider value={null}>
                                    <InDrawerContextProvider value={false}>
                                        <GoogleMapProvider>{children}</GoogleMapProvider>
                                    </InDrawerContextProvider>
                                </InputStateContextProvider>
                            </TelematicsContextProvider>
                        </UtilityDrawerProvider>
                    </ResourceDataContextProvider>
                </FormInfo>
            </SyncProvider>
        </SidebarProvider>
    )
}

export default GlobalProviders

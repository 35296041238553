import lodashGet from 'lodash/get'
import lodashSet from 'lodash/set'
import defaultMessages from 'ra-language-english'
import { I18nProvider } from 'react-admin'

const messages = {
    ...defaultMessages,
    EMERGENCY: 'Emergency',
    NON_SCHEDULED: 'Non-Scheduled',
    SCHEDULED: 'Scheduled',
    ENGINE_HOURS: 'Engine Hours',
}

lodashSet(
    messages,
    'ra.message.unsaved_changes',
    "The changes you've made will be lost, are you sure you want to continue?",
)
lodashSet(messages, 'ra.message.invalid_form', 'There was a validation error.')

const locale = 'en'

const i18nProvider: I18nProvider = {
    translate: (key) => lodashGet(messages, key) || key,
    changeLocale: () => {
        // change here message/locale if needed
        return Promise.resolve()
    },
    getLocale: () => locale,
}

export default i18nProvider

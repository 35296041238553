import { Box } from '@mui/material'

import { BoxContainer, Typography } from 'components'
import { Telematics, TelematicsExtra } from 'components/telematics/hooks/useTelematics'
import { formatDate } from 'utils'

import ConnectedUnitsStatus from './ConnectedUnitsStatus'
import NewUnits from './NewUnits'
const ConnectedCardContent = ({ item }: { item: TelematicsExtra & Telematics }) => {
    const {
        connectionTimestamp: date,
        provider: name,
        vehiclesNotSynced,
        vehiclesSynced,
        vehiclesTotal,
        vehiclesWithError,
    } = item
    return (
        <>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                gap="10px"
                my="10px"
            >
                <Typography
                    color="text.disabled"
                    textTransform="uppercase"
                    variant="tooltip"
                >
                    Last Synced
                </Typography>
                <Typography
                    textAlign="right"
                    color="text.primary"
                    variant="body2"
                >
                    {formatDate(date, (dateFormats) => dateFormats.shortenedDateTime)}
                </Typography>
            </Box>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                gap="10px"
                my="10px"
            >
                <Typography
                    color="text.disabled"
                    textTransform="uppercase"
                    variant="tooltip"
                >
                    Units from {name}
                </Typography>
                <Typography
                    textAlign="right"
                    color="text.primary"
                    variant="body1"
                >
                    {vehiclesTotal}
                </Typography>
            </Box>
            <BoxContainer
                justifyContent="flex-end"
                gap="16px"
            >
                <ConnectedUnitsStatus type="sync">{vehiclesSynced}</ConnectedUnitsStatus>
                <ConnectedUnitsStatus type="error">{vehiclesWithError}</ConnectedUnitsStatus>
                <BoxContainer gap="4px">
                    <ConnectedUnitsStatus type="notImported">
                        {vehiclesNotSynced}
                    </ConnectedUnitsStatus>
                    <NewUnits integration={item} />
                </BoxContainer>
            </BoxContainer>
        </>
    )
}

export default ConnectedCardContent

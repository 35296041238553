import { ComponentType, FC } from 'react'

import { renderOnFlag } from 'hocs'
import { authStore } from 'providers'

/**
 ** hides component body if billing is disabled
 */
const renderOnBilling = <P = any,>(Component: ComponentType<P>): FC<P> =>
    renderOnFlag((flags) => flags.useBilling && !!authStore.billing, Component)

export default renderOnBilling

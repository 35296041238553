import { ReactNode, createContext, useContext } from 'react'

import { AllProps } from 'appTypes'

export interface InputStateContextResult<RecordType extends AllProps = AllProps> {
    form?: {
        allDisabled?: boolean
        source?: (source: string) => string
    }
    inputs?: {
        [key in keyof RecordType]?: {
            disabled?: boolean
            source?: (source: string) => string
        }
    }
}

interface UseInputStateResult {
    disabled?: boolean
    source?: string
}

const InputStateContext = createContext<InputStateContextResult>(null)

export const useInputStateContext = () => useContext(InputStateContext)

export const useInputState = (
    source: string,
    { disabled }: { disabled: boolean },
): UseInputStateResult => {
    const { form, inputs } = useInputStateContext() || {}

    const inputState = inputs?.[source] || {}
    const disabledState = inputState.disabled ?? form?.allDisabled
    const sourceState = inputState.source ?? form?.source

    return {
        disabled: disabledState ?? disabled,
        source: sourceState ? sourceState(source) : source,
    }
}

interface InputStateContextProviderProps<RecordType extends AllProps = AllProps> {
    children: ReactNode
    value: InputStateContextResult<RecordType>
}

export const InputStateContextProvider = <RecordType extends AllProps = AllProps>({
    children,
    value,
}: InputStateContextProviderProps<RecordType>) => {
    return <InputStateContext.Provider value={value}>{children}</InputStateContext.Provider>
}

export default InputStateContext

export const inputStateAllDisabled: InputStateContextResult = {
    form: {
        allDisabled: true,
    },
}

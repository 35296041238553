import { FC } from 'react'

import { ReferenceInput as RaReferenceInput, ReferenceInputProps } from 'react-admin'

import { useInputState } from 'components/context/InputStateContext'
import { emptyPerPage } from 'configs/constants'

const ReferenceInput: FC<ReferenceInputProps & { disabled?: boolean }> = (props) => {
    const { source, disabled } = useInputState(props.source, { disabled: props.disabled })

    return (
        <RaReferenceInput
            {...props}
            source={source}
            perPage={disabled ? emptyPerPage : props.perPage}
        />
    )
}

export default ReferenceInput

import { Add } from '@mui/icons-material'
import { useMediaQuery, Theme } from '@mui/material'
import { useListContext } from 'react-admin'

import { BoxContainer, Button, ListTotalBadge, Spacer, ViewHeader } from 'components'

import TagDrawerToggler from './TagDrawerToggler'

const TagsHeader = () => {
    const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
    const { refetch } = useListContext()
    return (
        <BoxContainer
            padding="28px 28px 4px 28px"
            justifyContent="space-between"
        >
            <Spacer>
                <ViewHeader
                    title="Tags Management"
                    sx={{ margin: 0 }}
                />
                <ListTotalBadge />
            </Spacer>
            <TagDrawerToggler
                onSuccess={() => {
                    refetch()
                }}
            >
                {(open) => (
                    <Button
                        sx={(theme) => ({
                            [`${theme.breakpoints.down('sm')}`]: {
                                position: 'fixed',
                                bottom: '19px',
                                right: '19px',
                                zIndex: '4',
                            },
                        })}
                        startIcon={<Add />}
                        variant="contained"
                        size={isXSmall ? 'large' : 'small'}
                        onClick={open}
                    >
                        CREATE TAG
                    </Button>
                )}
            </TagDrawerToggler>
        </BoxContainer>
    )
}

export default TagsHeader

import { WorkOrderModel } from 'appTypes/models'
import { BoxContainer, Status, StatusText } from 'components'
import Icons from 'components/icons'
import { useCreateResourcePath } from 'hooks'
import { createReportResource } from 'pages/Units/config/constants'
import { woResource } from 'pages/WorkOrders/config/constants'

import { Monitor } from '../MonitorComponents'

import { useMonitor } from './useMonitor'

interface dataType {
    emergencyCount: number
    nonScheduledCount: number
    scheduledCount: number
}

const MonitorWorkOrder = () => {
    const createPath = useCreateResourcePath()
    const data = useMonitor<dataType>(createReportResource('work-orders-by-repair-class'))
    if (!data) {
        return <Monitor.Skeleton />
    }

    const { emergencyCount, nonScheduledCount, scheduledCount } = data
    const createLink = (repairPriorityClass: WorkOrderModel['repairPriorityClass']) => {
        return (
            createPath({ resource: woResource.resource, type: 'list' }) +
            '?filter=' +
            encodeURIComponent(
                JSON.stringify({
                    repairPriorityClass: [repairPriorityClass],
                    status: ['OPEN'],
                }),
            )
        )
    }
    return (
        <Monitor>
            <BoxContainer gap="4px">
                <Monitor.Title icon={Icons.WorkOrders}>Work Orders</Monitor.Title>
                <StatusText
                    color={(theme) => theme.palette.primary.main}
                    bgcolor={(theme) => theme.palette.primary.main}
                >
                    <BoxContainer gap="4px">
                        <Status
                            size="6px"
                            sx={{ mx: 0 }}
                        />
                        open
                    </BoxContainer>
                </StatusText>
            </BoxContainer>
            <Monitor.ArrowButton
                navigateTo={
                    createPath({
                        resource: woResource.resource,
                        type: 'list',
                    }) +
                    '?filter=' +
                    encodeURIComponent(JSON.stringify({ status: ['OPEN'] }))
                }
            />
            <Monitor.Container>
                <Monitor.Button navigateTo={createLink('EMERGENCY')}>
                    <Monitor.ValueText>{emergencyCount}</Monitor.ValueText>

                    <StatusText statusColor={(theme) => theme.palette.charts.red}>
                        Emergency
                    </StatusText>
                </Monitor.Button>
                <Monitor.Button navigateTo={createLink('NON_SCHEDULED')}>
                    <Monitor.ValueText>{nonScheduledCount}</Monitor.ValueText>
                    <StatusText statusColor={(theme) => theme.palette.charts.orange}>
                        Non-Scheduled
                    </StatusText>
                </Monitor.Button>
                <Monitor.Button navigateTo={createLink('SCHEDULED')}>
                    <Monitor.ValueText>{scheduledCount}</Monitor.ValueText>
                    <StatusText statusColor={(theme) => theme.palette.charts.greenBody}>
                        Scheduled
                    </StatusText>
                </Monitor.Button>
            </Monitor.Container>
        </Monitor>
    )
}
export default MonitorWorkOrder

import { alpha, formControlClasses, styled, typographyClasses } from '@mui/material'

export const ListFilterItemRoot = styled('div')`
    background-color: ${({ theme }) => alpha(theme.palette.grey[900], 0.04)};
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 20px;
    & .${formControlClasses.root} {
        margin-top: 0px;
        margin-bottom: 0px;
    }
`
export const ListFilterItemHeader = styled('div')`
    height: 31px;
    display: flex;
    & > * {
        align-self: center;
    }
    & .${typographyClasses.root} {
        margin-right: 6px;
    }
`

import { forwardRef, ReactElement } from 'react'

import { Avatar } from '@mui/material'
import { alpha, styled } from '@mui/material/styles'

import { generateNotForwardedProps, themeColor, ThemeColorType } from 'utils'

import type { AvatarProps } from '@mui/material'

export interface DataAvatarProps extends Omit<AvatarProps, 'color'> {
    color?: ThemeColorType
    defaultImage?: ReactElement | string
    imageSrc?: string
    avatarOpacity?: number
    className?: string
}

const DataAvatar = forwardRef<any, DataAvatarProps>(
    ({ imageSrc, defaultImage = null, color, ...rest }, ref) => {
        return (
            <Avatar
                ref={ref}
                src={imageSrc}
                alt="Avatar"
                {...rest}
            >
                {imageSrc ? null : defaultImage}
            </Avatar>
        )
    },
)

const shouldForwardProp = generateNotForwardedProps<DataAvatarProps>(['avatarOpacity'])

export default styled(DataAvatar, {
    shouldForwardProp,
})`
    ${({ imageSrc, color, avatarOpacity = 0.08, theme }) =>
        imageSrc
            ? ''
            : `
        border-radius: 50%;
        background: ${alpha(themeColor(color || theme.palette.primary.main, theme), avatarOpacity)};
        display: flex;
        justify-content: center;
        overflow: visible;
        align-items: center;
        & svg {
            color: ${themeColor(color || theme.palette.primary.main, theme)};
        }
`}
`

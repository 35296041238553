import { styled } from '@mui/material'

import { BoxContainer } from 'components'

export const WidgetHeaderContainer = styled(BoxContainer)`
    height: 152px;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    width: 100%;
`

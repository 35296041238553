import { FC } from 'react'

import { useUpdate } from 'react-admin'

import { ReportDowntimeModel, UnitModel } from 'appTypes/models'
import { DropdownSelector, DropdownSelectorProps } from 'components'
import { updatedMessage } from 'configs/constants'
import { useFinalErrorHandler, useNotify } from 'hooks'

import { unitResource, unitStatusChoices } from '../config/constants'

interface UnitStatusSelectorProps
    extends Omit<DropdownSelectorProps, 'choices' | 'value' | 'readOnly' | 'onChange'> {
    record: UnitModel | ReportDowntimeModel
    onComplete?: (record: UnitModel) => void
}

const UnitStatusSelector: FC<UnitStatusSelectorProps> = ({ record, onComplete, ...rest }) => {
    const [update] = useUpdate<UnitModel>()
    const errorHandler = useFinalErrorHandler()
    const notify = useNotify()

    return (
        <DropdownSelector
            {...rest}
            choices={unitStatusChoices}
            value={record.status}
            readOnly={Boolean(record.archived)}
            onChange={async (event) => {
                const value = event.target.value
                try {
                    const newRecord = await update(
                        unitResource.resource,
                        {
                            data: {
                                status: value as any,
                            },
                            id: record.id,
                        },
                        {
                            returnPromise: true,
                        },
                    )
                    onComplete?.(newRecord as UnitModel)
                    notify(updatedMessage, {
                        type: 'success',
                    })
                } catch (err) {
                    errorHandler(err)
                }
            }}
        />
    )
}

export default UnitStatusSelector

import { useMemo } from 'react'

import clsx from 'clsx'
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input'
import { useInput, FieldTitle, Validator } from 'ra-core'
import {
    sanitizeInputRestProps,
    InputHelperText,
    CommonInputProps,
    ResettableTextFieldProps,
} from 'react-admin'

import { useCreateInputId } from 'hooks'

const telValidator: Validator = (value: string) => {
    if (!value || matchIsValidTel(value)) {
        return null
    }
    return 'Invalid phone number'
}

const TelInput = (props: TelInputProps) => {
    const createId = useCreateInputId()
    const { className, label, helperText, onChange, source, validate, ...rest } = props

    const _validate = useMemo(() => {
        if (!validate) {
            return telValidator
        }
        if (Array.isArray(validate)) {
            return [...validate, telValidator]
        }
        return [validate, telValidator]
    }, [validate])

    const {
        field,
        fieldState: { error, invalid, isTouched },
        formState: { isSubmitted },
        isRequired,
    } = useInput({
        ...props,
        validate: _validate,
    })

    return (
        <MuiTelInput
            defaultCountry="US"
            focusOnSelectCountry
            id={createId(source)}
            {...field}
            className={clsx('ra-input', `ra-input-${source}`, className)}
            label={
                label !== '' && label !== false ? (
                    <FieldTitle
                        label={label}
                        source={source}
                        isRequired={isRequired}
                    />
                ) : null
            }
            error={(isTouched || isSubmitted) && invalid}
            helperText={
                <InputHelperText
                    touched={isTouched || isSubmitted}
                    error={error?.message}
                    helperText={helperText}
                />
            }
            {...sanitizeInputRestProps(rest)}
        />
    )
}

TelInput.defaultProps = {
    label: 'Phone',
    source: 'phone',
}

type TelInputProps = CommonInputProps & Omit<ResettableTextFieldProps, 'label' | 'helperText'>

export default TelInput

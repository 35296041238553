import { useEffect, FC } from 'react'

import { CircularProgress } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'

import { BoxContainer } from 'components'
import { urls } from 'configs/config'
import { useAuthProvider } from 'hooks'

const RegisterConfirm: FC = () => {
    const authProvider = useAuthProvider()
    const { key } = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        authProvider
            .registerConfirm(key)
            .then(() => {
                navigate(urls.registerConfirmEmail, { state: { key } })
            })
            .catch(() => {
                navigate(urls.root)
            })
    }, [])

    return (
        <BoxContainer
            sx={{
                height: '100vh',
                justifyContent: 'center',
            }}
        >
            <CircularProgress
                size={100}
                variant="indeterminate"
                disableShrink
            />
        </BoxContainer>
    )
}

export default RegisterConfirm

import { FC } from 'react'

import { UtilityDrawerCloseButtonBase } from 'components'

import { useUtilityDrawerContext } from './UtilityDrawer'
import { UtilityDrawerCloseButtonBaseProps } from './UtilityDrawerCloseButtonBase'

export interface UtilityDrawerCloseButtonProps extends UtilityDrawerCloseButtonBaseProps {
    customAction?: () => void
}

const UtilityDrawerCloseButton: FC<UtilityDrawerCloseButtonProps> = ({
    customAction,
    ...props
}) => {
    const { forceClose } = useUtilityDrawerContext()

    return (
        <UtilityDrawerCloseButtonBase
            {...props}
            onClick={
                customAction ||
                ((e) => {
                    forceClose()
                    props.onClick?.(e)
                })
            }
        />
    )
}

export default UtilityDrawerCloseButton

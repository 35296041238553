import { Grid } from '@mui/material'
import { ReferenceInput } from 'react-admin'

import { VendorModel } from 'appTypes/models'
import {
    EmailInput,
    GridBreak,
    GridContainerColumns,
    gridItemLayoutColumnsDoubled,
    GridItemLayoutInDrawer,
    SectionTitleInDrawer,
    SelectInput as SelectInputBase,
    TelInput,
    TextInput as TextInputBase,
    UploadImage,
} from 'components'
import { stateChoices } from 'configs/constants'
import TagInput from 'pages/Configure/TagsManagement/components/TagInput'
import {
    emailValidation,
    lowerCaseFormat,
    maxLengthValidation,
    requiredValidation,
    urlValidation,
    validateMaxLength,
} from 'utils'

import {
    vendorAvatarConfig,
    vendorPaymentMethods,
    vendorTypes,
    vendorTypesObject,
} from '../constants/config'

const TextInput = TextInputBase<VendorModel>
const SelectInput = SelectInputBase<VendorModel>

const nameValidation = [requiredValidation, maxLengthValidation]
const zipValidation = validateMaxLength(10)
const taxValidation = validateMaxLength(20)

const VendorForm = () => {
    return (
        <>
            <SectionTitleInDrawer>Company Details</SectionTitleInDrawer>
            <GridContainerColumns>
                <GridItemLayoutInDrawer>
                    <TextInput
                        source="name"
                        label="Name"
                        validate={nameValidation}
                    />
                </GridItemLayoutInDrawer>
                <GridItemLayoutInDrawer>
                    <SelectInput
                        disabled
                        source="type"
                        choices={vendorTypes}
                        label="Vendor Type"
                        defaultValue={vendorTypesObject.REPAIR_SERVICES.id}
                    />
                </GridItemLayoutInDrawer>
                <GridBreak />
                <GridItemLayoutInDrawer>
                    <UploadImage
                        buttonChangeText="Change Logo"
                        buttonUploadText="Upload Logo"
                        source="logo"
                        defaultIcon={<vendorAvatarConfig.Icon />}
                    />
                </GridItemLayoutInDrawer>
                <GridBreak />
                <GridItemLayoutInDrawer>
                    <TelInput label="Company Phone" />
                </GridItemLayoutInDrawer>
                <GridItemLayoutInDrawer>
                    <EmailInput
                        label="Company Email"
                        validate={emailValidation}
                    />
                </GridItemLayoutInDrawer>
                <GridBreak />
                <GridItemLayoutInDrawer>
                    <TextInput
                        parse={lowerCaseFormat}
                        source="website"
                        label="Website"
                        validate={urlValidation}
                    />
                </GridItemLayoutInDrawer>
                <GridBreak />
                <GridItemLayoutInDrawer>
                    <TagInput />
                </GridItemLayoutInDrawer>
            </GridContainerColumns>
            <GridBreak />
            <SectionTitleInDrawer
                frontLine
                smallVariant="subtitle2"
                largeVariant="subtitle1"
            >
                Address
            </SectionTitleInDrawer>
            <GridContainerColumns>
                <GridItemLayoutInDrawer
                    nonDrawerProps={{
                        gridColumns: gridItemLayoutColumnsDoubled,
                    }}
                >
                    <TextInput
                        source="address"
                        label="Address Line 1"
                        validate={maxLengthValidation}
                    />
                </GridItemLayoutInDrawer>
                <GridBreak />
                <GridItemLayoutInDrawer
                    nonDrawerProps={{
                        gridColumns: gridItemLayoutColumnsDoubled,
                    }}
                >
                    <TextInput
                        source="address2"
                        label="Address Line 2"
                        validate={maxLengthValidation}
                    />
                </GridItemLayoutInDrawer>
                <GridBreak />
                <GridItemLayoutInDrawer>
                    <TextInput
                        source="city"
                        label="City"
                        validate={maxLengthValidation}
                    />
                </GridItemLayoutInDrawer>
                <GridItemLayoutInDrawer>
                    <SelectInput
                        source="state"
                        label="State"
                        choices={stateChoices}
                    />
                </GridItemLayoutInDrawer>
                <GridBreak />
                <GridItemLayoutInDrawer container>
                    <Grid
                        item
                        xs={8}
                    >
                        <TextInput
                            source="zipCode"
                            label="Zip Code"
                            validate={zipValidation}
                        />
                    </Grid>
                </GridItemLayoutInDrawer>
            </GridContainerColumns>
            <GridBreak />
            <SectionTitleInDrawer>Financial Information</SectionTitleInDrawer>
            <GridContainerColumns>
                <GridItemLayoutInDrawer>
                    <TextInput
                        source="taxId"
                        label="Tax ID"
                        validate={taxValidation}
                    />
                </GridItemLayoutInDrawer>
                <GridItemLayoutInDrawer>
                    <TextInput
                        source="nationalAccount"
                        label="National Account"
                        validate={maxLengthValidation}
                    />
                </GridItemLayoutInDrawer>
                <GridBreak />
                <GridItemLayoutInDrawer>
                    <SelectInput
                        source="paymentMethod"
                        label="Default Payment Method"
                        choices={vendorPaymentMethods}
                    />
                </GridItemLayoutInDrawer>
                <GridItemLayoutInDrawer>
                    <ReferenceInput
                        source="paymentTerm"
                        reference="payment-terms"
                    >
                        <SelectInput
                            label="Default Payment Term"
                            optionText="name"
                        />
                    </ReferenceInput>
                </GridItemLayoutInDrawer>
            </GridContainerColumns>
        </>
    )
}

export default VendorForm

import { createContext, useContext } from 'react'

export interface CollapsibleContextValue {
    isExpanded: (name?: string) => boolean
    toggleExpand: (name?: string) => void
    close: () => void
    isEmpty: boolean
}

export interface CollapsibleControllerResult {
    isExpanded: boolean
    toggleExpand: () => void
    close: () => void
}

export const CollapsibleContext = createContext<CollapsibleContextValue>(null)

export const useCollapsibleContext = () => useContext(CollapsibleContext)

export const useCollapsibleController = (name?: string): CollapsibleControllerResult => {
    const value = useContext(CollapsibleContext)

    return {
        close: value.close,
        isExpanded: value.isExpanded(name),
        toggleExpand: () => value.toggleExpand(name),
    }
}

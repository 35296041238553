import { FC } from 'react'

import { listClasses, paperClasses, menuItemClasses, selectClasses, alpha } from '@mui/material'

import { StyledElement } from 'components/styled'
import { TagColor, tagColors } from 'configs/constants'
import classes from 'theme/classes'

import SelectInput, { SelectInputProps } from './SelectInput'

interface ColorInputProps extends Pick<SelectInputProps, 'validate'> {}

const ColorInput: FC<ColorInputProps> = ({ validate }) => {
    return (
        <>
            <SelectInput
                validate={validate}
                source="color"
                choices={tagColors}
                label="Color"
                disableEmptyValue
                optionText={optionText}
                sx={{
                    [`.${selectClasses.select}`]: {
                        [`.${colorInputClasses.icon}`]: {
                            mx: '3px',
                            width: '20px',
                            height: '20px',
                            flexBasis: '20px',
                            flexGrow: 0,
                            flexShrink: 0,
                        },
                    },
                }}
                SelectProps={{
                    MenuProps: {
                        sx: {
                            // TODO: find a way to tell the Menu adjusts its size equal to the input, without css
                            position: 'absolute !important',
                            [`.${paperClasses.root}`]: {
                                top: 'unset !important',
                                bottom: '0 !important',
                                left: '0 !important',
                                right: '0 !important',
                                transform: 'translateY(100%) !important',
                                transformOrigin: 'unset !important',
                                maxHeight: '400px !important',
                                minHeight: 'unset !important',
                                width: '100% !important',
                                maxWidth: 'unset !important',
                                minWidth: 'unset !important',
                            },
                            [`.${listClasses.root}`]: {
                                display: 'flex',
                                flexWrap: 'wrap',
                                px: '4px',
                            },
                            [`.${menuItemClasses.root}`]: {
                                p: '12px',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
                                    borderRadius: '2px',
                                },
                            },
                            [`.${menuItemClasses.root}.${classes.selected}`]: {
                                backgroundColor: 'unset',
                            },
                            [`.${colorInputClasses.optionText}`]: {
                                display: 'none',
                            },
                        },
                        disablePortal: true,
                    },
                }}
            />
        </>
    )
}

export default ColorInput

const optionText = (option: TagColor) => {
    return (
        <StyledElement
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
            }}
        >
            <StyledElement
                sx={{
                    borderRadius: '50%',
                    background: option.color,
                    width: '24px',
                    height: '24px',
                    [`.${menuItemClasses.root}.${classes.selected} &`]: {
                        boxShadow: '0 0 0 4px ' + alpha(option.color, 0.25),
                    },
                }}
                className={colorInputClasses.icon}
            />
            <StyledElement
                as="span"
                sx={{ overflow: 'hidden' }}
                className={colorInputClasses.optionText}
            >
                {option.name}
            </StyledElement>
        </StyledElement>
    )
}

const colorInputClasses = {
    optionText: 'color-text',
    icon: 'color-icon',
}

import { MergeUnions } from 'appTypes'
import {
    UnitPMIntervalIntersection,
    UnitPMIntervalMeterType,
    UnitPMReminderModel,
    UnitPMIntervalTimeType,
    UnitPMIntervalTimeTypeTypes,
} from 'appTypes/models'
import { DeleteOneFromListParams } from 'hooks'
import serialize, { Serializer, singleSerializer } from 'utils/serializer'

export const defaultValue: UnitPMIntervalTimeTypeTypes = 'DAY'

interface MeterType {
    id: UnitPMIntervalTimeTypeTypes
    name: string
    toDay: number
}

export const typeChoices: MeterType[] = [
    {
        id: defaultValue,
        name: 'Day(s)',
        toDay: 1,
    },
    {
        id: 'WEEK',
        name: 'Week(s)',
        toDay: 7,
    },
    {
        id: 'MONTH',
        name: 'Month(s)',
        toDay: 30,
    },
    {
        id: 'YEAR',
        name: 'Year(s)',
        toDay: 360,
    },
]
export const getTypeChoice = (type: UnitPMIntervalTimeTypeTypes) =>
    typeChoices.find((choice) => choice.id === type)
export const thresholdHelperText = 'Trigger Point for Status Change'

export const unitPMIntervalSerializator: Serializer<UnitPMReminderModel> = [
    'name',
    { name: 'component', parse: 'emptyToNull' },
    { name: 'reasonForRepair', parse: 'emptyToNull' },
    'description',
    'notes',
    { name: 'lastDone', parse: ['dateTime', 'disableOnEdit'] },
    {
        name: 'intervals',
        parse: (value) => {
            const thresholdParse: singleSerializer<UnitPMIntervalIntersection> = {
                name: 'threshold',
                parse: 'number',
            }
            const serializer: Serializer<UnitPMIntervalIntersection> = [
                { name: 'type', parse: 'emptyToNull' },
                { name: 'value', parse: 'number' },
            ]

            return value.map((interval) => {
                let intervalSerializer = [] as Serializer<UnitPMIntervalIntersection>
                if (interval.type === 'TIME') {
                    ;(intervalSerializer as Serializer<UnitPMIntervalTimeType>) = [
                        { name: 'valueType', parse: 'emptyToNull' },
                    ]
                    if (interval.threshold && interval.thresholdType) {
                        ;(intervalSerializer as Serializer<UnitPMIntervalTimeType>).push(
                            {
                                name: 'thresholdType',
                                parse: 'emptyToNull',
                            },
                            thresholdParse as singleSerializer<UnitPMIntervalTimeType>,
                        )
                    }
                } else {
                    ;(intervalSerializer as Serializer<UnitPMIntervalMeterType>) = [
                        { name: 'lastDoneMeterValue', parse: ['number', 'disableOnEdit'] },
                        thresholdParse as singleSerializer<UnitPMIntervalMeterType>,
                    ]
                }

                return serialize(interval as MergeUnions<typeof value[0]>, [
                    ...serializer,
                    ...intervalSerializer,
                ])
            })
        },
    },
]

export const deleteUnitPMIntervalConfig: DeleteOneFromListParams = {
    confirmConfig: {
        title: 'Are you sure you want to delete this interval?',
    },
}

import { DoneAll } from '@mui/icons-material'
import { SvgIcon, alpha, styled } from '@mui/material'
import { useRecordContext } from 'react-admin'

import { ReactComponent as InProgressDVIR } from 'assets/images/in-progress-dvir.svg'
import { ReactComponent as DVIRIcon } from 'assets/images/monitor-dvir.svg'
import { BoxContainer, StatusText, Typography } from 'components'
import { Monitor } from 'pages/Dashboard/components/Monitor/MonitorComponents'
import { useMonitor } from 'pages/Dashboard/components/Monitor/Monitors/useMonitor'
import { SliderWidgetSkeleton } from 'pages/Dashboard/components/WidgetBase/styled'
import { createReportResource } from 'pages/Units/config/constants'

interface dataType {
    inProgressCount: number
    unresolvedCount: number
}
const Circle = styled(BoxContainer)`
    min-width: 80px;
    min-height: 80px;
    border-radius: 50%;
    background-color: ${({ theme }) => alpha(theme.palette.charts.green, 0.15)};
`
const UnitDVIRWidget = () => {
    const record = useRecordContext()
    const data = useMonitor<dataType>(createReportResource(`defects`), {
        unit: record.id,
    })
    if (!data) {
        return <SliderWidgetSkeleton />
    }
    const { inProgressCount, unresolvedCount } = data

    const content =
        inProgressCount + unresolvedCount ? (
            <Monitor.Container>
                <Monitor.ColumnsContainer>
                    <BoxContainer gap="6px">
                        <SvgIcon
                            component={DVIRIcon}
                            sx={{ color: (theme) => theme.palette.error.main }}
                        />
                        <Monitor.ValueText>{unresolvedCount}</Monitor.ValueText>
                    </BoxContainer>
                    <StatusText statusColor={(theme) => theme.palette.charts.red}>
                        Unresolved
                    </StatusText>
                </Monitor.ColumnsContainer>
                <Monitor.ColumnsContainer>
                    <BoxContainer gap="6px">
                        <SvgIcon
                            component={InProgressDVIR || 0}
                            sx={{
                                color: (theme) => theme.palette.primary.main,
                            }}
                        />
                        <Monitor.ValueText display="flex">
                            {inProgressCount || 0}
                            <Typography
                                component="span"
                                variant="h5"
                                color="text.disabled"
                            >
                                /{unresolvedCount || 0}
                            </Typography>
                        </Monitor.ValueText>
                    </BoxContainer>
                    <StatusText statusColor={(theme) => theme.palette.primary.main}>
                        In Progress
                    </StatusText>
                </Monitor.ColumnsContainer>
            </Monitor.Container>
        ) : (
            <BoxContainer justifyContent="space-between">
                <Typography
                    variant="h5"
                    color="text.primary"
                >
                    No Defects Reported
                </Typography>
                <Circle justifyContent="center">
                    <SvgIcon
                        component={DoneAll}
                        sx={{
                            color: (theme) => theme.palette.success.main,
                        }}
                    />
                </Circle>
            </BoxContainer>
        )

    return (
        <Monitor>
            <Monitor.Title>Unresolved Defects</Monitor.Title>

            {content}
        </Monitor>
    )
}

export default UnitDVIRWidget

import { BoxProps, styled, TypographyProps } from '@mui/material'
import clsx from 'clsx'

import { SectionTitle, Skeleton } from 'components'
import { Spacer } from 'components/styled'
interface ViewHeaderProps extends TypographyProps {
    title: string
    loading?: boolean
}
const ViewHeaderSkeleton = () => {
    return (
        <Skeleton
            height="8px"
            width="104px"
            sx={{
                mb: '31px',
            }}
        />
    )
}
export const ViewHeader = styled(({ title, children, loading, ...props }: ViewHeaderProps) => {
    if (loading) {
        return <ViewHeaderSkeleton />
    }

    return (
        <SectionTitle
            as={Spacer}
            {...props}
        >
            {title}
            {children}
        </SectionTitle>
    )
})`
    margin-bottom: 21px;
    width: 100%;
    position: relative;
    & .right {
        margin-left: auto;
    }
    ${({ theme }) => theme.breakpoints.down('sm')} {
        & .rightMobile {
            margin-left: auto;
        }
        & .rightMobile + .right {
            margin-left: revert;
        }
    }
`

interface ViewHeaderContentProps extends BoxProps {
    placement?: 'left' | 'right' | 'rightMobile'
}
const ViewHeaderContent = ({ placement = 'left', className, ...props }: ViewHeaderContentProps) => {
    return (
        <Spacer
            {...props}
            className={clsx(placement, className)}
        />
    )
}

export default Object.assign(ViewHeader, {
    Content: ViewHeaderContent,
})

import { FC } from 'react'

import { Box } from '@mui/material'

import { BoxContainer, Status, Typography, EllipsisOverflow } from 'components'
import { WidgetListContainer, WidgetListFTU } from 'pages/Dashboard/components/WidgetBase/styled'
import LineItemsTooltip from 'pages/WorkOrders/Jobs/WorkOrderJobCard/components/LineItemsTooltip'
import { resolveDoublePriceMask } from 'utils'

import { ListItem } from '../../components'
import { listType, statusColors } from '../types'

interface TopCategoriesProps {
    list: listType
    description: string
}
const TopCategories: FC<TopCategoriesProps> = ({ list, description }) => {
    const { data } = list
    const isValid = data.length > 0 ? data.find((obj) => typeof obj.total === 'number') : false

    if (!isValid) {
        return <WidgetListFTU description={description} />
    }

    const List = data.flatMap(({ id, description, total, code, ...ratioData }, i) => {
        if (total == null) {
            return null
        }
        return (
            <Typography
                component={ListItem}
                variant="chartListItem"
                key={`${description}-${i}`}
            >
                <BoxContainer
                    sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        pr: '10px',
                        textOverflow: 'ellipsis',
                    }}
                >
                    <Box marginRight="13px">
                        <Status
                            size="8px"
                            iconColor={(theme) => theme.palette.charts[statusColors[i]]}
                        />
                    </Box>
                    <EllipsisOverflow
                        as="span"
                        sx={{ lineHeight: '150%' }}
                    >
                        {`${code.substring(0, 3)} ${description}`}
                    </EllipsisOverflow>
                </BoxContainer>

                <LineItemsTooltip
                    data={ratioData}
                    allWhite
                >
                    {resolveDoublePriceMask(total)}
                </LineItemsTooltip>
            </Typography>
        )
    })
    return <WidgetListContainer>{List}</WidgetListContainer>
}
export default TopCategories

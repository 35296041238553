import { ReactElement } from 'react'

import { tabsClasses } from '@mui/material'
import { Tab as RATab, TabbedShowLayoutTabs, TabProps } from 'react-admin'
import { Navigate, Route, useLocation } from 'react-router-dom'

import Skeleton from 'components/Skeletons/Skeleton'
import { BoxContainer } from 'components/styled'
import classes from 'theme/classes'

export interface TabType {
    label: string | ReactElement
    value: string
    path?: string
}

export interface LinkTabsProps {
    tabs?: TabType[]
    tabWidth?: string
    tabMinWidth?: string
    loading?: boolean
}

const Tab = (props: TabProps) => {
    const { search, ...location } = useLocation()

    return (
        <RATab
            {...props}
            // @ts-ignore
            to={{ ...location, pathname: props.value }}
        />
    )
}
const tabsSkeletons = new Array(4).fill(0).map((v, i) => (
    <BoxContainer
        key={i}
        sx={{
            minHeight: '48px',
            minWidth: '120px',
            justifyContent: 'center',
        }}
    >
        <Skeleton
            width="80px"
            height="18px"
        />
    </BoxContainer>
))
const RenderSkeletons = () => {
    return <>{tabsSkeletons}</>
}
const LinkTabs = ({ tabs = [], tabWidth, tabMinWidth, loading }: LinkTabsProps) => {
    if (!tabs.length) {
        return null
    }

    return (
        <TabbedShowLayoutTabs
            allowScrollButtonsMobile
            variant="scrollable"
            syncWithLocation
            sx={{
                background: 'white',
                [`& .${tabsClasses.scrollButtons}`]: (theme) => ({
                    color: theme.palette.text.primary,
                    opacity: 0.54,
                    [`&.${classes.disabled}`]: {
                        opacity: 0.26,
                    },
                }),
            }}
        >
            {/* @ts-ignore */}
            {loading ? (
                <RenderSkeletons />
            ) : (
                tabs.map((tab) => (
                    <Tab
                        sx={(theme) => ({
                            minWidth: tabMinWidth ?? 'fit-content',
                            flex: 1,
                            maxWidth: tabWidth,
                            [theme.breakpoints.down('sm')]: {
                                maxWidth: 'unset',
                            },
                        })}
                        context="header"
                        key={tab.value}
                        path={tab.path || tab.value}
                        value={tab.value}
                        label={tab.label}
                        children=""
                    />
                ))
            )}
        </TabbedShowLayoutTabs>
    )
}

export default LinkTabs

export const tabRoutes = (
    items: (TabType & { element: ReactElement; tabHidden?: boolean })[],
    {
        loading = false,
    }: {
        loading?: boolean
    } = {},
) => {
    const pages = items.filter((item) => item)
    const tabs = pages.filter((tab) => !tab.tabHidden).map(({ element, ...page }) => page)
    const routes = pages.map(({ value, element }) => (
        <Route
            key={value}
            path={value}
            element={element}
        />
    ))
    if (!loading) {
        routes.push(
            <Route
                key="fallback"
                path="*"
                element={
                    <Navigate
                        replace
                        to=""
                    />
                }
            />,
        )
    }

    return {
        tabs,
        routes,
    }
}

import { FC } from 'react'

import { ButtonProps } from '@mui/material'

import { Button } from 'components'
import { useArrayControllerContext } from 'components/ArrayController'
import Icons from 'components/icons'

interface DrawerAddNewButtonProps extends ButtonProps {}

export const UtilityDrawerAddNewButton: FC<DrawerAddNewButtonProps> = (props) => {
    const { array, append, limit } = useArrayControllerContext()

    return (
        <Button
            size="large"
            variant="text"
            disabled={array.length >= limit}
            onClick={() => append()}
            startIcon={<Icons.Add fontSize="large" />}
            children="Add More"
            {...props}
        />
    )
}

import { FC, ReactNode } from 'react'

import { useWatch } from 'react-hook-form'

import api from 'api'
import { PeriodFilter, Form, PeriodSelectorFormValues } from 'components'
import { flagName, useFlags, useQuery } from 'hooks'
import { dateRangeObject } from 'utils'

// TODO: Date - add types to these ids
const defaultDate = 'last-30-days'

interface useDashboardWidgetParms<Response> {
    resource: string
    sideEffect?: (data: Response) => Response
}
type Watched = {
    dateFrom: string
    dateTo: string
    date: string
}
export const useCreateDashboardWidgetLink = (config?: { flag: flagName }) => {
    const time = useWatch<Watched>({ name: ['dateFrom', 'dateTo', 'date'] })

    const flags = useFlags()
    if (config && !flags[config.flag]) {
        return (to: string) => undefined
    }
    return (to: string) =>
        to +
        `?displayedFilters=${encodeURIComponent(
            JSON.stringify({
                info: {
                    period: time[2],
                },
            }),
        )}&filter=
        ${encodeURIComponent(JSON.stringify({ periodStart: time[0], periodEnd: time[1] }))}`
}
export const useDashboardWidget = <Response = unknown,>({
    resource,
    sideEffect,
}: useDashboardWidgetParms<Response>) => {
    const time = useWatch<Watched>({ name: ['dateFrom', 'dateTo', 'date'] })

    const query = useQuery<Response>([resource, ...time], async () => {
        const period: PeriodFilter = {
            periodStart: time[0],
            periodEnd: time[1],
        }
        const data: Response = await api.get(resource, period)
        const mutatedData = sideEffect ? sideEffect(data) : data
        return mutatedData
    })
    return query.data
}

const WidgetsForm: FC<{ children: ReactNode }> = ({ children }) => {
    const [defaultFrom, defaultTo] = dateRangeObject[defaultDate].values('string')

    const defaultValue: PeriodSelectorFormValues = {
        date: defaultDate,
        dateFrom: defaultFrom,
        dateTo: defaultTo,
    }

    return (
        <Form
            defaultValues={defaultValue}
            warnWhenUnsavedChanges={false}
        >
            {children}
        </Form>
    )
}

export default WidgetsForm

import { useListContext } from 'react-admin'

import { useArchivedContext } from 'components/context'
import { basePermissions } from 'configs/constants'
import { useResourcePermissions } from 'hooks'
import useArchiveOneFromList, { ArchiveOneFromListParams } from 'hooks/useArchiveOneFromList'
import { hasPermission } from 'utils'

import { ActionChildren, ListActionExtendedOption } from '../Actions'

import { ArchiveActionArchiveOption, archiveActionBaseOptions } from './archiveActionCommon'

interface ArchiveOneFromListActionProps extends ArchiveOneFromListParams {
    children: ActionChildren<{}, ListActionExtendedOption & ArchiveActionArchiveOption>
}

export const ArchiveOneFromListAction = ({ children, ...rest }: ArchiveOneFromListActionProps) => {
    const archiveOne = useArchiveOneFromList()
    const isArchived = useArchivedContext()
    const listContext = useListContext()

    const permissions = useResourcePermissions(rest.resource)

    if (
        !hasPermission(
            isArchived
                ? permissions[basePermissions.unarchive]
                : permissions[basePermissions.archive],
        )
    ) {
        return null
    }

    const isMultiselectMode = Boolean(listContext.selectedIds.length)

    return children(
        {
            disabled: isMultiselectMode,
            ...archiveActionBaseOptions(isArchived),
            onClick: () => {
                archiveOne(rest)
            },
        },
        {
            isMultiselectMode,
            listContext,
            isArchived,
        },
    )
}

export const archiveOneFromListAction = (params: ArchiveOneFromListActionProps) => (
    <ArchiveOneFromListAction
        {...params}
        key="archive-one-from-list"
    />
)

import AddButton from 'components/AddButton'
import { LinkTabs } from 'components/router'

import PageHeader from './Header'
import HeaderActions from './HeaderActions'
import HeaderAside from './HeaderAside'
import HeaderAvatar from './HeaderAvatar'
import HeaderBackButton from './HeaderBackButton'
import HeaderBlock from './HeaderBlock'
import HeaderInfo from './HeaderInfo'
import PageHeaderMain from './HeaderMain'
import HeaderSpacer from './HeaderSpacer'
import HeaderTitle from './HeaderTitle'

const Header = Object.assign(PageHeader, {
    Title: HeaderTitle,
    Block: HeaderBlock,
    Main: PageHeaderMain,
    Aside: HeaderAside,
    Actions: HeaderActions,
    Avatar: HeaderAvatar,
    Info: HeaderInfo,
    BackButton: HeaderBackButton,
    AddButton,
    Spacer: HeaderSpacer,
    Tabs: LinkTabs,
})

export default Header

import { useState } from 'react'

import { ReferenceInput } from 'react-admin'
import { useFormContext } from 'react-hook-form'

import { CK79Model, CK33Model, JobPartModel } from 'appTypes/models'
import {
    DialogSelector,
    formatCK33CodeByLevel,
    SelectInput,
    TextInput,
    SectionTitleInDrawer,
    TextareaInput,
    Ck33Label,
} from 'components'
import { useUtilityDrawerContext } from 'components/Drawer/UtilityDrawer'
import { GridBreak, GridItem } from 'components/pages'
import { GridContainerColumns } from 'components/pages/GridContainer'
import { requiredValidation, validateMaxLength } from 'utils'
import { costMaskParams, inputQtyMaskParams } from 'utils/masks'

import { PartSelector } from '../../../Parts/components'
import JobLaborPartFormTotal from '../Labor/JobLaborPartFormTotal'
import { WorkOrderItemsExtra } from '../config/constants'

const qtyAndCostValidator = [validateMaxLength(10, 'Invalid value'), requiredValidation]

const DialogSelectorComponent = ({ disabled }: { disabled: boolean }) => {
    return (
        <DialogSelector<CK79Model>
            disabled={disabled}
            titleSource={(record) =>
                record ? `${record.sideAndOrientation} ${record.description}` : 'Position'
            }
            source="position"
            reference="vmrs/ck79"
            defaultSelectorProps={{
                label: 'Position',
            }}
            filter={{
                search: {
                    placeholder: 'Search by Code or Name',
                },
            }}
            itemPrimary="sideAndOrientation"
            itemSecondary="description"
            defaultSelectorValueSource={({ selected }) =>
                selected ? `${selected.sideAndOrientation} ${selected.description}` : ''
            }
        />
    )
}

const JobPartForm = () => {
    const { setValue, getValues } = useFormContext()
    const [showPossition, setShowPossition] = useState(false)
    const { extra } = useUtilityDrawerContext()
    const { disabledFields: disabled } = (extra || {}) as WorkOrderItemsExtra

    return (
        <>
            <SectionTitleInDrawer>Basic Details</SectionTitleInDrawer>
            <GridContainerColumns>
                <GridItem>
                    <PartSelector
                        disabled={disabled}
                        source="part"
                        defaultSelectorValueSource={({ selected, value }) => {
                            const id = getValues('id') // getValues('id') is to check if edit mode
                            if (id && !value) {
                                return '(Deleted) ' + getValues('partNumber')
                            }

                            return selected?.number
                        }}
                        defaultSelectorHelperText={({ value }) => {
                            const id = getValues('id')
                            if (id && !value) {
                                return 'This part has been deleted from the parts catalog.'
                            }

                            return null
                        }}
                        onSelectedChange={({ selected, input }) => {
                            if (input.fieldState.isDirty) {
                                setValue('description', selected?.description || '')
                                setValue('price', selected?.cost || '')
                                setValue('unitOfMeasure', selected?.unitOfMeasure || '')
                                setValue('manufacturer', selected?.manufacturer || '')
                                setValue('component', selected?.component || '')
                                if (!selected?.positionApplicable) {
                                    setValue('position', null)
                                }
                                setValue(
                                    'universalProductCode',
                                    selected?.universalProductCode || '',
                                )
                            }
                            setShowPossition(Boolean(selected?.positionApplicable))
                        }}
                    />
                </GridItem>

                <GridItem>
                    <TextareaInput<JobPartModel>
                        source="description"
                        label="Description"
                        disabled
                    />
                </GridItem>
                <GridItem>
                    <ReferenceInput
                        reference="vmrs/ck33"
                        source="component"
                        enableGetChoices={() => false}
                    >
                        <SelectInput
                            label={<Ck33Label />}
                            disabled
                            optionText={(selectedItem: CK33Model) =>
                                selectedItem
                                    ? formatCK33CodeByLevel(selectedItem.code, selectedItem.level) +
                                      ' ' +
                                      selectedItem.text
                                    : ''
                            }
                        />
                    </ReferenceInput>
                </GridItem>
                <GridItem>
                    <ReferenceInput
                        reference="vmrs/ck34"
                        source="manufacturer"
                        enableGetChoices={() => false}
                    >
                        <SelectInput
                            label="Manufacturer"
                            disabled
                            optionText="description"
                        />
                    </ReferenceInput>
                </GridItem>
                <GridItem>
                    <TextInput
                        source="unitOfMeasure"
                        label="UOM"
                        disabled
                    />
                </GridItem>
                <GridItem>
                    <TextInput
                        source="universalProductCode"
                        label="UPC"
                        disabled
                    />
                </GridItem>

                <GridBreak />

                <GridItem xs={6}>
                    <TextInput
                        source="qty"
                        label="Quantity"
                        validate={qtyAndCostValidator}
                        {...inputQtyMaskParams}
                        disabled={disabled}
                    />
                </GridItem>

                <GridItem xs={6}>
                    <TextInput
                        source="price"
                        label="Cost"
                        validate={qtyAndCostValidator}
                        {...costMaskParams}
                        disabled={disabled}
                    />
                </GridItem>

                <JobLaborPartFormTotal
                    title="Part Total"
                    inputOne="qty"
                    inputTwo="price"
                />
                {showPossition ? (
                    <GridItem>
                        <SectionTitleInDrawer>Position</SectionTitleInDrawer>
                        <DialogSelectorComponent disabled={disabled} />
                    </GridItem>
                ) : null}
            </GridContainerColumns>
        </>
    )
}

export default JobPartForm

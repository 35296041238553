import { PropsWithChildren } from 'react'

import { BoxContainer, Typography, TypographyProps } from 'components'

const ValueText = ({ children, ...props }: PropsWithChildren & TypographyProps) => {
    return (
        <BoxContainer gap="6px">
            <Typography
                {...props}
                variant="h5"
            >
                {children}
            </Typography>
        </BoxContainer>
    )
}
export default ValueText

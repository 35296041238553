import { ReactElement, useLayoutEffect, FC } from 'react'

import { createPortal } from 'react-dom'

import { useOptimizedRef } from 'hooks'

export interface PortalProps {
    containerId: string
    children: ReactElement
    mutateElement?: (el: HTMLDivElement) => void
}

const Portal: FC<PortalProps> = ({ containerId, children, mutateElement }) => {
    const el = useOptimizedRef(() => document.createElement('div'))

    useLayoutEffect(() => {
        const element = document.getElementById(containerId)
        if (element) {
            element.appendChild(el.current)
            mutateElement?.(el.current)
            return () => {
                element.removeChild(el.current)
            }
        }
        console.error('Portal: missing element with id: ', containerId)
    }, [])

    return createPortal(children, el.current)
}

export default Portal

import { FC } from 'react'

import { SubmitButton, SubmitButtonProps } from 'components'

interface AuthSubmitButtonProps extends SubmitButtonProps {}

const AuthSubmitButton: FC<AuthSubmitButtonProps> = (props) => {
    return (
        <SubmitButton
            fullWidth
            icon={null}
            size="large"
            {...props}
        />
    )
}

export default AuthSubmitButton

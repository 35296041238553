import { ReactElement } from 'react'

import { alpha, Backdrop, Box, CircularProgress } from '@mui/material'
import { useStore } from 'react-admin'

import Typography from 'components/mui/Typography'

export interface FullPageLoaderState {
    text?: string | ReactElement
}

const FullPageLoader = () => {
    const [state] = useStore('full-page-loader')

    if (!state) {
        return null
    }

    const { text } =
        typeof state === 'boolean'
            ? {
                  text: 'Please wait…',
              }
            : state

    return (
        <Backdrop
            className="backdrop-visible backdrop-custom-bg"
            sx={{
                zIndex: (theme) => theme.zIndex.drawer + 99999,
                backgroundColor: (theme) => alpha(theme.palette.white, 0.9),
            }}
            open
        >
            <Box textAlign="center">
                <CircularProgress
                    color="primary"
                    size={100}
                />
                <Typography
                    variant="body1"
                    color="text.primary"
                    textAlign="center"
                    component="div"
                >
                    {text}
                </Typography>
            </Box>
        </Backdrop>
    )
}

export default FullPageLoader

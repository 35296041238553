import { ListBase, useListContext, useShowContext } from 'react-admin'

import { CK33Model, UnitModel, WorkOrderModel } from 'appTypes/models'
import { PageContent } from 'components'

import UnitDvirDefectsSection from './UnitDvirDefectsSection'
import { UnitDVIRNoData, UnitDVIRNoIntegration } from './UnitDvirFTU'
import UnitDvirLastSection from './UnitDvirLastSection'
export type UnitDefect = {
    id: string
    component: string
    componentData: CK33Model
    detectedOn: string
    driverComment: string
    dvirs: string[]
    name: string
    photo: string
    authorName: string
    resolvedOn: string
    workOrderData: WorkOrderModel
    isResolved: boolean
}
// const slides = []
const UnitDVIR = () => {
    const { record, resource } = useShowContext<UnitModel>()
    if (!record) {
        return null
    }
    return (
        <PageContent>
            {!record.telematicsData ||
            record.telematicsData.telematicsStatus === 'DISCONNECTED' ||
            record.telematicsData.status === 'DEACTIVATED' ||
            record.archived ? (
                <UnitDVIRNoIntegration archived={record.archived} />
            ) : (
                <ListBase resource={`${resource}/${record.id}/defects`}>
                    <UnitDvirContent />
                </ListBase>
            )}
        </PageContent>
    )
}
const UnitDvirContent = () => {
    const { data } = useListContext<UnitDefect>()
    if (!data) {
        return null
    }
    if (!data.length) {
        return <UnitDVIRNoData />
    }
    return (
        <>
            <UnitDvirLastSection />
            <UnitDvirDefectsSection data={data} />
        </>
    )
}
export default UnitDVIR

import { ArrowForward } from '@mui/icons-material'
import { Button } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { calculateSyncedUnits } from '../IntegrationsUnitListDialog'
import { SyncedStateType } from '../utils/SyncedStateContext'

const UnitsToImportButton = ({ onClick, children }: { onClick: () => void; children: number }) => {
    const { watch } = useFormContext()
    const synced: SyncedStateType = watch('vehiclesSyncStatus', {})
    const syncedUnits = calculateSyncedUnits(synced, children)
    return (
        <Button
            color="primary"
            size="small"
            sx={{
                color: (theme) => `${theme.palette.primary.main} !important`,
            }}
            endIcon={<ArrowForward />}
            onClick={onClick}
        >
            Syncing {syncedUnits} Units
        </Button>
    )
}
export default UnitsToImportButton

import { useEffect, useContext } from 'react'

import { BlockersContext, BlockerType } from 'components'

interface Options {
    isOpen?: boolean
}

const useSetBlocker = (params: BlockerType, { isOpen = true }: Options = {}) => {
    const block = useContext(BlockersContext)

    useEffect(() => {
        if (isOpen) {
            const unblock = block(params)

            return unblock
        }
    }, [isOpen])
}

export default useSetBlocker

import { ReactElement } from 'react'

import { RefreshOutlined, WidgetsOutlined } from '@mui/icons-material'
import { Box, Breakpoint, Button, Divider, FormControlLabel, Switch } from '@mui/material'

import { BoxContainer, Columns, Typography, PeriodSelector } from 'components'
import { ActionsMenu } from 'components/actions'
import { flagName, useFlags } from 'hooks'

import GridLayout, { GridLayoutItem } from '../GridLayout'
import WidgetsForm from '../WidgetsForm'
import 'react-grid-layout/css/styles.css'
import { useWidgetLayoutContext } from '../WidgetsLayoutProvider'

import ComplianceRateWidget from './ComplianceRateWidget'
import CostPerCategoryWidget from './CostPerCategoryWidget'
import CostPerEquipmentWidget from './CostPerEquipment/CostPerEquipmentWidget'
import CostPerRepairReasonWidget from './CostPerRepairReasonWidget'
import CostPerUnitWidget from './CostPerUnitWidget'
import { CostPerVMRSWidget } from './CostPerVMRS'
import DowntimeWidget from './DowntimeWidget'
import FleetCostPerHourWidget from './FleetCostPerHourWidget'
import FleetCostPerMileWidget from './FleetCostPerMileWidget'
import VendorExpensesWidget from './VendorExpensesWidget'

const filterBreakpoint: Breakpoint = 'sm'

type WidgetListType = {
    id: string
    name: string
    component: ReactElement
    flag?: flagName
}[]
export const WidgetList: WidgetListType = [
    { id: 'cost-per-unit', name: 'Maintenance Cost', component: <CostPerUnitWidget /> },
    {
        id: 'cost-per-mile',
        name: 'Maintenance CPM',
        component: <FleetCostPerMileWidget />,
    },
    { id: 'downtime', name: 'Downtime by Repair Class', component: <DowntimeWidget /> },
    {
        id: 'cost-per-category',
        name: 'Top 3 Categories Account for',
        component: <CostPerCategoryWidget />,
    },
    {
        id: 'cost-per-vmrs',
        name: 'Top 3 VMRS Groups Account for',
        component: <CostPerVMRSWidget />,
    },
    {
        id: 'cost-per-hour',
        name: 'Cost per Engine Hour',
        component: <FleetCostPerHourWidget />,
    },
    {
        id: 'cost-per-vendor',
        name: 'Total Vendor Expenses',
        component: <VendorExpensesWidget />,
    },
    {
        id: 'cost-per-repair',
        name: 'Top 3 Reasons for Repair Account for',
        component: <CostPerRepairReasonWidget />,
    },
    {
        id: 'cost-per-equipment',
        name: 'Cost per Equipment Categories',
        component: <CostPerEquipmentWidget />,
    },
    {
        id: 'compliance-rate',
        name: 'Preventive Maintenance Compliance Rate',
        component: <ComplianceRateWidget />,
    },
]

const ManageWidgetsButton = () => {
    const { mutate, visibleWidgets, reset } = useWidgetLayoutContext()

    const toggleColumn = (event) => {
        const name = event.target.name
        mutate(name)
    }
    return (
        <ActionsMenu
            actions={() => [
                <Box
                    paddingX="15px"
                    paddingY="5"
                    key="0"
                >
                    <BoxContainer>
                        <Typography
                            variant="subtitle1"
                            color="text.secondary"
                            mb="12px"
                        >
                            Current Arrangement
                        </Typography>
                        <Divider variant="inset" />
                    </BoxContainer>
                    <Columns gap="5px">
                        {WidgetList.map((widget) => {
                            return (
                                <FormControlLabel
                                    key={widget.id}
                                    label={widget.name}
                                    control={
                                        <Switch
                                            checked={visibleWidgets[widget.id]}
                                            onChange={toggleColumn}
                                            name={widget.id}
                                            size="small"
                                        />
                                    }
                                />
                            )
                        })}
                    </Columns>
                    <BoxContainer mt="15px">
                        <Button
                            size="small"
                            startIcon={<RefreshOutlined />}
                            onClick={reset}
                        >
                            Reset
                        </Button>
                    </BoxContainer>
                </Box>,
            ]}
            renderToggler={(open) => (
                <Button
                    sx={(theme) => ({
                        color: theme.palette.text.secondary,
                        gap: '4px',
                    })}
                    onClick={open}
                >
                    <WidgetsOutlined fontSize="small" />
                    Manage
                </Button>
            )}
        />
    )
}

const Widgets = () => {
    const flags = useFlags()

    const { widgets } = useWidgetLayoutContext()
    return (
        <WidgetsForm>
            <Box
                mb={{
                    xs: '20px',
                    [filterBreakpoint]: '16px',
                }}
                display="flex"
                justifyContent="space-between"
                flexDirection={{ xs: 'column', [filterBreakpoint]: 'row' }}
                alignItems={{ [filterBreakpoint]: 'center' }}
            >
                <BoxContainer
                    sx={{
                        flex: '1',
                        justifyContent: 'space-between',
                    }}
                    mb={{ xs: '12px', [filterBreakpoint]: '0' }}
                >
                    <Typography
                        sx={{
                            typography: {
                                xs: 'subtitle1',
                                [filterBreakpoint]: 'h6',
                            },
                        }}
                    >
                        Widgets
                    </Typography>
                    <ManageWidgetsButton />
                </BoxContainer>

                <div>
                    <PeriodSelector />
                </div>
            </Box>
            <GridLayout>
                {widgets.map((widget, i) => {
                    return !widget.flag || flags[widget.flag] ? (
                        <GridLayoutItem
                            key={widget.id}
                            id={widget.id}
                            className="grid-item-handle"
                        >
                            {widget.component}
                        </GridLayoutItem>
                    ) : null
                })}
            </GridLayout>
        </WidgetsForm>
    )
}
export default Widgets

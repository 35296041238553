import { useRef, useState } from 'react'

import { styled } from '@mui/material'
import { GoogleMap, Marker } from '@react-google-maps/api'
import { useRecordContext } from 'react-admin'

import { UnitModel } from 'appTypes/models'
import markerIcon from 'assets/images/marker.svg'
import { useGoogleMap } from 'components'

import MapInfoBox from './MapInfoBox'
import unitMapStyles from './unitMapStyles'

const containerStyle = {
    width: '100%',
    height: '200px',
}

const options: google.maps.MapOptions = {
    styles: unitMapStyles,
    streetViewControl: false,
    mapTypeControl: false,
    zoomControl: false,
    fullscreenControl: false,
    panControl: false,
    disableDoubleClickZoom: true,
    gestureHandling: 'none',
    keyboardShortcuts: false,
    clickableIcons: false,
}

const Container = styled('div')`
    position: relative;

    // Hide Google Maps logo and "Terms of Use" link
    .gm-style-cc,
    a[target='_blank'] {
        display: none !important;
    }
`

const UnitMap = () => {
    const { isLoaded } = useGoogleMap()

    const [infoWindowOpen, setInfoWindowOpen] = useState(false)
    const unit = useRecordContext<UnitModel>()
    const location = unit.location

    const targetRef = useRef(null)

    const showInfoWindow = () => {
        setInfoWindowOpen(true)
    }

    return (
        <Container>
            <MapInfoBox
                targetRef={targetRef.current?.mapRef}
                isOpen={infoWindowOpen}
                close={() => setInfoWindowOpen(null)}
            />
            {isLoaded ? (
                <GoogleMap
                    ref={targetRef}
                    mapContainerStyle={containerStyle}
                    center={{
                        // move the point a little bit to the top, center of the visible area
                        lat: location.latitude - 0.005,
                        lng: location.longitude,
                    }}
                    zoom={12}
                    options={options}
                    onClick={showInfoWindow}
                >
                    <Marker
                        onClick={showInfoWindow}
                        options={{
                            clickable: true,
                        }}
                        position={{
                            lat: location.latitude,
                            lng: location.longitude,
                        }}
                        icon={markerIcon}
                    />
                </GoogleMap>
            ) : null}
        </Container>
    )
}

export default UnitMap

import { useSetBlocker } from 'hooks'

import { BlockerType } from './Blocker'

const SetBlocker = (props: BlockerType) => {
    useSetBlocker(props)

    return null
}
export default SetBlocker

import { ReactElement } from 'react'

import { inject, observer } from 'mobx-react'
import { useLocation, Navigate } from 'react-router-dom'

import { urls } from 'configs/config'
import { AuthStore } from 'providers/authStore'

interface AnonymousRequiredProps {
    children: ReactElement<any, any>
    auth?: AuthStore
}

const AnonymousRequired = inject('auth')(
    observer(({ children, auth }: AnonymousRequiredProps) => {
        const location = useLocation()

        if (auth.user) {
            const next = new URLSearchParams(location.search).get('next')
            return (
                <Navigate
                    to={{
                        pathname: (next && next !== urls.logout && next) || urls.root,
                    }}
                />
            )
        }

        return children
    }),
)

export default AnonymousRequired

import { useOpenUtilityDrawer } from 'components/Drawer'
import {
    PaymentFormCancelButton,
    PaymentFormContent,
    PaymentFormSubmitButton,
    PaymentFormWrapper,
} from 'pages/Company/CompanyInfo/CurrentBill/components'
import { authStore } from 'providers'

const usePaymentMethodForm = () => {
    const open = useOpenUtilityDrawer()
    const billing = !!authStore.billing

    return function (type: 'upgrade' | 'change') {
        if (!billing) {
            return null
        }

        open({
            drawerArgs: {
                title: type === 'change' ? 'Change Payment Method' : 'Add Payment Method',
                renderWrapper: (params) => (
                    <PaymentFormWrapper
                        {...params}
                        type={type}
                    />
                ),
                renderContent: () => <PaymentFormContent />,
                renderBottomRight: (renderButton) => (
                    <PaymentFormSubmitButton render={renderButton} />
                ),
                renderBottomLeft: (renderButton) => (
                    <PaymentFormCancelButton render={renderButton} />
                ),
            },
            extraArgs: {
                type: 'create',
            },
        })
    }
}

export default usePaymentMethodForm

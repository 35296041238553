import { FC } from 'react'

import { useMediaQuery, Tooltip, Theme, TooltipProps } from '@mui/material'

import { useSidebarContext } from 'pages/layouts/Sidebar'

const SidebarTooltip: FC<TooltipProps> = (props) => {
    const { open } = useSidebarContext()

    const matches = useMediaQuery((theme: Theme) =>
        theme.breakpoints.up(theme.props.mobileViewBreakpoint),
    )

    return (
        <>
            <Tooltip
                {...props}
                title={matches && !open ? props.title : ''}
            />
        </>
    )
}

export default SidebarTooltip

import { DependencyList, EffectCallback, useEffect, useRef } from 'react'

const useDidUpdate = (effect: EffectCallback, deps?: DependencyList) => {
    const didUpdate = useRef(false)

    useEffect(() => {
        if (didUpdate.current) {
            effect()
        } else {
            didUpdate.current = true
        }
    }, deps)
}

export default useDidUpdate

import { FC } from 'react'

import { MeterModel } from 'appTypes/models'
import { getMeterTypeAdornment } from 'pages/Units/Meters/constants'
import { formatDate, integerNonNegativeSpacedMaskResolver } from 'utils'

import { PMLastMetersContextResult, usePMLastMetersContext } from './PMLastMetersContext'

interface PMLastMeterHelperTextProps {
    meterType: MeterModel['type']
    meters?: PMLastMetersContextResult['meters']
    meter?: MeterModel
}

const PMLastMeterHelperText: FC<PMLastMeterHelperTextProps> = ({ meters, meterType, meter }) => {
    const metersContext = usePMLastMetersContext()
    const finalMeters = meters || metersContext?.meters
    const finalMeter = meter || finalMeters?.[meterType]
    if (!meter) {
        return null
    }
    return (
        <>
            Last reading {integerNonNegativeSpacedMaskResolver(finalMeter.value)}{' '}
            {getMeterTypeAdornment(meterType)} •{' '}
            {formatDate(finalMeter.timestamp, 'MMM dd yyyy hh:mm')}
        </>
    )
}

export default PMLastMeterHelperText

import { createContext, FC, ReactNode, useContext, useState } from 'react'

import { useNotify } from 'react-admin'

import {
    Typography,
    Form,
    TextInput,
    FormLoadingFullPageLoader,
    TelInput,
    Button,
} from 'components'
import { nonFieldErrors } from 'configs/constants'
import { useAuthProvider, useSubmit } from 'hooks'
import { companyNameValidation } from 'pages/Company/CompanyInfo/CompanyInfoDrawerForm'
import { requiredValidation, serialize } from 'utils'

import { AuthBody, AuthSubmitButton, TosButton } from '../components'

const sources = {
    companyPhone: 'companyPhone',
    companyName: 'companyName',
    tosAccepted: 'tosAccepted',
} as const

export interface CompanyDetailsData {
    [sources.companyPhone]: string
    [sources.companyName]: string
    [sources.tosAccepted]: boolean
}

const CompanyDetails = () => {
    const { userState, reset } = useRegisterContext()
    const authProvider = useAuthProvider()
    const notify = useNotify()

    const submitHandler = useSubmit(async (data: CompanyDetailsData) => {
        const formData = serialize(data, Object.keys(sources) as any[])
        const values = { ...formData, ...userState }
        if (userState.accessToken) {
            await authProvider.loginWithGoogle(values as any, true)
        } else {
            await authProvider.register(values, false)
        }
    })

    return (
        <AuthBody>
            <Form
                warnWhenUnsavedChanges={false}
                onSubmit={submitHandler}
                formOnError={({ errors, defaultOnError }) => {
                    if (
                        Object.keys(errors).some(
                            (source) => source !== nonFieldErrors && !sources[source],
                        )
                    ) {
                        notify('Invalid provided values', { type: 'error' })
                        reset()
                        return
                    }

                    defaultOnError()
                }}
            >
                <Typography
                    variant="h6"
                    mb="8px"
                >
                    Company Details
                </Typography>

                <Typography
                    variant="body2"
                    color="text.primary"
                    mb="24px"
                >
                    Almost there! <br />
                    Just a few more fields to complete.
                </Typography>

                <TextInput
                    source={sources.companyName}
                    validate={companyNameValidation}
                    label="Company Name"
                />

                <TelInput
                    validate={requiredValidation}
                    source={sources.companyPhone}
                />

                <TosButton />

                <FormLoadingFullPageLoader />
                <AuthSubmitButton label="Sign up" />

                <Button
                    type="button"
                    variant="text"
                    size="large"
                    sx={{ mt: '24px' }}
                    fullWidth
                    onClick={reset}
                >
                    Cancel
                </Button>
            </Form>
        </AuthBody>
    )
}

const RegisterContext = createContext<{
    userState: Record<string, any>
    setUserState: (userState: object) => void
    reset: () => void
}>(null)

const RegisterProvider = ({ children }: { children: ReactNode }) => {
    const [userState, setUserState] = useState(null)

    return (
        <RegisterContext.Provider
            value={{
                userState,
                setUserState,
                reset: () => setUserState(null),
            }}
        >
            {userState ? <CompanyDetails /> : children}
        </RegisterContext.Provider>
    )
}

export const useRegisterContext = () => useContext(RegisterContext)

export const withRegisterContext = (Component: FC) =>
    function () {
        return (
            <RegisterProvider>
                <Component />
            </RegisterProvider>
        )
    }

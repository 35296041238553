import { ReactComponent as ArchivedIcon } from 'assets/images/widgets-archived.svg'
import { Typography } from 'components'
import { useCreateResourcePath } from 'hooks'
import { useWidgetContext } from 'pages/Dashboard/components/GridLayout'
import { WidgetListContainer, WidgetListFTU } from 'pages/Dashboard/components/WidgetBase/styled'
import { getUnitResource } from 'pages/Units/config/constants'
import LineItemsTooltip from 'pages/WorkOrders/Jobs/WorkOrderJobCard/components/LineItemsTooltip'
import { resolveDoublePriceMask, formatPercent } from 'utils'

import { WidgetListRowBase } from '../../components'
import { CostPerUnitList as CostPerUnitListType } from '../types'

interface CostPerUnitListProps {
    data: CostPerUnitListType
    description: string
    validData: boolean
}
const CostPerUnitList = ({ data = [], description, validData }: CostPerUnitListProps) => {
    const sortedData = data.length ? data.sort((a, b) => b.total - a.total) : data
    const createPath = useCreateResourcePath()
    const dimensions = useWidgetContext()

    const List = sortedData
        .slice(0, 3)
        .flatMap(({ number, total, totalPercent, id, archived, ...ratioData }) => {
            if (!total && total !== 0) {
                return []
            }
            const unitEditUrl = createPath({
                resource: getUnitResource(archived).resource,
                id,
                type: 'edit',
            })
            return (
                <WidgetListRowBase
                    key={id}
                    link={unitEditUrl}
                    label={number}
                    hideClarification={!validData || total <= 0 || dimensions === 'mdY'}
                    icon={(render) =>
                        archived ? render({ icon: ArchivedIcon, tooltip: 'Archived' }) : undefined
                    }
                    clarification={
                        <>
                            {formatPercent(totalPercent)}
                            <Typography
                                color="text.disabled"
                                variant="chartListItem"
                            >
                                {' '}
                                of total cost
                            </Typography>
                        </>
                    }
                    total={
                        <LineItemsTooltip
                            data={ratioData}
                            allWhite
                        >
                            {resolveDoublePriceMask(total)}
                        </LineItemsTooltip>
                    }
                />
            )
        })

    return (
        <WidgetListContainer>
            {List.length ? List : <WidgetListFTU description={description} />}
        </WidgetListContainer>
    )
}
export default CostPerUnitList

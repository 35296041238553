import { FC } from 'react'

import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { Form, Typography, EmailInput, AnchorLink } from 'components'
import { urls } from 'configs/config'
import { useAuthProvider, useSubmit } from 'hooks'
import { emailValidation } from 'utils'

import { AuthBody, AuthSubmitButton, ErrorResetMessage } from '../components'

import { ResetPasswordState } from './ResetPasswordDone'

const ResetPassword: FC = () => {
    const authProvider = useAuthProvider()

    const navigate = useNavigate()

    const handleSubmit = useSubmit<{ email: string }>(async ({ email }) => {
        await authProvider.resetPassword(email)

        const state: ResetPasswordState = { validEmail: email }
        navigate(urls.passwordResetDone, {
            state,
        })
    })

    return (
        <AuthBody>
            <Typography
                variant="h6"
                mb="21px"
            >
                Forgot Password
            </Typography>

            <Form
                onSubmit={handleSubmit}
                warnWhenUnsavedChanges={false}
            >
                <ErrorResetMessage />

                <EmailInput
                    validate={emailValidation}
                    label="Email Address"
                />

                <AuthSubmitButton label="Reset Password" />
            </Form>

            <Box
                mt="23px"
                display="flex"
                justifyContent="center"
            >
                <Typography display="inline">Don't have an account?&nbsp;</Typography>

                <AnchorLink to={urls.register}>Sign up!</AnchorLink>
            </Box>
        </AuthBody>
    )
}

export default ResetPassword

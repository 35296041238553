import { ReactElement } from 'react'

import { Identifier, ListControllerResult, useListContext, useShowContext } from 'react-admin'

import { JobModel, WorkOrderModel } from 'appTypes/models'
import { ResourceType, useOpenUtilityDrawer, useResource } from 'components'
import { ConfirmContentWithFetchOne } from 'components/Confirm'
import UtilityDrawerListEditor from 'components/Drawer/UtilityDrawerListEditor'
import { basePermissions } from 'configs/constants'
import { renderOnPermission } from 'hocs'
import { DeleteOneParams } from 'hooks'
import {
    indexes,
    photoName,
    photoSerializer,
} from 'pages/WorkOrders/Invoice/components/InvoiceDrawer/components/Attachments'
import { useWoActionIsDisabled } from 'pages/WorkOrders/hooks'
import { Serializer } from 'utils'

import { JobForm } from '../components'

export const woJobSerializer: Serializer<JobModel> = [
    'name',
    'complaint',
    { name: 'notesToAdd', parse: 'emptyToNull' },
    { name: 'component', parse: 'emptyToNull' },
    { name: 'reasonForRepair', parse: 'emptyToNull' },
    ...(photoSerializer as Serializer<JobModel>),
]
export const CreateJobDrawerToggler = renderOnPermission(
    ({ children }: { children: (params: { onClick: () => void }) => ReactElement }) => {
        const open = useOpenUtilityDrawer()
        const resource = useResource()
        const listContext = useListContext()
        const { refetch } = useShowContext<WorkOrderModel>()
        const disabled = useWoActionIsDisabled()

        if (disabled) {
            return null
        }

        return children({
            onClick: () => {
                open({
                    drawerArgs: {
                        title: 'Create Job',
                        renderBottomRight: (render) =>
                            render({
                                label: 'Create',
                            }),
                        renderWrapper: (params) => (
                            <UtilityDrawerListEditor
                                onSuccess={refetch}
                                {...params}
                                serializer={woJobSerializer}
                                meta={{
                                    files: indexes.map(photoName),
                                }}
                            />
                        ),
                        renderContent: () => <JobForm />,
                    },
                    extraArgs: {
                        resource,
                        listContext,
                    },
                })
            },
        })
    },
    basePermissions.create,
)

export const deleteJobAction = (
    id: Identifier,
    resource: ResourceType,
    onSuccess?: () => void,
): DeleteOneParams => {
    return {
        onSuccess,
        confirmConfig: {
            title: 'Are you sure you want to delete this Job?',
            content: (
                <ConfirmContentWithFetchOne<JobModel>
                    id={id}
                    resource={resource}
                    render={({ itemsCount, pmSchedule }) => (
                        <>
                            This Job contains {itemsCount} line items.
                            {Boolean(pmSchedule) &&
                                ' It originated from a PM service reminder and deleting the Job will remove the connection with the WO.'}
                        </>
                    )}
                />
            ),
        },
    }
}

export interface WorkOrderItemsExtra {
    disabledFields: boolean
}

export const EditJobDrawerToggler = renderOnPermission(
    ({
        children,
        job,
        resource,
        listContext,
    }: {
        listContext: ListControllerResult
        job: JobModel
        resource: ResourceType
        children: (params: { onClick: () => void }) => ReactElement
    }) => {
        const open = useOpenUtilityDrawer()
        const { refetch, record = {} as WorkOrderModel } = useShowContext<WorkOrderModel>()
        const disabled = useWoActionIsDisabled({ workOrder: record })

        return children({
            onClick: () => {
                open({
                    drawerArgs: {
                        title: disabled ? 'View Job' : 'Edit Job',
                        renderTopRight: (render) =>
                            render({
                                ...deleteJobAction(job.id, resource, refetch),
                                disabled,
                                title:
                                    record.status === 'CLOSED'
                                        ? 'Reopen the WO to delete this job'
                                        : undefined,
                            }),
                        renderWrapper: (params) => (
                            <UtilityDrawerListEditor
                                onSuccess={refetch}
                                {...params}
                                serializer={woJobSerializer}
                                meta={{
                                    files: indexes.map(photoName),
                                }}
                            />
                        ),
                        renderBottomRight: disabled ? () => null : undefined,
                        renderBottomLeft: disabled
                            ? (renderCancelButton) => renderCancelButton({ children: 'Close' })
                            : undefined,
                        renderContent: () => <JobForm job={job} />,
                    },
                    extraArgs: {
                        resource,
                        listContext,
                        id: job.id,
                    },
                    extra: {
                        disabledFields: disabled,
                    } as WorkOrderItemsExtra,
                })
            },
        })
    },
    basePermissions.update,
)

import { Typography } from 'components'
import { useCreateResourcePath } from 'hooks'
import { WidgetListContainer, WidgetListFTU } from 'pages/Dashboard/components/WidgetBase/styled'
import { vendorsResource } from 'pages/Vendors/constants/config'
import LineItemsTooltip from 'pages/WorkOrders/Jobs/WorkOrderJobCard/components/LineItemsTooltip'
import { formatPercent, resolveDoublePriceMask } from 'utils'

import { WidgetListRowBase } from '../../components'
import { VendorExpensesListType } from '../types'

interface TopSpendersProps {
    data: VendorExpensesListType
    description: string
    validData: boolean
}
const VendorExpensesList = ({ data = [], description, validData }: TopSpendersProps) => {
    const sortedData = data.length ? data.sort((a, b) => b.total - a.total) : data
    const createPath = useCreateResourcePath()

    const List = sortedData
        .slice(0, 3)
        .flatMap(({ name, total, totalPercent, id, ...ratioData }) => {
            if (!total && total !== 0) {
                return []
            }
            const vendorUrl = createPath({
                resource: vendorsResource.resource,
                id,
                type: 'edit',
            })

            return (
                <WidgetListRowBase
                    key={id}
                    link={vendorUrl}
                    label={name}
                    hideClarification={!validData || total < 0}
                    clarification={
                        <>
                            {formatPercent(totalPercent)}
                            <Typography
                                color="text.disabled"
                                variant="chartListItem"
                            >
                                {' '}
                                of total vendor expenses
                            </Typography>
                        </>
                    }
                    total={
                        <LineItemsTooltip
                            data={ratioData}
                            allWhite
                        >
                            {resolveDoublePriceMask(total)}
                        </LineItemsTooltip>
                    }
                />
            )
        })

    return (
        <WidgetListContainer>
            {List.length ? List : <WidgetListFTU description={description} />}
        </WidgetListContainer>
    )
}
export default VendorExpensesList

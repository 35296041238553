import { Link as MuiLink } from '@mui/material'
import { GridRenderCellParams } from '@mui/x-data-grid'
import { Link } from 'react-router-dom'

import { useCreateResourcePath } from 'hooks'

const DatagridLink = (props: GridRenderCellParams & { resource?: string }) => {
    const createPath = useCreateResourcePath()

    const to = createPath({
        resource: props.resource,
        id: props.row.id,
        type: 'edit',
    })

    return (
        <MuiLink
            underline="none"
            component={Link}
            to={to}
        >
            {props.value}
        </MuiLink>
    )
}

export default DatagridLink

import { formHelperTextClasses } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import api from 'api'
import { Role } from 'appTypes/models'
import {
    ArrayControllerBox,
    ArrayControllerElements,
    SelectInput,
    useArrayControllerElementContext,
    Typography,
    EmailInput,
} from 'components'
import { useQuery } from 'hooks'
import { emailValidation } from 'utils'

const MemberInviteFormContent = () => {
    const roles = useQuery<Role[]>('roles', () => api.get('roles'))
    const { unregister } = useFormContext()

    if (roles.isError || roles.isFetching || !roles.data?.length) {
        return null
    }

    return (
        <>
            <Typography
                variant="body1"
                mb="16px"
                color="text.secondary"
            >
                To invite members, please enter an email.
            </Typography>
            <ArrayControllerElements
                onDelete={({ item }) => {
                    unregister(`email${item}`)
                    unregister(`role${item}`)
                }}
            >
                <ArrayControllerBox title="New Member">
                    <MemberInviteFormElement roles={roles.data} />
                </ArrayControllerBox>
            </ArrayControllerElements>
        </>
    )
}

const MemberInviteFormElement = ({ roles }: { roles: Role[] }) => {
    const { item } = useArrayControllerElementContext()

    return (
        <>
            <EmailInput
                source={`email${item}`}
                validate={emailValidation}
                fullWidth
                label="Email Address"
            />
            <SelectInput
                helperText={false}
                source={`role${item}`}
                defaultValue={roles[0].id}
                choices={roles}
                optionText="label"
                label="Role"
                sx={{
                    [`& .${formHelperTextClasses.root}`]: {
                        display: 'none',
                    },
                }}
                disableEmptyValue
                clearable={false}
            />
        </>
    )
}

export default MemberInviteFormContent

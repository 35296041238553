import { FC } from 'react'

import { ArrowForward } from '@mui/icons-material'
import { useListContext } from 'react-admin'

import { MeterModel } from 'appTypes/models'
import { SliderView, useArchivedContext } from 'components'
import { ActionsMenu } from 'components/actions'
import { useCreateResourcePath } from 'hooks'
import { ArchivedAction } from 'pages/Units/components'

import { metersActions } from '../../Meters/actions'
import {
    BoxLink,
    UnitMetersEmpty,
    UnitMetersCard,
    UnitMetersSectionTitle,
} from '../../Meters/components'
import { UnitMetersFallback } from '../UnitOverview'

const UnitMeters: FC = () => {
    const { data = [], total, isLoading } = useListContext<MeterModel>()
    const isArchived = useArchivedContext()

    const action = isArchived ? (
        <ArchivedAction to="meters" />
    ) : (
        <ActionsMenu actions={metersActions} />
    )

    const createPath = useCreateResourcePath()
    if (isLoading) {
        return <UnitMetersFallback />
    }
    let content

    if (total) {
        const items = data.map((meter) => <UnitMetersCard meter={meter} />)
        if (total >= 4) {
            const path = createPath({
                type: 'list',
            })
            items.push(
                <BoxLink
                    to={path}
                    label="SEE ALL METERS"
                    icon={<ArrowForward />}
                />,
            )
        }
        content = <SliderView items={items} />
    } else {
        content = <UnitMetersEmpty />
    }

    return (
        <div>
            <UnitMetersSectionTitle action={action} />
            {content}
        </div>
    )
}

export default UnitMeters

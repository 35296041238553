import { ComponentProps, useRef, useState } from 'react'

import { styled } from '@mui/material'
import { Item } from 'react-photoswipe-gallery'

import { StyledElement } from 'components/styled'
import { useAttachmentFile } from 'hooks'
const ItemWrapper = styled(StyledElement)`
    border-radius: 4px;
`
const SwipeImg = styled('img')`
    border-radius: 4px;
    cursor: pointer;
    object-fit: cover;
    width: 100%;
    height: 100%;
`
export interface RawGalleryItemProps extends ComponentProps<typeof StyledElement> {
    src: string
}
interface GalleryItemProps extends Omit<RawGalleryItemProps, 'src'> {
    file: string | File
}
export const GalleryItem = ({ file, ...props }: GalleryItemProps) => {
    const img = useAttachmentFile(file)
    if (!img || !img.url) {
        return null
    }

    return (
        <RawGalleryItem
            src={img.url}
            {...props}
        />
    )
}
export const RawGalleryItem = ({ src, id, ...rest }: RawGalleryItemProps) => {
    const imgRef = useRef<HTMLImageElement>(null)
    const [, rerender] = useState(false)

    return (
        <ItemWrapper {...rest}>
            <Item
                original={src}
                width={imgRef.current ? imgRef.current.naturalWidth : 0}
                height={imgRef.current ? imgRef.current.naturalHeight : 0}
            >
                {({ ref, open }) => (
                    <StyledElement
                        id={id}
                        ref={ref as React.MutableRefObject<HTMLImageElement>}
                        sx={{
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        <SwipeImg
                            ref={imgRef}
                            onLoad={() => {
                                rerender((p) => !p)
                            }}
                            onClick={open}
                            src={src}
                        />
                    </StyledElement>
                )}
            </Item>
        </ItemWrapper>
    )
}
export default GalleryItem

import { Columns, Typography } from 'components'
import { useUtilityDrawerContext } from 'components/Drawer/UtilityDrawer'

import SuccessImage from './SuccessImage'
import { IntegrationDrawerToglerExtraProps } from './useIntegrationDrawerTogler'

const IntegrationSuccessScreen = () => {
    const { extra }: { extra: IntegrationDrawerToglerExtraProps } = useUtilityDrawerContext()

    return (
        <Columns
            gap="15px"
            height="100%"
            justifyContent="center"
            alignItems="center"
        >
            <SuccessImage image={extra.provider.logo} />
            <Typography
                variant="h5"
                textAlign="center"
            >
                Connection Completed Successfully
            </Typography>
        </Columns>
    )
}

export default IntegrationSuccessScreen

import { observer } from 'mobx-react'

import { useCompanyTaskSync } from 'components/Sync'
import { hasIntegrationActiveTasks } from 'components/telematics/hooks/useTelematics'

const ShouldSync = observer(({ provider }: { provider: string }) => {
    useCompanyTaskSync((tasks) => {
        return hasIntegrationActiveTasks(provider, tasks)
    })

    return null
})
export default ShouldSync

import React from 'react'

import { useShowContext } from 'react-admin'

import { FlexReverse, PageContent } from 'components'

import { CrossReferenceList, PartCrossReference, PartDetails } from './components'

const PartOverview = () => {
    const { record } = useShowContext()
    if (!record) {
        return null
    }
    return (
        <PageContent>
            <FlexReverse>
                <PartDetails />
                <CrossReferenceList record={record}>
                    <PartCrossReference />
                </CrossReferenceList>
            </FlexReverse>
        </PageContent>
    )
}

export default PartOverview

import { TextInput as RATextInput, TextInputProps as RATextInputProps } from 'react-admin'

import { AllProps } from 'appTypes'
import { useInputState } from 'components/context/InputStateContext'
import { useCreateInputId } from 'hooks'

export interface TextInputProps<FormType extends AllProps = AllProps>
    extends Omit<RATextInputProps, 'source'> {
    source: Extract<keyof FormType, string>
}

const TextInput = <FormType extends AllProps = AllProps>(props: TextInputProps<FormType>) => {
    const createId = useCreateInputId()
    const state = useInputState(props.source, { disabled: props.disabled })
    return (
        <RATextInput
            variant="outlined"
            size="medium"
            {...props}
            {...state}
            id={createId({ source: props.source, id: props.id })}
        />
    )
}

export default TextInput

TextInput.defaultProps = {
    variant: 'outlined',
    size: 'medium',
}

import { ReactElement, FC } from 'react'

import get from 'lodash/get'
import { useRecordContext } from 'react-admin'
import { useWatch } from 'react-hook-form'

import { UnitPMReminderModel } from 'appTypes/models'
import { TextInput, Typography, useArrayControllerElementContext } from 'components'
import { useUtilityDrawerContext } from 'components/Drawer/UtilityDrawer'
import UnderInputTooltip from 'components/UnderInputTooltip'
import { requiredValidation, inputIntegerNonNegativeSpacedMask } from 'utils'

import LastDoneHelperTextBase from './LastDoneHelperTextBase'
import TriggerThresholdValidation from './TriggerValidation'
import UnitPMMeterLine from './UnitPMMeterLine'
import UnitPMMeterValueInput from './UnitPMMeterValueInput'
import UnitPMFMeterCalcThreshold from './UnitPmMeterCalcThreshold'
import useInputSource from './useInputSource'
import { UnitPMDrawerEditorExtraState } from './useUnitPMDrawerEditor'

interface UnitPMMeterBaseProps {
    endAdornment?: ReactElement | string
}
const UnitPMMeterBase: FC<UnitPMMeterBaseProps> = ({ endAdornment }) => {
    const { extra } = useUtilityDrawerContext()
    const { isArchived } = extra as UnitPMDrawerEditorExtraState
    const source = useInputSource()
    const { index } = useArrayControllerElementContext()
    const { intervals } = useRecordContext<UnitPMReminderModel>() || {}

    const lastDoneDisabled = intervals?.length > index

    return (
        <>
            <UnitPMMeterValueInput InputProps={{ endAdornment }} />

            <UnderInputTooltip
                title={
                    lastDoneDisabled && !isArchived
                        ? 'Use interval Reset action to edit this value'
                        : ''
                }
            >
                <div>
                    <TextInput
                        disabled={lastDoneDisabled || isArchived}
                        helperText={<LastDoneHelperText />}
                        source={source('lastDoneMeterValue')}
                        label="Reading at Last Done Date"
                        validate={requiredValidation}
                        {...inputIntegerNonNegativeSpacedMask({
                            InputProps: {
                                endAdornment,
                            },
                        })}
                    />
                </div>
            </UnderInputTooltip>

            <TextInput
                disabled={isArchived}
                source={source('threshold')}
                {...inputIntegerNonNegativeSpacedMask({
                    InputProps: {
                        endAdornment: (
                            <Typography
                                color="inherit"
                                whiteSpace="nowrap"
                            >
                                {endAdornment + ' left'}
                            </Typography>
                        ),
                    },
                })}
                validate={(thresholdValue, data) => {
                    const value = Number(get(data, source('value'))) || 0

                    if (value < thresholdValue) {
                        return 'The Threshold cannot be greater than the Meter interval'
                    }
                }}
                label="Change to “Due Soon” when"
            />
            <UnitPMMeterLine />
            <TriggerThresholdValidation />
            <UnitPMFMeterCalcThreshold />
        </>
    )
}

export default UnitPMMeterBase

const LastDoneHelperText = () => {
    const source = useInputSource()
    const [value, lastDone, type] = useWatch({
        name: [source('value'), source('lastDoneMeterValue'), source('type')],
    })

    return (
        <LastDoneHelperTextBase
            lastDone={lastDone}
            meterType={type}
            value={value}
        />
    )
}

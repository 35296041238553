import { FC } from 'react'

import { UnitPMIntervalIntersection } from 'appTypes/models'
import { CombinedInputs } from 'components'

import useInputSource from './useInputSource'

interface TriggerThresholdValidationProps {
    extraSource?: (keyof UnitPMIntervalIntersection)[]
    triggerExtraSource?: (keyof UnitPMIntervalIntersection)[]
}

const TriggerThresholdValidation: FC<TriggerThresholdValidationProps> = ({
    extraSource,
    triggerExtraSource,
}) => {
    const source = useInputSource()

    return (
        <CombinedInputs
            track={[source('value'), ...(extraSource || []).map((s) => source(s))]}
            sources={[source('threshold'), ...(triggerExtraSource || []).map((s) => source(s))]}
        />
    )
}

export default TriggerThresholdValidation

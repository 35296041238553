import { Box, styled, BoxProps } from '@mui/material'

import { generateNotForwardedProps } from 'utils'

type variants = 'sm' | 'md' | 'lg'

const spacerVariants: { [key in variants]: string } = {
    sm: '8px',
    md: '12px',
    lg: '24px',
}
export interface SpacerProps extends BoxProps {
    variant?: variants
}

const Spacer = styled(Box, {
    shouldForwardProp: generateNotForwardedProps<SpacerProps>(['variant']),
})<SpacerProps>`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: ${({ variant }) => spacerVariants[variant]};
`

Spacer.defaultProps = {
    variant: 'sm',
}

export default Spacer

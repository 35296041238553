import { useContext, useRef, createContext, ReactNode } from 'react'

// eslint-disable-next-line camelcase
import { Action as NavigateAction, History } from 'history'
import { UNSAFE_NavigationContext as UNSAFENavigationContext } from 'react-router-dom'

import { makeid } from 'utils'

type V = (params: BlockerType) => () => void
export interface BlockerType {
    // eslint-disable-next-line react/no-unused-prop-types
    close?: () => void
    // eslint-disable-next-line react/no-unused-prop-types
    preventNavigate?: (
        action: NavigateAction,
        params: {
            resume: () => void
        },
    ) => void
    allowSamePageNavigate?: boolean
}

interface BlockersOptions {
    unloadListener: () => void
    unblock: () => void
}

interface BlockerNonBlockedLocationState {
    forceRedirect: boolean
}
export interface BlockersProviderProps {
    children: ReactNode
}
export const locationStateBlockerForceRedirect: BlockerNonBlockedLocationState = {
    forceRedirect: true,
}

export const blockerForceRedirectState: BlockerNonBlockedLocationState = {
    forceRedirect: true,
}

export const BlockersContext = createContext<V>(null as any)

const BlockersProvider = ({ children }: BlockersProviderProps) => {
    const blockers = useRef<{ blocker: BlockerType; key: string }[]>([])
    const navigator = useContext(UNSAFENavigationContext).navigator as History
    const options = useRef<BlockersOptions>({
        unloadListener: () => {
            /* */
        },
        unblock: () => {
            /* */
        },
    })

    const add: V = (p) => {
        const key = makeid()
        blockers.current.push({ blocker: p, key })
        if (blockers.current.length === 1) {
            options.current.unloadListener = () => {
                if (blockers.current.filter(({ blocker }) => blocker.preventNavigate).length) {
                    return
                }
                options.current.unblock()
            }

            window.addEventListener('beforeunload', options.current.unloadListener)

            const block = () => {
                return navigator.block(({ action, location, retry }) => {
                    if (
                        (location.state as BlockerNonBlockedLocationState)?.forceRedirect &&
                        action !== 'POP'
                    ) {
                        blockers.current.forEach(({ blocker }) => {
                            blocker.close?.()
                        })
                        options.current.unblock()
                        retry()
                        return
                    }
                    const lastBlocker = blockers.current.at(-1)!.blocker

                    if (
                        lastBlocker.allowSamePageNavigate &&
                        location.pathname === window.location.pathname
                    ) {
                        options.current.unblock()
                        retry()
                        setTimeout(() => {
                            options.current.unblock = block()
                        }, 100)
                        return
                    }
                    if (lastBlocker.preventNavigate) {
                        lastBlocker.preventNavigate(action, {
                            resume: () => {
                                options.current.unblock()
                                retry()
                            },
                        })
                        return
                    }
                    if (action === 'POP') {
                        lastBlocker.close?.()
                    }
                })
            }

            options.current.unblock = block()
        }

        return () => {
            blockers.current = blockers.current.filter((blocker) => blocker.key !== key)
            if (!blockers.current.length) {
                options.current.unblock()
                window.removeEventListener('beforeunload', options.current.unloadListener)
            }
        }
    }

    return <BlockersContext.Provider value={add}>{children}</BlockersContext.Provider>
}
export default BlockersProvider

import { FC } from 'react'

import { Box } from '@mui/material'
import { Validator } from 'react-admin'
import { useParams } from 'react-router-dom'

import { CombinedInputs, Form, Typography, PasswordInput, AnchorLink } from 'components'
import { urls } from 'configs/config'
import { useAuthProvider, useSubmit } from 'hooks'
import { passwordValidation, requiredValidation, validatePasswordMatch } from 'utils'

import { AuthBody, AuthSubmitButton } from '../components'

const sources = {
    newPassword: 'newPassword',
    confirmPassword: 'confirmPassword',
}

const passwordMatchValidation: Validator[] = [
    requiredValidation,
    validatePasswordMatch(sources.newPassword),
]

const ResetPasswordConfirm: FC = () => {
    const authProvider = useAuthProvider()
    const { uid, token } = useParams()

    const handleSubmit = useSubmit(
        async (formData) => {
            await authProvider.resetPasswordConfirm(formData[sources.newPassword], uid, token)
        },
        {
            successMessage: 'Password has been changed successfully!',
        },
    )

    return (
        <AuthBody>
            <Typography
                variant="h6"
                mb="20px"
            >
                Reset Password
            </Typography>
            <Typography
                variant="body2"
                mb="24px"
            >
                To recover access, enter your new password.
            </Typography>
            <Form
                onSubmit={handleSubmit}
                warnWhenUnsavedChanges={false}
            >
                <CombinedInputs
                    sources={[sources.confirmPassword]}
                    track={[sources.newPassword]}
                />
                <PasswordInput
                    source={sources.newPassword}
                    label="New Password"
                    helperText="Use at least 8 characters"
                    validate={passwordValidation}
                />
                <PasswordInput
                    source={sources.confirmPassword}
                    label="Confirm New Password"
                    validate={passwordMatchValidation}
                />

                <AuthSubmitButton label="Set Password and Login" />
            </Form>

            <Box
                mt="23px"
                display="flex"
                justifyContent="center"
            >
                <Typography display="inline">Don't have an account?&nbsp;</Typography>

                <AnchorLink to={urls.register}>Sign up!</AnchorLink>
            </Box>
        </AuthBody>
    )
}

export default ResetPasswordConfirm

import { PropsWithChildren } from 'react'

import { Check } from '@mui/icons-material'
import { Alert, SvgIcon } from '@mui/material'

import TelematicsDataModel from 'appTypes/models/TelematicsDataModel'
import { BoxContainer, Columns, Spacer, Typography } from 'components'
import { ActionsMenu } from 'components/actions'
import { telematicsExtras } from 'components/telematics/hooks/useTelematics'
import ConnectedUnitsStatus from 'pages/Configure/Integrations/components/ConnectedUnitsStatus'
import { formatDate } from 'utils'

const CheckedItem = ({ children }: PropsWithChildren) => {
    return (
        <BoxContainer gap="16px">
            <SvgIcon
                sx={{
                    width: '20px',
                    height: '20px',
                    color: (theme) => theme.palette.charts.greenBody,
                }}
                component={Check}
            />
            <Typography variant="menuItem">{children}</Typography>
        </BoxContainer>
    )
}

const UnitIntegrationsStatusPopper = ({
    telematicData,
}: {
    telematicData: TelematicsDataModel
}) => {
    const errorContent = <Alert severity="error">{telematicData.error}</Alert>
    const connectedContent = (
        <>
            {telematicData.syncMeters && <CheckedItem>Meters</CheckedItem>}
            {telematicData.syncDvirs && <CheckedItem>DVIR</CheckedItem>}
        </>
    )
    return (
        <ActionsMenu
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            renderToggler={(open) => (
                <ConnectedUnitsStatus
                    type={telematicData.error ? 'error' : 'sync'}
                    onClick={open}
                />
            )}
            actions={() => [
                <Columns
                    key="content"
                    maxWidth="300px"
                    p="4px 12px"
                    gap="12px"
                >
                    <Spacer>
                        <SvgIcon
                            inheritViewBox
                            sx={{
                                width: '20px',
                                height: '20px',
                                maxWidth: '100%',
                            }}
                            component={telematicsExtras[telematicData.provider].logo}
                        />
                        <Typography
                            variant="subtitle1"
                            textTransform="capitalize"
                        >
                            {telematicData.provider}
                        </Typography>
                    </Spacer>
                    <BoxContainer>
                        <Typography
                            color="text.disabled"
                            variant="body1"
                        >
                            Last Synced
                        </Typography>
                        &nbsp;
                        <Typography
                            textAlign="right"
                            color="text.primary"
                            variant="body1"
                        >
                            {formatDate(
                                new Date(telematicData.lastSyncedMeters),
                                (dateFormats) => dateFormats.shortenedDateTime,
                            )}
                        </Typography>
                    </BoxContainer>
                    {telematicData.error ? errorContent : connectedContent}
                </Columns>,
            ]}
        />
    )
}

export default UnitIntegrationsStatusPopper

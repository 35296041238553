import {
    CloseOutlined,
    Delete,
    Rotate90DegreesCcwOutlined,
    ZoomInOutlined,
} from '@mui/icons-material'
import { Button, styled } from '@mui/material'
import PhotoSwipeLightboxStub from 'react-photoswipe-gallery/dist/lightbox-stub'

import { BoxContainer } from 'components/styled'
import { useConfirm } from 'hooks'
export interface GalleryToolbarProps {
    galleryContext: PhotoSwipeLightboxStub['pswp']
    onImageDelete?: (galleryCtx: PhotoSwipeLightboxStub['pswp']) => void
}
export const GalleryToolbarWrapper = styled(BoxContainer)`
    gap: 16px;
    margin: 20px 20px 0px 0px;
`
export const GalleryDefaultToolbar = ({ galleryContext, onImageDelete }: GalleryToolbarProps) => {
    const confirm = useConfirm()
    return (
        <GalleryToolbarWrapper>
            {onImageDelete ? (
                <Button
                    variant="contained"
                    color="error"
                    startIcon={<Delete />}
                    sx={(theme) => ({
                        [theme.breakpoints.down('sm')]: {
                            left: '20px',
                            top: '20px',
                            position: 'absolute',
                        },
                    })}
                    onClick={() => {
                        confirm({
                            disableEnforceFocus: true,
                            title: 'Are you sure you want to delete this photo?',
                            confirmButtonProps: {
                                startIcon: <Delete />,
                                variant: 'contained',
                                color: 'error',
                                children: 'Delete',
                            },
                            onConfirm: async () => {
                                onImageDelete(galleryContext)
                                galleryContext.close()
                            },
                        })
                    }}
                >
                    Delete
                </Button>
            ) : null}
            <Button
                variant="contained"
                startIcon={<Rotate90DegreesCcwOutlined />}
                onClick={(e) => {
                    if (!galleryContext.currSlide?.content.element) {
                        return
                    }

                    const item = galleryContext.currSlide.content.element

                    const prevRotateAngle = Number(item.dataset.rotateAngel) || 0
                    const rotateAngle = prevRotateAngle === 270 ? 0 : prevRotateAngle + 90

                    item.style.transform = `${item.style.transform.replace(
                        `rotate(-${prevRotateAngle}deg)`,
                        '',
                    )} rotate(-${rotateAngle}deg)`
                    item.dataset.rotateAngel = String(rotateAngle)
                }}
                sx={(theme) => ({
                    [theme.breakpoints.down('sm')]: {
                        position: 'absolute',
                        bottom: '80px',
                        right: '50%',
                        transform: 'translate(50%, 0%)',
                    },
                })}
            >
                Rotate
            </Button>
            <Button
                variant="contained"
                startIcon={<ZoomInOutlined />}
                sx={(theme) => ({
                    [theme.breakpoints.down('sm')]: {
                        position: 'absolute',
                        bottom: '20px',
                        right: '50%',
                        transform: 'translate(50%, 0%)',
                    },
                })}
                onClick={(e) => {
                    galleryContext.toggleZoom()
                }}
            >
                Zoom
            </Button>
            <Button
                variant="contained"
                startIcon={<CloseOutlined />}
                onClick={() => {
                    galleryContext.close()
                }}
            >
                Close
            </Button>
        </GalleryToolbarWrapper>
    )
}
export default GalleryDefaultToolbar

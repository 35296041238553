import { useOpenUtilityDrawer } from 'components'

import IntegrationConnectForm from './IntegrationConnectForm'
import InteractionDrawerWrapper from './InteractionDrawerWrapper'
import { ItemPropTypes } from './Item'
export interface IntegrationDrawerToglerExtraProps {
    provider: ItemPropTypes['item']
    refetch?: () => void
}
const useIntegrationDrawerTogler = () => {
    const open = useOpenUtilityDrawer()

    return (provider: ItemPropTypes['item'], refetch?: () => void) => {
        return open({
            drawerArgs: {
                title: provider.isActive ? 'Configure' : 'Connect Integration',
                renderWrapper: (props) => <InteractionDrawerWrapper {...props} />,
                renderContent: () => <IntegrationConnectForm />,
                renderBottomRight: (render) =>
                    render({
                        icon: provider.isActive ? undefined : null,
                        label: provider.isActive ? undefined : 'Connect',
                    }),
            },
            extra: {
                provider,
                refetch,
            } as IntegrationDrawerToglerExtraProps,
        })
    }
}

export default useIntegrationDrawerTogler

import { ChevronRight } from '@mui/icons-material'
import { buttonClasses } from '@mui/material'

import UtilityDrawerSaveButton from 'components/Drawer/UtilityDrawerSaveButton'

import { maxSteps, useStepsContext } from './UseWOStepsContext'

const SubmitButton = UtilityDrawerSaveButton

const UseWONextButton = () => {
    const { step } = useStepsContext()

    if (step === maxSteps) {
        return <SubmitButton formType="create" />
    }

    return (
        <SubmitButton
            label="Next"
            endIcon={<ChevronRight />}
            icon={null}
            sx={{
                [`& .${buttonClasses.endIcon} svg`]: {
                    marginRight: 0,
                },
            }}
        />
    )
}

export default UseWONextButton

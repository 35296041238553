import Icons from 'components/icons'
import { Button } from 'components/mui'

import { useQRScannerModalContext } from './QRScanner'

const QRScannerCloseButton = () => {
    const { close } = useQRScannerModalContext()

    return (
        <Button
            variant="contained"
            sx={{ position: 'absolute', top: '20px', right: '20px' }}
            onClick={close}
            startIcon={<Icons.Close />}
        >
            Close
        </Button>
    )
}

export default QRScannerCloseButton

import { AllKeys } from 'appTypes'
import { UnitPMReminderModel } from 'appTypes/models'
import { useArrayControllerElementContext } from 'components'

export type IntervalSource = AllKeys<UnitPMReminderModel['intervals'][0]> | 'thresholdError'

export const meterTypeItemSource = (index: number) => `intervals.${index}`

export const meterSource = (index: number, source: IntervalSource) =>
    `${meterTypeItemSource(index)}.${source as string}`

const useInputSource = () => {
    const { index } = useArrayControllerElementContext()

    return (source: IntervalSource) => meterSource(index, source)
}

export default useInputSource

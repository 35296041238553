import { forwardRef, useState } from 'react'

import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined'
import { Box, Divider, Paper } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch, { switchClasses } from '@mui/material/Switch'
import { styled } from '@mui/material/styles'
import { GridColDef } from '@mui/x-data-grid'

import { useListInternalContext } from 'components/list/ListInternalContextProvider'
import Button from 'components/mui/Button'
import Typography from 'components/mui/Typography'
import { Columns, EllipsisOverflow } from 'components/styled'

import { Column, DatagridColumnsProps } from './Datagrid'

const PanelWrapper = styled(Paper)`
    display: flex;
    max-height: 450px;
    max-width: 300px;
`
const PanelContent = styled(Columns)`
    overflow: auto;
    flex: 1 1 0%;
    max-height: 400px;
`
const GridColumnsPanelRoot = styled('div', {
    name: 'MuiDataGrid',
    slot: 'ColumnsPanel',
    overridesResolver: (props, styles) => styles.columnsPanel,
})(() => ({
    padding: '8px 16px',
}))
const GridColumnsPanelRowRoot = styled('div', {
    name: 'MuiDataGrid',
    slot: 'ColumnsPanelRow',
    overridesResolver: (props, styles) => styles.columnsPanelRow,
})(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1px 8px 1px 7px',
    [`& .${switchClasses.root}`]: {
        marginRight: theme.spacing(0.5),
    },
}))

interface GridColumnsPanelProps extends Pick<DatagridColumnsProps, 'resetColumns'> {}

const GridColumnsPanel = forwardRef<HTMLDivElement, GridColumnsPanelProps>(
    ({ resetColumns, ...rest }, ref) => {
        const { gridApi: apiRef } = useListInternalContext()

        const [, rerender] = useState(false)
        const toggleColumn = (event) => {
            const name = event.target.name
            const columnVisibilityModel = apiRef.current.state.columns.columnVisibilityModel

            apiRef.current.setColumnVisibility(name, columnVisibilityModel[name] === false)
            rerender((p) => !p)
        }

        const getHiddenColumn = (col: GridColDef, isVisible: boolean) => {
            if (col.hideable !== false) {
                return {
                    field: col.field,
                    hide: !isVisible,
                }
            }

            return col
        }

        const toggleAllColumns = (isVisible: boolean) => {
            const columns = apiRef.current.getAllColumns()

            apiRef.current.updateColumns(columns.map((col) => getHiddenColumn(col, isVisible)))
            rerender((p) => !p)
        }

        const reset = () => {
            if (typeof resetColumns === 'undefined') {
                apiRef.current.setColumnVisibilityModel({})
                rerender((p) => !p)
                return
            }

            const columns = apiRef.current.getAllColumns()

            apiRef.current.updateColumns(
                columns.map((col) => getHiddenColumn(col, resetColumns[col.field] !== false)),
            )
            rerender((p) => !p)
        }
        if (!apiRef.current) {
            return null
        }
        return (
            <PanelWrapper
                {...rest}
                ref={ref}
            >
                <Columns
                    flex="1 1 0%"
                    overflow="hidden"
                >
                    <Box
                        sx={{
                            p: '14px 12px 0',
                        }}
                    >
                        <Typography
                            variant="subtitle1"
                            color="text.secondary"
                            mb="12px"
                        >
                            Current Arrangement
                        </Typography>
                        <Divider />
                    </Box>
                    <PanelContent>
                        <GridColumnsPanelRoot>
                            {apiRef.current.getAllColumns().map((column: Column) =>
                                column.hidden || column.field === 'actions' ? null : (
                                    <GridColumnsPanelRowRoot key={column.field}>
                                        <FormControlLabel
                                            sx={{
                                                overflow: 'hidden',
                                                marginRight: 0,
                                            }}
                                            componentsProps={{
                                                typography: {
                                                    overflow: 'hidden',
                                                },
                                            }}
                                            label={
                                                <EllipsisOverflow>
                                                    {column.headerName || column.field}
                                                </EllipsisOverflow>
                                            }
                                            control={
                                                <Switch
                                                    disabled={column.hideable === false}
                                                    checked={
                                                        apiRef.current.state.columns
                                                            .columnVisibilityModel[column.field] !==
                                                        false
                                                    }
                                                    onChange={toggleColumn}
                                                    name={column.field}
                                                    size="small"
                                                />
                                            }
                                        />
                                    </GridColumnsPanelRowRoot>
                                ),
                            )}
                        </GridColumnsPanelRoot>
                    </PanelContent>
                    <Box
                        sx={{
                            p: '6px 16px 18px',
                            justifyContent: 'flex-start',
                        }}
                    >
                        <Button
                            size="small"
                            onClick={() => toggleAllColumns(true)}
                        >
                            Show all
                        </Button>

                        <Button
                            sx={{ ml: '8px' }}
                            size="small"
                            onClick={() => toggleAllColumns(false)}
                        >
                            Hide all
                        </Button>

                        <Button
                            sx={{ ml: 'auto' }}
                            size="small"
                            startIcon={<RefreshOutlinedIcon />}
                            onClick={reset}
                        >
                            Reset
                        </Button>
                    </Box>
                </Columns>
            </PanelWrapper>
        )
    },
)

export default GridColumnsPanel

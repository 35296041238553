import NoReference from 'assets/images/no-cross-reference.svg'
import { NoResultsCard, Typography } from 'components'
import { AddReferenceButton } from 'pages/Parts/CrossReference/CrossReference'

const NoResultCard = ({ path }: { path: string }) => {
    return (
        <NoResultsCard
            direction="row"
            height="240px"
            title="No Cross-reference"
            imageSrc={NoReference}
            imageProps={{
                width: '80px',
                height: '70px',
                mr: '32px',
                ml: '6px',
            }}
            subtitle={
                <AddReferenceButton
                    path={path}
                    renderElement={(onClick) => (
                        <Typography
                            onClick={onClick}
                            variant="body1"
                            sx={{
                                cursor: 'pointer',
                            }}
                            color="primary.main"
                        >
                            Add Cross-reference
                        </Typography>
                    )}
                />
            }
        />
    )
}
export default NoResultCard

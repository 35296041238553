import { ReferenceInput } from 'react-admin'

import { CK15Model, CauseModel, JobLaborModel } from 'appTypes/models'
import {
    CK33SelectorWithoutParts,
    AutocompleteInput,
    TextInput,
    SectionTitleInDrawer,
    TextareaInput,
} from 'components'
import { useUtilityDrawerContext } from 'components/Drawer/UtilityDrawer'
import { GridItem } from 'components/pages'
import { GridContainerColumns } from 'components/pages/GridContainer'
import { maxLengthValidationText, requiredValidation } from 'utils'
import { inputPriceMaskParams, costMaskParams } from 'utils/masks'

import { WorkOrderItemsExtra } from '../config/constants'

import JobLaborPartFormTotal from './JobLaborPartFormTotal'
const descriptionValidation = [maxLengthValidationText, requiredValidation]

const JobLaborForm = () => {
    const { extra } = useUtilityDrawerContext()
    const { disabledFields: disabled } = (extra || {}) as WorkOrderItemsExtra

    return (
        <>
            <SectionTitleInDrawer>Basic Details</SectionTitleInDrawer>
            <CK33SelectorWithoutParts
                disabled={disabled}
                required
            />
            <TextareaInput<JobLaborModel>
                source="description"
                label="Description"
                disabled={disabled}
                validate={descriptionValidation}
            />
            <ReferenceInput
                source="cause"
                reference="vmrs/ck18"
                perPage={disabled ? 1 : 100}
            >
                <AutocompleteInput
                    optionText={(record: CauseModel) =>
                        record.id && `${record.code} ${record.description}`
                    }
                    label="Cause"
                    disabled={disabled}
                />
            </ReferenceInput>
            <ReferenceInput
                source="correction"
                reference="vmrs/ck15"
                perPage={disabled ? 1 : 70}
            >
                <AutocompleteInput
                    optionText={(record: CK15Model) =>
                        record.id && `${record.code} ${record.description}`
                    }
                    label="Correction"
                    disabled={disabled}
                />
            </ReferenceInput>
            <GridContainerColumns>
                <GridItem xs={6}>
                    <TextInput
                        source="hours"
                        label="Qty/Hours"
                        {...inputPriceMaskParams}
                        validate={requiredValidation}
                        disabled={disabled}
                    />
                </GridItem>
                <GridItem xs={6}>
                    <TextInput
                        source="hourlyRate"
                        label="Rate"
                        {...costMaskParams}
                        disabled={disabled}
                        validate={requiredValidation}
                    />
                </GridItem>
                <JobLaborPartFormTotal
                    title="Labor Total"
                    inputOne="hours"
                    inputTwo="hourlyRate"
                />
            </GridContainerColumns>
        </>
    )
}

export default JobLaborForm

import { styled } from '@mui/material/styles'

import Typography from 'components/mui/Typography'

const PageTitle = styled(Typography)`
    display: flex;
    align-items: center;
    margin-bottom: 0;

    ${({ theme }) => `
        ${theme.breakpoints.up('sm')} {
            margin-bottom: 4px;
        }
    `}
`

PageTitle.defaultProps = {
    variant: 'h5',
    color: 'text.primary',
}

export default PageTitle

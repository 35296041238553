import { useFlags } from 'launchdarkly-react-client-sdk'
import { Routes } from 'react-router-dom'

import { tabRoutes } from 'components'
import { Show } from 'components/resource'

import CrossReference from '../CrossReference'
import { PartNotes } from '../Notes'
import { PartOverview } from '../Overview'
import PartUsage from '../PartUsage'
import { PartPhotos } from '../Photos'
import { PurchaseHistory } from '../PurchaseHistory'

import { PartShowHeader } from './components'

const PartShow = () => {
    const { flag3976PartPurchaseHistory } = useFlags()
    const { tabs, routes } = tabRoutes([
        {
            value: '',
            label: 'Overview',
            element: <PartOverview />,
        },
        {
            value: 'usage',
            label: 'Part Usage',
            element: <PartUsage />,
        },
        !flag3976PartPurchaseHistory && {
            value: 'purchase-history',
            label: 'Purchase History',
            element: <PurchaseHistory />,
        },
        {
            value: 'cross-reference',
            label: 'Cross-Reference',
            element: <CrossReference />,
        },
        {
            value: 'notes',
            label: 'Notes',
            element: <PartNotes />,
        },
        {
            value: 'photos',
            label: 'Photos',
            element: <PartPhotos />,
        },
    ])

    return (
        <Show>
            <>
                <PartShowHeader tabs={tabs} />
                <Routes>{routes}</Routes>
            </>
        </Show>
    )
}

export default PartShow

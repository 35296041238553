import { Box } from '@mui/material'

import noInvoicesIcon from 'assets/images/no-invoices.svg'
import { NoResultsCard, Typography } from 'components'
import { useWoActionIsDisabled } from 'pages/WorkOrders/hooks'

import { WorkOrderInvoiceOpenDrawerButton } from '../../WorkOrderInvoice'

const InvoicesSectionEmpty = () => {
    const disabled = useWoActionIsDisabled()

    return (
        <NoResultsCard
            title="No Items"
            direction="row"
            height="206px"
            heightsm="280px"
            directionsm="column"
            imageSrc={noInvoicesIcon}
            imageProps={{
                alt: 'No Items',
                width: {
                    xs: '122px',
                    sm: '107px',
                },
                mr: {
                    xs: 0,
                    sm: '16px',
                },
            }}
            action={
                <WorkOrderInvoiceOpenDrawerButton mode="create">
                    {({ onClick }) =>
                        disabled ? null : (
                            <Typography
                                component={Box}
                                sx={{
                                    cursor: 'pointer',
                                }}
                                onClick={onClick}
                                color="primary.main"
                                variant="body1"
                            >
                                Add Invoice
                            </Typography>
                        )
                    }
                </WorkOrderInvoiceOpenDrawerButton>
            }
        />
    )
}

export default InvoicesSectionEmpty

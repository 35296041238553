import {
    CardHeader,
    cardHeaderClasses,
    CardHeaderProps,
    Divider,
    styled,
    DividerProps,
} from '@mui/material'

import { ActionsMenu, MenuActionProps } from 'components/actions'

import infoCardStyles from './styles'

export interface InfoCardHeaderProps
    extends CardHeaderProps,
        Partial<Omit<MenuActionProps, 'title' | 'resource'>> {
    noDivider?: boolean
    actionsDisabled?: boolean
    dividerProps?: DividerProps
}

const InfoCardHeader = styled(
    ({
        action,
        actions,
        record,
        noDivider,
        actionsDisabled,
        dividerProps,
        ...rest
    }: InfoCardHeaderProps) => {
        return (
            <>
                <CardHeader
                    action={
                        action ||
                        (actions && (
                            <ActionsMenu
                                disabled={actionsDisabled}
                                actions={actions}
                                record={record}
                            />
                        ))
                    }
                    component="div"
                    {...rest}
                />
                {noDivider ? null : <InfoCardHeaderDivider {...dividerProps} />}
            </>
        )
    },
)`
    padding: 0;
    & .${cardHeaderClasses.action} {
        margin: 0;
    }
`

export default InfoCardHeader

const InfoCardHeaderDivider = styled(Divider)`
    margin-top: 10px;
    margin-bottom: var(${infoCardStyles.headerDividerOffsetBottom});
`

import { Alert } from '@mui/material'

import api from 'api'
import { WorkOrderModel } from 'appTypes/models'
import { DropdownSelector, DropdownSelectorProps, TextInput } from 'components'
import { updatedMessage } from 'configs/constants'
import { useConfirm, useResourcePermissions, useNotify, useFinalErrorHandler } from 'hooks'
import {
    dateTimeParse,
    hasPermission,
    maxLengthValidationText,
    parseNumber,
    pathJoin,
    requiredValidation,
} from 'utils'

import { useIsVenderWo } from '../Overview/WorkOrderOverview'
import { woResource, workOrderStatusesArray } from '../config/constants'

import { WorkOrderCloseDialogUnsuccessful, WorkOrderStatusCloseContent } from './WoClose'
import { pmMeterSources } from './WoClose/PmMeterInput'

export interface WorkOrderStatusSelectorProps
    extends Omit<
        DropdownSelectorProps,
        'choices' | 'readOnly' | 'isChoiceDissabled' | 'value' | 'onChange'
    > {
    workOrder: WorkOrderModel
    onComplete?: (params: { action: actionType }) => Promise<void>
}

type actionType = 'cancel' | 'reopen' | 'close'
const descriptionValidation = [maxLengthValidationText, requiredValidation]

const WorkOrderStatusSelector = ({
    workOrder,
    onComplete,
    ...rest
}: WorkOrderStatusSelectorProps) => {
    const permissions = useResourcePermissions(woResource)
    const filterChoice = (status: typeof workOrderStatusesArray[0]) =>
        hasPermission(permissions[status.permission]) && status.changeable(workOrder.status)
    const choices = workOrderStatusesArray.filter(filterChoice)
    const hasChoices =
        choices.length === 1
            ? choices[0].id === workOrder.status
                ? false
                : true
            : Boolean(choices.length)
    const confirm = useConfirm()
    const notify = useNotify()
    const isVendorWorkOrder = useIsVenderWo(workOrder)
    const errorHandling = useFinalErrorHandler()

    const selectStatus = async (event) => {
        const status = event.target.value as WorkOrderModel['status']

        if (status === workOrder.status) {
            return
        }

        const request = async (path: actionType, data?: any) => {
            await api.post(pathJoin(woResource.resource, workOrder.id, path), data)
            await onComplete?.({
                action: path,
            })
            notify(updatedMessage, { type: 'info' })
        }

        if (status === 'CANCELED') {
            confirm({
                title: 'Are you sure you want to cancel this work order?',
                closeOnError: false,
                content: (
                    <>
                        <Alert
                            severity="warning"
                            sx={{ mb: '22px' }}
                        >
                            Cancelling a work order is an irreversible action and will delete its
                            jobs and items.
                        </Alert>
                        <TextInput
                            source="reason"
                            validate={descriptionValidation}
                            label="Reason for Cancellation"
                            multiline
                            rows={3}
                        />
                    </>
                ),
                onConfirm: async ({ formValues }) => {
                    await request('cancel', formValues)
                },
                confirmButtonProps: {
                    children: 'Confirm',
                },
                awaitOnConfirm: true,
            })
        } else if (status === 'CLOSED') {
            const isVendorWOClosable =
                Math.round(workOrder.purchaseOrderData?.total) ===
                Math.round(workOrder.purchaseOrderData?.totalInvoices)

            if (!isVendorWOClosable && isVendorWorkOrder) {
                confirm({
                    title: `Vendor Work Order can't be closed.`,
                    content: <WorkOrderCloseDialogUnsuccessful data={workOrder} />,
                    confirmButtonProps: null,
                    cancelButtonProps: {
                        children: 'Close',
                    },
                })
            } else {
                confirm({
                    title: 'Close Work Order',
                    closeOnError: false,
                    content: <WorkOrderStatusCloseContent workOrder={workOrder} />,
                    onConfirm: async ({ formValues }) => {
                        const { completed, pm, dvir } = formValues

                        const body: Record<string, any> = {
                            pmsToReset: pm ? Object.keys(pm).filter((pmId) => pm[pmId]) : [],
                            defectsToReset: dvir
                                ? Object.keys(dvir).filter((dvirId) => dvir[dvirId])
                                : [],
                            completed: dateTimeParse(completed),
                        }

                        pmMeterSources.forEach((source) => {
                            if (formValues[source]) {
                                body[source] = parseNumber(formValues[source])
                            }
                        })

                        await request('close', body)
                    },
                    confirmButtonProps: {
                        children: 'Confirm',
                    },
                    awaitOnConfirm: true,
                    formProps: {
                        defaultValues: {
                            completed: new Date(),
                        },
                    },
                })
            }
        } else {
            try {
                await request('reopen')
            } catch (errors) {
                errorHandling(errors)
            }
        }
    }

    return (
        <DropdownSelector
            {...rest}
            choices={workOrderStatusesArray}
            readOnly={!hasChoices}
            isChoiceDissabled={(choice) => !filterChoice(choice) && choice.id !== workOrder.status}
            value={workOrder.status}
            onChange={selectStatus}
        />
    )
}

export default WorkOrderStatusSelector

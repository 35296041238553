import { ReactElement } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import { toolbarClasses, AppBar, Toolbar, Box } from '@mui/material'
import { RaRecord } from 'react-admin'

import { ExtendRecordType } from 'appTypes'
import Typography from 'components/mui/Typography'
import { extendRecord } from 'utils'

import DialogSelectorAppBarButton from './DialogSelectorAppBarButton'

export interface DialogSelectorAppBarProps<OptionType extends RaRecord = any> {
    onClose: () => void
    selected: OptionType
    titleSource: ExtendRecordType<OptionType, string | number>
    leftButton?: ReactElement
    paddingX?: string
}

const DialogSelectorAppBar = <OptionType extends RaRecord = any>({
    onClose,
    selected,
    titleSource,
    leftButton,
    paddingX,
}: DialogSelectorAppBarProps<OptionType>) => {
    return (
        <AppBar
            position="static"
            elevation={2}
        >
            <Toolbar
                variant="dense"
                sx={{
                    [`&.${toolbarClasses.root}`]: {
                        gap: '4px',
                        px: paddingX || '20px',
                        // display: 'grid',
                        // gridTemplateColumns: '48px 1fr 48px',
                    },
                }}
            >
                <Box flex="none">{leftButton}</Box>
                <Typography
                    variant="subtitle1"
                    component="div"
                    color="white"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    flexGrow={1}
                >
                    {extendRecord<OptionType>(selected, titleSource)}
                </Typography>
                <DialogSelectorAppBarButton
                    onClick={onClose}
                    aria-label="close"
                >
                    <CloseIcon />
                </DialogSelectorAppBarButton>
            </Toolbar>
        </AppBar>
    )
}

export default DialogSelectorAppBar

import { ListControllerResult, useListContext } from 'react-admin'

import useDeleteOne, { DeleteOneParams } from './useDeleteOne'

export interface DeleteOneFromListParams extends DeleteOneParams {
    listContext?: ListControllerResult
}

const useDeleteOneFromList = () => {
    const deleteOne = useDeleteOne()
    const listContextFromContext = useListContext()

    return ({ listContext, ...params }: DeleteOneFromListParams = {}) => {
        return deleteOne({
            redirectTo: '',
            ...params,
            onSuccess: () => {
                ;(listContext || listContextFromContext).refetch()
                params.onSuccess?.()
            },
        })
    }
}

export default useDeleteOneFromList

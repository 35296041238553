import { useRecordContext } from 'react-admin'

import api from 'api'
import { useQuery } from 'hooks'
import { SliderWidget, SliderWidgetSkeleton } from 'pages/Dashboard/components/WidgetBase/styled'
import { createReportResource } from 'pages/Units/config/constants'

import { UnitPMWidgetBars, UnitPmWidgetLabel } from './components'
import { PMWidgetData } from './types'

const UnitPMWidget = () => {
    const record = useRecordContext()

    const { data } = useQuery<PMWidgetData>(['reports/pm-schedules'], async () => {
        const data: PMWidgetData = await api.get(createReportResource('pm-schedules'), {
            unit: record.id,
        })
        return data
    })

    if (!data) {
        return <SliderWidgetSkeleton />
    }
    const mutatedData = data

    return (
        <SliderWidget
            headerProps={{
                title: 'PM Intervals',
                label: <UnitPmWidgetLabel data={mutatedData} />,
                chart: <UnitPMWidgetBars data={mutatedData} />,
            }}
        />
    )
}
export default UnitPMWidget

import { Route } from 'react-router-dom'

import { ResourceWithClearEffect } from 'components'

import WorkOrderCreate from './Create'
import CreateVendorWo from './CreateVendorWo'
import WorkOrdersList from './List'
import WorkOrderShow from './Show'
import { woResource } from './config/constants'

const WorkOrders = () => {
    return (
        <ResourceWithClearEffect
            name={woResource}
            list={WorkOrdersList}
            create={WorkOrderCreate}
            edit={WorkOrderShow}
        >
            <Route
                path="/vendors/create"
                element={<CreateVendorWo />}
            />
        </ResourceWithClearEffect>
    )
}

export default WorkOrders

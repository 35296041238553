import { ReactElement } from 'react'

import { Identifier, useListContext, useShowContext } from 'react-admin'

import { useOpenUtilityDrawer, useResource } from 'components'
import UtilityDrawerListEditor from 'components/Drawer/UtilityDrawerListEditor'
import { DeleteOneParams } from 'hooks'
import { WorkOrderItemsExtra } from 'pages/WorkOrders/Jobs/config/constants'
import { useWoActionIsDisabled } from 'pages/WorkOrders/hooks'
import { Serializer } from 'utils'

import { attachmentName, attachmentsSerializer, indexes } from './components/Attachments'
import InvoiceForm from './components/InvoiceForm'

export interface InvoiceDrawerTogglerProps {
    id?: Identifier
    children: (params: { onClick: () => void }) => ReactElement
    term?: string
}

const WorkOrderInvoiceEditDrawerToggler = ({ children, id, term }: InvoiceDrawerTogglerProps) => {
    const open = useOpenUtilityDrawer()
    const resource = useResource()
    const { refetch } = useShowContext()
    const disabledFields = useWoActionIsDisabled()

    const listContext = useListContext()

    return children({
        onClick: () => {
            open({
                extraArgs: {
                    id,
                    resource,
                    listContext,
                },
                drawerArgs: {
                    title: id ? (disabledFields ? 'View Invoice' : 'Edit Invoice') : 'Add Invoice',
                    renderWrapper: (params) => (
                        <UtilityDrawerListEditor
                            {...params}
                            onSuccess={refetch}
                            serializer={invoiceSerializer}
                            meta={{
                                files: indexes.map(attachmentName),
                            }}
                        />
                    ),
                    renderContent: () => <InvoiceForm />,
                    renderBottomRight: disabledFields
                        ? () => null
                        : (render) => render({ label: id ? undefined : 'Add Invoice' }),
                    renderTopRight:
                        id && !disabledFields
                            ? (render) => render({ ...deleteInvoiceAction(refetch) })
                            : null,
                },
                extra: {
                    term,
                    disabledFields,
                } as invoiceFormExtra,
            })
        },
    })
}

export default WorkOrderInvoiceEditDrawerToggler

export const deleteInvoiceAction = (onSuccess?: () => void): DeleteOneParams => ({
    onSuccess,
    confirmConfig: {
        title: 'Are you sure you want to delete the selected Invoice?',
    },
})
export interface WOInvoice {
    id: string
    number: string
    created: string
    updated: string
    amount: string
    date: string
    attachment0: string
    attachment1: string
    attachment2: string
    attachment3: string
    attachment4: string
    attachment5: string
    attachment6: string
    attachment7: string
    attachment8: string
    attachment9: string
    paymentTerm: string
    paymentTermData: {
        id: string
        name: string
    }
}

interface invoiceFormExtra extends WorkOrderItemsExtra {
    term: string
}
const invoiceSerializer: Serializer<WOInvoice> = [
    'number',
    { name: 'amount', parse: 'number' },
    { name: 'date', parse: 'date' },
    {
        name: 'paymentTerm',
        parse: 'emptyToNull',
    },
    ...(attachmentsSerializer as Serializer<WOInvoice>),
]

import { useEffect } from 'react'

import { AddCommentOutlined } from '@mui/icons-material'
import { Button, Typography, Alert, Tooltip } from '@mui/material'
import { inject } from 'mobx-react'
import { useCreatePath, useListContext, useRecordContext, useShowContext } from 'react-admin'
import { useFormContext } from 'react-hook-form'

import { JobModel, WorkOrderModel } from 'appTypes/models'
import {
    BoxContainer,
    Columns,
    InfoBadge,
    SimpleScrollbar,
    TextareaInput,
    Notes,
    NoteType,
} from 'components'
import { useUtilityDrawerContext } from 'components/Drawer/UtilityDrawer'
import { useConfirm, useDataProvider } from 'hooks'
import { woResource } from 'pages/WorkOrders/config/constants'
import { AuthStore } from 'providers/authStore'
import { maxLengthValidationText, requiredValidation } from 'utils'

interface JobNotesProps {
    notes?: JobModel['notes']
}
export const noteInputValidations = [maxLengthValidationText, requiredValidation]
export const JobNotes = ({ notes: notesProp }: JobNotesProps) => {
    const form = useFormContext()
    useEffect(() => {
        if (form) {
            form.register('notesToAdd', { value: [] })
        }
    }, [form])
    const notes = notesProp ? notesProp : form.watch('notes')
    return <JobNotesContent notes={notes} />
}

const JobNotesContent = inject('auth')(
    ({
        notes = [],
        auth,
    }: JobNotesProps & {
        auth?: AuthStore
    }) => {
        const confirm = useConfirm()
        const dataProvider = useDataProvider()
        const record = useRecordContext<JobModel>()
        const { extra }: { extra: { disabledFields: boolean } } = useUtilityDrawerContext()

        const { refetch } = useListContext()
        const { record: woRecord } = useShowContext<WorkOrderModel>()
        const woClosed = extra
            ? extra.disabledFields
            : woRecord
            ? woRecord.status === 'CLOSED'
            : false
        const createPath = useCreatePath()
        const form = useFormContext()
        let content = <Alert severity="info">No Notes Added</Alert>
        if (Array.isArray(notes) && notes.length) {
            content = (
                <SimpleScrollbar
                    sx={{
                        maxHeight: form ? '254px' : '144px',
                    }}
                >
                    <Notes notes={notes} />
                </SimpleScrollbar>
            )
        }
        const addButton = (
            <Button
                variant="text"
                color="primary"
                size="small"
                disabled={woClosed}
                startIcon={<AddCommentOutlined />}
                onClick={() => {
                    confirm({
                        title: 'Add Note',
                        formProps: {
                            warnWhenUnsavedChanges: true,
                        },
                        content: (
                            <TextareaInput
                                source="note"
                                label="Notes"
                                parse={(v) => {
                                    const s = String(v)
                                    return s.trimStart()
                                }}
                                validate={noteInputValidations}
                            />
                        ),
                        onConfirm: async ({ formValues }) => {
                            if (form) {
                                const currNotes = form.getValues('notesToAdd')
                                form.setValue('notesToAdd', [formValues.note, ...currNotes])
                                const note: NoteType = {
                                    createdByData: {
                                        name: auth.user.name,
                                        email: auth.user.email,
                                    },
                                    text: formValues.note,
                                    source: 'MANUAL',
                                }
                                form.setValue('notes', [note, ...notes], {
                                    shouldDirty: true,
                                    shouldTouch: true,
                                })
                            } else {
                                await dataProvider.update(
                                    createPath({
                                        resource: woResource.resource,
                                        id: woRecord.id,
                                        type: 'edit',
                                    }) + '/jobs',
                                    {
                                        data: { notesToAdd: [formValues.note] },
                                        id: record.id,
                                        previousData: {},
                                    },
                                )
                                refetch()
                            }
                        },
                        confirmButtonProps: {
                            variant: 'text',
                            size: 'small',
                            startIcon: <AddCommentOutlined />,
                            children: 'Add Note',
                        },
                        awaitOnConfirm: true,
                    })
                }}
            >
                ADD NOTE
            </Button>
        )
        return (
            <Columns
                gap="14px"
                mt="10px"
            >
                <BoxContainer justifyContent="space-between">
                    <Typography
                        color="text.secondary"
                        variant="chartTitle"
                        component={BoxContainer}
                        gap="8px"
                    >
                        Notes <InfoBadge badgeContent={notes.length} />
                    </Typography>
                    {woClosed ? (
                        <Tooltip title="Reopen the WO to add notes">
                            <div>{addButton}</div>
                        </Tooltip>
                    ) : (
                        addButton
                    )}
                </BoxContainer>
                {content}
            </Columns>
        )
    },
)

export default JobNotes

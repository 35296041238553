import { FileType } from 'appTypes'
import Icons from 'components/icons'

import { BaseActionOptions } from '../Actions'

export const downloadActionBaseOptions: {
    [key in keyof typeof FileType]: BaseActionOptions
} = {
    pdf: {
        Icon: Icons.Pdf,
        title: 'Export as PDF',
    },
    csv: {
        Icon: null,
        title: 'Export as CSV',
    },
}

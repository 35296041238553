import { Box } from '@mui/material'

import noPaymentsIcon from 'assets/images/no-payments.svg'
import { NoResultsCard, Typography } from 'components'

import { WorkOrderPaymentOpenDrawerButton } from '../../WorkOrderInvoice'

const PaymentsSectionEmpty = () => {
    return (
        <NoResultsCard
            title="No Items"
            direction="row"
            height="180px"
            heightsm="280px"
            directionsm="column"
            imageSrc={noPaymentsIcon}
            imageProps={{
                alt: 'No Items',
                width: {
                    xs: '122px',
                    sm: '107px',
                },
                mr: {
                    xs: 0,
                    sm: '23px',
                },
            }}
            action={
                <WorkOrderPaymentOpenDrawerButton mode="create">
                    {({ disabled, onClick }) =>
                        disabled ? null : (
                            <Typography
                                variant="body1"
                                color="primary"
                                component={Box}
                                onClick={onClick}
                                sx={{ cursor: 'pointer' }}
                            >
                                Add Payment
                            </Typography>
                        )
                    }
                </WorkOrderPaymentOpenDrawerButton>
            }
        />
    )
}

export default PaymentsSectionEmpty

import { useRef } from 'react'

import { useRecordContext } from 'react-admin'

import { UnitModel } from 'appTypes/models'
import { useResizeObserverWidth } from 'hooks'
import { SliderWidget, SliderWidgetSkeleton } from 'pages/Dashboard/components/WidgetBase/styled'
import { unitGetRoundedPercents } from 'pages/Dashboard/components/Widgets/CostPerUnitWidget'
import {
    Clarification,
    CostPerUnitChart,
} from 'pages/Dashboard/components/Widgets/CostPerUnitWidget/components'
import { baseContainerProps } from 'pages/Units/Overview/Widgets'
import { resolveDoublePriceMask } from 'utils'

const description = `The Total Expenses widget calculates the sum of all 
Work Orders associated with the current Vendor and provides an insightful breakdown.`
const TotalExpensesWidget = () => {
    const elementRef = useRef()
    const isUnderWidthPoint = useResizeObserverWidth(elementRef, 360)

    const record = useRecordContext<UnitModel>()
    if (!record) {
        return <SliderWidgetSkeleton />
    }
    const ratioData = unitGetRoundedPercents(record)
    const validData = ratioData.total > 0

    return (
        <SliderWidget
            ref={elementRef}
            headerProps={{
                title: 'Total Expenses',
                label:
                    typeof ratioData.total === 'number'
                        ? resolveDoublePriceMask(ratioData.total)
                        : null,
                tooltipText: description,
                clarification: (
                    <Clarification
                        {...ratioData}
                        showTooltip={isUnderWidthPoint}
                        validData={validData}
                    />
                ),
                chart: (
                    <CostPerUnitChart
                        {...ratioData}
                        validData={validData}
                    />
                ),
                containerProps: baseContainerProps,
            }}
        />
    )
}
export default TotalExpensesWidget

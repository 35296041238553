import { SortType } from 'appTypes'
import { ReportDowntimeModel, UnitModel } from 'appTypes/models'
import {
    BoxContainer,
    CardListConfig,
    DatagridColumnsProps,
    PageContent,
    StyledElement,
    dropdownSelectorInDatagridStyle,
} from 'components'
import { DatagridLink } from 'components/Datagrid'
import { ListSortContentProps } from 'components/list/ListSortDrawerContent'
import { FilterConfig } from 'components/list/filter/ListFilterForm'
import ResourceContextProviderWithClearEffect from 'components/resource/ResourceContextProviderWithClearEffect'
import { unitFiltersCfg, unitSortCfg } from 'pages/Units/List/UnitsList'
import { UnitStatusSelector } from 'pages/Units/components'
import {
    archivedUnitResource,
    reportsUnitsResource,
    unitResource,
} from 'pages/Units/config/constants'
import { formatDate, formatDuration } from 'utils'

import { navigateToUnit } from '../CostPerMeter/CostPerMeter'
import { ListBaseWithPeriod, ListWithPeriodSelector, ReportsHeader } from '../components'
import LinkArrowButton from '../components/LinkArrowButton'
import LinkCardButton from '../components/LinkCardButon'
import { newNames } from '../utils'

import { ArchivedUnitBadge, PeriodRangeFilter, PeriodRangeWithTypeFilter } from './components'

const defaultSort: SortType<ReportDowntimeModel> = {
    field: 'downtime',
    order: 'DESC',
}
const formatDurationForDowntime = (value: number) => {
    if (!value) {
        return null
    }
    return formatDuration(value, true)
}

const topFiltersMap: (keyof UnitModel)[] = ['number', 'name']
const includedFiltersMap: (keyof UnitModel)[] = [
    'vin',
    'licensePlate',
    'model',
    'modelYear',
    'engineModel',
    'engineHp',
    'transmissionModel',
    'transmissionGears',
    'color',
    'tireSize',
    'status',
    'created',
    'engineVmrsManufacturer',
    'transmissionVmrsManufacturer',
    'vmrsEquipmentCategory',
    'vmrsManufacturer',
]

const columnsCfg: DatagridColumnsProps<ReportDowntimeModel> = {
    // TO-DO add all columns from units
    resetColumns: {
        vin: false,
        licensePlate: false,
        model: false,
        modelYear: false,
        engineModel: false,
        engineHp: false,
        transmissionModel: false,
        transmissionGears: false,
        color: false,
        tireSize: false,
        status: false,
        created: false,
        engineVmrsManufacturer: false,
        transmissionVmrsManufacturer: false,
        vmrsEquipmentCategory: false,
        vmrsManufacturer: false,
    },
    checkboxSelection: false,
    columns: [
        {
            field: 'number',
            headerName: 'Unit Number',
            renderCell: (params) => {
                const { value, ...restParams } = params
                return (
                    <DatagridLink
                        {...restParams}
                        resource={
                            params.row.archived
                                ? archivedUnitResource.resource
                                : unitResource.resource
                        }
                        value={
                            <BoxContainer>
                                <StyledElement
                                    sx={{
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                    }}
                                >
                                    {value}
                                </StyledElement>
                                {params.row.archived && <ArchivedUnitBadge />}
                            </BoxContainer>
                        }
                    />
                )
            },
        },
        { field: 'name', headerName: 'Unit Name' },
        {
            field: 'downtimeEmergency',
            headerName: 'Emergency',
            valueGetter: ({ value }) => formatDurationForDowntime(value),
        },
        {
            field: 'downtimeNonScheduled',
            headerName: 'Non-Scheduled',
            valueGetter: ({ value }) => formatDurationForDowntime(value),
        },
        {
            field: 'downtimeScheduled',
            headerName: 'Scheduled',
            valueGetter: ({ value }) => formatDurationForDowntime(value),
        },
        {
            field: 'downtimePercent',
            headerName: 'Relative',
            renderCell: ({ value }) => `${Math.round(value)}%`,
        },
        {
            field: 'downtime',
            headerName: 'Total Downtime',
            valueGetter: ({ value }) => formatDurationForDowntime(value),
        },

        {
            field: 'created',
            headerName: 'Created on',
            renderCell: (cell) => {
                return formatDate(cell.value, (dateFormats) => dateFormats.shortenedDateTime)
            },
        },
        { field: 'licensePlate', headerName: 'License Plate' },
        { field: 'vin', headerName: 'VIN' },
        {
            field: 'vmrsEquipmentCategory',
            headerName: 'Equipment Category',
            renderCell: ({ row }) => row.vmrsEquipmentCategoryData?.description,
        },
        { field: 'model', headerName: 'Model' },
        { field: 'modelYear', headerName: 'Model Year' },
        { field: 'color', headerName: 'Color' },
        {
            field: 'status',
            headerName: 'Unit Status',
            flex: 1,
            minWidth: 80,

            renderCell: (cell, { listContext }) => {
                return (
                    <UnitStatusSelector
                        sx={dropdownSelectorInDatagridStyle}
                        record={cell.row}
                        onComplete={() => {
                            listContext.refetch()
                        }}
                    />
                )
            },
        },
    ],
    actions: null,
}

const cardsCfg: CardListConfig<ReportDowntimeModel> = {
    titleSource: (record) => (
        <BoxContainer>
            <StyledElement
                sx={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                }}
            >
                <LinkCardButton
                    to={{
                        id: record.id,
                        type: 'edit',
                        resource: record.archived
                            ? archivedUnitResource.resource
                            : unitResource.resource,
                    }}
                    variant="inherit"
                    text={record.number}
                />
            </StyledElement>
            {record.archived && <ArchivedUnitBadge />}
        </BoxContainer>
    ),
    defaultImage: null,
    details: [
        { source: 'name', label: 'unit name' },
        {
            source: 'downtimeEmergency',
            label: 'Emergency',
            render: (value) => formatDurationForDowntime(value),
        },
        {
            source: 'downtimeNonScheduled',
            label: 'Non-Scheduled',
            render: (value) => formatDurationForDowntime(value),
        },
        {
            source: 'downtimeScheduled',
            label: 'Scheduled',
            render: (value) => formatDurationForDowntime(value),
        },
        {
            source: 'downtimePercent',
            label: 'Relative',
            render: (value) => `${Math.round(value)}%`,
        },
        {
            source: 'downtime',
            label: 'Total Downtime',
            render: (value) => formatDurationForDowntime(value),
        },
    ],
    action: (record) => <LinkArrowButton path={navigateToUnit(record)} />,
}

const sortCfg: ListSortContentProps<ReportDowntimeModel> = {
    sortBy: [
        // TO DO: Find a better way to change the labels of the filters that come from another place
        ...unitSortCfg.sortBy
            .filter((item) => topFiltersMap.some((id) => id === item.id))
            .map((item) => ({
                ...item,
                label: newNames[item.id] || item.label,
            })),
        {
            id: 'downtimeEmergency',
            label: 'Emergency',
        },
        { id: 'downtimeNonScheduled', label: 'Non-Scheduled' },
        { id: 'downtimeScheduled', label: 'Scheduled' },
        { id: 'downtimePercent', label: 'Relative' },
        { id: 'downtime', label: 'Total Downtime' },
        ...unitSortCfg.sortBy
            .filter((item) => includedFiltersMap.some((id) => id === item.id))
            .map((item) => ({
                ...item,
                label: newNames[item.id] || item.label,
            })),
    ],
}

const filtersCfg: FilterConfig<ReportDowntimeModel> = {
    filters: [
        ...unitFiltersCfg.filters
            .filter((item) => topFiltersMap.some((id) => id === item.id))
            .map((item) => ({
                ...item,
                label: newNames[item.id] || item.label,
            })),
        {
            id: 'downtimeEmergency',
            label: 'Emergency',
            filterType: 'range',
            renderComponent: (props) => (
                <PeriodRangeWithTypeFilter
                    {...props}
                    choices={['h', 'd']}
                />
            ),
        },
        {
            id: 'downtimeNonScheduled',
            label: 'Non-Scheduled',
            filterType: 'range',
            renderComponent: (props) => (
                <PeriodRangeWithTypeFilter
                    {...props}
                    choices={['h', 'd']}
                />
            ),
        },
        {
            id: 'downtimeScheduled',
            label: 'Scheduled',
            filterType: 'range',
            renderComponent: (props) => (
                <PeriodRangeWithTypeFilter
                    {...props}
                    choices={['h', 'd']}
                />
            ),
        },
        {
            id: 'downtimePercent',
            label: 'Relative',
            filterType: 'range',
            renderComponent: (props) => <PeriodRangeFilter {...props} />,
        },
        {
            id: 'downtime',
            label: 'Total Downtime',
            filterType: 'range',
            renderComponent: (props) => (
                <PeriodRangeWithTypeFilter
                    {...props}
                    choices={['h', 'd']}
                />
            ),
        },
        ...unitFiltersCfg.filters
            .filter((item) => includedFiltersMap.some((id) => id === item.id))
            .map((item) => ({
                ...item,
                label: newNames[item.id] || item.label,
            })),
        { id: 'archived', label: 'Archived Unit' },
    ],
}
const preferencesResource = {
    ...reportsUnitsResource,
    name: 'downtime',
}
const Downtime = () => {
    return (
        <ResourceContextProviderWithClearEffect value={reportsUnitsResource}>
            <ListBaseWithPeriod
                preferencesResource={preferencesResource}
                sort={defaultSort}
                filter={{ withAspects: ['downtime'] }}
            >
                <ReportsHeader
                    renderTotal={({ hasPreviousPage }) => {
                        const aggregates = (hasPreviousPage as any).aggregates || {}
                        return `${Math.round(
                            aggregates.downtimePercentQueryset || 0,
                        )}% | ${formatDuration(aggregates.downtimeQueryset || 0, true)}`
                    }}
                >
                    Downtime
                </ReportsHeader>
                <PageContent>
                    <ListWithPeriodSelector
                        exportFileName="downtime-by-repair-class"
                        filtersCfg={filtersCfg}
                        sortCfg={sortCfg}
                        columnsCfg={columnsCfg}
                        cardsCfg={cardsCfg}
                        preferencesResource={preferencesResource}
                    />
                </PageContent>
            </ListBaseWithPeriod>
        </ResourceContextProviderWithClearEffect>
    )
}

export default Downtime

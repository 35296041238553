import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'

import smallLogo from 'assets/images/logo-small-part.svg'
import defaultLogo from 'assets/images/logo.svg'
import { urls } from 'configs/config'

const logoTypes = {
    default: defaultLogo,
    small: smallLogo,
}

interface ComponentTypes {
    className?: string
    type?: 'default' | 'small'
}

const LogoSection = styled(({ type, className }: ComponentTypes) => {
    return (
        <Link
            to={urls.root}
            className={className}
        >
            <img
                alt="Fleetpal"
                src={logoTypes[type]}
            />
        </Link>
    )
})`
    display: inline-block;

    & > img {
        height: 24px;
        vertical-align: middle;

        ${({ type }) =>
            ({
                small: `
                width: 25px;
            `,
                default: `
                width: 101px;
            `,
            }[type] || '')}
    }
`

LogoSection.defaultProps = {
    type: 'default',
}

export default LogoSection

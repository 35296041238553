import { WorkOrderModel } from 'appTypes/models'
import { InfoCard, InfoCardDetails } from 'components'
import { dateReadableFormat } from 'utils'

const CanceledInfo = () => {
    return (
        <InfoCard>
            <InfoCardDetails<WorkOrderModel>
                details={[
                    {
                        label: 'canceled On date',
                        source: ({ statusUpdated }) => dateReadableFormat(statusUpdated),
                    },
                    {
                        label: 'canceled by',
                        source: ({ statusUpdatedBy }) =>
                            statusUpdatedBy?.name || statusUpdatedBy?.email,
                    },
                ]}
            />
        </InfoCard>
    )
}

export default CanceledInfo

import { ButtonProps, Tooltip } from '@mui/material'

import { Button } from 'components'
import Icons from 'components/icons'

const UtilityDrawerDeleteButton = (props: ButtonProps) => {
    const { title, ...rest } = props
    const content = (
        <Button
            startIcon={<Icons.Delete />}
            size="small"
            color="error"
            {...rest}
            type="button"
        >
            Delete
        </Button>
    )
    return title ? (
        <Tooltip title={title}>
            <span>{content}</span>
        </Tooltip>
    ) : (
        <>{content}</>
    )
}

export default UtilityDrawerDeleteButton

import { useTranslate } from 'react-admin'
import { useFormState } from 'react-hook-form'
// eslint-disable-next-line camelcase

import { useConfirm, useSetBlocker } from 'hooks'

export const useAskConfirm = () => {
    const confirm = useConfirm()
    const translate = useTranslate()
    const askConfirm = (onConfirm: () => void) =>
        confirm({
            title: translate('ra.message.unsaved_changes'),
            confirmButtonProps: {
                children: 'Ok',
            },
            onConfirm,
            confirmType: 'INFO',
        })

    return askConfirm
}

const FormWarnWhenUnsavedChanges = () => {
    const askConfirm = useAskConfirm()

    const { isSubmitSuccessful, isSubmitting, dirtyFields } = useFormState()
    const isDirty = Object.keys(dirtyFields).length > 0
    const isSubmit = isSubmitting === false && isSubmitSuccessful === false

    useSetBlocker(
        {
            preventNavigate: (action, { resume }) => {
                askConfirm(() => {
                    resume()
                })
            },
        },
        {
            isOpen: isDirty && isSubmit,
        },
    )

    return null
}

export default FormWarnWhenUnsavedChanges

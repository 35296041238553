import { useFormContext } from 'react-hook-form'

import { DateInput, SelectInput } from 'components/inputs'
import { dateRangeObject, dateRanges, dateTimeRangeFormats } from 'utils'

interface ListFilterDateRangeValueInputProps {
    source: string
}

const ListFilterDateRangeValueInput = ({ source }: ListFilterDateRangeValueInputProps) => {
    const { setValue } = useFormContext()
    const sourceMin = source + 'Min'
    const sourceMax = source + 'Max'
    const sourcePre = source + 'Pre'

    const preChange = (event) => {
        const value = event.target.value

        const [from, to] = dateRangeObject[value].values('string')
        setValue(sourceMin, from)
        setValue(sourceMax, to)
    }

    const minMaxChange = () => {
        setValue(source, '')
    }

    return (
        <div>
            <SelectInput
                onChange={preChange}
                choices={dateRanges}
                source={sourcePre}
                label="Predefined range"
            />

            <DateInput
                returnDateFormat={dateTimeRangeFormats.from}
                source={sourceMin}
                label="From"
                onChange={minMaxChange}
            />

            <DateInput
                returnDateFormat={dateTimeRangeFormats.to}
                source={sourceMax}
                label="To"
                onChange={minMaxChange}
            />
        </div>
    )
}
ListFilterDateRangeValueInput.sources = {
    Max: 'Max',
    Min: 'Min',
}
export default ListFilterDateRangeValueInput

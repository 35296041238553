import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Link } from 'react-router-dom'

import { IconBox } from 'components'

const navigateIcon = (
    <IconBox
        sx={{
            width: '24px',
            height: '24px',
        }}
    >
        <ArrowForwardIcon />
    </IconBox>
)

const LinkArrowButton = ({ path }: { path: string }) => {
    return <Link to={path}>{navigateIcon}</Link>
}

export default LinkArrowButton

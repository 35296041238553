import { Spacer } from 'components/styled'
import { SpacerProps } from 'components/styled/Spacer'

const HeaderSpacer = ({ variant = 'md', ...rest }: SpacerProps) => {
    return (
        <Spacer
            variant={variant}
            {...rest}
        />
    )
}
export default HeaderSpacer

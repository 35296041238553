import { useListContext, useShowContext } from 'react-admin'

import { basePermissions } from 'configs/constants'
import { renderOnPermission } from 'hocs'
import { DeleteOneFromListParams, useDeleteOneFromList } from 'hooks'

import { ActionChildren, ListActionExtendedOption } from './../Actions'
import { deleteActionBaseOptions } from './deleteActionCommon'

interface DeleteOneFromListActionProps extends DeleteOneFromListParams {
    children: ActionChildren<{}, ListActionExtendedOption>
    disabled?: boolean
}

// TODO: fix: delete action from WO stops refetching, because the button unmounts and onSuccess is not being executed
export const DeleteOneFromListAction = renderOnPermission(
    ({ children, disabled, ...rest }: DeleteOneFromListActionProps) => {
        const deleteOne = useDeleteOneFromList()
        const listContext = useListContext()
        const isMultiselectMode = Boolean(listContext.selectedIds.length)
        return children(
            {
                disabled: disabled ?? isMultiselectMode,
                ...deleteActionBaseOptions,
                onClick: () => {
                    deleteOne(rest)
                },
            },
            {
                isMultiselectMode,
                listContext,
            },
        )
    },
    basePermissions.destroy,
)

export const deleteOneFromListAction = (params: DeleteOneFromListActionProps) => (
    <DeleteOneFromListAction
        {...params}
        key="delete-one-from-list"
    />
)

export const DeleteOneFromListActionWithShowRefetch = (props: DeleteOneFromListActionProps) => {
    const { refetch } = useShowContext()
    return (
        <DeleteOneFromListAction
            {...props}
            onSuccess={() => {
                props.onSuccess?.()
                refetch()
            }}
        />
    )
}

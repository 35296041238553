import startCase from 'lodash/startCase'
import toLower from 'lodash/toLower'

export const removeTrailingSlash = (path: string) => {
    return path.charAt(path.length - 1) === '/' ? path.slice(0, -1) : path
}

export const stripDiacritics = (str: string) => {
    return typeof str.normalize === 'undefined'
        ? str
        : str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export const capitalize = <W extends string = string>(w: W): Capitalize<W> => {
    return (w.charAt(0).toUpperCase() + w.slice(1)) as Capitalize<W>
}

export const capitalizeWithLowerCase = (w: string) => {
    return w.charAt(0).toUpperCase() + w.slice(1).toLowerCase()
}

export const prettyText = (w: string) => {
    return capitalizeWithLowerCase(w).replaceAll('_', ' ')
}

export const uppercaseFormat = <W extends string = string>(value: W): Uppercase<W> =>
    (value?.toUpperCase?.() ?? '') as Uppercase<W>
export const lowerCaseFormat = <W extends string = string>(value: W): Lowercase<W> =>
    (value?.toLowerCase?.() ?? '') as Lowercase<W>

export const precisionFormat2 = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2,
})

export const makeid = (length = 8) => {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    for (let i = 0; i < length; i += 1) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
}

export const stringSplitFirstOccurrence = (
    text: string,
    splitText: string = ' ',
): [string, string] => {
    const foundIndex = text.indexOf(splitText)
    const index = foundIndex === -1 ? text.length : foundIndex

    return [text.substring(0, index), text.substring(index + 1)]
}

export const pathJoin = (...pathnames: string[]) => {
    return pathnames.reduce((path, pathname) => {
        if (pathname) {
            return path + '/' + pathname
        }

        return path
    })
}

export const titleCase = (str: string) => startCase(toLower(str))

export const displayState = ({ abbr, name }: { abbr: string; name: string }) =>
    `${abbr || ''} ${name || ''}`

export const propertyToText = (source: string) => {
    if (!source) {
        return null
    }

    return source.replace(/([A-Z])/g, ' $1').trim()
}

import { useEffect } from 'react'

import { useFormContext } from 'react-hook-form'

import { SelectInput, TextInput, SectionTitleInDrawer, GridContainerColumns } from 'components'
import { GridItemLayoutInDrawer } from 'components/pages/GridItemLayout'
import { meterTypeChoices } from 'pages/Units/Meters/constants'
import { inputIntegerNonNegativeSpacedMaskParams } from 'utils'

const WorkOrderFormMeter = () => {
    const { setValue, watch } = useFormContext()
    const value = watch('meterType')

    const isEmptyMeterType = !value

    useEffect(() => {
        if (isEmptyMeterType) {
            setValue('meterValue', '')
        }
    }, [isEmptyMeterType])

    return (
        <>
            <SectionTitleInDrawer
                largeVariant="subtitle1"
                frontLine
            >
                Meter
            </SectionTitleInDrawer>
            <GridContainerColumns>
                <GridItemLayoutInDrawer>
                    <SelectInput
                        source="meterType"
                        label="Meter Type"
                        choices={meterTypeChoices}
                        disableEmptyValue
                    />
                </GridItemLayoutInDrawer>
                <GridItemLayoutInDrawer>
                    <TextInput
                        source="meterValue"
                        label="Value"
                        disabled={isEmptyMeterType}
                        {...inputIntegerNonNegativeSpacedMaskParams}
                    />
                </GridItemLayoutInDrawer>
            </GridContainerColumns>
        </>
    )
}

export default WorkOrderFormMeter

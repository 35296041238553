import { Invoice } from 'appTypes/models'
import { GridContainerGrid, GridItem } from 'components'

import PymentHistoryCard from './PymentHistoryCard'

const PaymentHistoryContent = ({ data }: { data: Invoice[] }) => {
    return (
        <GridContainerGrid>
            {data.map((invoice) => (
                <GridItem
                    sm={6}
                    md={4}
                    lg={3}
                    key={invoice.id}
                >
                    <PymentHistoryCard invoice={invoice} />
                </GridItem>
            ))}
        </GridContainerGrid>
    )
}

export default PaymentHistoryContent

import { ReactElement } from 'react'

import { Edit } from '@mui/icons-material'
import { Identifier, useListContext } from 'react-admin'
import { Link } from 'react-router-dom'

import { basePermissions } from 'configs/constants'
import { renderOnPermission } from 'hocs'
import { useCreateResourcePath } from 'hooks'

import { BaseActionRenderParams } from '../Actions'

export interface EditRedirectInListActionProps {
    id: Identifier
    children: (params: Omit<BaseActionRenderParams, 'onClick'>) => ReactElement
}

export const EditRedirectInListAction = renderOnPermission(
    ({ children, id }: EditRedirectInListActionProps) => {
        const createPath = useCreateResourcePath()
        const listContext = useListContext()

        const disabled = Boolean(listContext.selectedIds.length)

        const content = children({
            Icon: Edit,
            title: 'View/Edit',
            disabled: disabled ?? false,
        })

        if (disabled) {
            return content
        }

        return <Link to={createPath({ type: 'edit', id })}>{content}</Link>
    },
    basePermissions.retrieve,
)

export const editRedirectInListAction = (params: EditRedirectInListActionProps) => (
    <EditRedirectInListAction
        key="edit-redirect-action"
        {...params}
    />
)

import { styled } from '@mui/material'

import DataAvatar from 'components/DataAvatar'

const HeaderAvatar = styled(DataAvatar)`
    width: 40px;
    height: 40px;
    ${({ theme }) => `
    ${theme.breakpoints.up('sm')} {
        marginRight: 16px;
        width: 56px;
        height: 56px;
    }
`}
`

export default HeaderAvatar

import { PropsWithChildren } from 'react'

import { Tooltip } from '@mui/material'

import LineItemsTooltipContent, { LineItemsTooltipContentProps } from './LineItemsTooltipContent'
export const LineItemsTooltip = ({
    children,
    data,
    allWhite = false,
}: PropsWithChildren<LineItemsTooltipContentProps>) => {
    return (
        <Tooltip
            enterTouchDelay={0}
            leaveTouchDelay={5000}
            componentsProps={{
                tooltip: {
                    sx: {
                        padding: '0px',
                    },
                },
            }}
            title={
                <LineItemsTooltipContent
                    data={data}
                    allWhite={allWhite}
                />
            }
        >
            <div>{children}</div>
        </Tooltip>
    )
}

export default LineItemsTooltip

import { useLocation, Location } from 'react-router-dom'

import useDidUpdate from './useDidUpdate'

const useLocationChange = (callback: (l: Location) => void) => {
    const location = useLocation()

    useDidUpdate(() => {
        callback(location)
    }, [location])
}

export default useLocationChange

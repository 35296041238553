import { WOJobItemModels, WOJobItemTypes } from 'appTypes/models'

export type clarificationMapType = {
    id: keyof WOJobItemModels
    label: string
    tint: string
    total: string
    totalPercent: string
}[]
export const unitClarificationMap: clarificationMapType = [
    {
        id: WOJobItemTypes.part,
        label: 'Parts',
        tint: 'green',
        total: 'totalParts',
        totalPercent: 'totalPartsPercent',
    },
    {
        id: WOJobItemTypes.service,
        label: 'Services',
        tint: 'orange',
        total: 'totalServices',
        totalPercent: 'totalServicesPercent',
    },
    {
        id: WOJobItemTypes.tax,
        label: 'Tax',
        tint: 'blue',
        total: 'totalTax',
        totalPercent: 'totalTaxPercent',
    },
    {
        id: WOJobItemTypes.labor,
        label: 'Labor',
        tint: 'purple',
        total: 'totalLabor',
        totalPercent: 'totalLaborPercent',
    },
    {
        id: WOJobItemTypes.fee,
        label: 'Fee',
        tint: 'yellow',
        total: 'totalFees',
        totalPercent: 'totalFeesPercent',
    },
]
export type ExtraJobItemTotals = {
    totalTax: number
    totalServices: number
    totalFees: number
}
export type BaseJobItemTotals = {
    totalLabor: number
    totalParts: number
}
export type JobItemTotals = BaseJobItemTotals & ExtraJobItemTotals

export type ExtraJobItemTotalsPercentages = {
    totalTaxPercent: number
    totalServicesPercent: number
    totalFeesPercent: number
}
export type BaseJobItemTotalsPercentages = {
    totalLaborPercent: number
    totalPartsPercent: number
}
export type JobItemTotalsPercentages = ExtraJobItemTotalsPercentages & BaseJobItemTotalsPercentages

export interface JobItemsCalculations extends JobItemTotals, JobItemTotalsPercentages {
    total: number
    positiveTotal?: number
}
export const defaultJobItemsCalculations: JobItemsCalculations = {
    total: null,
    totalLabor: 0,
    totalParts: 0,
    totalLaborPercent: 0,
    totalPartsPercent: 0,
    totalTax: 0,
    totalTaxPercent: 0,
    totalServices: 0,
    totalServicesPercent: 0,
    totalFees: 0,
    totalFeesPercent: 0,
    positiveTotal: null,
}

import { Divider } from '@mui/material'

import { BoxContainer, Typography } from 'components'

const divider = (
    <Divider
        sx={{ flexGrow: 1 }}
        orientation="horizontal"
    />
)

const SignDivider = () => {
    return (
        <BoxContainer
            mb="21px"
            mt="20px"
        >
            {divider}
            <Typography
                variant="body1"
                px="22px"
                color="secondary.dark"
            >
                OR
            </Typography>
            {divider}
        </BoxContainer>
    )
}

export default SignDivider

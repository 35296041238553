import { useMediaQuery, Theme } from '@mui/material'

import Header from 'components/pages/header'
import { BoxContainer } from 'components/styled'

import Skeleton, { createSkeletonArray } from '../Skeleton'

const actionsSkeletons = createSkeletonArray(3, { width: '24px', height: '24px' })
const ShowHeaderSkeleton = () => {
    const match = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'))
    return (
        <Header fixed>
            <Header.Block divider="hideOnMobile">
                <Skeleton
                    width="28px"
                    height="28px"
                />
                <Header.Main>
                    <Header.Info
                        title={
                            <Skeleton
                                height="8px"
                                width={match ? '110px' : '250px'}
                                sx={{
                                    mb: '12px',
                                }}
                            />
                        }
                        avatar={
                            <Skeleton
                                variant="circular"
                                width="56px"
                                height="56px"
                            />
                        }
                        subheader={
                            <Skeleton
                                height="8px"
                                width={match ? '55px' : '120px'}
                            />
                        }
                    />
                </Header.Main>
                <Header.Aside>
                    <BoxContainer gap="12px">
                        {match ? actionsSkeletons[0] : actionsSkeletons}
                    </BoxContainer>
                </Header.Aside>
            </Header.Block>
            <Header.Tabs
                tabs={[{ label: 'fake', value: 'fake' }]}
                loading
            />
        </Header>
    )
}
export default ShowHeaderSkeleton

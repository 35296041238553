import { Drawer, drawerClasses } from '@mui/material'
import { styled } from '@mui/material/styles'

const SidebarDrawer = styled(Drawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})`
    background: ${({ theme }) => theme.palette.white};
    color: ${({ theme }) => theme.palette.text.primary};
    border-right: 1px solid ${({ theme }) => theme.palette.grey[100]};
    top: 0;
    flex-shrink: 0;
    white-space: nowrap;
    box-sizing: border-box;
    & .${drawerClasses.paper} {
        ${({ open, theme }) =>
            open
                ? `
            box-shadow: ${theme.shadows[16]};
            transition: ${theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            })};
            overflow-x: hidden;
            width: 256px;
        `
                : `
            transition: ${theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            })};
            overflow-x: hidden;
            width: 0;
            ${theme.breakpoints.up(theme.props.mobileViewBreakpoint)} {
                width: ${theme.props.drawerWidth}px;
            }
            `}
    }
`

export default SidebarDrawer

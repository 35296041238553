import { ReactElement, FC, ReactNode } from 'react'

import { GridViewOutlined, SendOutlined } from '@mui/icons-material'
import { Box, darken, Alert } from '@mui/material'
import { ReferenceInput, useRecordContext } from 'react-admin'
import { useFormContext } from 'react-hook-form'
import { Link } from 'react-router-dom'

import { VendorContactModel, WorkOrderModel } from 'appTypes/models'
import {
    GridContainerColumns,
    GridItemLayout,
    IconBox,
    InfoCard,
    InfoCardDetails,
    InfoCardHeader,
    useOpenUtilityDrawer,
    ViewHeader,
    Section,
    AutocompleteInput,
    Typography,
    Spacer,
    ArrayControllerContextProvider,
    UtilityDrawerAddNewButton,
    ArrayControllerElements,
    ArrayControllerBox,
    useArrayControllerElementContext,
    ReferenceInputCreateLayer,
    enhancedOptionText,
} from 'components'
import UtilityDrawerForm from 'components/Drawer/UtilityDrawerForm'
import { downloadOneAction } from 'components/actions'
import Icons from 'components/icons'
import { useDataProvider, useSubmit } from 'hooks'
import { VendorContactDrawerToggler } from 'pages/Vendors/Contacts/components'
import { vendorsResource } from 'pages/Vendors/constants/config'
import { useDataProviderConfig } from 'providers/dataProvider'
import { resolveDoublePriceMask } from 'utils'

import { poResource } from '../config/constants'
const optionText = enhancedOptionText<VendorContactModel>('email', ({ name }) =>
    name ? name : 'Company Email',
)
const inputText = ({ email, name }: VendorContactModel) => email || name

export const EmailField = ({ vendor }: { vendor: string }) => {
    const { item } = useArrayControllerElementContext()

    return (
        <ReferenceInput
            source={`email${item}`}
            perPage={20}
            filter={{ email: true }}
            reference={`${vendorsResource.resource}/${vendor}/contacts`}
        >
            <ReferenceInputCreateLayer>
                {({ setValue }) => {
                    return (
                        <AutocompleteInput
                            renderCreateOption={(renderOption) => (
                                <VendorContactDrawerToggler
                                    onSuccess={(record: VendorContactModel) => {
                                        setValue(record.email)
                                    }}
                                    children={({ onClick }) =>
                                        renderOption({
                                            children: '+ Add Contact Person',
                                            onClick,
                                        })
                                    }
                                />
                            )}
                            optionValue="email"
                            optionText={optionText}
                            inputText={inputText}
                            label="Select"
                        />
                    )
                }}
            </ReferenceInputCreateLayer>
        </ReferenceInput>
    )
}

export const PurchaseOrdersContactAlert = () => {
    return (
        <Alert
            severity="info"
            sx={{ mb: '20px', alignItems: 'center' }}
        >
            <Typography
                color="inherit"
                variant="body2"
            >
                Purchase Orders can be sent only to contacts with an email address.
            </Typography>
        </Alert>
    )
}
const PoFormContent = ({ vendor }: { vendor: string }) => {
    const { unregister } = useFormContext()
    useDataProviderConfig(`${vendorsResource.resource}/${vendor}/contacts`, {
        getMany: {
            shouldStopRequest: true,
        },
    })
    return (
        <>
            <PurchaseOrdersContactAlert />
            <ArrayControllerElements
                onDelete={({ item }) => {
                    unregister(`email${item}`)
                }}
            >
                <ArrayControllerBox title="Recipient">
                    <EmailField vendor={vendor} />
                </ArrayControllerBox>
            </ArrayControllerElements>
        </>
    )
}
interface PoFormWrapperType {
    children: ReactNode
    [x: string]: any
}
export const PoFormWrapper = ({ children, ...rest }: PoFormWrapperType) => {
    return (
        <UtilityDrawerForm {...rest}>
            <ArrayControllerContextProvider>{children}</ArrayControllerContextProvider>
        </UtilityDrawerForm>
    )
}

interface SendPoTogglerProps {
    children: (params: { onClick: () => void }) => ReactElement
}

const sanitizeEmails = (formData) => {
    const data = {}

    const keys = Object.keys(formData)

    for (let i = 0; i < keys.length; i += 1) {
        if (formData[keys[i]]) {
            data[`email${i}`] = formData[keys[i]]
        }
    }

    return data
}

export const SendPoActionToggler = ({ children }: SendPoTogglerProps) => {
    const open = useOpenUtilityDrawer()
    const { purchaseOrderData } = useRecordContext<WorkOrderModel>()
    const dataProvider = useDataProvider()

    const submitHandler = useSubmit(
        async (formData) => {
            const data = sanitizeEmails(formData)

            await dataProvider.createMany(
                poResource.resource + `/${purchaseOrderData.id}/send_pdf`,
                { data },
            )
        },
        {
            successMessage: ({ formData }) => {
                const data = sanitizeEmails(formData)
                return (
                    <Box
                        sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {purchaseOrderData.number} sent to
                        <Typography
                            variant="body2"
                            sx={(theme) => ({
                                fontWeight: '500',
                                color: darken(theme.palette.success.main, 0.6),
                            })}
                        >
                            {Object.values(data).join(', ')}
                        </Typography>
                    </Box>
                )
            },
            successMessageType: 'success',
        },
    )
    return children({
        onClick: () => {
            open({
                drawerArgs: {
                    title: 'Send PO',
                    renderWrapper: (params) => (
                        <PoFormWrapper
                            {...params}
                            onSubmit={submitHandler}
                        />
                    ),
                    renderTopRight: () => <UtilityDrawerAddNewButton children="Add recipient" />,
                    renderContent: () => <PoFormContent vendor={purchaseOrderData.vendorData.id} />,
                    renderBottomRight: (render) =>
                        render({ label: 'SEND', icon: <SendOutlined /> }),
                },
            })
        },
    })
}
export const SendPoActionTogglerButton: FC<SendPoTogglerProps> = ({ children }) => {
    return <SendPoActionToggler>{children}</SendPoActionToggler>
}
export const SendPoAction = () => {
    return (
        <SendPoActionTogglerButton>
            {({ onClick }) => (
                <IconBox
                    title="Send PO"
                    onClick={onClick}
                >
                    <SendOutlined />
                </IconBox>
            )}
        </SendPoActionTogglerButton>
    )
}
export const CanceledWorkOrderPurchaseInvoice = () => {
    const record = useRecordContext()

    if (record.purchaseOrderData) {
        return (
            <InfoCard>
                <InfoCardHeader title="Purchase Order" />
                <InfoCardDetails
                    details={[
                        {
                            label: 'Purchase Order Number',
                            source: ({ purchaseOrderData }) => purchaseOrderData.number,
                        },
                    ]}
                />
            </InfoCard>
        )
    }
    return null
}

export const PurchaseOrderExportAction = () => {
    const { purchaseOrderData } = useRecordContext<WorkOrderModel>()
    return downloadOneAction({
        children: ({ onClick }) => (
            <IconBox
                title="Export PO"
                onClick={onClick}
            >
                <Icons.Pdf />
            </IconBox>
        ),
        resource: poResource,
        id: purchaseOrderData.id,
        filename: purchaseOrderData.number,
        type: 'pdf',
    })
}

const WorkOrderPurchaseInvoice = () => {
    const { purchaseOrderData } = useRecordContext<WorkOrderModel>()

    return (
        <Section>
            <ViewHeader title="Purchase Order/Invoice">
                <ViewHeader.Content placement="rightMobile">
                    <Link to="invoice">
                        <IconBox title="See all">
                            <GridViewOutlined />
                        </IconBox>
                    </Link>
                </ViewHeader.Content>
            </ViewHeader>
            <GridContainerColumns>
                <GridItemLayout>
                    <InfoCard>
                        <InfoCardHeader
                            action={
                                <Spacer>
                                    <SendPoAction />
                                    <PurchaseOrderExportAction />
                                </Spacer>
                            }
                            title={purchaseOrderData.number}
                            titleTypographyProps={{
                                variant: 'subtitle2',
                            }}
                        />
                        <InfoCardDetails<WorkOrderModel>
                            details={[
                                {
                                    label: 'Purchase Order Total',
                                    source: ({ purchaseOrderData }) =>
                                        resolveDoublePriceMask(purchaseOrderData.total),
                                },
                                {
                                    label: 'Invoices Total',
                                    source: ({ purchaseOrderData }) =>
                                        resolveDoublePriceMask(purchaseOrderData.totalInvoices),
                                },
                                {
                                    label: 'Payments Total',
                                    source: ({ purchaseOrderData }) =>
                                        resolveDoublePriceMask(purchaseOrderData.totalPayments),
                                },
                            ]}
                        />
                    </InfoCard>
                </GridItemLayout>
            </GridContainerColumns>
        </Section>
    )
}
export default WorkOrderPurchaseInvoice

import { DeleteOneParams } from 'hooks'
import { generateResource } from 'utils'

export const tagsResource = generateResource({
    name: 'tags',
    resource: 'tags',
})

export const deleteTagAction = (items?: number): DeleteOneParams => {
    return {
        confirmConfig: {
            title: 'Are you sure you want to delete this Tag?',
            content: items ? `This tag is applied to a total of ${items} items.` : null,
        },
    }
}

import { GlobalStyles } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { Provider as MobxProvider } from 'mobx-react'
// import { ReactQueryDevtools } from 'react-query/devtools'
import PWAPrompt from 'react-ios-pwa-prompt'

import {
    Confirm,
    UserObserver,
    MobxSyncFlags,
    FullPageLoader,
    BlockersProvider,
    ErrorHandling,
    AdminContext,
} from 'components'
import { authStore } from 'providers'
import Routes from 'routes'
import globalStyle from 'theme/globalStyle'

const App = () => {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobxProvider auth={authStore}>
                <AdminContext>
                    {/* <ReactQueryDevtools initialIsOpen={false} /> */}
                    <GlobalStyles styles={globalStyle} />
                    <BlockersProvider>
                        <ErrorHandling>
                            <Routes />
                        </ErrorHandling>
                        <Confirm />
                        <UserObserver />
                        <MobxSyncFlags />
                        <FullPageLoader />
                    </BlockersProvider>
                </AdminContext>
                <PWAPrompt
                    copyBody={
                        'For a better experience you can add Fleetpal ' +
                        'to your home screen and use it in fullscreen mode.'
                    }
                    copyClosePrompt="Close"
                />
            </MobxProvider>
        </LocalizationProvider>
    )
}

export default App

// a

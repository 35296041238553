import { ListBase, useRecordContext, useShowContext } from 'react-admin'
import { Link } from 'react-router-dom'

import { WorkOrderModel } from 'appTypes/models'
import { FlexReverse, IconBox, PageContent } from 'components'
import { Action, ActionsMenu } from 'components/actions'
import Icons from 'components/icons/'

import { WorkOrderJobsResource, WorkOrderJobsSlider } from '../Jobs'
import { CreateJobDrawerToggler } from '../Jobs/config/constants'
import {
    WorkOrderDetails,
    WorkOrderPurchaseInvoice,
    WorkOrderTopSection,
    WorkOrderVendorCard,
} from '../components'

const jobsActions: Action<WorkOrderModel> = (params, { children }) => [
    <CreateJobDrawerToggler
        key="add-job"
        children={({ onClick }) => children({ Icon: Icons.Add, title: 'Add Job', onClick })}
    />,
    children({
        Icon: Icons.GridView,
        title: 'See All',
        key: 'see-all',
        to: 'jobs',
    }),
]

const JobsListAction = () => {
    return (
        <Link to="jobs">
            <IconBox title="See All">
                <Icons.GridView />
            </IconBox>
        </Link>
    )
}

export const isVendorWo = (wo: WorkOrderModel) => Boolean(wo?.purchaseOrderData)

export const useIsVenderWo = (raRecord?: WorkOrderModel) => {
    const { record: contextRecord } = useShowContext<WorkOrderModel>()
    const record = raRecord || contextRecord

    if (!record || !record.unit) {
        return null
    }
    return isVendorWo(record)
}
interface WithIsVendorWOProps {
    record?: WorkOrderModel
    [key: string]: any
}
export const WithIsVendorWO = (props: WithIsVendorWOProps) => {
    const isVendorWorkOrder = useIsVenderWo(props.record)
    return props.children(isVendorWorkOrder)
}

const WorkOrderOverview = () => {
    const workOrder = useRecordContext<WorkOrderModel>() || ({} as WorkOrderModel)
    const isVendorWorkOrder = useIsVenderWo()
    const isStatusCancel = workOrder?.status === 'CANCELED'
    const hasAction = workOrder?.status === 'OPEN'

    return (
        <div>
            <WorkOrderTopSection />
            <PageContent>
                {isStatusCancel ? (
                    <WorkOrderDetails />
                ) : (
                    <FlexReverse>
                        {isVendorWorkOrder ? <WorkOrderVendorCard data={workOrder} /> : null}
                        <WorkOrderDetails />
                        <WorkOrderJobsResource>
                            <ListBase perPage={3}>
                                <WorkOrderJobsSlider
                                    action={
                                        hasAction ? (
                                            <ActionsMenu
                                                record={workOrder}
                                                actions={jobsActions}
                                            />
                                        ) : (
                                            <JobsListAction />
                                        )
                                    }
                                />
                            </ListBase>
                        </WorkOrderJobsResource>
                        {isVendorWorkOrder ? <WorkOrderPurchaseInvoice /> : null}
                    </FlexReverse>
                )}
            </PageContent>
        </div>
    )
}

export default WorkOrderOverview

import { reportUrl } from 'pages/Reports'
import { createReportResource } from 'pages/Units/config/constants'

import { extractWidgetTop3 } from '../../WidgetBase'
import WidgetBase, { WidgetSkeleton } from '../../WidgetBase/WidgetBase'
import { useCreateDashboardWidgetLink, useDashboardWidget } from '../../WidgetsForm'
import { unitGetRoundedPercents } from '../CostPerUnitWidget'

import { CostPerCategoryChart, CostPerCategoryClarification, TopCategories } from './components'
import { ResponseType, listType, QueryResponse } from './types'

const description = `Drill down further into maintenance costs,
 by exploring the VMRS repair systems which account for most of the expenses.`
let validData = false
let hasData = false
const CostPerCategoryWidget = () => {
    const createLink = useCreateDashboardWidgetLink()

    const data = useDashboardWidget<QueryResponse>({
        resource: createReportResource('cost-per-category'),
        sideEffect: (data) => {
            const finalData = unitGetRoundedPercents(data)
            const { maxTotalCategories, ...ratioData } = finalData as unknown as ResponseType
            const slicedList = maxTotalCategories.slice(0, 3)
            const listExtras = extractWidgetTop3(slicedList)
            hasData = typeof ratioData.total === 'number'
            validData = ratioData.total > 0 && listExtras.top3Percentage >= 0
            const list: listType = {
                data: slicedList,
                ...listExtras,
                sumOfExcludedCategories: data.total - listExtras.top3Total,
            }
            return {
                ...ratioData,
                list,
            }
        },
    })
    if (!data) {
        return <WidgetSkeleton />
    }
    const { list, ...ratioData } = data

    return (
        <WidgetBase
            headerProps={{
                title: 'Top 3 Categories Account for',
                label: hasData ? (validData ? `${Math.round(list.top3Percentage)}%` : 'N/A') : null,
                tooltipText: description,
                clarification: (
                    <CostPerCategoryClarification>
                        of all Maintenance Costs
                    </CostPerCategoryClarification>
                ),
                chart: (
                    <CostPerCategoryChart
                        list={list}
                        validData={validData}
                        total={ratioData.total}
                    />
                ),
            }}
            infoProps={{
                title: 'Top 3 Categories',
                content: (
                    <TopCategories
                        list={list}
                        description={description}
                    />
                ),
            }}
            link={createLink(reportUrl(reportUrl.urls.costPerCategory))}
        />
    )
}
export default CostPerCategoryWidget

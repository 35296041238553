import { Alert } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import Typography from 'components/mui/Typography'
import { formErrors, formErrorToString } from 'utils'

const FormNonFieldErrors = () => {
    const { formState } = useFormContext()
    const error = formErrors(formState.errors).nonFieldErrors

    if (!error) {
        return null
    }

    return (
        <Alert
            variant="standard"
            severity="error"
            sx={{
                mb: '24px',
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <Typography
                variant="body2"
                color="inherit"
            >
                {formErrorToString(error)}
            </Typography>
        </Alert>
    )
}

export default FormNonFieldErrors

import noPhotos from 'assets/images/noPhotos.svg'
import { NoResultsCard, NonDisplayedInput, Typography } from 'components'

const NoPhotosCard = ({ upload }: { upload: (event: any) => Promise<void> }) => {
    return (
        <NoResultsCard
            title="No Photos Added"
            direction="row"
            height="180px"
            imageSrc={noPhotos}
            imageProps={{
                alt: 'No Notes',
                width: '106px',
                mt: '20px',
                mr: '28px',
            }}
            action={
                <label>
                    <NonDisplayedInput
                        type="file"
                        onChange={upload}
                        accept="image/*"
                    />
                    <Typography
                        variant="body1"
                        color="primary"
                        sx={{ cursor: 'pointer' }}
                    >
                        Add Photo
                    </Typography>
                </label>
            }
        />
    )
}

export default NoPhotosCard

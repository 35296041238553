import { FC } from 'react'

// FP-1713 Delete Button / Tooltip
import { Box, ButtonBase, Tooltip, Theme, useMediaQuery } from '@mui/material'

// FP-1713 Delete config

import { StyledElement, Button } from 'components'
import config from 'configs/config'
import { useFlags } from 'hooks'

import LogoSection from '../LogoSection'
import { useSidebarContext } from '../Sidebar'

import MenuButton from './MenuButton'
import { HeaderLayoutContainer } from './styled'

const MainHeader: FC = () => {
    const { toggle } = useSidebarContext()
    const flags = useFlags()
    const isVisible = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down(theme.props.mobileViewBreakpoint),
    )
    if (isVisible) {
        return (
            <StyledElement
                sx={{
                    width: '100%',
                }}
            >
                <HeaderLayoutContainer>
                    <Box component="span">
                        <LogoSection />
                    </Box>
                    {/* FP-1713 Show fleetpal-version on mobile -> to be deleted */}
                    {flags.showVersionInMobileMode && (
                        <Box component="span">
                            <Tooltip title={`Current Version:${config.VERSION}`}>
                                <Button variant="text">Version</Button>
                            </Tooltip>
                        </Box>
                    )}
                    {/* FP-1713 ------------- to be deleted */}
                    <ButtonBase
                        aria-label="Open Sidebar"
                        onClick={toggle}
                        sx={{
                            width: '56px',
                            height: '56px',
                        }}
                    >
                        <MenuButton />
                    </ButtonBase>
                </HeaderLayoutContainer>
            </StyledElement>
        )
    }
    return null
}

export default MainHeader

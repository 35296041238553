import { Create, PageContent } from 'components'

import { UnitForm } from '../components'
import { unitSerializer } from '../config/constants'

import { CreateUnitHeader } from './components'
const UnitCreate = () => {
    return (
        <Create serializer={unitSerializer}>
            <CreateUnitHeader />
            <PageContent>
                <UnitForm />
            </PageContent>
        </Create>
    )
}

export default UnitCreate

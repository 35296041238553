import { styled } from '@mui/material/styles'

import { withErrorBoundary } from 'components/ErrorBoundary'
import { cssVariables } from 'theme/globalStyle'

import PageBlock from './PageBlock'

const PageContent = styled(PageBlock)`
    ${cssVariables.pageContentPadding}: 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: var(${cssVariables.pageContentPadding});
    ${({ theme }) => `
        ${theme.breakpoints.up('sm')} {
            ${cssVariables.pageContentPadding}: 28px;
        }
    `}
`

export default withErrorBoundary(PageContent, {})

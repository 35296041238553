import CheckIcon from '@mui/icons-material/Check'
import { useTheme, Tooltip } from '@mui/material'
import { alpha } from '@mui/material/styles'
import { Identifier, useListContext } from 'react-admin'
import { useNavigate } from 'react-router-dom'

import { DataAvatar } from 'components'
import { useCreateResourcePath } from 'hooks'

import { DataAvatarProps } from '../DataAvatar'

export interface DataCardAvatarProps extends Omit<DataAvatarProps, 'id'> {
    id: Identifier
    disableLink?: boolean
    disable?: boolean
    titleOnDisabled?: string
    nonSelectable?: string
    to?: string
}

const DataCardAvatar = ({
    imageSrc,
    defaultImage,
    avatarOpacity,
    id,
    color,
    disableLink,
    disable,
    titleOnDisabled,
    nonSelectable,
    to,
    ...rest
}: DataCardAvatarProps) => {
    const createPath = useCreateResourcePath()
    const navigate = useNavigate()

    const theme = useTheme()
    const { selectedIds, onSelect } = useListContext()
    const isSelectedId = selectedIds.includes(id)

    const cardEditViewPath = createPath({
        id,
        type: 'edit',
    })

    if ((disable || nonSelectable) && selectedIds.length) {
        const content = (
            <DataAvatar
                sx={{ cursor: 'pointer' }}
                imageSrc={imageSrc}
                color={theme.palette.text.primary}
                defaultImage={
                    <CheckIcon
                        sx={(theme) => ({
                            color: `${alpha(theme.palette.text.primary, 0.26)} !important`,
                        })}
                    />
                }
                avatarOpacity={avatarOpacity}
                {...rest}
            />
        )
        if (nonSelectable || titleOnDisabled) {
            return (
                <Tooltip
                    placement="bottom-end"
                    title={nonSelectable || titleOnDisabled}
                    enterTouchDelay={0}
                >
                    {content}
                </Tooltip>
            )
        }
        return <>{content}</>
    }
    return (
        <DataAvatar
            sx={{
                cursor: disableLink ? null : 'pointer',
                display: defaultImage || selectedIds.length ? 'flex' : 'none',
            }}
            onClick={() => {
                if (!disableLink && !selectedIds.length) {
                    navigate(to || cardEditViewPath)
                    return
                }

                const ids = isSelectedId
                    ? selectedIds.filter((ids) => ids !== id)
                    : [...selectedIds, id]
                if (selectedIds.length !== 0) {
                    onSelect(ids)
                }
            }}
            imageSrc={selectedIds.length === 0 ? imageSrc : ''}
            defaultImage={
                selectedIds.length === 0 ? (
                    defaultImage
                ) : (
                    <CheckIcon
                        sx={(theme) => ({
                            color: isSelectedId
                                ? 'white !important'
                                : `${alpha(theme.palette.text.primary, 0.54)} !important`,
                        })}
                    />
                )
            }
            color={
                color ||
                (selectedIds.length === 0
                    ? ''
                    : isSelectedId
                    ? theme.palette.primary.main
                    : theme.palette.text.primary)
            }
            avatarOpacity={isSelectedId ? 1 : avatarOpacity}
            {...rest}
        />
    )
}
export default DataCardAvatar

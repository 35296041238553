import { IntervalsType, UnitPMReminderModel, UnitPMIntervalTypes } from 'appTypes/models'
import { BoxContainer, StyledElement, Typography } from 'components'
import { getMeterTypeAdornment } from 'pages/Units/Meters/constants'
import { formatDate, resolveIntegerSpacedMask } from 'utils'

interface ServiceSectionProps {
    selectedPM: IntervalsType
    unitPmRecord: UnitPMReminderModel
}
export const getIntervalUnitOfMeasure = (value: number, type: UnitPMIntervalTypes) => {
    if (type === 'ENGINE_HOURS') {
        return `${value} h`
    }
    return `${value} mi`
}
const ServiceSection = ({ selectedPM, unitPmRecord }: ServiceSectionProps) => {
    return (
        <StyledElement sx={{ paddingTop: '12px' }}>
            <BoxContainer justifyContent="space-between">
                <Typography
                    variant="tooltip"
                    color="text.disabled"
                >
                    LAST SERVICE
                </Typography>
                <Typography
                    variant="tooltip"
                    color="text.disabled"
                >
                    NEXT SERVICE
                </Typography>
            </BoxContainer>
            <BoxContainer justifyContent="space-between">
                <Typography
                    variant="body2"
                    color="text.primary"
                >
                    {selectedPM.type === 'TIME'
                        ? formatDate(
                              unitPmRecord.lastDone,
                              (dateFormats) => dateFormats.shortenedDate,
                          )
                        : `${resolveIntegerSpacedMask(
                              selectedPM.lastDoneMeterValue,
                          )} ${getMeterTypeAdornment(selectedPM.type)}`}
                </Typography>
                <Typography
                    variant="body2"
                    color="text.primary"
                >
                    {selectedPM.type === 'TIME'
                        ? formatDate(selectedPM.overdue, (dateFormats) => dateFormats.shortenedDate)
                        : `${resolveIntegerSpacedMask(selectedPM.overdue)} ${getMeterTypeAdornment(
                              selectedPM.type,
                          )}`}
                </Typography>
            </BoxContainer>
        </StyledElement>
    )
}
export default ServiceSection

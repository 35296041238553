import api, { CApi } from 'api'

import config from '../configs/config'

export class PushManagement {
    loading = 0

    pushSubscription: PushSubscription | null = null

    pushManager: PushManager | null = null

    private api: CApi

    constructor(api: CApi) {
        this.api = api
    }

    // this.setPushSubscription is undefined sometimes ???
    pushSubscribe() {
        return this.pushManager!.subscribe({
            userVisibleOnly: true,
            applicationServerKey: config.VAPID_PUBLIC_KEY,
        }).then((sub) => {
            this.setPushSubscription && this.setPushSubscription(sub)
            return sub
        })
    }

    pushUnsubscribe() {
        return this.pushSubscription!.unsubscribe().then((success) => {
            this.setPushSubscription && this.setPushSubscription(null)
            return success
        })
    }

    setPushManager(pushManager) {
        this.pushManager = pushManager
        if (pushManager) {
            pushManager.getSubscription().then((sub) => {
                this.setPushSubscription && this.setPushSubscription(sub)
            })
        }
    }

    setPushSubscription(sub) {
        if (this.pushSubscription && this.pushSubscription !== sub) {
            this.api.webpushUnsubscribe(this.pushSubscription)
        }
        this.pushSubscription = sub
        if (sub) {
            this.api.webpushSubscribe(sub)
        }
    }
}

const pushManagement = new PushManagement(api)
export default pushManagement

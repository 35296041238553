import { useArchivedContext } from 'components/context'
import { basePermissions } from 'configs/constants'
import { ArchiveOneParams, useArchiveOne, useResourcePermissions } from 'hooks'
import { hasPermission } from 'utils'

import { ActionChildren } from '../Actions'

import { archiveActionBaseOptions } from './archiveActionCommon'

interface ArchiveOneActionProps extends ArchiveOneParams {
    children: ActionChildren
}

export const ArchiveOneAction = ({ children, ...rest }: ArchiveOneActionProps) => {
    const archiveOne = useArchiveOne()
    const isArchived = useArchivedContext()
    const permissions = useResourcePermissions(rest.resource)
    // TODO: refactor this.
    if (
        !hasPermission(
            isArchived
                ? permissions[basePermissions.unarchive]
                : permissions[basePermissions.archive],
        )
    ) {
        return null
    }

    return children({
        disabled: false,
        onClick: () => {
            archiveOne(rest)
        },
        ...archiveActionBaseOptions(isArchived),
    })
}

export const archiveOneAction = (params: ArchiveOneActionProps) => (
    <ArchiveOneAction
        {...params}
        key="archive-one"
    />
)

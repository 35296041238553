import { ServerErrors } from 'appTypes'
import { ResourceType } from 'components'
import { ConfirmConfig } from 'components/Confirm'
import { useResource } from 'components/resource'
import { useNotify } from 'hooks'

import useConfirm from './useConfirm'
import { useFinalErrorHandler } from './useErrorHandler'

export interface UseDeleteParams {
    confirmConfig?: Partial<Omit<ConfirmConfig, 'onConfirm'>>

    notifyMessage?: string

    onConfirm?: (params: {
        resource: ResourceType
        onSuccess: () => void
        onError: (err: ServerErrors) => void
    }) => Promise<void>

    resource?: ResourceType
}

const useDelete = () => {
    const confirm = useConfirm()
    const notify = useNotify()
    const resourceFromContext = useResource()
    const errorHandler = useFinalErrorHandler()

    const deleteResource = ({
        confirmConfig,
        notifyMessage = 'Successfully deleted!',
        onConfirm,
        resource,
    }: UseDeleteParams) => {
        confirm({
            title: 'Are you sure?',
            ...confirmConfig,
            onConfirm: async () => {
                await onConfirm({
                    resource: resource || resourceFromContext,
                    onSuccess: () => {
                        notify(notifyMessage, {
                            type: 'success',
                        })
                    },
                    onError: (err) => {
                        errorHandler(err)
                    },
                })
            },
            confirmType: 'DELETE',
        })
    }

    return deleteResource
}

export default useDelete

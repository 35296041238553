import { ReactElement } from 'react'

import { Identifier, useListContext } from 'react-admin'

import { VendorContactModel } from 'appTypes/models'
import { useOpenUtilityDrawer, useResource } from 'components'
import { UtilityDrawerEditorProps } from 'components/Drawer/UtilityDrawerEditor'
import UtilityDrawerListEditor from 'components/Drawer/UtilityDrawerListEditor'
import { basePermissions } from 'configs/constants'
import { renderOnPermission } from 'hocs'
import { Serializer } from 'utils'

import { VendorContactForm } from '.'

const contactSerializer: Serializer<VendorContactModel> = [
    'name',
    { name: 'email', parse: 'email' },
    'phone',
]

interface VendorContactDrawerTogglerProps extends Pick<UtilityDrawerEditorProps, 'onSuccess'> {
    children: (params: { onClick: () => void }) => ReactElement
    id?: Identifier
}

const VendorContactDrawerToggler = renderOnPermission(
    ({ children, onSuccess, id }: VendorContactDrawerTogglerProps) => {
        const open = useOpenUtilityDrawer()
        const resource = useResource()
        const listContext = useListContext()

        return children({
            onClick: () => {
                open({
                    extraArgs: {
                        id,
                        resource,
                        listContext,
                    },
                    drawerArgs: {
                        title: id ? 'Edit Contact Person' : 'Add Contact Person',
                        renderWrapper: (params) => (
                            <UtilityDrawerListEditor
                                {...params}
                                onSuccess={onSuccess}
                                serializer={contactSerializer}
                            />
                        ),
                        renderContent: () => <VendorContactForm />,
                        renderBottomRight: (renderButton) =>
                            renderButton({
                                label: id ? undefined : 'Add Contact Person',
                            }),
                    },
                })
            },
        })
    },
    ({ props, permissions }) =>
        props.id ? permissions[basePermissions.update] : permissions[basePermissions.create],
    'vendor-contacts',
)

export default VendorContactDrawerToggler

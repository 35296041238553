import { WorkOrderModel } from 'appTypes/models'
import { InfoCard, InfoCardDetails } from 'components'
import { meterTypesConfig } from 'pages/Units/Meters/constants'
import { dateReadableFormat } from 'utils'

const DescriptionCreatenOnBy = () => {
    return (
        <InfoCard>
            <InfoCardDetails<WorkOrderModel>
                details={[
                    {
                        label: 'Meter Type',
                        source: ({ meterType }) => meterTypesConfig[meterType]?.name,
                    },
                    {
                        label: 'Meter Value',
                        source: 'meterValue',
                    },
                    {
                        label: 'Created on',
                        source: ({ created }) => dateReadableFormat(created),
                    },
                    {
                        label: 'Created By',
                        source: ({ createdBy }) => createdBy?.name || createdBy?.email,
                    },
                ]}
            />
        </InfoCard>
    )
}

export default DescriptionCreatenOnBy

import { IconButton, SvgIcon, Tooltip } from '@mui/material'
import { Link } from 'react-router-dom'

import { BaseActionRenderParams } from 'components/actions'

export const HeaderDesktopAction = ({
    Icon,
    title = '',
    to,
    onClick,
    disabled,
}: BaseActionRenderParams) => {
    const content = (
        <Tooltip title={title}>
            <span role="none">
                <IconButton
                    aria-label={typeof title === 'string' ? title : 'Action'}
                    size="small"
                    disabled={disabled}
                    onClick={disabled ? undefined : onClick}
                    sx={{ color: (theme) => theme.palette.text.primary }}
                >
                    <SvgIcon
                        inheritViewBox
                        component={Icon}
                        sx={{ width: '20px', height: '20px' }}
                    />
                </IconButton>
            </span>
        </Tooltip>
    )

    if (to && !disabled) {
        return <Link to={to}>{content}</Link>
    }

    return content
}
export default HeaderDesktopAction

import { alpha, formHelperTextClasses, TextFieldProps, styled } from '@mui/material'
import { FilterLiveSearch as RaFilterLiveSearch } from 'react-admin'

import { filterSearchText } from 'configs/constants'
import { useCreateInputId } from 'hooks'

const source = filterSearchText
const FilterLiveSearch = styled((props: TextFieldProps) => {
    const createId = useCreateInputId()

    return (
        <RaFilterLiveSearch
            /* @ts-ignore react-admin-wrong-types */
            variant="outlined"
            source={source}
            placeholder="Search"
            InputLabelProps={{
                shrink: true,
            }}
            {...props}
            id={createId({ source, id: props.id })}
        />
    )
})(({ theme }) => ({
    [`& .${formHelperTextClasses.root}`]: {
        display: 'none',
    },
    'legend *': {
        display: 'none !important',
    },
    label: {
        display: 'none !important',
    },
    'input::placeholder': {
        color: theme.palette.text.primary,
        opacity: 1,
    },
    svg: {
        color: alpha(theme.palette.grey[900], 0.54),
    },
}))

export default FilterLiveSearch

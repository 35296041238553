import { useState } from 'react'

import { observer } from 'mobx-react'
import { useListContext } from 'react-admin'

import api from 'api'
import { LoadingSpinner } from 'components'
import { useAskConfirm } from 'components/form/FormWarnWhenUnsavedChanges'
import { hasIntegrationActiveTasks } from 'components/telematics/hooks/useTelematics'
import { useFinalErrorHandler, useNotify, useSetBlocker } from 'hooks'
import { startProviderTask } from 'pages/Configure/Integrations/Integrations'
import { objectIsEmpty } from 'utils'

import { useSyncedStateContext } from '../../utils/SyncedStateContext'

import FooterSaveButton, { FooterSaveButtonProps } from './FooterSaveButton'

interface ManageFooterSaveButtonProps extends Omit<FooterSaveButtonProps, 'onClick'> {
    provider: string
}
const ManageFooterSaveButton = observer(({ provider, ...props }: ManageFooterSaveButtonProps) => {
    const hasTasks = hasIntegrationActiveTasks(provider)
    const { synced, reset } = useSyncedStateContext()
    const isEmpty = objectIsEmpty(synced)
    const notify = useNotify()
    const [loading, setLoading] = useState(false)
    const errorHandler = useFinalErrorHandler()
    const { refetch } = useListContext()

    const onClick = () => {
        setLoading(true)

        api.patch('telematics/' + provider, {
            vehiclesSyncStatus: synced,
        })
            .then(async () => {
                await refetch()
                startProviderTask(provider)
                notify('Changes updated successfully', {
                    type: 'success',
                })
                reset()
            })
            .catch((err) => {
                errorHandler(err, {
                    fallbackError: 'Failed to update changes',
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const askConfirm = useAskConfirm()

    useSetBlocker(
        {
            preventNavigate: (action, { resume }) => {
                askConfirm(() => {
                    resume()
                })
            },
            allowSamePageNavigate: true,
        },
        {
            isOpen: !isEmpty,
        },
    )

    if (hasTasks || loading) {
        return (
            <FooterSaveButton
                key="sync-button"
                {...props}
                disabled
                children="Sync in Progress"
                startIcon={<LoadingSpinner color="inherit" />}
            />
        )
    }

    return (
        <FooterSaveButton
            key="active-button"
            {...props}
            disabled={isEmpty}
            onClick={onClick}
        />
    )
})

export default ManageFooterSaveButton

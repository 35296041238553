import { Billing } from 'appTypes/models'
import { StyledElement } from 'components'

import { ProPlan, EarlyAccess } from './Plans'
import { EarlyAccessProps } from './Plans/EarlyAccess/EarlyAccess'

export interface PlanBodyProps extends EarlyAccessProps {
    subscription: Billing['subscription']
}

const PlanBody = ({ buttonProps, subscription }: PlanBodyProps) => {
    return (
        <StyledElement sx={{ mt: '20px' }}>
            {subscription === 'free' ? <EarlyAccess buttonProps={buttonProps} /> : <ProPlan />}
        </StyledElement>
    )
}

export default PlanBody

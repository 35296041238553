import { UnitModel } from 'appTypes/models'
import {
    CollapsibleContent,
    CollapsibleTogglerAction,
    InfoCardDetails,
    infoCardDetailsGap,
    InfoCardHeader,
    CollapsibleInfoCard,
} from 'components'

const Specifications = () => {
    return (
        <CollapsibleInfoCard>
            <InfoCardHeader
                title="Specifications"
                action={<CollapsibleTogglerAction />}
            />
            <InfoCardDetails<UnitModel>
                details={[
                    {
                        label: 'Equipment',
                        source: ({ vmrsEquipmentCategoryData }) =>
                            vmrsEquipmentCategoryData?.description,
                    },
                    {
                        label: 'Manufacturer',
                        source: ({ vmrsManufacturerData }) => vmrsManufacturerData?.description,
                    },
                    {
                        label: 'Model',
                        source: 'model',
                    },
                ]}
            />
            <CollapsibleContent>
                <InfoCardDetails<UnitModel>
                    mt={infoCardDetailsGap}
                    details={[
                        {
                            label: 'Model Year',
                            source: 'modelYear',
                        },
                        {
                            label: 'Color',
                            source: 'color',
                        },
                        {
                            label: 'Tire Size',
                            source: 'tireSize',
                        },
                    ]}
                />
            </CollapsibleContent>
        </CollapsibleInfoCard>
    )
}

export default Specifications

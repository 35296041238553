import { inject, observer } from 'mobx-react'
import { Identifier } from 'react-admin'

import { SortType } from 'appTypes'
import { CK33Levels, CK33Model } from 'appTypes/models'
import {
    CardListConfig,
    DatagridColumnsProps,
    List,
    ListBase,
    PageContent,
    TelematicsType,
    formatCK33CodeByLevel,
    useTelematics,
    getCk33Info,
    GetCk33InfoArgs,
    PhotoButton,
} from 'components'
import { ListSortContentProps } from 'components/list/ListSortDrawerContent'
import ListFilterDateRangeValueInput from 'components/list/filter/ListFilterDateRangeValueInput'
import { FilterConfig } from 'components/list/filter/ListFilterForm'
import ListFilterValueInput, {
    prepareFilterResource,
} from 'components/list/filter/ListFilterValueInput'
import ResourceContextProviderWithClearEffect from 'components/resource/ResourceContextProviderWithClearEffect'
import { urls } from 'configs/config'
import { UnitDefect } from 'pages/Units/DVIR/UnitDVIR'
import { UnitDVIRNoData, UnitDVIRNoIntegration } from 'pages/Units/DVIR/UnitDvirFTU'
import { dvirResource } from 'pages/Units/config/constants'
import { AuthStore } from 'providers/authStore'
import { formatDate, capitalizeWithLowerCase } from 'utils'

import { ReportsHeader } from '../components'
import LinkArrowButton from '../components/LinkArrowButton'
import LinkCardButton from '../components/LinkCardButon'

import DVIRStatus from './components/DVIRStatus'

interface DVERsData extends Pick<UnitDefect, 'authorName' | 'id'> {
    location: string
    status: string
    timestamp: string
    type: string
}
export interface DVIR extends UnitDefect {
    unit: 'string'
    isResolved: boolean
    dvirsData: DVERsData[]
    unitData: {
        id: Identifier
        number: 'string'
    }
    inProgress: string
    workOrder: string
    dvirStatus: string
    dvirTimestamp: string
    dvirType: string
}
const defaultSort: SortType<DVIR & { inProgress: string }> = {
    field: 'inProgress',
    order: 'ASC',
}

// Handle Inspection Types words that are semi-fused and come from the API in camel case.
// For example, the API returns 'preTrip,' but it needs to be 'Pre-trip.
const handleInspectionTypeFormating = (type: string) => {
    if (type.endsWith('TRIP')) {
        return capitalizeWithLowerCase(type.replaceAll('_', '-'))
    }
    return capitalizeWithLowerCase(type)
}

export const dvirCk33Label = (args: GetCk33InfoArgs) =>
    getCk33Info(args, CK33Levels.COMPONENT).label

export const DvirCk33Label = inject('auth')(({ auth }: { auth?: AuthStore }) => (
    <>{dvirCk33Label(auth)}</>
))
export const unitSortCfg: ListSortContentProps<DVIR> = {
    sortBy: [
        { id: 'name', label: 'Name' },
        { id: 'unit', label: 'Unit Number' },
        { id: 'detectedOn', label: 'Detected on' },
        { id: 'component', label: <DvirCk33Label /> },
        {
            id: 'workOrder',
            label: 'WOs Attached',
        },
        { id: 'photo', label: 'Photos' },
        { id: 'dvirStatus', label: 'DVIR Status' },
        {
            id: 'dvirTimestamp',
            label: 'DVIR Date',
        },
        { id: 'dvirType', label: 'Inspection Type' },
        { id: 'inProgress', label: 'In Progress' },
    ],
}

const filtersCfg: FilterConfig<
    DVIR & {
        inProgress: string
        workOrder: string
        dvirStatus: string
        dvirTimestamp: string
        dvirType: string
    }
> = {
    filters: [
        { id: 'name', label: 'Name' },
        { id: 'unit', label: 'Unit Number' },
        {
            id: 'detectedOn',
            label: 'Detected on',
            filterType: 'range',
            renderComponent: (props) => <ListFilterDateRangeValueInput {...props} />,
        },
        {
            id: 'component',
            label: <DvirCk33Label />,
            renderComponent: (props) => (
                <ListFilterValueInput
                    dataProviderProps={{
                        getList: {
                            prepareResource: prepareFilterResource,
                        },
                        getMany: {
                            prepareResource: () => 'vmrs/ck33',
                        },
                    }}
                    {...props}
                    makeItemLabel={(record: CK33Model) =>
                        formatCK33CodeByLevel(record.code, record.level) + ' ' + record.text
                    }
                />
            ),
        },
        {
            id: 'workOrder',
            label: 'WOs Attached',
        },

        { id: 'photo', label: 'Photos' },
        {
            id: 'dvirStatus',
            label: 'DVIR Status',
            renderComponent: (props) => (
                <ListFilterValueInput
                    {...props}
                    inputText={(option) => option.id}
                    makeItemLabel={(record) => <DVIRStatus>{record.id as string}</DVIRStatus>}
                />
            ),
        },
        {
            id: 'dvirTimestamp',
            label: 'DVIR Date',
            filterType: 'range',
            renderComponent: (props) => <ListFilterDateRangeValueInput {...props} />,
        },
        {
            id: 'dvirType',
            label: 'Inspection Type',
            renderComponent: (props) => (
                <ListFilterValueInput
                    {...props}
                    inputText={(option) => option.id}
                    makeItemLabel={(record) => handleInspectionTypeFormating(record.id as string)}
                />
            ),
        },
        {
            id: 'inProgress',
            label: 'In Progress',
        },
    ],
}

const isConnected = (telematics: TelematicsType) => {
    for (const key in telematics) {
        if (telematics[key].isActive) {
            return true
        }
    }
    return false
}
type CardDVIR = DVIR & {
    inProgress: string
    workOrdersAttached: string
    dvirStatus: string
    dvirDate: string
    inspectionType: string
}
const recordToLink = (record: CardDVIR) => `${urls.units}/${record.unit}/dvir`
const Dvirs = inject('auth')(
    observer(({ auth }: { auth?: AuthStore }) => {
        const { telematics, list } = useTelematics()
        if (list.isLoading) {
            return null
        }
        if (!isConnected(telematics)) {
            return <UnitDVIRNoIntegration archived={undefined} />
        }

        const cardsCfg: CardListConfig<DVIR & CardDVIR> = {
            titleSource: (record) => record.name || '-',
            titleLink: (record) => recordToLink(record),
            defaultImage: null,
            details: [
                {
                    source: 'unit',
                    label: 'Unit Number',
                    render: (value, record) => (
                        <LinkCardButton
                            text={record.unitData.number}
                            to={`${urls.units}/${value}`}
                        />
                    ),
                },
                {
                    source: 'detectedOn',
                    label: 'Detected on',
                    render: (value) =>
                        formatDate(value, (dateFormats) => dateFormats.shortenedDateTime),
                },
                {
                    source: 'component',
                    label: dvirCk33Label(auth),
                    render: (value, { componentData }) =>
                        componentData?.code
                            ? `${formatCK33CodeByLevel(componentData.code, componentData.level)} ${
                                  componentData.text
                              }`
                            : '',
                },
                {
                    source: 'workOrdersAttached',
                    label: 'WOs Attached',
                    render: (value, { workOrderData }) =>
                        workOrderData && (
                            <LinkCardButton
                                text={workOrderData.number}
                                to={`${urls.workOrders}/${workOrderData.id}`}
                            />
                        ),
                },
                {
                    source: 'driverComment',
                    label: 'Comments',
                },
                {
                    source: 'photo',
                    label: 'Photos',
                    render: (value) => (value ? <PhotoButton files={[value]} /> : null),
                },
                {
                    source: 'dvirStatus',
                    label: 'DVIR Status',
                    render: (value, { dvirsData }) => (
                        <DVIRStatus>{dvirsData[dvirsData.length - 1]?.status}</DVIRStatus>
                    ),
                },
                {
                    source: 'dvirDate',
                    label: 'DVIR Date',
                    render: (value, { dvirsData }) =>
                        formatDate(
                            new Date(dvirsData[dvirsData.length - 1]?.timestamp),
                            (dateFormats) => dateFormats.shortenedDateTime,
                        ),
                },
                {
                    source: 'inspectionType',
                    label: 'Inspection Type',
                    render: (value, { dvirsData }) =>
                        handleInspectionTypeFormating(dvirsData[dvirsData.length - 1].type),
                },
                {
                    source: 'inProgress',
                    label: 'In Progress',
                    render: (v, record) => (record.workOrderData ? 'Yes' : 'No'),
                },
            ],
            action: (record) => <LinkArrowButton path={recordToLink(record)} />,
        }

        const columnsCfg: DatagridColumnsProps<
            DVIR & {
                workOrdersAttached: string
                dvirStatus: string
                dvirDate: string
                inspectionType: string
                inProgress: string
            }
        > = {
            checkboxSelection: false,
            columns: [
                {
                    field: 'name',
                    headerName: 'Name',
                    renderCell: ({ row, value }) => (
                        <LinkCardButton
                            text={value}
                            to={`${urls.units}/${row.unit}/dvir`}
                        />
                    ),
                },
                {
                    field: 'unit',
                    headerName: 'Unit Number',
                    renderCell: ({ row }) => (
                        <LinkCardButton
                            text={row.unitData.number}
                            to={`${urls.units}/${row.unit}`}
                        />
                    ),
                },
                {
                    field: 'detectedOn',
                    headerName: 'Detected on',
                    valueGetter: ({ value }) =>
                        formatDate(value, (dateFormats) => dateFormats.shortenedDateTime),
                },
                {
                    field: 'component',
                    headerName: dvirCk33Label(auth),
                    valueGetter: ({ row }) =>
                        row.componentData?.code
                            ? `${formatCK33CodeByLevel(
                                  row.componentData.code,
                                  row.componentData.level,
                              )} ${row.componentData.text}`
                            : '',
                },

                {
                    field: 'workOrdersAttached',
                    headerName: 'WOs Attached',
                    renderCell: ({ row }) =>
                        row.workOrderData && (
                            <LinkCardButton
                                text={row.workOrderData.number}
                                to={`${urls.workOrders}/${row.workOrderData.id}`}
                            />
                        ),
                },
                {
                    field: 'driverComment',
                    headerName: 'Comments',
                },
                {
                    field: 'photo',
                    headerName: 'Photos',
                    renderCell: ({ value }) => (value ? <PhotoButton files={[value]} /> : null),
                },
                {
                    field: 'dvirStatus',
                    headerName: 'DVIR Status',
                    renderCell: ({ row }) => (
                        <DVIRStatus>{row.dvirsData[row.dvirsData.length - 1]?.status}</DVIRStatus>
                    ),
                },
                {
                    field: 'dvirDate',
                    headerName: 'DVIR Date',
                    valueGetter: ({ value, row }) =>
                        formatDate(
                            row.dvirsData[row.dvirsData.length - 1]?.timestamp,
                            (dateFormats) => dateFormats.shortenedDateTime,
                        ),
                },
                {
                    field: 'inspectionType',
                    headerName: 'Inspection Type',
                    valueGetter: ({ value, row }) =>
                        handleInspectionTypeFormating(row.dvirsData[row.dvirsData.length - 1].type),
                },
                {
                    field: 'inProgress',
                    headerName: 'In Progress',
                    valueGetter: ({ row }) => (row.workOrderData ? 'Yes' : 'No'),
                },
            ],
            actions: null,
        }

        return (
            <ResourceContextProviderWithClearEffect value={dvirResource}>
                <ListBase sort={defaultSort}>
                    <ReportsHeader>Unresolved Defects</ReportsHeader>
                    <PageContent>
                        <List
                            renderNoResults={() => <UnitDVIRNoData />}
                            exportFileName="defects-report"
                            filtersCfg={filtersCfg}
                            sortCfg={unitSortCfg}
                            columnsCfg={columnsCfg}
                            cardsCfg={cardsCfg}
                        />
                    </PageContent>
                </ListBase>
            </ResourceContextProviderWithClearEffect>
        )
    }),
)

export default Dvirs

import { FC } from 'react'

import { alpha } from '@mui/material'

import { UnitModel } from 'appTypes/models'
import { BoxContainer, EllipsisOverflow, Spacer, Typography } from 'components'

import UnitDeactivatedBadge from './UnitDeactivatedBadge'
import UnitIntegrationsStatusPopper from './UnitIntegrationsStatusPopper'

const TitleWithTelematicsStatus: FC<{ record: UnitModel; inShow?: boolean }> = ({
    record,
    inShow = false,
}) => {
    return (
        <Spacer
            textOverflow="ellipsis"
            overflow="hidden"
        >
            <EllipsisOverflow>{record.number}</EllipsisOverflow>
            {record.archived && inShow ? (
                <BoxContainer>
                    <Typography
                        color="text.disabled"
                        variant="chartLabel"
                        sx={({ palette }) => ({
                            background: alpha(palette.text.primary, 0.12),
                            borderRadius: '2px',
                            padding: '2px 4px',
                            marginLeft: '4px',
                            marginTop: '2px',
                        })}
                    >
                        ARCHIVED
                    </Typography>
                </BoxContainer>
            ) : record.telematicsData ? (
                <Spacer
                    onClick={(e) => {
                        e.preventDefault()
                    }}
                >
                    {(record.telematicsData.telematicsStatus === 'CONNECTED' ||
                        record.telematicsData.error) && (
                        <UnitIntegrationsStatusPopper telematicData={record.telematicsData} />
                    )}
                    {record.telematicsData.status === 'DEACTIVATED' && (
                        <UnitDeactivatedBadge provider={record.telematicsData.provider} />
                    )}
                </Spacer>
            ) : null}
        </Spacer>
    )
}
export default TitleWithTelematicsStatus

import { inject } from 'mobx-react'

import { Typography, TypographyProps } from 'components'
import { AuthStore } from 'providers/authStore'

import Icons from './icons'

export const getUserInitials = (user: { name: string }) => {
    if (!user.name) {
        return undefined
    }
    const userNameWords = user.name.split(' ')
    const firstInitial = userNameWords[0][0].toUpperCase()
    const lastInitial = (
        userNameWords.length > 1 ? userNameWords[userNameWords.length - 1][0] : ''
    ).toUpperCase()
    return firstInitial + lastInitial
}
interface DefaultUserAvatarInterface {
    auth?: AuthStore
    fontSize?: TypographyProps['fontSize']
    user?: { name: string }
}
const DefaultUserAvatar = inject('auth')(({ auth, fontSize, user }: DefaultUserAvatarInterface) => {
    const initials = getUserInitials(user || auth.user)
    return initials ? (
        <Typography
            color="primary.main"
            fontSize={fontSize}
            lineHeight="100%"
            fontWeight={500}
        >
            {initials}
        </Typography>
    ) : (
        <Icons.PersonOutlined />
    )
})
export default DefaultUserAvatar

import { ReactElement, ReactNode } from 'react'

import { useNotify as useRANotify, NotificationOptions } from 'react-admin'

type SecondArg = NotificationOptions

export type NotificationMessageType = ((...args: any) => ReactElement | string) | string | ReactNode

type UseNotifyType = () => (
    message: NotificationMessageType,
    args?: SecondArg & {
        messageArgs?: { forceDisplay?: boolean }
    },
) => void

const useNotify = () => {
    const notify: ReturnType<UseNotifyType> = useRANotify()
    return (message: NotificationMessageType, args: SecondArg) => {
        notify(message, { ...args, messageArgs: { forceDisplay: true, ...args?.messageArgs } })
    }
}

export default useNotify

import { Box } from '@mui/material'
import { useRecordContext } from 'react-admin'

import { UnitModel } from 'appTypes/models'
import { SliderWidget, SliderWidgetSkeleton } from 'pages/Dashboard/components/WidgetBase/styled'
import { WidgetLineChartBase } from 'pages/Dashboard/components/Widgets/components'
import { resolveDoublePriceMask } from 'utils'

import { Clarification } from './components'

const description = `The accumulated maintenance expenses
 are divided by the total mileage logged, empowering you to spot the trend at once.`
let isValid = false
const UnitCostPerMileWidget = () => {
    const record = useRecordContext<UnitModel>()

    if (!record) {
        return <SliderWidgetSkeleton />
    }
    isValid = typeof record.costPerMile === 'number'

    return (
        <SliderWidget
            headerProps={{
                title: 'Maintenance CPM',
                label: isValid ? resolveDoublePriceMask(record.costPerMile) : null,
                tooltipText: description,
                clarification: <Clarification />,
                chart: (
                    <Box mt="10px">
                        <WidgetLineChartBase
                            data={record.costPerMilePoints ?? []}
                            isValid={isValid}
                            height={95}
                            width={89}
                            margin={{ top: 10, right: 4, bottom: 30, left: 4 }}
                        />
                    </Box>
                ),
            }}
        />
    )
}
export default UnitCostPerMileWidget

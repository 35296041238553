import { inputBaseClasses } from '@mui/material/InputBase'
import { styled } from '@mui/material/styles'

import TextInput, { TextInputProps } from './TextInput'

const TextareaInput = styled((props: TextInputProps) => (
    <TextInput
        rows={3}
        multiline
        {...props}
    />
))`
    .${inputBaseClasses.root} {
        height: 112px;
    }
` as typeof TextInput

export default TextareaInput

import { Chip, ChipProps, styled } from '@mui/material'

import { TagModel } from 'appTypes/models'
import { TagColor, tagColorsObject } from 'configs/constants'

interface TagFieldProps extends ChipProps {
    tag: TagModel
}

const TagField = styled(({ tag, sx, ...props }: TagFieldProps) => {
    return (
        <Chip
            label={tag.name}
            variant="filled"
            size="small"
            {...props}
        />
    )
})<TagFieldProps>(({ tag }) => {
    const tagData = tagColorsObject[tag.color] || ({} as TagColor)

    return { backgroundColor: tagData.bgcolor as string, color: tagData.color }
})

export default TagField

import { PropsWithChildren, ReactNode } from 'react'

import { TooltipProps, Tooltip } from '@mui/material'

import { Typography, TypographyProps } from 'components'
interface PartsSubheaderTooltipProps
    extends Omit<TooltipProps, 'children' | 'title'>,
        PropsWithChildren {
    title?: ReactNode
    typographyProps?: TypographyProps
}

const PartsSubheaderTooltip = ({
    children,
    title,
    typographyProps,
    ...props
}: PartsSubheaderTooltipProps) => {
    return (
        <Typography
            display="block"
            variant="tooltip"
            color="text.disabled"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
            fontSize="12px"
            maxWidth="290px"
            {...typographyProps}
        >
            {children ? (
                <Tooltip
                    placement="bottom-start"
                    title={title || children}
                    {...props}
                >
                    <span>{children}</span>
                </Tooltip>
            ) : (
                children
            )}
        </Typography>
    )
}

export default PartsSubheaderTooltip

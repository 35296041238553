import { Palette } from '@mui/material'

import { BaseJobItemTotals } from '../components/types'
export type ratioDataType = {
    total: number | null
    totalLabor: number
    totalLaborPercent: number
    totalParts: number
    totalPartsPercent: number
}

export type maxTotalCategoriesType = {
    code: string
    description: string
    id: number
    total: number
    totalPercent: number
} & BaseJobItemTotals

export interface ResponseType extends ratioDataType {
    maxTotalCategories: maxTotalCategoriesType[]
}
export interface QueryResponse extends Omit<ResponseType, 'maxTotalCategories'> {
    list: listType
}
export interface listType {
    data: maxTotalCategoriesType[]
    top3Percentage: number
    top3Total: number
    sumOfExcludedCategories: number
}

type Top3CategoryColorProps = keyof Palette['charts']
export const statusColors: Top3CategoryColorProps[] = ['purple', 'orange', 'yellowBody']

import merge from 'lodash/merge'
import { computed, observable, action, makeObservable, runInAction } from 'mobx'

import api, { CApi, LoginWithGoogleArgs } from 'api'
import { RecursivePartial } from 'appTypes'
import { Billing, CompanyModel, UserPreferences } from 'appTypes/models'
import PermissionsModel from 'appTypes/models/PermissionsModel'
import UserModel from 'appTypes/models/UserModel'
import { Flags } from 'hooks'
import { makeid } from 'utils'

import config, { urls } from '../configs/config'

import { dataAndFiles } from './dataProvider'

export interface AuthStoreLogoutParams {
    redirectUrl?: string
}

type UserOnFetch = (user: UserModel | null) => Promise<void>

export class AuthStore {
    initialized: boolean = false

    user: UserModel = null

    currentCompanyId: number = null

    redirectUrl: string = null

    public api: CApi

    public apiToken: string

    public preferences: UserPreferences

    private flags: Flags

    private onInitCallbacks: UserOnFetch[] = []

    constructor(api) {
        this.api = api

        makeObservable(this, {
            user: observable,
            currentCompanyId: observable,
            redirectUrl: observable,
            initialized: observable,
            login: action.bound,
            logout: action.bound,
            setInitialized: action.bound,
            setBilling: action.bound,
            setRedirectUrl: action.bound,
            setCurrentCompanyId: action.bound,
            updateUserPreferences: action.bound,
            setUser: action.bound,
            setApiToken: action.bound,
            acceptInvite: action.bound,
            checkInvite: action.bound,
            currentCompany: computed,
            billing: computed,
            startAsyncTask: action.bound,
            action: action.bound,
            // preferences: observable,   // if observable 'updatePreferences' calls infinite times
            // updatePreferences: action.bound,
        })
    }

    init() {
        const token = localStorage.getItem(config.API_TOKEN_KEY)
        if (token) {
            this.tryToken(token).finally(() => this.setInitialized(true))
        } else {
            this.onInitCallMethods(null)
            this.setInitialized(true)
        }
    }

    onInit(cb: UserOnFetch) {
        this.onInitCallbacks.push(cb)
    }

    private async onInitCallMethods(user: UserModel | null) {
        // TODO: what if some of those promises give error?
        await Promise.all(this.onInitCallbacks.map((cb) => cb?.(user)))
    }

    updateFlags(flags: Flags) {
        this.flags = flags
    }

    setInitialized(value: boolean) {
        this.initialized = value
    }

    setUser(user: UserModel) {
        this.user = user
        const m = this.getMembership()
        this.setCurrentCompanyId(m ? m.company.id : null)
    }

    async tryToken(token: string, rememberMe = false) {
        this._setApiToken(token)
        return this.fetchUser(true)
            .then((user) => {
                this.setApiToken(token, rememberMe)
                return user
            })
            .catch(() => this.setApiToken(null))
    }

    fetchUser(withExtras = false) {
        return this.api.getCurrentUser().then(async (user) => {
            if (withExtras) {
                await this.onInitCallMethods(user)
            }
            if (!user.preferences) {
                user.preferences = { resources: {} }
            }
            if (!user.preferences.resources) {
                user.preferences.resources = {}
            }
            this.setUser(user)
            this.preferences = user.preferences
            return user
        })
    }

    async fetchBilling() {
        if (!this.billing) {
            return
        }
        await this.api.get('/company/billing-info').then((billing: Billing) => {
            this.setBilling(billing)
        })
    }

    setBilling(billing: Billing) {
        this.user.membership.billing = billing
    }

    async setPaymentLimit(amount: number) {
        if (!this.billing) {
            return
        }
        return this.api.post('/company/set-payment-limit', {
            amount,
        })
    }

    async upgradeBillingToProPlan(paymentMethodId: string) {
        if (!this.billing) {
            return
        }
        return this.api.post('/company/upgrade_subscription', {
            paymentMethodId,
        })
    }

    async downgradeSubscription() {
        if (!this.billing) {
            return
        }
        return this.api.post('/company/downgrade_subscription')
    }

    async changePaymentMethod(paymentMethodId: string) {
        if (!this.billing) {
            return
        }
        return this.api.post('/company/change-payment-method', {
            paymentMethodId,
        })
    }

    async register(values: any, dryRun: boolean) {
        return this.api
            .register(values, dryRun ? { params: { isDryRun: 1 } } : undefined)
            .then(async (data: AuthResponse) => {
                if (data.token) {
                    await this.tryToken(data.token, true)
                }
            })
    }

    registerConfirm(key: string) {
        return this.api.registerConfirm(key).then((data: AuthResponse) => {
            this.tryToken(data.token, true)
            return data.token
        })
    }

    async loginWithGoogle(values: LoginWithGoogleArgs, register?: boolean) {
        const data: AuthResponse = await this.api.loginWithGoogle(
            values,
            register ? { params: { isFullSignup: 1 } } : undefined,
        )
        if (data.token) {
            await this.tryToken(data.token, true)
        }
        return data
    }

    login(email: string, password: string, rememberMe = true) {
        return this.api.login(email, password).then(async (data: AuthResponse) => {
            await this.tryToken(data.token, rememberMe)
        })
    }

    async logout({ redirectUrl = urls.login }: AuthStoreLogoutParams = {}) {
        this.setRedirectUrl(redirectUrl)
        return this.api.logout().finally(() => {
            this.setApiToken(null)
        })
    }

    changePassword(oldPassword: string, newPassword: string) {
        return this.api.changePassword(oldPassword, newPassword)
    }

    resetPassword(email: string) {
        return this.api.resetPassword(email)
    }

    async resetPasswordConfirm(password, uid, token) {
        const data: AuthResponse = await this.api.resetPasswordConfirm(password, uid, token)
        await this.tryToken(data.token, true)
        return data
    }

    updateUser(params: { data: Partial<UserModel> }): Promise<UserModel> {
        const data = dataAndFiles(params)

        return this.api.patch('/user', data).then((data) => {
            this.setUser(data)
            return data
        })
    }

    saveCompany(params: { data: Partial<CompanyModel> }): Promise<CompanyModel> {
        const data = dataAndFiles(params)
        return this.api.patch('/company', data).then((resp) => {
            this.updateMembership(resp)
            return resp
        })
    }

    setCurrentCompanyId(id: number): void {
        this.currentCompanyId = id
    }

    setRedirectUrl(url: string): void {
        this.redirectUrl = url
    }

    updateMembership(company: CompanyModel) {
        const membership = this.user.membership

        runInAction(() => {
            membership.company = company
        })
    }

    get permissions(): PermissionsModel {
        return this.getMembership?.().permissions
    }

    getMembership(): UserModel['membership'] {
        return this.user?.membership
    }

    get currentCompany(): CompanyModel {
        return this.getMembership()?.company
    }

    get billing(): Billing {
        if (!this.flags.useBilling) {
            return null
        }
        return this.getMembership()?.billing
    }

    get userEmail(): string {
        return this.user?.email
    }

    get userPreferences(): UserPreferences {
        return this.user?.preferences
    }

    get userPhoneNumber(): string {
        return this.user?.phone
    }

    get userFullName(): string {
        const u = this.user
        if (!u) {
            return ''
        }
        return u.name || '' || this.userEmail
    }

    setApiToken(apiToken: string, rememberMe = true) {
        if (apiToken && this.apiToken === apiToken) {
            return
        }
        this.apiToken = apiToken
        if (!apiToken || rememberMe) {
            localStorage.setItem(config.API_TOKEN_KEY, apiToken)
        }
        this._setApiToken(apiToken)
    }

    _setApiToken(apiToken: string) {
        if (!apiToken) {
            localStorage.removeItem(config.API_TOKEN_KEY)
            this.api.clearAuthKey()
            this.api.setUnauthenticatedHandler(null)
            this.setUser(null)
            return
        }
        this.api.setAuthKey(apiToken)
        this.api.setUnauthenticatedHandler(() => {
            this.api.cancelPendingRequest()
            this.setApiToken(null)
        })
    }

    checkInvite(token: string) {
        return this.api.checkInvite(token)
    }

    acceptInvite(token: string, password: string, name: string) {
        return this.api.acceptInvite(token, password, name).then((data) => {
            this.tryToken(data.token, true)
            return data.token
        })
    }

    deactivateCompany() {
        return this.api.delete('/company')
    }

    updateUserPreferences(preferences: UserPreferences) {
        this.preferences = preferences
    }

    async updatePreferences(data: RecursivePartial<UserPreferences>) {
        const preferences = merge(this.preferences, data)

        const user: UserModel = await this.api.put('/user', {
            preferences,
        })

        this.updateUserPreferences(user.preferences)
    }

    getUserPreferencesByResource(resource: string) {
        if (!this.preferences.resources[resource]) {
            this.preferences.resources[resource] = {}
        }

        return this.preferences.resources[resource]
    }

    updateLocalUserPreferencesByResource<
        ResourceName extends keyof UserPreferences['resources'],
        KeyName extends keyof UserPreferences['resources'][ResourceName],
        Value extends UserPreferences['resources'][ResourceName][KeyName],
    >(resource: ResourceName, key: KeyName, value: Value) {
        if (!this.preferences.resources[resource]) {
            this.preferences.resources[resource] = {}
        }

        this.preferences.resources[resource][key] = value
    }

    async syncPreferences() {
        return this.api.put('/user', {
            preferences: this.preferences,
        })
    }

    startAsyncTask(name = makeid()) {
        this.user.activeTasks.push(name)
        this.currentCompany.activeTasks.push(name)
    }

    action(cb: () => void) {
        cb()
    }
}

export default new AuthStore(api)

export type AuthResponse = { token: string }

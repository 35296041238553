import { Alert, Link } from '@mui/material'

import { Section, SectionTitle } from 'components'
import { supportEmail } from 'configs/config'

const DeleteCompanyAccountSection = () => {
    return (
        <Section>
            <SectionTitle>Delete Company Account</SectionTitle>

            <Alert
                variant="standard"
                severity="info"
            >
                If you want to permanently delete your company account, please contact us at
                <Link href={`mailto:${supportEmail}`}> {supportEmail}</Link>
            </Alert>
        </Section>
    )
}

export default DeleteCompanyAccountSection

import Header from 'components/pages/header'

const DashboardHeader = () => {
    return (
        <Header bgColor="transparent">
            <Header.Block>
                <Header.Main>
                    <Header.Title>Dashboard</Header.Title>
                </Header.Main>
            </Header.Block>
        </Header>
    )
}

export default DashboardHeader

import { isValidElement, ReactElement } from 'react'

import { PercentOutlined, SvgIconComponent, WorkOutlineOutlined } from '@mui/icons-material'
import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined'
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined'
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined'
import { Theme, Divider, SvgIcon } from '@mui/material'
import { useListContext, ListControllerResult, useShowContext, useRecordContext } from 'react-admin'

import {
    WorkOrderModel,
    WOJobItem,
    WOJobItemModels,
    VendorModel,
    JobModel,
    WOJobItemTypes,
} from 'appTypes/models'
import { ReactComponent as FeeIcon } from 'assets/images/fee.svg'
import {
    BoxContainer,
    CollapsibleContent,
    Columns,
    formatCK33CodeByLevel,
    ResourceType,
    useResource,
    UtilityDrawerOpen,
    WithListContext,
    Typography,
    Button,
} from 'components'
import { ActionsMenu, UtilityDrawerAction } from 'components/actions'
import Icons from 'components/icons/'
import { basePermissions } from 'configs/constants'
import { useWoActionIsDisabled } from 'pages/WorkOrders/hooks'
import { resolveDoublePriceMask } from 'utils'

import { WorkOrderJobCardProps } from '../../WorkOrderJobCard'
import WorkOrderJobCardSummary from '../WorkOrderJobCardSummary'

import { editLaborActionExtraArgs, editPartActionExtraArgs, editVWOItemDrawerArgs } from './drawers'
import { AddButtonWrapper, ItemRow, ItemSection, ItemSubtitle } from './styled'

const iconSxProps = {
    height: '16px',
    width: '16px',
    verticalAlign: 'middle',
    paddingBottom: '1px',
    paddingRight: '8px',
    cursor: 'pointer',
}

interface WorkOrderJobCardItemsProps {
    itemActions: WorkOrderJobCardProps['itemActions']
}

export type WorkOrderItemsMapType = {
    [key in keyof WOJobItemModels]: (
        record: WOJobItemModels[key],
        listContext: ListControllerResult,
        refetch: () => void,
        resource: ResourceType,
        disabled: boolean,
        vendor: Pick<VendorModel, 'id' | 'name'> | null,
    ) => {
        icon: SvgIconComponent | ReactElement
        total: (() => number) | number
        totalCaption?: (() => string) | string
        subtitle: string
        action: (open: UtilityDrawerOpen) => void
    }
}
export const useJobItemTranslate = () => {
    const { refetch, record: workOrder } = useShowContext<WorkOrderModel>()
    const disabled = useWoActionIsDisabled({ workOrder })
    const resource = useResource()
    const listContext = useListContext()
    const vendor = workOrder.purchaseOrderData
        ? {
              name: workOrder.purchaseOrderData.vendorData.name,
              id: workOrder.purchaseOrderData.vendorData.id,
          }
        : null
    return <K extends keyof WOJobItemModels>(item: WOJobItemModels[K]) => {
        return WorkOrderItemsMap[item.type as K](
            item,
            listContext,
            refetch,
            resource,
            disabled,
            vendor,
        )
    }
}
const WorkOrderItemsMap: WorkOrderItemsMapType = {
    [WOJobItemTypes.labor]: (record, listContext, refetch, resource, disabled) => ({
        icon: ScheduleOutlinedIcon,
        total: () => {
            const cost = record.hourlyRate
            const qty = record.hours
            return cost * qty
        },
        totalCaption: () => {
            const cost = record.hourlyRate
            const qty = record.hours
            return `${qty} x ${resolveDoublePriceMask(cost)}`
        },
        subtitle:
            formatCK33CodeByLevel(record.componentData.code, record.componentData.level) +
            ' ' +
            record.componentData.text,
        action: (open) =>
            open(editLaborActionExtraArgs(resource, listContext, record.id, refetch, disabled)),
    }),
    [WOJobItemTypes.part]: (record, listContext, refetch, resource, disabled, vendor) => ({
        icon: BuildCircleOutlinedIcon,
        total: () => {
            const cost = record.price
            const qty = record.qty
            return cost * qty
        },
        totalCaption: () => {
            const cost = record.price
            const qty = record.qty
            return `${qty} x ${resolveDoublePriceMask(cost)}`
        },
        subtitle: `${record.partNumber}${
            record.manufacturerData ? ` • ${record.manufacturerData.description}` : ''
        }`,
        action: (open) =>
            open(
                editPartActionExtraArgs(
                    resource,
                    listContext,
                    record.id,
                    refetch,
                    disabled,
                    vendor,
                ),
            ),
    }),
    [WOJobItemTypes.fee]: (record, listContext, refetch, resource, disabled) => ({
        icon: (
            <SvgIcon
                component={FeeIcon}
                inheritViewBox
                color="primary"
                sx={{
                    width: '18px',
                    height: '18px',
                    ...iconSxProps,
                    paddingBottom: '0px',
                }}
            />
        ),
        total: record.price,
        subtitle: record.type.charAt(0).toUpperCase() + record.type.slice(1),
        action: (open) =>
            open(editVWOItemDrawerArgs('Fee', resource, listContext, record.id, refetch, disabled)),
    }),
    [WOJobItemTypes.tax]: (record, listContext, refetch, resource, disabled) => ({
        icon: PercentOutlined,
        total: record.price,
        subtitle: record.type.charAt(0).toUpperCase() + record.type.slice(1),
        action: (open) =>
            open(editVWOItemDrawerArgs('Tax', resource, listContext, record.id, refetch, disabled)),
    }),
    [WOJobItemTypes.service]: (record, listContext, refetch, resource, disabled) => ({
        icon: WorkOutlineOutlined,
        total: record.price,
        subtitle: record.type.charAt(0).toUpperCase() + record.type.slice(1),
        action: (open) =>
            open(
                editVWOItemDrawerArgs(
                    'Service',
                    resource,
                    listContext,
                    record.id,
                    refetch,
                    disabled,
                ),
            ),
    }),
}
const WorkOrderJobCardItems = ({ itemActions }: WorkOrderJobCardItemsProps) => {
    const itemsTranslate = useJobItemTranslate()
    let jobTotal = 0
    const { data = [], isLoading } = useListContext<WOJobItem>()

    const { record: workOrder } = useShowContext()
    const job = useRecordContext<JobModel>()
    const disabled = useWoActionIsDisabled({ workOrder })

    const resource = useResource()

    if (!data.length) {
        if (isLoading) {
            return null
        }
        return (
            <AddButtonWrapper>
                <WithListContext>
                    {(listContext) => (
                        <ActionsMenu
                            record={job}
                            actions={itemActions}
                            actionArgs={{ listContext }}
                            renderToggler={(open) => (
                                <Button
                                    onClick={open}
                                    startIcon={<Icons.Add />}
                                    disabled={disabled}
                                    sx={{
                                        marginBottom: '11px',
                                        marginTop: '11px',
                                    }}
                                >
                                    ADD LINE ITEM
                                </Button>
                            )}
                        />
                    )}
                </WithListContext>
            </AddButtonWrapper>
        )
    }

    return (
        <>
            <WorkOrderJobCardSummary data={data} />
            <CollapsibleContent>
                <Divider sx={{ my: '10px' }} />
                <ItemSection>
                    {data.map((item) => {
                        const {
                            total,
                            totalCaption,
                            subtitle: itemSubtitle,
                            icon: ItemIcon,
                            action: itemAction,
                        } = itemsTranslate(item)
                        const itemIcon = isValidElement(ItemIcon) ? (
                            ItemIcon
                        ) : (
                            <ItemIcon
                                fontSize="small"
                                color="primary"
                                sx={iconSxProps}
                            />
                        )
                        const itemTotal = typeof total === 'function' ? total() : total
                        jobTotal = jobTotal + itemTotal
                        const itemTotalCaption =
                            typeof totalCaption === 'function' ? totalCaption() : totalCaption

                        return (
                            <ItemRow key={item.id}>
                                <Columns>
                                    <BoxContainer>
                                        <div className="colName rowData">
                                            <UtilityDrawerAction
                                                resource={resource}
                                                permission={basePermissions.update}
                                                renderOnNoPermission
                                            >
                                                {(open) => (
                                                    <div onClick={() => itemAction(open)}>
                                                        {itemIcon}
                                                        <Typography
                                                            variant="caption"
                                                            color="primary"
                                                            sx={{
                                                                cursor: 'pointer',
                                                            }}
                                                        >
                                                            {item.description}
                                                        </Typography>
                                                    </div>
                                                )}
                                            </UtilityDrawerAction>
                                        </div>
                                        <div className="colQtyXCost rowData">
                                            <Typography
                                                variant="caption"
                                                color="text.primary"
                                            >
                                                {itemTotalCaption || ''}
                                            </Typography>
                                        </div>
                                        <div className="colPrice rowData">
                                            <Typography
                                                variant="body2"
                                                color="text.primary"
                                            >
                                                {resolveDoublePriceMask(itemTotal)}
                                            </Typography>
                                        </div>
                                    </BoxContainer>
                                    <ItemSubtitle>
                                        <Typography
                                            variant="chartLabel"
                                            color="text.secondary"
                                        >
                                            {itemSubtitle}
                                        </Typography>
                                    </ItemSubtitle>
                                </Columns>
                            </ItemRow>
                        )
                    })}
                </ItemSection>
            </CollapsibleContent>

            <Divider sx={{ marginBottom: '10px', marginTop: '12px' }} />
            <ItemSection>
                <div className="total">
                    <BoxContainer>
                        <div className="colName rowData">
                            <MonetizationOnOutlinedIcon
                                fontSize="small"
                                sx={(theme: Theme) => ({
                                    ...iconSxProps,
                                    color: theme.palette.text.primary,
                                })}
                            />
                            <Typography
                                variant="caption"
                                color="text.primary"
                            >
                                Total
                            </Typography>
                        </div>
                        <div className="rowData">
                            <Typography
                                variant="body2"
                                color="text.primary"
                            >
                                {resolveDoublePriceMask(jobTotal)}
                            </Typography>
                        </div>
                    </BoxContainer>
                </div>
            </ItemSection>
        </>
    )
}

export default WorkOrderJobCardItems

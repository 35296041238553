import { ReactElement } from 'react'

import { Identifier, useRecordContext } from 'react-admin'

import { RaResourceContextProvider, useArchivedContext } from 'components'
import { useClearResource } from 'hooks'
import { archivedUnitResource, unitResource } from 'pages/Units/config/constants'

const metersResourceShortName = 'unit-meters'

export const getMetersResource = (unitId: Identifier, isArchived?: boolean) =>
    `${isArchived ? archivedUnitResource.resource : unitResource.resource}/${unitId}/meters`
interface UnitMetersResourceProps {
    children: ReactElement
    fallback?: null | ReactElement
}
const UnitMetersResource = ({ children, fallback = null }: UnitMetersResourceProps) => {
    const { id } = useRecordContext() || {}
    const isArchived = useArchivedContext()

    const metersResource = getMetersResource(id, isArchived)

    useClearResource(metersResource)

    if (!id) {
        return fallback
    }

    return (
        <RaResourceContextProvider
            value={{ resource: metersResource, name: metersResourceShortName }}
        >
            {children}
        </RaResourceContextProvider>
    )
}

export default UnitMetersResource

import {
    FileDownloadOutlined,
    ArchiveOutlined,
    MoreVert,
    UnarchiveOutlined,
} from '@mui/icons-material'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import AddIcon from '@mui/icons-material/Add'
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'
import BarChartIcon from '@mui/icons-material/BarChart'
import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined'
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined'
import BusinessIcon from '@mui/icons-material/Business'
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import GridViewIcon from '@mui/icons-material/GridView'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import LibraryAddCheckOutlinedIcon from '@mui/icons-material/LibraryAddCheckOutlined'
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import NetworkCheckIcon from '@mui/icons-material/NetworkCheck'
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'
import PersonAddIcon from '@mui/icons-material/PersonAddAlt'
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined'
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined'
import SaveIcon from '@mui/icons-material/Save'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import SettingsIcon from '@mui/icons-material/Settings'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import SpeedIcon from '@mui/icons-material/Speed'
import StorefrontOutlined from '@mui/icons-material/StorefrontOutlined'

import { ReactComponent as PdfIcon } from 'assets/images/pdf.svg'
import { ReactComponent as VendorPartsIcon } from 'assets/images/vendor-parts.svg'
import { ReactComponent as WidgetsFTU } from 'assets/images/widgets-ftu.svg'

const Icons = {
    Dashboard: SpeedIcon,
    Units: LocalShippingOutlinedIcon,
    Issues: ReportOutlinedIcon,
    Customers: PeopleAltOutlinedIcon,
    WorkOrders: AssignmentOutlinedIcon,
    Reports: BarChartIcon,
    Settings: SettingsIcon,
    Invitations: PersonAddIcon,
    Company: BusinessIcon,
    Profile: AccountCircleOutlinedIcon,
    Logout: LogoutOutlinedIcon,
    Parts: BuildCircleOutlinedIcon,
    VendorParts: VendorPartsIcon,
    Part: BuildOutlinedIcon,
    Delete: DeleteIcon,
    Export: FileDownloadOutlined,
    Archive: ArchiveOutlined,
    Unarchive: UnarchiveOutlined,
    Options: MoreVert,
    NoteAdd: NoteAddOutlinedIcon,
    Edit: EditIcon,
    GridView: GridViewIcon,
    Add: AddIcon,
    PersonOutlined: PersonOutlinedIcon,
    SaveFilled: SaveIcon,
    Save: SaveIcon,
    SearchOutlined: SearchOutlinedIcon,
    Clear: ClearIcon,
    Close: CloseIcon,
    SettingsOutlined: SettingsOutlinedIcon,
    Pdf: PdfIcon,
    InfoOutlined: InfoOutlinedIcon,
    SelectAll: LibraryAddCheckOutlinedIcon,
    Check: CheckIcon,
    Calculate: CalculateOutlinedIcon,
    Odometer: NetworkCheckIcon,
    Vendors: StorefrontOutlined,
    WidgetsFTU,
}

export default Icons

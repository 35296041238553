import { useRecordContext, required } from 'react-admin'
import { useFormContext, useWatch } from 'react-hook-form'

import { MeterModel, UnitModel } from 'appTypes/models'
import {
    TextInput,
    TextInputProps,
    SelectInput,
    DateTimeInput,
    GridContainer,
    GridItem,
} from 'components'
import { useUtilityDrawerContext } from 'components/Drawer/UtilityDrawer'
import { useDidMount } from 'hooks'
import { inputIntegerNonNegativeSpacedMaskParams } from 'utils'

import { meterTypeChoices } from '../constants'

const commonProps: Partial<TextInputProps> = {
    validate: [required({ message: 'Required field' })],
}

export interface UnitMeterDrawerExtraState {
    unit: UnitModel
}

const UnitMetersFormContent = () => {
    const typeValue = useWatch({ name: 'type' })
    const { setValue } = useFormContext()
    const disabled = !typeValue
    const { id } = useRecordContext<MeterModel>() || ({} as MeterModel)
    const { extra } = useUtilityDrawerContext()
    const { unit } = extra as UnitMeterDrawerExtraState

    useDidMount(() => {
        if (!id) {
            const lastKnownType = unit.lastMeters?.[0]?.type
            if (lastKnownType) {
                setValue('type', lastKnownType)
            }
        }
    })

    return (
        <GridContainer>
            <GridItem>
                <SelectInput
                    {...commonProps}
                    source="type"
                    label="Meter Type"
                    choices={meterTypeChoices}
                    disableEmptyValue
                />
            </GridItem>
            <GridItem>
                <DateTimeInput
                    {...commonProps}
                    source="timestamp"
                    label="Date & Time"
                    disabled={disabled}
                    defaultValue={new Date()}
                />
            </GridItem>
            <GridItem>
                <TextInput
                    {...commonProps}
                    source="value"
                    label="Value"
                    disabled={disabled}
                    {...inputIntegerNonNegativeSpacedMaskParams}
                />
            </GridItem>
        </GridContainer>
    )
}

export default UnitMetersFormContent

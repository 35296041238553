import { FC } from 'react'

import { Grid, GridProps, Breakpoint, GridSize } from '@mui/material'

import { useInDrawerContext } from 'components'

export interface GridItemLayoutProps extends GridProps {
    gridColumns?: { [K in Breakpoint]?: GridSize }
}

const GridItemLayout: FC<GridItemLayoutProps> = ({ gridColumns, ...rest }) => {
    return (
        <Grid
            item
            {...gridColumns}
            {...rest}
        />
    )
}

export const gridItemLayoutColumns: { [K in Breakpoint]?: number } = {
    xs: 12,
    sm: 6,
    md: 5,
    lg: 4,
    xl: 3,
}
export const gridItemLayoutColumnsDoubled = Object.fromEntries(
    Object.entries(gridItemLayoutColumns).map(([key, value]) => [
        key,
        value * 2 > 12 ? 12 : value * 2,
    ]),
)

GridItemLayout.defaultProps = {
    gridColumns: gridItemLayoutColumns,
}

export default GridItemLayout

interface GridItemLayoutInDrawerProps extends GridItemLayoutProps {
    inDrawerProps?: Partial<GridItemLayoutProps>
    nonDrawerProps?: Partial<GridItemLayoutProps>
}

export const GridItemLayoutInDrawer: FC<GridItemLayoutInDrawerProps> = ({
    inDrawerProps,
    nonDrawerProps,
    ...props
}) => {
    const inDrawer = useInDrawerContext()

    if (inDrawer) {
        return (
            <GridItemLayout
                gridColumns={{ xs: 12 }}
                {...props}
                {...inDrawerProps}
            />
        )
    }

    return (
        <GridItemLayout
            {...props}
            {...nonDrawerProps}
        />
    )
}

export const GridItem: FC<GridProps> = (props) => {
    return (
        <Grid
            item
            xs={12}
            {...props}
        />
    )
}

export const GridItemCard: FC<GridProps> = (props) => {
    return (
        <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            xl={2}
            {...props}
        />
    )
}

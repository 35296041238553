import { FC } from 'react'

import { useTheme } from '@mui/material'
import { Dimensions } from '@nivo/core'
import { Point, DatumValue, Line, LineSvgProps, Datum, CustomLayerProps } from '@nivo/line'
import { TooltipWrapper } from '@nivo/tooltip'

import { Columns, Typography } from 'components'
import { TooltipBox } from 'pages/Dashboard/components/WidgetTooltip'
import { resolveDoublePriceMask } from 'utils'

interface CustomPointProps extends CustomLayerProps {
    currentPoint: {
        x: number
        y: number
    }
}
const CustomPoint = (props: CustomPointProps) => {
    const { currentPoint } = props
    // it will show the current point
    if (currentPoint) {
        return (
            <g>
                <circle
                    r={3}
                    strokeWidth="3"
                    stroke="#2979FF"
                    fill="#ffffff"
                    cx={currentPoint.x}
                    cy={currentPoint.y}
                />
            </g>
        )
    }
}
interface customPoint extends Omit<Point, 'data'> {
    data: {
        x: DatumValue
        xFormatted: string | number
        y: DatumValue
        yFormatted: string | number
        yStacked?: number
        date: string
    }
}
interface CustomPointTooltipProps {
    point: customPoint
}
const CustomTooltip: FC<CustomPointTooltipProps> = (props) => {
    const { point } = props

    return (
        <TooltipWrapper
            anchor="left"
            position={[0, 0]}
        >
            <TooltipBox>
                <Columns>
                    <Typography
                        variant="tooltip"
                        color="white"
                    >
                        {resolveDoublePriceMask(Number(point.data.yFormatted))}
                    </Typography>
                    <Typography
                        variant="tooltip"
                        color="white"
                    >
                        {point.data.date as any}
                    </Typography>
                </Columns>
            </TooltipBox>
        </TooltipWrapper>
    )
}
interface WidgetLineChartBaseProps
    extends Partial<Omit<LineSvgProps, 'data'>>,
        Partial<Dimensions> {
    data: {
        date: string
        value: number
    }[]
    isValid: boolean
}
const defaultData: Datum[] = [
    { x: 0, y: 0 },
    { x: 1, y: 0 },
]
// Refactor widget line charts to have a base
const WidgetLineChartBase = ({ data, isValid, ...props }: WidgetLineChartBaseProps) => {
    const theme = useTheme()

    const normalizedData = isValid
        ? data.map((obj, i) => {
              const value = obj.value
              const date = new Date(obj.date)
                  .toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'long',
                      day: '2-digit',
                  })
                  .replace(',', '')
              return { x: i, y: value, date }
          })
        : defaultData
    return (
        <Line
            axisBottom={null}
            width={108}
            height={110}
            margin={{ top: 15, right: 4, bottom: 15, left: 4 }}
            curve="cardinal"
            enableCrosshair={false}
            data={[
                {
                    id: 'fake corp. A',
                    data: normalizedData,
                },
            ]}
            enableGridX={false}
            enableGridY={false}
            enableArea={false}
            useMesh={isValid}
            axisLeft={null}
            axisRight={null}
            axisTop={null}
            tooltip={isValid ? CustomTooltip : () => null}
            yScale={{
                type: 'linear',
                min: 'auto',
                max: 'auto',
                stacked: true,
                reverse: false,
            }}
            yFormat=" >-.2f"
            layers={[
                'grid',
                'markers',
                'axes',
                'areas',
                'crosshair',
                'lines',
                'slices',
                CustomPoint,
                'mesh',
                'legends',
            ]}
            colors={isValid ? theme.palette.primary.main : theme.palette.charts.disable}
            {...props}
        />
    )
}
export default WidgetLineChartBase

import { Identifier } from 'react-admin'

import { SortType } from 'appTypes'
import {
    List,
    ListBase,
    IconText,
    PageContent,
    DatagridColumnsProps,
    Status,
    ListAvatar,
    dropdownSelectorInDatagridStyle,
} from 'components'
import { DatagridLink } from 'components/Datagrid'
import { DropdownIconText } from 'components/IconText'
import {
    deleteManyFromListAction,
    deleteOneFromListAction,
    multiselectAction,
} from 'components/actions'
import { CardListConfig } from 'components/list/CardList'
import { ListBulkActions } from 'components/list/ListSelection'
import { ListSortContentProps } from 'components/list/ListSortDrawerContent'
import ListFilterDateRangeValueInput from 'components/list/filter/ListFilterDateRangeValueInput'
import { FilterConfig } from 'components/list/filter/ListFilterForm'
import ListFilterRangeInput from 'components/list/filter/ListFilterRangeInput'
import ListFilterValueInput from 'components/list/filter/ListFilterValueInput'
import { PeriodRangeWithTypeFilter } from 'pages/Reports/Downtime/components'
import LinkCardButton from 'pages/Reports/components/LinkCardButon'
import { archivedUnitResource, unitResource } from 'pages/Units/config/constants'
import { formatDate, formatDuration } from 'utils'
import { resolveDoublePriceMask, costMaskParams } from 'utils/masks'

import { WithIsVendorWO } from '../Overview/WorkOrderOverview'
import {
    WorkOrderCreateMenu,
    WorkOrderIcon,
    WorkOrderStatusSelector,
    WorkOrderSubtitle,
} from '../components'
import {
    deleteWorkOrderAction,
    priorities,
    repairClass,
    vendorWODeleteDialogAction,
    workOrderStatuses,
} from '../config/constants'

import { editRedirectWOFromListAction, pdfOneWOFromListAction } from './actions'
import { WOListHeader } from './components'

import type { WorkOrderModel, WorkOrderType } from 'appTypes/models'

const defaultWorkOrderSort: SortType<WorkOrderModel> = {
    field: 'created',
    order: 'DESC',
}

interface AdditionalOrdering {
    priority: string
    repairPriorityClass: string
    status: string
}

const bulkActions: ListBulkActions = ({ children }) => [
    deleteManyFromListAction({
        children,
        confirmConfig: {
            title: 'Are you sure you want to delete the selected Work Orders?',
        },
    }),
]

export const woCardsCfg: CardListConfig<WorkOrderModel> = {
    titleSource: 'number',
    subTitleSource: (workOrder, { listContext }) => (
        <WorkOrderSubtitle
            workOrder={workOrder}
            onStatusChange={async () => {
                await listContext.refetch()
            }}
        />
    ),
    defaultImage: (record) => <WorkOrderIcon record={record} />,
    colorGetter: (record) => {
        const status = workOrderStatuses[record.status]
        if (!status) {
            return null
        }
        return status.iconColor
    },
    details: [
        {
            source: 'started',
            label: ({ record }) => {
                const { status } = record
                if (status === 'CANCELED') {
                    return 'Canceled on Date'
                }
                if (status === 'CLOSED') {
                    return 'Completion Date & Time'
                }
                return 'Start Date & Time'
            },
            render: (value, record) => {
                const { status } = record
                if (status === 'CANCELED') {
                    return formatDate(
                        record.statusUpdated,
                        (dateFormats) => dateFormats.fullDateTime,
                    )
                }
                if (status === 'CLOSED') {
                    return formatDate(record.completed, (dateFormats) => dateFormats.fullDateTime)
                }
                return formatDate(value, (dateFormats) => dateFormats.fullDateTime)
            },
        },
        {
            source: 'priority',
            render: (value) =>
                value ? (
                    <>
                        {priorities[value]?.icon} {priorities[value]?.text}
                    </>
                ) : null,
        },
        {
            source: 'repairPriorityClass',
            label: 'Repair class',
            render: (value) =>
                value ? (
                    <>
                        {repairClass[value]?.icon} {repairClass[value]?.text}
                    </>
                ) : null,
        },
        {
            source: 'vendor',
            label: 'Vendor',
            render: (value, record) => record.purchaseOrderData?.vendorData.name,
        },
        {
            source: 'purchaseOrder',
            label: 'PO Number',
            render: (value, record) => record.purchaseOrderData?.number,
        },
        {
            source: 'jobsCount',
            label: 'Jobs',
        },
        {
            source: 'total',
            label: 'Total',
            render: (value) => resolveDoublePriceMask(value),
        },
    ],
    actions: (record, { children, resource }) => [
        editRedirectWOFromListAction({ children, id: record.id }),
        multiselectAction({
            children,
            id: record.id,
        }),
        pdfOneWOFromListAction({ record, children }),

        <WithIsVendorWO
            record={record}
            key="deleteOne"
        >
            {(isVendorWorkOrder) =>
                deleteOneFromListAction({
                    disabled: record.status !== 'OPEN' || undefined,
                    id: record.id,
                    ...(isVendorWorkOrder && record.purchaseOrderData?.totalInvoices
                        ? vendorWODeleteDialogAction()
                        : deleteWorkOrderAction(record, resource!)),
                    children: (params) =>
                        children({
                            ...params,
                            titleOnDisabled:
                                record.status === 'OPEN'
                                    ? undefined
                                    : `${
                                          workOrderStatuses[record.status].text
                                      } WOs cannot be deleted`,
                        }),
                })
            }
        </WithIsVendorWO>,
    ],
}

const sortCfg: ListSortContentProps<WorkOrderModel & AdditionalOrdering & { duration: string }> = {
    sortBy: [
        { id: 'created', label: 'Created on' },
        { id: 'duration', label: 'WO Duration' },
        { id: 'number', label: 'WO Number' },
        { id: 'unit', label: 'Unit Number' },
        { id: 'priority', label: 'Priority' },
        { id: 'status', label: 'Status' },
        { id: 'repairPriorityClass', label: 'Repair Class' },
        { id: 'vendor', label: 'Vendor' },
        { id: 'purchaseOrder', label: 'PO Number' },
        { id: 'jobsCount', label: 'Jobs' },
        { id: 'total', label: 'Total' },
    ],
}

interface WoTypeChoice {
    id: Identifier
    name: string
}

const woTypeChoices: WoTypeChoice[] = [
    { id: 'internal', name: 'Internal' },
    { id: 'vendor', name: 'Vendor' },
]

const filtersCfg: FilterConfig<WorkOrderModel & { type: WorkOrderType; duration: string }> = {
    filters: [
        {
            id: 'created',
            label: 'Created on',
            filterType: 'range',
            renderComponent: (props) => <ListFilterDateRangeValueInput {...props} />,
        },
        {
            id: 'completed',
            label: 'Completed on',
            filterType: 'range',
            renderComponent: (props) => <ListFilterDateRangeValueInput {...props} />,
        },
        {
            id: 'duration',
            label: 'WO Duration',
            filterType: 'range',
            renderComponent: (props) => (
                <PeriodRangeWithTypeFilter
                    {...props}
                    inputMaxProps={{ maxValueLenght: 9 }}
                    inputMinProps={{ maxValueLenght: 9 }}
                    choices={['h', 'd']}
                />
            ),
        },
        { id: 'unit', label: 'Unit Number' },
        {
            id: 'priority',
            label: 'Priority',
            renderComponent: (props) => (
                <ListFilterValueInput
                    {...props}
                    inputText={(option) => option.id}
                    makeItemLabel={(record) => {
                        const priority = priorities[record.id as keyof typeof priorities]
                        if (priority) {
                            return (
                                <DropdownIconText>
                                    {priority.icon}
                                    {priority.text}
                                </DropdownIconText>
                            )
                        }

                        return record.id
                    }}
                />
            ),
        },
        {
            id: 'status',
            label: 'Status',
            renderComponent: (props) => (
                <ListFilterValueInput
                    {...props}
                    inputText={(option) => option.id}
                    makeItemLabel={(record) => {
                        const status =
                            workOrderStatuses[record.id as keyof typeof workOrderStatuses]
                        if (status) {
                            return (
                                <DropdownIconText>
                                    <Status
                                        size="8px"
                                        iconColor={status.iconColor}
                                    />
                                    {status.text}
                                </DropdownIconText>
                            )
                        }
                        return record.id
                    }}
                />
            ),
        },
        {
            id: 'repairPriorityClass',
            label: 'Repair Class',
            renderComponent: (props) => (
                <ListFilterValueInput
                    {...props}
                    inputText={(option) => option.id}
                    makeItemLabel={(record) => {
                        const priorityClass = repairClass[record.id as keyof typeof repairClass]
                        if (priorityClass) {
                            return (
                                <DropdownIconText>
                                    {priorityClass.icon}
                                    {priorityClass.text}
                                </DropdownIconText>
                            )
                        }

                        return record.id
                    }}
                />
            ),
        },
        { id: 'vendor', label: 'Vendor' },
        {
            id: 'total',
            label: 'Total',
            filterType: 'range',
            renderComponent: (props) => (
                <ListFilterRangeInput
                    inputProps={costMaskParams}
                    {...props}
                />
            ),
        },
        {
            id: 'type',
            label: 'WO Type',
            renderComponent: (props) => (
                <ListFilterValueInput
                    {...props}
                    source="type"
                    makeItemLabel={(record) => record.name}
                    matchSuggestion={(filter, choice: WoTypeChoice) => {
                        return choice.name?.toLowerCase().includes(filter?.toLowerCase())
                    }}
                    dataProviderProps={{
                        getList: {
                            makeResponse: () => woTypeChoices,
                        },
                        getMany: {
                            makeResponse: (resource, { ids }) => {
                                return ids.map((id) =>
                                    woTypeChoices.find((choice) => choice.id === id),
                                )
                            },
                        },
                    }}
                />
            ),
        },
        {
            id: 'jobsCount',
            label: 'Jobs',
        },
    ],
}

const columnsCfg: DatagridColumnsProps<WorkOrderModel & { image: string; duration: string }> = {
    resetColumns: {
        purchaseOrderTotal: false,
    },
    columns: [
        {
            field: 'image',
            headerName: 'Avatar',
            maxWidth: 72,
            renderCell: ({ row }) => (
                <ListAvatar
                    linkProps={{ 'aria-label': `Open Work Order ${row.number}` }}
                    id={row.id}
                    defaultImage={<WorkOrderIcon record={row} />}
                />
            ),
        },
        {
            field: 'number',
            headerName: 'WO Number',
            flex: 1,
            renderCell: (params) => <DatagridLink {...params} />,
        },
        {
            field: 'created',
            headerName: 'Created on',
            flex: 1,
            valueGetter: ({ value }) => {
                return formatDate(value, (dateFormats) => dateFormats.shortenedDateTime)
            },
        },
        {
            field: 'completed',
            headerName: 'Completed on',
            flex: 1,
            valueGetter: ({ value }) =>
                formatDate(value, (dateformats) => dateformats.shortenedDateTime),
        },

        {
            field: 'duration',
            headerName: 'WO Duration',
            flex: 1,
            valueGetter: ({ row }) => formatDuration(row.durationSeconds, true),
        },
        {
            field: 'unitData',
            headerName: 'Unit Number',
            flex: 1,
            renderCell: ({ value }) => {
                return (
                    value?.number && (
                        <LinkCardButton
                            text={value.number}
                            to={{
                                resource: value.archived
                                    ? archivedUnitResource.resource
                                    : unitResource.resource,
                                type: 'edit',
                                id: value.id,
                            }}
                        />
                    )
                )
            },
        },
        {
            field: 'priority',
            headerName: 'Priority',
            flex: 1,
            renderCell: (cell) => <IconText {...priorities[cell.value]} />,
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            renderCell: (cell, { listContext }) => {
                return (
                    <WorkOrderStatusSelector
                        sx={dropdownSelectorInDatagridStyle}
                        workOrder={cell.row}
                        onComplete={async () => {
                            await listContext.refetch()
                        }}
                    />
                )
            },
        },
        {
            field: 'repairPriorityClass',
            headerName: 'Repair Class',
            flex: 1,
            renderCell: (cell) => <IconText {...repairClass[cell.value]} />,
        },
        {
            field: 'vendor',
            headerName: 'Vendor',
            valueGetter: ({ row }) => row.purchaseOrderData?.vendorData.name,
        },
        {
            field: 'purchaseOrder',
            headerName: 'PO Number',
            flex: 1,
            valueGetter: ({ row }) => row.purchaseOrderData?.number,
        },
        {
            field: 'jobsCount',
            headerName: 'Jobs',
            flex: 1,
            valueGetter: ({ value }) => (value >= 1 ? value : null),
        },
        {
            field: 'total',
            headerName: 'Total',
            flex: 1,
            align: 'right',
            headerAlign: 'right',
            valueGetter: ({ value }) => resolveDoublePriceMask(value),
        },
    ],
    actions: ({ row: workOrder }, { children, resource }) => [
        editRedirectWOFromListAction({
            children,
            id: workOrder.id,
        }),
        pdfOneWOFromListAction({
            children,
            record: workOrder,
        }),
        <WithIsVendorWO
            record={workOrder}
            key="deleteOne"
        >
            {(isVendorWorkOrder) =>
                deleteOneFromListAction({
                    disabled: workOrder.status !== 'OPEN' || undefined,
                    id: workOrder.id,
                    ...(isVendorWorkOrder && workOrder.purchaseOrderData?.totalInvoices
                        ? vendorWODeleteDialogAction()
                        : deleteWorkOrderAction(workOrder, resource!)),
                    children: (params) =>
                        children({
                            ...params,
                            titleOnDisabled:
                                workOrder.status === 'OPEN'
                                    ? undefined
                                    : `${
                                          workOrderStatuses[workOrder.status].text
                                      } WOs cannot be deleted`,
                        }),
                })
            }
        </WithIsVendorWO>,
    ],
}

const WorkOrdersList = () => {
    return (
        <ListBase<WorkOrderModel> sort={defaultWorkOrderSort}>
            <WOListHeader />
            <PageContent>
                <List
                    bulkActions={bulkActions}
                    sortCfg={sortCfg}
                    columnsCfg={columnsCfg}
                    cardsCfg={woCardsCfg}
                    filtersCfg={filtersCfg}
                    listFTUProps={{
                        linkAction: () => {
                            /* */
                        },
                        linkText: (
                            <WorkOrderCreateMenu
                                renderToggler={(open) => (
                                    <span onClick={open}>Create Work Order</span>
                                )}
                            />
                        ),
                        secondaryTitle: 'Would you like to create one?',
                    }}
                />
            </PageContent>
        </ListBase>
    )
}

export default WorkOrdersList

import { FileDownloadOutlined } from '@mui/icons-material'
import { IconButton, Tooltip, SvgIcon } from '@mui/material'
import { stringify } from 'query-string'
import { RaRecord, useListContext } from 'react-admin'

import { BoxContainer } from 'components/styled'
import { useFileDownload } from 'hooks'
import { getOrderingQuery } from 'providers/dataProvider'

export interface ExportButtonProps<RecordType extends RaRecord = any> {
    fileName?: string
    visibleColsRef: { current: string[][] }
    filters?: {
        [key in keyof RecordType]?: string
    }
}

const ExportButton = <RecordType extends RaRecord = any>({
    fileName,
    visibleColsRef,
    filters,
}: ExportButtonProps<RecordType>) => {
    const listContext = useListContext()
    const fileDownload = useFileDownload()

    const downloadCSV = () => {
        const resource = listContext.resource
        const query = {
            ...listContext.filterValues,
            ...listContext.filter,
            csvFields: visibleColsRef.current || [],
            ...getOrderingQuery(listContext.sort),
            ...filters,
        }

        fileDownload({
            filename: fileName || listContext.resource,
            type: 'csv',
            headers: { accept: 'text/csv' },
            url: `${resource}?${stringify(query)}`,
        })
    }
    return (
        <Tooltip title="Export">
            <BoxContainer>
                <IconButton
                    aria-label="Export List"
                    size="small"
                    sx={{ color: (theme) => theme.palette.text.primary }}
                    onClick={downloadCSV}
                >
                    <SvgIcon
                        component={FileDownloadOutlined}
                        sx={{
                            width: { xs: '20px', sm: '24px' },
                            height: { xs: '20px', sm: '24px' },
                        }}
                    />
                </IconButton>
            </BoxContainer>
        </Tooltip>
    )
}

export default ExportButton

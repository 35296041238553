import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

const PageBlock = styled(Box)`
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 16px;

    ${({ theme }) => `
        ${theme.breakpoints.up('sm')} {
            padding-left: 28px;
            padding-right: 28px;
        }
    `}
`

export default PageBlock

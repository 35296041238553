import { SortType } from 'appTypes'
import Ck14ReportsModel from 'appTypes/models/Ck14ReportsModel'
import { CardListConfig, DatagridColumnsProps, PageContent } from 'components'
import { ListSortContentProps } from 'components/list/ListSortDrawerContent'
import { FilterConfig } from 'components/list/filter/ListFilterForm'
import ListFilterRangeInput from 'components/list/filter/ListFilterRangeInput'
import ResourceContextProviderWithClearEffect from 'components/resource/ResourceContextProviderWithClearEffect'
import { ck14Resource } from 'pages/Units/config/constants'
import { costMaskParams, resolveDoublePriceMask } from 'utils'

import { ListBaseWithPeriod, ListWithPeriodSelector, ReportsHeader } from '../components'

const defaultSort: SortType<Ck14ReportsModel> = {
    field: 'total',
    order: 'DESC',
}

const cardsCfg: CardListConfig<Ck14ReportsModel> = {
    titleSource: ({ code, description }) => `${code} ${description}`,
    disableTitleLink: true,
    defaultImage: null,
    details: [
        { source: 'type', label: 'Repair Type' },
        { source: 'totalParts', label: 'parts', render: (value) => resolveDoublePriceMask(value) },
        { source: 'totalLabor', label: 'labor', render: (value) => resolveDoublePriceMask(value) },
        {
            source: 'totalServices',
            label: 'services',
            render: (value) => resolveDoublePriceMask(value),
        },
        { source: 'totalFees', label: 'fees', render: (value) => resolveDoublePriceMask(value) },
        { source: 'totalTax', label: 'tax', render: (value) => resolveDoublePriceMask(value) },
        { source: 'total', label: 'total cost', render: (value) => resolveDoublePriceMask(value) },
    ],
}
const columnsCfg: DatagridColumnsProps<Ck14ReportsModel> = {
    checkboxSelection: false,
    columns: [
        {
            field: 'codeDescription',
            headerName: 'Reason for Repair',
        },
        {
            field: 'type',
            headerName: 'Repair Type',
        },
        {
            field: 'totalParts',
            headerName: 'Parts',
            valueFormatter: ({ value }) => resolveDoublePriceMask(value),
            align: 'right',
            headerAlign: 'right',
        },
        {
            field: 'totalLabor',
            headerName: 'Labor',
            valueFormatter: ({ value }) => resolveDoublePriceMask(value),
            align: 'right',
            headerAlign: 'right',
        },
        {
            field: 'totalServices',
            headerName: 'Services',
            valueFormatter: ({ value }) => resolveDoublePriceMask(value),
            align: 'right',
            headerAlign: 'right',
        },
        {
            field: 'totalFees',
            headerName: 'Fees',
            valueFormatter: ({ value }) => resolveDoublePriceMask(value),
            align: 'right',
            headerAlign: 'right',
        },
        {
            field: 'totalTax',
            headerName: 'Tax',
            valueFormatter: ({ value }) => resolveDoublePriceMask(value),
            align: 'right',
            headerAlign: 'right',
        },
        {
            field: 'total',
            headerName: 'Total Cost',
            valueFormatter: ({ value }) => resolveDoublePriceMask(value),
            align: 'right',
            headerAlign: 'right',
        },
    ],
    actions: null,
}
const filtersCfg: FilterConfig<Ck14ReportsModel> = {
    filters: [
        { id: 'codeDescription', label: 'Reason for Repair' },
        {
            id: 'type',
            label: 'Repair Type',
        },
        {
            id: 'totalParts',
            label: 'Parts',
            filterType: 'range',
            renderComponent: (props) => (
                <ListFilterRangeInput
                    inputProps={costMaskParams}
                    {...props}
                />
            ),
        },
        {
            id: 'totalLabor',
            label: 'Labor',
            filterType: 'range',
            renderComponent: (props) => (
                <ListFilterRangeInput
                    inputProps={costMaskParams}
                    {...props}
                />
            ),
        },
        {
            id: 'totalServices',
            label: 'Services',
            filterType: 'range',
            renderComponent: (props) => (
                <ListFilterRangeInput
                    inputProps={costMaskParams}
                    {...props}
                />
            ),
        },
        {
            id: 'totalFees',
            label: 'Fees',
            filterType: 'range',
            renderComponent: (props) => (
                <ListFilterRangeInput
                    inputProps={costMaskParams}
                    {...props}
                />
            ),
        },
        {
            id: 'totalTax',
            label: 'Tax',
            filterType: 'range',
            renderComponent: (props) => (
                <ListFilterRangeInput
                    inputProps={costMaskParams}
                    {...props}
                />
            ),
        },
        {
            id: 'total',
            label: 'Total Cost',
            filterType: 'range',
            renderComponent: (props) => (
                <ListFilterRangeInput
                    inputProps={costMaskParams}
                    {...props}
                />
            ),
        },
    ],
}
const sortCfg: ListSortContentProps<Ck14ReportsModel> = {
    sortBy: [
        { id: 'codeDescription', label: 'Reason for Repair' },
        { id: 'type', label: 'Repair Type' },
        {
            id: 'totalParts',
            label: 'Parts',
        },
        { id: 'totalLabor', label: 'Labor' },
        { id: 'totalServices', label: 'Services' },
        { id: 'totalFees', label: 'Fees' },
        { id: 'totalTax', label: 'Tax' },
        { id: 'total', label: 'Total Cost' },
    ],
}
const CostPerReasonForRepairCK14 = () => {
    return (
        <ResourceContextProviderWithClearEffect value={ck14Resource}>
            <ListBaseWithPeriod
                sort={defaultSort}
                filter={{ withAspects: ['totals'] }}
            >
                <ReportsHeader<Ck14ReportsModel>
                    renderTotal={({ total: totalCount, hasPreviousPage }) =>
                        `${totalCount} | ${resolveDoublePriceMask(
                            (hasPreviousPage as any).aggregates.totalQueryset || 0,
                        )} total`
                    }
                >
                    Cost per Reason for Repair
                </ReportsHeader>
                <PageContent>
                    <ListWithPeriodSelector
                        exportFileName="cost-per-reason-for-repair"
                        filtersCfg={filtersCfg}
                        sortCfg={sortCfg}
                        columnsCfg={columnsCfg}
                        cardsCfg={cardsCfg}
                    />
                </PageContent>
            </ListBaseWithPeriod>
        </ResourceContextProviderWithClearEffect>
    )
}

export default CostPerReasonForRepairCK14

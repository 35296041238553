import { FC } from 'react'

import { SxProps, Theme } from '@mui/material'
import { useWatch } from 'react-hook-form'

import { LineSlider } from '../ProgressLineSlider'

import useInputSource from './useInputSource'

interface UnitPMMeterLineProps {
    getThreshold?: (value: number | string) => number | string
    getEvery?: (value: number | string) => number | string
    sx?: SxProps<Theme>
}

const UnitPMMeterLine: FC<UnitPMMeterLineProps> = ({ getThreshold, getEvery, sx }) => {
    const source = useInputSource()
    const [every, threshold] = useWatch({ name: [source('value'), source('threshold')] })

    const current =
        100 -
        ((getThreshold ? getThreshold(threshold) : threshold) /
            (getEvery ? getEvery(every) : every)) *
            100

    return (
        <LineSlider
            barOpacity={1}
            value={current}
            sx={sx}
        />
    )
}

export default UnitPMMeterLine

import { ComponentProps, useEffect, useCallback } from 'react'

import {
    AddNotificationContext,
    Form as RAForm,
    NotificationPayload,
    useAddNotificationContext,
} from 'react-admin'
import {
    useFormState,
    useFormContext,
    UseFormReturn,
    UseFormClearErrors,
    UseFormReset,
    useWatch,
} from 'react-hook-form'
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed'

import { FormErrors } from 'appTypes'
import { ResourceType, useResource } from 'components/resource'
import { nonFieldErrors } from 'configs/constants'
import { useCreateInputId, useOptimizedRef, useServerErrorHandler, useNotify } from 'hooks'
import { formDefaultValuesKey } from 'utils'

import { FormInfo } from './FormInfo'
import FormWarnWhenUnsavedChanges from './FormWarnWhenUnsavedChanges'

interface FormExtraActionsProps {
    formOnError?: (params: {
        errors: UseFormReturn['formState']['errors']
        defaultOnError: () => void
        notify: ReturnType<typeof useNotify>
        clearErrors: UseFormClearErrors<any>
        reset: UseFormReset<any>
    }) => void
    notify: ReturnType<typeof useNotify>
    serverErrorHandler: ReturnType<typeof useServerErrorHandler>
}

const FormExtraActions = ({ formOnError, notify, serverErrorHandler }: FormExtraActionsProps) => {
    const { formState, clearErrors, reset } = useFormContext()
    const { errors, isSubmitting } = formState

    const createId = useCreateInputId()

    useEffect(() => {
        const errorNames = Object.keys(errors)

        if (errorNames.length > 0 && !isSubmitting) {
            for (const errorName of errorNames) {
                if (errorName === nonFieldErrors) {
                    continue
                }

                let name = errorName
                const arrayErrors = errors[errorName] as unknown as FormErrors[]
                if (Array.isArray(arrayErrors)) {
                    const arrayKeys = Object.keys(arrayErrors)
                    for (const errorIndex of arrayKeys) {
                        const arrayFields = arrayErrors[errorIndex]
                        if (!arrayFields) {
                            continue
                        }

                        const arrayErrorKeys = Object.keys(arrayFields)
                        const fieldKey = arrayErrorKeys.find((field) => arrayFields[field])
                        if (fieldKey) {
                            name += '.' + errorIndex + '.' + fieldKey
                            break
                        }
                    }
                }
                const element = document.getElementById(createId(name))
                if (element) {
                    smoothScrollIntoView(element, {
                        scrollMode: 'always',
                        boundary: element.closest('form'),
                    }).then(() => {
                        element.focus()
                    })
                }
                break
            }

            const defaultOnError = () => {
                serverErrorHandler(errors as any, { fallbackError: '' })
            }

            if (formOnError) {
                formOnError({
                    errors,
                    defaultOnError,
                    notify,
                    clearErrors,
                    reset,
                })
            } else {
                defaultOnError()
            }
        }
    }, [errors, isSubmitting])

    return null
}

const ResetOnSubmitSuccess = () => {
    const { reset, formState } = useFormContext()
    const { isSubmitSuccessful } = formState
    useEffect(() => {
        if (isSubmitSuccessful) {
            reset()
        }
    }, [isSubmitSuccessful])

    return null
}

export interface FormProps
    extends Omit<ComponentProps<typeof RAForm>, 'resource'>,
        Omit<FormExtraActionsProps, 'notify' | 'serverErrorHandler'> {
    disableSuccessRedirect?: boolean
    resource?: ResourceType
    resetOnSuccess?: boolean
}

const Form = ({
    warnWhenUnsavedChanges = true,
    formOnError,
    defaultValues,
    resource: resourceProp,
    resetOnSuccess,
    ...props
}: FormProps) => {
    const resource = useResource(resourceProp)
    const notify = useNotify()
    const addNotify = useAddNotificationContext()
    const emptyNotify = useCallback((args: NotificationPayload) => {
        if (args?.notificationOptions?.messageArgs?.forceDisplay) {
            addNotify(args)
        }
    }, [])
    const { current: formDefaultValuesFromQuery } = useOptimizedRef(() => {
        const queryParams = new URLSearchParams(window.location.search).get(formDefaultValuesKey)
        try {
            if (queryParams) {
                return JSON.parse(queryParams)
            }
        } catch {
            return null
        }

        return null
    })

    const formDefaultValues =
        (formDefaultValuesFromQuery || defaultValues) &&
        ((record) => {
            let values = defaultValues
            if (typeof defaultValues === 'function') {
                values = defaultValues(record)
            }
            return {
                ...values,
                ...formDefaultValuesFromQuery,
            }
        })

    const serverErrorHandler = useServerErrorHandler()

    const formName = props.formRootPathname || resourceToFormName(resource)

    const formId = resourceToFormId(resource)

    return (
        <AddNotificationContext.Provider value={emptyNotify as any}>
            <FormInfo name={formName}>
                <RAForm
                    noValidate
                    id={formId}
                    mode="onChange"
                    {...props}
                    warnWhenUnsavedChanges={false}
                    defaultValues={formDefaultValues}
                    resource={resource?.resource}
                >
                    <FormExtraActions
                        serverErrorHandler={serverErrorHandler}
                        formOnError={formOnError}
                        notify={notify}
                    />
                    {resetOnSuccess && <ResetOnSubmitSuccess />}
                    {warnWhenUnsavedChanges ? <FormWarnWhenUnsavedChanges /> : null}
                    {props.children}
                    <FormInitialValidation formId={formId} />
                </RAForm>
            </FormInfo>
        </AddNotificationContext.Provider>
    )
}

export default Form

/**
 * Used for initial validation of the form.
 * It will trigger validation for all VISIBLE fields.
 * It will make sure that all fields with errors are touched, so that the error message is displayed.
 * react-admin checks for touched fields to display the error message.
 * Simple solution, does not work for nested fields
 * It triggers required validation as well, this is why it should be used only on EDIT
 */
const FormInitialValidation = ({ formId }: { formId: string }) => {
    const id = useWatch({ name: 'id' })

    if (!id) {
        return null
    }

    return <FormInitialValidationBase formId={formId} />
}

const FormInitialValidationBase = ({ formId }: { formId: string }) => {
    const { trigger, setValue, getValues } = useFormContext()
    const { errors, touchedFields, isDirty } = useFormState()

    useEffect(() => {
        trigger(null, {
            shouldFocus: false,
        })
    }, [])

    useEffect(() => {
        const errorNames = Object.keys(errors)
        if (!isDirty && errorNames.length) {
            errorNames.forEach((name) => {
                if (
                    touchedFields[name] ||
                    // Don't validate if input is disabled. TODO: find better way to get input.disabled
                    (
                        document.querySelector(
                            `${formId ? '#' + formId : ''} [name="${name}"]`,
                        ) as HTMLInputElement
                    )?.disabled
                ) {
                    return
                }
                setValue(name, getValues(name), {
                    shouldDirty: true,
                    shouldTouch: true,
                    shouldValidate: true,
                })
            })
        }
    }, [isDirty, errors])

    return null
}

const resourceToFormName = (resource: ResourceType) => {
    return resource?.resource?.replaceAll('/', '__')
}

export const resourceToFormId = (resource: ResourceType) => {
    const name = resourceToFormName(resource)
    return name && `${name}-form`
}

import { Box, BoxProps, TypographyProps } from '@mui/material'

import { Typography } from 'components'
const DotSeparator = (props: BoxProps & TypographyProps) => (
    <Typography
        component={Box}
        {...props}
    >
        •
    </Typography>
)

export default DotSeparator

DotSeparator.defaultProps = {
    marginX: '6px',
    color: 'text.primary',
    variant: 'chartListItem',
}

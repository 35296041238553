import { ListBase } from 'react-admin'

import { UnitModel } from 'appTypes/models'
import { PageContent, FlexReverse, SliderView, Section, ViewHeader } from 'components'
import WorkOrderSimpleList from 'pages/WorkOrders/SimpleList'

import { UnitMetersResource } from '../Meters/components'
import { UnitSliderSkeleton } from '../components'

import TopSection from './TopSection/TopSection'
import UnitDetails from './UnitDetails'
import UnitMeters from './UnitMeters'

const metersSkeletonSlides = new Array(4).fill(<UnitSliderSkeleton height="121px" />)
export const UnitMetersFallback = () => (
    <div>
        <Section>
            <ViewHeader
                title={null}
                loading
            />
        </Section>
        <SliderView items={metersSkeletonSlides} />
    </div>
)
const UnitOverview = () => {
    return (
        <>
            <TopSection />
            <PageContent>
                <FlexReverse>
                    <UnitDetails />
                    <WorkOrderSimpleList<UnitModel>
                        createFormInitialValues={(record) => ({
                            unit: record?.id,
                        })}
                        filter={(record) => ({
                            unit: [record?.number],
                        })}
                    />
                    <UnitMetersResource fallback={<UnitMetersFallback />}>
                        <ListBase perPage={3}>
                            <UnitMeters />
                        </ListBase>
                    </UnitMetersResource>
                </FlexReverse>
            </PageContent>
        </>
    )
}

export default UnitOverview

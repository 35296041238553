import { Tooltip, styled, TooltipProps } from '@mui/material'

const StyledTooltip = ({ className, ...props }: TooltipProps) => (
    <Tooltip
        {...props}
        classes={{ tooltip: className }}
    />
)

export const TooltipContainer = styled(StyledTooltip)(({ theme }) => ({
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '16px 12px',
    gap: '16px',
    boxShadow:
        '0px 5px 5px -3px rgba(0, 0, 0, 0.2),' +
        ' 0px 8px 10px 1px rgba(0, 0, 0, 0.14),' +
        ' 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
}))

import { useShowContext } from 'react-admin'
import { useQueryClient } from 'react-query'

import { UnitModel } from 'appTypes/models'
import { useOpenUtilityDrawer, UtilityDrawerEditor } from 'components/Drawer'
import { DrawerState } from 'components/Drawer/UtilityDrawer'
import { useResource } from 'components/resource'

import { deleteMeterAction } from '../../config/constants'
import { meterSerializer } from '../constants'

import UnitMetersFormContent, {
    UnitMeterDrawerExtraState,
} from './../components/UnitMetersFormContent'

const useOpenMetersDrawer = () => {
    const open = useOpenUtilityDrawer()
    const resource = useResource()
    const { refetch, record } = useShowContext<UnitModel>()
    const query = useQueryClient()

    return ({ extraArgs = {}, drawerArgs }: DrawerState = {}) =>
        open({
            extraArgs: {
                resource,
                ...extraArgs,
            },
            drawerArgs: {
                title: extraArgs.id ? 'Edit Meter' : 'Add Meters',
                renderContent: () => <UnitMetersFormContent />,
                renderBottomRight: (render) =>
                    render({ label: extraArgs.id ? undefined : 'Add Meters' }),
                renderTopRight: extraArgs.id ? (render) => render(deleteMeterAction) : null,
                renderWrapper: (params) => (
                    <UtilityDrawerEditor
                        {...params}
                        serializer={meterSerializer}
                        onSuccess={() => {
                            refetch()
                            query.refetchQueries({
                                active: true,
                                predicate: (query) => {
                                    // refetch meters and pm list
                                    return Boolean(
                                        (query.queryKey[1] as string) === 'getList' &&
                                            ((query.queryKey[0] as string).endsWith?.('/meters') ||
                                                (query.queryKey[0] as string).endsWith?.(
                                                    '/pm-schedules',
                                                )),
                                    )
                                },
                            })
                        }}
                    />
                ),
                ...drawerArgs,
            },
            extra: {
                unit: record,
            } as UnitMeterDrawerExtraState,
        })
}

export default useOpenMetersDrawer

import { SvgIcon } from '@mui/material'
import { useRecordContext } from 'react-admin'

import { UnitModel, WorkOrderModel } from 'appTypes/models'
import { HideArchived, useArchivedContext, TabType, ShowHeaderSkeleton } from 'components'
import { archiveOneAction, deleteOneAction } from 'components/actions'
import { Action } from 'components/actions/Actions'
import Icons from 'components/icons'
import Header from 'components/pages/header'
import { meterTypesConfig } from 'pages/Units/Meters/constants'
import { WorkOrderCreateMenu } from 'pages/WorkOrders/components'
import { formDefaultValuesQuery } from 'utils'

import { UnitMetersDrawerToggler } from '../../Meters/components'
import UnitMetersResource from '../../Meters/components/UnitMetersResource'
import { TitleWithTelematicsStatus, UnitStatusSelector } from '../../components'
import { archiveUnitActionParams, deleteUnitAction } from '../../config/constants'

const actions: Action<UnitModel> = (record, { children }) => {
    const queryParams = formDefaultValuesQuery<WorkOrderModel>({
        unit: record?.id,
    })
    return [
        <HideArchived
            key="archived"
            replaceWith={deleteOneAction({
                children,
                id: record.id,
                ...deleteUnitAction(record.hasRelatedWorkOrders),
            })}
        >
            <UnitMetersResource>
                <UnitMetersDrawerToggler>
                    {({ onClick }) => {
                        return children({
                            title: 'Add Meters',
                            Icon: meterTypesConfig.HUBOMETER.Icon,
                            key: 'create-meter',
                            onClick,
                        })
                    }}
                </UnitMetersDrawerToggler>
            </UnitMetersResource>
            {/* {exportAction({ children })} */}
            <WorkOrderCreateMenu
                renderToggler={(open) =>
                    children({
                        title: 'Create Work Order',
                        Icon: Icons.WorkOrders,
                        key: 'create-wo',
                        onClick: open,
                        disableCloseOnClick: true,
                    })
                }
                queryParams={queryParams}
            />
        </HideArchived>,
        archiveOneAction({
            children,
            ...archiveUnitActionParams(
                Boolean(record.archived),
                Boolean(record.telematicsData),
                record.hasOpenWorkOrders,
            ),
        }),
    ]
}
interface UnitShowHeaderInterface {
    tabs: TabType[]
}

const UnitShowHeader = ({ tabs }: UnitShowHeaderInterface) => {
    const isArchived = useArchivedContext()
    const record = useRecordContext<UnitModel>()
    if (!record) {
        return <ShowHeaderSkeleton />
    }

    return (
        <Header fixed>
            <Header.Block divider="hideOnMobile">
                <Header.BackButton />
                <Header.Main>
                    <Header.Info
                        title={
                            <Header.Title>
                                <TitleWithTelematicsStatus
                                    record={record}
                                    inShow
                                />
                            </Header.Title>
                        }
                        avatar={
                            <Header.Avatar
                                color={
                                    isArchived ? (theme) => theme.palette.text.primary : undefined
                                }
                                imageSrc={record.photo}
                                defaultImage={
                                    <SvgIcon
                                        component={Icons.Units}
                                        inheritViewBox
                                        sx={isArchived ? { opacity: 0.54 } : undefined}
                                    />
                                }
                            />
                        }
                        subheader={<UnitStatusSelector record={record} />}
                    />
                </Header.Main>
                <Header.Aside>
                    <Header.Actions actions={actions} />
                </Header.Aside>
            </Header.Block>
            <Header.Tabs
                tabs={tabs}
                tabWidth="130px"
                tabMinWidth="120px"
            />
        </Header>
    )
}

export default UnitShowHeader

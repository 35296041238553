import { basePermissions } from 'configs/constants'
import { renderOnPermission } from 'hocs'
import { DeleteOneFromListParams, useDeleteOneFromList } from 'hooks'

import { useUtilityDrawerContext } from './UtilityDrawer'

import { UtilityDrawerDeleteButtonView } from '.'

export interface UtilityDrawerDeleteOneFromListButtonProps
    extends Partial<DeleteOneFromListParams> {
    disabled?: boolean
    title?: string
}

const UtilityDrawerDeleteOneFromListButton = renderOnPermission(
    (props: UtilityDrawerDeleteOneFromListButtonProps) => {
        const { forceClose, extraArgs } = useUtilityDrawerContext()
        const deleteOne = useDeleteOneFromList()
        const onSuccess = () => {
            props.onSuccess?.()
            forceClose()
        }

        const { id, resource, listContext } = extraArgs || {}

        return (
            <UtilityDrawerDeleteButtonView
                disabled={props.disabled ?? false}
                title={props.title ?? null}
                onClick={() => {
                    deleteOne({
                        id,
                        resource,
                        listContext,
                        ...props,
                        onSuccess,
                    })
                }}
            />
        )
    },
    basePermissions.destroy,
)

export default UtilityDrawerDeleteOneFromListButton

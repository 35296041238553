import { WorkOrderModel } from 'appTypes/models'
import {
    EditRedirectInListActionProps,
    editRedirectInListAction,
    DownloadOneFromListActionProps,
    downloadOneFromListAction,
} from 'components/actions'

import { pdfOneWOArgs } from '../config/constants'

interface PdfOneWOFromListActionParams {
    record: WorkOrderModel
    children: DownloadOneFromListActionProps['children']
}

export const pdfOneWOFromListAction = ({ record, children }: PdfOneWOFromListActionParams) => {
    return downloadOneFromListAction({
        ...pdfOneWOArgs(record),
        children,
    })
}

export const editRedirectWOFromListAction = (params: EditRedirectInListActionProps) => {
    return editRedirectInListAction(params)
}

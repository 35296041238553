import { ArrowForward, DragIndicator, OpenInFullOutlined } from '@mui/icons-material'
import { alpha, Card, styled, SvgIcon } from '@mui/material'
import { Link } from 'react-router-dom'

export * from './InfoComponents'
export * from './HeaderComponents'
export * from './OverviewWidgets'
export { default as WidgetListFTU } from './WidgetListFTU'
export const WidgetBaseContainer = styled(Card)`
    max-height: 304px;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
`

export const WidgetBaseLink = styled(({ link, ...p }: { link: string }) => {
    return (
        <Link to={link}>
            <SvgIcon
                component={ArrowForward}
                {...p}
            />
        </Link>
    )
})`
    color: ${({ theme }) => theme.palette.text.primary};
    background: ${({ theme }) => alpha(theme.palette.text.primary, 0.04)};
    width: 16px;
    height: 16px;
    border-radius: 4px;
    position: absolute;
    top: 17px;
    right: 15px;
    cursor: pointer;
    z-index: 4;
`

export const WidgetBaseDragIndicator = styled(({ className, ...p }: { className?: string }) => {
    return (
        <SvgIcon
            component={DragIndicator}
            className={`WidgetDrag ${className}`}
            {...p}
        />
    )
})`
    color: ${({ theme }) => theme.palette.text.disabled};
    display: none;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0%) rotate(90deg);
    cursor: pointer;
`
export const WidgetBaseResizeIndicator = styled(({ className, ...p }: { className?: string }) => {
    return (
        <SvgIcon
            component={OpenInFullOutlined}
            className={`WidgetResize ${className}`}
            {...p}
        />
    )
})`
    color: ${({ theme }) => theme.palette.text.disabled};
    display: none;
    position: absolute;
    width: 18px;
    height: 18px;
    bottom: 0;
    right: 0;
    transform: rotate(90deg);
`

import { Routes } from 'react-router-dom'

import { tabRoutes } from 'components'
import { Show } from 'components/resource'

import VendorContacts from '../Contacts/VendorContacts'
import VendorOverview from '../Overview/VendorOverview'

import { VendorShowHeader } from './components'

const VendorShow = () => {
    const { tabs, routes } = tabRoutes([
        {
            value: '',
            label: 'Overview',
            element: <VendorOverview />,
        },
        {
            value: 'contacts',
            label: 'Contact Persons',
            element: <VendorContacts />,
        },
    ])
    return (
        <Show>
            <>
                <VendorShowHeader tabs={tabs} />
                <Routes>{routes}</Routes>
            </>
        </Show>
    )
}

export default VendorShow

import { ReactNode } from 'react'

import { ExtendRecordType } from 'appTypes'

import { pathJoin } from './string'

export const extendRecord = <T = any, Return = ReactNode>(
    record: T,
    source: ExtendRecordType<T, Return>,
) => {
    if (typeof source === 'function') {
        return source(record)
    }

    if (!record) {
        return ''
    }

    return record[source as keyof T]
}

export const generateFilterReference = (base: string, source: string) =>
    base + '/' + source + '_filter_values'

const responseApiTranslate = {}
// this may be used when: client resource name != api resource name
export const translateResourceNameToApiResourceName = (resourceName: string) => {
    const resourceParts = resourceName.split('/')

    return pathJoin(
        ...resourceParts.map(
            (resource) =>
                responseApiTranslate[resource as keyof typeof responseApiTranslate] || resource,
        ),
    )
}

// true or undefined => true, false => false
export const hasPermission = (hasPermission: boolean | undefined) => hasPermission !== false

export const generateResource = <ResourceType extends string, NameType extends string>({
    name,
    resource,
}: {
    name: NameType
    resource: ResourceType
}) => {
    return {
        name,
        resource,
    } as const
}

import { FC } from 'react'

import { Box } from '@mui/material'

import {
    FormNonFieldErrors,
    TextInput,
    Form,
    Typography,
    EmailInput,
    PasswordInput,
    AnchorLink,
} from 'components'
import { urls } from 'configs/config'
import { useAuthProvider, useSubmit } from 'hooks'
import { validateEmail, serialize, passwordValidation, maxLengthValidation } from 'utils'

import { GoogleButton, AuthBody, AuthSubmitButton, SignDivider } from '../components'

import { useRegisterContext, withRegisterContext } from './CompanyDetails'

export interface RegisterFormData {
    name: string
    password: string
    email: string
}

const emailValidation = validateEmail(true)

const Register: FC = () => {
    const authProvider = useAuthProvider()
    const { setUserState } = useRegisterContext()

    const submitHandler = useSubmit<RegisterFormData>(async (formData) => {
        const data = serialize(formData, ['name', { name: 'email', parse: 'email' }, 'password'])
        await authProvider.register(data, true)
        setUserState(data)
    })

    return (
        <AuthBody>
            <Form
                warnWhenUnsavedChanges={false}
                onSubmit={submitHandler}
            >
                <Typography
                    variant="h6"
                    mb="8px"
                >
                    Sign Up
                </Typography>

                <Typography
                    variant="body2"
                    color="text.primary"
                    mb="22px"
                >
                    Please, fill out the information below.
                </Typography>

                <FormNonFieldErrors />

                <TextInput
                    source="name"
                    label="Full Name"
                    validate={maxLengthValidation}
                />

                <EmailInput
                    validate={emailValidation}
                    label="Email Address"
                />

                <PasswordInput
                    validate={passwordValidation}
                    helperText="Use at least 8 characters"
                />

                <AuthSubmitButton
                    label="CONTINUE WITH EMAIL"
                    disabled={false}
                />

                <SignDivider />

                <GoogleButton />

                <Box
                    mt="20px"
                    display="flex"
                    justifyContent="center"
                >
                    <Typography display="inline">Have an account?&nbsp;</Typography>

                    <AnchorLink to={urls.login}>Sign in!</AnchorLink>
                </Box>
            </Form>
        </AuthBody>
    )
}

export default withRegisterContext(Register)

import { IconButton, InputAdornment, InputAdornmentProps } from '@mui/material'

import { Optional } from 'appTypes'

import Icons from './icons'

const ClearAdornment = (props: Optional<InputAdornmentProps, 'position'>) => {
    return (
        <InputAdornment
            position="end"
            {...props}
        >
            <IconButton
                size="small"
                title="Clear value"
            >
                <Icons.Clear />
            </IconButton>
        </InputAdornment>
    )
}

export default ClearAdornment

import { ArchivedProvider, ResourceWithClearEffect } from 'components'
import { useDataProviderConfig } from 'providers/dataProvider'

import UnitForm from './Create/UnitCreate'
import UnitsList from './List'
import UnitShow from './Show'
import { archivedUnitResource, unitResource } from './config/constants'

interface UnitsProps {
    isArchived?: boolean
}

const dpConfig = {
    queryParams: () => ({ withAspects: ['cost_per_meter', 'downtime'] }),
}

const Units = ({ isArchived }: UnitsProps) => {
    const resource = isArchived ? archivedUnitResource : unitResource

    useDataProviderConfig(resource.resource, {
        getOne: dpConfig,
        update: dpConfig,
    })

    return (
        <ArchivedProvider value={isArchived}>
            <ResourceWithClearEffect
                name={resource}
                list={UnitsList}
                create={UnitForm}
                edit={UnitShow}
            />
        </ArchivedProvider>
    )
}

export default Units

import { useListContext, ListControllerResult } from 'react-admin'

import { useArchiveOne } from 'hooks'

import { ArchiveOneParams } from './useArchiveOne'

export interface ArchiveOneFromListParams extends ArchiveOneParams {
    listContext?: ListControllerResult
}

const useArchiveOneFromList = () => {
    const archive = useArchiveOne()
    const listContext = useListContext()

    return ({ listContext: listContextFromParams, ...params }: ArchiveOneFromListParams = {}) => {
        archive({
            redirectTo: '',
            ...params,
            onSuccess: (response) => {
                params.onSuccess?.(response)
                ;(listContextFromParams || listContext).refetch()
            },
        })
    }
}

export default useArchiveOneFromList

import {
    CheckOutlined,
    ExpandOutlined,
    ListOutlined,
    Star,
    StarOutline,
    ArrowBackOutlined,
} from '@mui/icons-material'
import {
    tooltipClasses,
    Breadcrumbs,
    Link,
    Theme,
    useMediaQuery,
    Tooltip,
    breadcrumbsClasses,
    buttonBaseClasses,
    styled,
} from '@mui/material'
import { inject, observer } from 'mobx-react'
import { GetListResult, useChoicesContext } from 'react-admin'
import { useQueryClient } from 'react-query'

import api from 'api'
import { CK33Levels, CK33Model } from 'appTypes/models'
import favoritesImage from 'assets/images/favorites.svg'
import { ActionChildren, ActionsMenu, MenuActionProps } from 'components/actions'
import { Typography } from 'components/mui'
import { useFinalErrorHandler } from 'hooks'
import { AuthStore } from 'providers/authStore'

import DialogSelector, {
    DialogSelectorAppBarButton,
    useDialogSelectorContext,
} from './DialogSelector'
import { DialogSelectorProps } from './DialogSelector/DialogSelector'

export const formatCK33CodeByLevel = (code: string, level: number) => {
    if (level === 0) {
        return code[1]
    }
    return code
        .split('-')
        .filter((s, i) => i < level)
        .join('-')
}

const favoritesSource = 'favorite'

interface DisplayedFiltersInfo {
    parents: CK33Model[]
}

interface FilterValues {
    parent?: string
    [favoritesSource]?: string
}

interface CK33SelectorProps
    extends Partial<Omit<DialogSelectorProps<CK33Model>, 'defaultSelectorValueSource'>> {
    excludeLevelAndBelow?: CK33Levels
}
const ck33Names = {
    [CK33Levels.GROUP]: 'Group',
    [CK33Levels.SYSTEM]: 'System',
    [CK33Levels.ASSEMBLY]: 'Assembly',
    [CK33Levels.COMPONENT]: 'Component',
}

const defaultCk33Levels = [0, 1, 2, 3]

export type GetCk33InfoArgs = AuthStore

export const getCk33Info = (auth: GetCk33InfoArgs, excludeLevelAndBelow?: number) => {
    const maxLevel = auth.currentCompany.ck33Level
    const levels = defaultCk33Levels.filter((level) => {
        if (typeof maxLevel === 'number' && level > maxLevel) {
            return false
        }

        if (typeof excludeLevelAndBelow === 'number' && excludeLevelAndBelow <= level) {
            return false
        }

        return true
    })

    const label = levels.map((level) => ck33Names[level]).join(' / ')

    return {
        levels,
        label,
    }
}

export const Ck33Label = inject('auth')(
    observer(
        ({ excludeLevelAndBelow, auth }: { excludeLevelAndBelow?: number; auth?: AuthStore }) => {
            return <>{getCk33Info(auth, excludeLevelAndBelow).label}</>
        },
    ),
)

const CK33Selector = inject('auth')(
    observer(
        ({ auth, excludeLevelAndBelow, ...props }: CK33SelectorProps & { auth?: AuthStore }) => {
            const { levels, label } = getCk33Info(auth, excludeLevelAndBelow)

            return (
                <DialogSelector<CK33Model>
                    defaultFilter={{ level: levels[0] }}
                    source="component"
                    titleSource={(record) =>
                        record
                            ? formatCK33CodeByLevel(record.code, record.level) + ' ' + record.text
                            : label
                    }
                    reference="vmrs/ck33"
                    itemPrimary={(record) => {
                        const code = formatCK33CodeByLevel(record.code, record.level)
                        if (record.favorite) {
                            return (
                                <>
                                    {code}{' '}
                                    <Star
                                        sx={{
                                            color: (theme) => theme.palette.charts.orange,
                                            width: '16px',
                                            height: '16px',
                                        }}
                                    />
                                </>
                            )
                        }

                        return code
                    }}
                    itemSecondary="text"
                    defaultSelectorProps={{
                        label,
                    }}
                    renderListItem={(record, render) => (
                        <OptionMenu
                            levels={levels}
                            key={record.id}
                            choice={record}
                            renderToggler={(open) => {
                                return render({
                                    onSelect: open,
                                })
                            }}
                        />
                    )}
                    defaultSelectorValueSource={(data) => {
                        return data.selected
                            ? formatCK33CodeByLevel(data.selected.code, data.selected.level) +
                                  ' ' +
                                  data.selected.text
                            : ''
                    }}
                    filter={{
                        search: {
                            placeholder: 'Search by Code or Name',
                        },
                        select: {
                            items: [
                                {
                                    value: '',
                                    Icon: ListOutlined,
                                },
                                {
                                    value: true,
                                    Icon: Star,
                                },
                            ],
                            source: favoritesSource,
                            makeFilters: (filter) => {
                                if (filter[favoritesSource]) {
                                    return { ...filter, level: levels }
                                }
                                return filter
                            },
                        },
                    }}
                    appBar={{
                        leftButton: <BackButton />,
                        paddingX: '12px',
                    }}
                    renderTop={() => <CK33Breadcrumbs />}
                    renderNextToResultCount={() => <CurrentLevel levels={levels} />}
                    noResults={({ filterValues, searchValue }) => {
                        if (filterValues[favoritesSource] && !searchValue) {
                            return {
                                image: favoritesImage,
                                title: 'No Favorites Yet!',
                                text: "Use the 'Add to Favorites' action in the list of items to save them here.",
                            }
                        }
                    }}
                    {...props}
                />
            )
        },
    ),
)

export default CK33Selector

export const CK33SelectorWithoutParts = (props: CK33SelectorProps) => {
    return (
        <CK33Selector
            excludeLevelAndBelow={CK33Levels.COMPONENT}
            {...props}
        />
    )
}

interface OptionMenuProps extends Pick<MenuActionProps, 'renderToggler'> {
    choice: CK33Model
    levels: number[]
}

export const OptionMenu = ({ renderToggler, choice, levels }: OptionMenuProps) => {
    return (
        <ActionsMenu
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            sx={{ position: 'inherit', height: 'fit-content' }}
            PaperProps={{
                sx: {
                    position: 'absolute',
                    top: '100% !important',
                    left: '0 !important',
                    width: '100%',
                    maxWidth: '100%',
                    maxHeight: 'fit-content',
                    zIndex: '1300',
                },
            }}
            disablePortal
            renderToggler={renderToggler}
            actions={(params, { children }) => [
                <Actions
                    key="actions"
                    choice={choice}
                    children={children}
                    levels={levels}
                />,
            ]}
        />
    )
}

const Actions = ({
    children,
    choice,
    levels,
}: {
    children: ActionChildren
    choice: CK33Model
    levels: number[]
}) => {
    const { onSelect, control } = useDialogSelectorContext<CK33Model>()
    const { filterValues, perPage, sort, setFilters, refetch, total, allChoices } = control
    const displayedFilters = control.displayedFilters as DisplayedFiltersInfo
    const errorHandler = useFinalErrorHandler()

    const queryClient = useQueryClient()

    const handleFav = async () => {
        const favorite = !choice.favorite
        try {
            await api.post(`vmrs/ck33/${choice.id}/favorite`, {
                favorite,
            })

            // handle remove with refetch if favorites filter is on
            if (
                filterValues[favoritesSource] &&
                !favorite &&
                allChoices.length < total &&
                allChoices.length <= perPage / 2
            ) {
                refetch()
                return
            }

            queryClient.setQueryData(
                [
                    'vmrs/ck33',
                    'getList',
                    { filter: filterValues, pagination: { page: 1, perPage }, sort },
                ],
                (oldData: GetListResult<CK33Model>) => {
                    let data = oldData.data
                    let total = oldData.total
                    if (!favorite && filterValues[favoritesSource]) {
                        data = data.filter((item) => item.id !== choice.id)
                        total -= 1
                    } else {
                        data = data.map(
                            (item): CK33Model =>
                                item.id === choice.id ? { ...item, favorite } : item,
                        )
                    }

                    return {
                        ...oldData,
                        data,
                        total,
                    }
                },
            )
        } catch (e) {
            errorHandler(e)
        }
    }

    return (
        <>
            {children({ title: 'Select', Icon: CheckOutlined, onClick: () => onSelect(choice.id) })}
            {!filterValues[favoritesSource] && levels.some((level) => level > choice.level)
                ? children({
                      title: 'Expand',
                      Icon: ExpandOutlined,
                      onClick: () => {
                          const _displayedFilters: DisplayedFiltersInfo = {
                              ...displayedFilters,
                              parents: displayedFilters?.parents
                                  ? [...displayedFilters.parents, choice]
                                  : [choice],
                          }

                          const _filterValues: FilterValues = {
                              parent: choice.id,
                          }

                          setFilters(_filterValues, _displayedFilters, false)
                      },
                  })
                : null}
            {choice.favorite
                ? children({
                      title: 'Remove from Favorites',
                      Icon: StarOutline,
                      onClick: handleFav,
                  })
                : children({ title: 'Add To Favorites', Icon: Star, onClick: handleFav })}
        </>
    )
}
const BreadcrumbLink = styled(Link)(({ theme }) => ({
    color: theme.palette.text.secondary,
}))
export const CK33Breadcrumbs = () => {
    const { defaultFilter, control } = useDialogSelectorContext<CK33Model>()
    const { setFilters, filterValues } = control
    const displayedFilters = control.displayedFilters as DisplayedFiltersInfo
    const parents = displayedFilters?.parents || []

    const isExtraSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down(theme.props.mobileViewBreakpoint),
    )

    if (filterValues[favoritesSource]) {
        return null
    }

    const set = (choice: CK33Model, index: number) => {
        if (!parents.length || index === parents.length - 1) {
            return
        }
        const _displayedFilters: DisplayedFiltersInfo = {
            ...displayedFilters,
            parents: choice ? parents.slice(0, index + 1) : [],
        }

        const _filterValues: FilterValues = choice ? { parent: choice.id } : (defaultFilter as {})

        setFilters(_filterValues, _displayedFilters, false)
    }

    return (
        <Breadcrumbs
            maxItems={3}
            aria-label="breadcrumb"
            sx={(theme) => ({
                [`& .${breadcrumbsClasses.ol} .${breadcrumbsClasses.li}:last-of-type a`]: {
                    color: `${theme.palette.text.primary} !important`,
                    cursor: 'default',
                    textDecoration: 'none',
                },
                [`& .${breadcrumbsClasses.ol} li .${buttonBaseClasses.root}`]: {
                    opacity: 0.54,
                },
            })}
        >
            <BreadcrumbLink
                underline="hover"
                onClick={() => set(null, null)}
            >
                Home
            </BreadcrumbLink>
            {parents.map((choice, index) => {
                const { level, code, text } = choice
                const content = (
                    <BreadcrumbLink
                        key={code}
                        underline={index === parents.length - 1 ? 'none' : 'hover'}
                        onClick={() => set(choice, index)}
                    >
                        {ck33Names[level]} {formatCK33CodeByLevel(code, level)}
                    </BreadcrumbLink>
                )
                if (isExtraSmall) {
                    return content
                }
                return (
                    <Tooltip
                        title={`${ck33Names[level]} ${formatCK33CodeByLevel(code, level)} ${text}`}
                        key={code}
                        PopperProps={{
                            sx: {
                                [`& .${tooltipClasses.tooltip}`]: {
                                    maxWidth: '200px',
                                    boxSizing: 'border-box',
                                },
                            },
                        }}
                    >
                        {content}
                    </Tooltip>
                )
            })}
        </Breadcrumbs>
    )
}

const BackButton = () => {
    const { defaultFilter, control } = useDialogSelectorContext<CK33Model>()
    const { setFilters, isFetching } = control
    const displayedFilters = control.displayedFilters as DisplayedFiltersInfo
    const pop = () => {
        const parents = [...displayedFilters.parents]
        parents.pop()
        const choice = parents.at(-1)

        const _filterValues: FilterValues = choice ? { parent: choice.id } : (defaultFilter as {})

        const _displayedFilters: DisplayedFiltersInfo = {
            ...displayedFilters,
            parents,
        }
        setFilters(_filterValues, _displayedFilters, false)
    }

    return (
        <DialogSelectorAppBarButton
            disabled={!displayedFilters?.parents?.length || isFetching}
            onClick={pop}
            aria-label="Back"
        >
            <ArrowBackOutlined />
        </DialogSelectorAppBarButton>
    )
}

const CurrentLevel = ({ levels }: { levels: number[] }) => {
    const { displayedFilters, filterValues } = useChoicesContext()
    const parents = (displayedFilters as DisplayedFiltersInfo)?.parents || []
    const lastChoice = parents.at(-1)

    return (
        <Typography
            variant="h6"
            color="text.primary"
        >
            {filterValues[favoritesSource]
                ? 'Favorites'
                : ck33Names[lastChoice?.level == null ? levels[0] : lastChoice.level + 1]}
        </Typography>
    )
}

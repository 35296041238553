import { ElementType, ReactNode } from 'react'

import { SvgIcon, SvgIconProps, Tooltip, alpha } from '@mui/material'
import { Link, LinkProps } from 'react-router-dom'

import { BoxContainer, StyledElement, Typography } from 'components'

import { useWidgetContext } from '../../GridLayout'

import DotSeparator from './DotSeparator'
import ListItem from './ListItem'

interface renderIconProps extends SvgIconProps {
    icon: ElementType
    tooltip?: string
}
const renderIcon = ({ icon, tooltip, ...props }: renderIconProps) => {
    return (
        <Tooltip title={tooltip}>
            <SvgIcon
                component={icon}
                fontSize="small"
                titleAccess="qqq"
                inheritViewBox
                sx={({ palette }) => ({
                    color: palette.text.disabled,
                    background: alpha(palette.text.primary, 0.12),
                    borderRadius: '2px',
                })}
                {...props}
            />
        </Tooltip>
    )
}
interface WidgetListRowBaseProps {
    link: LinkProps['to']
    label: string
    icon?: (render: (p: renderIconProps) => ReactNode) => ReactNode
    clarification: ReactNode
    hideClarification?: boolean
    total: ReactNode
}
const WidgetListRowBase = ({
    link,
    label,
    clarification,
    hideClarification = false,
    total,
    icon,
}: WidgetListRowBaseProps) => {
    const dimensions = useWidgetContext()
    return (
        <Typography
            component={ListItem}
            variant="chartListItem"
        >
            <BoxContainer
                sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}
            >
                <BoxContainer gap="6px">
                    <Typography
                        component={Link}
                        to={link}
                        color="primary.main"
                        variant="chartListItem"
                        sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            lineHeight: '150%',
                        }}
                    >
                        {label}
                    </Typography>
                    {icon ? icon(renderIcon) : null}
                </BoxContainer>
                {hideClarification || dimensions === 'mdY' ? null : (
                    <>
                        <DotSeparator />
                        <StyledElement
                            sx={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                lineHeight: '150%',
                            }}
                        >
                            {clarification}
                        </StyledElement>
                    </>
                )}
            </BoxContainer>
            {total}
        </Typography>
    )
}
export default WidgetListRowBase

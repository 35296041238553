import { ReactElement } from 'react'

import { inject, observer } from 'mobx-react'

import { Billing } from 'appTypes/models'
import { InfoCard, Columns, Typography } from 'components'
import { renderOnBilling } from 'hocs'
import { AuthStore } from 'providers/authStore'

import { PlanBody, PlanTitle } from './components'
import { PlanBodyProps } from './components/PlanBody'

interface CurrentPlanProps extends Omit<PlanBodyProps, 'subscription'> {
    subscription?: Billing['subscription']
    topTitle?: ReactElement
}

const CurrentPlan = inject('auth')(
    observer(
        ({
            auth,
            buttonProps,
            subscription,
            topTitle,
        }: { auth?: AuthStore } & CurrentPlanProps) => {
            const billing = auth.billing
            const subscriptionType = subscription || billing.subscription

            return (
                <InfoCard sx={{ padding: '20px' }}>
                    <Columns>
                        <Typography
                            variant="chartTitle"
                            color="text.secondary"
                        >
                            {topTitle || 'Current Plan'}
                        </Typography>

                        <PlanTitle subscription={subscriptionType} />
                        <PlanBody
                            buttonProps={buttonProps}
                            subscription={subscriptionType}
                        />
                    </Columns>
                </InfoCard>
            )
        },
    ),
)

export default renderOnBilling(CurrentPlan)

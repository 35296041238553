import { WidgetHeaderProps } from 'pages/Dashboard/components/WidgetBase/WidgetHeader'

export { default as UnitCostWidget } from './UnitCostWidget'
export { default as UnitCostPerMileWidget } from './UnitCostPerMileWidget'
export { default as SinceLastServiceTrackWidget } from './SinceLastServiceTrackWidget'
export { default as UnitProgress } from './UnitProgress'
export { default as UnitPMWidget } from './UnitPMWidget'
export { default as UnitDowntimeWidget } from './UnitDowntimeWidget'
export { default as UnitDVIRWidget } from './UnitDVIRWidget'

export const baseContainerProps: WidgetHeaderProps['containerProps'] = {
    sx: {
        height: '140px',
        '& .WidgetBaseTitle': {
            paddingBottom: '22px',
        },
    },
}

import { FC, useEffect, useRef, ReactElement } from 'react'

import { Alert, Tooltip } from '@mui/material'
import { useChoicesContext, useRecordContext } from 'react-admin'
import { FieldArrayWithId, useFieldArray, useFormContext, useWatch } from 'react-hook-form'

import { MeterModel, UnitPMReminderModel, CK14Model } from 'appTypes/models'
import {
    ArrayControllerBox,
    ArrayControllerElements,
    AutocompleteInput,
    Button,
    CK33SelectorWithoutParts,
    DateTimeInput,
    ReferenceInput,
    StyledElement,
    TextareaInput,
    TextInput as TextInputBase,
    useArrayControllerContext,
    useArrayControllerElementContext,
} from 'components'
import { ArrayControllerContextProviderBase } from 'components/ArrayController/ArrayControllerBase'
import { useUtilityDrawerContext } from 'components/Drawer/UtilityDrawer'
import UnderInputTooltip from 'components/UnderInputTooltip'
import { ActionsMenu } from 'components/actions'
import Icons from 'components/icons'
import { maxLengthValidationText, requiredValidation, validateMaxLength } from 'utils'

import { getMeterTypeAdornment, meterTypesConfig } from '../../Meters/constants'
import { PMLastMetersFetch } from '../PMLastMeters'

import UnitPMMeterBase from './UnitPMMeterBase'
import UnitPMMeterEngineHours from './UnitPMMeterEngineHours'
import UnitPMMeterTime from './UnitPMMeterTime'
import { UnitPMDrawerEditorExtraState } from './useUnitPMDrawerEditor'

const TextInput = TextInputBase<UnitPMReminderModel>

export const ReasonForRepairDefaultValueBase: FC<{ code: string }> = ({ code }) => {
    const choices = useChoicesContext<CK14Model>()
    const didInit = useRef(false)
    const { setValue } = useFormContext()

    useEffect(() => {
        if (!choices.isFetching && !didInit.current) {
            didInit.current = true
            const reasonForRepairValue: CK14Model = choices.allChoices.find(
                (choice: CK14Model) => choice.code === code,
            )
            if (reasonForRepairValue) {
                setValue('reasonForRepair', reasonForRepairValue.id)
            }
        }
    }, [choices.isFetching])

    return null
}

export const ReasonForRepairDefaultValue = ({ code }: { code: string }) => {
    const id = useWatch({ name: 'id' })

    if (id) {
        return null
    }

    return <ReasonForRepairDefaultValueBase code={code} />
}

const nameValidation = [validateMaxLength(100), requiredValidation]

const UnitPMForm = () => {
    const id = useWatch({ name: 'id' })
    const { extra } = useUtilityDrawerContext()
    const { unit, isArchived } = extra as UnitPMDrawerEditorExtraState

    return (
        <>
            <TextInput
                disabled={isArchived}
                source="name"
                label="Name"
                validate={nameValidation}
            />

            <CK33SelectorWithoutParts
                source="component"
                required
                disabled={isArchived}
            />

            <ReferenceInput
                source="reasonForRepair"
                reference="vmrs/ck14"
                perPage={100}
                disabled={isArchived}
            >
                <>
                    <AutocompleteInput
                        optionText={(record) => record.id && `${record.code} ${record.description}`}
                        label="Reason for Repair"
                        disabled={isArchived}
                    />
                    <ReasonForRepairDefaultValue code="08" />
                </>
            </ReferenceInput>

            <TextareaInput
                disabled={isArchived}
                source="description"
                label="Notes"
                validate={maxLengthValidationText}
            />
            <UnderInputTooltip title={id ? 'Use interval Reset action to edit this value' : ''}>
                <div>
                    <DateTimeInput
                        disableFuture
                        source="lastDone"
                        label="Last Done Date"
                        validate={requiredValidation}
                        disabled={Boolean(id) || isArchived}
                        defaultValue={new Date()}
                    />
                </div>
            </UnderInputTooltip>
            <PMLastMetersFetch
                unitId={unit.id}
                archived={unit.archived}
            />
            <Intervals />
        </>
    )
}

export default UnitPMForm

export type PMIntervalRow = FieldArrayWithId<
    Omit<UnitPMReminderModel, 'workOrderData'>,
    'intervals',
    'id'
>

const Intervals = () => {
    const { control } = useFormContext<Omit<UnitPMReminderModel, 'workOrderData'>>()

    const fields = useFieldArray({
        control,
        name: 'intervals',
    })

    return (
        <ArrayControllerContextProviderBase<PMIntervalRow>
            value={{
                array: fields.fields,
                append: (item) => {
                    fields.append(item, { shouldFocus: false })
                    return item
                },
                remove: ({ index }) => {
                    fields.remove(index)
                },
                setArray: () => {
                    //
                },
                limit: 2,
            }}
        >
            <UnitPMFormMeters />
        </ArrayControllerContextProviderBase>
    )
}

type MeterTypes = MeterModel['type'] | 'TIME'
const meterTypes = [
    meterTypesConfig.ODOMETER,
    meterTypesConfig.TIME,
    meterTypesConfig.ENGINE_HOURS,
    meterTypesConfig.HUBOMETER,
]

const UnitPMFormMeters = () => {
    const { append, array, limit } = useArrayControllerContext<PMIntervalRow>()

    const addButtonDisabled = array.length === limit
    const { extra } = useUtilityDrawerContext()
    const { isArchived } = extra as UnitPMDrawerEditorExtraState

    return (
        <>
            <ActionsMenu
                actions={(args, { children }) =>
                    meterTypes.map(({ name, Icon, id }) =>
                        array?.some((item) => item.type === id)
                            ? null
                            : children({
                                  title: name,
                                  key: id,
                                  Icon,
                                  onClick: () => {
                                      append({ type: id } as PMIntervalRow)
                                  },
                              }),
                    )
                }
                renderToggler={(open) => (
                    <Tooltip
                        title={addButtonDisabled ? 'Maximum of two intervals supported' : ''}
                        placement="top"
                        arrow
                    >
                        <StyledElement
                            as="span"
                            sx={{ mb: '32px', display: 'inline-block' }}
                        >
                            <Button
                                disabled={addButtonDisabled || isArchived}
                                onClick={open}
                                startIcon={<Icons.Add />}
                                variant="contained"
                                size="large"
                            >
                                Add Meter Type
                            </Button>
                        </StyledElement>
                    </Tooltip>
                )}
            />
            {!array.length && (
                <>
                    <TextInputBase
                        sx={{ display: 'none !important' }}
                        source="hidden"
                        validate={requiredValidation}
                        type="hidden"
                    />
                    <Alert
                        severity="info"
                        sx={{ mt: '7px' }}
                    >
                        Add at least one interval meter to continue
                    </Alert>
                </>
            )}
            <ArrayControllerElements<PMIntervalRow> itemKey={({ item }) => item.id}>
                <UnitPMFormMeter />
            </ArrayControllerElements>
        </>
    )
}

interface MeterData {
    component: ReactElement
    title: string
}

const meters: { [key in MeterTypes]: MeterData } = {
    ODOMETER: {
        component: <UnitPMMeterBase endAdornment={getMeterTypeAdornment('ODOMETER')} />,
        title: 'Odometer',
    },
    ENGINE_HOURS: {
        component: <UnitPMMeterEngineHours />,
        title: 'Engine Hours',
    },
    HUBOMETER: {
        component: <UnitPMMeterBase endAdornment={getMeterTypeAdornment('HUBOMETER')} />,
        title: 'Hubometer',
    },
    TIME: {
        component: <UnitPMMeterTime />,
        title: 'Time Meter',
    },
}

const UnitPMFormMeter = () => {
    const { item, index } = useArrayControllerElementContext<{ valueType: PMIntervalRow }>()
    const type = item.type
    const meterData = meters[type as MeterTypes] || ({} as MeterData)
    const recordContext = useRecordContext<UnitPMReminderModel>()
    const canDelete = !recordContext?.intervals?.[index]

    return (
        <ArrayControllerBox
            renderDeleteButton={
                canDelete
                    ? ({ defaultButton }) => defaultButton({ controller: { alwaysVisible: true } })
                    : () => null
            }
            title={meterData.title}
        >
            {meterData.component}
        </ArrayControllerBox>
    )
}

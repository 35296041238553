import { GlobalStylesProps } from '@mui/material'
import { pickersToolbarClasses } from '@mui/x-date-pickers/internals'

import { galleryStyles } from 'components'

export const cssVariables = {
    pageContentPadding: '--page-content-padding',
}

const globalStyle: GlobalStylesProps['styles'] = (theme) => {
    return `

        :root {
            ${cssVariables.pageContentPadding}: 0px;
        }
        
        html {
            min-height: 100%;
            height:100%;
        }
        body{
            height:100%;
        }
        #root {
            box-sizing: border-box;
            display: flex;
            min-height:100%;
            flex-direction: column;
            position: relative;
        }
    
        
        html body {
            padding: 0;
            margin: 0;
            min-height:100%;
            background-color: ${theme.palette.gray};
        }
        
        input {
            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none;
            filter: none;
            border: 0;
        
            &::-ms-reveal,
            &::-ms-clear {
                display: none;
            }
        }
        
        a {
            text-decoration: none;
        }

        .d-none {
            display: none !important;
        }

        .${pickersToolbarClasses.penIconButton} {
            display: none !important;
        }
        ${galleryStyles(theme)}
        
        .scroll-lock {
            overflow: hidden;
        }
    `
}

export default globalStyle

import { RaRecord } from 'react-admin'

import { useUtilityDrawerContext } from './UtilityDrawer'
import UtilityDrawerEditor, { UtilityDrawerEditorProps } from './UtilityDrawerEditor'

const UtilityDrawerListEditor = <RecordType extends RaRecord = any>(
    props: UtilityDrawerEditorProps<RecordType>,
) => {
    const { extraArgs } = useUtilityDrawerContext()

    const listContext = extraArgs!.listContext

    const onSuccess = (record) => {
        props.onSuccess?.(record)
        listContext!.refetch()
    }

    return (
        <UtilityDrawerEditor
            {...props}
            onSuccess={onSuccess}
        />
    )
}

export default UtilityDrawerListEditor

import { LoadingButton } from '@mui/lab'
import { Box } from '@mui/material'

import Typography from 'components/mui/Typography'
import { useReferenceInputInfiniteScroll, useReferenceInputTotal, useWillUnmount } from 'hooks'

const DialogSelectorLoadMore = () => {
    const { reset, loadMoreResults, choicesController, loading } = useReferenceInputInfiniteScroll()

    const { availableChoices, isFetching } = choicesController

    const total = useReferenceInputTotal()

    const isLoadingProgress = isFetching || loading

    const totalFetchedCount = availableChoices.length
    const allFetched = totalFetchedCount === total

    useWillUnmount(reset)

    if (allFetched) {
        return null
    }

    return (
        <Box
            mt="auto"
            p="10.5px 20px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
                boxShadow: 6,
            }}
        >
            {isLoadingProgress ? (
                <span />
            ) : (
                <Typography
                    variant="caption"
                    color="textPrimary"
                >
                    {totalFetchedCount} / {total} Showing
                </Typography>
            )}

            <LoadingButton
                size="small"
                onClick={() => loadMoreResults()}
                loading={isLoadingProgress}
                disabled={allFetched}
            >
                See More
            </LoadingButton>
        </Box>
    )
}

export default DialogSelectorLoadMore

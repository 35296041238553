import { useFormState } from 'react-hook-form'

import { FullPageLoaderState } from './FullPageLoader'
import { useToggleFullPageLoader } from './hooks'

const FormLoadingFullPageLoader = ({ state }: { state?: FullPageLoaderState }) => {
    const { isSubmitting } = useFormState()

    useToggleFullPageLoader({ isOpen: isSubmitting, state })

    return null
}

export default FormLoadingFullPageLoader

import { Create, PageContent } from 'components'

import { PartForm } from '../components'
import { partSerializer } from '../config/constants'

import { CreatePartHeader } from './components'

const PartCreate = () => {
    return (
        <Create serializer={partSerializer}>
            <CreatePartHeader />
            <PageContent>
                <PartForm />
            </PageContent>
        </Create>
    )
}

export default PartCreate

import { ArrowForward } from '@mui/icons-material'
import { inject, observer } from 'mobx-react'
import { useListContext, useShowContext } from 'react-admin'

import { PartModel } from 'appTypes/models'
import {
    BoxContainer,
    CardListCard,
    Columns,
    Section,
    Skeleton,
    SkeletonSliderCardContainer,
    SliderView,
    ViewHeader,
} from 'components'
import { useCreateResourcePath } from 'hooks'
import {
    AddReferenceButton,
    getCrossReferenceCardsCfg,
} from 'pages/Parts/CrossReference/CrossReference'
import { partsResource } from 'pages/Parts/config/constants'
import { BoxLink } from 'pages/Units/Meters/components'
import { AuthStore } from 'providers/authStore'

import NoResultCard from './NoResultCard'
import SeeAllButton from './SeeAllButton'

const PartCrossReferenceSkeletonCard = () => {
    return (
        <SkeletonSliderCardContainer
            sx={{
                height: '243px',
            }}
        >
            <Columns
                gap="20px"
                height="100%"
            >
                <BoxContainer gap="12px">
                    <Skeleton
                        variant="circular"
                        height="42px"
                        width="42px"
                        sx={{ flexShrink: '0' }}
                    />
                    <Columns
                        gap="12px"
                        width="100%"
                    >
                        <Skeleton height="8px" />
                        <Skeleton
                            height="8px"
                            width="120px"
                        />
                    </Columns>
                </BoxContainer>

                <Skeleton />
            </Columns>
        </SkeletonSliderCardContainer>
    )
}
const sliderSkeletons = new Array(4).fill(0).map((v, i) => {
    return <PartCrossReferenceSkeletonCard key={i} />
})
const PartCrossReference = inject('auth')(
    observer(({ auth }: { auth?: AuthStore }) => {
        const createPath = useCreateResourcePath()
        const { isLoading, data, total, resource } = useListContext()
        const { record } = useShowContext()
        const navigateTo = (record: PartModel) =>
            createPath({
                resource: partsResource.resource,
                id: record.id,
                type: 'edit',
            })

        let content
        if (isLoading) {
            content = <SliderView items={sliderSkeletons} />
        } else if (total) {
            const items = data.map((part) => (
                <CardListCard<PartModel>
                    cardsCfg={getCrossReferenceCardsCfg(navigateTo, auth, {
                        name: 'cross-reference',
                        resource: navigateTo(record) + '/cross-references',
                    })}
                    record={part}
                    key={part.id}
                />
            ))
            if (total >= 4) {
                items.push(
                    <BoxLink
                        to="cross-reference"
                        label="SEE ALL CROSS-REFERENCES"
                        icon={<ArrowForward />}
                    />,
                )
            }
            content = <SliderView items={items} />
        } else {
            content = <NoResultCard path={resource} />
        }

        return (
            <Section>
                <ViewHeader
                    title="Cross-reference"
                    loading={isLoading}
                >
                    <ViewHeader.Content placement="rightMobile">
                        <AddReferenceButton path={resource} />
                        {total ? <SeeAllButton /> : null}
                    </ViewHeader.Content>
                </ViewHeader>
                {content}
            </Section>
        )
    }),
)

export default PartCrossReference

import Icons from 'components/icons'

import SubmitButton from './SubmitButton'

const CreateButton = () => {
    return (
        <SubmitButton
            label="CREATE"
            size="large"
            icon={<Icons.Add />}
        />
    )
}

export default CreateButton

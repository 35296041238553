import { FC, Fragment, ReactElement } from 'react'

import { PercentOutlined, WorkOutlineOutlined } from '@mui/icons-material'
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined'
import { useListContext } from 'react-admin'

import { WOJobItemTypes, JobModel } from 'appTypes/models'
import { ReactComponent as FeeIcon } from 'assets/images/fee.svg'
import {
    ViewHeader,
    InfoCardHeaderProps,
    GridContainer,
    GridItemLayoutProps,
    GridItemLayout,
    GridBreak,
    useResource,
    ListTotalBadge,
} from 'components'
import { DeleteOneFromListActionWithShowRefetch } from 'components/actions'
import Icons from 'components/icons'
import { partsAvatarConfig } from 'pages/Parts/config/constants'

import { useIsVenderWo } from '../Overview/WorkOrderOverview'

import WorkOrderJobCard, {
    WorkOrderJobCardActions,
    WorkOrderJobCardItemsActions,
} from './WorkOrderJobCard'
import {
    LaborCreateDrawerToggler,
    PartCreateDrawerToggler,
    VendorWorkOrderExtraActionsDrawerToggler,
} from './WorkOrderJobCard/components'
import WorkOrderJobsEmpty from './WorkOrderJobsEmpty'
import { deleteJobAction, EditJobDrawerToggler } from './config/constants'

const actions: WorkOrderJobCardActions = (job, { children, resource, listContext }) => [
    <EditJobDrawerToggler
        listContext={listContext}
        job={job}
        resource={resource}
        key="edit"
        children={({ onClick }) => children({ title: 'Edit', Icon: Icons.Edit, onClick })}
    />,
    <DeleteOneFromListActionWithShowRefetch
        {...deleteJobAction(job.id, resource)}
        key="delete"
        id={job.id}
        resource={resource}
        children={children}
        listContext={listContext}
    />,
]

interface WorkOrderJobsSectionProps
    extends InfoCardHeaderProps,
        Pick<GridItemLayoutProps, 'gridColumns'> {
    action: ReactElement
}

const WorkOrderJobsSection: FC<WorkOrderJobsSectionProps> = ({ action, gridColumns }) => {
    const jobsListContext = useListContext<JobModel>()
    const isVendorWorkOrder = useIsVenderWo()

    const itemActions: WorkOrderJobCardItemsActions = (
        job,
        { children, resource, listContext },
    ) => {
        return [
            <PartCreateDrawerToggler
                key={WOJobItemTypes.part}
                resource={resource}
                listContext={listContext}
                children={({ onClick }) =>
                    children({ title: 'Add Part', Icon: partsAvatarConfig.Icon, onClick })
                }
            />,
            <LaborCreateDrawerToggler
                key={WOJobItemTypes.labor}
                resource={resource}
                listContext={listContext}
                job={job}
                children={({ onClick }) =>
                    children({
                        title: 'Add Labor',
                        Icon: ScheduleOutlinedIcon,
                        onClick,
                    })
                }
            />,
            <VendorWorkOrderExtraActionsDrawerToggler
                key={WOJobItemTypes.service}
                resource={resource}
                listContext={listContext}
                title="Add Services"
                itemResource="service"
                children={({ onClick }) =>
                    children({
                        title: 'Add Services',
                        Icon: WorkOutlineOutlined,
                        onClick,
                    })
                }
            />,
            ...(isVendorWorkOrder
                ? [
                      <VendorWorkOrderExtraActionsDrawerToggler
                          key={WOJobItemTypes.fee}
                          resource={resource}
                          listContext={listContext}
                          title="Add Fee"
                          itemResource="fee"
                          children={({ onClick }) =>
                              children({
                                  title: 'Add Fee',
                                  Icon: FeeIcon,
                                  onClick,
                              })
                          }
                      />,
                      <VendorWorkOrderExtraActionsDrawerToggler
                          key={WOJobItemTypes.tax}
                          resource={resource}
                          listContext={listContext}
                          title="Add Tax"
                          itemResource="tax"
                          children={({ onClick }) =>
                              children({
                                  title: 'Add Tax',
                                  Icon: PercentOutlined,
                                  onClick,
                              })
                          }
                      />,
                  ]
                : []),
        ]
    }
    const { data = [], total, isLoading } = jobsListContext

    const resource = useResource()

    if (isLoading) {
        return null
    }

    return (
        <div>
            <ViewHeader title="Jobs">
                <ViewHeader.Content>
                    <ListTotalBadge />
                </ViewHeader.Content>
                <ViewHeader.Content placement="rightMobile">{action}</ViewHeader.Content>
            </ViewHeader>
            {total ? (
                <GridContainer spacing={{ xs: 3, sm: 6 }}>
                    {data.map((job) => (
                        <Fragment key={job.id}>
                            <GridItemLayout>
                                <WorkOrderJobCard
                                    job={job}
                                    jobsListContext={jobsListContext}
                                    resource={resource}
                                    actions={actions}
                                    itemActions={itemActions}
                                />
                            </GridItemLayout>
                            <GridBreak />
                        </Fragment>
                    ))}
                </GridContainer>
            ) : (
                <WorkOrderJobsEmpty />
            )}
        </div>
    )
}

export default WorkOrderJobsSection

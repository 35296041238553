import { createContext, ReactElement, useContext } from 'react'

import { RaRecord } from 'react-admin'

import { DialogSelectorUniversalParam } from './DialogSelector'

export interface DialogSelectorContextResult<T extends RaRecord = RaRecord>
    extends DialogSelectorUniversalParam<T> {}

export const DialogSelectorContext = createContext<DialogSelectorContextResult<any>>(null)

export const useDialogSelectorContext = <
    T extends RaRecord = RaRecord,
>(): DialogSelectorContextResult<T> => useContext(DialogSelectorContext)

export interface DialogSelectorProviderProps<T extends RaRecord> {
    children: ReactElement
    value: DialogSelectorContextResult<T>
}

export const DialogSelectorProvider = <T extends RaRecord>({
    children,
    value,
}: DialogSelectorProviderProps<T>) => {
    return <DialogSelectorContext.Provider value={value}>{children}</DialogSelectorContext.Provider>
}

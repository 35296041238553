import { Dispatch, SetStateAction, useRef, useState } from 'react'

import { selectClasses, Popper, Button, Paper, ClickAwayListener } from '@mui/material'
import { Form } from 'react-admin'
import { useFormContext, UseFormReturn } from 'react-hook-form'

import { BoxContainer, Columns, DateInput, SelectInput, StyledElement } from 'components'
import { dateRangeObject, dateRanges, dateTimeRangeFormats } from 'utils'

const dateRangeOptions = dateRanges.filter((date) => date.id !== 'today')
const CustomPeriodFormContent = ({
    periodForm,
    setCurrMenu,
}: {
    setCurrMenu: Dispatch<SetStateAction<'select' | 'custom'>>
    periodForm: UseFormReturn
}) => {
    const { getValues } = useFormContext()
    return (
        <Columns
            gap="18px"
            padding="16px"
            sx={{
                '& .MuiFormHelperText-root': {
                    display: 'none',
                },
            }}
        >
            <DateInput
                returnDateFormat={dateTimeRangeFormats.from}
                source="customFrom"
                disableFuture
                isRequired
                defaultValue={periodForm.getValues('dateFrom')}
                openTo="day"
                label="From"
            />

            <DateInput
                returnDateFormat={dateTimeRangeFormats.to}
                source="customTo"
                disableFuture
                isRequired
                defaultValue={periodForm.getValues('dateTo')}
                openTo="day"
                label="To"
            />
            <BoxContainer
                justifyContent="space-between"
                mt="14px"
            >
                <Button
                    onClick={() => {
                        setCurrMenu('select')
                    }}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        const from = getValues('customFrom')
                        const to = getValues('customTo')
                        if (!from || !to) {
                            return
                        }

                        periodForm.setValue('dateFrom', from)
                        periodForm.setValue('dateTo', to)
                        periodForm.setValue('date', 'custom')
                        setCurrMenu(null)
                    }}
                >
                    Apply
                </Button>
            </BoxContainer>
        </Columns>
    )
}
const PeriodSelectorInput = () => {
    const form = useFormContext()
    const anchorEl = useRef<HTMLDivElement>(null)
    const [currMenu, setCurrMenu] = useState<'select' | 'custom'>(null)

    return (
        <>
            <Popper
                anchorEl={anchorEl.current}
                open={currMenu === 'custom'}
            >
                <ClickAwayListener
                    onClickAway={() => {
                        setCurrMenu(null)
                    }}
                >
                    <Paper>
                        <Form>
                            <CustomPeriodFormContent
                                setCurrMenu={setCurrMenu}
                                periodForm={form}
                            />
                        </Form>
                    </Paper>
                </ClickAwayListener>
            </Popper>
            <StyledElement ref={anchorEl}>
                <SelectInput
                    disableEmptyValue
                    helperText=""
                    source="date"
                    choices={[
                        ...dateRangeOptions,
                        {
                            id: 'custom',
                            name: 'Custom',
                        },
                    ]}
                    SelectProps={{
                        open: currMenu === 'select',
                        onOpen: () => setCurrMenu('select'),
                        onClose: (e) => {
                            const target = e.target as HTMLDivElement
                            setCurrMenu(target.innerText === 'Custom' ? 'custom' : null)
                        },
                        onChange: (event) => {
                            const value = event.target.value as string

                            if (value === 'custom') {
                                return
                            }

                            const [from, to] = dateRangeObject[value].values('string')

                            form.setValue('dateFrom', from)
                            form.setValue('dateTo', to)
                            form.setValue('date', value)
                        },

                        MenuProps: {
                            sx: ({ palette }) => ({
                                '& .custom': {
                                    color: palette.primary.main,
                                    borderTop: '1px solid ' + palette.text.disabled,
                                    padding: '24px',
                                },
                                '& .custom-placeholder': {
                                    opacity: 0,
                                    padding: '8px',
                                    paddingLeft: '10px',
                                },
                            }),
                        },
                    }}
                    optionText={(choice) => {
                        if (choice.id !== 'custom') {
                            return choice.name
                        }
                        return (
                            <>
                                {/* Hack for padding */}
                                <BoxContainer className="custom-placeholder">
                                    {choice.name}
                                </BoxContainer>
                                <BoxContainer
                                    className="custom"
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        padding: 'inherit',
                                        height: '100%',
                                        minHeight: 'fit-content',
                                    }}
                                >
                                    {choice.name}
                                </BoxContainer>
                            </>
                        )
                    }}
                    variant="standard"
                    label=""
                    FormHelperTextProps={{
                        sx: {
                            display: 'none',
                        },
                    }}
                    sx={{
                        width: 'unset',
                        mb: 0,
                        '& .MuiMenu-list': {
                            background: 'red',
                            '.custom': {
                                background: 'red',
                            },
                        },
                    }}
                    InputProps={{
                        sx: ({ palette }) => ({
                            fontSize: '12px',
                            minWidth: '95px',
                            marginTop: 'unset !important',
                            backgroundColor: 'unset !important',
                            color: palette.text.primary + ' !important',
                            '&::before, &::after': {
                                content: 'unset',
                            },
                            [`& .${selectClasses.select}`]: {
                                padding: '5px 0px 5px 5px',
                                color: 'red',
                            },
                        }),
                    }}
                    clearable={false}
                />
            </StyledElement>
        </>
    )
}

export default PeriodSelectorInput

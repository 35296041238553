import { PageContent, SliderView } from 'components'
import { SliderViewProps } from 'components/Slider/SliderView'
import { TotalExpensesWidget } from 'pages/Vendors/components'

const baseSlides: SliderViewProps['items'] = [<TotalExpensesWidget />]

const VendorTopSection = () => {
    const slides = [...baseSlides]

    return (
        <PageContent
            bgcolor={(theme) => theme.palette.darkGray}
            flexGrow="initial !important"
        >
            <SliderView items={slides} />
        </PageContent>
    )
}

export default VendorTopSection

import { styled, autocompleteClasses } from '@mui/material'

export const CreateOptionLi = styled('li')`
    color: ${({ theme }) => theme.palette.primary.main};
`
export const StyledList = styled('ul')`
    li.${autocompleteClasses.option} {
        &:hover,
        &:focus,
        &:active {
            background: rgba(0, 0, 0, 0.04);
        }
    }
`

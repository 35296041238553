import { FC } from 'react'

import { WorkOrderModel } from 'appTypes/models'
import { DateTimeInput } from 'components'
import { requiredValidation, validateDateBefore } from 'utils'

import WoCloseFields from './WoCloseFields'

const complitionDateValidator = [
    validateDateBefore('started', 'Completion date cannot be before starting date'),
    requiredValidation,
]

const WorkOrderStatusCloseContent: FC<{ workOrder: WorkOrderModel }> = ({ workOrder }) => {
    return (
        <>
            <DateTimeInput
                source="started"
                label="Start Date &amp; Time"
                disabled
                defaultValue={workOrder.started}
                validate={requiredValidation}
            />
            <DateTimeInput
                source="completed"
                label="Completion Date &amp; Time"
                disableFuture
                validate={complitionDateValidator}
            />
            <WoCloseFields workOrder={workOrder} />
        </>
    )
}

export default WorkOrderStatusCloseContent

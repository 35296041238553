import { FC, useEffect } from 'react'

import { inject, observer } from 'mobx-react'
import { Navigate, useParams } from 'react-router-dom'

import { useLogout } from 'hooks'
import { AuthStore } from 'providers/authStore'

const LogoutInvatation: FC<{ auth?: AuthStore }> = inject('auth')(
    observer(({ auth }) => {
        const { token } = useParams()
        const logout = useLogout()

        useEffect(() => {
            if (auth.user) {
                logout()
            }
        }, [])

        if (auth.user) {
            return null
        }

        return (
            <Navigate
                to={{
                    pathname: `/invitations/accept/${token}`,
                }}
            />
        )
    }),
)

export default LogoutInvatation

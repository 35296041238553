import { ReceiptOutlined, SendOutlined } from '@mui/icons-material'
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'
import { Icon } from '@mui/material'
import { useShowContext } from 'react-admin'

import { WorkOrderModel } from 'appTypes/models'
import { ReactComponent as VendorWOIcon } from 'assets/images/vendorsWO.svg'
import { ShowHeaderSkeleton, TabType } from 'components'
import { Action, ActionsMenu, deleteOneAction, downloadOneAction } from 'components/actions'
import Icons from 'components/icons'
import Header from 'components/pages/header'
import { WithIsVendorWO, useIsVenderWo } from 'pages/WorkOrders/Overview/WorkOrderOverview'
import { WorkOrderSubtitle } from 'pages/WorkOrders/components'
import { SendPoActionToggler } from 'pages/WorkOrders/components/WorkOrderPurchaseInvoice'
import {
    deleteWorkOrderAction,
    pdfOneWOArgs,
    poResource,
    vendorWODeleteDialogAction,
} from 'pages/WorkOrders/config/constants'

const setTitleOnDisabled = (status: WorkOrderModel['status']) => {
    if (status === 'CANCELED') {
        return 'Canceled WOs cannot be deleted'
    }
    if (status === 'CLOSED') {
        return 'Closed WOs cannot be deleted'
    }
    return null
}
const actions: Action<WorkOrderModel> = (record, { children, resource }) => {
    return [
        <WithIsVendorWO key="send-purchase-wo">
            {(isVendorWorkOrder) =>
                isVendorWorkOrder &&
                record.status !== 'CANCELED' && (
                    <SendPoActionToggler
                        children={({ onClick }) =>
                            children({
                                Icon: SendOutlined,
                                title: 'Send Purchase Order',
                                key: 'send-purchase-wo',
                                onClick,
                            })
                        }
                    />
                )
            }
        </WithIsVendorWO>,
        <WithIsVendorWO key="export-pdf">
            {(isVendorWorkOrder) =>
                isVendorWorkOrder && (
                    <ActionsMenu
                        record={record}
                        renderToggler={(open) =>
                            children({
                                Icon: Icons.Pdf,
                                key: 'export-pdf',
                                title: 'Export as PDF',
                                onClick: (e) => open(e),
                                disableCloseOnClick: true,
                            })
                        }
                        actions={(params, { children }) => [
                            downloadOneAction({
                                children: (params) =>
                                    children({
                                        ...params,
                                        Icon: AssignmentOutlinedIcon,
                                        key: 'export-pdf',
                                        title: 'Export Work Order',
                                    }),
                                ...pdfOneWOArgs(record),
                            }),
                            downloadOneAction({
                                children: (params) =>
                                    children({
                                        ...params,
                                        Icon: ReceiptOutlined,
                                        key: 'export-purchaseorder',
                                        title: 'Export Purchase Order',
                                    }),
                                resource: poResource,
                                id: record.purchaseOrderData.id,
                                filename: record.purchaseOrderData.number,
                                type: 'pdf',
                            }),
                        ]}
                    />
                )
            }
        </WithIsVendorWO>,
        <WithIsVendorWO key="downloadOne">
            {(isVendorWorkOrder) =>
                !isVendorWorkOrder &&
                downloadOneAction({
                    children,
                    ...pdfOneWOArgs(record),
                })
            }
        </WithIsVendorWO>,
        <WithIsVendorWO key="deleteOne">
            {(isVendorWorkOrder) =>
                deleteOneAction({
                    disabled: record.status !== 'OPEN' || undefined,
                    id: record.id,
                    ...(isVendorWorkOrder && record.purchaseOrderData?.totalInvoices
                        ? vendorWODeleteDialogAction()
                        : deleteWorkOrderAction(record, resource!)),
                    children,
                    title: setTitleOnDisabled(record.status),
                })
            }
        </WithIsVendorWO>,
    ]
}
const WOShowHeader = ({ tabs }: { tabs: TabType[] }) => {
    const { refetch, record: workOrder } = useShowContext<WorkOrderModel>()

    const isVendorWorkOrder = useIsVenderWo()
    if (!workOrder) {
        return <ShowHeaderSkeleton />
    }
    return (
        <Header fixed>
            <Header.Block divider="hideOnMobile">
                <Header.BackButton />
                <Header.Main>
                    <Header.Info
                        title={<Header.Title>{workOrder.number}</Header.Title>}
                        avatar={
                            <Header.Avatar
                                defaultImage={
                                    isVendorWorkOrder ? (
                                        <Icon component={VendorWOIcon} />
                                    ) : (
                                        <AssignmentOutlinedIcon />
                                    )
                                }
                            />
                        }
                        subheader={
                            <WorkOrderSubtitle
                                workOrder={workOrder}
                                onStatusChange={async () => {
                                    refetch()
                                }}
                            />
                        }
                    />
                </Header.Main>
                <Header.Aside>
                    <Header.Actions actions={actions} />
                </Header.Aside>
            </Header.Block>
            <Header.Tabs
                tabs={tabs}
                tabWidth="124px"
            />
        </Header>
    )
}
export default WOShowHeader

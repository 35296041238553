import { useEffect } from 'react'

import { useQueryClient } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'

import api from 'api'
import {
    GridContainerGrid,
    PageContent,
    Typography,
    useOpenFullPageLoader,
    useTelematics,
} from 'components'
import { telematicsExtras } from 'components/telematics/hooks/useTelematics'
import { useFinalErrorHandler, useNotify } from 'hooks'
import { authStore } from 'providers'
import { makeid } from 'utils'

import { Item } from './components'
import useIntegrationDrawerTogler from './components/useIntegrationDrawerTogler'

const Integrations = () => {
    const { search } = useLocation()
    const openDrawer = useIntegrationDrawerTogler()
    const navigate = useNavigate()
    const { telematics, redirectUri, list } = useTelematics()
    const notify = useNotify()
    const fullPageLoading = useOpenFullPageLoader()
    const handleError = useFinalErrorHandler()
    const queryClient = useQueryClient()

    useEffect(() => {
        const searchParams = new URLSearchParams(search)
        if (searchParams) {
            navigate('', { replace: true })
        }
        const oauth2 = searchParams.get('oauth2')
        if (oauth2) {
            const code = searchParams.get('code')
            ;(async () => {
                try {
                    fullPageLoading({
                        text: (
                            <Typography
                                variant="inherit"
                                color="inherit"
                            >
                                This process may take a little longer,
                                <br />
                                thank you for your patience.
                            </Typography>
                        ),
                    })
                    await api
                        .post('telematics', {
                            provider: oauth2,
                            code: decodeURIComponent(code),
                            redirectUri: redirectUri + '?oauth2=' + oauth2,
                        })
                        .then((response) => {
                            const telematic = {
                                ...response,
                                ...telematicsExtras[response.provider],
                            }
                            queryClient.setQueryData('telematics', (oldData) => {
                                if (Array.isArray(oldData)) {
                                    const existingIndex = oldData.findIndex(
                                        (data) => data.provider === response.provider,
                                    )

                                    if (existingIndex !== -1) {
                                        const newData = [...oldData]
                                        newData[existingIndex] = response
                                        return newData
                                    }

                                    return [...oldData, response]
                                }
                                return [response]
                            })
                            openDrawer(telematic, list.refetch)
                        })
                } catch (e) {
                    handleError(e, {
                        fallbackError: 'Connection unsuccessful',
                    })
                } finally {
                    fullPageLoading(false)
                }
            })()
        }
    }, [])

    const data = [telematics.samsara, telematics.motive]

    const onDisconnect = async (provider) => {
        try {
            await api.delete('telematics/' + provider)
            list.refetch()
            notify('Successfully disconnected', {
                type: 'success',
            })
        } catch (e) {
            handleError(e, {
                fallbackError: 'Operation unsuccessful',
            })
        }
    }

    const onManualSync = async (provider) => {
        try {
            await api.post('telematics/' + provider + '/sync')
            startProviderTask(provider)
            list.refetch()
        } catch (e) {
            handleError(e, {
                fallbackError: 'Operation unsuccessful',
            })
        }
    }
    return (
        <PageContent>
            <GridContainerGrid rowSpacing="10px">
                {data.map((item) => (
                    <Item
                        refetch={list.refetch}
                        key={item.provider}
                        onDisconnect={() => onDisconnect(item.provider)}
                        onManualSync={() => onManualSync(item.provider)}
                        item={item}
                    />
                ))}
            </GridContainerGrid>
        </PageContent>
    )
}

export default Integrations

export const startProviderTask = (provider: string) => {
    authStore.startAsyncTask(provider + '_' + makeid())
}

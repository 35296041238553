import { Theme, useMediaQuery } from '@mui/material'

import { WorkOrderModel } from 'appTypes/models'
import { ListTotalBadge } from 'components'
import Header from 'components/pages/header'
import WorkOrderCreateMenu from 'pages/WorkOrders/components/WorkOrderCreateMenu'
import { resolveDoublePriceMask } from 'utils'

const WOListHeader = () => {
    const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

    return (
        <Header bgColor="transparent">
            <Header.Block>
                <Header.Main>
                    <Header.Spacer>
                        <Header.Title>Work Orders</Header.Title>
                        <ListTotalBadge<WorkOrderModel>
                            renderContent={({ data = [], total: totalCount }) =>
                                `${totalCount || 0} • ${resolveDoublePriceMask(
                                    data[0]?.totalQueryset || 0,
                                )} Total`
                            }
                        />
                    </Header.Spacer>
                </Header.Main>
                <Header.Aside fixed>
                    <WorkOrderCreateMenu
                        {...(isSm
                            ? {
                                  anchorOrigin: {
                                      vertical: 'top',
                                      horizontal: 'left',
                                  },
                                  transformOrigin: {
                                      vertical: 'bottom',
                                      horizontal: 'left',
                                  },
                              }
                            : {})}
                        renderToggler={(open) => (
                            <Header.AddButton
                                onClick={(e) => {
                                    e.preventDefault()
                                    open(e)
                                }}
                            >
                                CREATE WORK ORDER
                            </Header.AddButton>
                        )}
                    />
                </Header.Aside>
            </Header.Block>
        </Header>
    )
}

export default WOListHeader

import Icons from 'components/icons'

import { BaseActionOptions } from '../Actions'

export interface ArchiveActionArchiveOption {
    isArchived: boolean
}

export const archiveActionBaseOptions = (isArchived: boolean): BaseActionOptions => ({
    Icon: isArchived ? Icons.Unarchive : Icons.Archive,
    title: isArchived ? 'Unarchive' : 'Archive',
})

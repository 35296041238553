import { ReactElement } from 'react'

import { useRecordContext } from 'react-admin'

import { WorkOrderModel } from 'appTypes/models'
import {
    Columns,
    RaResourceContextProvider,
    Section,
    SliderView,
    ViewHeader,
    useResource,
} from 'components'
import Skeleton, { SkeletonSliderCardContainer } from 'components/Skeletons'
import { useClearResource } from 'hooks'

const skeletonSlides = new Array(4).fill(0).map((v, i) => {
    return (
        <SkeletonSliderCardContainer key={i}>
            <Columns
                gap="10px"
                height="100%"
            >
                <Skeleton
                    height="8px"
                    width="120px"
                    sx={{
                        my: '20px',
                    }}
                />
                <Skeleton />
            </Columns>
        </SkeletonSliderCardContainer>
    )
})
export const WorkOrderJobsFallback = () => {
    return (
        <Section>
            <ViewHeader
                title={null}
                loading
            />
            <SliderView
                height="219px"
                items={skeletonSlides}
            />
        </Section>
    )
}

const WorkOrderJobsResource = ({
    children,
    workOrder,
}: {
    children: ReactElement
    workOrder?: WorkOrderModel
}) => {
    const record = useRecordContext({ record: workOrder })
    const resource = useResource()

    const jobsResource = `${resource.resource}/${record?.id}/jobs`

    useClearResource(jobsResource)

    if (!record || !record.id) {
        return <WorkOrderJobsFallback />
    }

    return (
        <RaResourceContextProvider value={{ resource: jobsResource, name: 'work-order-jobs' }}>
            {children}
        </RaResourceContextProvider>
    )
}

export default WorkOrderJobsResource

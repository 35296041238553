import { To } from 'react-router-dom'

import { WorkOrderType } from 'appTypes/models'
import { useCreateResourcePath, UseCreateResourcePathParams } from 'hooks'

import { woResource } from '../config/constants'
import { useWoLimit } from '../hooks'

const useWoCreateLink = () => {
    const createPath = useCreateResourcePath()

    const { canCreateWo, woLimitReachedAction } = useWoLimit()

    const clickHandler = (
        event: React.MouseEvent<HTMLElement, MouseEvent>,
        onSuccess?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void,
    ) => {
        if (canCreateWo()) {
            onSuccess?.(event)
            return
        }
        event.preventDefault()
        woLimitReachedAction()
    }

    const to = ({
        workOrderType = 'internal',
        queryParams,
    }: { workOrderType?: WorkOrderType } & Pick<
        UseCreateResourcePathParams,
        'queryParams'
    > = {}): To =>
        createPath({
            resource: woResource.resource + (workOrderType === 'internal' ? '' : '/vendors'),
            type: 'create',
            queryParams,
        })

    return {
        to,
        clickHandler,
    }
}

export default useWoCreateLink

import { Theme, useMediaQuery } from '@mui/material'

import { List, ListBase, ListBaseProps, PeriodFilter } from 'components'
import { ListProps } from 'components/list/List'
import ListFilterNoResults from 'components/list/filter/ListFilterNoResults'

import PeriodSelector, { defaultReportPeriod } from './PeriodSelector'

export const reportPeriodKeys: (keyof PeriodFilter)[] = ['periodStart', 'periodEnd']

const ListWithPeriodSelector = (props: Omit<ListProps, 'toolbarContent'>) => {
    const match = useMediaQuery<Theme>((theme) => theme.breakpoints.down('lg'))

    return (
        <List
            {...props}
            excludeFilterFields={reportPeriodKeys}
            renderNoResults={() => (
                <ListFilterNoResults
                    disableFilter
                    subtitle={
                        <>
                            No results match your criteria.
                            <br />
                            Try modifying the search, filters or the
                            <br />
                            reporting period.
                        </>
                    }
                />
            )}
            toolbarContent={
                match
                    ? { contentUnderToolbar: <PeriodSelector sx={{ pb: '20px' }} /> }
                    : { contentBeforeViews: <PeriodSelector /> }
            }
        />
    )
}

export default ListWithPeriodSelector

export const ListBaseWithPeriod = (props: Omit<ListBaseProps, 'filterDefaultValues'>) => {
    return (
        <ListBase
            {...props}
            filterDefaultValues={defaultReportPeriod()}
        />
    )
}

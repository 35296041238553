import { Identifier } from 'react-admin'

import { renderOnPermission } from 'hocs'
import { useFileDownload, UseFileDownloadParams } from 'hooks'

import { ActionChildren } from '../Actions'

import { downloadActionBaseOptions } from './downloadActionCommon'

export interface DownloadOneActionProps extends Omit<UseFileDownloadParams, 'id'> {
    children: ActionChildren
    id: Identifier
}

export const DownloadOneAction = renderOnPermission(
    ({ children, ...rest }: DownloadOneActionProps) => {
        const downloadOne = useFileDownload()

        return children({
            ...downloadActionBaseOptions[rest.type],
            onClick: () => {
                downloadOne(rest)
            },
        })
    },
    ({ props, permissions }) => permissions?.[props.type],
)

export const downloadOneAction = (params: DownloadOneActionProps & { key?: string | number }) => (
    <DownloadOneAction
        key={'download-one-' + (params.filename || '') + '-' + params.type}
        {...params}
    />
)

import { reportUrl } from 'pages/Reports'
import { createReportResource } from 'pages/Units/config/constants'

import { extractWidgetTop3 } from '../../WidgetBase'
import WidgetBase, { WidgetSkeleton } from '../../WidgetBase/WidgetBase'
import { useCreateDashboardWidgetLink, useDashboardWidget } from '../../WidgetsForm'
import { CostPerCategoryClarification } from '../CostPerCategoryWidget/components'
import { unitGetRoundedPercents } from '../CostPerUnitWidget'

import { CostPerRepairChart, CostPerRepairList } from './components'
import { listType, QueryResponse, ResponseType } from './types'

const description = `Monitor closely the reasons for repair that lead to high maintenance costs.
Explore seasonality and trend dynamics by comparing reporting periods.`

let hasData = false
let validData = false
const CostPerCategoryWidget = () => {
    const createLink = useCreateDashboardWidgetLink()
    const data = useDashboardWidget<QueryResponse>({
        resource: createReportResource('cost-per-reason-for-repair'),
        sideEffect: (data) => {
            const finalData = unitGetRoundedPercents(data)

            const { maxTotalReasonsForRepair, ...ratioData } = finalData as ResponseType

            const slicedList = maxTotalReasonsForRepair.slice(0, 3)

            const listExtras = extractWidgetTop3(slicedList)

            hasData = typeof ratioData.total === 'number'
            validData = ratioData.total > 0 && listExtras.top3Percentage > 0

            const sumOfExcludedReasons = data.total - listExtras.top3Total
            const sumPercentageOfExcludedReasons = Math.floor(100 - listExtras.top3Percentage)
            const list: listType = {
                data: slicedList,
                ...listExtras,
                sumOfExcludedReasons,
                sumPercentageOfExcludedReasons,
            }
            return {
                ...ratioData,
                list,
            }
        },
    })
    if (!data) {
        return <WidgetSkeleton />
    }
    const { list, ...ratioData } = data

    return (
        <WidgetBase
            headerProps={{
                title: 'Top 3 Reasons for Repair Account for',
                label: hasData ? (validData ? `${Math.round(list.top3Percentage)}%` : 'N/A') : null,
                tooltipText: description,
                clarification: (
                    <CostPerCategoryClarification>
                        of all Maintenance Costs
                    </CostPerCategoryClarification>
                ),
                chart: (
                    <CostPerRepairChart
                        list={list}
                        validData={validData}
                        total={ratioData.positiveTotal}
                    />
                ),
            }}
            infoProps={{
                title: 'Top 3 Categories',
                content: (
                    <CostPerRepairList
                        list={list}
                        isValid={hasData && validData}
                        description={description}
                    />
                ),
            }}
            link={createLink(reportUrl(reportUrl.urls.costPerReasonForRepair))}
        />
    )
}
export default CostPerCategoryWidget

import { reportUrl, reportsUrls } from 'pages/Reports'
import { createReportResource } from 'pages/Units/config/constants'

import { extractWidgetTop3 } from '../../WidgetBase'
import WidgetBase, { WidgetSkeleton } from '../../WidgetBase/WidgetBase'
import { useCreateDashboardWidgetLink, useDashboardWidget } from '../../WidgetsForm'
import { CostPerCategoryClarification } from '../CostPerCategoryWidget/components'
import { unitGetRoundedPercents } from '../CostPerUnitWidget'

import { CostPerVMRSChart, TopCategories } from './components'
import { QueryResponse, ResponseType, listType } from './types'

const description = `Drill down further into maintenance costs, 
by exploring which VMRS repair systems account for most of the expenses. `
let validData = false
let hasData = false
const CostPerVMRSWidget = () => {
    const createLink = useCreateDashboardWidgetLink()

    const data = useDashboardWidget<QueryResponse>({
        resource: createReportResource('cost-per-group'),
        sideEffect: (data) => {
            const finalData = unitGetRoundedPercents(data)
            const { maxTotalGroups, ...ratioData } = finalData as unknown as ResponseType
            const slicedList = maxTotalGroups.slice(0, 3)
            const listExtras = extractWidgetTop3(slicedList)
            hasData = typeof ratioData.total === 'number'
            validData = ratioData.total > 0 && listExtras.top3Percentage >= 0
            const list: listType = {
                data: slicedList,
                ...listExtras,
                sumOfExcludedGroups: data.total - listExtras.top3Total,
            }
            return {
                ...ratioData,
                list,
            }
        },
    })
    if (!data) {
        return <WidgetSkeleton />
    }
    const { list, ...ratioData } = data

    return (
        <WidgetBase
            headerProps={{
                title: 'Top 3 VMRS Groups Account for',
                label: hasData ? (validData ? `${Math.round(25)}%` : 'No Data') : null,
                tooltipText: description,
                clarification: (
                    <CostPerCategoryClarification>
                        of all Maintenance Costs
                    </CostPerCategoryClarification>
                ),
                chart: (
                    <CostPerVMRSChart
                        list={list}
                        validData={validData}
                        total={ratioData.total}
                    />
                ),
            }}
            infoProps={{
                title: 'Top 3 VMRS groups',
                content: (
                    <TopCategories
                        list={list}
                        description={description}
                    />
                ),
            }}
            link={createLink(reportUrl(reportsUrls.costPerVMRSGroup))}
        />
    )
}
export default CostPerVMRSWidget

import { InsertDriveFileOutlined } from '@mui/icons-material'
import { Box, Divider, Icon } from '@mui/material'
import { useListContext, useShowContext } from 'react-admin'

import { WorkOrderModel } from 'appTypes/models'
import {
    BoxContainer,
    Columns,
    IconBox,
    InfoBadge,
    InfoCardDetails,
    InfoCardHeader,
    ListTotalBadge,
    SliderView,
    ViewHeader,
    Section,
    Spacer,
    CollapsibleInfoCard,
    CollapsibleTogglerAction,
    CollapsibleContent,
    Typography,
} from 'components'
import { Action, deleteOneFromListAction } from 'components/actions'
import Icons from 'components/icons'
import { useWoActionIsDisabled } from 'pages/WorkOrders/hooks'
import { formatDate, resolveDoublePriceMask, stringDateFixTimezone } from 'utils'

import { WorkOrderInvoiceOpenDrawerButton } from '../../WorkOrderInvoice'
import WorkOrderInvoiceEditDrawerToggler, {
    WOInvoice,
    deleteInvoiceAction,
} from '../InvoiceDrawer/WorkOrderInvoiceDrawerToggler'

import InvoicesSectionEmpty from './InvoicesSectionEmpty'
interface InvoiceActionsProps {
    id: number
    onSuccess: () => void
    disabled: boolean
}
const invoiceActions: Action = ({ id, onSuccess, disabled }: InvoiceActionsProps, { children }) => [
    <WorkOrderInvoiceEditDrawerToggler
        id={id}
        key="edit"
        children={({ onClick }) => children({ title: 'Edit/View', Icon: Icons.Edit, onClick })}
    />,
    deleteOneFromListAction({
        children,
        id,
        disabled,
        ...deleteInvoiceAction(onSuccess),
    }),
]

const InvoiceCardExpanded = ({ attachments }: { attachments: string[] }) => {
    return (
        <Columns>
            {attachments.map((att) => (
                <BoxContainer
                    key={att}
                    mt="10px"
                    sx={{
                        cursor: 'pointer',
                    }}
                >
                    <Icon
                        sx={(theme) => ({
                            color: theme.palette.primary.main,
                        })}
                    >
                        <InsertDriveFileOutlined fontSize="small" />
                    </Icon>
                    <Typography
                        component="a"
                        href={att}
                        target="_blank"
                        rel="noreferrer"
                        variant="body2"
                        ml="10px"
                        color="primary.main"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                    >
                        {att.split('?')[0].split('/').pop()}
                    </Typography>
                </BoxContainer>
            ))}
        </Columns>
    )
}
const InvoiceCardTitle = ({ invoice }: { invoice: WOInvoice }) => {
    return (
        <WorkOrderInvoiceOpenDrawerButton
            id={invoice.id}
            mode="edit"
        >
            {({ onClick }) => (
                <Typography
                    component={Box}
                    sx={{
                        cursor: 'pointer',
                    }}
                    onClick={onClick}
                    color="primary.main"
                    variant="subtitle2"
                >
                    Invoice No {invoice.number}
                </Typography>
            )}
        </WorkOrderInvoiceOpenDrawerButton>
    )
}
const InvoiceCard = ({ invoice }: { invoice: WOInvoice }) => {
    const attachments = Array(10)
        .fill(0)
        .flatMap((v, index) => invoice[`attachment${index}`] || [])
    const { refetch } = useShowContext()
    const disabled = useWoActionIsDisabled()
    return (
        <CollapsibleInfoCard>
            <InfoCardHeader
                actions={(p, p2) =>
                    invoiceActions({ id: invoice.id, onSuccess: refetch, disabled }, p2)
                }
                title={<InvoiceCardTitle invoice={invoice} />}
            />
            <InfoCardDetails<WOInvoice>
                details={[
                    {
                        label: 'amount',
                        source: ({ amount }) => resolveDoublePriceMask(parseFloat(amount)),
                    },
                    {
                        label: 'date',
                        source: ({ date }) =>
                            date &&
                            formatDate(
                                stringDateFixTimezone(date),
                                (dateFormats) => dateFormats.shortenedDate,
                            ),
                    },
                    {
                        label: 'payment term',
                        source: ({ paymentTermData }) => paymentTermData?.name,
                    },
                ]}
                propRecord={invoice}
            />
            <Divider sx={{ my: '12px' }} />
            <BoxContainer justifyContent="space-between">
                <Spacer>
                    <Typography variant="subtitle2">Attachments</Typography>
                    <InfoBadge badgeContent={attachments.length} />
                </Spacer>
                {attachments.length ? <CollapsibleTogglerAction /> : null}
            </BoxContainer>
            <CollapsibleContent>
                <InvoiceCardExpanded attachments={attachments} />
            </CollapsibleContent>
        </CollapsibleInfoCard>
    )
}

const InvoicesSection = () => {
    const { isLoading, data, total } = useListContext<WOInvoice>()
    const { record } = useShowContext<WorkOrderModel>()
    const disabled = useWoActionIsDisabled()

    let content

    if (isLoading) {
        return null
    }
    if (total) {
        const items = data.map((inv) => <InvoiceCard invoice={inv} />)
        content = (
            <SliderView
                height="204px"
                items={items}
            />
        )
    } else {
        content = <InvoicesSectionEmpty />
    }
    return (
        <Section>
            <ViewHeader title="Invoices">
                <ViewHeader.Content>
                    <ListTotalBadge
                        renderContent={() => {
                            const v = resolveDoublePriceMask(record.purchaseOrderData.totalInvoices)
                            return total ? `${total} • ${v} Total ` : '0'
                        }}
                    />
                </ViewHeader.Content>
                {disabled ? null : (
                    <ViewHeader.Content placement="rightMobile">
                        <WorkOrderInvoiceOpenDrawerButton mode="create">
                            {({ onClick }) => (
                                <IconBox
                                    title="Add Invoice"
                                    onClick={onClick}
                                >
                                    <Icons.Add />
                                </IconBox>
                            )}
                        </WorkOrderInvoiceOpenDrawerButton>
                    </ViewHeader.Content>
                )}
            </ViewHeader>
            {content}
        </Section>
    )
}
export default InvoicesSection

import { Grid } from '@mui/material'

import api from 'api'
import { Role } from 'appTypes/models'
import { PageContent, Typography } from 'components'
import { useQuery } from 'hooks'

import UserRoleCard from './components/UserRoleCard'

const UserRoles = () => {
    const roles = useQuery<Role[]>('roles', () => api.get('roles'))

    if (roles.isError || roles.isFetching || !roles.data?.length) {
        return null
    }

    return (
        <PageContent>
            <Typography
                variant="h6"
                mb="16px"
                color="text.primary"
            >
                User Roles
            </Typography>

            <Grid
                container
                spacing={4}
            >
                {roles.data.map((role) => (
                    <Grid
                        item
                        md={4}
                        xs={12}
                        key={role.id}
                    >
                        <UserRoleCard role={role} />
                    </Grid>
                ))}
            </Grid>
        </PageContent>
    )
}

export default UserRoles

import { Theme } from '@mui/material'

import { AllProps } from 'appTypes'

import { arrayOfStringToObject } from './objects'

export const generateNotForwardedProps = <T extends AllProps = AllProps>(params: (keyof T)[]) => {
    const obj = arrayOfStringToObject(params, true)

    return (prop: keyof T) => !obj[prop]
}

export type ThemeColorType = string | ((themeParam: Theme) => string)

// TODO: use currying technique on themeColor
export const themeColor = (color: ThemeColorType, theme: Theme) => {
    if (typeof color === 'function') {
        return color(theme)
    }
    return color
}

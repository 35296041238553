import { ReferenceInput } from 'react-admin'

import { DateInput, SelectInput, TextInput } from 'components'
import { useUtilityDrawerContext } from 'components/Drawer/UtilityDrawer'
import { WorkOrderItemsExtra } from 'pages/WorkOrders/Jobs/config/constants'
import { costNonNegativeMaskParams, requiredValidation } from 'utils'

import Attachments from './Attachments'

const InvoiceForm = () => {
    const { extra } = useUtilityDrawerContext()
    const { disabledFields: disabled } = (extra || {}) as WorkOrderItemsExtra

    return (
        <>
            <TextInput
                source="number"
                disabled={disabled}
                label="Invoice Number"
                validate={requiredValidation}
            />

            <TextInput
                disabled={disabled}
                source="amount"
                label="Amount"
                validate={requiredValidation}
                {...costNonNegativeMaskParams}
            />

            <DateInput
                disabled={disabled}
                source="date"
                label="Date"
                defaultValue={new Date()}
                validate={requiredValidation}
            />

            <ReferenceInput
                source="paymentTerm"
                reference="payment-terms"
            >
                <SelectInput
                    disabled={disabled}
                    label="Payment Terms"
                    disableEmptyValue
                    optionText="name"
                    defaultValue={extra.term}
                />
            </ReferenceInput>

            <Attachments disabled={disabled} />
        </>
    )
}
export default InvoiceForm

import { useListContext, ListControllerResult } from 'react-admin'

import api from 'api'
import { ResourceType, useArchivedContext } from 'components'
import { useConfirm, useCreateResourcePath, useFinalErrorHandler, useNotify } from 'hooks'

import { useResource } from '../components/resource/RaResourceContextProvider'

import { ArchiveParams } from './useArchive'

export interface ArchiveManyFromListParams extends Omit<ArchiveParams, 'path'> {
    listContext?: ListControllerResult
}

// TODO: use the logic from useArchive
const useArchiveManyFromList = () => {
    const isArchived = useArchivedContext()
    const notify = useNotify()
    const resourceFromContext = useResource()
    const listContextFromContext = useListContext()
    const createPath = useCreateResourcePath()
    const errorHandler = useFinalErrorHandler()
    const action = isArchived ? 'unarchive' : 'archive'
    const confirm = useConfirm()

    const archiveResource = (
        params:
            | ArchiveManyFromListParams
            | ((nestedParams: {
                  resource: ResourceType
                  listContext: ListControllerResult
              }) => ArchiveManyFromListParams) = {},
    ) => {
        let finalParams =
            typeof params === 'function'
                ? params({
                      resource: resourceFromContext,
                      listContext: listContextFromContext,
                  })
                : params

        finalParams = {
            resource: resourceFromContext,
            listContext: listContextFromContext,
            ...finalParams,
        }

        const {
            notifyMessage = isArchived ? 'Successfully unarchived!' : 'Successfully archived!',
            onSuccess,
            listContext,
            resource,
            confirmConfig,
        } = finalParams

        const finalListContext = listContext || listContextFromContext

        if (!finalListContext.selectedIds.length) {
            return
        }

        const archive = () => {
            api.post(
                createPath({ resource: resource.resource, type: 'list' }) +
                    '/' +
                    action +
                    '_bulk?id=' +
                    finalListContext.selectedIds.join(','),
            )
                .then((response) => {
                    finalListContext.onUnselectItems()
                    finalListContext.refetch()
                    onSuccess?.(response)
                    notify(notifyMessage, { type: 'info' })
                })
                .catch((err) => {
                    errorHandler(err)
                })
        }

        confirm({
            title: `Are you sure you want to ${isArchived ? 'unarchive' : 'archive'}?`,
            confirmType: isArchived ? 'UNARCHIVE' : 'ARCHIVE',
            ...confirmConfig,
            onConfirm: archive,
        })
    }

    return archiveResource
}

export default useArchiveManyFromList

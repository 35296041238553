import { Link } from '@mui/material'
import { Link as RRDLink } from 'react-router-dom'

import { WorkOrderModel } from 'appTypes/models'
import { EllipsisOverflow, Spacer, Typography } from 'components'
import { useCreateResourcePath } from 'hooks'
import { archivedUnitResource, unitAvatarConfig, unitResource } from 'pages/Units/config/constants'

import { WorkOrderStatusSelector } from '../components'

import { WorkOrderStatusSelectorProps } from './WorkOrderStatusSelector'

interface WorkOrderSubtitleProps {
    workOrder: WorkOrderModel
    onStatusChange?: WorkOrderStatusSelectorProps['onComplete']
}
const WorkOrderSubtitle = ({ workOrder, onStatusChange }: WorkOrderSubtitleProps) => {
    const createPath = useCreateResourcePath()

    if (!workOrder) {
        return null
    }

    const { unitData } = workOrder
    const path = createPath({
        resource: unitData.archived ? archivedUnitResource.resource : unitResource.resource,
        type: 'edit',
        id: unitData.id,
    })
    return (
        <Spacer variant="md">
            <WorkOrderStatusSelector
                workOrder={workOrder}
                onComplete={onStatusChange}
            />
            <Link
                component={RRDLink}
                to={path}
                color="primary.main"
                variant="body2"
                sx={{
                    display: 'flex',
                    gap: '4px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                }}
            >
                <unitAvatarConfig.Icon
                    color="inherit"
                    sx={{
                        width: '16px ',
                        height: '16px',
                    }}
                />
                <EllipsisOverflow>
                    <Typography
                        variant="inherit"
                        color="inherit"
                        sx={{
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {unitData.number}
                    </Typography>
                </EllipsisOverflow>
            </Link>
        </Spacer>
    )
}

export default WorkOrderSubtitle

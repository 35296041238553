import React, { ElementType, PropsWithChildren } from 'react'

import { Sensors, SensorsOff } from '@mui/icons-material'
import { BoxProps, SvgIcon, styled, Tooltip } from '@mui/material'
import { SvgIconProps } from '@mui/material/SvgIcon'

import { BoxContainer, StatusText, StatusTextProps, Typography } from 'components'
export const StyledSVG = styled(SvgIcon)<SvgIconProps & { component: ElementType }>`
    width: 13px;
    height: 13px;
`
export const connectedUnitsStatusMap: {
    [key in ConnectedUnitsStatusProps['type']]: StatusTextProps
} = {
    sync: {
        children: <StyledSVG component={Sensors} />,
        statusColor: (theme) => theme.palette.charts.greenBody,
        title: 'Connected',
    },
    error: {
        children: <StyledSVG component={SensorsOff} />,
        statusColor: (theme) => theme.palette.charts.red,
        title: 'Sync Error',
    },
    notImported: {
        children: <StyledSVG component={SensorsOff} />,
        statusColor: (theme) => theme.palette.text.disabled,
        title: 'Unsynced',
    },
}

interface ConnectedUnitsStatusProps extends PropsWithChildren, BoxProps {
    type: 'sync' | 'error' | 'notImported'
    hideTooltip?: boolean
}
const ConnectedUnitsStatus = ({
    type,
    children,
    title,
    hideTooltip,
    ...props
}: ConnectedUnitsStatusProps) => {
    const { title: statusMapTitle, ...statusMap } = connectedUnitsStatusMap[type]

    const content = (
        <BoxContainer
            component="span"
            gap="4px"
            {...props}
        >
            <StatusText
                {...statusMap}
                sx={{
                    boxSizing: 'border-box',
                    width: '16px',
                    height: '16px',
                    padding: '1.5px !important',
                }}
            />
            {children == null ? null : (
                <Typography
                    component="span"
                    variant="body2"
                    color="text.primary"
                >
                    {children}
                </Typography>
            )}
        </BoxContainer>
    )
    if (hideTooltip) {
        return <>{content}</>
    }
    return <Tooltip title={statusMapTitle}>{content}</Tooltip>
}

export default ConnectedUnitsStatus

import { HomeOutlined } from '@mui/icons-material'
import { SvgIcon, Box } from '@mui/material'
import { Link } from 'react-router-dom'

import { ReactComponent as NotFoundImage } from 'assets/images/page404.svg'
import { BoxContainer, Button, Typography } from 'components'
import { urls } from 'configs/config'

const NotFound = () => {
    return (
        <BoxContainer
            minHeight="100vh"
            justifyContent="center"
            px="15px"
        >
            <Box
                maxWidth="424px"
                textAlign="center"
            >
                <SvgIcon
                    inheritViewBox
                    component={NotFoundImage}
                    sx={{ mb: '32px', width: 'auto', height: 'auto' }}
                />

                <Typography
                    variant="h5"
                    component="div"
                    color="text.primary"
                    mb="16px"
                >
                    Something is Wrong
                </Typography>

                <Typography
                    variant="body1"
                    color="text.secondary"
                    mb="16px"
                >
                    The page you are looking for was moved, removed, renamed, or might never existed
                </Typography>

                <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    component={Link}
                    to={urls.root}
                    startIcon={<HomeOutlined />}
                >
                    Home
                </Button>
            </Box>
        </BoxContainer>
    )
}

export default NotFound

import { FC, ReactElement } from 'react'

import { useRecordContext } from 'react-admin'

import { WorkOrderModel } from 'appTypes/models'
import { useOpenUtilityDrawer, useResource, UtilityDrawerEdit } from 'components'
import { basePermissions } from 'configs/constants'
import { renderOnPermission } from 'hocs'
import { AuthStore } from 'providers/authStore'

import { useIsVenderWo } from '../Overview/WorkOrderOverview'
import { vendorWorkOrderSerializer, workOrderSerializer } from '../config/constants'
import { useWoLimit } from '../hooks'

import VendorWorkOrderForm from './VendorWorkOrderForm'
import WorkOrderForm from './WorkOrderForm'

interface WorkOrderEditDrawerTogglerProps {
    children: (params: { onClick: () => void }) => ReactElement
}

export const WorkOrderEditDrawerToggler: FC<
    WorkOrderEditDrawerTogglerProps & { auth?: AuthStore }
> = renderOnPermission(({ children }) => {
    const open = useOpenUtilityDrawer()
    const record = useRecordContext<WorkOrderModel>()
    const resource = useResource()
    const { hasError, woLimitReachedAction } = useWoLimit()
    const isWOVendorWO = useIsVenderWo()

    return children({
        onClick: () => {
            open({
                extraArgs: {
                    id: record.id,
                    resource,
                },
                drawerArgs: {
                    title: 'Edit Details',
                    renderWrapper: (params) => (
                        <UtilityDrawerEdit
                            formOnError={({ errors, defaultOnError, reset }) => {
                                if (hasError(errors)) {
                                    woLimitReachedAction()
                                    reset(
                                        {},
                                        {
                                            keepValues: true,
                                            keepDirty: true,
                                            keepTouched: true,
                                        },
                                    )
                                    return
                                }

                                defaultOnError()
                            }}
                            {...params}
                            serializer={
                                isWOVendorWO ? vendorWorkOrderSerializer : workOrderSerializer
                            }
                        />
                    ),
                    renderContent: () =>
                        isWOVendorWO ? <VendorWorkOrderForm /> : <WorkOrderForm />,
                },
            })
        },
    })
}, basePermissions.update)

export default WorkOrderEditDrawerToggler

import { useEffect } from 'react'

import { Icon } from '@mui/material'
import { RaRecord, useListController, useRecordContext } from 'react-admin'

import { UnitModel } from 'appTypes/models'
import { BoxContainer, Columns, Typography } from 'components'
import { useActionsMenuContext } from 'components/actions'
import { woGetAvatarConfig, woResource } from 'pages/WorkOrders/config/constants'

import { WorkOrderCreateMenuProps } from './AddWorkOrderMenu'
import useAttachWO from './UseWO/useAttachWO'

const ExistingWOSection = <ParentType extends RaRecord = any>({
    parentRecord,
    refetchList,
    cardMenuClose,
    options,
}: Pick<WorkOrderCreateMenuProps<ParentType>, 'parentRecord' | 'refetchList' | 'options'> & {
    cardMenuClose?: () => void
}) => {
    const { close } = useActionsMenuContext()
    const woUse = useAttachWO()
    const record = useRecordContext<UnitModel>()
    const listController = useListController({
        resource: woResource.resource,
        filter: {
            status: ['OPEN'],
            unit: [record?.number],
        },
        perPage: 1000,
        queryOptions: {
            enabled: Boolean(record?.id),
        },
    })
    useEffect(() => {
        if (listController.isFetching === false) {
            window.dispatchEvent(new Event('resize'))
        }
    }, [listController.isFetching])
    if (listController.isLoading || listController.error) {
        return null
    }

    const data = listController.data

    return data.length ? (
        <Columns
            width="274px"
            sx={{ padding: '14px 16px 6px' }}
        >
            <Typography
                variant="subtitle2"
                color="text.primary"
                pb="14px"
            >
                USE EXISTING WORK ORDER
            </Typography>
            <Columns gap="12px">
                {data?.map((item) => (
                    <BoxContainer
                        gap="18px"
                        key={item.id}
                    >
                        <Icon
                            component={woGetAvatarConfig(item).Icon}
                            sx={{
                                color: (theme) => theme.palette.text.primary,
                                opacity: 0.54,
                                height: '20px',
                                width: '20px',
                            }}
                        />
                        <BoxContainer
                            gap="4px"
                            key={item.id}
                            onClick={() => {
                                close()
                                cardMenuClose?.()
                                woUse({
                                    workOrder: item,
                                    parentRecord,
                                    refetchList,
                                    options,
                                })
                            }}
                        >
                            <Typography
                                whiteSpace="nowrap"
                                variant="menuItem"
                                sx={{ cursor: 'pointer' }}
                            >
                                {item.number}
                            </Typography>
                            {item?.purchaseOrderData?.vendorData ? (
                                <Typography
                                    overflow="hidden"
                                    whiteSpace="nowrap"
                                    textOverflow="ellipsis"
                                    variant="menuItem"
                                    color="text.disabled"
                                    sx={{ cursor: 'pointer' }}
                                >
                                    {item.purchaseOrderData.vendorData.name}
                                </Typography>
                            ) : null}
                        </BoxContainer>
                    </BoxContainer>
                ))}
            </Columns>
        </Columns>
    ) : null
}

export default ExistingWOSection

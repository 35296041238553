import { useResetStore } from 'react-admin'
import { useQueryClient } from 'react-query'

import { useAuthProvider } from 'hooks'
import { AuthStoreLogoutParams } from 'providers/authStore'

const useLogout = ({
    onSuccess,
    logoutParams,
}: {
    onSuccess?: () => void
    logoutParams?: AuthStoreLogoutParams
} = {}) => {
    const authProvider = useAuthProvider()
    const resetStore = useResetStore()
    const queryClient = useQueryClient()

    return () => {
        authProvider.logout(logoutParams).then(() => {
            onSuccess?.()
            queryClient.clear()
            resetStore()
        })
    }
}

export default useLogout

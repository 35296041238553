import { createContext, FC, useContext, useEffect, useMemo, useState, ReactNode } from 'react'

import { useLocation } from 'react-router-dom'

interface SidebarContextValue {
    open: boolean
    toggle: () => void
    close: () => void
    setOpen: (toggler: boolean | ((open: boolean) => boolean)) => void
}

const SidebarContext = createContext<SidebarContextValue>(null)

export const useSidebarContext = (): SidebarContextValue => useContext(SidebarContext)

export const SidebarProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [open, setOpen] = useState(false)
    const location = useLocation()

    useEffect(() => {
        setOpen(false)
    }, [location])

    const toggle = () => {
        setOpen((oldOpen) => !oldOpen)
    }

    const close = () => {
        setOpen(false)
    }

    const value: SidebarContextValue = useMemo(
        () => ({
            open,
            setOpen,
            toggle,
            close,
        }),
        [open],
    )

    return <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>
}

export default SidebarContext

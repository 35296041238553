import { useState } from 'react'

import { Dialog } from '@mui/material'

import { useSetBlocker } from 'hooks'

import { ItemPropTypes } from '../components/Item'

import IntegrationsUnitList from './IntegrationsUnitList'
import UnitsToImportButton from './components/UnitsToImportButton'
import { SyncedStateType } from './utils/SyncedStateContext'

interface IntegrationsUnitListDialogProps {
    telematic: ItemPropTypes['item']
}
export const calculateSyncedUnits = (synced: SyncedStateType, vehiclesSynced: number) => {
    let difference = 0

    for (const key in synced) {
        if (synced[key]) {
            difference += 1
        } else {
            difference -= 1
        }
    }
    return (vehiclesSynced || 0) + difference
}

const IntegrationsUnitListDialog = ({ telematic }: IntegrationsUnitListDialogProps) => {
    const [open, setOpen] = useState(false)

    const onClose = () => {
        setOpen(false)
    }
    useSetBlocker(
        {
            close: onClose,
        },
        {
            isOpen: open,
        },
    )
    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                fullScreen
            >
                <IntegrationsUnitList
                    dialog
                    provider={telematic.provider}
                    onClose={onClose}
                />
            </Dialog>
            <UnitsToImportButton onClick={() => setOpen(true)}>
                {telematic.vehiclesSynced}
            </UnitsToImportButton>
        </>
    )
}

export default IntegrationsUnitListDialog

import { FC } from 'react'

import { ArrowForward } from '@mui/icons-material'
import { Box } from '@mui/material'
import { useListContext, useShowContext } from 'react-admin'

import { WoPaymentModel, WorkOrderModel } from 'appTypes/models'
import {
    IconBox,
    InfoCard,
    InfoCardDetails,
    InfoCardHeader,
    ListTotalBadge,
    SliderView,
    ViewHeader,
    Section,
    Typography,
} from 'components'
import { deleteOneFromListAction } from 'components/actions'
import { Action } from 'components/actions/Actions'
import Icons from 'components/icons'
import { useCreateResourcePath } from 'hooks'
import { BoxLink } from 'pages/Units/Meters/components'
import { vendorPaymentMethodsObject } from 'pages/Vendors/constants/config'
import { useWoActionIsDisabled } from 'pages/WorkOrders/hooks'
import { formatDate, resolveDoublePriceMask, stringDateFixTimezone } from 'utils'

import { WorkOrderPaymentOpenDrawerButton } from '../../WorkOrderInvoice'
import { WorkOrderPaymentEditDrawerToggler, deletePaymentAction } from '../PaymentDrawer'

import PaymentsSectionEmpty from './PaymentsSectionEmpty'
interface PaymentActionsProps {
    id: number
    onSuccess: () => void
    disabled: boolean
}
const paymentActions: Action = ({ id, onSuccess, disabled }: PaymentActionsProps, { children }) => [
    <WorkOrderPaymentEditDrawerToggler
        key="edit"
        id={id}
        children={({ onClick }) => children({ title: 'Edit/View', Icon: Icons.Edit, onClick })}
    />,
    deleteOneFromListAction({
        children,
        disabled,
        id,
        ...deletePaymentAction(onSuccess),
    }),
]

interface PaymentCardProps {
    payment: WoPaymentModel
    index: number
}
const InvoiceCardTitle = ({
    payment,
    index,
}: {
    payment: WoPaymentModel
    index: PaymentCardProps['index']
}) => {
    return (
        <WorkOrderPaymentOpenDrawerButton
            id={payment.id}
            mode="edit"
        >
            {({ disabled, onClick }) => (
                <Typography
                    component={Box}
                    sx={{
                        cursor: disabled ? 'inherit' : 'pointer',
                    }}
                    onClick={() =>
                        disabled
                            ? () => {
                                  /* */
                              }
                            : onClick()
                    }
                    color={disabled ? 'text.primary' : 'primary.main'}
                    variant="subtitle2"
                >
                    Payment {index}
                </Typography>
            )}
        </WorkOrderPaymentOpenDrawerButton>
    )
}
const PaymentCard: FC<PaymentCardProps> = ({ payment, index }) => {
    const { refetch } = useShowContext()
    const disabled = useWoActionIsDisabled()
    return (
        <InfoCard>
            <InfoCardHeader
                actions={(p, p2) =>
                    paymentActions({ id: payment.id, onSuccess: refetch, disabled }, p2)
                }
                title={
                    <InvoiceCardTitle
                        payment={payment}
                        index={index}
                    />
                }
                titleTypographyProps={{
                    color: 'primary.main',
                }}
            />
            <InfoCardDetails<WoPaymentModel>
                details={[
                    {
                        label: 'amount paid',
                        source: ({ amount }) => resolveDoublePriceMask(amount),
                    },
                    {
                        label: 'added on',
                        source: (record) =>
                            formatDate(
                                stringDateFixTimezone(record.created),
                                (dateFormats) => dateFormats.shortenedDate,
                            ),
                    },
                    {
                        label: 'payment date',
                        source: (record) =>
                            formatDate(
                                stringDateFixTimezone(record.date),
                                (dateFormats) => dateFormats.shortenedDate,
                            ),
                    },
                    {
                        label: 'payment method',
                        source: (record) => vendorPaymentMethodsObject[record.method]?.name,
                    },
                    {
                        label: 'payment number',
                        source: 'number',
                    },
                ]}
                propRecord={payment}
            />
        </InfoCard>
    )
}

const PaymentsSection = () => {
    const { isLoading, data, total } = useListContext<WoPaymentModel>()
    const { record } = useShowContext<WorkOrderModel>()
    const createPath = useCreateResourcePath()
    const disabled = useWoActionIsDisabled()
    let content

    if (isLoading) {
        return null
    }
    if (total) {
        const items = data.map((payment, i) => (
            <PaymentCard
                payment={payment}
                index={i + 1}
            />
        ))
        if (total >= 4) {
            const path = createPath({
                type: 'list',
            })
            items.push(
                <BoxLink
                    to={path}
                    sx={{ height: '176px' }}
                    label="SEE ALL PAYMENTS"
                    icon={<ArrowForward />}
                />,
            )
        }
        content = <SliderView items={items} />
    } else {
        content = <PaymentsSectionEmpty />
    }
    return (
        <Section>
            <ViewHeader title="Payments">
                <ViewHeader.Content>
                    <ListTotalBadge
                        renderContent={() => {
                            const v = resolveDoublePriceMask(record.purchaseOrderData.totalPayments)
                            return total ? `${total}  •  ${v} Total` : '0'
                        }}
                    />
                </ViewHeader.Content>

                {disabled ? null : (
                    <ViewHeader.Content placement="rightMobile">
                        <WorkOrderPaymentOpenDrawerButton mode="create">
                            {({ onClick }) => (
                                <IconBox
                                    title="Create"
                                    onClick={onClick}
                                >
                                    <Icons.Add />
                                </IconBox>
                            )}
                        </WorkOrderPaymentOpenDrawerButton>
                    </ViewHeader.Content>
                )}
            </ViewHeader>
            {content}
        </Section>
    )
}
export default PaymentsSection

import { Link, Navigate, useLocation } from 'react-router-dom'

import { ReactComponent as ConfirmIcon } from 'assets/images/deactivate.svg'
import { StyledElement, Button, CenterText, Typography } from 'components'
import { urls } from 'configs/config'

import { AuthBody } from '../components'

interface StateType {
    state: {
        key?: string
    }
}

const RegisterConfirmPage = () => {
    const { state }: StateType = useLocation()

    if (!state?.key) {
        return <Navigate to={urls.root} />
    }
    return (
        <AuthBody>
            <CenterText>
                <Typography variant="h6">Email address confirmed!</Typography>
                <StyledElement sx={{ pt: '32px', pb: '32px' }}>
                    <ConfirmIcon />
                </StyledElement>
                <Button
                    component={Link}
                    to={urls.root}
                    size="large"
                    fullWidth
                    variant="contained"
                >
                    GO TO FLEETPAL
                </Button>
            </CenterText>
        </AuthBody>
    )
}

export default RegisterConfirmPage

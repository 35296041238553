import { UnitModel } from 'appTypes/models'

import { SinceLastServiceTrackWidgetLabelProps } from '../components/Label'

type dataSinceLastRepairParams = {
    lastRepairMeters: UnitModel['lastCompletedWorkOrderMeters']
    lastMeters: UnitModel['lastMeters']
    lastWorkOrder: UnitModel['lastCompletedWorkOrder']
}

type funcType = ({
    lastRepairMeters,
    lastMeters,
}: dataSinceLastRepairParams) => SinceLastServiceTrackWidgetLabelProps

const getSinceLastRepairData: funcType = ({ lastRepairMeters, lastMeters, lastWorkOrder }) => {
    // Calculate Miles
    let milesSinceLastService = null
    if (lastRepairMeters?.length && lastMeters?.length) {
        const firstValidMeter = lastMeters.find((meter) => meter.type === 'ODOMETER')
        const firstValidRepairMeter = lastRepairMeters.find((meter) => meter.type === 'ODOMETER')
        milesSinceLastService =
            firstValidMeter && firstValidRepairMeter
                ? firstValidMeter.value - firstValidRepairMeter.value
                : null
    }

    // Calculate Time
    const lastWorkOrderCompletionDate = new Date(lastWorkOrder.completed)
    const currentTime = new Date()
    const differenceInTime = currentTime.getTime() - lastWorkOrderCompletionDate.getTime()
    const timeSinceLastService = differenceInTime / (1000 * 3600 * 24)

    return { milesSinceLastService, timeSinceLastService }
}
export default getSinceLastRepairData

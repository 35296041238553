import { Navigate, useLocation } from 'react-router-dom'

export const RemoveTrailingSlash = () => {
    const location = useLocation()

    if (location.pathname.match('/.*/$')) {
        return (
            <Navigate
                replace
                to={{
                    pathname: location.pathname.replace(/\/+$/, ''),
                    search: location.search,
                }}
            />
        )
    }

    return null
}

import { ComponentProps } from 'react'

import { cardHeaderClasses, styled } from '@mui/material'

import { InfoCardHeader } from 'components/InfoCard'

interface HeaderInfoProps extends Omit<ComponentProps<typeof InfoCardHeader>, 'actions'> {}

const HeaderInfo = styled((props: HeaderInfoProps) => {
    return (
        <InfoCardHeader
            {...props}
            noDivider
        />
    )
})`
    & .${cardHeaderClasses.avatar} {
        margin-right: 0;
    }
    & .${cardHeaderClasses.content} {
        overflow: hidden;
        white-space: nowrap;
    }

    & .${cardHeaderClasses.avatar} + .${cardHeaderClasses.content} {
        padding-left: 10px;

        ${({ theme }) => `
        ${theme.breakpoints.up('sm')} {
                padding-left: 24px;
            }
        `}
    }
`

export default HeaderInfo

import { SvgIcon } from '@mui/material'

import { BoxContainer, Columns, Typography } from 'components'
import { JobItemsCalculations } from 'pages/Dashboard/components/Widgets/components/types'
import { formatPercent, resolveDoublePriceMask } from 'utils'

import useJobItemsTooltipData, {
    JobLineItemsMapType,
    useJobItemsTooltipDataOptions,
} from './useJobItemsTooltipData'

export type LineItemsTooltipContentProps = {
    data: JobLineItemsMapType | (Partial<JobItemsCalculations> & { id?: string })
    allWhite?: useJobItemsTooltipDataOptions['allWhite']
}

const LineItemsTooltipContent = ({ data, allWhite = false }: LineItemsTooltipContentProps) => {
    const resolveJobItemsData = useJobItemsTooltipData()
    const mutatedData = Array.isArray(data) ? data : resolveJobItemsData(data, { allWhite })

    const List = mutatedData.flatMap((item) => {
        const { total, label, icon: ItemIcon, totalPercent } = item

        if (total === null || total === undefined) {
            return []
        }
        return (
            <BoxContainer
                padding="4px"
                key={item.id}
                justifyContent="space-between"
                width="132px"
                color="white"
            >
                <BoxContainer gap="5px">
                    <SvgIcon
                        component={ItemIcon}
                        sx={(theme) => ({
                            width: '14px',
                            height: '14px',
                            color: item?.color || theme.palette.white,
                        })}
                    />
                    <Typography
                        color="inherit"
                        variant="tooltip"
                    >
                        {label}
                    </Typography>
                </BoxContainer>
                <Typography
                    color="inherit"
                    variant="tooltip"
                >
                    {totalPercent === null ? '' : formatPercent(totalPercent)}{' '}
                    {resolveDoublePriceMask(total)}
                </Typography>
            </BoxContainer>
        )
    })
    return (
        <Columns
            gap="8px"
            padding={List.length ? '4px' : '0px'}
        >
            {List}
        </Columns>
    )
}

export default LineItemsTooltipContent

import { ComponentType, FC } from 'react'

import { AllPermissions, PermissionsModel } from 'appTypes/models'
import { useResourcePermissions } from 'hooks'
import { hasPermission } from 'utils'

const renderOnPermission = <P = any,>(
    Component: ComponentType<P>,
    permission:
        | keyof AllPermissions
        | ((params: { props: P; permissions: AllPermissions }) => boolean),
    name?: keyof PermissionsModel,
): FC<P> => {
    return function (props: P) {
        const permissions = useResourcePermissions(
            name ? { name, resource: name } : (props as any).resource,
        )

        if (
            typeof permission === 'function'
                ? !hasPermission(permission({ props, permissions }))
                : !hasPermission(permissions?.[permission])
        ) {
            return null
        }

        return <Component {...props} />
    }
}

export default renderOnPermission

import { FC, ReactElement, ReactNode } from 'react'

import { Box, BoxProps, CardProps as MuiCardProps, Theme, useMediaQuery } from '@mui/material'

import listNotFound from 'assets/images/list-filter-no-results.svg'
import listEmpty from 'assets/images/list-ftu.svg'
import NoResultsLayout from 'components/NoResultsLayout'
import { Columns, SectionTypography } from 'components/styled'

import { CardProps, ColumnCard, RowCard } from './styled'

type CustomCardProps = Omit<MuiCardProps, 'title'> & CardProps

const CustomCard: FC<CustomCardProps> = ({ ...props }) => {
    const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
    const computedDirection = props.directionsm && matches ? props.directionsm : props.direction

    return computedDirection === 'column' ? <ColumnCard {...props} /> : <RowCard {...props} />
}

const imageChoices = {
    listEmpty,
    listNotFound,
}
export interface NoResultsCardProps extends CustomCardProps {
    action?: ReactNode
    imageSrc: ((images: typeof imageChoices) => string) | string
    imageProps: BoxProps<'img'>
    title: ReactNode
    subtitle?: ReactNode
    gridItem?: boolean
}
interface WithResultsLayoutType {
    children: ReactElement
    gridItem: boolean
}
const WithResultsLayout = ({ children, gridItem }: WithResultsLayoutType) => {
    if (!gridItem) {
        return children
    }

    return <NoResultsLayout>{children}</NoResultsLayout>
}
const NoResultsCard: FC<NoResultsCardProps> = ({
    gridItem,
    title,
    subtitle,
    action,
    imageSrc,
    imageProps,
    ...props
}) => {
    return (
        <WithResultsLayout gridItem={gridItem}>
            <CustomCard {...props}>
                <Box
                    component="img"
                    src={typeof imageSrc === 'function' ? imageSrc(imageChoices) : imageSrc}
                    {...imageProps}
                    sx={{
                        objectFit: 'cover',
                        ...imageProps.sx,
                    }}
                    alt="No Results"
                />
                <Columns className="content">
                    <SectionTypography
                        variant="h5"
                        textAlign="left"
                    >
                        {title}
                    </SectionTypography>
                    {subtitle && (
                        <SectionTypography
                            variant="body1"
                            mb="6px"
                            as="div"
                        >
                            {subtitle}
                        </SectionTypography>
                    )}
                    {action}
                </Columns>
            </CustomCard>
        </WithResultsLayout>
    )
}
NoResultsCard.defaultProps = {
    direction: 'column',
    gridItem: true,
}
export default NoResultsCard

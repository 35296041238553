import { StatusType } from 'appTypes'
import Typography from 'components/mui/Typography'
import { Status } from 'components/styled'

const HeaderStatus = ({ iconColor, text }: StatusType) => {
    return (
        <>
            <Typography
                variant="tooltip"
                color="text.disabled"
                textTransform="uppercase"
                display="flex"
                alignItems="center"
                whiteSpace="nowrap"
                sx={{ fontSize: '12px' }}
            >
                <Status
                    size="8px"
                    mr="5px"
                    iconColor={iconColor}
                />
                {text}
            </Typography>
        </>
    )
}

export default HeaderStatus

import { ReferenceInput } from 'react-admin'

import { TextInput, SelectInput, EmailInput } from 'components'
import { requiredValidation } from 'utils'

const MemberForm = () => {
    return (
        <>
            <EmailInput
                label="Email Address"
                disabled
            />

            <TextInput
                source="name"
                label="Name"
                disabled
            />

            <ReferenceInput
                reference="roles"
                source="role"
            >
                <SelectInput
                    optionText="label"
                    validate={requiredValidation}
                    label="Role"
                    disableEmptyValue
                />
            </ReferenceInput>
        </>
    )
}

export default MemberForm

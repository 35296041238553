import { useState } from 'react'

import { reportUrl } from 'pages/Reports'
import { createReportResource } from 'pages/Units/config/constants'
import { resolveDoublePriceMask } from 'utils'

import WidgetBase, { WidgetSkeleton } from '../../WidgetBase/WidgetBase'
import { useCreateDashboardWidgetLink, useDashboardWidget } from '../../WidgetsForm'
import { WidgetLineChartBase } from '../components'

import { Clarification, CostListSelect, CostPerMileList } from './components'
import { listOrderType, ResponseType } from './types'

const description = `The accumulated maintenance expenses are divided by the total mileage logged, and
the result is displayed for the selected period of time, empowering you to spot the trend at once.`
let isValid = false
const FleetCostPerMileWidget = () => {
    const createLink = useCreateDashboardWidgetLink()

    const [listOrder, setListOrder] = useState<listOrderType>('DESC')
    const data = useDashboardWidget<ResponseType>({
        resource: createReportResource('cost-per-mile'),
        sideEffect: (data) => {
            isValid = typeof data.fleetAverageCostPerMile === 'number'
            return data
        },
    })
    if (!data) {
        return <WidgetSkeleton />
    }
    const {
        fleetAverageCostPerMile,
        changeInPercentsForPeriod,
        maxCostPerMileUnits,
        fleetCostPerMilePoints,
    } = data

    return (
        <WidgetBase
            headerProps={{
                title: 'Maintenance CPM',
                label: isValid ? resolveDoublePriceMask(fleetAverageCostPerMile) : null,
                tooltipText: description,
                clarification: <Clarification changeForPeriod={changeInPercentsForPeriod} />,
                chart: (
                    <WidgetLineChartBase
                        data={fleetCostPerMilePoints}
                        isValid={isValid}
                    />
                ),
            }}
            infoProps={{
                title: (
                    <CostListSelect
                        setListOrder={setListOrder}
                        listLength={maxCostPerMileUnits.length}
                    />
                ),
                content: (
                    <CostPerMileList
                        data={maxCostPerMileUnits}
                        description={description}
                        listOrder={listOrder}
                    />
                ),
            }}
            link={createLink(reportUrl(reportUrl.urls.costPerMeter))}
        />
    )
}
export default FleetCostPerMileWidget

import { ComponentProps } from 'react'

import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined'
import { SvgIcon } from '@mui/material'
import { useListController } from 'react-admin'

import { Invoice } from 'appTypes/models'
import { InfoCard, InfoCardHeader, LinkButton, Typography } from 'components'
import Icons from 'components/icons'
import { urls } from 'configs/config'

interface InvoiceLinkProps extends ComponentProps<typeof LinkButton> {
    Icon: typeof SvgIcon
}

const InvoiceLink = ({ children, Icon, ...props }: InvoiceLinkProps) => {
    return (
        <LinkButton
            display="flex"
            alignItems="center"
            gap="4px"
            {...props}
        >
            <Icon sx={{ width: '16px', height: '16px' }} />
            {children}
        </LinkButton>
    )
}

const CompanyPaymentHistory = () => {
    // TODO: BE - add limit and filter to this endpoint
    const { data } = useListController<Invoice>({
        resource: '/company/invoices',
    })

    const firstInvoice = data?.find((invoice) => invoice.invoicePdf)
    const invoicePdf = firstInvoice?.invoicePdf

    return (
        <InfoCard>
            <InfoCardHeader title={<Typography variant="subtitle2">Payment history</Typography>} />
            <InvoiceLink
                disabled={!invoicePdf}
                download
                href={invoicePdf}
                mb="12px"
                Icon={Icons.Export}
            >
                Download Last Receipt
            </InvoiceLink>
            <InvoiceLink
                Icon={ReceiptOutlinedIcon}
                to={urls.paymentHistory}
            >
                View Payment History
            </InvoiceLink>
        </InfoCard>
    )
}

export default CompanyPaymentHistory

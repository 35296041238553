import { ReactNode, useEffect, useId } from 'react'

import { styled, Theme } from '@mui/material'
import { useListContext, ListControllerProps, RaRecord, ListControllerResult } from 'react-admin'

import { Skeleton } from 'components'
import { DataCardProps } from 'components/DataCard/DataCard'
import { InfoCard } from 'components/InfoCard'
import { Action, SingleAction } from 'components/actions'
import { GridItemCard } from 'components/pages'
import { GridContainerGrid } from 'components/pages/GridContainer'
import { BoxContainer, Columns } from 'components/styled'
import { Flags } from 'hooks'

import CardListCard from './CardListCard'

export interface CardListConfig<RecordType extends RaRecord = any>
    extends Omit<
        DataCardProps,
        | 'record'
        | 'title'
        | 'subtitle'
        | 'image'
        | 'details'
        | 'defaultImage'
        | 'color'
        | 'id'
        | 'actions'
        | 'actionsDisabled'
        | 'withStatus'
        | 'collapsibleContent'
    > {
    collapsibleContent?: ReactNode | ((record: RecordType) => ReactNode)
    withStatus?: ((params: { flags: Flags }) => boolean) | boolean
    titleSource: keyof RecordType | ((record: RecordType) => ReactNode)
    titleLink?: string | ((record: RecordType) => string)
    subTitleSource?:
        | keyof RecordType
        | ((
              record: RecordType,
              params: {
                  flags: Flags
                  listContext: ListControllerResult<RecordType>
              },
          ) => ReactNode)
    imageSource?: keyof RecordType | ((record: RecordType) => string)
    colorGetter?: (record: RecordType, theme: Theme) => DataCardProps['color']
    defaultImage:
        | DataCardProps['defaultImage']
        | ((record: RecordType) => DataCardProps['defaultImage'])
    avatarColor?: DataCardProps['avatarColor']
    avatarOpacity?: DataCardProps['avatarOpacity']
    actions?: Action<RecordType>
    action?: SingleAction<RecordType>
    actionsDisabled?: (record: RecordType) => boolean
    details:
        | {
              [K in keyof RecordType]: {
                  source: K
                  label?: (({ record }: { record: RecordType }) => ReactNode) | ReactNode
                  render?: (value: RecordType[K], record: RecordType) => ReactNode
              }
          }[keyof RecordType][]
        | ((record: RecordType) => ReactNode)
}
export interface CardListProps<RecordType extends RaRecord = any> extends ListControllerProps {
    cardsCfg: CardListConfig<RecordType>
    disableSelectRecord?: (record: RecordType) => string
}

const StyledGridContainer = styled(GridContainerGrid)`
    &:last-of-type {
        padding-bottom: 24px;
    }
`
const useSetCardsHeight = (id: string, isLoading: boolean) => {
    useEffect(() => {
        const card = document.querySelector(`[id="${id}"] .data-card`)
        if (card) {
            const grid = document.querySelector(`[id="${id}"]`) as HTMLElement
            grid.style.setProperty('--maxHeight', `${card.getBoundingClientRect().height}px`)
        }
    }, [isLoading])
}
const CardListSkeleton = ({ height }: { height: `${number}px` }) => {
    return (
        <GridItemCard>
            <InfoCard
                sx={{
                    height,
                }}
            >
                <Columns
                    gap="32px"
                    height="100%"
                >
                    <BoxContainer>
                        <Skeleton
                            height="40px"
                            width="40px"
                            variant="circular"
                        />
                        <Columns
                            marginLeft="16px"
                            gap="5px"
                        >
                            <Skeleton
                                height="17px"
                                width="150px"
                            />
                            <Skeleton
                                height="12px"
                                width="150px"
                            />
                        </Columns>
                    </BoxContainer>
                    <Skeleton />
                </Columns>
            </InfoCard>
        </GridItemCard>
    )
}
const CardSkeletons = new Array(50).fill(0).map((v, i) => (
    <CardListSkeleton
        height="272px"
        key={i}
    />
))
const CardList = <RecordType extends RaRecord = any>({
    cardsCfg,
    disableSelectRecord,
    ...props
}: CardListProps<RecordType>) => {
    const { data, isLoading } = useListContext<RecordType>(props)
    const id = useId()
    useSetCardsHeight(id, isLoading)

    if (isLoading) {
        return null
        // <StyledGridContainer>{CardSkeletons}</StyledGridContainer>, push at the same as datagrid
    }

    return (
        <StyledGridContainer
            id={id}
            sx={{
                '--maxHeight': '100%',
                position: 'relative',
                zIndex: '1',
                '& .data-card': {
                    maxHeight: 'var(--maxHeight)',
                },
            }}
        >
            {data.map((record, i) => (
                <CardListCard
                    record={record}
                    cardsCfg={cardsCfg}
                    key={record.id}
                    sx={{ zIndex: data.length - i, position: 'relative' }}
                    disableSelectRecord={disableSelectRecord}
                />
            ))}
        </StyledGridContainer>
    )
}

export default CardList

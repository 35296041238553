import Header from 'components/pages/header'

const PaymentHistoryHeader = () => {
    return (
        <Header fixed>
            <Header.Block
                divider={false}
                mobileCenter
            >
                <Header.BackButton />
                <Header.Main>
                    <Header.Title>Payment History</Header.Title>
                </Header.Main>
            </Header.Block>
        </Header>
    )
}

export default PaymentHistoryHeader

import { alpha, Divider } from '@mui/material'
import { RaRecord } from 'react-admin'

import { CollapsibleContent } from 'components/Collapsible'
import { GalleryItem, getRecordPhotos, Photos, photosToArray } from 'components/Gallery'
import Gallery, { GalleryItemGrid } from 'components/Gallery/Gallery'
import { Chip, Typography } from 'components/mui'
import { BoxContainer, Columns } from 'components/styled'

export interface PhotosCollapsibleContentProps {
    record: RaRecord & Photos
}
const PhotosCollapsibleContent = ({ record }: PhotosCollapsibleContentProps) => {
    const photos = getRecordPhotos(record)
    const files = photosToArray(photos.files)
    return (
        <CollapsibleContent name="photos">
            <Columns mt="15px">
                <Typography
                    gap="8px"
                    component={BoxContainer}
                    variant="chartTitle"
                    mb="10px"
                >
                    Photos
                    <Chip
                        component="span"
                        label={photos.count}
                        sx={(theme) => ({
                            bgcolor: alpha(theme.palette.text.primary, 0.08),
                            fontSize: '12px',
                        })}
                        variant="filled"
                        size="extraSmall"
                    />
                </Typography>
                <Divider />
                <Gallery>
                    <GalleryItemGrid
                        gridHeight="73px"
                        height="73px"
                        gap="16px"
                    >
                        {files.map((file) => {
                            return (
                                <GalleryItem
                                    id={String(file.id)}
                                    file={file.file}
                                    key={file.id}
                                    sx={{
                                        height: 'inherit',
                                        width: '100%',
                                    }}
                                />
                            )
                        })}
                    </GalleryItemGrid>
                </Gallery>
            </Columns>
        </CollapsibleContent>
    )
}
export default PhotosCollapsibleContent

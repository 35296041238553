import { Link } from '@mui/material'
import { RaRecord, useRecordContext } from 'react-admin'
import { Link as RouterLink } from 'react-router-dom'

import { WorkOrderModel, WorkOrderType } from 'appTypes/models'
import { basePermissions } from 'configs/constants'
import { renderOnPermission } from 'hocs'
import { UseCreateResourcePathParams } from 'hooks'
import { formDefaultValuesQuery } from 'utils'

import { WorkOrderCreateMenu } from '../components'
import { useWoCreateLink } from '../hooks'

export interface WorkOrderSimpleListNoResultsCreateActionProps<RecordType extends RaRecord> {
    createFormInitialValues: (record: RecordType) => Partial<WorkOrderModel>
    workOrderType?: WorkOrderType
}

const Toggler: typeof Link = (props) => {
    return (
        <Link
            variant="body1"
            {...props}
        >
            Create Work Order
        </Link>
    )
}

const WorkOrderToggler = ({
    queryParams,
    workOrderType,
}: Pick<UseCreateResourcePathParams, 'queryParams'> & { workOrderType?: WorkOrderType }) => {
    const { to, clickHandler } = useWoCreateLink()

    return (
        <Toggler
            onClick={clickHandler}
            component={RouterLink}
            to={to({ queryParams, workOrderType })}
        />
    )
}

const WorkOrderSimpleListNoResultsCreateAction = renderOnPermission(
    <RecordType extends RaRecord>({
        createFormInitialValues,
        workOrderType,
    }: WorkOrderSimpleListNoResultsCreateActionProps<RecordType>) => {
        const record = useRecordContext<RecordType>()
        const queryParams = formDefaultValuesQuery<WorkOrderModel>(createFormInitialValues(record))

        if (workOrderType) {
            return (
                <WorkOrderToggler
                    queryParams={queryParams}
                    workOrderType={workOrderType}
                />
            )
        }

        return (
            <WorkOrderCreateMenu
                renderToggler={(open) => <Toggler onClick={open} />}
                queryParams={queryParams}
            />
        )
    },
    basePermissions.create,
    'work-orders',
)

export default WorkOrderSimpleListNoResultsCreateAction

import { useState, useEffect } from 'react'

import { CheckCircleOutline } from '@mui/icons-material'
import { Alert, Button, alertClasses } from '@mui/material'
import { observer } from 'mobx-react'

import Typography from 'components/mui/Typography'
import { LoadingSpinner } from 'components/styled'

import { useUserTaskSync } from './Sync'

const barMap = {
    info: {
        icon: <LoadingSpinner />,
        text: 'Integrations data syncing in the background',
    },
    success: {
        icon: <CheckCircleOutline sx={{ color: (theme) => theme.palette.success.main }} />,
        text: 'Integrations data has synchronized successfully',
    },
}

const TelematicsSyncBar = observer(() => {
    const shouldSync = useUserTaskSync()
    const [prevSync, setPrevSync] = useState(shouldSync)

    useEffect(() => {
        if (shouldSync) {
            setPrevSync(shouldSync)
        } else {
            const timer = setTimeout(() => {
                setPrevSync(false)
            }, 5000)

            return () => {
                clearTimeout(timer)
            }
        }
    }, [shouldSync])

    const sync = (shouldSync && 'info') || (prevSync && 'success')

    if (!sync) {
        return null
    }

    return (
        <Alert
            sx={{
                height: '60px',
                alignItems: 'center',
                padding: '6px 22px',
                [`& .${alertClasses.action}`]: {
                    marginRight: 0,
                },
            }}
            severity={sync}
            icon={barMap[sync].icon}
            action={
                sync === 'success' && (
                    <Button
                        size="small"
                        color="inherit"
                        sx={{ minWidth: 'fit-content' }}
                        onClick={() => setPrevSync(false)}
                    >
                        Close
                    </Button>
                )
            }
        >
            <Typography
                color="inherit"
                variant="body2"
            >
                {barMap[sync].text}
            </Typography>
        </Alert>
    )
})

export default TelematicsSyncBar

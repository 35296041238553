import { Identifier, RaRecord, useRecordContext } from 'react-admin'
import { useNavigate } from 'react-router-dom'

import useArchive, { ArchiveParams } from './useArchive'
import useCreateResourcePath from './useCreateResourcePath'

export interface ArchiveOneParams extends Omit<ArchiveParams, 'path'> {
    redirectTo?: 'list' | ''

    id?: Identifier
}

const useArchiveOne = () => {
    const archive = useArchive()
    const navigate = useNavigate()
    const createPath = useCreateResourcePath()
    const { id } = useRecordContext() || ({} as RaRecord)

    return ({ redirectTo = 'list', ...params }: ArchiveOneParams = {}) => {
        archive(({ resource }) => {
            const finalId = params.id || id
            const finalResource = params.resource || resource

            return {
                ...params,
                path: createPath({
                    resource: finalResource.resource,
                    id: finalId,
                    type: 'edit',
                }),
                onSuccess: (response) => {
                    params.onSuccess?.(response)
                    if (redirectTo) {
                        navigate(
                            createPath({
                                resource: finalResource.resource,
                                id: finalId,
                                type: redirectTo,
                            }),
                        )
                    }
                },
            }
        })
    }
}

export default useArchiveOne

import {
    ArrowRightOutlined,
    AttachFileOutlined,
    CalendarTodayOutlined,
    CommentOutlined,
    HowToRegOutlined,
} from '@mui/icons-material'
import { SvgIcon, alpha, styled, Alert, Divider, BoxProps } from '@mui/material'
import { useListContext, useResourceContext } from 'react-admin'
import { Link } from 'react-router-dom'

import api from 'api/api'
import { ReactComponent as DvirInProgress } from 'assets/images/dvir-inprogress.svg'
import { ReactComponent as DvirUnresolved } from 'assets/images/dvir-unresolved.svg'
import 'photoswipe/dist/photoswipe.css'
import {
    BoxContainer,
    CollapsibleContent,
    CollapsibleInfoCard,
    InfoCardDetails,
    InfoCardHeader,
    Typography,
    Columns,
    useArchivedContext,
    CollapsibleIconHolder,
    formatCK33CodeByLevel,
    DataAvatar,
    InfoBadge,
    Gallery,
    GalleryItemGrid,
    SimpleScrollbar,
    RawGalleryItem,
} from 'components'
import Icons from 'components/icons'
import { useConfirm, useCreateResourcePath, useNotify } from 'hooks'
import { DvirCk33Label } from 'pages/Reports/Dvirs/Dvirs'
import { woResource } from 'pages/WorkOrders/config/constants'
import { formatDate, pathJoin } from 'utils'

import { AddWorkOrderMenu } from '../components'
import { WorkOrderCreateMenuProps } from '../components/AddWorkOrderMenu'

import { UnitDefect } from './UnitDVIR'

export const DriverPhotos = ({ files }: { files: string[] }) => {
    let content = <Alert severity="info">No Photos Added</Alert>
    if (files.length) {
        content = (
            <Gallery>
                <GalleryItemGrid>
                    {files.map((file, i) => {
                        return (
                            <RawGalleryItem
                                id={`${i}`}
                                key={i}
                                src={file}
                                sx={{
                                    height: '89px',
                                }}
                            />
                        )
                    })}
                </GalleryItemGrid>
            </Gallery>
        )
    }
    return (
        <Columns
            gap="14px"
            mt="10px"
        >
            <BoxContainer justifyContent="space-between">
                <Typography
                    color="text.secondary"
                    variant="chartTitle"
                    component={BoxContainer}
                    gap="8px"
                >
                    Photos <InfoBadge badgeContent={files.length} />
                </Typography>
            </BoxContainer>
            {content}
        </Columns>
    )
}
interface DriverNoteProps extends Pick<UnitDefect, 'authorName' | 'detectedOn'> {
    notes: string[]
}
export const DriverNotes = ({ notes, ...rest }: DriverNoteProps) => {
    let content = <Alert severity="info">No Notes Added</Alert>
    if (notes.length) {
        content = (
            <SimpleScrollbar
                sx={{
                    maxHeight: '144px',
                }}
            >
                <Columns gap="10px">
                    {notes.map((text) => (
                        <Note
                            description={text}
                            {...rest}
                        />
                    ))}
                </Columns>
            </SimpleScrollbar>
        )
    }
    return (
        <Columns
            gap="14px"
            mt="10px"
        >
            <BoxContainer justifyContent="space-between">
                <Typography
                    color="text.secondary"
                    variant="chartTitle"
                    component={BoxContainer}
                    gap="8px"
                >
                    Notes <InfoBadge badgeContent={notes.length} />
                </Typography>
            </BoxContainer>
            {content}
        </Columns>
    )
}
interface NoteProps extends BoxProps, Pick<DriverNoteProps, 'authorName' | 'detectedOn'> {
    description: DriverNoteProps['notes'][0]
}
export const Note = styled(({ authorName, detectedOn, description, ...props }: NoteProps) => {
    return (
        <Columns
            gap="4px"
            {...props}
        >
            <Typography variant="chartTitle">{authorName}</Typography>
            <Typography
                variant="chartLabel"
                component={BoxContainer}
                gap="13ox"
                color="text.secondary"
            >
                <SvgIcon
                    component={CalendarTodayOutlined}
                    sx={{
                        width: '10px',
                        height: '10px',
                        marginRight: '4px',
                    }}
                />
                {formatDate(detectedOn, 'MMM dd yyyy hh:mm a')}
            </Typography>
            <Typography
                variant="chartsBody"
                sx={{ wordBreak: 'break-word' }}
            >
                {description}
            </Typography>
        </Columns>
    )
})`
    padding: 12px;
    gap: 4px;
    border-radius: 8px;
    background: ${({ theme }) => alpha(theme.palette.text.primary, 0.06)};
`

const UnitDvirDefectCard = (data: UnitDefect) => {
    const isArchived = useArchivedContext()
    const createPath = useCreateResourcePath()
    const { refetch } = useListContext<UnitDefect>()
    const resource = useResourceContext()
    const notify = useNotify()

    const confirm = useConfirm()
    const isInProgress = Boolean(data.workOrderData)
    const { name, workOrderData, detectedOn, driverComment, id, authorName, photo } = data
    const woAttachConfig: WorkOrderCreateMenuProps = {
        options: {
            jobDisabledFields: {
                name: true,
                reasonForRepair: true,
            },
            jobDefaultValues: {
                photo0: data.photo,
                notes: driverComment
                    ? [
                          {
                              createdByData: {
                                  name: authorName,
                              },
                              text: driverComment,
                              created: detectedOn,
                              source: 'DEFECT',
                          },
                      ]
                    : [],
            },
        },
        parentRecord: data,
        disabled: isArchived || Boolean(workOrderData),
        refetchList: 'defects',
    }

    return (
        <CollapsibleInfoCard sameParent>
            <InfoCardHeader
                title={
                    <Typography
                        variant="subtitle2"
                        lineHeight="100%"
                    >
                        {name}
                    </Typography>
                }
                actions={(params, { children }) => [
                    children({
                        title: 'Manual Resolve',
                        Icon: HowToRegOutlined,
                        key: 'resolve',
                        onClick: () =>
                            confirm({
                                title: 'Are you sure you want to resolve this defect manually?',
                                content: (
                                    <Typography
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        Resolved defects will no longer appear on this list.
                                        <br />
                                        {isInProgress ? (
                                            <span>
                                                Its corresponding{' '}
                                                {
                                                    <Typography
                                                        color="primary"
                                                        variant="body2"
                                                        component={Link}
                                                        to={
                                                            createPath({
                                                                resource: woResource.resource,
                                                                id: workOrderData.id,
                                                                type: 'edit',
                                                            }) + '/jobs'
                                                        }
                                                        state={{ forceRedirect: true }}
                                                    >
                                                        jobs ({workOrderData.number})
                                                    </Typography>
                                                }{' '}
                                                will not be deleted.
                                            </span>
                                        ) : null}
                                    </Typography>
                                ),
                                confirmButtonProps: {
                                    color: 'error',
                                    variant: 'text',
                                    children: 'MANUAL RESOLVE',
                                    startIcon: <HowToRegOutlined />,
                                },
                                onConfirm: async () => {
                                    await api.post(pathJoin(resource, String(id), 'resolve'), {
                                        resolvedOn: new Date(),
                                    })
                                    refetch()
                                    notify('Successfully Resolved!', { type: 'success' })
                                },
                            }),
                    }),
                    <AddWorkOrderMenu<UnitDefect>
                        {...woAttachConfig}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        key="add-wo"
                        renderToggler={(open) =>
                            children({
                                title: (
                                    <BoxContainer
                                        justifyContent="space-between"
                                        component="span"
                                        width="140px"
                                    >
                                        Add to WO <ArrowRightOutlined fontSize="small" />
                                    </BoxContainer>
                                ),

                                Icon: Icons.WorkOrders,
                                onClick: open,
                                disabled: isArchived || Boolean(workOrderData),
                                disableCloseOnClick: true,
                            })
                        }
                    />,
                ]}
                subheader={
                    <Typography variant="chartsBody">
                        {workOrderData ? 'In Progress' : 'Unresolved'}
                    </Typography>
                }
                avatar={
                    <DataAvatar
                        color={({ palette }) => palette[workOrderData ? 'primary' : 'error'].main}
                        defaultImage={workOrderData ? <DvirInProgress /> : <DvirUnresolved />}
                    />
                }
            />
            <InfoCardDetails
                propRecord={data}
                details={[
                    {
                        label: 'Detected On',
                        source: ({ detectedOn }) => formatDate(detectedOn, 'MMM dd yyyy hh:mm a'),
                    },
                    {
                        label: <DvirCk33Label />,
                        source: ({ componentData }) =>
                            componentData &&
                            formatCK33CodeByLevel(componentData.code, componentData.level) +
                                ' ' +
                                componentData.text,
                    },

                    {
                        label: 'Work orders',
                        source: ({ workOrderData }) =>
                            workOrderData ? (
                                <Typography
                                    variant="body2"
                                    color="primary.main"
                                    component={Link}
                                    to={createPath({
                                        id: workOrderData.id,
                                        resource: woResource.resource,
                                        type: 'edit',
                                    })}
                                >
                                    {workOrderData.number}
                                </Typography>
                            ) : (
                                <AddWorkOrderMenu<UnitDefect> {...woAttachConfig} />
                            ),
                    },
                ]}
            />
            <Divider
                sx={{
                    margin: '10px 0px',
                }}
            />
            <BoxContainer gap="8px">
                <CollapsibleIconHolder
                    name="comment"
                    title="Comments (1)"
                    titleOnDisabled="No Driver Comments Added"
                    disabled={!driverComment}
                >
                    <CommentOutlined fontSize="small" />
                </CollapsibleIconHolder>
                <CollapsibleIconHolder
                    name="files"
                    title="Photos (1)"
                    titleOnDisabled="No Photos Added"
                    disabled={!photo}
                >
                    <AttachFileOutlined fontSize="small" />
                </CollapsibleIconHolder>
            </BoxContainer>
            <CollapsibleContent name="comment">
                <DriverNotes
                    notes={driverComment ? [driverComment] : []}
                    detectedOn={detectedOn}
                    authorName={authorName}
                />
            </CollapsibleContent>
            <CollapsibleContent name="files">
                <DriverPhotos files={photo ? [photo] : []} />
            </CollapsibleContent>
        </CollapsibleInfoCard>
    )
}
export default UnitDvirDefectCard

import { useLayoutEffect, useState } from 'react'

import CameraswitchOutlinedIcon from '@mui/icons-material/CameraswitchOutlined'
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined'
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined'
import QRScanner from 'qr-scanner'

import { Button } from 'components'
import { ActionsMenu } from 'components/actions'

import { qrScannerDefaultCamera, useQRScannerContext } from './QRScannerContent'
const QRScannerChangeCameraButton = () => {
    const [cameras, setCameras] = useState<QRScanner.Camera[]>([
        {
            id: qrScannerDefaultCamera,
            label: 'Environment Facing (default)',
        },
    ])

    const { scanner, setActiveCamera, activeCamera } = useQRScannerContext()

    useLayoutEffect(() => {
        QRScanner.listCameras().then((cameras) => {
            setCameras((oldCameras) => [...oldCameras, ...cameras])
        })
    }, [])

    return (
        <ActionsMenu
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            renderToggler={(open) => (
                <Button
                    variant="contained"
                    onClick={open}
                    startIcon={<CameraswitchOutlinedIcon />}
                >
                    Change Camera
                </Button>
            )}
            actions={(params, { children }) =>
                cameras.map((camera) =>
                    children({
                        key: camera.id,
                        title: camera.label,
                        onClick: () => {
                            scanner.setCamera(camera.id)
                            setActiveCamera(camera.id)
                        },
                        Icon:
                            activeCamera === camera.id
                                ? RadioButtonCheckedOutlinedIcon
                                : RadioButtonUncheckedOutlinedIcon,
                    }),
                )
            }
        />
    )
}

export default QRScannerChangeCameraButton

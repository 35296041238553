import { useRecordContext } from 'react-admin'

import { WorkOrderModel } from 'appTypes/models'
import {
    GridItemLayout,
    InfoCard,
    InfoCardDetails,
    InfoCardHeader,
    ViewHeader,
    Section,
    GridContainerColumns,
    Spacer,
} from 'components'
import {
    SendPoAction,
    PurchaseOrderExportAction,
} from 'pages/WorkOrders/components/WorkOrderPurchaseInvoice'
import { resolveDoublePriceMask } from 'utils'

const PurchaseOrderSection = () => {
    const record = useRecordContext<WorkOrderModel>()
    if (!record) {
        return null
    }
    const { purchaseOrderData } = record
    return (
        <Section>
            <ViewHeader title="Purchase Order" />
            <GridContainerColumns>
                <GridItemLayout>
                    <InfoCard>
                        <InfoCardHeader
                            action={
                                <Spacer>
                                    <SendPoAction />
                                    <PurchaseOrderExportAction />
                                </Spacer>
                            }
                            title={purchaseOrderData.number}
                            titleTypographyProps={{
                                variant: 'subtitle2',
                            }}
                        />
                        <InfoCardDetails<WorkOrderModel>
                            details={[
                                {
                                    label: 'Purchase Order Total',
                                    source: ({ purchaseOrderData }) =>
                                        resolveDoublePriceMask(purchaseOrderData.total),
                                },
                            ]}
                        />
                    </InfoCard>
                </GridItemLayout>
            </GridContainerColumns>
        </Section>
    )
}
export default PurchaseOrderSection

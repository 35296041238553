import { ReactElement, useEffect, useState } from 'react'

import { EventAvailableOutlined, RestoreOutlined } from '@mui/icons-material'
import { Theme, Divider, Link, cardHeaderClasses, SvgIcon } from '@mui/material'
import { differenceInCalendarDays } from 'date-fns'
import { useListContext, useResourceContext, useShowContext } from 'react-admin'

import api from 'api'
import { MeterModel, UnitModel, UnitPMIntervalTypes, UnitPMReminderModel } from 'appTypes/models'
import { ReactComponent as CalendarAlertOutlined } from 'assets/images/calendar-alert-outline.svg'
import { ReactComponent as CalendarClockOutlined } from 'assets/images/calendar-clock-outline.svg'
import {
    BoxContainer,
    CollapsibleContent,
    CollapsibleInfoCard,
    CollapsibleTogglerAction,
    DataAvatar,
    EllipsisOverflow,
    IconBox,
    InfoCardHeader,
    Spacer,
    Typography,
    formatCK33CodeByLevel,
    useArchivedContext,
} from 'components'
import { MenuItemActions, deleteOneFromListAction } from 'components/actions'
import Icons from 'components/icons'
import { useConfirm, useNotify } from 'hooks'
import { serialize, pathJoin, resolveIntegerSpacedMask, formatDate } from 'utils'

import useUnitPMDrawerEditor from '../PMEditor/useUnitPMDrawerEditor'
import { LineSlider } from '../ProgressLineSlider'
import { deleteUnitPMIntervalConfig, getTypeChoice } from '../constants'

import BottomSection from './BottomSection'
import CollapsibleNotes from './CollapsibleNotes'
import MeterTypeSelectionButton from './MeterTypeSelectionButton'
import ResetPmContent from './ResetPmContent'
import ServiceSection from './ServiceSection'

export interface DataType {
    type: MeterModel['type']
    every: number
    lastDone: number
    value: number
    color?: string
    total: number
    threshold?: number
    image?: ReactElement
    note?: string
}

export const getImage = (status: UnitPMReminderModel['mostUrgentStatus']) => {
    if (status === 'PLANNED') {
        return EventAvailableOutlined
    }
    if (status === 'OVERDUE') {
        return CalendarAlertOutlined
    }
    return CalendarClockOutlined
}
export const tooltipValueFormatter = (value: number, type: UnitPMIntervalTypes) => {
    if (type === 'ENGINE_HOURS') {
        return `${resolveIntegerSpacedMask(value)} h`
    }
    return `${resolveIntegerSpacedMask(value)} mi`
}
export const getColor = (status: UnitPMReminderModel['mostUrgentStatus'], isArchived?: boolean) => {
    if (isArchived) {
        return (theme: Theme) => theme.palette.action.disabled
    }
    if (status === 'PLANNED') {
        return (theme: Theme) => theme.palette.success.main
    }
    if (status === 'OVERDUE') {
        return (theme: Theme) => theme.palette.error.main
    }
    return (theme: Theme) => theme.palette.charts.orange
}
const UnitPMCard = ({ unitPmRecord }: { unitPmRecord: UnitPMReminderModel }) => {
    const isArchived = useArchivedContext()
    const { record } = useShowContext<UnitModel>()

    const [selectedPM, setSelectedPM] = useState(() =>
        unitPmRecord.mostUrgentType
            ? unitPmRecord.intervals.find((item) => item.type === unitPmRecord.mostUrgentType)
            : unitPmRecord.intervals[0],
    )

    const resource = useResourceContext()
    const confirm = useConfirm()
    const { refetch, data } = useListContext<UnitPMReminderModel>()
    const notify = useNotify()
    const getThresholdValue = () => {
        if (selectedPM.threshold === null) {
            return 100
        }
        if (selectedPM.type === 'TIME') {
            return (
                ((selectedPM.threshold * getTypeChoice(selectedPM.thresholdType).toDay) /
                    (selectedPM.value * getTypeChoice(selectedPM.valueType).toDay)) *
                100
            )
        }
        return (selectedPM.threshold / selectedPM.value) * 100
    }
    useEffect(
        () =>
            setSelectedPM(() =>
                unitPmRecord.intervals.find((item) => item.type === selectedPM.type),
            ),
        [unitPmRecord],
    )
    const currentValue =
        (selectedPM.passed /
            (selectedPM.type === 'TIME'
                ? Math.abs(
                      differenceInCalendarDays(
                          new Date(unitPmRecord.lastDone),
                          new Date(selectedPM.overdue),
                      ),
                  )
                : selectedPM.value)) *
        100

    const current = 100 - getThresholdValue()
    const edit = useUnitPMDrawerEditor()

    const editPM = () => edit({ id: unitPmRecord.id, type: 'dependent' })
    const Icon = Icons.Options

    const actions: MenuItemActions<any, {}> = isArchived
        ? undefined
        : (args, { children }) => [
              children({
                  Icon: Icons.Edit,
                  title: 'Edit',
                  key: 'edit',
                  onClick: editPM,
              }),
              children({
                  Icon: RestoreOutlined,
                  title: 'Reset',
                  key: 'reset',
                  onClick: () => {
                      confirm({
                          title: 'Are you sure you want to reset this interval?',
                          closeOnError: false,
                          content: (
                              <ResetPmContent
                                  data={data}
                                  record={record}
                                  unitPmRecord={unitPmRecord}
                              />
                          ),
                          onConfirm: async ({ formValues }) => {
                              const { lastDone, ...inputValues } = formValues
                              const data = serialize({ ...formValues }, [
                                  { name: 'lastDone', parse: 'dateTime' },
                                  ...Object.keys(inputValues),
                              ])
                              await api.post(
                                  pathJoin(resource, String(unitPmRecord.id), 'reset'),
                                  data,
                              )
                              notify('PM Reset Successfully!', {
                                  type: 'success',
                              })
                              refetch()
                          },
                          confirmButtonProps: {
                              children: 'Reset',
                              startIcon: <RestoreOutlined />,
                          },
                          awaitOnConfirm: true,
                      })
                  },
              }),
              deleteOneFromListAction({
                  children,
                  ...deleteUnitPMIntervalConfig,
                  id: unitPmRecord.id,
              }),
          ]

    return (
        <CollapsibleInfoCard>
            <InfoCardHeader
                sx={{
                    [`& .${cardHeaderClasses.content}`]: {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    },
                }}
                avatar={
                    <DataAvatar
                        defaultImage={
                            <SvgIcon
                                component={getImage(unitPmRecord.mostUrgentStatus)}
                                sx={(theme) => ({
                                    color: getColor(unitPmRecord.mostUrgentStatus, isArchived),
                                })}
                            />
                        }
                        color={getColor(unitPmRecord.mostUrgentStatus, isArchived)}
                    />
                }
                title={
                    <Link
                        component={EllipsisOverflow}
                        onClick={editPM}
                    >
                        {unitPmRecord.name}
                    </Link>
                }
                subheader={
                    <Typography
                        variant="chartsBody"
                        sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {formatCK33CodeByLevel(
                            unitPmRecord.componentData.code,
                            unitPmRecord.componentData.level,
                        )}{' '}
                        {unitPmRecord.componentData.text}
                    </Typography>
                }
                action={
                    isArchived ? (
                        <IconBox onClick={editPM}>
                            <Icon />
                        </IconBox>
                    ) : undefined
                }
                actions={actions}
            />
            <MeterTypeSelectionButton
                selectedPM={selectedPM}
                setSelectedPM={setSelectedPM}
                unitPmRecord={unitPmRecord.intervals}
            />
            <ServiceSection
                selectedPM={selectedPM}
                unitPmRecord={unitPmRecord}
            />
            <LineSlider
                value={current}
                bgColor={getColor(selectedPM.status, isArchived)}
                lines={[
                    {
                        bgColor: getColor(selectedPM.status, isArchived),
                        width: currentValue > 100 ? 100 : currentValue,
                        tooltip: isArchived
                            ? null
                            : selectedPM.type === 'TIME'
                            ? `${formatDate(
                                  selectedPM.now,
                                  (dateFormats) => dateFormats.shortenedDate,
                              )} - ${selectedPM.passedPercentTillOverdue}%`
                            : `${tooltipValueFormatter(
                                  selectedPM.now,
                                  selectedPM.type,
                              )} - ${Math.round(currentValue)}%`,
                    },
                ]}
            />
            <BottomSection
                isArchived={isArchived}
                selectedPM={selectedPM}
                pmReminder={unitPmRecord}
            />

            <Divider sx={{ my: '10px' }} />
            <BoxContainer
                justifyContent="space-between"
                m="3px 0px"
            >
                <Spacer>
                    <Typography variant="subtitle2">
                        {unitPmRecord.description ? 'Notes' : 'No Notes Added'}
                    </Typography>
                </Spacer>
                {unitPmRecord.description ? <CollapsibleTogglerAction /> : null}
            </BoxContainer>
            <CollapsibleContent>
                <CollapsibleNotes note={unitPmRecord.description} />
            </CollapsibleContent>
        </CollapsibleInfoCard>
    )
}

export default UnitPMCard

import { CommentOutlined, PhotoOutlined } from '@mui/icons-material'
import { Divider, Link, cardHeaderClasses, styled } from '@mui/material'
import { ListBase, RecordContextProvider, ListControllerResult, useShowContext } from 'react-admin'

import { JobLaborModel, JobModel, JobPartModel, WorkOrderModel } from 'appTypes/models'
import {
    InfoCardHeader,
    SectionTypography,
    GridItemLayout,
    WithListContext,
    ResourceType,
    IconBox,
    CollapsibleInfoCard,
    Spacer,
    Typography,
    BoxContainer,
    CollapsibleIconHolder,
    CollapsibleContent,
    getRecordPhotos,
} from 'components'
import { ActionsMenu, MenuItemActions } from 'components/actions'
import Icons from 'components/icons'
import { formatCK33CodeByLevel } from 'components/inputs/CK33Selector'
import { useWoActionIsDisabled } from 'pages/WorkOrders/hooks'

import WorkOrderJobItemsResource from '../WorkOrderJobItemsResource'
import { EditJobDrawerToggler } from '../config/constants'

import { CardJobPhotos, JobNotes, JobSourceLabel, WorkOrderJobCardItems } from './components'

export type WorkOrderJobCardActions = MenuItemActions<
    JobModel,
    { listContext: ListControllerResult<JobModel> }
>
export type WorkOrderJobCardItemsActions = MenuItemActions<
    JobModel,
    { listContext: ListControllerResult<JobLaborModel | JobPartModel> }
>

export interface WorkOrderJobCardProps {
    job: JobModel
    resource: ResourceType
    jobsListContext: ListControllerResult<JobModel>
    itemActions: WorkOrderJobCardItemsActions
    actions: WorkOrderJobCardActions
}

const JobCardSectionTypography = styled(Typography)`
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 167%;
`
export const jobSourceMap: {
    [key in JobModel['source']]?: string
} = {
    DEFECT: 'DVIR',
    PM_SCHEDULE: 'PM',
}
const WorkOrderJobCard = ({
    job,
    resource,
    jobsListContext,
    itemActions,
    actions,
}: WorkOrderJobCardProps) => {
    const name = job.name
    let component = ''
    const { record: woRecord } = useShowContext<WorkOrderModel>()

    if (job.componentData && job.componentData.code) {
        component =
            formatCK33CodeByLevel(job.componentData.code, job.componentData.level) +
            ' ' +
            job.componentData.text
    }

    const reasonForRepair = job?.reasonForRepairData?.description
        ? job.reasonForRepairData.description
        : '-'
    const inJobsTab = window.location.toString().includes('/jobs')

    const jobPhotosCount = getRecordPhotos(job).count

    const disabled = useWoActionIsDisabled()

    return (
        <GridItemLayout
            key={job.id}
            gridColumns={inJobsTab ? { xs: 12 } : undefined}
        >
            <RecordContextProvider value={job}>
                <WorkOrderJobItemsResource>
                    <CollapsibleInfoCard
                        defaultOpen={inJobsTab}
                        disableActiveState={inJobsTab}
                        sameParent
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            minHeight: '176px',
                            [`& .${cardHeaderClasses.content}`]: {
                                overflow: 'hidden',
                            },
                        }}
                    >
                        <ListBase perPage={200}>
                            <InfoCardHeader
                                action={
                                    <WithListContext>
                                        {(listContext) => (
                                            <Spacer>
                                                {jobSourceMap[job.source] ? (
                                                    <JobSourceLabel
                                                        isSourceDeleted={
                                                            job.source === 'DEFECT' &&
                                                            !job.defectData
                                                        }
                                                    >
                                                        {jobSourceMap[job.source]}
                                                    </JobSourceLabel>
                                                ) : null}
                                                <ActionsMenu
                                                    disabled={disabled}
                                                    record={job}
                                                    actions={itemActions}
                                                    title={
                                                        disabled
                                                            ? 'Reopen the WO to add line items'
                                                            : null
                                                    }
                                                    icon={Icons.Add}
                                                    actionArgs={{ listContext }}
                                                />
                                                {disabled ? (
                                                    <EditJobDrawerToggler
                                                        listContext={jobsListContext}
                                                        job={job}
                                                        resource={resource}
                                                        key="edit"
                                                        children={({ onClick }) => (
                                                            <IconBox onClick={onClick}>
                                                                <Icons.Options />
                                                            </IconBox>
                                                        )}
                                                    />
                                                ) : (
                                                    <ActionsMenu
                                                        record={job}
                                                        actions={actions}
                                                        actionArgs={{
                                                            listContext: jobsListContext,
                                                        }}
                                                        resource={resource}
                                                    />
                                                )}
                                            </Spacer>
                                        )}
                                    </WithListContext>
                                }
                                title={
                                    <>
                                        <SectionTypography
                                            mb="1px"
                                            variant="inherit"
                                        >
                                            <EditJobDrawerToggler
                                                job={job}
                                                resource={resource}
                                                listContext={jobsListContext}
                                            >
                                                {({ onClick }) => (
                                                    <Link
                                                        component="span"
                                                        onClick={onClick}
                                                        sx={{
                                                            whiteSpace: 'nowrap',
                                                            textOverflow: 'ellipsis',
                                                            overflow: 'hidden',
                                                        }}
                                                    >
                                                        {name}
                                                    </Link>
                                                )}
                                            </EditJobDrawerToggler>
                                        </SectionTypography>
                                        <JobCardSectionTypography
                                            variant="caption"
                                            mb="0"
                                        >
                                            {component}
                                        </JobCardSectionTypography>
                                        <JobCardSectionTypography
                                            variant="caption"
                                            mb="12px"
                                        >
                                            {reasonForRepair}
                                        </JobCardSectionTypography>
                                    </>
                                }
                                noDivider
                            />
                            <WorkOrderJobCardItems itemActions={itemActions} />
                        </ListBase>
                        <>
                            <Divider
                                sx={{
                                    margin: '10px 0px',
                                }}
                            />
                            <BoxContainer gap="8px">
                                <CollapsibleIconHolder
                                    name="notes"
                                    title={
                                        job.notes.length
                                            ? `Notes (${job.notes.length})`
                                            : 'No Notes Added'
                                    }
                                    disabled={woRecord.status === 'CLOSED' && !job.notes.length}
                                    titleOnDisabled="No Notes Added"
                                >
                                    <CommentOutlined fontSize="small" />
                                </CollapsibleIconHolder>
                                <CollapsibleIconHolder
                                    name="photos"
                                    disabled={woRecord.status === 'CLOSED' && !jobPhotosCount}
                                    titleOnDisabled="No Photos Added"
                                    title={
                                        jobPhotosCount
                                            ? `Photos (${jobPhotosCount})`
                                            : 'No Photos Added'
                                    }
                                >
                                    <PhotoOutlined fontSize="small" />
                                </CollapsibleIconHolder>
                            </BoxContainer>
                            <CollapsibleContent name="photos">
                                <CardJobPhotos />
                            </CollapsibleContent>
                            <CollapsibleContent name="notes">
                                <JobNotes notes={job.notes} />
                            </CollapsibleContent>
                        </>
                    </CollapsibleInfoCard>
                </WorkOrderJobItemsResource>
            </RecordContextProvider>
        </GridItemLayout>
    )
}

export default WorkOrderJobCard

import { ReactNode } from 'react'

import { BoxContainer } from 'components/styled'

interface CardCollapsibleContentProps {
    content?: {
        iconHolder: ReactNode
        component: ReactNode
    }[]
}
const CardCollapsibleContent = ({ content }: CardCollapsibleContentProps) => {
    return (
        <>
            <BoxContainer gap="8px">
                {content.map((item) => {
                    return item.iconHolder
                })}
            </BoxContainer>
            {content.map((item) => {
                return item.component
            })}
        </>
    )
}
export default CardCollapsibleContent

import { Box, styled } from '@mui/material'

const ListItem = styled(Box)`
    justify-content: space-between;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    & > * {
        margin-top: 0px;
        margin-bottom: 0px;
    }
`
export default ListItem

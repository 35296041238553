import { Routes } from 'react-router-dom'

import { tabRoutes } from 'components'
import { Show } from 'components/resource'

import UnitDVIR from '../DVIR/UnitDVIR'
import UnitMeters from '../Meters'
import UnitNotes from '../Notes/UnitNotes'
import UnitOverview from '../Overview'
import UnitPM from '../PM'

import { UnitShowHeader } from './components'

const UnitShow = () => {
    const { tabs, routes } = tabRoutes([
        {
            value: '',
            label: 'Overview',
            element: <UnitOverview />,
        },
        {
            value: 'pm',
            label: 'PM',
            element: <UnitPM />,
        },
        {
            value: 'dvir',
            label: 'DVIR',
            element: <UnitDVIR />,
        },
        {
            value: 'meters',
            label: 'Meters',
            element: <UnitMeters />,
        },
        {
            value: 'notes',
            label: 'Notes',
            element: <UnitNotes />,
        },
    ])
    return (
        <Show>
            <>
                <UnitShowHeader tabs={tabs} />
                <Routes>{routes}</Routes>
            </>
        </Show>
    )
}

export default UnitShow

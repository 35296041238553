import { DetailedHTMLProps, HTMLAttributes, forwardRef, createContext, useContext } from 'react'

import useCollapseWidget from '../WidgetBase/useCollapseWidget'

interface GridLayoutItemProps
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    id: string
}

export const WidgetContext = createContext(null)

export const useWidgetContext = () => useContext(WidgetContext)

const GridLayoutItem = forwardRef((props: GridLayoutItemProps, ref: any) => {
    const dimensions = useCollapseWidget(ref)

    return (
        <WidgetContext.Provider value={dimensions}>
            <div
                {...props}
                ref={ref}
                key={props.id}
            />
        </WidgetContext.Provider>
    )
})
export default GridLayoutItem

import { createContext, FC, ReactNode, useContext } from 'react'

export const InDrawerContext = createContext<boolean>(true)

interface InDrawerContextProviderProps {
    value?: boolean
    children: ReactNode
}

export const InDrawerContextProvider: FC<InDrawerContextProviderProps> = ({
    value = true,
    children,
}) => {
    return <InDrawerContext.Provider value={value}>{children}</InDrawerContext.Provider>
}

export const useInDrawerContext = () => useContext(InDrawerContext)

import { ReactNode, memo, ReactElement } from 'react'

import { styled } from '@mui/material'

import { Portal } from 'components'

const AdditionalFixedParts = memo(
    styled('div')`
        display: flex;
        flex-direction: column;
    `,
    () => true,
)

const FixedTopPart = styled(
    ({ children, className }: { children: ReactNode; className?: string }) => {
        return (
            <div className={className}>
                {children}

                <AdditionalFixedParts id="additional-fixed-parts" />
            </div>
        )
    },
)`
    margin-left: auto;
    position: sticky;
    top: 0;
    right: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    ${({ theme }) => theme.breakpoints.up('sm')} {
        width: calc(100% - ${({ theme }) => theme.props.drawerWidth}px);
    }
`

export default FixedTopPart

interface FixedPortalProps {
    order?: number
    children: ReactElement
}

export const FixedTopPortal = ({ children, order }: FixedPortalProps) => {
    return (
        <Portal
            containerId="additional-fixed-parts"
            mutateElement={(el) => {
                el.style.order = (order || 1) as unknown as string
            }}
        >
            {children}
        </Portal>
    )
}

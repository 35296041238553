import { FilterNone } from '@mui/icons-material'
import { Identifier, useListContext } from 'react-admin'

import { ActionChildren, ListActionExtendedOption } from './Actions'

interface MultiselectActionProps {
    id: Identifier
    children: ActionChildren<{}, ListActionExtendedOption>
    disabled?: boolean
}

export const MultiselectAction = ({ children, id, disabled }: MultiselectActionProps) => {
    const listContext = useListContext()

    const isMultiselectMode = Boolean(listContext.selectedIds.length)

    return children(
        {
            disabled,
            title: 'Multiselect',
            Icon: FilterNone,
            onClick: () => {
                listContext.onToggleItem(id)
            },
        },
        {
            isMultiselectMode,
            listContext,
        },
    )
}

export const multiselectAction = (params: MultiselectActionProps) => (
    <MultiselectAction
        {...params}
        key="multiselect"
    />
)

import { GoogleOAuthProvider } from '@react-oauth/google'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import App from 'App'
import config from 'configs/config'
import reportWebVitals from 'sys/reportWebVitals'

import * as serviceWorkerRegistration from './serviceWorkerRegistration'

const app = document.getElementById('root')
const root = createRoot(app!)

const renderApp = async () => {
    const LDProvider = await asyncWithLDProvider({
        clientSideID: config.LAUNCH_DARKLY_CLIENT_ID,
        user: { anonymous: true },
    })

    root.render(
        <GoogleOAuthProvider clientId={config.GOOGLE_CLIENT_ID}>
            <BrowserRouter>
                <LDProvider>
                    <App />
                </LDProvider>
            </BrowserRouter>
        </GoogleOAuthProvider>,
    )
}

renderApp()

serviceWorkerRegistration.register()

console.info('Application version: ', config.VERSION)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined'
import { Link, LinkProps } from 'react-router-dom'

import { IconBox } from 'components'

const ArchivedAction = ({ to }: { to: LinkProps['to'] }) => {
    return (
        <Link to={to}>
            <IconBox title="See All">
                <GridViewOutlinedIcon />
            </IconBox>
        </Link>
    )
}

export default ArchivedAction

import { useState, FC, useEffect, ReactNode } from 'react'

import { CircularProgress } from '@mui/material'
import { captureMessage } from '@sentry/browser'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { inject, observer } from 'mobx-react'
import { useLocation } from 'react-router-dom'

import api from 'api'
import { Toast, BoxContainer, Typography } from 'components'
import { withErrorBoundary } from 'components/ErrorBoundary'
import { useNotify } from 'hooks'
import { AuthStore } from 'providers/authStore'

const updateVersion = () => {
    navigator.serviceWorker?.getRegistrations().then((registrations) => {
        try {
            for (const reg of registrations) {
                reg.update()
            }
        } catch (error) {
            try {
                captureMessage('SW update error custom', {
                    extra: {
                        handledError: error,
                    },
                })
            } catch {
                //
            }
            if (error.name !== 'InvalidStateError') {
                throw error
            }
        }
    })
}

const AppErrorFallback = () => {
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        updateVersion()
        const timer = setTimeout(() => {
            setLoading(false)
        }, 10000)

        return () => {
            clearTimeout(timer)
        }
    }, [])

    if (!loading) {
        return <Typography p="5px">Something went wrong!</Typography>
    }

    return <FullPageLoading />
}

const FullPageLoading = () => {
    return (
        <BoxContainer
            sx={{
                height: '100vh',
                justifyContent: 'center',
            }}
        >
            <CircularProgress
                size={100}
                variant="indeterminate"
                disableShrink
            />
        </BoxContainer>
    )
}

const ErrorHandling: FC<{ children: ReactNode; auth?: AuthStore }> = inject('auth')(
    observer(({ children, auth }) => {
        const ldClient = useLDClient()
        const notify = useNotify()
        const { pathname } = useLocation()

        useEffect(() => {
            auth.onInit(async (user) => {
                if (!ldClient) {
                    return
                }
                if (!user) {
                    await ldClient.identify({ anonymous: true })
                    return
                }
                await ldClient.identify({
                    key: user.id,
                    name: user.name,
                    email: user.email,
                })
            })

            api.setServerErrorHandler(() =>
                notify(
                    'There has been a technical problem and our team has been notified about it.' +
                        'Please try again later!',
                    {
                        type: 'error',
                    },
                ),
            )
            api.setNetworkErrorHandler(() =>
                notify('There is a network problem. Please, try again later!', {
                    type: 'error',
                }),
            )
            api.setErrorCallback(
                '405',
                (error) => error.status === 405,
                () => {
                    notify('Action Not Allowed!', {
                        type: 'error',
                    })
                },
            )
            api.setErrorCallback(
                '415',
                (error) => error.status === 415,
                () => {
                    notify('Unsupported Media Type!', {
                        type: 'error',
                    })
                },
            )
            api.setErrorCallback(
                '429',
                (error) => error.status === 429,
                () => {
                    notify('Too Many Requests!', {
                        type: 'error',
                    })
                },
            )
            auth.init()

            return () => {
                api.setServerErrorHandler(null)
                api.setNetworkErrorHandler(null)
            }
        }, [])

        useEffect(() => {
            updateVersion()
        }, [pathname])

        if (!auth.initialized) {
            return <FullPageLoading />
        }

        return (
            <>
                {children}
                <Toast />
            </>
        )
    }),
)

export default withErrorBoundary(ErrorHandling, { fallback: <AppErrorFallback /> })

import { styled } from '@mui/material/styles'

import Status from 'components/styled/Status'
import { ThemeColorType } from 'utils'
interface DataCardSubheaderProps {
    value: string | React.ReactNode
    withStatus?: boolean
    statusColor?: ThemeColorType
}
const Badge = styled(Status)`
    display: inline-block;
    margin-right: 6px;
`

const DataCardSubheader = ({ value, withStatus, statusColor }: DataCardSubheaderProps) => {
    return (
        <>
            {withStatus ? (
                <>
                    <Badge
                        iconColor={statusColor}
                        size="8px"
                    />
                    {value}
                </>
            ) : (
                value
            )}
        </>
    )
}
export default DataCardSubheader

import { basePermissions } from 'configs/constants'
import { partsResource } from 'pages/Parts/config/constants'
import { unitResource } from 'pages/Units/config/constants'
import { woPermissions, woResource } from 'pages/WorkOrders/config/constants'

import { companyName } from '../CompanyInfo/config/constants'

type TranslatorType = {
    id: string
    label: string
    permissions: {
        [key: string]: string
    }
}

const { retrieve, create, update, destroy, archive, pdf } = basePermissions

const permissionsTranslator: TranslatorType[] = [
    {
        id: companyName,
        label: 'Company',
        permissions: {
            [retrieve]: 'View',
            [update]: 'Edit',
            [destroy]: 'Deactivate',
        },
    },
    {
        id: 'members',
        label: 'Members',
        permissions: {
            [retrieve]: 'View Members',
            [create]: 'Invite Members',
            [update]: 'Edit Member Role',
            [destroy]: 'Delete Members',
        },
    },
    {
        id: 'subscription',
        label: 'Billing',
        permissions: {
            [retrieve]: 'View Subscription',
            [create]: 'Create Subscription',
        },
    },
    {
        id: woResource.name,
        label: 'Work Orders',
        permissions: {
            [create]: 'Create',
            [retrieve]: 'View',
            [update]: 'Edit',
            [destroy]: 'Delete',
            [pdf]: 'Export as PDF',
        },
    },
    {
        id: woResource.name,
        label: 'Work Order Status',
        permissions: {
            [woPermissions.close]: 'Open to Closed',
            [woPermissions.cancel]: 'Open to Canceled',
            [woPermissions.reopen]: 'Closed to Open',
        },
    },
    {
        id: unitResource.name,
        label: 'Units',
        permissions: {
            [create]: 'Create',
            [retrieve]: 'View',
            [update]: 'Edit',
            [archive]: 'Archive',
            [destroy]: 'Delete',
        },
    },
    {
        id: partsResource.name,
        label: 'Parts',
        permissions: {
            [create]: 'Create',
            [retrieve]: 'View',
            [update]: 'Edit',
            [destroy]: 'Delete',
        },
    },
]

export default permissionsTranslator

import { CalendarTodayOutlined, CommentOutlined } from '@mui/icons-material'
import { SvgIcon, Typography, alpha, styled, BoxProps, Box, Divider, Chip } from '@mui/material'

import {
    BoxContainer,
    CardCollapsibleScrollbar,
    CollapsibleContent,
    CollapsibleIconHolder,
    Columns,
    EllipsisOverflow,
} from 'components'
import { jobSourceMap } from 'pages/WorkOrders/Jobs/WorkOrderJobCard'
import { JobSourceLabel } from 'pages/WorkOrders/Jobs/WorkOrderJobCard/components'
import { formatDate } from 'utils'

export type NoteType = {
    created?: string
    text: string
    createdByData: {
        name?: string
        email?: string
    }
    source: 'DEFECT' | 'MANUAL' | 'PM_SCHEDULE'
}
interface NoteProps extends BoxProps, NoteType {}
const Note = styled(({ text, created, createdByData, source, ...props }: NoteProps) => {
    const authorName = createdByData?.name || createdByData?.email
    return (
        <Columns
            gap="4px"
            {...props}
        >
            <Box
                display="flex"
                gap="10px"
                alignItems="flex-start"
            >
                <Box
                    flexGrow={1}
                    display="flex"
                    flexDirection="column"
                    gap="4px"
                    overflow="hidden"
                >
                    {authorName && (
                        <Typography
                            variant="chartTitle"
                            justifyContent="space-between"
                            lineHeight="120%"
                            component={EllipsisOverflow}
                        >
                            {authorName}
                        </Typography>
                    )}
                    {created ? (
                        <Typography
                            variant="chartLabel"
                            component={BoxContainer}
                            gap="13ox"
                            color="text.secondary"
                        >
                            <SvgIcon
                                component={CalendarTodayOutlined}
                                sx={{
                                    width: '10px',
                                    height: '10px',
                                    marginRight: '4px',
                                }}
                            />
                            {formatDate(created, (dateFormats) => dateFormats.fullDateTime)}
                        </Typography>
                    ) : null}
                </Box>
                {jobSourceMap[source] ? (
                    <JobSourceLabel
                        ml="auto"
                        disableIcon
                    >
                        {jobSourceMap[source]}
                    </JobSourceLabel>
                ) : null}
            </Box>
            <Typography
                variant="chartsBody"
                sx={{
                    wordBreak: 'break-word',
                }}
            >
                {text}
            </Typography>
        </Columns>
    )
})`
    padding: 12px;
    gap: 4px;
    border-radius: 8px;
    background: ${({ theme }) => alpha(theme.palette.text.primary, 0.06)};
    &:nth-last-of-type(even) {
        background: ${({ theme }) => alpha(theme.palette.info.main, 0.12)};
    }
`
interface NotesProps extends BoxProps {
    notes: NoteType[]
}
export const Notes = ({ notes, ...rest }: NotesProps) => {
    return (
        <Columns
            gap="10px"
            {...rest}
        >
            {notes.map((obj, i) => (
                <Note
                    {...obj}
                    key={i}
                />
            ))}
        </Columns>
    )
}
export const NotesCollapsibleContent = ({ notes, ...rest }: NotesProps) => {
    return (
        <CollapsibleContent name="notes">
            <Columns mt="15px">
                <Typography
                    gap="8px"
                    component={BoxContainer}
                    variant="chartTitle"
                    mb="10px"
                >
                    Notes
                    <Chip
                        component="span"
                        label={notes.length}
                        sx={(theme) => ({
                            bgcolor: alpha(theme.palette.text.primary, 0.08),
                            fontSize: '12px',
                        })}
                        variant="filled"
                        size="extraSmall"
                    />
                </Typography>
                <Divider />
                <CardCollapsibleScrollbar
                    sx={{
                        maxHeight: '119px',
                    }}
                >
                    <Notes notes={notes} />
                </CardCollapsibleScrollbar>
            </Columns>
        </CollapsibleContent>
    )
}
interface NotesCollapsibleContentProps {
    notes: NoteType[]
}
export const NotesCollapsibleIconHolder = ({ notes }: NotesCollapsibleContentProps) => {
    return (
        <CollapsibleIconHolder
            name="notes"
            title={`Notes (${notes.length})`}
            disabled={!notes.length}
            titleOnDisabled="No Notes Added"
        >
            <CommentOutlined fontSize="small" />
        </CollapsibleIconHolder>
    )
}

export default Note

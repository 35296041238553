import { SellOutlined } from '@mui/icons-material'
import { Divider, alpha, Chip } from '@mui/material'

import { TagModel } from 'appTypes/models'
import {
    BoxContainer,
    CardCollapsibleScrollbar,
    CollapsibleContent,
    CollapsibleIconHolder,
    Columns,
    Typography,
} from 'components'

import TagField from './TagField'

interface TagsCollapsibleContentProps {
    tags: TagModel[]
}
export const TagsCollapsibleIconHolder = ({ tags }: TagsCollapsibleContentProps) => {
    return (
        <CollapsibleIconHolder
            name="tags"
            title={`Tags (${tags.length})`}
            disabled={!tags.length}
            titleOnDisabled="No Tags Added"
        >
            <SellOutlined fontSize="small" />
        </CollapsibleIconHolder>
    )
}
const TagsCollapsibleContent = ({ tags }: TagsCollapsibleContentProps) => {
    return (
        <CollapsibleContent name="tags">
            <Columns mt="15px">
                <Typography
                    gap="8px"
                    mb="10px"
                    component={BoxContainer}
                    variant="chartTitle"
                >
                    Tags
                    <Chip
                        component="span"
                        label={tags.length}
                        sx={(theme) => ({
                            bgcolor: alpha(theme.palette.text.primary, 0.08),
                            fontSize: '12px',
                        })}
                        variant="filled"
                        size="extraSmall"
                    />
                </Typography>
                <Divider />
                <CardCollapsibleScrollbar
                    sx={{
                        maxHeight: '119px',
                    }}
                >
                    <BoxContainer
                        gap="4px"
                        flexWrap="wrap"
                    >
                        {tags.map((tag) => (
                            <TagField
                                tag={tag}
                                key={tag.id}
                            />
                        ))}
                    </BoxContainer>
                </CardCollapsibleScrollbar>
            </Columns>
        </CollapsibleContent>
    )
}
export default TagsCollapsibleContent

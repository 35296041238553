import noJobsIcon from 'assets/images/no-jobs.svg'
import { NoResultsCard, Typography } from 'components'

import { CreateJobDrawerToggler } from './config/constants'

const WorkOrderJobsEmpty = () => {
    return (
        <NoResultsCard
            title="No Jobs"
            direction="row"
            height="160px"
            heightsm="280px"
            directionsm="column"
            imageSrc={noJobsIcon}
            imageProps={{
                alt: 'No Jobs',
                width: {
                    xs: '122px',
                    sm: '107px',
                },
                mr: {
                    xs: 0,
                    sm: '32px',
                },
            }}
            action={
                <CreateJobDrawerToggler>
                    {({ onClick }) => (
                        <Typography
                            variant="body1"
                            color="primary"
                            sx={{ cursor: 'pointer' }}
                            onClick={onClick}
                        >
                            Create Job
                        </Typography>
                    )}
                </CreateJobDrawerToggler>
            }
        />
    )
}

export default WorkOrderJobsEmpty

import type BaseModel from './BaseModel'

interface CK33Model extends BaseModel {
    code: string

    level: number

    parent: number

    text: string

    favorite: boolean
}

export default CK33Model

export enum CK33Levels {
    GROUP = 0,
    SYSTEM = 1,
    ASSEMBLY = 2,
    COMPONENT = 3,
}

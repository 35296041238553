import { FC } from 'react'

import { useTheme } from '@mui/material'

import { ReactComponent as Top3ReasonsIcon } from 'assets/images/top3-reasons.svg'
import { formatPercent, resolveDoublePriceMask } from 'utils'

import { ExtendPieData, WidgetPieChartBase } from '../../components'
import { JobItemsCalculations } from '../../components/types'
import { listType, statusColors } from '../types'

interface PieChartProps {
    list: listType
    total: JobItemsCalculations['total']
    validData: boolean
}

const PieChart: FC<PieChartProps> = ({ list, total, validData }) => {
    const { palette } = useTheme()

    const listData = list.data.map((obj, index) => {
        return {
            id: `${obj.description} ${formatPercent(obj.totalPercent)}`,
            subTitle: obj.type,
            value: obj.total,
            color: palette.charts[statusColors[index]],
            total,
        }
    })

    return (
        <WidgetPieChartBase<ExtendPieData<{ subTitle?: string }>>
            valid={validData}
            icon={Top3ReasonsIcon}
            sortByValue={false}
            renderContent={({ id, subTitle, value }) => [
                id,
                subTitle,
                resolveDoublePriceMask(value),
            ]}
            data={[
                ...listData,
                {
                    id: `Other ${formatPercent(list.sumPercentageOfExcludedReasons)}`,
                    value: list.sumOfExcludedReasons,
                    color: palette.charts.disable,
                    total,
                },
            ]}
        />
    )
}
export default PieChart

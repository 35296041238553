import { PaletteMode } from '@mui/material'
import { createTheme, Components, Breakpoint } from '@mui/material/styles'
import { LicenseInfo } from '@mui/x-license-pro'
import merge from 'lodash/merge'

import { MUIX_KEY } from 'configs/config'

import { componentStyleOverrides } from './compStyleOverride'
import darkPalette from './darkPallete'
import lightPalette, { IPalette } from './palette'
import themeTypography, { ITypography } from './typography'

LicenseInfo.setLicenseKey(MUIX_KEY)

declare module '@mui/material/styles' {
    interface Palette extends IPalette {}
    interface PaletteOptions extends IPalette {}

    interface PaletteColor extends Partial<IPalette> {}
    interface SimplePaletteColorOptions extends Partial<IPalette> {}
    interface Theme {
        props: typeof extraProps

        palette: IPalette

        typography: ITypography
    }

    interface ThemeOptions extends Partial<Theme> {}
}

const extraProps = {
    drawerWidth: 64,
    headerHeight: 56,
    sidebarPaddingX: 8,
    mobileViewBreakpoint: 'sm' as Breakpoint,
}

export interface ThemeOptionsConfig {
    palette: IPalette
}

export const ThemeBreakpoints: { [key in Breakpoint]: number } = {
    xs: 0,
    sm: 720,
    md: 1024,
    lg: 1440,
    xl: 2048,
}

function theme(palette: IPalette) {
    const themeOption: ThemeOptionsConfig = {
        palette,
    }

    const typography = themeTypography(themeOption)

    const rtheme = createTheme({
        direction: 'ltr',
        palette,
        typography,
        props: extraProps,
        spacing: 4,
        shape: {
            borderRadius: 4,
        },
        components: componentStyleOverrides(themeOption) as Components,
        breakpoints: {
            values: ThemeBreakpoints,
        },
    })

    return rtheme
}

const lightTheme = theme(lightPalette)
const darkTheme = theme(merge(darkPalette, lightPalette))

const getTheme = (mode: PaletteMode) => {
    return mode === 'dark' ? darkTheme : lightTheme
}

export default getTheme

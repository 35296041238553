import { FC } from 'react'

import { PollOutlined } from '@mui/icons-material'
import { useTheme } from '@mui/material'

import { resolveDoublePriceMask } from 'utils'

import { WidgetPieChartBase, calculateWidgetValue } from '../../components'
import { ratioDataType, listType, statusColors } from '../types'

interface CostPerCategoryChartProps {
    list: listType
    total: ratioDataType['total']
    validData: boolean
}
const CostPerCategoryChart: FC<CostPerCategoryChartProps> = ({ list, total, validData }) => {
    const { palette } = useTheme()
    const listData = list.data.map((obj, index) => {
        return {
            id: `${obj.code.substring(0, 3)} ${obj.description}`,
            value: obj.total,
            color: palette.charts[statusColors[index]],
            total,
        }
    })

    return (
        <WidgetPieChartBase
            valid={validData}
            icon={PollOutlined}
            sortByValue={false}
            renderContent={({ id, value, total }) => [
                id,
                calculateWidgetValue(total, value),
                resolveDoublePriceMask(value),
            ]}
            data={[
                ...listData,
                {
                    id: 'Other',
                    value: list.sumOfExcludedCategories,
                    color: palette.charts.disable,
                    total,
                },
            ]}
        />
    )
}
export default CostPerCategoryChart

import { Link } from '@mui/material'

import noMetersIcon from 'assets/images/no-meters.svg'
import { NoResultsCard, useArchivedContext } from 'components'

import useUnitPMDrawerEditor from './PMEditor/useUnitPMDrawerEditor'

const UnitPMEmpty = ({ type }: { type: 'dependent' | 'independent' }) => {
    const open = useUnitPMDrawerEditor()
    const isArchived = useArchivedContext()

    return (
        <NoResultsCard
            direction="row"
            height="160px"
            title="No PM"
            imageSrc={noMetersIcon}
            imageProps={{
                width: '107px',
                height: '107px',
                mr: '32px',
            }}
            action={
                !isArchived && (
                    <Link
                        variant="body1"
                        onClick={() => open({ type })}
                    >
                        Create Interval
                    </Link>
                )
            }
        />
    )
}

export default UnitPMEmpty

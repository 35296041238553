import { ReactNode } from 'react'

import { Box, styled } from '@mui/material'
import clsx from 'clsx'

import headerClasses from './classes'

interface HeaderAsideProps {
    children: ReactNode
    fixed?: boolean
    className?: string
}

const HeaderAside = styled(({ children, fixed, className }: HeaderAsideProps) => {
    return <Box className={clsx([fixed && headerClasses.fixed, className])}>{children}</Box>
})<HeaderAsideProps>(({ theme, fixed }) => [
    fixed && {
        [`${theme.breakpoints.down('sm')}`]: {
            position: 'fixed',
            bottom: '19px',
            right: '19px',
            zIndex: '4',
        },
    },
    {
        gridArea: 'aside-left',
        [`.${headerClasses.main} + &`]: {
            gridArea: 'aside-right',
        },
    },
])

export default HeaderAside

import { reportUrl } from 'pages/Reports'
import { createReportResource } from 'pages/Units/config/constants'
import { resolveDoublePriceMask } from 'utils'

import { extractWidgetTop3 } from '../../WidgetBase'
import WidgetBase, { WidgetSkeleton } from '../../WidgetBase/WidgetBase'
import { useCreateDashboardWidgetLink, useDashboardWidget } from '../../WidgetsForm'
import { includeUncategorized } from '../CostPerCategoryWidget'
import { CostPerCategoryClarification } from '../CostPerCategoryWidget/components'
import { unitGetRoundedPercents } from '../CostPerUnitWidget'

import { CostPerEquipmentChart, CostPerEquipmentList } from './components'
import { listType, maxTotalEquipmentsType, QueryResponse, ResponseType } from './types'

const description = `Maintenance costs for the reporting period are broken down by the Unit's equipment 
category VMRS classification, and the top 3 are listed in descending order.`

let isValid = false

const CostPerEquipmentWidget = () => {
    const createLink = useCreateDashboardWidgetLink()
    const data = useDashboardWidget<QueryResponse>({
        resource: createReportResource('cost-per-equipment-category'),
        sideEffect: (data) => {
            const finalData = unitGetRoundedPercents(data)
            const { maxTotalEquipmentCategories, ...ratioData } = finalData as ResponseType

            const initialArray = includeUncategorized<maxTotalEquipmentsType>(
                ratioData,
                maxTotalEquipmentCategories,
            )

            const listExtras = extractWidgetTop3(initialArray, true)
            const uncategorizedTotal = listExtras.uncategorized ? null : ratioData.uncategorized

            isValid = typeof ratioData.total === 'number'
            const sumOfExcludedEquipment = uncategorizedTotal
                ? finalData.total - listExtras.top3Total - uncategorizedTotal
                : finalData.total - listExtras.top3Total

            let chartTotal = listExtras.top3Total
            chartTotal =
                sumOfExcludedEquipment > 0 ? chartTotal + sumOfExcludedEquipment : chartTotal
            chartTotal = uncategorizedTotal > 0 ? chartTotal + uncategorizedTotal : chartTotal
            const list: listType = {
                data: initialArray,
                ...listExtras,
                sumOfExcludedEquipment,
                uncategorizedTotal,
                chartTotal,
            }

            return {
                ...ratioData,
                list,
            }
        },
    })
    if (!data) {
        return <WidgetSkeleton />
    }
    const { list, ...ratioData } = data

    return (
        <WidgetBase
            headerProps={{
                title: 'Cost per Equipment Categories',
                label: isValid ? resolveDoublePriceMask(ratioData.total) : null,
                tooltipText: description,
                clarification: (
                    <CostPerCategoryClarification>
                        including Uncategorized Equipment
                    </CostPerCategoryClarification>
                ),
                chart: (
                    <CostPerEquipmentChart
                        validData={isValid && ratioData.total > 0}
                        list={list}
                        total={list.chartTotal}
                    />
                ),
            }}
            infoProps={{
                title: 'Most Expensive',
                content: (
                    <CostPerEquipmentList
                        list={list}
                        description={description}
                    />
                ),
            }}
            link={createLink(reportUrl(reportUrl.urls.costPerEquipmentCategory))}
        />
    )
}
export default CostPerEquipmentWidget

import { ListBase } from 'react-admin'

import { PageContent } from 'components'

import { VendorContactsList, VendorContactResource } from './components'

const VendorContacts = () => {
    return (
        <PageContent>
            <VendorContactResource>
                <ListBase perPage={100}>
                    <VendorContactsList />
                </ListBase>
            </VendorContactResource>
        </PageContent>
    )
}

export default VendorContacts

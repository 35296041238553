import { RaRecord } from 'react-admin'
type Tuple<L extends number, E = unknown, T extends E[] = []> = T extends { length: L }
    ? T
    : Tuple<L, E, [...T, E]>

type IndexesOf<T extends unknown[]> = { [K in keyof T]: K }[number]

type RepeatKeys<S extends string, N extends number, T> = {
    [K in IndexesOf<Tuple<N>> | N as `${S}${K}`]?: T
}

export type Photos = RepeatKeys<'photo', 9, string>
export const getRecordPhotos = (
    record: RaRecord,
    formValues?: any,
): {
    files: Photos
    count: number
} => {
    const photos = record || formValues
    const files = {}
    let count = 0
    for (let i = 0; i <= 9; i += 1) {
        if (photos && photos['photo' + i]) {
            count += 1
        }
        files['photo' + i] = photos ? photos['photo' + i] : null
    }
    return { files, count }
}
export const photosToArray = (photos: Photos) =>
    Object.keys(photos).map((key) => ({
        id: key,
        file: photos[key],
    }))

import { FC } from 'react'

import { Box } from '@mui/material'

import { BoxContainer, EllipsisOverflow, Status, Typography } from 'components'
import { WidgetListContainer, WidgetListFTU } from 'pages/Dashboard/components/WidgetBase/styled'
import LineItemsTooltip from 'pages/WorkOrders/Jobs/WorkOrderJobCard/components/LineItemsTooltip'
import { resolveDoublePriceMask } from 'utils'

import { ListItem } from '../../components'
import { listType, statusColors } from '../types'

interface CostPerEquipmentListProps {
    list: listType
    description: string
}
const CostPerEquipmentList: FC<CostPerEquipmentListProps> = ({ list, description }) => {
    const { data } = list

    const isValid = data.length > 0 ? data.some((obj) => typeof obj.total === 'number') : false

    if (!isValid) {
        return <WidgetListFTU description={description} />
    }

    const List = data.flatMap(({ id, description, total, code, ...ratioData }, i) => {
        if (typeof total !== 'number') {
            return []
        }
        // TODO: Unify top3 lists

        return (
            <Typography
                component={ListItem}
                variant="chartListItem"
                key={`${description}-${i}`}
            >
                <BoxContainer
                    sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        pr: '10px',
                        textOverflow: 'ellipsis',
                    }}
                >
                    <Box marginRight="13px">
                        <Status
                            size="8px"
                            iconColor={(theme) => theme.palette.charts[statusColors[i]]}
                        />
                    </Box>
                    <EllipsisOverflow
                        as="span"
                        sx={{ lineHeight: '150%' }}
                    >
                        {description}
                    </EllipsisOverflow>
                </BoxContainer>

                <LineItemsTooltip
                    data={{ ...ratioData, id }}
                    allWhite
                >
                    {resolveDoublePriceMask(total)}
                </LineItemsTooltip>
            </Typography>
        )
    })
    return <WidgetListContainer>{List}</WidgetListContainer>
}
export default CostPerEquipmentList

import { ReactNode, createContext } from 'react'

export interface ArrayControllerContextProviderPropsBase<ValueType = any> {
    initialArray?: ValueType[] | (() => ValueType[])
    children: ReactNode
    limit?: number
    onAppend?: (key: ValueType) => void
    onRemove?: (key: ValueType) => void
}

export interface ArrayControllerResult<ValueType = any> {
    array: ValueType[]
    append: (key?: ValueType) => ValueType
    remove: (params: { item: ValueType; index: number }) => void
    setArray: React.Dispatch<React.SetStateAction<ValueType[]>>
    limit: number
}

export const ArrayControllerContext = createContext<ArrayControllerResult>(null as any)

export const ArrayControllerContextProviderBase = <ValueType = any,>({
    children,
    value,
}: {
    children: ReactNode
    value: ArrayControllerResult<ValueType>
}) => {
    return (
        <ArrayControllerContext.Provider value={value}>{children}</ArrayControllerContext.Provider>
    )
}

export type ArrayControllerBasevalueType = number

import { FC, useEffect } from 'react'

import { useRecordContext } from 'react-admin'
import { useFormContext } from 'react-hook-form'

import { WorkOrderModel } from 'appTypes/models'
import { useUtilityDrawerContext } from 'components/Drawer/UtilityDrawer'
import { JobForm } from 'pages/WorkOrders/Jobs/components'
import { VendorWorkOrderForm, WorkOrderForm } from 'pages/WorkOrders/components'

import UseWOStepper from './UseWOStepper'
import { useStepsContext } from './UseWOStepsContext'
import { UseWOExtraState } from './useAttachWO'

const UseWOFormContent = () => {
    const { step } = useStepsContext()
    const { extra } = useUtilityDrawerContext()
    const extraState: UseWOExtraState = extra || {}
    const { isVendor, options } = extraState
    const isFromDvir = extraState?.parentRecord?.dvirs

    return (
        <>
            <UseWOStepper />
            {step === 1 ? (
                isVendor ? (
                    <>
                        <VendorWorkOrderForm canEditVendor />
                        <UpdateVendorInput />
                    </>
                ) : (
                    <WorkOrderForm />
                )
            ) : (
                <JobForm
                    desabledFields={options?.jobDisabledFields}
                    reasonForRepairDefaultValue={isFromDvir ? '04' : undefined}
                />
            )}
        </>
    )
}

export default UseWOFormContent

const UpdateVendorInput: FC = () => {
    const record = useRecordContext<WorkOrderModel>()
    const { setValue } = useFormContext()

    useEffect(() => {
        if (record?.purchaseOrderData?.vendorData?.id) {
            setTimeout(() => {
                setValue('vendor', record.purchaseOrderData.vendorData.id, {
                    shouldValidate: true,
                })
            }, 100)
        }
    }, [record])

    return null
}

import { useListContext } from 'react-admin'

import api from 'api/api'
import { useOpenUtilityDrawer, useResource } from 'components'
import UtilityDrawerForm from 'components/Drawer/UtilityDrawerForm'
import { useSubmit } from 'hooks'

import PMRepositionForm from './PMRepositionForm'

const usePMRepositionDrawer = () => {
    const open = useOpenUtilityDrawer()
    const listContext = useListContext()
    const repositionPath = listContext.resource + '/reorder_dependent'
    const resource = useResource()

    const handleSubmit = useSubmit(
        async (formData) => {
            const { parentFirstOrder } = formData
            await api.post(repositionPath, { parentFirstOrder })
            listContext!.refetch()
        },
        {
            successMessage: ({ defaultMessages }) => defaultMessages.updated,
        },
    )

    return () => {
        open({
            drawerArgs: {
                title: 'Reposition',
                renderContent: () => <PMRepositionForm />,
                renderWrapper: (props) => (
                    <UtilityDrawerForm
                        {...props}
                        submittableAtBeginning
                        onSubmit={handleSubmit}
                    />
                ),
            },
            extraArgs: {
                type: 'edit',
                listContext,
                resource,
            },
        })
    }
}

export default usePMRepositionDrawer

import React, {
    forwardRef,
    useRef,
    createContext,
    useContext,
    useLayoutEffect,
    useState,
    ComponentProps,
} from 'react'

import { ErrorBoundary } from 'components'
import { useDebounce } from 'hooks'

import { useWidgetContext } from '../GridLayout'

import { WidgetHeader, WidgetHeaderProps, WidgetHeaderSkeleton } from './WidgetHeader'
import { WidgetInfoProps, WidgetInfo, WidgetInfoSkeleton } from './WidgetInfo'
import {
    WidgetBaseContainer,
    WidgetBaseDragIndicator,
    WidgetBaseLink,
    WidgetBaseResizeIndicator,
} from './styled'
import { checkWidgetDimensions } from './useCollapseWidget'
// TODO: Rename props

const WidgetTooltipContext = createContext<number>(undefined)

export const useWidgetTooltipContext = () => useContext(WidgetTooltipContext)

const useCalculateWidgetWidth = (ref: React.MutableRefObject<HTMLDivElement>) => {
    const [tooltipWidth, setTooltipWidth] = useState<number>(undefined)
    const handleChangeWidth = useDebounce((v) => setTooltipWidth(v), { time: 1000 })

    useLayoutEffect(() => {
        const observer = new ResizeObserver((entries) => {
            handleChangeWidth(entries[0].contentRect.width - 145)
        })
        observer.observe(ref.current)
        return () => {
            observer.disconnect()
        }
    }, [ref.current])
    return tooltipWidth
}
export interface WidgetBaseProps extends ComponentProps<typeof WidgetBaseContainer> {
    headerProps: WidgetHeaderProps
    infoProps?: WidgetInfoProps
    link?: string
}
export interface WidgetSkeletonProps
    extends Omit<WidgetBaseProps, 'headerProps' | 'infoProps' | 'link'> {
    disableInfo?: boolean
}
export const WidgetSkeleton = ({ disableInfo, ...rest }: WidgetSkeletonProps) => {
    const dimension = useWidgetContext()

    return (
        <WidgetBaseContainer {...rest}>
            <WidgetHeaderSkeleton />
            {!disableInfo && checkWidgetDimensions(dimension, ['lg', 'mdY']) ? (
                <WidgetInfoSkeleton />
            ) : null}
        </WidgetBaseContainer>
    )
}

const WidgetBase = forwardRef(
    (
        { link, headerProps, infoProps, ...rest }: WidgetBaseProps,
        ref: React.MutableRefObject<HTMLDivElement>,
    ) => {
        const widgetRef = useRef<HTMLDivElement>()
        const currentRef = ref || widgetRef
        const tooltipWidth = useCalculateWidgetWidth(currentRef)
        const dimension = useWidgetContext()

        return (
            <WidgetBaseContainer
                ref={currentRef}
                {...rest}
            >
                <ErrorBoundary>
                    {link ? <WidgetBaseLink link={link} /> : null}
                    <WidgetBaseDragIndicator />
                    <WidgetBaseResizeIndicator />
                    <WidgetTooltipContext.Provider value={tooltipWidth}>
                        <WidgetHeader {...headerProps} />
                        {infoProps && checkWidgetDimensions(dimension, ['lg', 'mdY']) ? (
                            <WidgetInfo {...infoProps} />
                        ) : null}
                    </WidgetTooltipContext.Provider>
                </ErrorBoundary>
            </WidgetBaseContainer>
        )
    },
)

export default WidgetBase

import { ReactNode } from 'react'

import { TypographyProps } from '@mui/material'

import { BoxContainer } from 'components'
import Typography from 'components/mui/Typography'

interface IconTextProps {
    icon: React.ReactNode
    text: string
    typographyProps?: TypographyProps
}
const IconText = ({ typographyProps, ...props }: IconTextProps) => {
    return (
        <>
            {props.icon}
            <Typography
                variant="body2"
                color="text.main"
                pl="0.5ex"
                ml="12px"
                {...typographyProps}
            >
                {props.text}
            </Typography>
        </>
    )
}

export default IconText

export const DropdownIconText = ({
    children,
    gap = '10px',
}: {
    children: ReactNode
    gap?: string | number
}) => {
    return (
        <BoxContainer
            sx={{
                gap,
            }}
        >
            {children}
        </BoxContainer>
    )
}

import { FC } from 'react'

import { useWatch } from 'react-hook-form'

import { UnitModel } from 'appTypes/models'
import {
    ReferenceInput,
    AutocompleteInput,
    BoxContainer,
    ReferenceInputCreateLayer,
    Typography,
} from 'components'
import { emptyPerPage, perPageLimit } from 'configs/constants'
import UnitDrawerToggler from 'pages/Units/components/UnitDrawerToggler'
import { unitResource } from 'pages/Units/config/constants'
import { requiredValidation } from 'utils'

export interface WoFromInputProps {
    disabled?: boolean
    defaultValue?: any
}

const inputText = (record: UnitModel) => `${record.number} ${record.name}`
const optionText = (record: UnitModel) => {
    return (
        <BoxContainer whiteSpace="pre">
            <Typography color="text.primary">{record.number} </Typography>
            <Typography
                noWrap
                color="text.disabled"
            >
                {record.name}
            </Typography>
        </BoxContainer>
    )
}

export const UnitField: FC<WoFromInputProps & { canEditUnit?: boolean; unitsPerPage?: number }> = ({
    canEditUnit,
    unitsPerPage,
    ...props
}) => {
    const id = useWatch({ name: 'id' })

    const disabled = Boolean(id)

    return (
        <ReferenceInput
            disabled={disabled}
            reference={unitResource.resource}
            source="unit"
            perPage={props.disabled ? emptyPerPage : unitsPerPage ?? perPageLimit}
        >
            <ReferenceInputCreateLayer>
                {({ setValue }) => (
                    <AutocompleteInput
                        disabled={disabled}
                        renderCreateOption={(renderOption) => (
                            <UnitDrawerToggler
                                onSuccess={(record) => {
                                    setValue(record.id)
                                }}
                                resource={unitResource}
                                children={({ onClick }) =>
                                    renderOption({
                                        children: '+ Create New Unit',
                                        onClick,
                                    })
                                }
                            />
                        )}
                        optionText={optionText}
                        inputText={inputText}
                        label="Unit Number"
                        validate={requiredValidation}
                        {...props}
                    />
                )}
            </ReferenceInputCreateLayer>
        </ReferenceInput>
    )
}

export default UnitField

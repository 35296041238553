import { Palette } from '@mui/material'

import { JobItemsCalculations, JobItemTotals } from '../components/types'

export interface maxTotalEquipmentsType extends JobItemTotals {
    id: string
    code: string
    description: string
    total: number | null
    totalPercent: number | null
}

export type UncategorizedJobItems = {
    uncategorized: number
    uncategorizedTax: number
    uncategorizedServices: number
    uncategorizedFees: number
    uncategorizedLabor: number
    uncategorizedParts: number
}

export interface ResponseType extends JobItemsCalculations, UncategorizedJobItems {
    maxTotalEquipmentCategories: maxTotalEquipmentsType[]
}
export interface QueryResponse extends Omit<ResponseType, 'maxTotalEquipmentCategories'> {
    list: listType
}
export interface listType {
    data: maxTotalEquipmentsType[]
    top3Percentage: number
    top3Total: number
    sumOfExcludedEquipment: number
    uncategorizedTotal: number
    chartTotal: number
}

type Top3EquipmentsColorProps = keyof Palette['charts']
export const statusColors: Top3EquipmentsColorProps[] = ['orange', 'yellowBody', 'blue']

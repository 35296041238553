import { cardHeaderClasses, styled } from '@mui/material'

import { VendorContactModel } from 'appTypes/models'
import {
    InfoCard,
    InfoCardDetails,
    InfoCardHeader as CardHeader,
    DataAvatar,
    Typography,
    StyledElement,
} from 'components'
import DefaultUserAvatar from 'components/DefaultUserAvatar'
import { Action } from 'components/actions'
import { Flags, useFlags } from 'hooks'
import { phoneMaskResolver } from 'utils'

import VendorContactDrawerToggler from './VendorContactDrawerToggler'

export type ContactActions = Action<{
    contact: VendorContactModel
    flags: Flags
}>

interface VendorContactCardProps {
    //    listContext: ListControllerResult<VendorContactModel>,
    contact: VendorContactModel
    actions: ContactActions
}

const InfoCardHeader = styled(CardHeader)`
    .${cardHeaderClasses.content} {
        overflow: hidden;
    }
`
const VendorContactCard = ({ contact, actions }: VendorContactCardProps) => {
    const flags = useFlags()
    return (
        <InfoCard>
            <InfoCardHeader
                record={{ contact, flags }}
                avatar={
                    <VendorContactDrawerToggler id={contact.id}>
                        {({ onClick }) => (
                            <DataAvatar
                                sx={{ cursor: 'pointer' }}
                                defaultImage={<DefaultUserAvatar user={contact} />}
                                onClick={onClick}
                            />
                        )}
                    </VendorContactDrawerToggler>
                }
                actions={actions}
                title={
                    <VendorContactDrawerToggler id={contact.id}>
                        {({ onClick }) => (
                            <StyledElement
                                onClick={onClick}
                                sx={{ width: 'fit-content' }}
                            >
                                <Typography
                                    sx={{
                                        display: 'block',
                                        color: 'primary.main',
                                        whiteSpace: 'nowrap',
                                        cursor: 'pointer',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        width: 'fit-content',
                                        fontSize: 20,
                                        fontWeight: 500,
                                    }}
                                >
                                    {contact.name}
                                </Typography>
                            </StyledElement>
                        )}
                    </VendorContactDrawerToggler>
                }
            />
            <InfoCardDetails<VendorContactModel>
                details={[
                    {
                        label: 'Email',
                        source: 'email',
                    },
                    {
                        label: 'Phone',
                        source: ({ phone }) => phoneMaskResolver(phone),
                    },
                ]}
                propRecord={contact}
            />
        </InfoCard>
    )
}
export default VendorContactCard

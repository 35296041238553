import { ReactNode, ReactElement } from 'react'

import { alpha, Box, Theme } from '@mui/material'

import Typography from 'components/mui/Typography'
import { BoxContainer } from 'components/styled'

import {
    ArrayControllerDeleteButton,
    ArrayControllerDeleteButtonProps,
} from './ArrayControllerDeleteButton'

interface ArrayControllerBoxProps {
    title?: string
    children: ReactNode
    renderDeleteButton?: (params: {
        defaultButton: (args?: ArrayControllerDeleteButtonProps) => ReactElement
    }) => ReactElement
    deleteAlwaysVisible?: boolean
}

export const arrayControllerBoxBackground = (theme: Theme) =>
    alpha(theme.palette.text.primary, 0.04)

const ArrayControllerBox = ({
    children,
    title,
    renderDeleteButton,
    deleteAlwaysVisible,
}: ArrayControllerBoxProps) => {
    return (
        <Box
            bgcolor={arrayControllerBoxBackground}
            p="20px"
            mb="20px"
        >
            <BoxContainer
                mb="20px"
                justifyContent="space-between"
                minHeight="31px"
            >
                <Typography variant="subtitle2">{title}</Typography>
                {renderDeleteButton ? (
                    renderDeleteButton({
                        defaultButton: (params) => <ArrayControllerDeleteButton {...params} />,
                    })
                ) : (
                    <ArrayControllerDeleteButton
                        {...(deleteAlwaysVisible && { controller: { alwaysVisible: true } })}
                    />
                )}
            </BoxContainer>
            {children}
        </Box>
    )
}

export default ArrayControllerBox

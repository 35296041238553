import { useState } from 'react'

import { alpha } from '@mui/material/styles'
import { inject, observer } from 'mobx-react'

import { BoxContainer, DataAvatar, StyledElement, Typography } from 'components'
import DefaultUserAvatar from 'components/DefaultUserAvatar'
import { AuthStore } from 'providers/authStore'
import { freeSubscriptionBillingColorVariant } from 'utils'

import SidebarHide from './SidebarHide'
import SidebarProfileMenu from './SidebarProfileMenu'
import SidebarTooltip from './SidebarTooltip'

const SidebarProfile = inject('auth')(
    observer(({ auth }: { auth?: AuthStore }) => {
        const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

        const handleMenuClick = (event: React.MouseEvent<HTMLDivElement>) => {
            setAnchorEl(event.currentTarget)
        }
        const handleMenuClose = () => {
            setAnchorEl(null)
        }

        const billing = auth.billing

        return (
            <>
                <BoxContainer
                    sx={{
                        mb: '16px',
                        px: '16px',
                        minHeight: '48px',
                        cursor: 'pointer',
                    }}
                    onClick={handleMenuClick}
                >
                    <SidebarTooltip
                        title="Settings"
                        placement="right"
                        arrow
                    >
                        <DataAvatar
                            imageSrc={auth.user.avatar}
                            defaultImage={<DefaultUserAvatar fontSize="small" />}
                            sx={[
                                billing &&
                                    billing.subscription === 'free' && {
                                        boxSizing: 'border-box',
                                        borderWidth: '2px',
                                        borderStyle: 'solid',
                                        borderColor: (theme) =>
                                            freeSubscriptionBillingColorVariant(billing).color(
                                                theme,
                                            ),
                                    },
                                {
                                    width: 32,
                                    height: 32,
                                    backgroundColor: (theme) =>
                                        alpha(theme.palette.primary.main, 0.08),
                                    '& svg': {
                                        color: (theme) => theme.palette.primary.main,
                                    },
                                },
                            ]}
                        />
                    </SidebarTooltip>

                    <SidebarHide>
                        <div>
                            <StyledElement sx={{ display: 'grid', pr: '16px' }}>
                                <Typography
                                    variant="menuItem"
                                    sx={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        marginLeft: '28px',
                                    }}
                                >
                                    {auth.user.name}
                                </Typography>
                            </StyledElement>
                            <Typography
                                variant="inputLabel"
                                sx={{
                                    marginLeft: '28px',
                                    fontWeight: 400,
                                    fontSize: '12px',
                                    lineHeight: '20px',
                                }}
                            >
                                Settings
                            </Typography>
                        </div>
                    </SidebarHide>
                </BoxContainer>
                <SidebarProfileMenu
                    onClose={handleMenuClose}
                    anchorEl={anchorEl}
                />
            </>
        )
    }),
)

export default SidebarProfile

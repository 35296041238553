import { IconButton, SvgIcon } from '@mui/material'

import { ReactComponent as SortActiveIcon } from 'assets/images/sort-active.svg'
import { ReactComponent as SortIcon } from 'assets/images/sort.svg'
import HideOnBreakpoint from 'components/Injectors/HideOnBreakpoint'
import Icons from 'components/icons'
import Button from 'components/mui/Button'
import Typography from 'components/mui/Typography'
import { BoxContainer } from 'components/styled'

import { useListSortDrawerContext } from './ListSortDrawerContext'

const SortButton = () => {
    const { open, isDefaultSorted, reset } = useListSortDrawerContext()

    const Icon = isDefaultSorted ? SortIcon : SortActiveIcon

    return (
        <BoxContainer>
            <Typography
                component="div"
                color="text"
            >
                <HideOnBreakpoint
                    replaceWith={
                        <IconButton
                            aria-label="Open Sort"
                            size="small"
                            color="inherit"
                            onClick={open}
                        >
                            <SvgIcon
                                sx={{
                                    width: 20,
                                    height: 20,
                                }}
                                inheritViewBox
                                component={Icon}
                            />
                        </IconButton>
                    }
                >
                    <Button
                        variant="text"
                        color="inherit"
                        onClick={open}
                        startIcon={
                            <SvgIcon
                                inheritViewBox
                                component={Icon}
                            />
                        }
                    >
                        Sort By
                    </Button>
                    {!isDefaultSorted && (
                        <IconButton
                            aria-label="Clear Sort"
                            size="small"
                            onClick={reset}
                        >
                            <Icons.Close />
                        </IconButton>
                    )}
                </HideOnBreakpoint>
            </Typography>
        </BoxContainer>
    )
}

export default SortButton

import { useParams } from 'react-router-dom'

import IntegrationsUnitList from './IntegrationsUnitList'

const IntegrationsUnitPage = () => {
    const { telematic } = useParams()

    return <IntegrationsUnitList provider={telematic} />
}

export default IntegrationsUnitPage

import { ReactNode } from 'react'

import { RecordContextProvider } from 'react-admin'

import api from 'api/api'
import { useUtilityDrawerContext } from 'components/Drawer/UtilityDrawer'
import UtilityDrawerForm from 'components/Drawer/UtilityDrawerForm'
import { useFinalErrorHandler, useSubmit } from 'hooks'

import { startProviderTask } from '../Integrations'

import IntegrationDrawerCloseButton from './IntegrationDrawerCloseButton'
import IntegrationSuccessScrean from './IntegrationSuccessScreen'
import { IntegrationDrawerToglerExtraProps } from './useIntegrationDrawerTogler'

interface propsType {
    children: ReactNode
}

const InteractionDrawerWrapper = (props: propsType) => {
    const { updateState, state, extra, forceClose } = useUtilityDrawerContext()
    const errorHandler = useFinalErrorHandler()
    const { provider, refetch }: IntegrationDrawerToglerExtraProps = extra

    const handleSubmit = useSubmit(
        async ({ formDirtyTriggerField, ...formData }) => {
            try {
                if (provider.isActive) {
                    await api.patch('telematics/' + provider.provider, formData)
                } else {
                    await api.post('telematics/' + provider.provider + '/activate', formData)
                }
                startProviderTask(provider.provider)
            } catch (err) {
                errorHandler(err, {
                    fallbackError: 'Connection unsuccessful',
                })
                forceClose()
                return
            }
            refetch()
            provider.isActive
                ? forceClose()
                : updateState({
                      ...state,
                      drawerArgs: {
                          ...state.drawerArgs,
                          renderContent: () => <IntegrationSuccessScrean />,
                          renderBottomLeft: () => null,
                          renderBottomRight: () => <IntegrationDrawerCloseButton />,
                      },
                  })
        },
        {
            successMessage: provider.isActive ? 'Changes updated successfully' : null,
        },
    )
    return (
        <RecordContextProvider value={provider}>
            <UtilityDrawerForm
                onSubmit={handleSubmit}
                submittableAtBeginning={!provider.isActive}
                disableCloseOnSubmit
                {...props}
            />
        </RecordContextProvider>
    )
}

export default InteractionDrawerWrapper

import { useEffect } from 'react'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Box, Divider, IconButton, List, ListItemText } from '@mui/material'

import { BoxContainer, HideOnBreakpoint, SimpleScrollbar, StyledElement } from 'components'
import { menuItems } from 'configs/menus'

import LogoSection from '../LogoSection'

import { useSidebarContext } from './SidebarContext'
import SidebarDrawer from './SidebarDrawer'
import SidebarHide from './SidebarHide'
import SidebarNavItem from './SidebarNavItem'
import SidebarProfile from './SidebarProfile'
import SidebarSupport from './SidebarSupport'
import SidebarTooltip from './SidebarTooltip'

const Sidebar = () => {
    const { open, close, toggle } = useSidebarContext()

    useEffect(() => {
        if (open) {
            document.body.classList.add('scroll-lock')
        } else {
            document.body.classList.remove('scroll-lock')
        }

        return () => {
            document.body.classList.remove('scroll-lock')
        }
    }, [open])

    return (
        <SidebarDrawer
            variant="permanent"
            anchor="left"
            open={open}
            onClose={close}
            ModalProps={{ keepMounted: true }}
        >
            <Box>
                <StyledElement
                    sx={{
                        padding: '16px 20px',
                    }}
                >
                    {/* No ternary operator here, it has small transition effect */}
                    <LogoSection sx={{ ...(open ? null : { display: 'none' }) }} />
                    <LogoSection
                        type="small"
                        sx={{ ...(open ? { display: 'none' } : null) }}
                    />
                </StyledElement>
            </Box>

            <SimpleScrollbar
                style={{
                    paddingLeft: '4px',
                    paddingRight: '3px',
                    paddingTop: '31px',
                    height: '100%',
                    overflowX: 'hidden',
                }}
            >
                <List disablePadding>
                    {menuItems.map((item) => (
                        <SidebarNavItem {...item} />
                    ))}
                </List>
            </SimpleScrollbar>
            <Box mt="auto">
                <SidebarSupport />
                <SidebarProfile />
                <HideOnBreakpoint>
                    <>
                        <Divider />
                        <BoxContainer
                            sx={(theme) => ({
                                padding: '8px ' + theme.props.sidebarPaddingX + 'px',
                                cursor: 'pointer',
                            })}
                            onClick={toggle}
                        >
                            <SidebarTooltip
                                title="Expand"
                                placement="right"
                                arrow
                            >
                                <IconButton
                                    aria-label={open ? 'Expand Sidebar' : 'Collapse Sidebar'}
                                    sx={{
                                        width: '48px',
                                        height: '48px',
                                    }}
                                >
                                    {open ? <ArrowBackIcon /> : <ArrowForwardIcon />}
                                </IconButton>
                            </SidebarTooltip>

                            <SidebarHide>
                                <ListItemText sx={{ marginLeft: '20px' }}>Collapse</ListItemText>
                            </SidebarHide>
                        </BoxContainer>
                    </>
                </HideOnBreakpoint>
            </Box>
        </SidebarDrawer>
    )
}

export default Sidebar

import { Box, alpha } from '@mui/material'
import { useListController } from 'react-admin'
import RGL, { WidthProvider } from 'react-grid-layout'
import { useFormContext } from 'react-hook-form'

import { UnitPMReminderModel } from 'appTypes/models'

import PMRepositionElement, {
    PMRepositionElementPosition,
    elementHeight,
} from './PMRepositionElement'

const GridLayout = WidthProvider(RGL)

const elementsGap = 20

const PMRepositionList = () => {
    const { data, isFetching, error } = useListController<UnitPMReminderModel>({
        filter: { type: 'dependent' },
        sort: { field: 'rank', order: 'DESC' },
    })
    const formContext = useFormContext()

    if (isFetching || error) {
        return null
    }

    return (
        <Box
            display="flex"
            gap="12px"
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: elementsGap + 'px' }}>
                {data.map((record) => (
                    <PMRepositionElementPosition
                        key={record.id}
                        position={record.rank}
                    />
                ))}
            </Box>
            <Box
                sx={{
                    flexGrow: 1,
                    '& .react-grid-placeholder': {
                        backgroundColor: 'unset',
                        border: (theme) => '2px dashed ' + alpha(theme.palette.text.primary, 0.23),
                        borderRadius: '4px',
                    },
                }}
            >
                <GridLayout
                    containerPadding={[0, 0]}
                    className="layout"
                    margin={[elementsGap, elementsGap]}
                    onLayoutChange={(layout) => {
                        formContext.setValue(
                            'parentFirstOrder',
                            [...layout].sort((a, b) => a.y - b.y).map((item) => item.i),
                        )
                    }}
                    draggableHandle=".draggable-area"
                    rowHeight={elementHeight}
                    cols={1}
                    isResizable={false}
                >
                    {data.map((record) => (
                        <div
                            key={record.id}
                            data-grid={{ i: record.id, x: 0, y: 0, w: 1, h: 1 }}
                        >
                            <PMRepositionElement record={record} />
                        </div>
                    ))}
                </GridLayout>
            </Box>
        </Box>
    )
}

export default PMRepositionList

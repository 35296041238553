import { basePermissions } from 'configs/constants'
import { renderOnPermission } from 'hocs'
import { DeleteOneParams, useDeleteOne } from 'hooks'

import { ActionChildren } from '../Actions'

import { deleteActionBaseOptions } from './deleteActionCommon'

interface DeleteOneActionProps extends DeleteOneParams {
    children: ActionChildren
    disabled?: boolean
    title?: string
}

export const DeleteOneAction = renderOnPermission(
    ({ children, disabled, title, ...rest }: DeleteOneActionProps) => {
        const deleteOne = useDeleteOne()

        return children({
            disabled: disabled ?? false,
            Icon: deleteActionBaseOptions.Icon,
            onClick: () => {
                deleteOne(rest)
            },
            title: title ?? 'Delete',
        })
    },
    basePermissions.destroy,
)

export const deleteOneAction = (params: DeleteOneActionProps) => (
    <DeleteOneAction
        {...params}
        key="delete-one"
    />
)

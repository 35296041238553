import { FC } from 'react'

import { Identifier, useRecordContext, ListBase } from 'react-admin'

import { WorkOrderModel } from 'appTypes/models'
import { FlexReverse, PageContent, RaResourceContextProvider } from 'components'

// import { useWoActionIsDisabled } from '../hooks'

import { poResource } from '../config/constants'

import {
    InvoiceDrawerTogglerProps,
    InvoicesSection,
    PaymentDrawerTogglerProps,
    PaymentsSection,
    PurchaseOrderSection,
    WorkOrderInvoiceEditDrawerToggler,
    WorkOrderPaymentEditDrawerToggler,
} from './components'

interface WorkOrderInvoiceOpenDrawerButtonProps
    extends Pick<InvoiceDrawerTogglerProps, 'children'> {
    mode: 'edit' | 'create'
    id?: Identifier
}
export const WorkOrderInvoiceOpenDrawerButton: FC<WorkOrderInvoiceOpenDrawerButtonProps> = ({
    mode,
    children,
    id,
}) => {
    const record = useRecordContext<WorkOrderModel>()

    if (!record) {
        return null
    }

    return (
        <WorkOrderInvoiceEditDrawerToggler
            key={mode}
            id={mode === 'edit' ? id : null}
            term={record?.purchaseOrderData?.vendorData.paymentTerm || ''}
        >
            {children}
        </WorkOrderInvoiceEditDrawerToggler>
    )
}

interface WorkOrderPaymentOpenDrawerButtonProps
    extends Pick<PaymentDrawerTogglerProps, 'children'> {
    mode: 'edit' | 'create'
    id?: Identifier
}
export const WorkOrderPaymentOpenDrawerButton: FC<WorkOrderPaymentOpenDrawerButtonProps> = ({
    mode,
    children,
    id,
}) => {
    const record = useRecordContext<WorkOrderModel>()
    // const disabled = useWoActionIsDisabled()
    if (!record) {
        return null
    }
    return (
        <WorkOrderPaymentEditDrawerToggler
            key={mode}
            id={mode === 'edit' ? id : null}
            paymentMethod={record.purchaseOrderData?.vendorData.paymentMethod || ''}
        >
            {children}
        </WorkOrderPaymentEditDrawerToggler>
    )
}

const invoicesResource = 'invoices'
const paymentsResource = 'payments'
const WorkOrderInvoice = () => {
    const record = useRecordContext()

    const invoiceResource = `${poResource.resource}/${record?.purchaseOrderData?.id}/${invoicesResource}`
    const paymentResource = `${poResource.resource}/${record?.purchaseOrderData?.id}/${paymentsResource}`
    return (
        <PageContent>
            <FlexReverse>
                <PurchaseOrderSection />
                <RaResourceContextProvider value={{ resource: invoiceResource, name: 'invoices' }}>
                    <ListBase perPage={1000}>
                        <InvoicesSection />
                    </ListBase>
                </RaResourceContextProvider>
                <RaResourceContextProvider value={{ resource: paymentResource, name: 'payments' }}>
                    <ListBase perPage={1000}>
                        <PaymentsSection />
                    </ListBase>
                </RaResourceContextProvider>
            </FlexReverse>
        </PageContent>
    )
}

export default WorkOrderInvoice

import { Badge, badgeClasses } from '@mui/material'
import { styled } from '@mui/material/styles'

const BadgeText = styled(Badge)`
    position: static;

    & .${badgeClasses.badge} {
        position: static;
        transform: unset;
        z-index: unset;
    }
`

BadgeText.defaultProps = {
    max: Infinity,
}

export default BadgeText

import { Link, LinkProps, styled } from '@mui/material'
import { Link as RouterLink, To } from 'react-router-dom'

export interface LinkButtonProps extends LinkProps {
    disabled?: boolean
    to?: To
}

const LinkButton = styled((props: LinkButtonProps) => {
    const { disabled, to, ...rest } = props
    let forwardedProps: LinkProps = rest

    if (disabled) {
        const { href, download, onClick, ...restForwarded } = rest

        forwardedProps = restForwarded
    }

    if (to) {
        return (
            <Link
                {...forwardedProps}
                component={RouterLink}
                to={to}
            />
        )
    }

    return <Link {...forwardedProps} />
})<LinkButtonProps>`
    ${({ disabled, theme }) =>
        disabled
            ? `
        color: ${theme.palette.action.disabled}
    `
            : ''}
`

LinkButton.defaultProps = {
    variant: 'body2',
}

export default LinkButton

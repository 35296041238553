import colors from './colors'

const darkPalette = {
    primary: {
        light: '#42A5F5',
        main: '#90CAF9',
        dark: '#E3F2FD',
        contrastText: '#000000',
        contrastTextOpacity: 0.8,
    },
    secondary: {
        light: '#F3E5F5',
        main: '#CE93D8',
        dark: '#AB47BC',
        contrastText: '#000000',
        contrastTextOpacity: 0.87,
    },
    error: {
        light: '#E57373',
        main: '#F44336',
        dark: '#D32F2F',
        contrastText: colors.white,
        background: colors.red[50],
        content: '#5d0b0b',
    },
    success: {
        light: '#81C784',
        main: '#66BB6A',
        dark: '#388E3C',
        contrastText: '#000000',
        background: colors.lightGreen[50],
        content: '#0a422d',
    },
    info: {
        light: '#4FC3F7',
        main: '#29B6F6',
        dark: ' #0288D1',
        contrastText: '#000000',
        contrastTextOpacity: 0.87,
        background: colors.blue[50],
        content: '#143d80',
    },
    action: {
        active: colors.white,
        activeOpacity: 0.56,
        selected: colors.white,
        hoverOpacity: 0.08,
        selectedOpacity: 0.16,
        disabledBackground: colors.white,
        disabledBackgroundOpacity: 0.12,
        disabled: colors.white,
        disabledOpacity: 0.3,
        focusOpacity: 0.12,
    },
    warning: {
        light: '#FFB74D',
        main: '#FFA726',
        dark: '#F57C00',
        contrastText: '#000000',
        contrastTextOpacity: 0.87,
        background: colors.orange[50],
        content: '#7e4600',
    },
    text: {
        primary: colors.white,
        secondary: colors.white,
        secondaryOpacity: 0.7,
        disabled: colors.white,
        disabledOpacity: 0.5,
    },
    background: {
        default: '#121212',
        paper: '#121212',
    },
}

export default darkPalette
type PaletteType = typeof darkPalette
export interface darkPaletteType extends PaletteType {}

import { ReactComponent as ArchivedIcon } from 'assets/images/widgets-archived.svg'
import { Columns, Spacer } from 'components'
import { useCreateResourcePath } from 'hooks'
import { WidgetListFTU } from 'pages/Dashboard/components/WidgetBase/styled'
import { getUnitResource } from 'pages/Units/config/constants'
import { resolveDoublePriceMask } from 'utils'

import { Arrow, WidgetListRowBase } from '../../components'
import { listOrderType, listType } from '../types'

interface CostPerMileListProps {
    data: listType
    listOrder: listOrderType
    description: string
}
const CostPerMileList = ({ data, description, listOrder }: CostPerMileListProps) => {
    const createPath = useCreateResourcePath()

    const sortedData = data.length
        ? data.sort((a, b) =>
              listOrder === 'DESC'
                  ? b.differenceWithAverage - a.differenceWithAverage
                  : a.differenceWithAverage - b.differenceWithAverage,
          )
        : data

    const List = sortedData
        .slice(0, 3)
        .flatMap(({ number, differenceWithAverage, id, costPerMile, archived }) => {
            const isBelowAvg = differenceWithAverage < 0
            const unitEditUrl = createPath({
                resource: getUnitResource(archived).resource,
                id,
                type: 'edit',
            })
            return (
                <WidgetListRowBase
                    key={id}
                    link={unitEditUrl}
                    icon={(render) =>
                        archived ? render({ icon: ArchivedIcon, tooltip: 'Archived' }) : undefined
                    }
                    label={number}
                    clarification={
                        <>
                            {resolveDoublePriceMask(Math.abs(differenceWithAverage))}
                            {isBelowAvg ? ' below ' : ' above '}
                            average
                        </>
                    }
                    total={
                        <Spacer>
                            <Arrow
                                direction={isBelowAvg ? 'down' : 'up'}
                                sx={(theme) => ({
                                    width: '16px',
                                    height: '16px',
                                    color: theme.palette.charts[isBelowAvg ? 'greenBody' : 'red'],
                                })}
                            />
                            {resolveDoublePriceMask(costPerMile)}
                        </Spacer>
                    }
                />
            )
        })

    return (
        <Columns
            width="100%"
            height="100%"
            gap="29px"
        >
            {List.length ? List : <WidgetListFTU description={description} />}
        </Columns>
    )
}
export default CostPerMileList

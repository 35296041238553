import { RaRecord } from 'react-admin'

import { CreateCustomProps } from 'components/resource/Create'

import { useUtilityDrawerContext } from './UtilityDrawer'
import UtilityDrawerCreate, { UtilityDrawerCreateCustomProps } from './UtilityDrawerCreate'
import { UtilityDrawerEditCustomProps } from './UtilityDrawerEdit'

import { UtilityDrawerEdit } from '.'

export interface UtilityDrawerExtraProps {
    redirect?: CreateCustomProps['redirect']
}

export type UtilityDrawerEditorProps<RecordType extends RaRecord = any> = (
    | UtilityDrawerEditCustomProps<RecordType>
    | UtilityDrawerCreateCustomProps<RecordType>
) &
    UtilityDrawerExtraProps & {
        editorType?: 'edit' | 'create'
    }

const UtilityDrawerEditor = <RecordType extends RaRecord = any>({
    editorType,
    ...props
}: UtilityDrawerEditorProps<RecordType>) => {
    const { extraArgs = {} } = useUtilityDrawerContext()
    const Component =
        (editorType && editorType === 'edit') ?? extraArgs.id
            ? UtilityDrawerEdit
            : UtilityDrawerCreate

    return <Component {...props} />
}

export default UtilityDrawerEditor

import { inject, observer } from 'mobx-react'
import { useFormContext } from 'react-hook-form'

import { Button, FormProps, TextInput } from 'components'
import UtilityDrawerForm from 'components/Drawer/UtilityDrawerForm'
import { updatedMessage } from 'configs/constants'
import { useAuthProvider, useSubmit } from 'hooks'
import { AuthStore } from 'providers/authStore'
import { costNonNegativeMaskParams, parseNumber } from 'utils'

export const PaymentLimitClear = () => {
    const { reset, watch } = useFormContext()
    const { amount } = watch()

    return (
        <Button
            size="small"
            color="error"
            disabled={amount ? false : true}
            onClick={() =>
                reset({
                    amount: null,
                })
            }
        >
            Clear
        </Button>
    )
}
export const PaymentLimitForm = () => {
    return (
        <TextInput
            source="amount"
            {...costNonNegativeMaskParams}
            label="Limit"
        />
    )
}

export const PaymentLimitFormWrapper = inject('auth')(
    observer(({ auth, ...props }: FormProps & { auth?: AuthStore }) => {
        const authProvider = useAuthProvider()
        const billing = auth.billing

        const submit = useSubmit<{ amount: string }>(
            async ({ amount }) => {
                await authProvider.setPaymentLimit(parseNumber(amount) as number)
            },
            {
                successMessage: updatedMessage,
            },
        )

        return (
            <UtilityDrawerForm
                {...props}
                onSubmit={submit}
                record={{ amount: billing.paymentLimit }}
            />
        )
    }),
)

import { FC, useEffect } from 'react'

import { useAskConfirm } from 'components/form/FormWarnWhenUnsavedChanges'
import { useSetBlocker } from 'hooks'

import { useUtilityDrawerContext } from './UtilityDrawer'

const UtilityDrawerBlock: FC<{ isBlocked?: boolean }> = ({ isBlocked = true }) => {
    const askConfirm = useAskConfirm()

    const { controller, forceClose } = useUtilityDrawerContext()

    useSetBlocker(
        {
            preventNavigate: (action) => {
                if (action === 'POP') {
                    askConfirm(() => {
                        forceClose()
                    })
                }
            },
        },
        {
            isOpen: isBlocked,
        },
    )

    useEffect(() => {
        if (!isBlocked) {
            return
        }

        const unblock = controller.block(({ retry }) => {
            askConfirm(() => {
                unblock()
                retry()
            })
        })

        return unblock
    }, [isBlocked])

    return null
}

export default UtilityDrawerBlock

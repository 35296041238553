import { FC } from 'react'

import { Alert, Box } from '@mui/material'

import { WorkOrderModel } from 'appTypes/models'
import { Typography } from 'components'
import { resolveDoublePriceMask } from 'utils'

interface WorkOrderCloseDialogUnsuccessfulProps {
    data: WorkOrderModel
}

const WorkOrderCloseDialogUnsuccessful: FC<WorkOrderCloseDialogUnsuccessfulProps> = ({ data }) => {
    return (
        <Box>
            <Alert
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
                variant="standard"
                severity="warning"
            >
                The amount of Purchase Order and Invoices Total should match.
            </Alert>
            <Typography
                color="text.primary"
                padding="12px 0px"
                variant="body2"
            >
                Purchase Order Total {resolveDoublePriceMask(data.purchaseOrderData.total || 0)}
            </Typography>
            <Typography
                color="text.primary"
                padding="12px 0px"
                variant="body2"
            >
                Invoices Total {resolveDoublePriceMask(data.purchaseOrderData.totalInvoices || 0)}
            </Typography>
        </Box>
    )
}

export default WorkOrderCloseDialogUnsuccessful

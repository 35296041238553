import { alpha, styled } from '@mui/material'

import { BoxContainer, Columns, Typography } from 'components'

export const WidgetInfoContainer = styled(BoxContainer)`
    justify-content: space-between;
    padding: 30px 20px 20px 20px;
    max-height: 153px;
    position: relative;
    justify-content: center;
    flex: 1;
`
export const WidgetListContainer = styled(Columns)`
    gap: 27px;
    width: 100%;
    height: 100%;
`
export const Separator = styled('div')`
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 100%;
    height: 1px;
    background: ${({ theme }) => alpha(theme.palette.text.main, 0.12)};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 30px;
`

export const InfoTitle = styled(Typography)`
    background: white;
    padding: 0px 5px;
`

import { ReactNode } from 'react'

import { ListContextProvider, useListController } from 'react-admin'

import { PartModel } from 'appTypes/models'
import { useClearResource, useCreateResourcePath } from 'hooks'

const CrossReferenceList = ({ record, children }: { record: PartModel; children: ReactNode }) => {
    const createPath = useCreateResourcePath()

    const path =
        createPath({
            id: record?.id,
            type: 'edit',
        }) + '/cross-references'
    useClearResource(path)

    const listController = useListController({
        resource: path,
        perPage: 3,
        queryOptions: {
            enabled: Boolean(record?.id),
        },
    })

    return <ListContextProvider value={listController}>{children}</ListContextProvider>
}
export default CrossReferenceList

import { ReactElement, ReactNode } from 'react'

import { useMediaQuery, Theme } from '@mui/material'
interface HideOnBreakpointProps {
    direction: string
    breakpoint: string
    children: ReactNode
    replaceWith: ReactNode
    render: (match: boolean) => ReactElement
}

const HideOnBreakpoint = ({
    direction = 'up',
    breakpoint = 'sm',
    children,
    replaceWith = null,
    render,
}: Partial<HideOnBreakpointProps>) => {
    const match: boolean = useMediaQuery((theme: Theme) =>
        theme.breakpoints[direction!](breakpoint),
    )

    if (render) {
        return render(match)
    }

    if (!match) {
        return <>{replaceWith}</>
    }

    return <>{children}</>
}

export default HideOnBreakpoint

import { createContext, FC, useContext, ReactNode } from 'react'

import { useTelematics } from './hooks'

const TelematicsContext = createContext({} as ReturnType<typeof useTelematics>)

export const TelematicsContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const telematics = useTelematics()

    return <TelematicsContext.Provider value={telematics}>{children}</TelematicsContext.Provider>
}

export const useTelematicsContext = () => useContext(TelematicsContext)

export default TelematicsContext

import { ArrowUpward, ArrowDownward } from '@mui/icons-material'

type ArrowType = React.ComponentProps<typeof ArrowUpward>
interface ArrowProps extends ArrowType {
    direction: 'up' | 'down'
}
const Arrow = ({ direction, ...props }: ArrowProps) => {
    return direction === 'up' ? <ArrowUpward {...props} /> : <ArrowDownward {...props} />
}
Arrow.defaultProps = {
    direction: 'up',
}
export default Arrow

import { FC, ReactNode } from 'react'

import { Link } from '@mui/material'
import { Identifier } from 'react-admin'
import { useQueryClient } from 'react-query'
import { Link as RouterLink } from 'react-router-dom'

import { JobModel, WorkOrderModel } from 'appTypes/models'
import { RaResourceContextProvider, useResource, UtilityDrawerCreate } from 'components'
import { useUtilityDrawerContext } from 'components/Drawer/UtilityDrawer'
import { useCreateResourcePath } from 'hooks'
import { woJobSerializer } from 'pages/WorkOrders/Jobs/config/constants'
import { woResource } from 'pages/WorkOrders/config/constants'
import { useWoLimit } from 'pages/WorkOrders/hooks'
import { Serializer } from 'utils'

import { useStepsContext } from './UseWOStepsContext'
import { UseWOExtraState } from './useAttachWO'

const UseWOJobForm: FC<{ children: ReactNode }> = ({ children }) => {
    const { extra, forceClose } = useUtilityDrawerContext()
    const { parentRecord, workOrder, refetchList, options }: UseWOExtraState = extra
    const { stepsData, step } = useStepsContext()
    const resource = useResource()
    const query = useQueryClient()
    const { hasError, woLimitReachedAction } = useWoLimit()
    const createPath = useCreateResourcePath()

    const jobSerializer: Serializer<JobModel & { workOrder: Identifier }> = [
        ...woJobSerializer,
        { name: 'workOrder', parse: () => workOrder.id },
    ]

    return (
        <RaResourceContextProvider
            value={{ resource: resource.resource + '/' + parentRecord.id + '/job' }}
        >
            <UtilityDrawerCreate
                formOnError={({ errors, defaultOnError, reset }) => {
                    if (hasError(errors)) {
                        woLimitReachedAction()
                        reset(
                            {},
                            {
                                keepValues: true,
                                keepDirty: true,
                                keepTouched: true,
                            },
                        )
                        return
                    }

                    defaultOnError()

                    if (errors.workOrder) {
                        forceClose()
                    }
                }}
                submittableAtBeginning
                serializer={jobSerializer}
                defaultValues={
                    stepsData.current[step]?.values || {
                        name: parentRecord.name,
                        component: parentRecord.component,
                        reasonForRepair: parentRecord.reasonForRepair,
                        ...options?.jobDefaultValues,
                    }
                }
                onSuccess={() => {
                    query.refetchQueries({
                        active: true,
                        predicate: (query) => {
                            return Boolean(
                                (query.queryKey[1] as string) === 'getList' &&
                                    (query.queryKey[0] as string).endsWith?.('/' + refetchList),
                            )
                        },
                    })
                }}
                successMessage={({ defaultMessages, response }) => {
                    const wo = response as { workOrderData: Pick<WorkOrderModel, 'id' | 'number'> }
                    return (
                        <>
                            {defaultMessages.created}
                            <Link
                                fontWeight="700"
                                ml="10px"
                                variant="body2"
                                component={RouterLink}
                                to={createPath({
                                    resource: woResource.resource,
                                    type: 'edit',
                                    id: wo.workOrderData.id,
                                })}
                            >
                                Go to {wo.workOrderData.number}
                            </Link>
                        </>
                    )
                }}
                successMessageArgs={{ width: 'max-content' }}
            >
                {children}
            </UtilityDrawerCreate>
        </RaResourceContextProvider>
    )
}

export default UseWOJobForm

import { BoxContainer, LoadingSpinner } from 'components'

const SyncingCardContent = () => {
    return (
        <BoxContainer
            height="70px"
            justifyContent="center"
        >
            <LoadingSpinner
                sx={{
                    width: '40px',
                    height: '40px',
                }}
            />
        </BoxContainer>
    )
}

export default SyncingCardContent

import { styled } from '@mui/material/styles'
import {
    SelectInput as RASelectInput,
    SelectInputProps as RASelectInputProps,
    useChoicesContext,
    useInput,
} from 'react-admin'

import { AllProps } from 'appTypes'
import ClearAdornment from 'components/ClearAdornment'
import { useInputState } from 'components/context/InputStateContext'
import { emptySelectValue } from 'configs/constants'
import { useCreateInputId } from 'hooks'
import { propertyToText } from 'utils'

const StyledRASelectInput = styled(RASelectInput)`
    margin-top: 0px;
`
export interface SelectInputProps<FormType extends AllProps = AllProps>
    extends Omit<RASelectInputProps, 'source'> {
    disableEmptyValue?: boolean
    clearable?: boolean
    source: Extract<keyof FormType, string>
}

const SelectInput = <FormType extends AllProps = AllProps>({
    disableEmptyValue,
    clearable,
    format,
    ...rest
}: SelectInputProps<FormType>) => {
    const { source: choicesSource } = useChoicesContext()
    const { source, ...state } = useInputState(rest.source || choicesSource || '', {
        disabled: rest.disabled,
    })
    const finalSource = choicesSource || source
    const { field, isRequired } = useInput({
        source: finalSource,
        validate: rest.validate,
    })

    const createId = useCreateInputId()

    return (
        <StyledRASelectInput
            // @ts-ignore
            clearAlwaysVisible={false}
            defaultValue=""
            fullWidth
            size="medium"
            emptyValue={disableEmptyValue ? emptySelectValue : ''}
            variant="outlined"
            format={(value) => {
                const v = format ? format(value) : value
                return v ? v : ''
            }}
            {...rest}
            {...state}
            SelectProps={{
                ...rest.SelectProps,
                MenuProps: {
                    ...rest.SelectProps?.MenuProps,
                    MenuListProps: {
                        'aria-label': `Options for ${propertyToText(finalSource)}`,
                        ...rest.SelectProps?.MenuProps?.MenuListProps,
                    },
                },
            }}
            id={createId({ source: finalSource, id: rest.id })}
            {...(!state.disabled &&
                (clearable ?? !isRequired) &&
                Boolean(field.value) && {
                    InputProps: {
                        ...rest.InputProps,
                        endAdornment: (
                            <ClearAdornment
                                className="show-on-hover"
                                onClick={() => field.onChange('')}
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    right: '29px',
                                    transform: 'translate(0px,-50%)',
                                }}
                            />
                        ),
                    },
                })}
        />
    )
}

SelectInput.defaultProps = {
    source: '',
}
export default SelectInput

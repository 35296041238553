import { ReactElement } from 'react'

import { ViewModule, ViewHeadline } from '@mui/icons-material'
import {
    Box,
    Divider,
    ToggleButtonGroup,
    ToggleButton,
    styled,
    Tooltip,
    buttonBaseClasses,
} from '@mui/material'

import { DatagridColumnsProps, FilterLiveSearch, StyledElement } from 'components'
import HideOnBreakpoint from 'components/Injectors/HideOnBreakpoint'
import classes from 'theme/classes'

import ColumnsButton from './ColumnsButton'
import ExportButton, { ExportButtonProps } from './ExportButton'
import FilterButton from './FilterButton'
import { useListView, ListViewMode } from './ListViewContext'
import SortButton from './SortButton'
import { ListFilterFormProps } from './filter/ListFilterForm'

const ListToolbarWrapper = styled('div')`
    display: flex;
    width: 100%;
    height: 40px;
    margin-bottom: 16px;
`
const ListToolbarLeft = styled('div')`
    display: flex;
    align-items: center;
    flex-grow: 1;
    gap: 16px;
`
export interface ListToolbarProps {
    exportFileName?: string
    disableFilter?: boolean
    disableSort?: boolean
    filtersCfg?: ListFilterFormProps['filtersCfg']
    visibleColsRef?: ExportButtonProps['visibleColsRef']
    disableViewsSwitch?: boolean
    contentBeforeViews?: ReactElement
    contentUnderToolbar?: ReactElement
    excludeFields?: string[]
    resetColumns?: DatagridColumnsProps['resetColumns']
    disableManageColumns?: boolean
    disableExportButton?: boolean
    hideSearch?: boolean
}

const ListToolbar = ({
    exportFileName,
    disableFilter,
    disableSort,
    filtersCfg,
    visibleColsRef,
    disableViewsSwitch = false,
    contentBeforeViews,
    excludeFields,
    contentUnderToolbar,
    resetColumns,
    disableManageColumns = false,
    disableExportButton = false,
    hideSearch,
}: ListToolbarProps) => {
    const { toggleView, viewMode } = useListView()

    return (
        <>
            <ListToolbarWrapper>
                <ListToolbarLeft>
                    {!hideSearch && <FilterLiveSearch sx={{ width: '184px' }} />}
                    {!(disableSort && disableFilter) && (
                        <StyledElement
                            sx={(theme) => ({
                                display: 'flex',
                                gap: '16px',
                                [theme.breakpoints.down('sm')]: {
                                    marginLeft: 'auto',
                                    gap: '12px',
                                },
                            })}
                        >
                            {!disableSort && <SortButton />}
                            {!disableFilter && (
                                <FilterButton
                                    excludeFields={excludeFields}
                                    filtersCfg={filtersCfg}
                                />
                            )}
                            {!disableExportButton && (
                                <HideOnBreakpoint
                                    breakpoint="sm"
                                    direction="down"
                                >
                                    <ExportButton
                                        fileName={exportFileName}
                                        visibleColsRef={visibleColsRef}
                                    />
                                </HideOnBreakpoint>
                            )}
                        </StyledElement>
                    )}
                </ListToolbarLeft>
                <Box
                    sx={{
                        display: 'flex',
                        gap: '16px',
                    }}
                >
                    {contentBeforeViews}
                    {disableViewsSwitch ? null : (
                        <HideOnBreakpoint breakpoint="md">
                            {viewMode === ListViewMode.list && !disableManageColumns ? (
                                <>
                                    <ColumnsButton resetColumns={resetColumns} />
                                    <Divider
                                        orientation="vertical"
                                        flexItem
                                    />
                                </>
                            ) : null}
                            <ToggleButtonGroup
                                size="small"
                                value={viewMode}
                                exclusive
                                sx={({ palette }) => ({
                                    [`.${buttonBaseClasses.root}`]: {
                                        backgroundColor: palette.white,
                                        color: palette.text.secondary,
                                    },
                                    [`.${classes.selected}`]: {
                                        color: palette.text.primary,
                                    },
                                })}
                            >
                                <Tooltip title="Table View">
                                    <ToggleButton
                                        value={viewMode}
                                        onClick={() => toggleView(ListViewMode.list)}
                                        selected={viewMode === ListViewMode.list}
                                    >
                                        <ViewHeadline />
                                    </ToggleButton>
                                </Tooltip>
                                <Tooltip title="Card View">
                                    <ToggleButton
                                        value={viewMode}
                                        onClick={() => toggleView(ListViewMode.grid)}
                                        selected={viewMode === ListViewMode.grid}
                                    >
                                        <ViewModule />
                                    </ToggleButton>
                                </Tooltip>
                            </ToggleButtonGroup>
                            {!disableExportButton && (
                                <Divider
                                    orientation="vertical"
                                    flexItem
                                />
                            )}
                        </HideOnBreakpoint>
                    )}
                    {!disableExportButton && (
                        <HideOnBreakpoint breakpoint="sm">
                            <ExportButton
                                fileName={exportFileName}
                                visibleColsRef={visibleColsRef}
                            />
                        </HideOnBreakpoint>
                    )}
                </Box>
            </ListToolbarWrapper>
            {contentUnderToolbar}
        </>
    )
}

ListToolbar.defaultProps = {}

export default ListToolbar

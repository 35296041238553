import { JobLaborModel } from 'appTypes/models'
import { Serializer } from 'utils'

export const jobLaborSerializer: Serializer<JobLaborModel> = [
    'description',
    { name: 'hourlyRate', parse: 'number' },
    { name: 'hours', parse: 'number' },
    { name: 'component', parse: 'emptyToNull' },
    { name: 'cause', parse: 'emptyToNull' },
    { name: 'correction', parse: 'emptyToNull' },
]

import { Icon } from '@mui/material'
import { RaRecord } from 'react-admin'

import { BoxContainer, Columns, Typography } from 'components'
import { useActionsMenuContext } from 'components/actions'
import { vwoAvatarConfig, woAvatarConfig } from 'pages/WorkOrders/config/constants'

import { WorkOrderCreateMenuProps } from './AddWorkOrderMenu'
import useAttachWO, { UseWOParams } from './UseWO/useAttachWO'
const CreateWOSection = <ParentType extends RaRecord = any>({
    parentRecord,
    refetchList,
    cardMenuClose,
    options,
}: Pick<WorkOrderCreateMenuProps<ParentType>, 'parentRecord' | 'refetchList' | 'options'> & {
    cardMenuClose?: () => void
}) => {
    const woUse = useAttachWO()
    const { close } = useActionsMenuContext()

    const attachWo = (type: UseWOParams['woType']) => {
        woUse({
            parentRecord,
            refetchList,
            options,
            woType: type,
        })
    }

    return (
        <Columns
            width="274px"
            sx={{ padding: '14px 16px 0' }}
        >
            <Typography
                variant="subtitle2"
                color="text.primary"
                pb="20px"
            >
                ADD NEW WORK ORDER
            </Typography>
            <BoxContainer
                gap="18px"
                mb="12px"
            >
                <Icon
                    component={woAvatarConfig.Icon}
                    sx={{
                        color: (theme) => theme.palette.text.primary,
                        opacity: 0.54,
                        height: '20px',
                        width: '20px',
                    }}
                />
                <Typography
                    variant="menuItem"
                    color="text.primary"
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                        close()
                        cardMenuClose?.()
                        attachWo('internal')
                    }}
                >
                    Create Internal Work Order
                </Typography>
            </BoxContainer>

            <BoxContainer gap="18px">
                <Icon
                    component={vwoAvatarConfig.Icon}
                    sx={{
                        color: (theme) => theme.palette.text.primary,
                        opacity: 0.54,
                        height: '20px',
                        width: '20px',
                    }}
                />
                <Typography
                    variant="menuItem"
                    color="text.primary"
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                        close()
                        cardMenuClose?.()
                        attachWo('vendor')
                    }}
                >
                    Create Vendor Work Order
                </Typography>
            </BoxContainer>
        </Columns>
    )
}

export default CreateWOSection

import { ReactElement } from 'react'

import { Identifier, RaRecord, useGetOne } from 'react-admin'

import { ResourceType } from 'components'

import ConfirmContentWhileRequest from './ConfirmContentWhileRequest'
const ConfirmContentWithFetchOne = <RecordType extends RaRecord = any>({
    resource,
    id,
    render,
}: {
    resource: ResourceType
    id: Identifier
    render: (data: RecordType) => ReactElement
}) => {
    const { data, isFetching } = useGetOne(resource.resource, { id })

    if (isFetching) {
        return <ConfirmContentWhileRequest />
    }

    return render(data)
}
export default ConfirmContentWithFetchOne

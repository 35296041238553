import { useListContext } from 'react-admin'

import {
    Portal,
    buttonMobileFixed,
    useOpenUtilityDrawer,
    useResource,
    UtilityDrawerAddNewButton,
    Button,
} from 'components'
import Icons from 'components/icons/'
import { basePermissions } from 'configs/constants'
import { renderOnPermission } from 'hocs'

import { MemberInviteWrapper } from './MemberInviteDrawer'
import MemberInviteFormContent from './MemberInviteFormContent'

const ButtonComponent = buttonMobileFixed(Button)

export const MemberInviteOpenDrawerButton = renderOnPermission(() => {
    const open = useOpenUtilityDrawer()
    const listContext = useListContext()
    const resource = useResource()

    return (
        <Portal containerId="header-portal-root">
            <ButtonComponent
                size="large"
                variant="contained"
                startIcon={<Icons.Add />}
                onClick={() =>
                    open({
                        drawerArgs: {
                            title: 'Invite New Member',
                            renderWrapper: (params) => <MemberInviteWrapper {...params} />,
                            renderContent: () => <MemberInviteFormContent />,
                            renderTopRight: () => <UtilityDrawerAddNewButton />,
                            renderBottomRight: (renderButton) =>
                                renderButton({
                                    icon: <Icons.Invitations fontSize="large" />,
                                    label: 'Invite',
                                }),
                        },
                        extraArgs: {
                            listContext,
                            resource,
                        },
                    })
                }
            >
                INVITE MEMBER
            </ButtonComponent>
        </Portal>
    )
}, basePermissions.createBulk)

import { Stack } from '@mui/material'
import { useRecordContext } from 'react-admin'

import { WorkOrderModel } from 'appTypes/models'
import { ViewHeader, SliderView, GridContainer, GridItemLayout, Section } from 'components'
import Skeleton, { SkeletonSliderCardContainer } from 'components/Skeletons'

import { useIsVenderWo } from '../Overview/WorkOrderOverview'

import {
    CanceledInfo,
    ClosedInfo,
    DatePriorityClass,
    DescriptionCreatenOnBy,
    ReasonForCancelation,
} from './Slides'
import WorkOrderEditButton from './WorkOrderEditButton'
import { CanceledWorkOrderPurchaseInvoice } from './WorkOrderPurchaseInvoice'
import WorkOrderVendorCard from './WorkOrderVendorCard'

const slides = [
    <DatePriorityClass key="date-priority-class" />,
    <DescriptionCreatenOnBy key="description-created-on-by" />,
]
const skeletonSlides = new Array(2).fill(0).map((v, i) => {
    return (
        <SkeletonSliderCardContainer
            key={i}
            sx={{
                height: '112px',
            }}
        >
            <Skeleton />
        </SkeletonSliderCardContainer>
    )
})
const WorkOrderDetails = () => {
    const workOrder = useRecordContext<WorkOrderModel>()
    const isVendorWorkOrder = useIsVenderWo()
    const isStatusCancel = workOrder?.status === 'CANCELED'
    const isStatusClosed = workOrder?.status === 'CLOSED'

    return (
        <Section>
            <ViewHeader
                title="Details"
                loading={!workOrder}
            >
                <ViewHeader.Content placement="rightMobile">
                    <WorkOrderEditButton />
                </ViewHeader.Content>
            </ViewHeader>
            {isStatusCancel ? (
                <>
                    <GridContainer>
                        <GridItemLayout>
                            <Stack spacing="24px">
                                <ReasonForCancelation />
                                <CanceledInfo />
                                {isVendorWorkOrder && (
                                    <WorkOrderVendorCard
                                        data={workOrder}
                                        canceled={isStatusCancel}
                                    />
                                )}
                                {slides}
                                <CanceledWorkOrderPurchaseInvoice />
                            </Stack>
                        </GridItemLayout>
                    </GridContainer>
                </>
            ) : (
                <SliderView
                    items={
                        workOrder
                            ? isStatusClosed
                                ? [...slides, <ClosedInfo />]
                                : slides
                            : skeletonSlides
                    }
                />
            )}
        </Section>
    )
}

export default WorkOrderDetails

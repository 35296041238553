import { FC, ForwardRefExoticComponent, MouseEvent, ReactElement, ReactNode } from 'react'

import {
    ListItemButton,
    ListItemText,
    ListItemIcon,
    alpha,
    SvgIconProps,
    Badge,
    BadgeProps,
    badgeClasses,
} from '@mui/material'

import { NavLink, Typography } from 'components'
import { ActionsMenu, BaseActionRenderParams } from 'components/actions'
import { Flags, useFlags } from 'hooks'
import { pathJoin } from 'utils'

import SidebarHide from './SidebarHide'
import SidebarTooltip from './SidebarTooltip'

export interface MenuItemRenderIconParams {
    renderIconView: (params?: SvgIconProps) => ReactElement
    renderBadge: (params?: BadgeProps) => ReactElement
}

type ExtendedValue<T> = T | ((params: { flags: Flags }) => T)
export type MenuItemInterface = {
    key: string
    to: string
    primary: string
    submenu?: ExtendedValue<
        (Omit<BaseActionRenderParams, 'key' | 'to'> & { key: string; to: string })[]
    >
    icon: FC
    tooltip?: ExtendedValue<string>
    disabled?: ExtendedValue<boolean>
    renderIcon?: (params: MenuItemRenderIconParams) => ReactNode
}
const SidebarNavItemContent = ({
    onClick,
    className,
    ...props
}: Omit<MenuItemInterface, 'submenu'> & {
    onClick?: (event: MouseEvent<HTMLElement>) => void
    className?: string
}) => {
    const { icon, tooltip, primary, disabled, renderIcon, ...rest } = props
    const flags = useFlags()
    const Icon = icon
    const renderIconView = (params?: SvgIconProps) => <Icon {...params} />
    return (
        <SidebarTooltip
            title={
                tooltip ? (typeof tooltip === 'function' ? tooltip({ flags }) : tooltip) : primary
            }
            placement="right"
            arrow
        >
            <li>
                <ListItemButton
                    component={NavLink as ForwardRefExoticComponent<any>}
                    {...rest}
                    sx={{ p: '14px 16px', border: 0 }}
                    activeClassName="MuiNavDrawerActive"
                    className={className}
                    onClick={onClick}
                    disabled={typeof disabled === 'function' ? disabled({ flags }) : disabled}
                    aria-label={`Go to ${primary}`}
                >
                    <ListItemIcon
                        sx={(theme) => ({
                            padding: 0,
                            color: alpha(theme.palette.text.main, 0.54),
                        })}
                    >
                        {renderIcon
                            ? renderIcon({
                                  renderIconView,
                                  renderBadge: (params = {}) => (
                                      <Badge
                                          color="primary"
                                          variant="dot"
                                          sx={{
                                              [`& .${badgeClasses.badge}`]: {
                                                  mt: '2px',
                                                  mr: '2px',
                                              },
                                          }}
                                          {...params}
                                          children={params.children || renderIconView()}
                                      />
                                  ),
                              })
                            : renderIconView()}
                    </ListItemIcon>
                    <SidebarHide>
                        <ListItemText
                            disableTypography
                            sx={{ margin: 0 }}
                        >
                            <Typography variant="body1">{primary}</Typography>
                        </ListItemText>
                    </SidebarHide>
                </ListItemButton>
            </li>
        </SidebarTooltip>
    )
}
const SidebarNavItem: FC<MenuItemInterface> = ({ submenu, ...rest }) => {
    const flags = useFlags()
    const menu = (typeof submenu === 'function' ? submenu({ flags }) : submenu)?.filter?.(
        (item) => item,
    )
    if (menu?.length) {
        return (
            <ActionsMenu
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                renderToggler={(open, isOpen) => (
                    <SidebarNavItemContent
                        className={isOpen ? 'MuiNavDrawerActive' : ''}
                        onClick={(event: MouseEvent<HTMLElement>) => {
                            open(event)
                            event.preventDefault()
                        }}
                        {...rest}
                    />
                )}
                actions={(params, { children }) =>
                    menu.map(({ to = '', ...item }) =>
                        children({ to: pathJoin(rest.to, to), ...item }),
                    )
                }
            />
        )
    }
    return <SidebarNavItemContent {...rest} />
}

export default SidebarNavItem

import { Box, ClickAwayListener, Typography } from '@mui/material'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Popper from '@mui/material/Popper'
import { useRecordContext } from 'react-admin'

import { UnitModel } from 'appTypes/models'
import { ReactComponent as GoogleMapIcon } from 'assets/images/google-map-pin.svg'
import { ActionsMenu, copyAction } from 'components/actions'
import Icons from 'components/icons'
import { formatDate } from 'utils'

interface MapInfoBoxProps {
    targetRef: HTMLDivElement
    close: () => void
    isOpen: boolean
}
const MapInfoBox = ({ targetRef, close, isOpen }: MapInfoBoxProps) => {
    const unit = useRecordContext<UnitModel>()
    const location = unit.location

    return (
        <Popper
            open={Boolean(isOpen)}
            anchorEl={targetRef}
            placement="top"
            disablePortal
            sx={{
                transform: 'translate(-50%, -50%) !important',
                top: 'calc(50% - 35px) !important',
                left: '50% !important',
                zIndex: 2,
            }}
        >
            <ClickAwayListener onClickAway={close}>
                <Box
                    p="12px"
                    width="256px"
                    bgcolor="white"
                    boxShadow={8}
                    borderRadius="4px"
                >
                    <Box
                        display="flex"
                        alignItems="flex-start"
                        gap="10px"
                    >
                        <Box
                            flexGrow="1"
                            overflow="hidden"
                            textOverflow="ellipsis"
                            whiteSpace="nowrap"
                        >
                            {location.description ? (
                                <Description description={location.description} />
                            ) : (
                                <div />
                            )}
                        </Box>

                        <ActionsMenu
                            renderToggler={(open) => (
                                <IconButton
                                    onClick={open}
                                    size="small"
                                >
                                    <Icons.Options fontSize="inherit" />
                                </IconButton>
                            )}
                            iconBackgroundColor="transparent"
                            actions={(_, { children }) => [
                                <a
                                    href={`https://www.google.com/maps/search/?api=1&query=${location.latitude},${location.longitude}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    key="open-map"
                                >
                                    {children({
                                        Icon: GoogleMapIcon,
                                        title: 'View on Google Maps',
                                    })}
                                </a>,
                                copyAction({
                                    title: 'Copy Address',
                                    copyMessage: location.description,
                                    children,
                                }),
                            ]}
                        />
                    </Box>
                    <Divider sx={{ my: '8px' }} />
                    <Typography
                        variant="caption"
                        color="text.secondary"
                    >
                        Last Synced {formatDate(location.updated, 'MMM dd yyyy H:mm')}
                    </Typography>
                </Box>
            </ClickAwayListener>
        </Popper>
    )
}

export default MapInfoBox

const Description = ({ description }: { description: string }) => {
    // split description by first comma
    const [address, locationRest] = description.split(/,(.*)/s)

    return (
        <Typography
            variant="chartTitle"
            color="text.primary"
            display="inline"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '4px',
                '& > *': {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
            }}
        >
            <div>{address}</div>
            <div>{locationRest}</div>
        </Typography>
    )
}

import { Fragment } from 'react'

import { Divider, ListItem, ListItemText } from '@mui/material'
import { RaRecord } from 'react-admin'

import { extendRecord } from 'utils'

import { DialogSelectorListProps } from './DialogSelectorList'

export interface DialogSelectorListItemProps<T extends RaRecord = any>
    extends Pick<DialogSelectorListProps<T>, 'itemPrimary' | 'itemSecondary'> {
    choice: T
    onSelect: (event: React.MouseEvent<HTMLElement>, choice: T) => void
}

const DialogSelectorListItem = <T extends RaRecord = any>({
    choice,
    onSelect,
    itemPrimary,
    itemSecondary,
}: DialogSelectorListItemProps<T>) => {
    const secondaryText = extendRecord(choice, itemSecondary)

    return (
        <>
            <ListItem
                button
                role="option"
                component="li"
                sx={{ px: '20px', py: '6px' }}
                onClick={(e) => onSelect(e, choice)}
            >
                <ListItemText
                    primaryTypographyProps={{
                        sx: {
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            fontWeight: 'bold',
                        },
                    }}
                    secondaryTypographyProps={{
                        component: 'div',
                        sx: {
                            '& > *': {
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            },
                        },
                    }}
                    primary={extendRecord(choice, itemPrimary)}
                    secondary={
                        Array.isArray(secondaryText)
                            ? secondaryText.map((item, i) => <div key={i}>{item}</div>)
                            : secondaryText
                    }
                />
            </ListItem>
            <Divider />
        </>
    )
}
export default DialogSelectorListItem
